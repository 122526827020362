import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  IoDocument,
  IoDocumentText,
  IoHeart,
  IoMedkit,
  IoWater,
} from 'react-icons/io5';
import { ViewportList } from 'react-viewport-list';
import SnapshotModal from '../../Modal/SnapshotModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import {
  getDraftUpdates,
  publishDraftUpdates,
} from '../../../../data/AmplifyVersion';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import { useSelector } from 'react-redux';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import Loading from '../../Loading/Loading';
import { BiArrowBack, BiFolderOpen } from 'react-icons/bi';
import { FaTimes } from 'react-icons/fa';
import ModelItem from '../../../../data/model/ModelItem';
import SearchableList from '../../Search/SearchableList';
import CategoryItem from '../../../../data/model/CategoryItem';
import { FaBoltLightning, FaFolderOpen, FaSyringe } from 'react-icons/fa6';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import VitalItem from '../../../../data/model/VitalItem';
import FormItem from '../../../../data/model/FormItem';
import InfusionItem from '../../../../data/model/InfusionItem';

interface ReviewSideOutProps {
  database: DatabaseResponse;
  handleClose?: any;
  handleAdd?: () => void | any;
  isVisible?: boolean;
  setVisible: (visible: boolean) => void;
  onPublish: () => void | any;
}

/* 11-10-23 Arul: Created the review side out component for Protocol */
const ReviewSideOut: React.FC<ReviewSideOutProps> = (props) => {
  const { isVisible, handleClose, setVisible, database, onPublish } = props;
  const user: User = useSelector((state: any) => state.user);
  const [isSnapshotOnPublishChecked, setIsSnapshotOnPublishChecked] =
    useState(false);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [isSnapshotModalVisible, setIsSnapshotModalVisible] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [allDrafts, setAllDrafts] = useState<any[]>([]);
  const [draftList, setDraftList] = useState<any[]>([]);
  const department = useSelector(
    (state: any) => state.protocol.departmentItem.department
  );

  const searchList = useMemo(
    () =>
      /* First out all the added drafts from the search list */
      allDrafts.filter((draft) => !draftList.includes(draft)),
    [allDrafts, draftList]
  );

  useEffect(() => {
    loadDetectedChanges();
  }, []);

  //function for snapshot modal submit
  const handleSnapshotSubmit = async () => {
    setIsSnapshotModalVisible(false);
    setIsPublishing(true);
    let results = await publishDraftUpdates(department, draftList);
    if (results.type === ResponseType.Success) {
      let updates = results.data;
      onPublish();
      setIsReviewModalVisible(true);
    } else {
      if (globals.debug) console.log('Failed to publish updates', results);
    }
    setIsPublishing(false);
  };

  //function for publish button onclick
  const handlePublishClick = () => {
    if (isSnapshotOnPublishChecked) {
      setIsSnapshotModalVisible(true);
    } else {
      handleSnapshotSubmit();
    }
  };
  const loadDetectedChanges = async () => {
    const response: Response = await getDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (globals.debug) console.log(response.data);
      setAllDrafts(response.data);
      setDraftList(response.data);
    }
  };
  return (
    <div>
      <SnapshotModal
        dialogVisible={isSnapshotModalVisible}
        setDialogVisible={setIsSnapshotModalVisible}
        headerText="Your Snapshot Modal Header Text"
        handleSnapshotSubmit={handleSnapshotSubmit}
      />

      {isReviewModalVisible && (
        <ConfirmModal
          isVisible={isReviewModalVisible}
          title="Successfully Published, Notify?"
          handleClose={() => {
            setIsReviewModalVisible(false);
            handleClose();
          }}
          handleSubmit={() => {
            setIsReviewModalVisible(false); // Close the Review Modal
            setIsSnapshotModalVisible(false);
            handleClose();
          }}
          isDeleteBtn={false}
          primaryBtnName="Dismiss"
          secondaryBtnName="Accept"
          primaryDescription="The updates have successfully been published to the OneDose application."
          secondaryDescription="Would you like to go to the notification page to create a notification for the update?"
        />
      )}
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleClose}
        style={{ width: '30%' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
      >
        <div className="sidebarContainer">
          <div>
            <div
              className="buttonContainer contentTitleLarge hoverText"
              onClick={handleClose}
            >
              <span className="">
                <BiArrowBack className="header-icon" style={{}} /> Review
              </span>
            </div>
            <div
              className="contentText"
              style={{
                fontSize: '16px',
              }}
            >
              All items that are in a DRAFT state can be published to OneDose
              immediately. Please review the changes below and make sure
              everything is correct before publishing. We recommend running
              every change through a review process to ensure the highest
              quality of content.
            </div>
            <div className="contentLabel">Revision Information</div>
            <div>
              <div className="infolist">
                <div>Published By:</div>
                <div>
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div className="infolist">
                <div>Publish Date:</div>
                <div>{getFormattedDate(new Date(), true)}</div>
              </div>
            </div>
            {isPublishing && (
              <div className="loading">
                <Loading type="bubbles" />
                <div className="loadingText">Publishing...</div>
              </div>
            )}
            <SearchableList<ModelItem<any>>
              id="searchDropdown"
              options={searchList}
              labelField={(option) => option.model.name}
              valueField={(option) => option.model.name + option.TAG}
              multiSelect
              iconField={(option) => {
                if (option.model instanceof CategoryItem)
                  return <FaFolderOpen />;
                else if (option.model instanceof ProtocolItem)
                  return <IoDocument />;
                else if (option.model instanceof MedicationItem)
                  return <FaSyringe />;
                else if (option.model instanceof InfusionItem)
                  return <IoWater />;
                else if (option.model instanceof ElectricalItem)
                  return <FaBoltLightning />;
                else if (option.model instanceof EquipmentItem)
                  return <IoMedkit />;
                else if (option.model instanceof VitalItem) return <IoHeart />;
                else if (option.model instanceof FormItem)
                  return <IoDocumentText />;
                else return <BiFolderOpen />;
              }}
              onChange={(option: ModelItem<any>) => {
                setDraftList([...draftList, option]);
              }}
              // multiSelect={true}
              placeholder="Search Drafts..."
              containerStyle={{ width: '100%' }}
              notFoundText="No drafts found..."
              itemClassName="search-item"
            />
            {!isPublishing && (
              <div>
                <div className="contentLabelBold">
                  Detected Changes: {draftList.length}
                  <span
                    onClick={() => {
                      if (draftList.length > 0) setDraftList([]);
                      else setDraftList(allDrafts);
                    }}
                  >
                    <div
                      className="clickableText"
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {draftList.length > 0 ? 'Clear All' : 'Add All'}
                    </div>
                  </span>
                </div>

                <div className="contentBorder protocolCalculationPad reviewListScroll">
                  <ViewportList items={draftList}>
                    {(item: any, index: any) => (
                      <div
                        key={item + index}
                        className={`listhover spaceBetween cursorPointer item contentHeading ${draftList.length !== index + 1 ? 'contentUnderline' : ''}`}
                      >
                        <div
                          style={{
                            flex: 1,
                          }}
                        >
                          {item.message}
                        </div>
                        <span
                          className="hoverable-icon-button red"
                          onClick={() => {
                            setDraftList(draftList.filter((i) => i !== item));
                          }}
                        >
                          <FaTimes />
                        </span>
                      </div>
                    )}
                  </ViewportList>
                </div>
                {/* <div className='btn_Bottom review_btn_Bottom' style={{ left: 0 }}>
                                    <div style={{ marginBottom: '20px' }}>
                                        <div className='colAlignCenter'>
                                            <input
                                                className='checkBoxSelectedColor custom-checkbox-button-input'
                                                type="checkbox"
                                                id="reviewCheckbox"
                                                checked={isSnapshotOnPublishChecked}
                                                onChange={() => setIsSnapshotOnPublishChecked(!isSnapshotOnPublishChecked)}
                                            />
                                            <div className="contentHeading">Create a snapshot on publish</div>
                                        </div>

                                    </div>
                                </div> */}
              </div>
            )}
          </div>
          <div className="btn_Bottom ">
            <Button
              className="secondary-button btn-rightMargin"
              onClick={() => setVisible(false)}
            >
              Dismiss
            </Button>
            <Button className="primary-button" onClick={handlePublishClick}>
              Publish
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ReviewSideOut;
