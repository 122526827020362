import { useFormik } from 'formik';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { handleSetSelectedDoseValue } from '../../../../store/actions';
import CalculationBasisDialog from '../../Modal/CalculationBasisModal';
import DoseFixedCalcDialogModal from '../../Modal/DoseFixedCalcDialogModal';
import RangeDialog from '../../Modal/RangeDialogModal';

import * as _ from 'lodash';
import ConfirmModal from '../../Modal/ConfirmModal';
import {
  ElectricalShockOption,
  ProgressStatus,
  User,
} from '../../../../models';
import {
  electricalRangeKeys,
  findDepartmentOwner,
  globals,
} from '../../../_global/common/Utils';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import { BiArrowBack } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import SearchableDropdown from '../../SearchableDropdown';
import {
  DatabaseResponse,
  ResponseType,
  fetchPDF,
} from '../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import GeneralSelection from '../../Selection/GeneralSelection';
import { ViewportList } from 'react-viewport-list';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import { InputTextarea } from 'primereact/inputtextarea';
import ElectricalDoseUI from '../../../pages/protocol/edit/Electrical/ElectricalDoseUI';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ElectricalDoseSelection from '../../../pages/protocol/edit/Electrical/ElectricalDoseSelection';
import { Tooltip } from '@mui/material';
import { BsCloudFill, BsCloudSlashFill } from 'react-icons/bs';
import { DeviceMockup } from '../../DeviceMockup/DeviceMockup';
import Status from '../../ProgressStatus/ProgressStatus';
import AgeRangeDialog from '../../Modal/AgeRangeDialogModal';
import PatientAge from '../../../_global/common/PatientAge';

interface MedicationDoseSideoutProps {
  visible: boolean;
  dose?: ElectricalSubItem;
  parentModel: ElectricalItem;
  subElectrialItems: ElectricalSubItem[];
  setVisible: (visible: boolean) => void;
  editDose?: boolean;
  protocol: ProtocolItem | null;
  allProtocols?: ProtocolItem[];

  doseIndex?: any;
  onSideOutClose?: any;
  onRemove: (dose: any, type: 'block' | 'restore' | 'delete') => void;
  onSubmit: (
    dose: any,
    prevDose?: ElectricalSubItem,
    parentProtocol?: ProtocolItem
  ) => void;
  onSubmitMultiDose?: (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => void;
  onSubmitElectrialOption?: (eo: ElectricalShockOption) => void;
}

const NEW_DOSE = 0;
const COPY_DOSE = 1;
const COPY_PROTOCOL_DOSE = 2;
const ADMIN_MED_PROTOCOL = 3;

const SHOW_PDF = 0;
const SHOW_DEVICE_MOCKUP = 1;

/*10-16-23 Praveen:  Created common component for Edit and New Dose section sideout view*/
const ElectricalShockSideout: React.FC<MedicationDoseSideoutProps> = ({
  doseIndex,
  protocol,
  parentModel,
  subElectrialItems,
  dose,
  visible,
  setVisible,
  editDose,
  onSubmit,
  onSideOutClose,
  onSubmitMultiDose,
  onRemove,
  onSubmitElectrialOption,
}) => {
  const dispatch = useDispatch();
  const [minMaxDialog, setMinMaxDialog] = useState<string | null>(null);
  const [rangeDialog, setRangeDialog] = useState<string | null>(null);
  const [ageRangeDialog, setAgeRangeDialog] = useState<string | null>(null);
  const [calculationDialogVisible, setCalculationDialogVisible] =
    useState(false);

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const user: User = useSelector((state: any) => state.user);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    database.protocols.sort((a, b) => a.name.localeCompare(b.name))
  );
  const [parentProtocol, setParentProtocol] = useState<ProtocolItem | null>(
    protocol ? protocol : dose ? dose.parentProtocol : null
  );
  const [electricalOption, setElectricalOption] = useState<string>('');
  const [mpError, setMpError] = useState('');

  const [inputType, setInputType] = useState(NEW_DOSE);
  const [doseProtocols, setDoseProtocols] = useState<ProtocolItem[]>([]);

  const [selectedDose, setSelectedDose] = useState<ElectricalSubItem | null>(
    null
  );
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolItem | null>(
    null
  );
  const [selectedProtocolDoses, setSelectedProtocolDoses] = useState<
    ElectricalSubItem[]
  >([]);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfOrDeviceMock, setPdfOrDeviceMock] = useState(SHOW_PDF);

  const [isEmergency, setIsEmergency] = useState(
    dose ? dose.model.basis === '-1' : false
  );
  const isDeactivated = useMemo(() => {
    if (
      dose &&
      dose.status === ProgressStatus.DEACTIVATED &&
      dose.overrideItem &&
      dose.overrideItem.departmentID !== database.department.id
    )
      return true;
    return false;
  }, [dose, database.department]);

  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    dose
      ? findDepartmentOwner(
          database.department,
          isDeactivated && dose.overrideItem ? dose.overrideItem : dose
        )
      : null
  );

  const isOwner = useMemo(() => {
    return (
      departmentOwner == null || departmentOwner.id === database.department.id
    );
  }, [departmentOwner, database.department]);

  const [edittable, setEdittable] = useState<boolean>(isOwner);
  const allDoses = useMemo(() => {
    return subElectrialItems.filter((m) => m.status !== 'DEACTIVATED');
  }, [subElectrialItems]);

  useEffect(() => {
    if (dose && database.department) {
      setDepartmentOwner(
        findDepartmentOwner(
          database.department,
          isDeactivated && dose.overrideItem ? dose.overrideItem : dose
        )
      );

      setEdittable(isOwner);
    }
  }, [dose, database.department, isOwner, isDeactivated]);

  const loadPDF = useCallback(async () => {
    let result = await fetchPDF(parentProtocol?.pdfUrl);
    if (result.type === ResponseType.Success) {
      setPdfUrl(result.data);
    } else {
      console.error(
        'ERROR: Could not load pdf for protocol: ' + parentProtocol?.name,
        result.data
      );
      setPdfUrl('');
    }
  }, [parentProtocol]);

  useEffect(() => {
    if (parentProtocol?.pdfUrl && !pdfLoaded) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [parentProtocol, pdfLoaded, loadPDF]);

  useEffect(() => {
    let prots: ProtocolItem[] = [];
    for (let i = 0; i < allDoses.length; i++) {
      let found = false;
      for (let j = 0; j < prots.length; j++) {
        if (prots[j].uid === allDoses[i].parentProtocol.uid) {
          found = true;
          break;
        }
      }
      if (!found) prots.push(allDoses[i].parentProtocol);
    }
    setDoseProtocols(prots);
  }, [allDoses]);

  const formik = useFormik({
    initialValues: {
      /* Shared on all models */
      rangeLow: dose ? dose.rangeLow : '',
      rangeHigh: dose
        ? dose.rangeHigh === globals.MAX_VALUE
          ? ''
          : dose.rangeHigh
        : '',
      ageLow: dose ? dose.ageLow : undefined,
      ageHigh: dose ? dose.ageHigh : undefined,

      /* Medication, Electrical and Infusion */
      basis: dose ? dose.model.basis : '+Add',
      // parentProtocol: protocol,

      minDose: dose && dose.model.minDose ? dose.model.minDose : '',
      maxDose: dose && dose.model.maxDose ? dose.model.maxDose : '',
      maxTotalDose:
        dose && dose.model.maxTotalDose ? dose.model.maxTotalDose : '',
      calcMax: dose && dose.model.calcMax ? dose.model.calcMax : '',
      calcMin: dose && dose.model.calcMin ? dose.model.calcMin : '',

      repeatTime: dose && dose.model.repeatTime ? dose.model.repeatTime : '',
      incrementAmount:
        dose && dose.model.incrementAmnt ? dose.model.incrementAmnt : '',

      title: dose ? dose.title : '',
      warning: dose ? dose.warning : '',
      instruction: dose ? dose.instruction : '',
      note: dose ? dose.note : '',
    },
    validationSchema: Yup.object({
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      ageLow: Yup.string(),
      ageHigh: Yup.string(),
      repeatTime: Yup.string(),
      incrementAmount: Yup.string(),
      maxTotalDose: Yup.string(),
      calcMax: Yup.string(),
      calcMin: Yup.string(),
      routes: Yup.array(),
      basis: Yup.string().required('Required'),
      minDose: Yup.string(),
      maxDose: Yup.string(),
      title: Yup.string().required('Required'),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  /* 11-01-23 Praveen: handled function to set calcaulation basis popup value */
  const handleCalculationBasisset = async (newBasis: string) => {
    setCalculationDialogVisible(false);
    formik.setFieldValue('basis', newBasis);
  };

  //*10-30-23 Arul: handling function for create and edit dose sideout */
  const handleSubmit = async () => {
    console.log('SUBMITTING', inputType, electricalOption);
    let newDose = formik.values;
    if (inputType === COPY_PROTOCOL_DOSE) {
      if (onSubmitMultiDose && selectedProtocol && parentProtocol)
        onSubmitMultiDose(parentProtocol, selectedProtocol);
    } else if (inputType === ADMIN_MED_PROTOCOL) {
      let eo: ElectricalShockOption = JSON.parse(electricalOption);
      console.log('Detected Medication Protocol', validateElectricalOption(eo));
      if (onSubmitElectrialOption && validateElectricalOption(eo)) {
        console.log('Adding Electrical Option', eo);
        onSubmitElectrialOption(eo);
      } else {
        setMpError('Invalid Medication Protocol');
      }
    } else {
      if (isEmergency) {
        newDose.basis = '-1';
      }
      onSubmit(
        newDose,
        dose,
        parentProtocol ? parentProtocol : dose ? dose.parentProtocol : undefined
      );
    }
    // if (onDoseCreationComplete) {
    // 	onDoseCreationComplete();
    // }
    setVisible(false);
  };

  /* 11-02-23 Praveen: submit button validation*/
  const isSaveValid = useMemo(() => {
    if (inputType === COPY_PROTOCOL_DOSE)
      return (
        parentProtocol && selectedProtocol && selectedProtocolDoses.length > 0
      );
    else {
      if (inputType === COPY_DOSE && (!selectedDose || !parentProtocol))
        return false;
      else {
        if (isEmergency && formik.values.warning && parentProtocol != null)
          return true;
        else if (parentProtocol == null) return false;
        else if (
          formik.values.basis === '' ||
          formik.values.basis === '+Add' ||
          formik.values.title === ''
        )
          return false;
        return formik.dirty;
      }
    }
  }, [
    inputType,
    parentProtocol,
    selectedProtocol,
    selectedProtocolDoses.length,
    selectedDose,
    isEmergency,
    formik.values,
    formik.isValid,
    formik.dirty,
  ]);

  const handleCloseSideout = () => {
    if (formik.dirty) {
      setIsWarningModal(true);
    } else {
      setVisible(false);
      // onSideOutClose && onSideOutClose();
      // dispatch<any>(handleSetSelectedDoseValue(""));
    }
  };

  const handleCloseWarningModal = () => {
    setVisible(false);
    dispatch<any>(handleSetSelectedDoseValue(''));
  };

  const customHeader = () => {
    return (
      <div>
        <h4 className="headerText hoverText" onClick={handleCloseSideout}>
          <span className="">
            <BiArrowBack className="header-icon" data-testid="isBackBtn" />
          </span>{' '}
          {editDose ? 'Edit' : 'Create'} Electrical Shock
        </h4>
        {departmentOwner && (
          <Tooltip
            title={departmentOwner.name + ' owns this item.'}
            placement="bottom"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <div
              className="protocol-header-logo-container"
              style={{
                top: '2px',
                right: '0px',
              }}
            >
              <img
                className="no-select owner-logo"
                src={departmentOwner.logoVerifiedUrl ?? ''}
                alt="Agency Logo"
              />
              <div
                className="lock-icon"
                style={{
                  top: '5px',
                }}
              >
                <FaLock
                  data-testid="isLockButton"
                  style={{
                    color:
                      departmentOwner.id !== database.department.id
                        ? '#A3A3A3'
                        : 'transparent',
                    fontSize: '1.25rem',
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleDeleteRestoreBlockDose = (
    type: 'block' | 'restore' | 'delete'
  ) => {
    if (onRemove) onRemove(dose, type);
    setIsDeleteModal(false);
    handleCloseSideout();
  };

  const handleSelectCopyDose = (dose: ElectricalSubItem | null) => {
    setSelectedDose(dose);
    formik.setFieldValue('rangeLow', dose ? dose.rangeLow : '');
    formik.setFieldValue(
      'rangeHigh',
      dose ? (dose.rangeHigh === globals.MAX_VALUE ? '' : dose.rangeHigh) : ''
    );
    formik.setFieldValue('ageLow', dose ? dose.ageLow : '');
    formik.setFieldValue('ageHigh', dose ? dose.ageHigh : '');

    formik.setFieldValue('basis', dose ? dose.model.basis : '+Add');
    formik.setFieldValue(
      'minDose',
      dose && dose.model.minDose ? dose.model.minDose : ''
    );
    formik.setFieldValue(
      'maxDose',
      dose && dose.model.maxDose ? dose.model.maxDose : ''
    );
    formik.setFieldValue(
      'maxTotalDose',
      dose && dose.model.maxTotalDose ? dose.model.maxTotalDose : ''
    );
    formik.setFieldValue(
      'calcMax',
      dose && dose.model.calcMax ? dose.model.calcMax : ''
    );
    formik.setFieldValue(
      'calcMin',
      dose && dose.model.calcMin ? dose.model.calcMin : ''
    );

    formik.setFieldValue(
      'repeatTime',
      dose && dose.model.repeatTime ? dose.model.repeatTime : ''
    );
    formik.setFieldValue(
      'incrementAmount',
      dose && dose.model.incrementAmnt ? dose.model.incrementAmnt : ''
    );

    formik.setFieldValue('title', dose ? dose.title : '');
    formik.setFieldValue('warning', dose ? dose.warning : '');
    formik.setFieldValue('instruction', dose ? dose.instruction : '');
    formik.setFieldValue('note', dose ? dose.note : '');
  };

  const buttonTitle = useMemo(() => {
    if (inputType === NEW_DOSE) return editDose ? 'Save' : 'Create';
    else if (inputType === COPY_DOSE) return 'Copy Dose';
    else if (inputType === COPY_PROTOCOL_DOSE)
      return 'Copy ' + selectedProtocolDoses.length + ' Doses';
    else if (inputType === ADMIN_MED_PROTOCOL) return 'Add';
  }, [editDose, inputType, selectedProtocolDoses]);

  const Modals = () => {
    return (
      <>
        {/* Min Max Dose Popup */}
        <DoseFixedCalcDialogModal
          unit={'J'}
          isVisible={minMaxDialog != null}
          type="electrical"
          value={
            // editData
            minMaxDialog === 'min'
              ? formik.values.minDose
              : minMaxDialog === 'max'
                ? formik.values.maxDose
                : minMaxDialog === 'maxTotal'
                  ? formik.values.maxTotalDose
                  : minMaxDialog === 'calcMin'
                    ? formik.values.calcMin
                    : formik.values.calcMax
          }
          handleSet={(data: any) => {
            let e: string = data.value ? data.value + ' ' + data.unit : '';
            minMaxDialog === 'min'
              ? formik.setFieldValue('minDose', e)
              : minMaxDialog === 'max'
                ? formik.setFieldValue('maxDose', e)
                : minMaxDialog === 'maxTotal'
                  ? formik.setFieldValue('maxTotalDose', e)
                  : minMaxDialog === 'calcMin'
                    ? formik.setFieldValue('calcMin', e)
                    : formik.setFieldValue('calcMax', e);
            setMinMaxDialog(null);
          }}
          handleClose={() => {
            setMinMaxDialog(null);
          }}
          headerText={
            minMaxDialog === 'min'
              ? 'Min Dose'
              : minMaxDialog === 'max'
                ? 'Max Dose'
                : minMaxDialog === 'maxTotal'
                  ? 'Max Total Dose'
                  : minMaxDialog === 'calcMin'
                    ? 'Calculated Min Dose'
                    : 'Calculated Max Dose'
          }
          headerTitle={
            minMaxDialog === 'min'
              ? 'Min'
              : minMaxDialog === 'max'
                ? 'Max'
                : minMaxDialog === 'maxTotal'
                  ? 'Max Total'
                  : minMaxDialog === 'calcMin'
                    ? 'Calculated Min'
                    : 'Calculated Max'
          }
          InputLabel={
            minMaxDialog === 'min' || minMaxDialog === 'calcMin' ? 'Min' : 'Max'
          }
          isCalculated={false}
        />

        <RangeDialog
          dialogVisible={rangeDialog != null}
          type={'Electical'}
          value={
            rangeDialog === 'low'
              ? formik.values.rangeLow
              : formik.values.rangeHigh
          }
          handleSet={(e: any) => {
            rangeDialog === 'low'
              ? formik.setFieldValue('rangeLow', e)
              : formik.setFieldValue('rangeHigh', e);
            setRangeDialog(null);
          }}
          setDialogVisible={() => {
            setRangeDialog(null);
          }}
          headerText={rangeDialog === 'low' ? 'Low Range' : 'High Range'}
          headerTitle={rangeDialog === 'low' ? 'Low' : 'High'}
          InputLabel={rangeDialog === 'low' ? 'LOWER' : 'HIGHER'}
        />

        <AgeRangeDialog
          dialogVisible={ageRangeDialog != null}
          type={'Electrical'}
          value={
            (ageRangeDialog === 'low'
              ? formik.values.ageLow
              : formik.values.ageHigh) ?? undefined
          }
          handleSet={(e: any) => {
            ageRangeDialog === 'low'
              ? formik.setFieldValue('ageLow', e)
              : formik.setFieldValue('ageHigh', e);
            setAgeRangeDialog(null);
          }}
          setDialogVisible={() => {
            setAgeRangeDialog(null);
          }}
          headerText={ageRangeDialog === 'low' ? 'Low Age' : 'High Age'}
          headerTitle={ageRangeDialog === 'low' ? 'Low' : 'High'}
          InputLabel={ageRangeDialog === 'low' ? 'LOWER' : 'HIGHER'}
        />

        {/* CalculationBasis Popup */}
        <CalculationBasisDialog
          unit={'J'}
          dialogVisible={calculationDialogVisible}
          type={'Electrical'}
          handleSet={handleCalculationBasisset}
          basis={formik.values.basis}
          setDialogVisible={() => {
            setCalculationDialogVisible(false);
          }}
          headerText="Calculation Basis"
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleCloseWarningModal}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication Dose.  Click cancel to return to Medication Dose details.  To continue without saving changes, select Abandon Changes.`}
          />
        )}

        {isDeleteModal && (
          <ConfirmModal
            isVisible={isDeleteModal}
            title={`Confirm ${dose?.status === 'DRAFT_DELETE' ? 'Restore?' : 'Delete?'}`}
            handleClose={() => {
              setIsDeleteModal(false);
            }}
            handleSubmit={() =>
              handleDeleteRestoreBlockDose(
                dose?.status === 'DRAFT_DELETE' ? 'restore' : 'delete'
              )
            }
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName={
              dose?.status === 'DRAFT_DELETE' ? 'Restore' : 'Delete'
            }
            primaryDescription={`Are you sure you want to ${dose?.status === 'DRAFT_DELETE' ? 'restore' : 'delete'} this Medication Dose. This will ${dose?.status === 'DRAFT_DELETE' ? 'restore the active electrical and make no change to the published electricals' : 'create a "delete draft" stage and must be published beore it will be deleted'}.`}
          />
        )}
        {isBlockModal && (
          <ConfirmModal
            isVisible={isBlockModal}
            title={`${
              isDeactivated ? 'Reactivate' : 'Deactivate'
            } ${departmentOwner?.name} ${dose?.name} Dose?`}
            handleClose={() => {
              setIsBlockModal(false);
            }}
            handleSubmit={() =>
              handleDeleteRestoreBlockDose(isDeactivated ? 'restore' : 'block')
            }
            isDeleteBtn={!isDeactivated}
            primaryBtnName="Cancel"
            secondaryBtnName={isDeactivated ? 'Reactivate' : 'Deactivate'}
            primaryDescription={`This will ${isDeactivated ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${dose?.title} Dose for ${database.department?.name}. ${isDeactivated ? '' : 'This can be reactiveated at any time.'}`}
          />
        )}
      </>
    );
  };

  const findDoses = (protocol: ProtocolItem) => {
    let doses = allDoses.filter(
      (dose: ElectricalSubItem) => dose.parentProtocol.uid === protocol.uid
    );
    return doses.length + ' doses';
  };

  const validateElectricalOption = (eo: any) => {
    if (eo == null) return false;
    if (eo.protocolID == null) return false;
    if (eo.ranges == null) return false;
    let availableKeys = electricalRangeKeys;
    for (let i = 0; i < eo.ranges.length; i++) {
      let er: any = eo.ranges[i];
      let keys = Object.keys(er);
      for (let j = 0; j < keys.length; j++) {
        if (!availableKeys.includes(keys[j])) return false;
      }
    }
    return true;
  };

  return (
    <div>
      <Modals />

      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => {
          if (!formik.dirty) handleCloseSideout();
        }}
        style={{
          minWidth: '600px',
        }}
        className="sidebarWidth"
      >
        {parentProtocol && (
          <>
            <div
              style={{
                position: 'absolute',
                left: `calc(-40vw - 20px)`, // Adjust for the sidebar width (40% of viewport) and the clip width (20px)
                top: '0',
                height: '100%',
                backgroundColor: '#fff',

                padding: '10px',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                width: `calc(100vw - 60vw)`, // Full width minus the sidebar width (40% of viewport) + 20% for clip
                overflowY: 'auto', // Allow scrolling if content overflows
              }}
            >
              <GeneralSelection
                items={['PDF', 'Show Device Mockup']}
                selectedIndex={pdfOrDeviceMock}
                onPress={(item, index) => {
                  setPdfOrDeviceMock(index);
                }}
                labelField={(option) => option}
                valueField={(option) => option}
              />
              {pdfOrDeviceMock === SHOW_DEVICE_MOCKUP && (
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    paddingTop: '20px',
                    alignItems: 'center',
                    height: '90vh',
                  }}
                >
                  <DeviceMockup
                    parentModel={parentModel as ElectricalItem}
                    formik={formik}
                    protocol={parentProtocol}
                  />
                </div>
              )}
              {pdfOrDeviceMock === SHOW_PDF && (
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    paddingTop: '20px',
                  }}
                >
                  <iframe
                    src={pdfUrl}
                    title="PDF Viewer"
                    width="100%"
                    style={{
                      border: 'none',
                      height: '90vh',
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <div style={{ padding: '0px 15px' }}>
          <p className="sidebarText">
            {dose ? 'Edit' : 'Create'} the information for{' '}
            {protocol ? protocol.name : ''} electricals's dose.
          </p>
          <p className="sidebarText">
            Need more information: <a className="sidebarLink">Learn More</a>
          </p>
        </div>
        {dose && isDeactivated && (
          <div style={{ marginTop: '15px' }}>
            <span
              className="ketamine-general-label"
              style={{
                marginBottom: 0,
                paddingBottom: 0,
                marginLeft: 0,
              }}
            >
              {dose.name} Shock is
              <span>
                <Status status="DEACTIVATED" />
              </span>
            </span>
            <p className="sidebarText">
              This does has been deactivated by {database.department.name}. So,
              it is hidden in OneDose for {database.department.name}
              {"'s "}
              users. This can be reactivated at any time.
            </p>
          </div>
        )}
        {!dose && (
          <div className="" style={{ padding: '0px 15px' }}>
            <span
              className="ketamine-general-label"
              style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
            >
              Input Type
            </span>
            <GeneralSelection
              items={
                user.type === 'ADMIN' && onSubmitElectrialOption
                  ? [
                      'Create New',
                      'Copy Dose',
                      'Copy From Protocol',
                      'Add EO Json',
                    ]
                  : ['Create New', 'Copy Dose', 'Copy From Protocol']
              }
              selectedIndex={inputType}
              onPress={(item, index) => {
                if (inputType === COPY_PROTOCOL_DOSE)
                  handleSelectCopyDose(null);
                else handleSelectCopyDose(null);
                setParentProtocol(protocol ? protocol : null);
                setInputType(index);
              }}
              labelField={(option) => option}
              valueField={(option) => option}
            />
          </div>
        )}

        {inputType === NEW_DOSE && (
          <>
            {!protocol && (
              <div className="" style={{ padding: '0px 15px' }}>
                <div style={{ marginTop: '15px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    This is the protocol that this dose will belong to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.uid}
                  disabled={!edittable}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              <div style={{ marginTop: '15px' }}>
                <span className="ketamine-general-label">
                  Dose {doseIndex + 1}
                </span>
              </div>

              <div className="sidebarInputContainer">
                <ElectricalDoseSelection
                  isEmergency={isEmergency}
                  formik={formik}
                  doseIndex={doseIndex}
                  parent={parentModel}
                  disabled={!edittable}
                  onRangeClick={(
                    range: string,
                    isLow: boolean,
                    isVital: boolean
                  ) => {
                    setRangeDialog(isLow ? 'low' : 'high');
                  }}
                  onBasisClick={(basis: string, parent: ElectricalItem) => {
                    setCalculationDialogVisible(true);
                  }}
                  onAgeRange={(age: PatientAge, isLow: boolean) => {
                    setAgeRangeDialog(isLow ? 'low' : 'high');
                  }}
                  onMinMaxDoseClick={(
                    value: string,
                    type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
                  ) => {
                    setMinMaxDialog(type);
                  }}
                  handleSubmit={(dose: any) => {
                    if (isSaveValid) handleSubmit();
                  }}
                />
              </div>
              {/* {(edittable || isEmergency) && (
                <>
                  <div
                    className="calculationDialogContent"
                    style={{ marginBottom: '0px' }}
                  >
                    <label
                      htmlFor="isSolidOnly"
                      className="departmentItemText"
                      style={{ flexDirection: 'row' }}
                    >
                      Emergency Note:
                      <InputSwitch
                        name="isSolidOnly"
                        style={{ marginLeft: '10px' }}
                        checked={isEmergency}
                        onChange={(e) => {
                          setIsEmergency(e.value);
                        }}
                      />
                    </label>
                  </div>
                  <p className="sidebarText" style={{ marginLeft: '12px' }}>
                    This will display a large red text for the medics and will
                    NOT show any electrical dose details.
                  </p>
                </>
              )} */}
            </div>
          </>
        )}

        {inputType === COPY_DOSE && (
          <>
            {!selectedDose && (
              <span
                className="ketamine-general-label"
                style={{
                  padding: '0px 15px',
                  marginTop: 10,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 0,
                  fontSize: '18px',
                }}
              >
                Select Dose to Copy From
              </span>
            )}
            {selectedDose && (
              <h6
                className="headerText hoverText"
                style={{ padding: '0px 15px', marginTop: 10 }}
                onClick={() => {
                  handleSelectCopyDose(null);
                }}
              >
                <span className="" style={{ marginRight: '8px' }}>
                  <BiArrowBack
                    className="header-icon"
                    data-testid="isBackBtn"
                  />
                </span>
                {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                Edit Copied Dose
                {/* </span> */}
              </h6>
            )}
            {!protocol && selectedDose && (
              <div
                className=""
                style={{ padding: '0px 15px', marginBottom: 20 }}
              >
                <div style={{ marginTop: '0px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    Select the protocol to add the doses to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.uid}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              {!selectedDose && <hr style={{ margin: 0, padding: 0 }} />}
              {allDoses.length === 0 && (
                <div style={{ marginTop: '15px' }}>
                  <p className="sidebarText">
                    There are no doses to copy from.
                  </p>
                </div>
              )}
              {selectedDose ? (
                <div className="sidebarInputContainer">
                  <ElectricalDoseSelection
                    isEmergency={isEmergency}
                    formik={formik}
                    doseIndex={doseIndex}
                    parent={parentModel}
                    disabled={!edittable}
                    onRangeClick={(
                      range: string,
                      isLow: boolean,
                      isVital: boolean
                    ) => {
                      setRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onAgeRange={(age: PatientAge, isLow: boolean) => {
                      setAgeRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onBasisClick={(basis: string, parent: ElectricalItem) => {
                      setCalculationDialogVisible(true);
                    }}
                    onMinMaxDoseClick={(
                      value: string,
                      type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
                    ) => {
                      setMinMaxDialog(type);
                    }}
                    handleSubmit={(dose: any) => {
                      if (isSaveValid) handleSubmit();
                    }}
                  />
                </div>
              ) : (
                <ViewportList items={allDoses}>
                  {(dose: ElectricalSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleSelectCopyDose(dose);
                        }}
                      >
                        <ElectricalDoseUI
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={true}
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </>
        )}

        {inputType === COPY_PROTOCOL_DOSE && (
          <>
            {!selectedProtocol && (
              <span
                className="ketamine-general-label"
                style={{
                  padding: '0px 15px',
                  marginTop: 10,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 0,
                  fontSize: '18px',
                }}
              >
                Select Protocol to copy doses from
              </span>
            )}
            {selectedProtocol && (
              <h6
                className="headerText hoverText"
                style={{ padding: '0px 15px', marginTop: 10 }}
                onClick={() => {
                  setSelectedProtocol(null);
                  setSelectedProtocolDoses([]);
                }}
              >
                <span className="" style={{ marginRight: '8px' }}>
                  <BiArrowBack
                    className="header-icon"
                    data-testid="isBackBtn"
                  />
                </span>
                {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                View Doses
                {/* </span> */}
              </h6>
            )}
            {!protocol && selectedProtocol && (
              <div
                className=""
                style={{ padding: '0px 15px', marginBottom: 20 }}
              >
                <div style={{ marginTop: '0px' }}>
                  <span
                    className="ketamine-general-label"
                    style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
                  >
                    Protocol
                  </span>
                  <p className="sidebarText">
                    Select the protocol to add the doses to.
                  </p>
                </div>
                <SearchableDropdown<ProtocolItem>
                  id="searchDropdown"
                  value={parentProtocol ? parentProtocol : undefined}
                  options={allProtocols}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.uid}
                  onChange={(option: ProtocolItem) => {
                    setParentProtocol(option);
                  }}
                  onClear={() => {
                    setParentProtocol(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search protocol..."
                />
              </div>
            )}
            <div className="siderbarContent">
              {!selectedDose && <hr style={{ margin: 0, padding: 0 }} />}
              {doseProtocols.length === 0 && (
                <div style={{ marginTop: '15px' }}>
                  <p className="sidebarText">
                    There are no protocols with {parentModel.name} doses.
                  </p>
                </div>
              )}
              {selectedProtocol ? (
                <>
                  {doseProtocols.length === 0 && (
                    <div style={{ marginTop: '15px' }}>
                      <p className="sidebarText">
                        The protocol does not have {parentModel.name} doses.
                      </p>
                    </div>
                  )}
                  <ViewportList items={selectedProtocolDoses}>
                    {(dose: ElectricalSubItem, index: number) => {
                      return (
                        <div
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handleSelectCopyDose(dose);
                          }}
                        >
                          <ElectricalDoseUI
                            dose={dose}
                            doseIndex={index + 1}
                            showProtocol={false}
                          />
                        </div>
                      );
                    }}
                  </ViewportList>
                </>
              ) : (
                <ViewportList items={doseProtocols}>
                  {(prot: ProtocolItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ borderBottom: '1px solid #ccc' }}
                      >
                        <div
                          className="departmentItem"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '4fr 1fr',
                          }}
                          onClick={() => {
                            setSelectedProtocol(prot);
                            let doses = allDoses.filter(
                              (dose: ElectricalSubItem) =>
                                dose.parentProtocol.uid === prot.uid
                            );
                            setSelectedProtocolDoses(doses);
                          }}
                        >
                          <h6 className="departmentItemText">{prot.name}</h6>
                          <h6
                            className="departmentItemText"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {findDoses(prot)}
                            <span>
                              <FaChevronRight
                                className="icon-normal "
                                style={{ margin: '4px' }}
                              />
                            </span>
                          </h6>
                        </div>
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </>
        )}

        {inputType === ADMIN_MED_PROTOCOL && (
          <div className="siderbarContent">
            <label htmlFor="type" className={`notification-css-title`}>
              Electrical Shock Option JSON
            </label>
            {mpError && (
              <div className="errorText" style={{ marginBottom: '10px' }}>
                {mpError}
              </div>
            )}
            <InputTextarea
              className="form-control-general"
              id="message"
              name="message"
              required={true}
              data-testid="message"
              value={electricalOption}
              autoFocus
              onChange={(e: any) => {
                if (e.target.value === '') {
                  setMpError('');
                  setElectricalOption(e.target.value);
                  return;
                }
                try {
                  let mp = JSON.parse(e.target.value);
                  /* Check if the JSON is valid MedicationProtocol type */
                  if (validateElectricalOption(mp)) {
                    setElectricalOption(e.target.value);
                    setMpError('');
                  } else {
                    setMpError('Invalid Medication Protocol JSON');
                  }
                } catch (error: any) {
                  setElectricalOption(e.target.value);
                  setMpError('Invalid Medication Protocol JSON');
                }
              }}
              style={{ height: '110px', verticalAlign: 'top' }}
            />
          </div>
        )}

        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleCloseSideout}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>
          {!isOwner ? (
            <>
              {isDeactivated ? (
                <Button
                  className="primary-button"
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsBlockModal(true);
                  }}
                >
                  <span>
                    <BsCloudFill className="icon-normal" />
                  </span>{' '}
                  Reactivate
                </Button>
              ) : (
                <Button
                  className="red-background-button btn-rightMargin "
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsBlockModal(true);
                  }}
                >
                  <span>
                    <BsCloudSlashFill className="icon-normal" />
                  </span>{' '}
                  Deactivate
                </Button>
              )}
            </>
          ) : (
            <>
              {editDose && (
                <Button
                  className="red-background-button btn-rightMargin "
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsDeleteModal(true);
                  }}
                >
                  {dose?.status === 'DRAFT_DELETE' ? 'Restore' : 'Delete'}
                </Button>
              )}
              <Button
                className="primary-button"
                data-testid="saveBtn"
                onClick={handleSubmit}
                disabled={!isSaveValid}
              >
                <span>
                  <AiOutlinePlus className="icon-normal" />
                </span>{' '}
                {buttonTitle}
              </Button>
            </>
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default ElectricalShockSideout;
