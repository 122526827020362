import { Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import { useState, forwardRef, useMemo, useEffect } from 'react';

const HinckleyCheckbox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#00534C',
    '&:hover': {
      backgroundColor: alpha('#00534C', theme.palette.action.hoverOpacity),
    },
  },
  '&:hover': {
    backgroundColor: alpha('#ffcc00', theme.palette.action.hoverOpacity), // Color when hovering over the checkbox
  },
  '& .MuiCheckbox-root': {
    backgroundColor: '#ccc', // Default color
  },
}));

const label = { inputProps: { 'aria-label': 'Hinckley Medical Checkbox' } };

interface CheckboxProps {
  checked?: boolean;
  tooltipText?: string;
  indeterminate?: boolean; // Add indeterminate prop
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

const HMCheckbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const [checked, setChecked] = useState(props.checked || false);
  const intermediate = useMemo(
    () => props.indeterminate,
    [props.indeterminate]
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked); // Update the internal state
    if (props.onChange) {
      props.onChange(event, checked); // Call the external onChange if passed
    }
  };

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  return props.tooltipText ? (
    <Tooltip title={props.tooltipText} arrow placement="right">
      <HinckleyCheckbox
        {...label}
        checked={checked} // Controlled state
        onChange={handleChange} // Detect checkbox change
        indeterminate={intermediate}
        // ref={ref} // Forward the ref
      />
    </Tooltip>
  ) : (
    <HinckleyCheckbox
      {...label}
      checked={checked} // Controlled state
      onChange={handleChange} // Detect checkbox change
      indeterminate={intermediate}
      // ref={ref} // Forward the ref
    />
  );
});

export default HMCheckbox;
