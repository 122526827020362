import React from 'react';
import { InputText } from 'primereact/inputtext';
import { LiaHeartbeatSolid, LiaWeightHangingSolid } from 'react-icons/lia';
import { globals, mapIndexToRange } from '../../../../_global/common/Utils';
import { VitalOption } from '../../../../../models';
import { formatForMatchingUnits } from '../../../../../data/model/EquipmentItem';

interface VitalDoseProps {
  dose?: VitalOption | any;
  doseIndex: number;
  isEditMode?: boolean;
}

const VitalDose: React.FC<VitalDoseProps> = (props) => {
  const { dose, doseIndex, isEditMode } = props;
  const rangeLowString =
    dose.rangeLow !== '' && dose.rangeLow !== 'MIN'
      ? mapIndexToRange(Number(dose.rangeLow))
      : 'MIN';
  const rangeHighString =
    dose.rangeHigh !== '' && dose.rangeHigh !== 'MAX'
      ? mapIndexToRange(Number(dose.rangeHigh))
      : 'MAX';

  return (
    <div>
      <div className="addDoseHeader">
        <span className="ketamine-general-label">Dose {doseIndex + 1}</span>
      </div>
      <div
        className={`model-input-container  ${
          isEditMode ? 'cursorPointerDiv hoverableBackground' : ''
        }`}
      >
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      !dose.rangeLow ? 'greyText' : ''
                    }`}
                    placeholder="Min"
                    value={rangeLowString}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor={''}
                  className={` ketamine-label cursorPointerDiv`}
                >
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${
                      Number(dose.rangeLow) === globals.MAX_VALUE
                        ? 'greyText'
                        : ''
                    }`}
                    placeholder="Max"
                    value={rangeHighString}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="ketamine-input-content-equipment">
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              <LiaHeartbeatSolid className="protocol-icons " />
            </div>
            <div className="form-group">
              <label htmlFor="value" className="ketamine-label-equipment">
                Value:
              </label>
              <div className="p-input-icon-right">
                <InputText
                  type="text"
                  className={`form-control btn-rightMargin  pointer inputHover`}
                  value={formatForMatchingUnits(dose?.amntLow, dose?.amntHigh)}
                  placeholder=""
                  readOnly={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalDose;
