import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import '../../components/Popup.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';

interface TextConfirmModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (comment: string) => void;
  isDeleteBtn?: any;
  isPermanentlyDeleteBtn?: any;
  title: any;
  primaryDescription?: any;
  secondaryDescription?: any;
  primaryBtnName?: any;
  secondaryBtnName?: any;
  secondaryBtnColor?: any;
  isChecked?: boolean;
  isSingleBtn?: boolean;
  inputType?: string;
  prefilledInput?: string;
  required?: boolean;
}

const TextConfirmModal: React.FC<TextConfirmModalProps> = (props: any) => {
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const {
    primaryBtnName,
    secondaryBtnName,
    isVisible,
    handleClose,
    handleSubmit,
    handleCheck,
    isNewBtn,
    isDeleteBtn,
    title,
    primaryDescription,
    secondaryDescription,
    isChecked,
    isPermanentlyDeleteBtn,
    isSingleBtn,
    inputType,
    secondaryBtnColor,
    prefilledInput,
    required,
  } = props;
  const [checked, setChecked] = useState(isChecked ? isChecked : false);
  const [inputChange, setInputChange] = useState(
    prefilledInput ? prefilledInput : ''
  );
  useEffect(() => {
    setInputChange(prefilledInput);
  }, [prefilledInput]);
  const customHeader = (
    <div className="pointer" style={{ marginLeft: '-10px' }}>
      <h4 className="headerText">{title}</h4>
    </div>
  );
  const handleInput = (e: any) => {
    setInputChange(e.target.value);
  };

  const submit = () => {
    setInputChange('');
    handleSubmit(inputChange);
  };

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{ width: '40vw', maxWidth: '600px', backgroundColor: 'white' }}
        onHide={handleClose}
        header={customHeader}
        className="confirmDialog routeConfirmModal"
      >
        <div style={{ background: 'white' }}>
          <div className="">
            {primaryDescription && (
              <div className="contentText contentTextBlackColor">
                {primaryDescription}
              </div>
            )}
            {secondaryDescription && (
              <div
                className="contentText contentTextBlackColor"
                style={{
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
              >
                {secondaryDescription}
              </div>
            )}

            {inputType && (
              <div style={{ marginTop: '10px' }}>
                <textarea
                  onChange={handleInput}
                  value={inputChange}
                  autoFocus
                  style={{
                    width: '100%',
                    height: '100px',
                    padding: '10px',
                    boxSizing: 'border-box',
                    resize: 'none',
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.key === 'Enter' &&
                      !e.shiftKey &&
                      !e.ctrlKey &&
                      !e.metaKey
                    ) {
                      e.preventDefault();
                      submit();
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <div
              className="dialogButtons"
              style={{ marginTop: '20px', alignItems: 'flex-end' }}
            >
              {!isSingleBtn && (
                <Button
                  data-testid="cancel-Bttn"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleClose}
                >
                  {' '}
                  {primaryBtnName}
                </Button>
              )}
              {!isDeleteBtn && (
                <Button
                  data-testid="set-Bttn"
                  className="primary-button btn-rightMargin"
                  onClick={() => submit()}
                  disabled={inputChange === '' && required}
                  style={{
                    backgroundColor: secondaryBtnColor
                      ? secondaryBtnColor
                      : '#00534C',
                    color: 'white',
                  }}
                >
                  {secondaryBtnName}
                </Button>
              )}
              {isDeleteBtn && (
                <Button
                  data-testid="delete-Bttn"
                  className="red-background-button btn-rightMargin "
                  onClick={submit}
                >
                  {secondaryBtnName}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TextConfirmModal;
