import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { ViewportList } from 'react-viewport-list';
import { useSelector } from 'react-redux';
import { User } from '../../../models';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import SearchableDropdown from '../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';

interface AddReviewSideoutProps {
  handleUpdateReviewer: (reviewers: User[]) => void;
  handleClose: () => void;
  isVisible?: boolean;
  users?: User[];
  type?: any;
}

const ReviewUserSideout: React.FC<AddReviewSideoutProps> = (props) => {
  const { isVisible, handleUpdateReviewer, handleClose, users, type } = props;
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>([]);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((reviewal: any) => reviewal.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state.user);
  const [allUsers, setAllUsers] = useState<any>(
    database.users.filter((u: User) => u.id !== user.id)
  );
  const [filteredUsers, setFilteredUsers] = useState<any>([]);
  useEffect(() => {
    if (users) {
      setOptions(users);
    }
  }, [users]);

  useEffect(() => {
    // users that are not in all users
    const filteredUsers = allUsers.filter(
      (user: User) => !options.some((option: User) => option.id === user.id)
    );
    setFilteredUsers(filteredUsers);
  }, [allUsers, options]);

  const handleAddReviwer = (reviewer: User) => {
    setOptions([...options, reviewer]);
  };

  const handleRemoveReviewer = (reviewer: User) => {
    const newOptions = options.filter(
      (option: User) => option.id !== reviewer.id
    );
    setOptions(newOptions);
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <BiArrowBack className="header-icon" style={{}} />{' '}
        {type === 'review' ? 'Reviewers List' : ' Acknowledge List'}{' '}
      </span>
    </div>
  );

  return (
    <div>
      <Sidebar
        header={customHeader}
        visible={isVisible}
        position="right"
        onHide={() => {
          handleClose();
        }}
        style={{ width: '25%' }}
        className={'sidebarWidth'}
      >
        <div style={{ padding: '0px 15px', height: '100%' }}>
          <div className="contentText">
            {type === 'review'
              ? 'Add or remove the reviewers for this protocol.'
              : 'All the users that have acknowledged this notification in the OneDose application.'}
          </div>
          {/* <div className="search-custom-input">
                    <span className='icon_search_pad'><FiSearch className='icon-normal' /></span>
                    <InputText value={value} onChange={handleSearch} className="search-inputs" />
                </div> */}
          <SearchableDropdown
            id="searchDropdown"
            options={filteredUsers}
            labelField={(option) => option.firstName + ' ' + option.lastName}
            valueField={(option) => option.firstName + ' ' + option.lastName}
            keyField={(option) => option.id}
            multiSelect={true}
            onChange={(option: User) => handleAddReviwer(option)}
            onClear={() => {}}
            placeholder="Search Reviewers..."
          />
          <div className="contentLabel" style={{ display: 'block' }}>
            <div className="contentLabel" style={{ fontSize: '15px' }}>
              Users
            </div>
          </div>
          <div className="contentBorder protocolCalculationPad reviewListScroll">
            <ViewportList items={options}>
              {(item: any, index: any) => (
                <div
                  key={item + index}
                  className={`listhover spaceBetween cursorPointer item contentHeading ${options.length !== index + 1 ? 'contentUnderline' : ''}`}
                >
                  <div>
                    {item?.firstName} {item?.lastName}
                  </div>

                  <div>
                    <FaTimes
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => handleRemoveReviewer(item)}
                    />
                  </div>
                </div>
              )}
            </ViewportList>
          </div>
          <div className="btn_Bottom">
            {/* <Button className="secondary-button" onClick={handleClose}>
              Dismiss
            </Button> */}
            <Button
              className="primary-button"
              disabled={options.length === 0}
              onClick={() => handleUpdateReviewer(options)}
            >
              Save
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ReviewUserSideout;
