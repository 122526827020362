import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Loading from '../../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  DatabaseResponse,
  fetchCategoriesWithProtocols,
  getDepartments,
  loadDatabase,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { handleGetDepartment } from '../../../../../../store/actions';
import { handleCopy } from '../../../../../_global/common/Utils';
import { set } from 'lodash';
import { ViewportList } from 'react-viewport-list';
import ElectricalSubItem from '../../../../../../data/model/ElectricalSubItem';
import ElectricalItem from '../../../../../../data/model/ElectricalItem';
import { DataStore } from 'aws-amplify';
import {
  ElectricalDose,
  ElectricalShockOption,
  ElectricalShockRange,
} from '../../../../../../models';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import {
  convertAllElectricalShockRangestoElectricalDoses,
  convertToNewDatabaseLogic,
} from '../../../../../../data/AmplifyActions';
import { fetchElectrical } from '../../../../../../data/functions/ElectricalDB';

type ElectricalDoseType = {
  dose: ElectricalDose;
  protocol: ProtocolItem;
  electrical: ElectricalItem;
};
type OldElectricalDoseType = {
  dose: ElectricalShockRange;
  protocol: ProtocolItem;
  electrical: ElectricalItem;
};
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const MigrateElectricalDoses = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    database: undefined as DatabaseResponse | undefined,
    flattendMeds: [] as OldElectricalDoseType[],
    newStyleMeds: [] as ElectricalDose[],
  });

  const handleBack = () => {
    navigate('/actions');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
    }
  };

  const loadData = async (department: DepartmentItem) => {
    let promises = [
      fetchCategoriesWithProtocols(department),
      fetchElectrical(department),
      DataStore.query(ElectricalDose, (c) => c.departmentID.eq(department.id)),
    ];
    let [catResp, electricalResp, doses]: any = await Promise.all(promises);
    if (
      catResp.type === ResponseType.Success &&
      electricalResp.type === ResponseType.Success
    ) {
      let protocols: ProtocolItem[] = catResp.data[1];
      let electricals = electricalResp.data;
      let allOldDoses: OldElectricalDoseType[] = [];
      for (let i = 0; i < electricals.length; i++) {
        let elec: ElectricalItem = electricals[i];
        for (let j = 0; j < elec.options.length; j++) {
          let eo: ElectricalShockOption = elec.options[j];
          let protocol = protocols.find((p: ProtocolItem) => {
            let protocolID = p.activeID ? p.activeID : p.uid;
            return protocolID === eo.protocolID;
          });
          if (protocol == null) {
            console.error('Protocol not found for:', eo);
            continue;
          }
          for (let k = 0; k < eo.ranges.length; k++) {
            let range = eo.ranges[k];
            allOldDoses.push({
              dose: range,
              protocol: protocol,
              electrical: elec,
            });
          }
        }
      }

      let newStyleMeds: ElectricalDoseType[] = [];
      console.log('Electricals:', electricals);
      console.log('Doses:', doses);
      for (let i = 0; i < doses.length; i++) {
        let d: ElectricalDose = doses[i];
        let med = electricals.find((m: ElectricalItem) => {
          let medID = m.activeID ? m.activeID : m.uid;
          return medID === d.electricalID;
        });
        console.log('Ifusion:', med);
        let protocol = protocols.find((p: ProtocolItem) => {
          let protocolID = p.activeID ? p.activeID : p.uid;
          return protocolID === d.protocolID;
        });
        console.log('Protocol:', protocol);
        if (med && protocol) {
          newStyleMeds.push({ dose: d, electrical: med, protocol: protocol });
        } else {
          console.error('Electrical or Protocol not found for dose:', d);
        }
      }
      console.log('Doses:', doses);
      setInfo({
        department: department,

        flattendMeds: allOldDoses,
        newStyleMeds: newStyleMeds,
      });
    }
    setIsLoading(null);
  };

  const handlePerformAudit = async () => {
    console.log('Perming Audit');
    convertAllElectricalShockRangestoElectricalDoses(info.department)
      .then((response) => {
        console.log('Response:', response);
        if (response.type === ResponseType.Success) {
          let medDoses = response.data;
          setModalState({
            isVisible: true,
            title: 'Successfully Created Electrical Doses',
            primaryBtnName: 'Okay',
            secondaryBtnName: 'Okay',
            primaryDescription:
              'Successfully created ' +
              medDoses.length +
              ' Electrical Doses for ' +
              info.department?.name,
          });
          // setInfo({
          //   ...info,
          //   newStyleMeds: medDoses,
          // });
          loadData(info.department);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isSaveValid = useMemo(() => {
    return info.department !== undefined;
  }, [info]);

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          if (modalState.title === 'Audit Required') {
            setModalState({ ...modalState, isVisible: false });
          } else {
            setModalState({ ...modalState, isVisible: false });
            navigate('/database');
          }
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={'Audit/Migrate Electrical to Electrical Doses'}
        description={
          'This will loop through every Electrical saved in the old style of MedicartionProtocol & Electrical Range to the new style (Add GOATED) of ElectricalDose'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Run"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit();
        }}
      />
      <label className="ketamine-general-label">
        Department: <span className="required-field">*</span>
      </label>
      <p className="sidebarText" style={{ marginLeft: '10px' }}>
        Select the department you want to perform the audit on.
      </p>
      <SearchableDropdown<DepartmentItem>
        id="searchDropdown"
        options={departments}
        labelField={(option) => option.name}
        valueField={(option) => option.name}
        keyField={(option) => option.id}
        onChange={(option: DepartmentItem) => {
          setIsLoading('Loading ' + option.name + ' Data...');
          loadData(option);
        }}
        onClear={() => {
          setInfo({
            department: undefined,
            database: undefined,
          });
        }}
        placeholder="Search department..."
      />

      {info.department && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {info.department && info.department.logoVerifiedUrl && (
              <img
                className="large-dep-logo"
                src={info.department.logoVerifiedUrl}
                alt="Agency Logo"
              />
            )}
            {info.department && (
              <div
                className="large-dep-name"
                style={{
                  marginLeft: '2rem',
                  marginRight: '6px',
                }}
              >
                {info.department.name}
              </div>
            )}
          </div>
          <div
            className="input-container roundBorder "
            style={{
              marginLeft: '1rem',
              marginRight: '1rem',
              marginTop: '1rem',
            }}
          >
            <div
              className="ketamine-general-label"
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <div style={{ marginRight: '10px' }}>ID:</div>
              <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                {info.department.id}
                <span>
                  {isCopied && isCopied === info.department.id ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(info.department.id, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              className="input-container roundBorder "
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <h4
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                Old Style Doses:{' '}
                <span style={{ color: '#636363' }}>
                  {info.flattendMeds.length}
                </span>
              </h4>
              <ViewportList items={info.flattendMeds}>
                {(item: OldElectricalDoseType, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{ fontSize: '12px', color: '#636363' }}>
                          {item.protocol.name}
                        </div>
                        <div style={{}}>{item.electrical.name}</div>
                        <div style={{ fontSize: '12px', color: '#636363' }}>
                          {item.electrical.uid}
                        </div>
                      </div>
                      <div>{item.dose.basis}</div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
            <div
              className="input-container roundBorder "
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <h4
                style={{
                  borderBottom: '1px solid #e0e0e0',
                  padding: '10px',
                  fontWeight: 'bold',
                  fontSize: '1.2rem',
                }}
              >
                New Style Doses:{' '}
                <span style={{ color: '#636363' }}>
                  {info.newStyleMeds.length}
                </span>
              </h4>
              <ViewportList items={info.newStyleMeds}>
                {(item: ElectricalDoseType, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '10px',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div style={{ fontSize: '12px', color: '#636363' }}>
                          {item.protocol.name}
                        </div>
                        <div style={{}}>{item.electrical.name}</div>
                        <div style={{ fontSize: '12px', color: '#636363' }}>
                          {item.electrical.uid}
                        </div>
                      </div>
                      <div>{item.dose.basis}</div>
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MigrateElectricalDoses;
