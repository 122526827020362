import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoAdd, IoClose } from 'react-icons/io5';
import React, { useEffect, useMemo } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import SearchableDropdown from '../SearchableDropdown';
import OwnerImage from '../OwnerImage/OwnerImage';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { MdAddToPhotos } from 'react-icons/md';
import { FaSquareMinus } from 'react-icons/fa6';
interface UnlockKeychainModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (departments: DepartmentItem[]) => void;
  department: DepartmentItem;
  values?: DepartmentItem[];
  availableDepartments?: DepartmentItem[];
  title?: string;
  description?: string;
  includeOwner?: boolean;
  canRemoveOwner?: boolean;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const SelectDepartmentsModal: React.FC<UnlockKeychainModalProps> = (
  props: UnlockKeychainModalProps
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    department,
    values,
    availableDepartments,
    description,
    includeOwner,
    canRemoveOwner,
    title,
    buttonText,
    buttonIcon,
  } = props;

  const [state, setState] = React.useState<DepartmentItem[]>(values ?? []);

  useEffect(() => {
    if (isVisible && values) {
      let list = includeOwner === false ? [...values] : [department, ...values];
      setState(
        list.filter(
          (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
        )
      );
    }
  }, [isVisible, values, includeOwner, department]);

  // const isSaveValid = useMemo(() => {
  //   return state.length > 0;
  // }, [state]);

  const fullDepartmentList = useMemo(() => {
    let list: DepartmentItem[] = [];
    if (availableDepartments) list = [...availableDepartments];
    else list = [...(department.allSubDeps ?? [])];
    if (includeOwner !== false) list = [department, ...list];
    //Filter out any duplicates
    list = list.filter(
      (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
    );
    return list;
  }, [department, availableDepartments, includeOwner]);

  const departmentList = useMemo(() => {
    let deps = fullDepartmentList.filter(
      (dep) => !state.find((s) => s.id === dep.id)
    );
    return deps;
  }, [state, fullDepartmentList]);

  const isButtonValid = useMemo(() => {
    if (canRemoveOwner === false && state.length === 0) return false;
    else return true;
    // let _values =
    //   includeOwner === false ? (values ?? []) : [department, ...(values ?? [])];
    // _values = _values.filter(
    //   (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
    // );
    // if (state.length !== _values.length) return true;
    // console.log(state, _values);
    // for (let i = 0; i < state.length; i++) {
    //   if (!_values.find((v) => v.id === state[i].id)) return true;
    // }
    // return false;
  }, [state, values, includeOwner, canRemoveOwner, department]);

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          minWidth: '800px',
          minHeight: '500px',
          // flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '500px',
        }}
        onHide={() => {
          setState(values ?? []);
          handleClose();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '3rem' }}
        >
          <h4
            className="keychain-title"
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <OwnerImage owner={department} size={40} />
            <span
              style={{
                // marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              {title ?? 'Select Departments'}
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={() => {
                setState(values ?? []);
                handleClose();
              }}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <p
            className="departmentItemText"
            style={{
              fontSize: '0.9rem',
              color: 'black',
              margin: '10px 20px',
              // maxWidth: '50%',
            }}
          >
            {description
              ? description
              : `Select the departments that will have access to this folder. ${
                  department.name
                } will have access by default.`}
          </p>
          {department && (
            <div
              style={{
                padding: '0 1.25rem',
              }}
            >
              <label
                htmlFor=""
                className={`notification-css-title`}
                style={{
                  alignItems: 'center',
                }}
              >
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Selected Departments: {state.length + '/'}
                  {fullDepartmentList.length}
                  {/* <span onClick={() => setState(fullDepartmentList)}>
                    <div className="clickableText">Add All</div>
                  </span> */}
                  {state.length === fullDepartmentList.length ? (
                    <Tooltip title={'Remove All'} placement="bottom" arrow>
                      <span
                        className={`iconButton`}
                        style={{
                          marginLeft: '6px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (canRemoveOwner) setState([]);
                          else setState([department]);
                        }}
                      >
                        <FaSquareMinus
                          data-testid="isFullScreenButton"
                          className="icon"
                          style={{
                            padding: '6px',
                            color: '#8CB181',
                          }}
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Add All'} placement="bottom" arrow>
                      <span
                        className={`iconButton`}
                        style={{
                          marginLeft: '6px',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setState(fullDepartmentList);
                        }}
                      >
                        <MdAddToPhotos
                          data-testid="isFullScreenButton"
                          className="icon"
                          style={{
                            padding: '6px',
                            color: '#8CB181',
                          }}
                        />
                      </span>
                    </Tooltip>
                  )}
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that is under{' '}
                {department.name}.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  setState([...state, option])
                }
                onClear={() => {
                  setState([]);
                }}
                placeholder="Search department..."
              />
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList
                  items={includeOwner === false ? state : [...state]}
                >
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === state.length - 1 ? '' : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      {(canRemoveOwner || item.id !== department.id) && (
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => {
                            e.stopPropagation();
                            setState(state.filter((dep) => dep.id !== item.id));
                          }}
                        />
                      )}
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
        <div className="dialogButtonsForce">
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isButtonValid}
            onClick={() => {
              handleSubmit(state);
              handleClose();
            }}
          >
            <span>{buttonIcon ?? <IoAdd size="1rem" />}</span>{' '}
            {buttonText ?? 'Add'}
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SelectDepartmentsModal;
