import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { copyChecklistsFromDeptoDep } from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin } from '../../../../_global/common/Encrypt';
import { IoLockClosed } from 'react-icons/io5';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import Dropdown from '../../../../components/Dropdown/Dropdown';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const EncryptionPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const [info, setInfo] = useState<any>({
    salt: '',
    pin: '',
    hash: '',
    type: 'MD5',
  });

  const handleBack = () => {
    navigate('/actions');
  };

  const handleEncrypt = async () => {
    if (info.salt === '' || info.pin === '' || info.type === '') {
      alert('Please enter salt and pin');
      return;
    }
    const encrypt = getHashedPin(info.pin, info.salt, info.type);
    setInfo({ ...info, hash: encrypt });
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const isSaveValid = useMemo(() => {
    return info.salt !== '' && info.pin !== '';
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Encryption Page'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Row>
        <Col sm={6}>
          {/* Left column */}

          <label className="ketamine-general-label" style={{}}>
            Pin: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter in the pin or password that you want to encrypt.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              data-testid="action"
              name="pin"
              value={info.pin}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' && isSaveValid) handleEncrypt();
              }}
              onChange={(e: any) => {
                if (info.hash !== '')
                  setInfo({ ...info, hash: '', pin: e.target.value });
                else setInfo({ ...info, pin: e.target.value });
              }}
            />
            <div className="input-border"></div>
          </div>
          <label
            className="ketamine-general-label"
            style={{
              marginTop: '20px',
            }}
          >
            Salt Pin: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter in the salt pin to encrypt the data.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="salt"
              data-testid="action"
              name="salt"
              value={info.salt}
              onKeyDown={(e: any) => {
                if (e.key === 'Enter' && isSaveValid) handleEncrypt();
              }}
              onChange={(e: any) => {
                if (info.hash !== '')
                  setInfo({ ...info, hash: '', salt: e.target.value });
                else setInfo({ ...info, salt: e.target.value });
              }}
            />
            <div className="input-border"></div>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '24px',
            }}
          >
            <Dropdown
              value={info.type}
              options={[
                { label: 'MD5', value: 'MD5' },
                { label: 'SHA256', value: 'SHA256' },
                { label: 'SHA512', value: 'SHA512' },
              ]}
              onChange={(value: any) => {
                setInfo({ ...info, type: value });
              }}
              style={{
                marginTop: '-6px',
                padding: 0,
              }}
              buttonColor={'#E0EADD'}
              textColor={'#00534C'}
            />
            <Button
              disabled={!isSaveValid}
              className="primary-button"
              onClick={handleEncrypt}
            >
              <span>
                <IoLockClosed className="icon-normal" size="1rem" />
              </span>{' '}
              Encrypt
            </Button>
          </div>
        </Col>
        <Col sm={6}></Col>
        {info.hash !== '' && (
          <div
            style={{
              marginTop: '20px',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Hash Type:
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              {info.type}
              {isCopied !== info.type && (
                <BiCopy
                  size="1rem"
                  className="refresh-icon"
                  onClick={(e) => handleCopy(info.type, e)}
                />
              )}
              {isCopied === info.type && (
                <BiSolidCopy
                  color={'#00534C'}
                  size="1rem"
                  className="refresh-icon"
                />
              )}
            </p>
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Hashed Pin:
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              {info.hash}
              {isCopied !== info.hash && (
                <BiCopy
                  size="1rem"
                  className="refresh-icon"
                  onClick={(e) => handleCopy(info.hash, e)}
                />
              )}
              {isCopied === info.hash && (
                <BiSolidCopy
                  color={'#00534C'}
                  size="1rem"
                  className="refresh-icon"
                />
              )}
            </p>
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Salt Pin:
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              {info.salt}
              {isCopied !== info.salt && (
                <BiCopy
                  size="1rem"
                  className="refresh-icon"
                  onClick={(e) => handleCopy(info.salt, e)}
                />
              )}
              {isCopied === info.salt && (
                <BiSolidCopy
                  color={'#00534C'}
                  size="1rem"
                  className="refresh-icon"
                />
              )}
            </p>
          </div>
        )}
      </Row>
    </div>
  );
};

export default EncryptionPage;
