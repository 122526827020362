import PatientWeight from '../../ui/_global/common/PatientWeight';
import { getObjectDifference } from '../../ui/_global/common/Utils';
import { DatabaseResponse } from '../AmplifyDB';
import UserCommentItem from './UserCommentItem';
import AcknowledgeItem, { cloneAcknowledgeItem } from './AcknowledgeItem';
import DraftGroupItem from './DraftGroupItem';
import ModelItem from './ModelItem';
import ModelInterface from './ModelInterface';
import { Acknowledge, DraftChange } from '../../API';
import { User } from '../../models';
import { findUserComments } from '../functions/ReviewalDB';
import ModelSubItem from './ModelSubItem';

export enum DraftChangeType {
  FOLDER = 'FOLDER',
  PROTOCOL = 'PROTOCOL',
  PROTOCOL_INDEX = 'PROTOCOL_INDEX',
  PROTOCOL_PDF = 'PROTOCOL_PDF',
  MEDICATION = 'MEDICATION',
  MEDICATION_DOSE = 'MEDICATION_DOSE',
  MEDICATION_INDEX = 'MEDICATION_INDEX',
  INFUSION = 'INFUSION',
  INFUSION_DOSE = 'INFUSION_DOSE',
  INFUSION_INDEX = 'INFUSION_INDEX',
  ELECTRICAL = 'ELECTRICAL',
  ELECTRICAL_INDEX = 'ELECTRICAL_INDEX',
  ELECTRICAL_SHOCK = 'ELECTRICAL_SHOCK',
  EQUIPMENT = 'EQUIPMENT',
  VITAL = 'VITAL',
  CHECKLIST = 'CHECKLIST',
  UNKNOWN = 'UNKNOWN',
  CPR = 'CPR',
}

export function cloneDraftChangeItem(
  item: DraftChangeItem,
  db: DatabaseResponse,
  curUser?: User
): DraftChangeItem {
  let newItem = new DraftChangeItem(
    item.draftGroup,
    item.draftChange,
    db,
    curUser
  ) as DraftChangeItem;
  newItem.changeItem = item.changeItem;
  newItem.previousItem = item.previousItem;
  newItem.reviewACK = item.reviewACK
    ? cloneAcknowledgeItem(item.reviewACK)
    : null;
  newItem.comments = item.comments;
  return newItem;
}

export function getChangeType(type: string): DraftChangeType {
  switch (type) {
    case 'FOLDER':
      return DraftChangeType.FOLDER;
    case 'PROTOCOL':
      return DraftChangeType.PROTOCOL;
    case 'PROTOCOL_PDF':
      return DraftChangeType.PROTOCOL_PDF;
    case 'PROTOCOL_INDEX':
      return DraftChangeType.PROTOCOL_INDEX;
    case 'MEDICATION':
      return DraftChangeType.MEDICATION;
    case 'MEDICATION_DOSE':
      return DraftChangeType.MEDICATION_DOSE;
    case 'MEDICATION_INDEX':
      return DraftChangeType.MEDICATION_INDEX;
    case 'INFUSION':
      return DraftChangeType.INFUSION;
    case 'INFUSION_DOSE':
      return DraftChangeType.INFUSION_DOSE;
    case 'INFUSION_INDEX':
      return DraftChangeType.INFUSION_INDEX;
    case 'ELECTRICAL':
      return DraftChangeType.ELECTRICAL;
    case 'ELECTRICAL_SHOCK':
      return DraftChangeType.ELECTRICAL_SHOCK;
    case 'ELECTRICAL_INDEX':
      return DraftChangeType.ELECTRICAL_INDEX;
    case 'EQUIPMENT':
      return DraftChangeType.EQUIPMENT;
    case 'VITAL':
      return DraftChangeType.VITAL;
    default:
      return DraftChangeType.UNKNOWN;
  }
}

class DraftChangeItem extends ModelItem<DraftChange> implements ModelInterface {
  draftGroup: DraftGroupItem | undefined;
  draftChange: DraftChange;
  comments: UserCommentItem[] = [];
  changeItem: ModelItem<any> | ModelSubItem<any> | null | undefined;
  previousItem: ModelItem<any> | ModelSubItem<any> | null | undefined;
  changeType: DraftChangeType;
  acknowledgements: AcknowledgeItem[] = [];
  reviewACK: AcknowledgeItem | null | undefined;
  isClosed: boolean = false;

  constructor(
    draftGroup: DraftGroupItem | undefined,
    draftChange: any,
    db?: DatabaseResponse,
    curUser?: User,
    isClone?: boolean
  ) {
    super(draftChange, draftChange.id, draftChange.departmentID);
    this.TAG = 'DraftChangeItem';
    this.draftGroup = draftGroup;
    this.draftChange = draftChange;
    this.changeType = getChangeType(draftChange.changeType);
    this.comments = [];
    this.isClosed = draftChange.isClosed;

    if (db) {
      if (draftChange.Comments) {
        let items = draftChange.Comments.items;
        items.forEach((item: any) => {
          let user = db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for comment', item);
            return;
          }
          this.comments.push(new UserCommentItem(item, this, user, []));
        });
      }
      if (draftChange.Acknowledges) {
        let items = draftChange.Acknowledges.items;
        items.forEach((item: Acknowledge) => {
          if (item._deleted) return;
          let user =
            curUser && item.userID === curUser.id
              ? curUser
              : db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for Acknowledgement', item);
            return;
          }
          let ack = new AcknowledgeItem(item, this, user);
          findUserComments(db, ack);
          if (curUser && item.userID === curUser.id) this.reviewACK = ack;
          this.acknowledgements.push(ack);
        });
      }
    }
  }

  getChangeModelItem(
    itemID: string,
    type: DraftChangeType,
    db: DatabaseResponse
  ): ModelItem<any> | undefined {
    switch (type) {
      case DraftChangeType.FOLDER:
        return db.categories.find((f) => f.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.PROTOCOL:
        return db.protocols.find((p) => p.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.PROTOCOL_PDF:
        return db.protocols.find((p) => p.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.MEDICATION:
        return db.medications.find((m) => m.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.INFUSION:
        return db.infusions.find((i) => i.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.ELECTRICAL:
        return db.electrical.find((e) => e.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.EQUIPMENT:
        return db.equipment.find((e) => e.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.VITAL:
        return db.vitals.find((v) => v.uid === itemID) as
          | ModelItem<any>
          | undefined;
      default:
        return undefined;
    }
  }

  getName(): string {
    return this.changeType + ' -> ' + this.uid;
  }

  getAmount(weight: PatientWeight): string {
    return 'N/A';
  }

  getUid(): string {
    return this.uid;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof DraftChangeItem)) return false;
    return getObjectDifference(this, obj).length === 0;
  }

  toString(): string {
    return `DraftChangeItem -> {
            uid=${this.uid},
            draftGroup=${this.draftGroup},
            draftChange=${this.draftChange},
            changeItem=${this.changeItem},
            previousItem=${this.previousItem},
            changeType=${this.changeType},
        }`;
  }
}

export default DraftChangeItem;
