import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Log, User } from '../../../../../models';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../ProtocolHeader';

import { ViewportList } from 'react-viewport-list';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import { UserType } from '../../../../../models';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import Loading from '../../../../components/Loading/Loading';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { createUserGraphQL } from '../../../../../data/GraphQL_API';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import {
  adminUserTypes,
  genUserTypes,
} from '../../../../_global/constants/Protocol_constants';
import {
  generateTemporaryPassword,
  getGroupCode,
  globals,
} from '../../../../_global/common/Utils';
import { getEmailByCognitoId } from './UserCreateEditPage';
import { handleGetDepartment } from '../../../../../store/actions';
import { fetchUsers } from '../../../../../data/functions/UserDB';
// import EmailIcon from '@mui/icons-material'; // Import the email icon

const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});
const cognito = new AWS.CognitoIdentityServiceProvider();
const lambda = new AWS.Lambda();

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CreateNewUser = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const searchState = state.searchState;
  const currentUser: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const list = state?.list;
  const department: DepartmentItem = database.department;
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [depAdmins, setDepAdmins] = useState<DepartmentItem[]>([]);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userWarningModal, setUserWarningModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      username: '',
      pin: '',
      pairedDeps: [],
      type: UserType.USER,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/\S+@\S+\.\S+/, 'Invalid email address')
        .required('Required'),
      firstName: Yup.string()
        .min(2, 'First name must be at least 2 characters')
        .required('Required'),
      lastName: Yup.string()
        .min(2, 'Last name must be at least 2 characters')
        .required('Required'),
      username: Yup.string().required('Required'),
      pin: Yup.string()
        .length(4, '4-digit PIN is required')
        .required('Required'),
      pairedDeps: Yup.array().required('Required'),
      type: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      handleCreateUser(values);
    },
  });

  const availableDeps = useMemo(() => {
    if (department.isTopEnabled || user.type === UserType.ADMIN)
      return [department, ...(department.allSubDeps || [])];
    return department.allSubDeps || [];
  }, [department, user]);

  const availableDepAdmins = useMemo(() => {
    return availableDeps.filter((d: DepartmentItem) => !depAdmins.includes(d));
  }, [availableDeps, depAdmins]);

  const handleCreateUser = async (values: any) => {
    setIsLoading(true); // Show loading state
    const { email, firstName, lastName, username, pin, type } = values; // Extract values
    let capitalizedFirstName = firstName;
    let capitalizedLastName = lastName;
    if (firstName[0] !== firstName[0].toUpperCase()) {
      capitalizedFirstName = firstName[0].toUpperCase() + firstName.slice(1);
      formik.setFieldValue('firstName', capitalizedFirstName);
    }

    if (lastName[0] !== lastName[0].toUpperCase()) {
      capitalizedLastName = lastName[0].toUpperCase() + lastName.slice(1);
      formik.setFieldValue('lastName', capitalizedLastName);
    }

    // Generate a strong temporary password
    const tempPassword = generateTemporaryPassword();

    const cognitoParams = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: username.replace(/\s/g, ''),
      TemporaryPassword: tempPassword,

      UserAttributes: [
        { Name: 'email', Value: email },
        {
          Name: 'email_verified',
          Value: 'true', // // Mark email as verified to udpate email address in cognito later on
        },
        { Name: 'custom:type', Value: '3' },
      ],
      MessageAction: 'SUPPRESS', // it suppress the email notification
    };

    try {
      // Create user in Cognito
      let cognitoResponse = await cognito
        .adminCreateUser(cognitoParams)
        .promise();

      let _pairedDeps = [...pairedDeps];
      let _depAdmins = [...depAdmins];
      if (availableDeps.length <= 1) {
        _pairedDeps = [department];
        _depAdmins = [department];
      }

      // Create user entry in GraphQL or your database
      let dbResponse = await createUserGraphQL(
        type,
        username,
        capitalizedFirstName,
        capitalizedLastName,
        pin,
        department,
        _pairedDeps,
        _depAdmins
      );
      //  Create user in the Group
      let groupResponse = await addUserToGroup();

      // Check if the user was successfully created in the database
      if (
        dbResponse &&
        groupResponse &&
        groupResponse.statusCode === 200 &&
        cognitoResponse
      ) {
        handleSendEmail('newUser', tempPassword); // Send email to the user
        setIsSuccessModal(true); // Show success modal
        await reloadDatabase();
      } else {
        throw new Error('Failed to save user details in the database.'); // Handle the error if the response is not valid
      }
    } catch (error: any) {
      if (error.code === 'UsernameExistsException') {
        setUserWarningModal(true); // Show warning modal if the username already exists
      } else {
        console.error('Error creating user:', error);
        setIsWarningModal(true);
      } // Show warning modal on error
    } finally {
      setIsLoading(false); // Hide loading state regardless of success or failure
    }
  };

  const handleSendEmail = async (type: string, password: string) => {
    let userEmail = await getEmailByCognitoId(formik.values.username);
    if (formik.values.username && userEmail) {
      let payload = {};
      if (type === 'newUser') {
        payload = {
          email: userEmail,
          actionType: 'USER_CREATION',
          username: formik.values.username,
          fullName: formik.values.firstName + ' ' + formik.values.lastName,
          departmentName: department.name,
          tempPassword: password,
        };
      }

      const params = {
        FunctionName: 'sendEmailToUsers', // Ensure this matches your Lambda's actual name
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(payload),
      };

      try {
        const result = await lambda.invoke(params).promise();
        const parsedResult = JSON.parse(result.Payload);

        if (parsedResult && parsedResult.statusCode === 200) {
          if (globals.debug)
            console.log('Email sent successfully:', parsedResult);
        } else {
          if (globals.debug)
            console.error('Error in Lambda response:', parsedResult);
          throw new Error('Error invoking Lambda function');
        }
      } catch (error) {
        if (globals.debug)
          console.error('Error invoking Lambda function:', error);
        throw new Error('Error invoking Lambda function');
      }
    }
  };

  const addUserToGroup = async () => {
    const groupCode = getGroupCode(formik.values.type);
    const payload = {
      isAddToGroup: true,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userName: formik.values.username,
      type: groupCode,
    };

    const params = {
      FunctionName: 'addingUsersByAdmintoOneDoseGroup',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify(payload),
    };

    try {
      const result = await lambda.invoke(params).promise();
      if (globals.debug) console.log('Lambda invoked successfully:', result);
      return JSON.parse(result.Payload as string);
    } catch (error) {
      console.error('Error invoking Lambda function:', error);
      throw error;
    }
  };

  const reloadDatabase = async (): Promise<DatabaseResponse | null> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await fetchUsers(database.department);
    if (resp.type === ResponseType.Success) {
      const users: User[] = resp.data as User[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          users: users,
        })
      );
      return { ...database, users: users } as DatabaseResponse;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return null;
    }
  };

  const handleBack = () => {
    navigate(`/database/list-users`, {
      state: { department: department, database, searchState },
    });
  };

  const handleAddDepAdmin = (option: DepartmentItem) => {
    let l = [...depAdmins, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setDepAdmins(l);
  };

  const handleRemoveDepAdmin = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = depAdmins.filter((d: DepartmentItem) => d.id !== option.id);
    setDepAdmins(l);
  };

  useEffect(() => {
    if (department.allSubDeps) {
      let l = [...department.allSubDeps, department];
      l = l.filter((dep) => {
        return !formik.values.pairedDeps.some(
          (d: DepartmentItem) => d.id === dep.id
        );
      });
      setDepartmentList(l);
    }
  }, [department, formik.values.pairedDeps]);

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
    formik.setFieldValue('pairedDeps', l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
    formik.setFieldValue('pairedDeps', []);
  };
  const RenderUsers = ({ item, index }: any) => {
    return (
      <div key={index}>
        <div
          className="departmentItem"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            padding: '10px 10px',
            borderTopLeftRadius: index === 0 ? '6px' : '0px',
            borderTopRightRadius: index === 0 ? '6px' : '0px',
            borderBottomLeftRadius: index === list.length - 1 ? '6px' : '0px',
            borderBottomRightRadius: index === list.length - 1 ? '6px' : '0px',
            borderBottom:
              index !== list.length - 1 ? '1px solid #E0E0E0' : 'none',
            cursor: 'pointer',
          }}
        >
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0 }}
          >
            {index + 1}: {item.firstName} {item.lastName}
          </div>
          <div
            className="headerTextLight"
            style={{ fontSize: '14px', margin: 0, fontWeight: '500' }}
          >
            {item.number}
          </div>
        </div>
      </div>
    );
  };

  const isCreateActive = useMemo(() => {
    if (availableDeps.length > 1 && pairedDeps.length === 0) return false;
    return formik.dirty;
  }, [availableDeps, formik.dirty, pairedDeps]);

  return (
    <div className="screen-container">
      {isLoading && <Loading type="bubbles" />}

      {userWarningModal && (
        <ConfirmModal
          isVisible={userWarningModal}
          title="Error: UserName Already Exists"
          handleClose={() => {
            setUserWarningModal(false);
          }}
          handleSubmit={() => setUserWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Username already exists. Please try again with a different username."
        />
      )}

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Error: User Creation Failed"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          isDeleteBtn={true}
          handleSubmit={() => setIsWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Please try again later or contact Hinckley Medical Representative at support@hinckleymed.com."
        />
      )}

      {isSuccessModal && (
        <ConfirmModal
          isVisible={isSuccessModal}
          title="Success: Account Created Requested"
          handleClose={() => {
            setIsSuccessModal(false);
          }}
          handleSubmit={() => {
            setIsSuccessModal(false);
            formik.resetForm();
            setPairedDeps([]);
          }}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription={
            'User created. Please check your email for the confirmation code from onedose@hinckleymed.com.\nEmail: ' +
            formik.values.email
          }
        />
      )}

      <div className="fixedHeader">
        <ProtocolHeader
          name={'Create New User'}
          type={'protocol'}
          page={'Users'}
          isBackButton={true}
          isCreateButton={true}
          isCreateActive={isCreateActive}
          handleCreate={() => {
            formik.handleSubmit();
          }}
          isCancelButton={true}
          handleCancel={handleBack}
          handleCancelEdit={() => {
            formik.resetForm();
            setPairedDeps([]);
          }}
        />
      </div>

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">User Information</h5>
          <Row>
            <Col sm={6}>
              <label htmlFor="Name" className="ketamine-general-label">
                Email <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="email"
                  className="form-control-general"
                  id="email"
                  name="email"
                  data-testid="email"
                  value={formik.values.email}
                  onChange={(e: any) => {
                    formik.setFieldValue('email', e.target.value);
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.email && formik.errors.email && (
                  <div className="errorText">{formik.errors.email}</div>
                )}
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="Name" className="ketamine-general-label">
                Username <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="username"
                  name="username"
                  data-testid="username"
                  value={formik.values.username}
                  onChange={(e: any) => {
                    formik.setFieldValue('username', e.target.value);
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.username && formik.errors.username && (
                  <div className="errorText">{formik.errors.username}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <label htmlFor="Name" className="ketamine-general-label">
                First Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="firstName"
                  name="firstName"
                  data-testid="firstName"
                  value={formik.values.firstName}
                  onChange={(e: any) => {
                    formik.setFieldValue('firstName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="errorText">{formik.errors.firstName}</div>
                )}
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="Name" className="ketamine-general-label">
                Last Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="lastName"
                  name="lastName"
                  data-testid="lastName"
                  value={formik.values.lastName}
                  onChange={(e: any) => {
                    formik.setFieldValue('lastName', e.target.value);
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="errorText">{formik.errors.lastName}</div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <label htmlFor="Name" className="ketamine-general-label">
                4 Digit Pin <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="pin"
                  name="pin"
                  data-testid="pin"
                  value={formik.values.pin}
                  onChange={(e: any) => {
                    const pin = e.target.value.replace(/\s+/g, ''); // Remove all spaces
                    if (pin.length <= 4 && !isNaN(Number(pin)))
                      formik.setFieldValue('pin', pin);
                  }}
                />
                <div className="input-border"></div>
                {formik.touched.pin && formik.errors.pin && (
                  <div className="errorText">{formik.errors.pin}</div>
                )}
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="type" className={`notification-css-title`}>
                User Role <span className="required-field">*</span>
              </label>
              {currentUser.type !== UserType.ADMIN &&
                currentUser.type !== UserType.DEPT_ADMIN && (
                  <p
                    className="contentText greyText"
                    style={{ fontSize: '13px', marginLeft: '0px', margin: 0 }}
                  >
                    Only Admins can change the user role.
                  </p>
                )}
              <div className="input-container-we cursorPointer">
                <Dropdown
                  id="type"
                  name="type"
                  data-testid="type"
                  value={formik.values.type}
                  options={
                    currentUser.type === UserType.ADMIN
                      ? adminUserTypes
                      : genUserTypes
                  }
                  disabled={
                    currentUser.type !== UserType.ADMIN &&
                    currentUser.type !== UserType.DEPT_ADMIN
                  }
                  onChange={(e) => {
                    if (globals.debug) console.log('e', e.value);
                    formik.setFieldValue('type', e.value);
                  }}
                  style={{ width: '100%', borderRadius: '10px', margin: 0 }}
                  className="dropDownIconWidth input-container-we"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="KetamineGeneral">
          {(formik.values.type === UserType.ADMIN ||
            formik.values.type === UserType.DEPT_ADMIN) &&
            availableDeps &&
            availableDeps.length > 1 && (
              <>
                <label htmlFor="" className={`notification-css-title`}>
                  <span
                    className="headerTextMargin"
                    style={{ fontSize: '16px', marginTop: '10px' }}
                  >
                    Department Admins: {depAdmins.length} /{' '}
                    {availableDeps.length}
                    <span
                      onClick={() =>
                        setDepAdmins(availableDeps as DepartmentItem[])
                      }
                    >
                      <div className="clickableText">Add All</div>
                    </span>
                  </span>
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '10px' }}
                >
                  This is a list of every department that this user will have
                  ADMIN access to.
                </span>
                <SearchableDropdown<DepartmentItem>
                  id="searchDropdown"
                  options={availableDepAdmins}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.id}
                  multiSelect={true}
                  onChange={(option: DepartmentItem) =>
                    handleAddDepAdmin(option)
                  }
                  onClear={() => {
                    setDepAdmins([]);
                  }}
                  placeholder="Search department..."
                />
                {depAdmins.length === 0 && (
                  <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                    No paired departments...
                  </h6>
                )}
                <div
                  style={{
                    overflowY: 'auto',
                    maxHeight: '200px',
                    border: depAdmins.length === 0 ? '0px' : '1px solid #ccc',
                    borderRadius: '5px',
                    marginBottom: '20px',
                    marginTop: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ViewportList items={depAdmins}>
                    {(item: DepartmentItem, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '16fr 1fr',
                          padding: '6px 10px',
                          alignItems: 'center',
                          borderBottom:
                            index === pairedDeps.length - 1
                              ? ''
                              : '1px solid #ccc',
                          // borderTopLeftRadius: '6px',
                          // borderTopRightRadius: '6px',
                          // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                          // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        }}
                        className="listItem"
                      >
                        <div className="contentText">{item.name}</div>
                        <FaTimes
                          className="icon-cancel"
                          size={16}
                          onClick={(e) => handleRemoveDepAdmin(item, e)}
                        />
                      </div>
                    )}
                  </ViewportList>
                </div>
              </>
            )}

          {availableDeps && availableDeps.length > 1 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {pairedDeps.length} /{' '}
                  {availableDeps.length}
                  <span
                    onClick={() =>
                      setPairedDeps(availableDeps as DepartmentItem[])
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '200px',
                  border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateNewUser;
