// Note: EditDB for Checklist/Form
// Date: 01/15/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import { Storage } from '@aws-amplify/storage';
import {
  Department,
  Form,
  FormGroup,
  FormQuestion,
  GroupNotification,
  Notification,
  NotifyACK,
  Question,
  User,
} from '../../models';
import {
  CreateGroupNotificationInput,
  CreateNotificationInput,
  DeleteNotificationInput,
  NotificationType,
  ProgressStatus,
  UpdateNotificationInput,
} from '../../API';
import {
  ResponseType,
  Response,
  executeSingleQuery,
  executeQuery,
} from '../AmplifyDB';
import NotificationItem from '../model/NotificationItem';
import DepartmentItem from '../model/DepartmentItem';
import { globals } from '../../ui/_global/common/Utils';
import GroupItem from '../model/GroupItem';
import { AckStatus } from '../../models';
import {
  createGroupNotification,
  createNotification,
  deleteNotification,
  deleteNotifyACK,
  updateNotification,
} from '../../graphql/mutations';
import {
  getNotification,
  listNotifications,
  notifyACKsByOwnerID,
} from '../../graphql/queries';
import { notificationsByDepartmentID } from '../QueryTypes';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

export type NotificationJSON = {
  title: string;
  message: string;
  timestamp: Date;
  expirationTimestamp?: Date;
  deadline?: Date;
  type: NotificationType;
  isReadIDs: string[];
  isAckIDs: string[];
  departmentID: string;
  isPush: boolean;
  pairedDeps: DepartmentItem[] | undefined | null;
  fileURLs: string[];
  taggedProtocols: string[];
  questions: Question[];
  formQuestions: FormQuestion[];
  Groups: GroupItem[] | undefined | null;
};

export const createNotificationItem = async (
  notification: NotificationJSON | NotificationItem,
  creator: User
): Promise<Response> => {
  try {
    let json: NotificationJSON;
    if (notification.hasOwnProperty('dbNotification')) {
      let not = notification as NotificationItem;
      json = {
        title: not.title,
        message: not.message ? not.message : '',
        timestamp: new Date(),
        deadline: not.deadline ? not.deadline : undefined,
        type: not.type,
        isReadIDs: not.isReadIDs,
        isAckIDs: not.isAckIds,
        departmentID: not.depID,
        fileURLs: not.fileURLs,
        taggedProtocols: not.taggedProtocols.map((p) => p.uid),
        questions: not.questions,
        isPush: not.isPush,
        pairedDeps: not.pairedDeps,
        formQuestions: not.formQuestions,
        Groups: not.groups,
      };
    } else json = notification as NotificationJSON;

    //Create a date in 30 days from now
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 30);

    // let result = await DataStore.save(
    //   new Notification({
    //     title: json.title,
    //     message: json.message,
    //     timestamp: json.timestamp.toISOString(),
    //     expirationTimestamp: json.expirationTimestamp
    //       ? json.expirationTimestamp.toISOString()
    //       : expirationDate.toISOString(),
    //     type: json.type,
    //     isReadIDs: json.isReadIDs,
    //     isAckIDs: json.isAckIDs,
    //     departmentID: json.departmentID,
    //     fileURLs: json.fileURLs,
    //     taggedProtocols: json.taggedProtocols,
    //     questions: json.questions,
    //     isPush: json.isPush,
    //     createdBy: creator.id,
    //     modifiedBy: creator.id,
    //     deadlineTimestamp: json.deadline
    //       ? json.deadline.toISOString()
    //       : undefined,
    //     pairedDepIDs: json.pairedDeps ? json.pairedDeps.map((d) => d.id) : [],
    //     formQuestions: json.formQuestions,
    //   })
    // );
    let result = await executeSingleQuery(createNotification, {
      input: {
        title: json.title,
        message: json.message,
        timestamp: json.timestamp.toISOString(),
        expirationTimestamp: json.expirationTimestamp
          ? json.expirationTimestamp.toISOString()
          : expirationDate.toISOString(),
        type: json.type,
        isReadIDs: json.isReadIDs,
        isAckIDs: json.isAckIDs,
        departmentID: json.departmentID,
        fileURLs: json.fileURLs,
        taggedProtocols: json.taggedProtocols,
        questions: json.questions,
        isPush: json.isPush,
        createdBy: creator.id,
        modifiedBy: creator.id,
        deadlineTimestamp: json.deadline
          ? json.deadline.toISOString()
          : undefined,
        pairedDepIDs: json.pairedDeps ? json.pairedDeps.map((d) => d.id) : [],
        formQuestions: json.formQuestions,
      } as CreateNotificationInput,
    });

    if (json.Groups) {
      for (let i = 0; i < json.Groups.length; i++) {
        let groupNotification = await executeSingleQuery(
          createGroupNotification,
          {
            input: {
              groupId: json.Groups[i].uid,
              notificationId: result.id,
            } as CreateGroupNotificationInput,
          }
        );

        console.log('Group Notification:', groupNotification);

        // let groupNotification = new GroupNotification({
        //   groupId: json.Groups[i].uid,
        //   notificationId: result.id,
        //   notification: result,
        //   group: json.Groups[i].model,
        // });
        // await DataStore.save(groupNotification);
      }
    }

    let newNotification = new NotificationItem(result, []);
    // newNotification.addGroups(groups);
    newNotification.setModifiedBy(creator);

    return {
      type: ResponseType.Success,
      data: newNotification,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const updateNotificationItem = async (
  updatedNotification: any | Notification,
  user: User,
  removedFileURLs: string[]
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Updated Notification:', updatedNotification);
    const originalNotification = await executeSingleQuery(getNotification, {
      id: updatedNotification.uid,
    });
    if (!originalNotification) {
      throw new Error('Notification not found.');
    }
    let input = {
      id: updatedNotification.uid,
      _version: originalNotification._version,
      title: updatedNotification.title,
      message: updatedNotification.message,
      fileURLs: updatedNotification.fileURLs,
      pairedDepIDs: updatedNotification.pairedDepIDs
        ? updatedNotification.pairedDepIDs.map((d: any) => d)
        : [],
    } as UpdateNotificationInput;

    if (globals.debug) console.log('Update Notification Input:', input);

    // Update the notification with the new values
    const result = await executeSingleQuery(updateNotification, { input });

    if (removedFileURLs.length > 0) {
      await deleteNotificationFiles(updatedNotification, removedFileURLs);
    }
    // const result = await DataStore.save(
    //   Notification.copyOf(originalNotification, (updated) => {
    //     updated.title = updatedNotification.title;
    //     updated.message = updatedNotification.message;
    //     updated.timestamp = updatedNotification.timestamp.toISOString();
    //     updated.type = updatedNotification.type;
    //     updated.isReadIDs = updatedNotification.isReadIDs;
    //     updated.isAckIDs = updatedNotification.isAckIDs
    //       ? updatedNotification.isAckIDs
    //       : [];
    //     updated.departmentID = updatedNotification.depID;
    //     updated.fileURLs = updatedNotification.fileURLs;
    //     updated.taggedProtocols = updatedNotification.taggedProtocols.map(
    //       (p: any) => p
    //     );
    //     updated.questions = updatedNotification.questions;
    //     updated.isPush = updatedNotification.isPush;
    //     updated.modifiedBy = user.id;
    //     updated.createdBy = updatedNotification.dbNotification.createdBy;
    //     updated.pairedDepIDs = updatedNotification.pairedDepIDs
    //       ? updatedNotification.pairedDepIDs.map((d: any) => d)
    //       : [];
    //     updated.deadlineTimestamp = updatedNotification.deadlineTimestamp;
    //     updated.formQuestions = updatedNotification.formQuestions;
    //   })
    // );

    let newNotification = new NotificationItem(result, []);
    newNotification.setModifiedBy(user);

    return {
      type: ResponseType.Success,
      data: newNotification,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchNotifications = async (
  department: DepartmentItem,
  lazyCallback?: (results: any) => void
): Promise<Response> => {
  try {
    const topLevelDep = department.getTopLevelDep();

    let filter: any =
      topLevelDep.id === department.id
        ? {
            _deleted: {
              ne: true,
            },
          }
        : {
            and: [
              {
                _deleted: {
                  ne: true,
                },
              },
              {
                pairedDepIDs: {
                  contains: department.id,
                },
              },
            ],
          };

    const notifications = await executeQuery(
      notificationsByDepartmentID,
      {
        departmentID: topLevelDep.id,
        sortDirection: 'DESC',
        filter: filter,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      false
    );

    let nots: NotificationItem[] = [];
    for (let i = 0; i < notifications.length; i++) {
      let notification = new NotificationItem(notifications[i]);
      nots.push(notification);
    }

    fetchAllNotifyACKs(nots).then((responses) => {
      if (lazyCallback) lazyCallback(nots);
    });

    return {
      type: ResponseType.Success,
      data: nots,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchAllNotifyACKs = async (
  notifications: NotificationItem[]
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    try {
      let promises: Promise<Response>[] = [];
      for (let i = 0; i < notifications.length; i++) {
        promises.push(fetchNotifyACKs(notifications[i]));
        // if(promises.length > 50){
        //   Promise.all(promises).then((responses) => {
        //     resolve({ type: ResponseType.Success, data: responses });
        //   });
        //   promises = [];
        // }
      }
      Promise.all(promises).then((responses) => {
        resolve({ type: ResponseType.Success, data: responses });
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchNotifyACKs = async (
  notification: NotificationItem
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    try {
      executeQuery(
        notifyACKsByOwnerID,
        {
          ownerID: notification.uid,
          sortDirection: 'DESC',
          filter: {
            _deleted: {
              ne: true,
            },
          },
        },
        undefined,
        undefined,
        undefined,
        undefined,
        false
      ).then((responses) => {
        notification.acknowledged = responses.filter(
          (ack: NotifyACK) => ack.status === AckStatus.APPROVED
        );
        notification.viewed = responses.filter(
          (ack: NotifyACK) => ack.status === AckStatus.VIEWED
        );
        resolve({ type: ResponseType.Success, data: responses });
      });
    } catch (e) {
      reject(e);
    }
  });
};
/**
 * Hard delete a notification
 * @param notification  The notification to delete
 * @returns Success if the notification was deleted or Failure if the notification does not exist
 */
export const deleteNotificationItem = async (
  notification: NotificationItem,
  deleteFiles: boolean = true
): Promise<Response> => {
  try {
    /* Delete the notification files in S3 */
    if (deleteFiles) {
      deleteNotificationFiles(notification, notification.fileURLs).then(
        (response) => {
          if (globals.debug)
            console.log('Files deleted response:', response.data);
        }
      );
    }

    /* Delete acknowledged and viewed NotifyACK items */
    const deleteNotifyACKPromises = [
      ...notification.acknowledged.map((ack) =>
        executeSingleQuery(deleteNotifyACK, {
          input: {
            id: ack.id,
            _version: Number(ack._version),
          } as DeleteNotificationInput,
        })
      ),
      ...notification.viewed.map((view) =>
        executeSingleQuery(deleteNotifyACK, {
          input: {
            id: view.id,
            _version: Number(view._version),
          } as DeleteNotificationInput,
        })
      ),
    ];

    const deleteNotifyACKResults = await Promise.all(deleteNotifyACKPromises);

    const allNotifyACKsDeleted = deleteNotifyACKResults.every(
      (result) => result != null
    );

    console.log('All NotifyACKs deleted:', allNotifyACKsDeleted);

    if (!allNotifyACKsDeleted) {
      return {
        type: ResponseType.Failure,
        data: 'Failed to delete all acknowledged or viewed notifications',
      };
    }

    console.log('Deleting notification:', notification);

    // let not = await DataStore.delete(Notification, notification.uid);
    let not = await executeSingleQuery(deleteNotification, {
      input: {
        id: notification.uid,
        _version: Number(notification.dbNotification._version),
      } as DeleteNotificationInput,
    });

    if (not == null) {
      return {
        type: ResponseType.Failure,
        data: 'The notification does not exist',
      };
    }
    return {
      type: ResponseType.Success,
      data: notification,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Delete the files associated with a notification
 * @param notification The notification that owns the files
 * @param fileURLs The file URLs to delete
 * @returns Success if the files were deleted or Failure if there was an error
 *   - Data will contain the number of files deleted and the number of files that failed to delete
 */
export const deleteNotificationFiles = async (
  notification: NotificationItem,
  fileURLs: string[]
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    try {
      let deletePromises: Promise<Response>[] = [];
      for (let i = 0; i < fileURLs.length; i++) {
        if (fileURLs[i] != null && fileURLs[i].includes(HM_DB_ID)) {
          if (globals.debug)
            console.log(
              'NOT deleting file because it belongs to HM DB Department',
              fileURLs[i]
            );
        } else if (fileURLs[i] != null) {
          deletePromises.push(
            fetchNotificationsWithFile(notification, fileURLs[i]).then(
              (response) => {
                if (response.type === ResponseType.Success) {
                  let notifications = response.data as NotificationItem[];
                  if (notifications.length === 0) {
                    Storage.remove(fileURLs[i], {
                      level: 'public',
                    })
                      .then(() => {
                        if (globals.debug)
                          console.log('Deleted file:', fileURLs[i]);
                      })
                      .catch((e) => console.error('Error deleting file:', e));
                  } else {
                    if (globals.debug)
                      console.log(
                        'NOT deleting file because it is associated with another notification',
                        fileURLs[i]
                      );
                  }
                  return { type: ResponseType.Success, data: 'Success' };
                }
                return response;
              }
            )
          );
        }
      }
      Promise.all(deletePromises).then((responses) => {
        let successCount = responses.filter(
          (response) => response.type === ResponseType.Success
        ).length;
        resolve({
          type: ResponseType.Success,
          data: {
            success: successCount,
            failure: deletePromises.length - successCount,
          },
        });
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const fetchNotificationsWithFile = async (
  currentNotification: NotificationItem,
  fileURL: string
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let notifications = await executeQuery(listNotifications, {
        filter: {
          and: [
            {
              id: {
                ne: currentNotification.uid,
              },
            },
            {
              fileURLs: {
                contains: fileURL,
              },
            },
          ],
        },
      });

      resolve({ type: ResponseType.Success, data: notifications });
    } catch (e) {
      reject(e);
    }
  });
};

// export const deleteNotificationAck = async (
//   notification: NotificationItem,
// ): Promise<Response> => {
//   try {
//     l
//   }
// }

/**
 * Upload multiple files to S3 and return the file paths
 * @param department The department that owns the notification
 * @param files The files to upload
 * @returns Success if the files were uploaded or Failure if there was an error
 */
export const uploadAllFilesToS3 = async (
  department: DepartmentItem,
  files: File[],
  callback?: (status: string) => void
): Promise<Response> => {
  try {
    let fileIncrementPercent = Math.round((1.0 / files.length) * 100);
    let totalPercent = 0;
    let filePaths: string[] = [];
    for (let i = 0; i < files.length; i++) {
      let response = await uploadNewFileToS3(
        department,
        files[i],
        totalPercent,
        fileIncrementPercent,
        callback
      );
      if (response.type === ResponseType.Success)
        filePaths.push(response.data as string);
      else {
        //Retry the upload
        response = await uploadNewFileToS3(
          department,
          files[i],
          totalPercent,
          fileIncrementPercent,
          callback
        );
        if (response.type === ResponseType.Success)
          filePaths.push(response.data as string);
        else console.error('Error uploading file:', files[i], response.data);
      }
      totalPercent += fileIncrementPercent;
    }
    return {
      type: ResponseType.Success,
      data: filePaths,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Upload the new JPEG, PNG, PDF, MP4, MOV, or IMEG to S3 and return the file path
 * @param department The department that owns the protocol
 * @param notification The notification that owns the file
 * @param file The file to upload
 * @returns Success if the file was uploaded or Failure if there was an error
 */
export const uploadNewFileToS3 = async (
  department: DepartmentItem,
  file: File,
  totalPercent: number,
  fileIncrementPercent?: number,
  loadingCallback?: (status: string) => void
): Promise<Response> => {
  try {
    /* First create the file path -> Public / departmentID / notifications / (Month_Day_Year) / (Name).(File Extension)*/
    let dateID = new Date().toLocaleDateString().replace(/\//g, '_');
    let fileName = file.name.split('.');
    let name = fileName.slice(0, fileName.length - 1).join('.');
    let filExt = fileName[fileName.length - 1];
    let filePath =
      department.id + '/notifiations/' + dateID + '/' + name + '.' + filExt;

    /* Then take out ay characters that are not allowed in the file path and replace them with an underscore */
    filePath = filePath.replace(/[^a-zA-Z0-9./-]/g, '_');

    /* Make sure all the folders exist in the S3 bucket */
    await Storage.put(filePath, file, {
      contentType: file.type,
      level: 'public',
      progressCallback: (progress: any) => {
        let percent = progress.loaded / progress.total;
        if (fileIncrementPercent && loadingCallback)
          loadingCallback(
            'Uploading files... ' +
              Math.round(totalPercent + percent * fileIncrementPercent) +
              '%'
          );
      },
    });

    return {
      type: ResponseType.Success,
      data: filePath,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Fetch a temporary URL for the file from S3
 * @param filePath The file path to get from S3
 * @returns Success if the file was retrieved or Failure if there was an error
 */
export const getFileFromS3 = async (filePath: string): Promise<Response> => {
  try {
    let result: any = await Storage.get(filePath, {
      level: 'public',
      download: true,
    });
    let fileName: string = filePath.split('/').pop() as string;
    let file = blobToFile(result.Body, fileName);
    return {
      type: ResponseType.Success,
      data: file,
    };
  } catch (e: any) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

const blobToFile = (blob: any, filename: string): File => {
  const lastModified = new Date(); // Use the current date as the last modified date
  const file = new File([blob], filename, {
    type: blob.type,
    lastModified: lastModified.getTime(),
  });
  return file;
};

export const NotificationAcknowledgeUsers = async (
  notification: NotificationItem
): Promise<Response> => {
  try {
    const notifications = await DataStore.query(NotifyACK, (n) =>
      n.and((n) => [
        n.ownerID.eq(notification.uid),
        n.status.eq(AckStatus.APPROVED),
      ])
    );

    const userIds = notifications.map((n) => n.userID);
    return {
      type: ResponseType.Success,
      data: userIds,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
