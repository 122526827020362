import React, { useState } from 'react';

import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { EquipmentOption, ModelMetaData, User } from '../../../../models';

import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import EquipmentDose from '../../protocol/edit/Equipment/EquipmentDose';
interface FolderComparisonProps {
  focusItem: EquipmentItem;
  handleBack: () => void;
  viewText?: string;
}

const EquipmentFullView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5
            className="ketmine-header-text backTextLight hoverText"
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <span>
              <IoArrowBack
                data-testid="isBackBttn"
                size="1.15rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            General Information
          </h5>

          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {focusItem.modifiedBy
                    ? focusItem.modifiedBy.firstName +
                      ' ' +
                      focusItem.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>

              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
              </div>

              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.uid}
                    <span>
                      {isCopied && isCopied === focusItem.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(focusItem.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                focusItem.status === ProgressStatus.DRAFT &&
                focusItem.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.activeID}
                      <span>
                        {isCopied && isCopied === focusItem.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(focusItem.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.name}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Title" className="ketamine-general-label">
            Title <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="title"
              name="title"
              data-testid="title"
              required={true}
              value={focusItem?.title}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="warning"
              className="form-control-general"
              id="warning"
              name="warning"
              data-testid="warning"
              required={true}
              value={focusItem?.warning}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>

          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="instruction"
              className="form-control-general"
              id="instruction"
              name="instruction"
              data-testid="instruction"
              required={true}
              value={focusItem?.instruction}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>

          <label htmlFor="Note" className="ketamine-general-label">
            Note <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="note"
              className="form-control-general"
              id="note"
              name="note"
              data-testid="note"
              required={true}
              value={focusItem?.note}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Equipment Options</h5>

          <ViewportList items={focusItem.options}>
            {(item: EquipmentOption, index: number) => {
              return (
                <div key={index} style={{ cursor: 'pointer' }}>
                  <EquipmentDose dose={item} doseIndex={index} />
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default EquipmentFullView;
