import React, { useMemo, useState } from 'react';
import DraftGroupItem from '../../../data/model/DraftGroupItem';
import { FaChevronDown, FaChevronRight, FaCircleXmark } from 'react-icons/fa6';
import DraftChangeItem, {
  DraftChangeType,
} from '../../../data/model/DraftChangeItem';
import { toTitleCase } from '../../_global/common/Utils';
import './Review.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';
import {
  faCircleCheck,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { getStatusStyles } from './styles';
import { compareFolderItems } from './reviewComparsion/FolderComparison';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import MedicationItem from '../../../data/model/MedicationItem';
import ElectricalItem from '../../../data/model/ElectricalItem';
import { compareMedicationItems } from './reviewComparsion/MedicationComparison';
import { compareProtocolItems } from './reviewComparsion/ProtocolComparison';
import { compareInfusionItems } from './reviewComparsion/InfusionComparison';
import { compareElectricalItems } from './reviewComparsion/ElectricalComparison';
import VitalItem from '../../../data/model/VitalItem';
import { compareVitalItems } from './reviewComparsion/VitalComparison';
import ReviewalItem from '../../../data/model/ReviewalItem';
import { ACKStatus } from '../../../API';
import { FaCheckCircle } from 'react-icons/fa';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import { compareMedicationSubItems } from './reviewComparsion/MedicationDoseComparison';
import { compareInfusionSubItems } from './reviewComparsion/InfusionDoseComparison';
import { compareElectricalSubItemItems } from './reviewComparsion/ElectricalShockComparison';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import InfusionSubItem from '../../../data/model/InfusionSubItem';
import { compareEquipmentItems } from './reviewComparsion/EquipmentComparison';
import EquipmentItem from '../../../data/model/EquipmentItem';

interface DraftGroupProps {
  reviewal: ReviewalItem;
  isUserOwner: boolean;
  draftGroup: DraftGroupItem;
  currentDraft: DraftChangeItem | null;
  handleClick: (draft: DraftChangeItem) => void;
  expanded: string[];
  setExpanded: (expanded: string[]) => void;
  searchQuery: string;
  filteredDrafts: DraftChangeItem[];
  singleDraft?: boolean;
}

const ReviewDraftGroup: React.FC<DraftGroupProps> = ({
  reviewal,
  isUserOwner,
  draftGroup,
  currentDraft,
  handleClick,
  expanded,
  setExpanded,
  searchQuery,
  filteredDrafts,
  singleDraft,
}) => {
  const isExpanded = useMemo(
    () => singleDraft || expanded.includes(draftGroup.uid),
    [expanded, draftGroup.uid]
  );

  const toggleExpand = () => {
    if (isExpanded) {
      setExpanded(expanded.filter((item) => item !== draftGroup.uid));
    } else {
      setExpanded([...expanded, draftGroup.uid]);
    }
  };

  const [isHovered, setIsHovered] = useState<string | null>(null);
  const [hoverPosition, setHoverPosition] = useState({ top: 0, left: 0 });

  const calculateChangesCount = (draftItem: DraftChangeItem) => {
    if (
      !draftItem ||
      draftItem.changeItem == null
      // draftItem.previousItem == null
    )
      return 0;
    let countMedicationDose = 0;
    let countInfusionDose = 0;
    let countElectricalShock = 0;
    switch (draftItem?.changeType) {
      case DraftChangeType.FOLDER:
        return compareFolderItems(
          draftItem.changeItem as CategoryItem,
          draftItem.previousItem as CategoryItem
        ).length;

      case DraftChangeType.PROTOCOL:
        return compareProtocolItems(
          draftItem.changeItem as ProtocolItem,
          draftItem.previousItem as ProtocolItem
        ).length;

      case DraftChangeType.MEDICATION:
        return compareMedicationItems(
          draftItem.changeItem as MedicationItem,
          draftItem.previousItem as MedicationItem
        ).length;

      case DraftChangeType.MEDICATION_DOSE:
        return compareMedicationSubItems(
          draftItem.changeItem as MedicationSubItem,
          draftItem.previousItem as MedicationSubItem,
          countMedicationDose
        ).count;

      // case DraftChangeType.MEDICATION_INDEX:
      //   return compareMedicationSubItems(
      //     draftItem.changeItem as MedicationSubItem,
      //     draftItem.previousItem as MedicationSubItem
      //   ).length;

      case DraftChangeType.INFUSION:
        return compareInfusionItems(
          draftItem.changeItem as MedicationItem,
          draftItem.previousItem as MedicationItem
        ).length;

      case DraftChangeType.INFUSION_DOSE:
        return compareInfusionSubItems(
          draftItem.changeItem as InfusionSubItem,
          draftItem.previousItem as InfusionSubItem,
          countInfusionDose
        ).count;

      case DraftChangeType.ELECTRICAL:
        return compareElectricalItems(
          draftItem.changeItem as ElectricalItem,
          draftItem.previousItem as ElectricalItem
        ).length;

      case DraftChangeType.ELECTRICAL_SHOCK:
        return compareElectricalSubItemItems(
          draftItem.changeItem as ElectricalSubItem,
          draftItem.previousItem as ElectricalSubItem,
          countElectricalShock
        ).count;

      case DraftChangeType.VITAL:
        return compareVitalItems(
          draftItem.changeItem as VitalItem,
          draftItem.previousItem as VitalItem
        ).length;

      case DraftChangeType.EQUIPMENT:
        return compareEquipmentItems(
          draftItem.changeItem as EquipmentItem,
          draftItem.previousItem as EquipmentItem
        ).length;

      default:
        return 0;
    }
  };

  const isAllApproved = useMemo(() => {
    return draftGroup.draftChanges.every(
      (draft) => draft?.reviewACK?.state === 'APPROVED'
    );
  }, [draftGroup, draftGroup.draftChanges]);

  const calculateACKStatus = (draftItem: DraftChangeItem) => {
    if (
      isUserOwner &&
      (reviewal.reviewers.length <= draftItem.acknowledgements.length ||
        draftItem.acknowledgements.filter(
          (ack) => ack.state === ACKStatus.REJECTED
        ).length > 0)
    )
      return draftItem.acknowledgements.find(
        (ack) => ack.state === ACKStatus.REJECTED
      )
        ? ACKStatus.REJECTED
        : ACKStatus.APPROVED;
    return draftItem.reviewACK?.state || null;
  };

  // const draftsToShow = searchQuery
  //   ? filteredDrafts.filter((draft) => draftGroup.draftChanges.includes(draft))
  //   : draftGroup.draftChanges;

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const draftsToShow = useMemo(() => {
    return searchQuery
      ? filteredDrafts.filter((draft) =>
          draftGroup.draftChanges.includes(draft)
        )
      : draftGroup.draftChanges;
  }, [searchQuery, filteredDrafts, draftGroup.draftChanges]);

  const handleMouseEnter = (uid: string, event: any) => {
    setIsHovered(uid);
    setHoverPosition({
      top: event.currentTarget.getBoundingClientRect().bottom + window.scrollY,
      left: event.currentTarget.getBoundingClientRect().left + window.scrollX,
    });
  };

  const handleMouseLeave = () => {
    setIsHovered(null);
  };
  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      {!searchQuery && !singleDraft && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className={`border hover-raise-elevation ${isExpanded ? 'expanded' : ''}`}
            style={{
              display: 'flex',
              width: '100%',
              padding: '10px',
              background: isExpanded ? '#E0EADD' : '#FFF',
              cursor: 'pointer',
              border: `1px solid ${isExpanded ? '#0052CC' : '#DEE2E6'}`,
              borderRadius: '4px',
              transition: 'background-color 0.3s, border-color 0.3s',
            }}
            onClick={toggleExpand}
          >
            {isExpanded ? (
              <FaChevronDown size={14} color={'#00534C'} />
            ) : (
              <FaChevronRight size={14} color={'#a0a0a0'} />
            )}
            {isAllApproved && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="sm"
                style={{
                  color: '#037F02',
                  marginLeft: '8px',
                }}
              />
            )}
            <span
              className="ketamine-general-label no-select"
              style={{ flex: 1, fontWeight: '700' }}
            >
              {draftGroup.title}
            </span>
            <span
              className="ketamine-general-label no-select"
              style={{
                marginRight: '10px',
                fontWeight: '500',
                color: '#6A6A6A',
              }}
            >
              {draftGroup.draftChanges.length} items
            </span>
          </div>
        </div>
      )}
      {(isExpanded || searchQuery) && (
        <div style={{ marginTop: isExpanded ? '10px' : '0px' }}>
          {draftsToShow.map(
            (draftItem: DraftChangeItem, draftIndex: number) => {
              const ackStatus = calculateACKStatus(draftItem);

              const ackUserInitial = `${draftItem?.reviewACK?.user.firstName[0]}${draftItem?.reviewACK?.user.lastName[0]}`;

              const changeCount = calculateChangesCount(draftItem);
              console.log('ackStatus', ackStatus);
              return (
                <>
                  {ackStatus === 'APPROVED' || ackStatus === 'REJECTED' ? (
                    <div
                      className="jira-tickets"
                      key={draftIndex}
                      onClick={() => handleClick(draftItem)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '6px 16px',
                        border: '1px solid rgb(224, 224, 224)',
                        borderRadius: '8px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        transition:
                          'border-color 0.3s, background-color 0.3s, box-shadow 0.3s',
                        backgroundColor:
                          currentDraft === draftItem
                            ? ackStatus === 'APPROVED'
                              ? '#E6F4EA'
                              : '#FFC7CE'
                            : 'white',
                        marginBottom:
                          draftIndex === draftsToShow.length - 1 ? '10px' : '0',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon
                          icon={
                            ackStatus === 'APPROVED'
                              ? faCircleCheck
                              : faTimesCircle
                          }
                          size="sm"
                          style={{
                            color:
                              ackStatus === 'APPROVED' ? '#037F02' : '#8c1010',
                            marginRight: '8px',
                          }}
                        />
                        <div
                          style={{
                            color: '#3d3d3d',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            fontSize: '14px',
                            flex: 1,
                          }}
                        >
                          {truncateText(
                            draftItem.changeItem?.name || '',
                            isUserOwner ? 20 : 30
                          )}
                        </div>

                        {isUserOwner && (
                          <div
                            style={{
                              // marginLeft: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '5px',
                            }}
                          >
                            {draftItem.acknowledgements
                              .slice(0, 3)
                              .map((ack) => {
                                return (
                                  <div
                                    className="doseReviewButtonContainer"
                                    style={{
                                      position: 'relative',
                                      gap: '0px',
                                      // display: 'inline-block',
                                      // marginRight: '10px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        // marginLeft: 'auto',
                                        width: '24px',
                                        height: '24px',
                                        borderRadius: '50%',
                                        backgroundColor: '#00534C',

                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {ack.user.firstName[0] +
                                        ack.user.lastName[0]}
                                    </div>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        bottom: -4,
                                        right: -3,
                                        width: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#FFF',
                                      }}
                                    >
                                      {ack.state === ACKStatus.APPROVED ? (
                                        <FaCheckCircle
                                          style={{
                                            color: '#037F02',
                                            fontSize: '12px',
                                          }}
                                        />
                                      ) : (
                                        <FaCircleXmark
                                          style={{
                                            color: '#8c1010',
                                            fontSize: '12px',
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            {draftItem.acknowledgements.length > 3 && (
                              <div
                                style={{
                                  position: 'relative',
                                  gap: '0px',
                                  // display: 'inline-block',
                                  // marginRight: '10px',
                                }}
                              >
                                <div
                                  style={{
                                    width: '24px',
                                    height: '24px',
                                    borderRadius: '50%',
                                    backgroundColor: '#5A8F5C',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '11px',
                                    fontWeight: 'bold',
                                  }}
                                  onMouseEnter={(e) =>
                                    handleMouseEnter(draftGroup.uid, e)
                                  }
                                  onMouseLeave={handleMouseLeave}
                                >
                                  +{draftItem.acknowledgements.length - 3}
                                </div>
                                {isHovered === draftItem.uid &&
                                  createPortal(
                                    <div
                                      style={{
                                        position: 'fixed',
                                        top: hoverPosition.top,
                                        left: hoverPosition.left,
                                        transform: 'translateY(8px)',
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        borderRadius: '4px',
                                        boxShadow:
                                          '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        padding: '10px',
                                        // zIndex: 10000,
                                      }}
                                    >
                                      {draftItem.acknowledgements.map((ack) => (
                                        <div key={ack.user.id}>
                                          {ack.user.firstName}{' '}
                                          {ack.user.lastName}
                                        </div>
                                      ))}
                                    </div>,
                                    document.body
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="jira-tickets-not-ack"
                      key={draftIndex}
                      onClick={() => handleClick(draftItem)}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border:
                          currentDraft === draftItem
                            ? '1px solid  rgba(41, 122, 216, 0.5)'
                            : '1px solid rgb(224, 224, 224)',
                        // outline:
                        //   currentDraft === draftItem
                        //     ? '1px solid #1f5ca2'
                        //     : 'none',
                        borderRadius: '8px',
                        boxShadow:
                          currentDraft === draftItem
                            ? '0 2px 4px rgba(41, 122, 216, 0.8)'
                            : '0 2px 4px rgba(0, 0, 0, 0.1)',
                        cursor: 'pointer',
                        transition:
                          'border-color 0.3s, background-color 0.3s, box-shadow 0.3s',
                        backgroundColor:
                          currentDraft === draftItem ? '#EAF0FF' : 'white',
                        marginBottom: '5px',
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <div
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            backgroundColor: getStatusStyles(
                              draftItem.changeType
                            ).backgroundColor,
                            color: getStatusStyles(draftItem.changeType).color,
                            padding: '6px',
                            fontSize: '14px',
                            borderTopRightRadius: '8px',
                            borderTopLeftRadius: '8px',
                          }}
                        >
                          {toTitleCase(draftItem.changeType) +
                            ' ' +
                            draftItem.changeItem?.name}
                        </div>
                        <div
                          style={{
                            color: '#6a6a6a',
                            fontSize: '12px',
                            padding: '5px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {/*  add a text for the change count */}

                          {draftItem.changeType ===
                          DraftChangeType.PROTOCOL_PDF ? (
                            <span>There is a change in the PDF</span>
                          ) : changeCount ? (
                            <span>
                              There are {changeCount} changes in this draft
                            </span>
                          ) : (
                            <span>No changes in this draft</span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default ReviewDraftGroup;
