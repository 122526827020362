import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
  globals,
} from '../../../_global/common/Utils';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import ContactItem from '../../../../data/model/ContactItem';
import SearchBar from '../../../components/Search/SearchBar';
import KeychainItem from '../../../../data/model/KeychainItem';
import {
  deleteKeychain,
  fetchKeychains,
} from '../../../../data/functions/KeychainDB';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListKeychains = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [keychains, setKeychains] = useState<KeychainItem[]>(
    database.keychains
  );

  const scrollPosition = useRef(0);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );

  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<KeychainItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const reloadDatabase = async () => {
    const response = await fetchKeychains(department);
    if (response.type === ResponseType.Success) {
      setKeychains(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          keychains: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : keychains)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      return a.name.localeCompare(b.name);
    });

    /* Sort the list based on the sort value */
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort.includes('modified')) {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        if (sort === 'modified_desc') return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
      } else return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(keychains);
  }, [searchQuery, keychains, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (obj: KeychainItem) => {
    const state = {
      selectedProtocol: null,
      value: obj,
      subValue: null,
      type: 'KeychainItem',
      editType: 'edit',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/edit/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'KeychainItem',
      editType: 'new',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/new/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '12fr 6fr 6fr 6fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };
  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value.name}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },

    {
      field: 'modified_date',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Modified Date</div>
      ),
      filterable: false,
    },
    {
      field: 'modified_by',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Modified By</div>,
      filterable: false,
    },

    {
      field: 'type',
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          Type
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{params.value.type}</span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      filterable: false,
    },
  ];

  const rows = list.map((item: KeychainItem) => {
    let id = item.model?.modifiedBy;
    if (id == null) id = item.model?.createdBy;
    let username = 'Hinckley Medical';
    if (id) {
      let user = database.users.find((user) => user.id === id);
      if (user) username = user.firstName + ' ' + user.lastName;
    }
    let type = 'None';
    if (department.keychainID === item.uid) type = 'Department';
    else {
      for (let i = 0; i < database.categories.length; i++) {
        if (database.categories[i].keychainID === item.uid) {
          type = 'Folder and Proocols';
          break;
        }
      }
      if (type === 'None') {
        for (let i = 0; i < database.protocols.length; i++) {
          if (database.protocols[i].keychainID === item.uid) {
            type = 'Folder and Protocols';
            break;
          }
        }
      }
    }

    return {
      id: item.uid,
      name: {
        name: item.name,
      },

      modified_date: getFormattedDate(
        item.model.updatedAt ? item.model.updatedAt : new Date(),
        true
      ),
      modified_by: username,
      type: {
        type: type,
      },
    };
  });

  const handleSelectionChange = (newSelectionModel: any) => {
    const selectedItems = newSelectionModel
      .map((id: string) => list.find((item) => item.uid === id))
      .filter((item: KeychainItem | undefined) => item !== undefined);
    setSelectedItems(selectedItems);
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteKeychain(currentMedication, database);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted keychain: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: KeychainItem[]) => {
          const updatedItems = prevItems.filter(
            (item: KeychainItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete keychain');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete keychain');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteKeychain(parmMedication, database);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} keychains.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} keychains.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete keychain');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Keychains?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} keychains?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Keychains: ' + list.length + ' items'
            : 'Keychains: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Keychains...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[50, 100, 200]}
          checkboxSelection
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.uid === params.id))
          }
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedItems.map(
            (item: KeychainItem) => item.uid
          )}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListKeychains;
