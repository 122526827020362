import { Contact } from '../../models';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import DepartmentItem from './DepartmentItem';
import ModelItem from './ModelItem';

class ContactItem extends ModelItem<Contact> {
  TAG: string = 'ContactItem';
  title: string;
  number: string;
  index: number;
  note: string;
  dbContact: Contact;
  isTitle: boolean;

  constructor(contact: Contact, isTitle: boolean) {
    super(contact, contact.fullName, contact.departmentID);
    this.TAG = 'ContactItem';
    this.title = contact.title ? contact.title : '';
    this.number = contact.number;
    this.index = contact.index;
    this.note = contact.note ? contact.note : '';
    this.pairedDeps = contact.pairedDepIDs ? [] : undefined;
    this.dbContact = contact;
    this.isTitle = isTitle;
  }

  public getDescription(): string {
    return this.title;
  }

  public getContact(): Contact {
    return this.dbContact;
  }

  public getNote(): string {
    return this.note;
  }

  public getTitle(): string {
    return this.title;
  }

  public hasTitle(): boolean {
    return this.isTitle;
  }

  public getNumber(): string {
    return this.number;
  }

  public getIndex(): number {
    return this.index;
  }
}

export default ContactItem;
