import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import InfusionSubItem, { cloneInfusionSubItems } from './InfusionSubItem';
import {
  roundToDec,
  globals,
  getObjectDifference,
  isObjectInArray,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import {
  Concentration,
  Drip,
  Medication,
  MedicationConcentration,
  MedicationProtocol,
  User,
} from '../../models';
import { ProgressStatus } from '../../API';

export const cloneInfusion = (med: InfusionItem): InfusionItem => {
  let newMed = new InfusionItem(med.model);
  newMed.subItems = cloneInfusionSubItems(med.subItems);
  newMed.concentrations = med.concentrations;
  return newMed;
};

class InfusionItem extends ModelItem<Drip> {
  concentrations: Concentration[] = [];
  oldConcentrations: MedicationConcentration[];
  subItems: InfusionSubItem[];
  protocolOptions: MedicationProtocol[];
  routes: string[];
  rxNormCode: string | null | undefined;
  rangeLow: number;
  rangeHigh: number;
  contraindication: string;
  warning: string;
  indication: string;
  interaction: string;
  onset: string;
  duration: string;
  note: string;
  medClass: string;
  action: string;

  overrideItem: InfusionItem | null | undefined;
  activeItem: InfusionItem | null | undefined;

  constructor(model: Drip) {
    super(model, model.name, model.departmentID);
    this.TAG = 'InfusionItem';
    // console.log('MEDICATION:', model.name, model.rxNormCode);
    this.rxNormCode = model.rxNormCode;
    this.subItems = [];
    this.oldConcentrations = model.concentration ?? [];
    this.routes = model.route ?? [];
    this.rangeLow = model.rangeLow ?? 0;
    this.rangeHigh = model.rangeHigh ?? globals.MAX_VALUE;
    this.protocolOptions = model.dripOptions ?? [];

    this.contraindication = model.contraindication ?? '';
    this.warning = model.warning ?? '';
    this.indication = model.indication ?? '';
    this.interaction = model.interaction ?? '';
    this.onset = model.onset ?? '';
    this.duration = model.duration ?? '';
    this.note = model.note ?? '';
    this.medClass = model.medClass ?? '';
    this.action = model.action ?? '';

    if ((model as any).Concentrations != null) {
      this.concentrations = (model as any).Concentrations.items;
    }
  }

  // constructor(name: string){
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.name               = name;
  //     this.dbMedication       = null;
  //     this.medicationSubItems = [];
  //     this.concentrations     = [{
  //         firstAmnt: 10.0,
  //         firstUnit: "mg",
  //         secAmnt: 1,
  //         secUnit: "mL"
  //     }]

  // }

  /**
   * Return nothing for the parent
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  getAmount(weight: PatientWeight): string {
    return '';
  }

  /**
   * Get the list of sub items for this medication
   * @returns Get the list of sub items for this medication
   */
  getInfusionSubItems(): InfusionSubItem[] {
    return this.subItems;
  }

  /**
   * Add a sub item to this medication
   * @param medicationSubItem Add a sub item to this medication
   */
  addInfusionSubItem(medicationSubItem: InfusionSubItem) {
    let find = this.subItems.find(
      (item) => isObjectEqual(item, medicationSubItem) === true
    );
    if (find) return;
    this.subItems.push(medicationSubItem);
    this.subItems.sort((a, b) => a.index - b.index);
  }

  removeInfusionSubItem(medicationSubItem: InfusionSubItem) {
    this.subItems = this.subItems.filter(
      (item) => isObjectEqual(item, medicationSubItem) === false
    );
  }

  /**
   * Get the string representation of the concentration
   * @returns The string representation of the concentration Ex. "1 mg/mL"
   */
  getConcentrations(): MedicationConcentration[] {
    return this.concentrations;
  }

  /**
   * Compare this InfusionItem to another InfusionItem first by name then by concentration
   * @param other A InfusionItem to compare to
   * @returns 0 if the two items are equal, 1 if this item is greater than the other, -1 if this item is less than the other
   */
  compareTo(other: ModelItem<any>): number {
    if (other instanceof InfusionItem) {
      let med = other as InfusionItem;
      if (this.name === med.name) {
        return this.uid === med.uid ? 0 : this.uid > med.uid ? 1 : -1;
      } else if (this.name > med.name) return 1;
      return -1;
    }
    return -1;
  }

  /**
   * Get if the medication is in range of the patient's weight
   * @param weight The patient's weight
   */
  inRange(weight: PatientWeight): boolean {
    return true;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  //Create a toString function to output the data in a readable format
  toString(): string {
    return `InfusionItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
            subItems=${this.subItems.length}
        }`;
  }
}

export default InfusionItem;
