// import Accordion from '../components/accordian';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ViewportList } from 'react-viewport-list';
import AccordionItem from '../../../components/Accordion/AccordionItem_old';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import CategoryItem from '../../../../data/model/CategoryItem';
import KeychainItem from '../../../../data/model/KeychainItem';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import ProtocolItem from '../../../../data/model/ProtocolItem';

/* 09-28-23 Arul: Created Department component  for Protocol page to display the category and protocol accordion*/
const ListDepartmentItems = (props: any) => {
  /* 09-29-23 Arul: handle function to view the protocol*/
  const handleViewProtocol = (data: any) => {
    props.handleViewProtocol(data);
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const ProtocolListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(item, index) => {
          const lastIndex = data.length === index + 1;
          return (
            <div key={index} className="item">
              <AccordionItem
                onItemClick={handleViewProtocol}
                value={item}
                lastIndex={lastIndex}
                keychains={props.data.keychains}
                isPublic={props.isPublic}
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  return (
    <div style={{ flex: 1 }}>
      {props.data.categories && props.data.categories.length >= 1 ? (
        <div className="accordion-container">
          <ViewportList
            items={props.data.categories.filter(
              (item: CategoryItem) => item.status !== 'DEACTIVATED'
            )}
          >
            {(item: CategoryItem, index: any) => {
              const keychain: KeychainItem | undefined =
                props.data.keychains.find(
                  (keychain: KeychainItem) => keychain.uid === item.keychainID
                );
              return (
                <div
                  key={index}
                  className="item"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (
                      props.isPublic &&
                      keychain &&
                      !keychain.isUnlocked &&
                      props.handleUnlockKeychain
                    ) {
                      props.handleUnlockKeychain(keychain);
                    }
                  }}
                >
                  <Accordion activeIndex={index === 0 ? 1 : -1}>
                    <AccordionTab
                      disabled={
                        props.isPublic && keychain && !keychain.isUnlocked
                      }
                      header={
                        <h6 className="departmentItemText">
                          {item.name}
                          {item.status !== 'ACTIVE' && (
                            <Status status={item.status} />
                          )}
                          {props.isPublic &&
                            keychain &&
                            (keychain.isUnlocked ? (
                              <FaLockOpen
                                size="1.0rem"
                                style={{
                                  marginLeft: '1rem',
                                  marginRight: '0rem',
                                  color: '#939393',
                                }}
                              />
                            ) : (
                              <FaLock
                                size="1.0rem"
                                style={{
                                  marginLeft: '1rem',
                                  marginRight: '0rem',
                                  color: '#939393',
                                }}
                              />
                            ))}
                        </h6>
                      }
                    >
                      <ProtocolListRender
                        data={item.protocols.filter(
                          (item: ProtocolItem) => item.status !== 'DEACTIVATED'
                        )}
                      />
                    </AccordionTab>
                  </Accordion>
                </div>
              );
            }}
          </ViewportList>
        </div>
      ) : (
        <div className="headerTextLight" style={{ fontSize: '20px' }}>
          {' '}
          No Records Found
        </div>
      )}
    </div>
  );
};

export default ListDepartmentItems;
