import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';

import { Col, Row } from 'react-grid-system';
import SearchBar from '../../components/Search/SearchBar';
import { ViewportList } from 'react-viewport-list';
import { FaComment, FaUser } from 'react-icons/fa6';
import { DatabaseResponse } from '../../../data/AmplifyDB';

import GeneralSelection from '../../components/Selection/GeneralSelection';
import { commonStyle, getStatusStyles } from './styles';

import { IoChevronForward } from 'react-icons/io5';
import ReviewalItem from '../../../data/model/ReviewalItem';
import { getReviewals } from '../../../data/functions/ReviewalDB';
import { handleReviewalsCache } from '../../../store/actions';
import { HiMiniDocumentCheck } from 'react-icons/hi2';
import { FaCheckCircle, FaFileUpload } from 'react-icons/fa';
import { globals, toTitleCase } from '../../_global/common/Utils';
import { ACKStatus } from '../../../API';
import { User } from '../../../models';
import { MdRateReview } from 'react-icons/md';
const OPEN_STATUS = 0;
const CLOSED_STATUS = 1;

const ReviewPage = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [inputType, setInputType] = useState(OPEN_STATUS);
  const reviewState = useSelector((state: any) => state.review);
  const [allReviewals, setAllReviewals] = useState<ReviewalItem[]>(
    reviewState.reviewals ? reviewState.reviewals : []
  );
  const [filteredData, setFilteredData] = useState<ReviewalItem[]>([]);
  const user: User = useSelector((state: any) => state.user);

  useEffect(() => {
    filter(allReviewals, searchQuery, inputType);
  }, [inputType, allReviewals, searchQuery]);

  useEffect(() => {
    setAllReviewals(reviewState.reviewals);
  }, [reviewState.reviewals]);

  useEffect(() => {
    const loadReviewals = async () => {
      getReviewals(database, user)
        .then((reviewals: ReviewalItem[]) => {
          if (globals.debug) console.log('Reviewals loaded: ', reviewals);
          setAllReviewals(reviewals);
          dispatch<any>(handleReviewalsCache(reviewals));
        })
        .catch((error: any) => {
          if (globals.debug) console.log('Error loading reviewals: ', error);
        });
    };
    loadReviewals();
  }, [database]);

  const filter = (data: ReviewalItem[], search: string, inputType: number) => {
    let filtered = filterDataByStatus(data);
    filtered = handleSearchData(filtered, search);
    setFilteredData(filtered);
  };

  const filterDataByStatus = (data: ReviewalItem[]): ReviewalItem[] => {
    if (inputType === CLOSED_STATUS) {
      return data.filter((item: any) => {
        return (
          item.state === ACKStatus.CLOSED || item.state === ACKStatus.PUBLISHED
        );
      });
    } else {
      return data.filter((item: any) => {
        return (
          item.state !== ACKStatus.CLOSED && item.state !== ACKStatus.PUBLISHED
        );
      });
    }
  };

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
    // filter(allReviewals, text, inputType);
  };

  const handleSearchData = (
    data: ReviewalItem[],
    searchQuery: string
  ): ReviewalItem[] => {
    if (searchQuery === '') return data;
    else {
      return data.filter((item: any) => {
        return (
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.reviewers.some(
            (reviewer: string) =>
              database.users
                .find((user) => user.id === reviewer)
                ?.firstName.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              database.users
                .find((user) => user.id === reviewer)
                ?.lastName.toLowerCase()
                .includes(searchQuery.toLowerCase())
          ) ||
          database.users
            .find((user) => user.id === item.ownerID)
            ?.firstName.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          database.users
            .find((user) => user.id === item.ownerID)
            ?.lastName.toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      });
    }
  };

  const RenderBadges = ({ reviewal }: any) => {
    let { draftGroups, reviewers, acknowledgements, events, comments } =
      reviewal;

    let extraCount = events.length + comments.length;
    return (
      <div className="reviewal-badge-container">
        {draftGroups.length > 0 && (
          <div className="icon-container">
            <label className="icon-text">
              {draftGroups.length}
              <FaFileUpload className="icon" />
            </label>
          </div>
        )}
        {reviewers.length > 0 && (
          <div className="icon-container">
            <label className="icon-text">
              {reviewers.length}
              <FaUser className="icon" />
            </label>
          </div>
        )}
        {acknowledgements > 0 && (
          <div className="icon-container">
            <label className="icon-text">
              {extraCount}
              <FaCheckCircle className="icon" />
            </label>
          </div>
        )}
        {extraCount > 0 && (
          <div className="icon-container">
            <label className="icon-text">
              {extraCount}
              <FaComment className="icon" />
            </label>
          </div>
        )}
      </div>
    );
  };

  const handleReviewClicked = (item: ReviewalItem, index: Number) => () => {
    navigate('/reviewDetails', {
      state: {
        data: item,
      },
    });
  };

  const handleReviewCreate = () => {
    navigate('/reviewCreate', {
      state: {
        data: allReviewals,
      },
    });
  };

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <ProtocolHeader
        name={'Review'}
        rightSideBtn={'edit'}
        isCreateButton={true}
        isCreateActive={true}
        type={'protocol'}
        handleCreate={handleReviewCreate}
      />
      <div>
        <div>
          <Row>
            <Col sm={8}>
              <SearchBar
                containerStyle={{ width: '100%' }}
                value={searchQuery}
                onChange={(searchTerm: string) => {
                  handleSearchChange(searchTerm);
                }}
                onSubmit={(searchTerm: string) => {}}
                placeholder={'Search Reviews...'}
              />
            </Col>
            <Col sm={3}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <GeneralSelection
                  items={['Open', 'Closed']}
                  selectedIndex={inputType}
                  onPress={(item, index) => {
                    setInputType(index);
                  }}
                  labelField={(option) => option}
                  valueField={(option) => option}
                />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className="contentBorder"
          style={{ height: '100%', marginTop: '1rem' }}
        >
          <ViewportList items={filteredData}>
            {(item: ReviewalItem, index: number) => {
              let style = getStatusStyles(item.state);
              return (
                <div
                  data-testid={`viewNotification-${index}`}
                  key={index}
                  className={`cursorPointer hoverItem`}
                  style={{ borderRadius: '10px' }}
                  onClick={handleReviewClicked(item, index)}
                >
                  <div
                    style={{
                      padding: '2.5px 10px 10px',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* Main Notification UI */}
                    <div
                      style={{
                        display: 'flex',
                        marginLeft: '10px',
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      {/* <ReviewUserIcon /> */}
                      <HiMiniDocumentCheck
                        style={{
                          height: '3rem',
                          width: '3rem',
                          marginRight: '1rem',
                          color: '#636363', //style.color,
                        }}
                      />
                      <div
                        className={''}
                        style={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        {/* Notification Timestamp */}
                        <div
                          className="notification-css-grey"
                          style={{ fontSize: '14px' }}
                        >
                          {item.user.firstName + ' ' + item.user.lastName}
                          {/* {getFormattedDate(item.createdAt, false)} */}
                        </div>

                        <div
                          style={{
                            // justifyContent: 'space-around',
                            padding: '0px 0px',
                            marginBottom: '5px',
                          }}
                          className="contentTitle"
                        >
                          {item.title}
                        </div>
                        {/* {item.reviewers && item.reviewers.length > 0 && (
                          <div className="notification-css-grey">
                            {item.reviewers.length}{' '}
                            {item.reviewers.length === 1
                              ? 'Reviewer'
                              : 'Reviewers'}
                          </div>
                        )} */}
                        {/* Notification Message */}
                        {/* <div
                          style={{
                            justifyContent: 'flex-start',
                            fontSize: '14px',
                            color: '#212121',
                          }}
                          className="notification-css-grey"
                        >
                          {item.description}
                        </div> */}
                        <RenderBadges reviewal={item} />
                      </div>
                    </div>

                    {/* Progress bar title */}
                    <div style={{ width: '8%' }}>
                      <div
                        className="lightText"
                        style={{
                          ...commonStyle,
                          border: `2px solid ${style.borderColor}`, // Conditional border color
                          backgroundColor: `${style.backgroundColor}`, // Conditional background color
                          color: `${style.color}`, // Conditional text color
                        }}
                      >
                        <div>{toTitleCase(item.state)}</div>
                      </div>
                    </div>

                    {/* Arrow Icon */}
                    <div
                      className=""
                      style={{
                        width: '4%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <IoChevronForward
                        size={20}
                        style={{ marginRight: '5px' }}
                      />
                    </div>
                  </div>

                  {/* Bottom border */}
                  {index !== filteredData.length - 1 && (
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        background: '#E0E0E0',
                      }}
                    ></div>
                  )}
                </div>
              );
            }}
          </ViewportList>
        </div>
        {/* only display those review that are not in pending and closed */}
        {filteredData.length === 0 && (
          <div className="no-data-container" style={{ marginTop: '15vh' }}>
            <MdRateReview size={220} className="light-grey-icon" />
            <h4 className="light-grey-icon">
              No reviews {inputType === 0 ? 'open' : 'closed'}...
            </h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewPage;
