import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import EquipmentItem from './EquipmentItem';
import { CPRAssist } from '../../models';
import { isObjectInArray } from '../../ui/_global/common/Utils';
import MedicationSubItem from './MedicationSubItem';
import ElectricalSubItem from './ElectricalSubItem';

class CPRItem extends ModelItem<CPRAssist> {
  TAG = 'CPRItem';
  uid: string;
  depID: string;
  name: string;
  protocols: ProtocolItem[];
  epiDoses: MedicationSubItem[];
  defibShocks: ElectricalSubItem[];
  airwayDevice: EquipmentItem | undefined;
  defaultMode: '15:2' | '30:2' | 'Continuous';
  mode: '15:2' | '30:2' | 'Continuous';
  epiOrangeSeconds: number;
  epiRedSeconds: number;
  compressionRate: number;
  ventRatePerMin: number;
  ventilateSoundDelay: number;
  model: CPRAssist;

  constructor(cpr: CPRAssist) {
    super(cpr, 'CPR', cpr.departmentID);
    this.TAG = 'CPRItem';
    this.model = cpr;
    this.uid = cpr.id;
    this.depID = cpr.departmentID;
    this.name = '';
    this.protocols = [];
    this.epiDoses = [];
    this.defibShocks = [];
    this.defaultMode = this.convertToCPRMode(cpr.cprMetaData.defaultMode);
    this.mode = this.defaultMode;
    this.epiOrangeSeconds = cpr.cprMetaData.epiOrangeSec;
    this.epiRedSeconds = cpr.cprMetaData.epiRedSec;
    this.compressionRate = cpr.cprMetaData.compressionRate;
    this.ventRatePerMin = cpr.cprMetaData.ventRatePerMin;
    this.ventilateSoundDelay = cpr.cprMetaData.ventilateSoundDelay;
  }
  getAmount(weight: PatientWeight): string {
    throw new Error('Method not implemented.');
  }

  getEpiDoses(): MedicationSubItem[] {
    return this.epiDoses;
  }

  getDefibShocks(): ElectricalSubItem[] {
    return this.defibShocks;
  }

  getAirwayDevice(): EquipmentItem | undefined {
    return this.airwayDevice;
  }

  getDefaultMode(): '15:2' | '30:2' | 'Continuous' {
    return this.defaultMode;
  }

  getModeString(): string {
    if (this.defaultMode === 'Continuous') return 'Cont.';
    return this.defaultMode;
  }

  getMode(): '15:2' | '30:2' | 'Continuous' {
    return this.mode;
  }

  setMode(mode: '15:2' | '30:2' | 'Continuous'): void {
    this.mode = mode;
  }

  getEpiOrangeSeconds(): number {
    return this.epiOrangeSeconds;
  }

  getEpiRedSeconds(): number {
    return this.epiRedSeconds;
  }

  getCompressionRate(): number {
    return this.compressionRate;
  }

  getVentRatePerMin(): number {
    return this.ventRatePerMin;
  }

  getVentilateSoundDelay(): number {
    return this.ventilateSoundDelay;
  }

  getModel() {
    return this.model;
  }

  convertToCPRMode(mode: string): '15:2' | '30:2' | 'Continuous' {
    switch (mode) {
      case '30:2':
        return '30:2';
      case 'Continuous':
        return 'Continuous';
      default:
        return '15:2';
    }
  }

  getUid(): string {
    return this.uid;
  }

  getDepID(): string {
    return this.depID;
  }

  getName(): string {
    return '';
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof CPRItem)) return false;
    let other: CPRItem = obj as CPRItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  getDescription(): string {
    return this.name;
  }

  addEquipment(equipment: EquipmentItem): void {
    this.airwayDevice = equipment;
  }

  addProtocol(protocol: ProtocolItem): void {
    if (isObjectInArray(this.protocols, protocol)) return;
    this.protocols.push(protocol);
    this.protocols.sort((a, b) => a.name.localeCompare(b.name));
  }

  addMedication(medication: MedicationSubItem): void {
    if (isObjectInArray(this.epiDoses, medication)) return;
    this.epiDoses.push(medication);
    this.epiDoses.sort((a, b) => a.index - b.index);
  }

  addElectrical(electrical: ElectricalSubItem): void {
    if (isObjectInArray(this.defibShocks, electrical)) return;
    this.defibShocks.push(electrical);
    this.defibShocks.sort((a, b) => a.index - b.index);
  }
  removeMedication(medication: MedicationSubItem): void {
    for (let i = 0; i < this.epiDoses.length; i++) {
      if (this.epiDoses[i].equals(medication)) {
        this.epiDoses.splice(i, 1);
        return;
      }
    }
  }
  removeElectrical(electrical: ElectricalSubItem): void {
    for (let i = 0; i < this.defibShocks.length; i++) {
      if (this.defibShocks[i].equals(electrical)) {
        this.defibShocks.splice(i, 1);
        return;
      }
    }
  }

  toString(): string {
    return `CPRItem -> {
            uid=${this.uid},
            name=${this.name},
            depID=${this.depID},
            protocols=${this.protocols.length},
            epiDoses=${this.epiDoses.length},
            defibShocks=${this.defibShocks.length},
            defaultMode=${this.defaultMode},
            epiOrangeSeconds=${this.epiOrangeSeconds},
            epiRedSeconds=${this.epiRedSeconds},
            compressionRate=${this.compressionRate},
            ventRatePerMin=${this.ventRatePerMin},
            ventilateSoundDelay=${this.ventilateSoundDelay}`;
  }
}

export default CPRItem;
