import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createDepartment,
  DatabaseResponse,
  getDepartments,
  Response,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import {
  globals,
  SOFTWARE_PLAN_OPTIONS,
  toTitleCase,
  USAStatesAbrev,
} from '../../../../../_global/common/Utils';
import {
  DepartmentInput,
  processDepartmentsExcelFile,
} from './ProcessDepartmentsExcel';
import { Row } from 'primereact/row';
import { Department, SoftwareType } from '../../../../../../models';
import Dropdown from '../../../../../components/Dropdown/Dropdown';
import HMSwitch from '../../../../../components/general/HMSwitch';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import { InputText } from 'primereact/inputtext';
import { BiRename } from 'react-icons/bi';
import Loading from '../../../../../components/Loading/Loading';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
import { Alert, Snackbar, Tooltip } from '@mui/material';
import { FaCodeMerge, FaPlus, FaTrash, FaUpload } from 'react-icons/fa6';
import { getAllDepartments } from '../../../../../../data/functions/DepartmentDB';
import { IoMdRefresh } from 'react-icons/io';
import { getHashedPin } from '../../../../../_global/common/Encrypt';
import { getSalt } from '../../../../../_global/common/Encrypt';
import {
  addDepartmentIDToAllReferences,
  copyCategoriesAndProtocolFromDeptoDep,
  copyConcentrationsToSubDepartment,
} from '../../../../../../data/AmplifyActions';

const BATCH_SIZE = 20;

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ImportDepartmentPage = (props: any) => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState<any>();
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const dropRef = useRef(null);
  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [newDepartments, setNewDepartments] = useState<
    DepartmentInput[] | undefined
  >(undefined);

  const [state, setState] = useState<any>({
    location: 'MN',
    isPublic: true,
    isPublicSignup: true,
    isOneWeightEnabled: true,
    infusionCalculation: false,
    softwarePlan: SoftwareType.PROFESSIONAL,
    parentDep: null,
    columnName: '',
  });
  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });
  const [allDepartments, setAllDepartments] = useState<DepartmentItem[]>([]);
  const [data, setData] = useState<any>();
  const handleBack = () => {
    navigate(`/actions/import`);
  };

  const handleCreateDepartment = async () => {
    try {
      console.log('Creating departments', newDepartments);
      if (!isCreateActive || !newDepartments) return;
      let promises: Promise<Response>[] = [];
      let responses: Response[] = [];
      for (let dep of newDepartments) {
        let salt = getSalt();
        let value = {
          ...dep,
          hashedPin: getHashedPin(dep.pin, salt),
          saltedPin: salt,
          parentDep: state.parentDep,
        };
        promises.push(createDepartment(value));
        if (promises.length > BATCH_SIZE) {
          let resp = await Promise.all(promises);
          responses.push(...resp);
          promises = [];
        }
      }
      if (promises.length > 0) {
        let resp = await Promise.all(promises);
        responses.push(...resp);
      }
      setSnackbar({
        open: true,
        message: 'Departments Created Successfully',
        severity: 'success',
      });
      if (globals.debug) console.log('Responses', responses);
      promises = [];
      for (let resp of responses) {
        if (resp.type === ResponseType.Failure) {
          throw new Error(resp.data);
        } else {
          let dep: DepartmentItem = new DepartmentItem(resp.data);
          if (state.parentDep != null) {
            promises.push(
              copyConcentrationsToSubDepartment(state.parentDep, dep)
            );
            if (promises.length > BATCH_SIZE) {
              let resp = await Promise.all(promises);
              responses.push(...resp);
              promises = [];
            }
          }
        }
      }
      if (promises.length > 0) {
        let resp = await Promise.all(promises);
        responses.push(...resp);
      }
      for (let resp of responses) {
        if (resp.type === ResponseType.Failure) {
          throw new Error(resp.data);
        }
      }
      if (globals.debug)
        console.log(
          'Finished Copying Concentrations',
          responses.length,
          promises.length
        );

      setSnackbar({
        open: true,
        message: 'Concentrations Copied Successfully',
        severity: 'success',
      });
      setNewDepartments(undefined);
      setState({ ...state, parentDep: null });
    } catch (error) {
      console.error('Error creating department:', error);
    }
  };

  useEffect(() => {
    const fetchAllDepartments = async () => {
      let resp = await getAllDepartments();
      console.log('DEPARTMENTS FETCHED', resp);
      setAllDepartments(resp);
    };
    fetchAllDepartments();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      let resp = await getDepartments();
      if (resp.type === ResponseType.Success) setDepartmentList(resp.data);
    };
    fetchDepartments();
  }, []);

  const handleFileSelect = (e: any) => {
    console.log('File selected:', e.target.files);
    const file = e.target.files[0];
    setSelectedFile(file);
    handleProcessFileUpload(file);

    if (e.target.files.length) {
      const data = e.target.files[0];
    } else {
      setErrorText('No file selected');
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    /* Check if the file is a pdf */

    const file: File = e.dataTransfer?.files[0];
    /* Check to make sure it is a excel workboox */
    if (
      file.type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setSelectedFile(file);
      handleProcessFileUpload(file);
      setErrorText('');
    } else {
      setErrorText('Only Excel Workbook files are allowed');
    }
    setIsDragActive(false);
  };

  const handleProcessFileUpload = async (file: File) => {
    setIsLoading(true);
    processDepartmentsExcelFile(file, allDepartments, state)
      .then((resp) => {
        if (resp.type === ResponseType.Success) {
          setNewDepartments(resp.data);
          setSnackbar({
            open: true,
            message: 'Departments imported successfully',
            severity: 'success',
          });
        } else setErrorText(resp.data);
      })
      .catch((error) => {
        setErrorText(error);
      })
      .finally(() => {
        setIsLoading(false);
        setSelectedFile(null);
      });
  };

  const generateCodes = (index?: number) => {
    if (!newDepartments) return;
    const getCode = (
      departments: DepartmentItem[],
      digits: number = 6
    ): number => {
      let code = Math.floor(
        10 ** (digits - 1) + Math.random() * 9 * 10 ** (digits - 1)
      );
      if (digits === 6) {
        let found = departments.find(
          (dep) => dep.departmentCode === code.toString()
        );
        if (found) return getCode(departments);
      }
      return code;
    };
    let temp = [...newDepartments];
    if (index != null) {
      temp[index].uniqueCode = getCode(allDepartments).toString();
      temp[index].pin = getCode(allDepartments, 4).toString();
    } else {
      temp.forEach((dep) => {
        dep.uniqueCode = getCode(allDepartments).toString();
        dep.pin = getCode(allDepartments, 4).toString();
      });
    }
    setNewDepartments(temp);
  };

  const isCreateActive = useMemo(() => {
    if (!newDepartments || newDepartments.length === 0) return false;
    for (let dep of newDepartments) {
      if (
        !dep.name ||
        (dep.uniqueCode && dep.uniqueCode.length !== 6) ||
        (dep.pin && dep.pin.length !== 4)
      )
        return false;
      //Check if the pin is a number
      if (dep.pin && isNaN(parseInt(dep.pin))) return false;
      if (dep.uniqueCode && isNaN(parseInt(dep.uniqueCode))) return false;
    }
    return true;
  }, [newDepartments]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Import'}
        name={'Import Departments'}
        description={
          'Import sub-departments for a parent department from an excel file. Requires the excel column name to be correct.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        isCancelButton={newDepartments != null}
        handleCancelEdit={() => {
          setNewDepartments(undefined);
        }}
        isCreateButton={newDepartments != null}
        isCreateActive={isCreateActive}
        handleCreate={handleCreateDepartment}
        isCustomButton={newDepartments == null}
        isCustomActive={newDepartments == null}
        customText="Skip"
        handleCustom={() => {
          setNewDepartments([]);
        }}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isLoading && (
        <Loading type="bubbles" message={'Reading Departments from Excel...'} />
      )}
      <div
        style={{
          flex: 1,
          display: 'flex',
          ...props.containerStyle,
          gap: '20px',
        }}
      >
        {/* <Row> */}
        <div
          style={{
            flex: 2.5,
            display: 'flex',
            flexDirection: 'column',
            // marginTop: '-20px',
          }}
        >
          <label
            htmlFor="s"
            className="settings-general-label"
            style={{
              marginLeft: '0.5rem',
              marginTop: '1rem',
              fontSize: '18px',
            }}
          >
            Import Settings
          </label>
          <div
            className="input-container roundBorder"
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0.25rem',
              position: 'sticky',
              top: '20vh', // gap: '0.75rem',
              flex: 1,
            }}
          >
            <label htmlFor="Name" className="ketamine-general-label">
              Column Name
              <span className="required-field ">
                *
                <BiRename
                  className="refresh-icon"
                  onClick={() => {
                    setState({
                      ...state,
                      columnName: toTitleCase(state.columnName),
                    });
                  }}
                  size="1.25rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
            </label>
            <span
              className="contentText greyText"
              style={{ fontSize: '13px', marginLeft: '10px', marginTop: '0px' }}
            >
              Enter the exact name of the column that contains the department
              names.
            </span>
            <div className="input-container">
              <InputText
                type="text"
                className="form-control-general"
                id="columnName"
                name="columnName"
                data-testid="columnName"
                required={true}
                value={state.columnName}
                onChange={(e: any) => {
                  setState({
                    ...state,
                    columnName: e.target.value,
                  });
                }}
                disabled={false}
              />
              <div className="input-border"></div>
            </div>
            <label htmlFor="" className="settings-general-label">
              Parent Department
            </label>
            <span
              className="contentText greyText"
              style={{ fontSize: '13px', marginLeft: '10px', marginTop: '0px' }}
            >
              This is a list of every department that will subscribe to the
              protocol.
            </span>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={departmentList}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.id}
              multiSelect={false}
              onChange={(option: DepartmentItem) =>
                setState({ ...state, parentDep: option })
              }
              onClear={() => setState({ ...state, parentDep: null })}
              placeholder="Search Parent Department..."
            />
            {/* Left column */}
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Software Plan
              </label>
              <Dropdown
                value={state.softwarePlan}
                options={SOFTWARE_PLAN_OPTIONS}
                onChange={(value: any) => {
                  setState({ ...state, softwarePlan: value });
                }}
                style={{
                  marginTop: '-13px',
                  marginLeft: '16px',
                  padding: 0,
                }}
                buttonColor={'#E0EADD'}
                textColor={'#00534C'}
              />
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Location
              </label>
              <Dropdown
                value={state.location}
                options={USAStatesAbrev}
                onChange={(value: any) => {
                  setState({ ...state, location: value });
                }}
                style={{
                  marginTop: '-13px',
                  marginLeft: '16px',
                  padding: 0,
                }}
                buttonColor={'#E0EADD'}
                textColor={'#00534C'}
              />
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Is Public Signup:
              </label>
              <HMSwitch
                checked={state.isPublicSignup}
                onChange={(e, checked) => {
                  setState({ ...state, isPublicSignup: checked });
                }}
              />
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Enable Public Website:
              </label>
              <HMSwitch
                checked={state.isPublic}
                onChange={(e, checked) => {
                  setState({ ...state, isPublic: checked });
                }}
              />
            </div>
            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Enable OneWeight:
              </label>
              <HMSwitch
                checked={state.isOneWeightEnabled}
                onChange={(e, checked) => {
                  setState({ ...state, isOneWeightEnabled: checked });
                }}
              />
            </div>

            <div
              style={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <label htmlFor="" className="settings-general-label">
                Enable Infusion Calculation:
              </label>
              <HMSwitch
                checked={state.infusionCalculation}
                onChange={(e, checked) => {
                  setState({ ...state, infusionCalculation: checked });
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ flex: 9, display: 'flex' }}>
          {newDepartments ? (
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                // marginTop: '-20px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <label
                  htmlFor="s"
                  className="settings-general-label"
                  style={{
                    marginLeft: '0.5rem',
                    marginTop: '1rem',
                    fontSize: '18px',
                    flex: 1,
                  }}
                >
                  Imported Departments: {newDepartments.length}
                </label>
                <Tooltip title={'Delete All'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setNewDepartments([]);
                    }}
                  >
                    <FaTrash
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#8c1010',
                      }}
                    />
                  </span>
                </Tooltip>
                <Tooltip title={'Generate Codes'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      generateCodes();
                    }}
                  >
                    <FaCodeMerge
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
                <Tooltip title={'Add New Department'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setNewDepartments([
                        {
                          name: '',
                          uniqueCode: '',
                          pin: '',
                          hashedPin: '',
                          saltedPin: '',
                          softwarePlan: state.softwarePlan,
                          location: state.location,
                          isPublic: state.isPublic,
                          isPublicSignup: state.isPublicSignup,
                          isOneWeightEnabled: state.isOneWeightEnabled,
                          infusionCalculation: state.infusionCalculation,
                        } as DepartmentInput,
                        ...(newDepartments ?? []),
                      ]);
                    }}
                  >
                    <FaPlus
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#00534C',
                      }}
                    />
                  </span>
                </Tooltip>
              </div>

              <div
                style={{
                  flex: 1,
                  border: '1px solid #e0e0e0',
                  borderRadius: '5px',
                  //Make this max height 500px
                }}
              >
                <ViewportList items={newDepartments}>
                  {(data: DepartmentInput, index: number) => {
                    return (
                      <div
                        onClick={() => {}}
                        key={index}
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          padding: '10px 10px',
                          backgroundColor: index % 2 === 0 ? '#F7FAF6' : '#fff',
                        }}
                      >
                        {/* {data.confirmed && data.pattern && (
                                <InputText
                                  style={{
                                    flex: 0.9,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#000',
                                    margin: '0 0rem 0 3rem',
                                    // borderLeft: 'none',
                                    // borderRight: 'none',
                                    // borderTop: 'none',
                                    // borderRadius: '0px',
                                    // borderColor: '#E0EADD',
                                    // padding: '0 10px 0 10px',
                                  }}
                                  value={data.pattern}
                                  onChange={(e) => {
                                    let temp = [...parsedProtocolData];
                                    temp[index].name = e.target.value;
                                    setParsedProtocolData(temp);
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              )} */}
                        <InputText
                          style={{
                            flex: 8,
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#000',
                            margin: '0 0.5rem 0 2rem',
                          }}
                          value={data.name}
                          onChange={(e) => {
                            let temp = [...newDepartments];
                            temp[index].name = e.target.value;
                            setNewDepartments(temp);
                          }}
                          placeholder="Department Name..."
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onKeyDown={(e) => {
                            //Check for shift + enter to add a new line
                            // if (e.key === 'Enter' && e.shiftKey) {
                            //   e.preventDefault();
                            //   let start = '';
                            //   if (parsedProtocolData.length > 0) {
                            //     let data = parsedProtocolData[0];
                            //     start =
                            //       Number(
                            //         data.endPage ? data.endPage : data.startPage
                            //       ) +
                            //       1 +
                            //       '';
                            //   }
                            //   let temp = [
                            //     {
                            //       folder: state.folder,
                            //       file: '',
                            //       name: '',
                            //       startPage: start,
                            //       endPage: '',
                            //       confirmed: false,
                            //       pattern: '',
                            //     },
                            //     ...parsedProtocolData,
                            //   ];
                            //   setParsedProtocolData(temp);
                            // } else if (e.key === 'e' && e.ctrlKey) {
                            //   console.log('Incrementing end page');
                            //   //Increment the end page by 1
                            //   e.preventDefault();
                            //   let temp = [...parsedProtocolData];
                            //   if (
                            //     temp[index].endPage === '' ||
                            //     temp[index].endPage == null
                            //   )
                            //     temp[index].endPage = (
                            //       Number(temp[index].startPage) + 1
                            //     ).toString();
                            //   else
                            //     temp[index].endPage = (
                            //       Number(temp[index].endPage) + 1
                            //     ).toString();
                            //   setParsedProtocolData(temp);
                            // } else if (e.key === 's' && e.ctrlKey) {
                            //   //Increment the start page by 1
                            //   e.preventDefault();
                            //   let temp = [...parsedProtocolData];
                            //   temp[index].startPage = (
                            //     Number(temp[index].startPage) + 1
                            //   ).toString();
                            //   setParsedProtocolData(temp);
                            // } else if (e.key === 'w' && e.ctrlKey) {
                            //   //Increment the end page by 1
                            //   e.preventDefault();
                            //   let temp = [...parsedProtocolData];
                            //   if (
                            //     temp[index].endPage === '' ||
                            //     temp[index].endPage == null
                            //   )
                            //     temp[index].endPage = (
                            //       Number(temp[index].startPage) - 1
                            //     ).toString();
                            //   else
                            //     temp[index].endPage = (
                            //       Number(temp[index].endPage) - 1
                            //     ).toString();
                            //   setParsedProtocolData(temp);
                            // } else if (e.key === 'a' && e.ctrlKey) {
                            //   //Increment the start page by 1
                            //   e.preventDefault();
                            //   let temp = [...parsedProtocolData];
                            //   temp[index].startPage = (
                            //     Number(temp[index].startPage) - 1
                            //   ).toString();
                            //   setParsedProtocolData(temp);
                            // } else if (e.key === 't' && e.ctrlKey) {
                            //   //toTitleCase the name
                            //   e.preventDefault();
                            //   let temp = [...parsedProtocolData];
                            //   temp[index].name = toTitleCase(
                            //     temp[index].name ?? ''
                            //   );
                            //   setParsedProtocolData(temp);
                            // }
                          }}
                        />
                        <InputText
                          style={{
                            flex: 1,
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#000',
                            marginRight: '2px',
                          }}
                          value={data.uniqueCode}
                          onChange={(e) => {
                            let temp = [...newDepartments];
                            temp[index].uniqueCode = e.target.value;
                            setNewDepartments(temp);
                          }}
                          placeholder="Unique Code..."
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          onKeyDown={(e) => {}}
                        />
                        <InputText
                          style={{
                            flex: 1,
                            fontSize: '14px',
                            fontWeight: '500',
                            color: '#000',
                            marginLeft: '2px',
                          }}
                          value={data.pin}
                          onChange={(e) => {
                            let temp = [...newDepartments];
                            temp[index].pin = e.target.value;
                            setNewDepartments(temp);
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          placeholder="Pin..."
                          onKeyDown={(e) => {}}
                        />
                        <HMSwitch
                          tooltipText="Public Department"
                          tooltipPosition="top"
                          checked={data.isPublic}
                          onChange={(e, checked) => {
                            let temp = [...newDepartments];
                            temp[index].isPublic = checked;
                            setNewDepartments(temp);
                          }}
                        />
                        <HMSwitch
                          tooltipText="Public Signup"
                          tooltipPosition="top"
                          checked={data.isPublicSignup}
                          onChange={(e, checked) => {
                            let temp = [...newDepartments];
                            temp[index].isPublicSignup = checked;
                            setNewDepartments(temp);
                          }}
                        />
                        <HMSwitch
                          tooltipText="OneWeight"
                          tooltipPosition="top"
                          checked={data.isOneWeightEnabled}
                          onChange={(e, checked) => {
                            let temp = [...newDepartments];
                            temp[index].isOneWeightEnabled = checked;
                            setNewDepartments(temp);
                          }}
                        />
                        <HMSwitch
                          tooltipText="Infusion Calculation"
                          tooltipPosition="top"
                          checked={data.infusionCalculation}
                          onChange={(e, checked) => {
                            let temp = [...newDepartments];
                            temp[index].infusionCalculation = checked;
                            setNewDepartments(temp);
                          }}
                        />
                        <Dropdown
                          value={data.softwarePlan}
                          options={SOFTWARE_PLAN_OPTIONS}
                          onChange={(value: any) => {
                            let temp = [...newDepartments];
                            temp[index].softwarePlan = value;
                            setNewDepartments(temp);
                          }}
                          style={{
                            marginTop: '-13px',
                            marginLeft: '16px',
                            marginRight: '0.5rem',
                            padding: 0,
                          }}
                          buttonColor={'#E0EADD'}
                          textColor={'#00534C'}
                        />
                        <Tooltip
                          title={'Generate Single Code'}
                          placement="bottom"
                          arrow
                        >
                          <span
                            className={`iconButton`}
                            onClick={() => {
                              generateCodes(index);
                            }}
                          >
                            <FaCodeMerge
                              data-testid="isDotButton"
                              className="icon"
                              style={{
                                padding: '6px',
                                color: '#616161',
                              }}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={'Remove Department'}
                          placement="bottom"
                          arrow
                        >
                          <span
                            className={`iconButton`}
                            onClick={(e) => {
                              e.stopPropagation();
                              let temp = [...newDepartments];
                              temp.splice(index, 1);
                              setNewDepartments(temp);
                            }}
                          >
                            <FaTimes
                              data-testid="isFullScreenButton"
                              className="icon"
                              style={{
                                padding: '6px',
                                color: '#8c1010',
                              }}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          ) : (
            <div
              className="cursorPointer dragDropDotGridModalWorkbook"
              style={{
                display: 'flex',
                flex: 1,
                background: isDragActive ? '#E0EADD' : 'transparent',
                padding: '20px 0px',
                border: isDragActive
                  ? '1px dashed #00534C'
                  : errorText
                    ? '1px dashed #880808'
                    : '1px dashed #cdc8c8',
              }}
              onClick={() => {
                const fileInput = document.getElementById('fileInput');
                fileInput?.click();
              }}
              ref={dropRef}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragActive(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragActive(false);
              }}
              onDrop={handleDrop}
            >
              <input
                type="file"
                accept=".xlsx"
                className="pdfInputBox"
                id="fileInput"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              {errorText !== '' && (
                <div
                  className="errorText"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    marginBottom: '5px',
                    fontSize: '16px',
                  }}
                >
                  {errorText}
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                {isDragActive ? (
                  <RiUpload2Line className="drag-drop-icon" />
                ) : (
                  <RiDownload2Line className="drag-drop-icon" />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                Drag and Drop
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                or{' '}
                <span
                  style={{ marginLeft: '5px' }}
                  className="green_accent_text"
                >
                  {' '}
                  Browse
                </span>
              </div>
              <div
                style={{ fontSize: '12px' }}
                className="grey_text ketamine-general-label"
              >
                Support: EXCEL ONLY
              </div>
            </div>
          )}
        </div>

        {/* </Row> */}
      </div>
    </div>
  );
};

export default ImportDepartmentPage;
