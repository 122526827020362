import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import { ageDropdownValues } from '../../_global/constants/Protocol_constants';
import { globals, mapIndexToRange } from '../../_global/common/Utils';
import PatientAge from '../../_global/common/PatientAge';

type AgeRangeDialogProps = {
  dialogVisible: boolean;
  setDialogVisible: (visible: boolean) => void;
  headerText: string;
  headerTitle?: string;
  InputLabel?: string;
  value?: PatientAge | undefined;
  handleSet: (age: PatientAge) => void;
  type?: any;
};

function calculateAge(months: number) {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;
  return { years, months: remainingMonths };
}

/* 10-20-23 Praveen: Created the popup globally for Max and Min Range related changes */
const AgeRangeDialog: React.FC<AgeRangeDialogProps> = ({
  value,
  dialogVisible,
  setDialogVisible,
  headerText,
  headerTitle,
  InputLabel,
  handleSet,
  type,
}) => {
  const [rangeSelect, setRangeSelect] = useState('No Min - 0 Kg');
  const [ageValueYears, setAgeValueYears] = useState<number>(0);
  const [ageValueMonths, setAgeValueMonths] = useState<number>(0);
  const [selectedAge, setSelectedAge] = useState<any>();

  const handleButtonClick = (label: string) => {
    setRangeSelect(label);
    setAgeValueYears(0);
    setAgeValueMonths(0);
  };

  //populate the range value in field
  useEffect(() => {
    if (value == null) return;
    if (headerText === 'Low Age') {
      if (value.ageValue === 0) {
        setRangeSelect('No Min - 0 Months');
        setAgeValueYears(0);
        setAgeValueMonths(0);
      } else {
        const { years, months } = calculateAge(Number(value.ageValue));
        setAgeValueYears(years);
        setAgeValueMonths(months);
        setRangeSelect('');
      }
    } else {
      if (value.ageValue === 0) {
        setRangeSelect('No max - Inf. Months');
        setAgeValueYears(0);
        setAgeValueMonths(0);
      } else {
        const { years, months } = calculateAge(Number(value.ageValue));
        setAgeValueYears(years);
        setAgeValueMonths(months);
        setRangeSelect('');
      }
    }
  }, [value, headerText]);

  /*10-31-2023,Arul: handling function for submit*/
  const handleAgeSet = async () => {
    let age = new PatientAge(0);
    if (ageValueYears === 0 && ageValueMonths === 0) {
      if (rangeSelect === 'Pediatric - 12 Years') age = new PatientAge(144);
      else
        age = new PatientAge(headerText === 'Low Age' ? 0 : globals.MAX_VALUE);
    } else age = new PatientAge(ageValueYears * 12 + ageValueMonths);
    console.log('age', age);
    handleSet(age);
  };

  return (
    <Dialog
      header={headerText}
      visible={dialogVisible}
      style={{ width: '50vw', maxWidth: '750px' }}
      onHide={() => setDialogVisible(false)}
    >
      <div className="outerDialogDiv">
        <div className="dialogInputContainer">
          <div style={{ padding: '10px' }}>
            <>
              <div>
                <div style={{ marginBottom: '10px' }}>
                  <h6 className="headerTextMargin">Department Preset Ranges</h6>
                  <span className="contentText">
                    Select the preset cuttoffs determine by the department.
                  </span>
                  <span className="contentText">
                    NOTE: This is the {InputLabel} bound so if this is an adult
                    dose the {headerText === 'Low Age' ? headerTitle : 'lower'}{' '}
                    bound is set at the cut off between pediatric and adult.
                  </span>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      handleButtonClick(
                        headerText === 'Low Age'
                          ? 'No Min - 0 Months'
                          : 'No Max - Inf.'
                      );
                    }}
                    className={` btn-rightMargin ${
                      rangeSelect === 'No Min - 0 Months' ||
                      rangeSelect === 'No Max - Inf.'
                        ? 'secondary-button-range'
                        : 'secondary-button-large'
                    }`}
                  >
                    {headerText === 'Low Age'
                      ? 'No Min - 0 Months'
                      : 'No Max - Inf.'}
                  </Button>
                  <Button
                    onClick={() => {
                      handleButtonClick(`Pediatric - 12 Years`);
                    }}
                    className={` btn-rightMargin ${
                      rangeSelect === 'Pediatric - 12 Years' ||
                      rangeSelect === 'Neonate - 12 Years'
                        ? 'secondary-button-range'
                        : 'secondary-button-large'
                    }`}
                  >
                    {`Pediatric - 12 Years`}
                  </Button>

                  <hr />
                </div>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <h6 className="headerTextMargin">Direct Entry</h6>
                <span className="contentText">
                  Entry the {headerText === 'Low Age' ? headerTitle : 'lower'}{' '}
                  bound in Years and/or Months.
                </span>
                <span className="contentText">
                  NOTE: This is the {InputLabel} bound cut off for this dose.
                </span>
              </div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '10px',
                  }}
                >
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon-left">
                      <span>Years:</span>
                    </span>
                    <div className="input-container rangeContainer">
                      <InputNumber
                        value={ageValueYears}
                        mode="decimal"
                        data-testid="ageValueYears"
                        maxFractionDigits={1}
                        autoFocus={true}
                        onChange={(e: any) => {
                          setAgeValueYears(e?.value);
                          setRangeSelect('');
                        }}
                        placeholder=""
                        className="dialogInput"
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            handleAgeSet();
                          }
                        }}
                      />
                      <div className="dialogInput-border"></div>
                    </div>
                  </div>
                  <div className="p-inputgroup flex-1">
                    <span className="p-inputgroup-addon-left">
                      <span>Months:</span>
                    </span>
                    <div className="input-container rangeContainer">
                      <InputNumber
                        value={ageValueMonths}
                        mode="decimal"
                        data-testid="ageValueMonths"
                        maxFractionDigits={1}
                        autoFocus={true}
                        onChange={(e: any) => {
                          setAgeValueMonths(e?.value);
                          setRangeSelect('');
                        }}
                        placeholder=""
                        className="dialogInput"
                        onKeyDown={(e: any) => {
                          if (e.key === 'Enter') {
                            handleAgeSet();
                          }
                        }}
                      />
                      <div className="dialogInput-border"></div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>

          <div className="dialogButtons">
            <Button
              data-testid="cancelBtnn"
              className="secondary-button-dialog btn-rightMargin"
              onClick={() => setDialogVisible(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="saveBtnn"
              className="primary-button"
              disabled={type === 'Vital' && !selectedAge}
              onClick={handleAgeSet}
            >
              Set
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AgeRangeDialog;
