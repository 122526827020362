import React, { useState } from 'react';

import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { ModelMetaData, User } from '../../../../models';

import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import VitalItem from '../../../../data/model/VitalItem';
import VitalDose from '../../protocol/edit/Vital/VitalDose';
interface FolderComparisonProps {
  focusItem: VitalItem;
  handleBack: () => void;
  viewText?: string;
}

const VitalFullView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5
            className="ketmine-header-text backTextLight hoverText"
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <span>
              <IoArrowBack
                data-testid="isBackBttn"
                size="1.15rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            General Information
          </h5>

          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {focusItem.modifiedBy
                    ? focusItem.modifiedBy.firstName +
                      ' ' +
                      focusItem.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>

              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
              </div>

              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.uid}
                    <span>
                      {isCopied && isCopied === focusItem.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(focusItem.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                focusItem.status === ProgressStatus.DRAFT &&
                focusItem.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.activeID}
                      <span>
                        {isCopied && isCopied === focusItem.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(focusItem.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.name}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>

          {/* <label htmlFor="Name" className="ketamine-general-label">
            Options <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.options ? focusItem.options.join(', ') : ''}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div> */}
        </div>
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5 className="ketmine-header-text">Vital Options</h5>

          <ViewportList items={focusItem.options}>
            {(item: any, index: any) => (
              <div key={index} style={{ cursor: 'pointer' }}>
                <VitalDose dose={item} doseIndex={index} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default VitalFullView;
