import { useCallback, useEffect, useMemo, useState } from 'react';
import Dropzone from 'react-dropzone';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { set } from 'lodash';
import GenerateThumbnail from '../../../_global/common/GenerateThumbnail';
import FullScreenModal from './FullScreeenModal';
import { globals } from '../../../_global/common/Utils';

/* 11-30-23 Arul: Created the  common component for drag and drop images*/
const DragDropPreviewPage = (props: any) => {
  const [files, setFiles] = useState<any>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [isDragActive, setIsDragActive] = useState(false);
  const [errorText, setErrorText] = useState('');
  const objURLS = useMemo(
    () => files.map((file: File) => URL.createObjectURL(file)),
    [files]
  );
  const [fullScreenIndex, setFullScreenIndex] = useState(-1);

  useEffect(() => {
    if (props.existingFiles) {
      setFiles(props.existingFiles);
    }
  }, [props.existingFiles]);

  /* 11-30-23 Arul: function for drag and drop*/
  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      const newArray = [...files, ...acceptedFiles];
      setFiles(newArray);
      props.handleChangefiles(newArray);
    },
    [files, props]
  );

  // Use useCallback for the delete handler
  const handleDelete = useCallback(() => {
    const data: any = [...files];
    data.splice(selectedIndex, 1);
    setFiles(data);
    // objURLS.forEach((url: URL) => URL.revokeObjectURL(url));
    props.handleChangefiles(data);
    setIsDelete(false);
    setSelectedIndex(null);
  }, [files, selectedIndex, objURLS, props]);

  /* 11-30-23 Arul: validation for max images to drag and drop*/
  const isValid = useMemo(() => {
    if (files.length <= 10) {
      return false;
    } else {
      return true;
    }
  }, [files]);

  const handleViewFile = (e: any, selectedIndex: number) => {
    e.stopPropagation();
    setFullScreenIndex(selectedIndex);
  };

  return (
    <div>
      <FullScreenModal
        isVisible={fullScreenIndex >= 0}
        objURLs={objURLS}
        files={files}
        onClose={() => {
          setFullScreenIndex(-1);
        }}
        startIndex={fullScreenIndex}
      />
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove attachment?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDelete}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want remove this attachment from the notification?'
          }
        />
      )}
      <Dropzone
        maxFiles={10}
        // maxSize={5242880}
        accept={{ 'image/*': [], 'video/*': [] }}
        onDragOver={() => {
          setIsDragActive(true);
          if (errorText) setErrorText('');
        }}
        onDragLeave={() => setIsDragActive(false)}
        onDropRejected={(files: any[], event: any) => {
          if (globals.debug) console.log(files, event);
          setErrorText(
            files.length +
              ' file' +
              (files.length > 1 ? 's' : '') +
              ' type not supported\nOnly JPEG, PNG, PDF, MP4, IMEG, MOV accepted'
          );
          setIsDragActive(false);
        }}
        onDropAccepted={(files: File[], event: any) => {
          if (errorText) setErrorText('');
          handleDrop(files);
          setIsDragActive(false);
        }}
        disabled={isValid}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className="cursorPointer dragDropDotGridModal"
            style={{
              background: isDragActive ? '#E0EADD' : '#FFF',
              border: isDragActive
                ? '2px dashed #00534C'
                : errorText
                  ? '2px dashed #880808'
                  : '2px dashed #cdc8c8',
            }}
          >
            <input {...getInputProps({ disabled: false })} />
            {errorText !== '' && (
              <div
                className="errorText"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                  fontSize: '16px',
                }}
              >
                {errorText}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              {isDragActive ? (
                <RiUpload2Line className="drag-drop-icon" />
              ) : (
                <RiDownload2Line className="drag-drop-icon" />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              Drag and Drop
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              or{' '}
              <span style={{ marginLeft: '5px' }} className="green_accent_text">
                {' '}
                Browse
              </span>
            </div>
            <div
              style={{ fontSize: '12px' }}
              className="grey_text ketamine-general-label"
            >
              Support: JPEG, PNG, MP4, IMEG, MOV
            </div>
          </div>
        )}
      </Dropzone>

      <div className="image-gallery previewImage">
        {files?.map((file: any, index: any) => (
          <div key={file.name}>
            {file.type.startsWith('image') && (
              <div
                className="cursorPointer"
                style={{ width: '150px', marginTop: '10px' }}
                onClick={(e) => handleViewFile(e, index)}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    marginBottom: '2px',
                  }}
                >
                  <AiOutlineCloseCircle
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIndex(index);
                      setIsDelete(true);
                    }}
                    className="red_text cursorPointer icon-normal"
                  />
                </div>
                <img
                  src={objURLS[index]}
                  alt={file.name}
                  style={{ width: '150px' }}
                />
              </div>
            )}
            {file.type.startsWith('video') && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'end',
                    marginBottom: '2px',
                  }}
                >
                  <AiOutlineCloseCircle
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedIndex(index);
                      setIsDelete(true);
                    }}
                    className="red_text cursorPointer icon-normal"
                  />
                </div>
                <GenerateThumbnail
                  file={file}
                  onClick={(e: any) => handleViewFile(e, index)}
                  isDelete={true}
                  onDelete={(e) => {
                    e.stopPropagation();
                    setSelectedIndex(index);
                    setIsDelete(true);
                  }}
                />
              </>
              // <div className='cursorPointer' style={{ width: '133px', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
              //   <video src={objURLS[index]} controls style={{ width: '133px' }} />
              // </div>
            )}
            {file.type === 'application/pdf' && (
              <GenerateThumbnail
                file={file}
                onClick={(e: any) => handleViewFile(e, index)}
              />
              // <div className='cursorPointer' style={{ width: '100', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
              //   <embed src={objURLS[index]} width="150" height="85" style={{zIndex: 1}} onClick={(e) => handleViewFile(e, index)}/>
              //   {/*Create a clickable item over this one to register the touch */}
              //   <div style={{ position: 'absolute', top: 0, left: 0, width: '150px', height: '85px', zIndex: 2}} onClick={(e) => handleViewFile(e, index)}></div>
              // </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DragDropPreviewPage;
