import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FaCopy, FaTimes } from 'react-icons/fa';
import { toTitleCase } from '../../../_global/common/Utils';
import { FormQuestion } from '../../../../models';
import { FormType } from '../../../../API';
import { InputText } from 'primereact/inputtext';
import { on } from 'events';
import { set } from 'lodash';

interface QuestionBoxProps {
  question: FormQuestion;
  index: number;
  isSelected: boolean;
  onSelect: () => void;
  onCopy: () => void;
  onRemove: () => void;
  onInputChange: (updates: { [field: string]: string | string[] }) => void;
  onTypeChange: (newType: FormType) => void;
  formik: any;
  isDisabled?: boolean;
}

const questionTypes = Object.values(FormType).filter(
  (type) => type !== FormType.LONG_ANSWER
);

const QuestionBox: React.FC<QuestionBoxProps> = ({
  question,
  index,
  isSelected,
  onSelect,
  onCopy,
  onRemove,
  onInputChange,
  onTypeChange,
  formik,
  isDisabled,
}) => {
  const [isFocused, setIsFocused] = useState({
    question: false,
    description: false,
    shortAnswer: false,
    longAnswer: false,
  });
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) {
      if (question.type === FormType.TRUE_FALSE) {
        onInputChange({
          answers: ['True', 'False'],
          correctAnswer: question.correctAnswer || [],
        });
      } else if (
        question.type === FormType.SINGLE_SELECT ||
        question.type === FormType.MULTI_SELECT
      ) {
        onInputChange({
          answers: question.answers || ['', ''],
          correctAnswer: question.correctAnswer || [],
        });
      } else {
        onInputChange({
          answers: question.answers || [],
          correctAnswer: question.correctAnswer || [],
        });
      }
      setIsFirstRender(false);
    }
  }, [question.type, isFirstRender]);

  useEffect(() => {
    if (!isFirstRender) {
      if (question.type === FormType.TRUE_FALSE) {
        onInputChange({
          answers: ['True', 'False'],
          correctAnswer: [],
        });
      } else if (
        question.type === FormType.SINGLE_SELECT ||
        question.type === FormType.MULTI_SELECT
      ) {
        onInputChange({
          answers: ['', ''],
          correctAnswer: [],
        });
      } else {
        onInputChange({
          answers: [],
          correctAnswer: [],
        });
      }
    }
  }, [question.type]);

  const renderOptions = () => {
    switch (question.type) {
      case FormType.SINGLE_SELECT:
      case FormType.MULTI_SELECT:
        const defaultOptions = question.answers?.length
          ? question.answers
          : ['', ''];
        const isSingleSelect = question.type === FormType.SINGLE_SELECT;

        return (
          <div className="answers-container">
            {defaultOptions.map((answer: any, i: number) => {
              const isChecked =
                question.correctAnswer?.includes(answer) || false;

              return (
                <div key={i} className="option">
                  <div className="option-input">
                    <input
                      type={isSingleSelect ? 'radio' : 'checkbox'}
                      name={`question-${index}`}
                      value={answer}
                      checked={isChecked}
                      onChange={() => {
                        if (isSingleSelect) {
                          onInputChange({
                            correctAnswer: [answer],
                          });
                        } else {
                          const updatedCorrectAnswer =
                            isChecked && question.correctAnswer
                              ? question.correctAnswer.filter(
                                  (a) => a !== answer
                                )
                              : [...(question.correctAnswer || []), answer];
                          onInputChange({
                            correctAnswer: updatedCorrectAnswer,
                          });
                        }
                      }}
                      disabled={isDisabled}
                    />
                  </div>

                  <div
                    className="search-custom-input"
                    style={{ marginBottom: 'unset' }}
                  >
                    <InputText
                      type="text"
                      value={answer}
                      autoFocus
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const updatedAnswers = [...defaultOptions];
                        updatedAnswers[i] = newValue;

                        if (isChecked) {
                          onInputChange({
                            correctAnswer: [newValue],
                            answers: updatedAnswers,
                          });
                        } else {
                          onInputChange({
                            answers: updatedAnswers,
                          });
                        }
                      }}
                      disabled={isDisabled}
                      className="p-inputtextborderNone p-inputtext form-control pointer"
                      style={{
                        cursor: 'auto',
                        textAlign: 'left',
                        fontWeight: 400,
                        border: 'none',
                      }}
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const updatedAnswers = [...defaultOptions, ''];
                          onInputChange({
                            answers: updatedAnswers,
                          });
                        }
                      }}
                    />
                  </div>
                  <FaTimes
                    className="remove-icon"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering parent clicks
                      const updatedAnswers = question.answers
                        ? [...question.answers]
                        : [];

                      const updatedCorrectAnswer =
                        question.correctAnswer?.filter((a) => a !== answer) ||
                        [];
                      updatedAnswers.splice(i, 1);
                      onInputChange({
                        correctAnswer: updatedCorrectAnswer,
                        answers: updatedAnswers,
                      });
                    }}
                  />
                </div>
              );
            })}
            <div
              onClick={() => {
                if (!isDisabled) {
                  const updatedAnswers = [...defaultOptions, ''];
                  onInputChange({
                    answers: updatedAnswers,
                  });
                }
              }}
              style={{
                cursor: 'pointer',
                color: '#5a8f5c',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              + Add Option
            </div>
          </div>
        );

      case FormType.DROPDOWN:
        const defaultDropdownOptions = question.answers?.length
          ? question.answers
          : [''];
        return (
          <div className="answers-container">
            {defaultDropdownOptions.map((answer, i) => {
              const isChecked =
                question.correctAnswer?.includes(answer) || false;

              return (
                <div key={i} className="option">
                  <div className="option-input">
                    <input
                      type={'radio'}
                      name={`question-${index}`}
                      value={answer}
                      checked={isChecked}
                      onChange={() => {
                        onInputChange({
                          correctAnswer: [answer],
                        });
                      }}
                      disabled={isDisabled}
                    />
                  </div>

                  <div
                    className="search-custom-input"
                    style={{ marginBottom: 'unset' }}
                  >
                    <InputText
                      type="text"
                      value={answer}
                      autoFocus
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const updatedAnswers = [...defaultDropdownOptions];
                        updatedAnswers[i] = newValue;

                        if (isChecked) {
                          onInputChange({
                            correctAnswer: [newValue],
                            answers: updatedAnswers,
                          });
                        } else {
                          onInputChange({
                            answers: updatedAnswers,
                          });
                        }
                      }}
                      disabled={isDisabled}
                      className="p-inputtextborderNone p-inputtext form-control pointer"
                      style={{
                        cursor: 'auto',
                        textAlign: 'left',
                        fontWeight: 400,
                        border: 'none',
                      }}
                      onBlur={formik.handleBlur}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          const updatedAnswers = [
                            ...defaultDropdownOptions,
                            '',
                          ];
                          onInputChange({
                            answers: updatedAnswers,
                          });
                        }
                      }}
                    />
                  </div>
                  <FaTimes
                    className="remove-icon"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering parent clicks
                      const updatedAnswers = question.answers
                        ? [...question.answers]
                        : [];

                      const updatedCorrectAnswer =
                        question.correctAnswer?.filter((a) => a !== answer) ||
                        [];
                      updatedAnswers.splice(i, 1);
                      onInputChange({
                        correctAnswer: updatedCorrectAnswer,
                        answers: updatedAnswers,
                      });
                    }}
                  />
                </div>
              );
            })}
            <div
              onClick={() => {
                if (!isDisabled) {
                  const updatedAnswers = [...defaultDropdownOptions, ''];
                  onInputChange({
                    answers: updatedAnswers,
                  });
                }
              }}
              style={{
                cursor: 'pointer',
                color: '#5a8f5c',
                fontSize: '16px',
                textAlign: 'center',
              }}
            >
              + Add Option
            </div>
          </div>
        );

      case FormType.SHORT_ANSWER:
        return (
          <div className="answers-container">
            <div
              className={`input-wrapper ${isFocused.shortAnswer ? 'focused' : ''}`}
            >
              <input
                type="text"
                placeholder={'Enter your short answer'}
                value={question.correctAnswer || ''}
                onChange={(e) =>
                  onInputChange({ correctAnswer: [e.target.value] })
                }
                onFocus={() =>
                  setIsFocused((prev) => ({
                    ...prev,
                    shortAnswer: true,
                  }))
                }
                onBlur={() =>
                  setIsFocused((prev) => ({
                    ...prev,
                    shortAnswer: false,
                  }))
                }
                disabled={isDisabled}
              />
            </div>
          </div>
        );
      // case FormType.LONG_ANSWER:
      //   return (
      //     <div className="answers-container">
      //       <div
      //         className={`input-wrapper ${isFocused.longAnswer ? 'focused' : ''}`}
      //       >
      //         <input
      //           type="text"
      //           placeholder={'Enter your long answer'}
      //           value={question.correctAnswer || ''}
      //           onChange={(e) =>
      //             onInputChange({ correctAnswer: [e.target.value] })
      //           }
      //           onFocus={() =>
      //             setIsFocused((prev) => ({
      //               ...prev,
      //               longAnswer: true,
      //             }))
      //           }
      //           onBlur={() =>
      //             setIsFocused((prev) => ({
      //               ...prev,
      //               longAnswer: false,
      //             }))
      //           }
      //           disabled={isDisabled}
      //         />
      //       </div>
      //     </div>
      //   );

      case FormType.NUMBER_ANSWER:
        return (
          <div className="answers-container">
            <div className="option">
              <input
                type="number"
                placeholder="Enter a number"
                value={question.correctAnswer || ''}
                onChange={(e) =>
                  onInputChange({ correctAnswer: [e.target.value] })
                }
                disabled={isDisabled}
              />
            </div>
          </div>
        );

      case FormType.DATE_ENTRY:
        return (
          <div className="answers-container">
            <div className="option">
              <input
                type="date"
                value={question.correctAnswer || ''}
                onChange={(e) =>
                  onInputChange({ correctAnswer: [e.target.value] })
                }
                disabled={isDisabled}
              />
            </div>
          </div>
        );

      case FormType.TRUE_FALSE:
        return (
          <div className="answers-container">
            {question.answers?.map((option: any) => {
              const isChecked = question.correctAnswer?.[0] === option;

              return (
                <div className="option" key={option}>
                  <input
                    type="radio"
                    name={`question-${index}`}
                    value={option}
                    checked={isChecked}
                    onChange={() => onInputChange({ correctAnswer: [option] })}
                    disabled={isDisabled}
                  />
                  <label className="option-label">{option}</label>
                </div>
              );
            })}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      key={question.index}
      className={`question-box ${isSelected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      <div className="question-box-header">
        <div style={{ fontSize: '16px', paddingLeft: '5px', color: 'grey' }}>
          <FontAwesomeIcon icon={faGripVertical} className="ham-bar" />
          {toTitleCase(question.type.replace('_', ' '))}
        </div>
        {isSelected && (
          <div style={{ fontSize: '16px' }}>
            <select
              value={question.type}
              onChange={(e) => onTypeChange(e.target.value as FormType)}
              style={{ marginRight: '10px' }}
              disabled={isDisabled}
            >
              {questionTypes.map((type) => (
                <option key={type} value={type}>
                  {toTitleCase(type.replace('_', ' '))}
                </option>
              ))}
            </select>
            <FaCopy
              className="copy-icon"
              onClick={(e) => {
                e.stopPropagation();
                isDisabled || onCopy();
              }}
            />
            <FaTimes
              className="remove-icon"
              onClick={(e) => {
                e.stopPropagation();
                isDisabled || onRemove();
              }}
            />
          </div>
        )}
      </div>
      <div
        className={`text-wrapper ${isFocused.question ? 'focused' : ''} ${isDisabled ? 'disabled' : ''}`}
      >
        <input
          type="text"
          placeholder="Enter your question"
          style={{ fontSize: '18px', fontWeight: 700 }}
          value={question.question}
          onChange={(e) => onInputChange({ question: e.target.value })}
          onFocus={() =>
            setIsFocused((prev) => ({
              ...prev,
              question: true,
            }))
          }
          onBlur={() =>
            setIsFocused((prev) => ({
              ...prev,
              question: false,
            }))
          }
          disabled={isDisabled}
        />
      </div>
      {formik.errors.questions && formik.errors.questions[index] && (
        <span className="errorText">
          {formik.errors.questions[index].question}
        </span>
      )}
      {isSelected && (
        <div
          className={`text-wrapper ${isFocused.description ? 'focused' : ''} ${isDisabled ? 'disabled' : ''}`}
          style={{ fontSize: '12x' }}
        >
          <input
            type="text"
            placeholder="Enter your description (optional)"
            value={question.description || ''}
            onChange={(e) => onInputChange({ description: e.target.value })}
            onFocus={() =>
              setIsFocused((prev) => ({
                ...prev,
                description: true,
              }))
            }
            onBlur={() =>
              setIsFocused((prev) => ({
                ...prev,
                description: false,
              }))
            }
            disabled={isDisabled}
          />
        </div>
      )}
      <div className="input-wrapper">{renderOptions()}</div>

      {formik.errors.questions && formik.errors.questions[index] && (
        <>
          <span className="errorText">
            {formik.errors.questions[index].answers}
          </span>
          <span className="errorText">
            {formik.errors.questions[index].correctAnswer}
          </span>
        </>
      )}
    </div>
  );
};

export default QuestionBox;
