import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import { globals } from '../_global/common/Utils';
import ConfirmModal from '../components/Modal/ConfirmModal';
import Loading from '../components/Loading/Loading';
const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});
const lambda = new AWS.Lambda();
const AccountDeletionForm: React.FC = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [comments, setComments] = useState('');
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (newEmail.trim() === '') {
      setError(true);
      setHelperText('Email is required');
    } else if (!isValidEmail(newEmail)) {
      setError(true);
      setHelperText('Please enter a valid email address');
    } else {
      setError(false);
      setHelperText('');
    }
  };

  const handleDelete = async () => {
    console.log('Account deletion requested:', { fullName, email, comments });
    const payload = {
      fullName,
      email,
      username,
      comments,
    };

    const params = {
      FunctionName: 'account-deletion',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify(payload),
    };

    try {
      setIsLoading(true);
      const result = await lambda.invoke(params).promise();
      if (result && result.Payload) {
        const response = JSON.parse(result.Payload);
        if (response.statusCode === 200) {
          setIsSuccessModal(true);
          console.log('Account deletion successful:', response);
        } else {
          setIsWarningModal(true);
          console.error('Failed to invoke Lambda:', response);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsWarningModal(true);
      console.error('Failed to invoke Lambda:', error);
    }
  };

  return (
    <>
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Error: Account Deletion Failed"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          isDeleteBtn={true}
          handleSubmit={() => setIsWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Please try again later or contact Hinckley Medical Representative at support@hinckleymed.com."
        />
      )}

      {isSuccessModal && (
        <ConfirmModal
          isVisible={isSuccessModal}
          title="Success: Account Deletion Requested"
          handleClose={() => {
            setIsSuccessModal(false);
          }}
          handleSubmit={() => {
            setIsSuccessModal(false);
            setFullName('');
            setEmail('');
            setUsername('');
            setComments('');
          }}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Your account deletion request has been submitted. We will process your request within 24 hours."
        />
      )}
      {isLoading && <Loading type="bubbles" />}
      <Box
        sx={{
          maxWidth: '1024px',
          margin: '0 auto',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          marginTop: '20px',
        }}
      >
        <h2
          style={{
            fontSize: '1.5rem',
            fontWeight: 'bold',
            marginBottom: '10px',
          }}
        >
          OneDose™ Account Deletion Form
        </h2>
        <p
          style={{
            fontSize: '1rem',
            color: '#616161',
          }}
        >
          We understand that individuals have a variety of reasons to delete
          accounts. We hope that our OneDose app served you well. To help
          improve our services for other EMS professionals, please share why you
          would like your account removed. If you have questions, you can
          contact us at:
        </p>
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#616161',
          }}
        >
          Hinckley Medical, Inc.
        </div>
        <div
          style={{
            fontSize: '1rem',
            color: '#616161',
          }}
        >
          8485 210th St W<br />
          Lakeville, MN 55044 USA
          <br />
          support@hinckleymed.com
          <br />
          (402) 207-0220
        </div>
        <TextField
          label="Full Name"
          variant="outlined"
          fullWidth
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          style={{
            marginTop: '20px',
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#00534C',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#00534C',
            },
          }}
        />
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          required
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={error}
          helperText={helperText}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#00534C',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#00534C',
            },
          }}
        />
        <TextField
          label="Username"
          variant="outlined"
          required
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#00534C',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#00534C',
            },
          }}
        />
        <TextField
          label="Comments"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: '#00534C',
              },
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: '#00534C',
            },
          }}
        />
        <Button
          className="primary-button  btn btn-primary"
          onClick={handleDelete}
          disabled={error || isLoading}
        >
          Delete Account
        </Button>
      </Box>
    </>
  );
};

export default AccountDeletionForm;
