/*
1-2-24: Abhishek: showOnlySideout is added as a prop that makes this component only show the sideout [MedicationDoseSideout] and not the main page to avoid redundancy
*/
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import AddRouteModal from '../../../../components/Modal/AddRouteModal';
import ConcentrationDialog from '../../../../components/Modal/ConcentrationDialogModal';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import ConfirmWarningModal from '../../../../components/Modal/ConfirmWarningModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import {
  findDepartmentOwner,
  findStartingIndex,
  getActiveID,
  getFormattedDate,
  getFormattedDateTime,
  globals,
  handleCopy,
  hasAdminUserAccess,
  isObjectEqual,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import MedicationItem, {
  cloneMedication,
  getConcentrationString,
} from '../../../../../data/model/MedicationItem';
import { Concentration, MedicationProtocol, User } from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../../../../../data/model/MedicationSubItem';
import MedicationDoseUI from './MedicationDoseUI';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import ProtocolHeader from '../../ProtocolHeader';
import {
  MedicationDoseJSON,
  MedicationJSON,
  createMedicationDoseItem,
  createParentMedication,
  deleteMedication,
  deleteMedicationDoseItem,
  duplicateMedicationDose,
  fetchMedications,
  updateConcentrations,
} from '../../../../../data/functions/MedicationDB';
import { ProgressStatus } from '../../../../../models';
import { Col, Row } from 'react-bootstrap';
import MedicationDoseSideout from '../../../../components/SideOut/doseSideOut/MedicationDoseSideout';
import { UserType } from '../../../../../models';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { InputSwitch } from 'primereact/inputswitch';
import AccordionItem from '../../../../components/Accordion/AccordionItem';
import { auditMedicationDosesToOldDBStyle } from '../../../../../data/AmplifyActions';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { handleToggleEnabled } from '../../../../../data/functions/ModelDB';
import PatientAge from '../../../../_global/common/PatientAge';

interface MedicationProtocolEditPageProps {
  stateData?: any;
  handleCloseSideout?: () => void;
  showOnlySideout?: boolean;
}

const SHOW_PROTOCOL_ID = false;

/* 10-24-23  Created Medication Edit Page component for Medication layout */
const MedicationProtocolEditPage: React.FC<MedicationProtocolEditPageProps> = ({
  stateData,
  handleCloseSideout,
  showOnlySideout,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedProtocol,
    value,
    subValue,
    type,
    editType,
    editMode,
    page,
    showEditDose,
  } = stateData;
  const [protocol, setProtocol] = useState<ProtocolItem | null>(
    selectedProtocol
  );

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);
  const [noConcentration, setNoConcentration] = useState(false);

  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  // const parmMedication: MedicationItem = protocolDetail;
  const [parmMedication, setParmMedication] = useState<MedicationItem>(value);
  const [allSubMedicationItems, setAllSubMedicationItems] = useState<
    MedicationSubItem[]
  >(value.subItems);
  const subMedicationItems = useMemo(() => {
    return selectedProtocol
      ? allSubMedicationItems
          .filter(
            (item: MedicationSubItem) =>
              item.parentProtocol.uid === selectedProtocol.uid
          )
          .sort((a, b) => a.index - b.index)
      : allSubMedicationItems;
  }, [allSubMedicationItems, selectedProtocol]);

  const numDeactivated = useMemo(() => {
    return subMedicationItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    ).length;
  }, [subMedicationItems]);

  const [showDeactivated, setShowDeactivated] = useState(false);
  // Save the initial deactivation status in a ref
  const initialDeactivationStatusIDs = useRef<string[]>([]);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    MedicationSubItem[]
  >([]);
  const [selectedItemDeactivationStatus, setSelectedItemDeactivationStatus] =
    useState<MedicationSubItem[] | null>(null);

  const allProtocols = useMemo(() => {
    let prots = allSubMedicationItems
      .filter((item: MedicationSubItem) => item.parentProtocol != null)
      .map((m) => m.parentProtocol);
    //Remove all the duplicates
    return prots
      .filter((v, i, a) => a.findIndex((t) => t.uid === v.uid) === i)
      .sort((a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      });
  }, [allSubMedicationItems]);

  const departmentOwner = useMemo(() => {
    return findDepartmentOwner(department, parmMedication);
  }, [department, parmMedication]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, user);
  }, [department, user]);

  const isOwner = useMemo(() => {
    return departmentOwner?.id === department.id;
  }, [departmentOwner, department]);

  const [warningModal, setWarningModal] = useState<React.ReactNode | null>(
    null
  );

  const [isBlockModal, setIsBlockModal] = useState(false);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(editMode);
  const [isAddRouteVisible, setIsAddRouteVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(
    showOnlySideout ? true : false
  );
  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    showEditDose && subValue ? subValue : null
  );

  const [isDelete, setIsDelete] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [editDose, setEditDose] = useState(showEditDose ? true : false);
  const [concentrationDialogVisible, setConcentrationDialogVisible] =
    useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [modalType, setModalType] = useState('');

  const [deleteData, setDeleteData] = useState<any>(null);
  const [isWarning, setIsWarning] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDraftConfirmModal, setIsDraftConfirmModal] = useState(false);

  const [doseIndex, setDoseIndex] = useState(subMedicationItems.length);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modifiedBy, setModifiedBy] = useState<User | null>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmMedication ? parmMedication.name : '',
      concentration: parmMedication ? parmMedication.concentrations : [],
      routes: parmMedication ? parmMedication.routes : [],
      rangeLow: parmMedication ? parmMedication.rangeLow + '' : '',
      rangeHigh: parmMedication ? parmMedication.rangeHigh + '' : '',
      protocolOptions:
        parmMedication && parmMedication.model
          ? parmMedication.model.protocolOptions
          : [],

      contraindication:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.contraindication
          ? parmMedication.model.contraindication
          : '',
      note:
        parmMedication && parmMedication.model && parmMedication.model.note
          ? parmMedication.model.note
          : '',
      warning:
        parmMedication && parmMedication.model && parmMedication.model.warning
          ? parmMedication.model.warning
          : '',
      medClass:
        parmMedication && parmMedication.model && parmMedication.model.medClass
          ? parmMedication.model.medClass
          : '',
      action:
        parmMedication && parmMedication.model && parmMedication.model.action
          ? parmMedication.model.action
          : '',
      indication:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.indication
          ? parmMedication.model.indication
          : '',
      interaction:
        parmMedication &&
        parmMedication.model &&
        parmMedication.model.interaction
          ? parmMedication.model.interaction
          : '',
      onset:
        parmMedication && parmMedication.model && parmMedication.model.onset
          ? parmMedication.model.onset
          : '',
      duration:
        parmMedication && parmMedication.model && parmMedication.model.duration
          ? parmMedication.model.duration
          : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      concentration: Yup.array().min(
        1,
        'At least one concentration is required'
      ),
      routes: Yup.array().min(1, 'At least one route is required'),
      rangeLow: Yup.number().required(),
      rangeHigh: Yup.number().required(),
      protocolOptions: Yup.array(),
      contraindication: Yup.string(),
      note: Yup.string(),
      warning: Yup.string(),
      medClass: Yup.string(),
      action: Yup.string(),
      indication: Yup.string(),
      interaction: Yup.string(),
      onset: Yup.string(),
      duration: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (globals.debug) console.log('SUBMITTING NEW MEDICATION', values);
      /* First check if the medication has changed */
      if (
        formik.dirty &&
        formik.isValid &&
        values.concentration.length > 0 &&
        values.routes.length > 0
      ) {
        const protocolList = findProtocols(values.protocolOptions);
        const isOwner = department.id === parmMedication.departmentID;
        console.log('IS OWNER', isOwner);

        if (!isOwner) {
          let isConcenUpdated = isConcentrationOnly(values);
          if (isConcenUpdated) {
            let resp = await updateConcentrations(
              department,
              parmMedication,
              values.concentration
            );
            if (resp.type === ResponseType.Success) {
              parmMedication.concentrations = values.concentration;
              formik.resetForm();
              return;
            } else {
              console.error('Failed to update concentrations', resp.data);
            }
          } else return;
        }

        console.log('UPDATNG MEDICATION');
        let activeID =
          parmMedication.status === ProgressStatus.ACTIVE
            ? parmMedication.uid
            : parmMedication.activeID;
        let overrideID = undefined;
        if (!isOwner) {
          overrideID = activeID;
          activeID = undefined;
          // let result = await showDiverageModal(
          //   'This means that the medication will override the original medication by ' +
          //     departmentOwner.name +
          //     '. Do you want to continue?'
          // );
        }

        const newMedication: MedicationJSON = {
          name: values.name,
          concentration: values.concentration,
          routes: values.routes,
          rangeLow: !isNaN(Number(values.rangeLow))
            ? Number(values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(values.rangeHigh))
            ? Number(values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          protocolOptions: values.protocolOptions,

          contraindication: values.contraindication,
          note: values.note,
          warning: values.warning,
          medClass: values.medClass,
          action: values.action,
          indication: values.indication,
          interaction: values.interaction,
          onset: values.onset,
          duration: values.duration,
          taggedProtocols: protocolList,
          createdBy:
            parmMedication.model && parmMedication.model.createdBy
              ? parmMedication.model.createdBy
              : '',
          modifiedBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID: activeID,
          overrideID: overrideID,

          version:
            parmMedication.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmMedication.version == null
                    ? 'v1.0.0'
                    : parmMedication.version
                )
              : parmMedication.version == null
                ? 'v1.0.0'
                : parmMedication.version,
        };
        let results: Response = await createParentMedication(
          department,
          newMedication,
          isOwner ? parmMedication : undefined
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created medication', results.data);
          let newMedication: MedicationItem = results.data;
          updateMedication(newMedication);
        } else {
          console.error('Failed to create medication', results.data);
        }
        // formik.resetForm();

        /* Update the initial values to the current values so dirty works */
        // formik.setValues({
        // 	...formik.values,
        // 	name: values.name,
        // 	concentration: values.concentration,
        // 	routes: values.routes,
        // 	rangeLow: values.rangeLow,
        // 	rangeHigh: values.rangeHigh,
        // 	protocolOptions: values.protocolOptions,
        // 	contraindication: values.contraindication,
        // 	note: values.note,
        // 	warning: values.warning,
        // 	medClass: values.medClass,
        // 	action: values.action,
        // 	indication: values.indication,
        // 	interaction: values.interaction,
        // 	onset: values.onset,
        // 	duration: values.duration,
        // });
      } else {
        /* If the form is not dirty then just reset the form */
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (value) {
      setParmMedication(value);
      setAllSubMedicationItems(value.subItems);
      formik.resetForm();
    }
  }, [value]);

  function isConcentrationOnly(values: any) {
    let concenChanged = false;
    if (values.concentration.length !== parmMedication.concentrations.length)
      return true;
    for (let i = 0; i < values.concentration.length; i++) {
      let concen = values.concentration[i];
      let found = parmMedication.concentrations.find((c: Concentration) =>
        isObjectEqual(c, concen)
      );
      if (!found) {
        concenChanged = true;
        break;
      }
    }
    return concenChanged;
  }

  useEffect(() => {
    let id = parmMedication.model?.modifiedBy;
    if (id == null) id = parmMedication.model?.createdBy;
    if (id) {
      let user = database.users.find((u: User) => u.id === id);
      setModifiedBy(user ? user : null);
      if (user) {
        parmMedication.modifiedBy = user;
      }
    } else {
      setModifiedBy(null);
    }
  }, [database, parmMedication, protocol]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'b') handleBack();
      else if (event.ctrlKey && event.key === 'e' && !isEditMode) {
        handleCheckIsDraft().then((isDraft: boolean) => {
          if (!isDraft && !isEditMode) setIsEditMode(true);
        });
      } else if (event.ctrlKey && event.key === 's' && isEditMode)
        formik.submitForm();
      else if (event.key === 'Escape') handleCancel();
      else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('MEDICATION', parmMedication);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik, isEditMode]);

  useEffect(() => {
    const initialStatus = subMedicationItems.filter(
      (item) => item.status === ProgressStatus.DEACTIVATED
    );
    initialDeactivationStatusIDs.current = initialStatus.map(
      (item: MedicationSubItem) => item.uid
    );
    setItemDeactivationStatus(initialStatus);
  }, [subMedicationItems]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (adminAccess && event.ctrlKey && event.key === 'e') {
        event.preventDefault();
        handleCheckIsDraft();
      } else if (adminAccess && event.ctrlKey && event.key === 's') {
        event.preventDefault();
        formik.submitForm();
      } else if (event.ctrlKey && event.key === 'b') {
        event.preventDefault();
        handleBack();
      } else if (
        adminAccess &&
        event.ctrlKey &&
        event.key === 'c' &&
        isEditMode
      ) {
        event.preventDefault();
        setConcentrationDialogVisible(true);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [adminAccess, isEditMode]);

  const solidUnits = useMemo(() => {
    if (parmMedication.concentrations?.length > 0) {
      let concen = parmMedication.concentrations[0];
      return concen.firstUnit;
    }
    return '';
  }, [parmMedication]);

  /**
   * Find the protocols from the protocol options
   * @param protocolOptions The protocol options to search for
   * @returns The protocols that were found
   */
  const findProtocols = (
    protocolOptions: MedicationProtocol[]
  ): ProtocolItem[] => {
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolOptions.length; i++) {
      let po = protocolOptions[i];
      let protocol = database.protocols.find((p: ProtocolItem) => {
        let id =
          p.status === ProgressStatus.ACTIVE ||
          (p.status === ProgressStatus.DRAFT && p.activeID == null)
            ? p.uid
            : p.activeID;
        return id === po.protocolID;
      });
      if (protocol) protocols.push(protocol);
    }
    return protocols;
  };

  /* After reloading the database update the medication item */
  // useEffect(() => {

  // }, [database]);

  /**
   * Reload the database from the server
   * @returns true when the database is reloaded
   */
  const reloadDatabase = async (parmMedID?: string): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await fetchMedications(
      database.department,
      database,
      true,
      true,
      true
    );
    if (resp.type === ResponseType.Success) {
      let medications = resp.data as MedicationItem[];
      dispatch<any>(
        handleGetDepartment({
          ...database,
          medications: medications,
        } as DatabaseResponse)
      );

      /* Search for the updated medication item in the database - ALWAYS search for activeID
       *  Use cases:
       *  1.  Medication was DRAFT FIRST RELEASE and updated item - match uid
       *  2.  Medication was ACTIVE and updated item - match old uid to new activeID
       */
      let updatedMedication: MedicationItem | null = null;
      let medicationID = getActiveID(parmMedication);
      if (globals.debug) console.log('Searing for medication', medicationID);

      for (let i = 0; i < medications.length; i++) {
        let medID = getActiveID(medications[i]);
        if (medID === medicationID) {
          updatedMedication = medications[i];
          break;
        }
      }
      if (globals.debug)
        console.log('Here is the updated Medication', updatedMedication);

      /* Search for the right sub medications */
      if (updatedMedication) {
        if (globals.debug)
          console.log('Found updated medication', updatedMedication);
        setParmMedication(updatedMedication);
        formik.resetForm();
        setAllSubMedicationItems(updatedMedication.subItems);
      } else if (globals.debug)
        console.log('Failed to find updated medication');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Todo have this open up a sideout to view the history of the medication
   */
  const handleVersion = () => {
    /* Output why the formik is dirty in the console */
  };

  /**
   * Back button has been pressed
   */
  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else if (protocol) {
      const state = { selectedProtocol: protocol, editMode: isEditMode };
      const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
      navigate(`/${formattedString}}/protocol-detail`, { state });
    } else {
      navigate(`/database/list-medications`, {
        state: {
          department: department,
          data: database.medications,
          database: database,
        },
      });
    }
  };

  /**
   * Cancen button has been pressed reset the formik values
   */
  const handleCancel = () => {
    /* Check if the formik is dirty and if the warning modal is not open */
    if (formik.dirty && !isCancelWarningModal) setIsCancelWarningModal(true);
    /* If the formik is not dirty or abandon has been pressed then reset it */ else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
      if (formik.values.protocolOptions.length === 0) handleBack();
      setAllSubMedicationItems(value.subItems);
    }
  };

  /* 10/11/2023: Praveen, Function to handle on Add Dose click*/
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedDose(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  /*function to close the modal*/
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedDose(null);
    handleCloseSideout && handleCloseSideout();
  };

  /* 11-01-23 Praveen: handled function to remove concentration values to show confirm popup*/
  const handleRemoveConcentration = (e: any, item: Concentration) => {
    e.stopPropagation();
    setModalType('Concentration');
    setDeleteData(item);
    setIsConfirmModal(true);
  };

  /* 11-01-23 Praveen: handled function to remove route values to show confirm popup*/
  const handleRemoveRoute = (e: any, value: any) => {
    e.stopPropagation();
    setModalType('Route');
    setIsConfirmModal(true);
    setDeleteData(value);
  };

  const updateMedication = async (newMedication: MedicationItem) => {
    reloadDatabase(newMedication.uid);
  };

  /* 11-01-23 Praveen: handled function to add new administration route*/
  const handleNewRouteSet = async (value: string) => {
    setIsAddRouteVisible(false);
    let routes = [...formik.values.routes, value];
    formik.setFieldValue('routes', routes);
  };

  /* 11-01-23 Praveen: handled function to delete concentartion and route values */
  const handleConfirmDelete = async (type: any) => {
    setIsConfirmModal(false);
    if (type === 'Route') {
      if (globals.debug)
        console.log(
          'Removing route',
          value,
          'from medication',
          formik.values.name
        );
      let routes = formik.values.routes.filter((r: string) => r !== deleteData);
      formik.setFieldValue('routes', routes);
    } else if (type === 'Concentration') {
      if (globals.debug)
        console.log(
          'Removing concentration',
          value,
          'from medication',
          formik.values.name
        );
      let concentrations = formik.values.concentration.filter(
        (c: Concentration) => {
          return c.id !== deleteData.id;
        }
      );
      formik.setFieldValue('concentration', concentrations);
    }
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationSet = async (
    amount1: any,
    amount2: any,
    unit1: any,
    unit2: any
  ) => {
    setConcentrationDialogVisible(false);
    let newConcentration = new Concentration({
      departmentID: department.id,
      medicationID: parmMedication.uid,
      firstAmnt: amount1,
      secAmnt: amount2,
      firstUnit: unit1,
      secUnit: unit2,
    });

    formik.setFieldValue('concentration', [
      ...formik.values.concentration,
      newConcentration,
    ]);
  };

  const handleRouteEdit = (event: any) => {
    if (!isEditMode) return;
    event.stopPropagation();
    setIsAddRouteVisible(true);
  };

  /* 11-01-23 Praveen: To set concentration popup value */
  const handleConcentrationEdit = (event: any) => {
    if (!isEditMode) return;
    event.stopPropagation();
    setConcentrationDialogVisible(true);
  };

  /* 1-23-24 Hazlett:  Function to remove the previous draft and make a new one */
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
    setIsDraftConfirmModal(false);
  };

  const handleOnSubmitMedicationDose = async (
    data: any,
    previousDose: MedicationSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    const protocol = parentProtocol ? parentProtocol : selectedProtocol;
    let protocolID =
      protocol.status === ProgressStatus.DRAFT && protocol.activeID !== null
        ? protocol.activeID
        : protocol.uid;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Create a new MedicationDose for the medicaiton */
    let prevDose = previousDose as MedicationSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose ? prevDose.index : protocol.medications.length;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    let newMedDose: MedicationDoseJSON = {
      departmentID: department.id,
      medication: parmMedication,
      protocol: protocol,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      route: data.routes,
      title: data.warning ? undefined : data.title,
      nemsisRoutes: data.nemsisRoutes,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,

      overrideID: isOwner ? null : prevDose?.uid,
      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    createMedicationDoseItem(newMedDose, isOwner ? prevDose : undefined).then(
      (response: Response) => {
        if (response.type === ResponseType.Success) {
          let newMedSubItem = response.data as MedicationSubItem;
          newMedSubItem.activeItem = prevDose ? prevDose : null;
          let allSubMeds = prevDose
            ? allSubMedicationItems.filter(
                (item: MedicationSubItem) => item !== prevDose
              )
            : allSubMedicationItems;
          setAllSubMedicationItems([...allSubMeds, newMedSubItem]);
          // reloadDatabase(parmMedication.uid);
        }
      }
    );
  };

  const handleCopyProtocolDoses = async (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let promises: any[] = [];
    let medDoses = [...fromProtocol.medications];
    for (let i = 0; i < medDoses.length; i++) {
      let medDose = medDoses[i];
      let parent = parmMedication;
      if (
        medDose.parent.uid === parent.uid ||
        medDose.parent.activeID === parent.uid
      ) {
        promises.push(
          duplicateMedicationDose(department, medDose, user, toProtocol)
        );
      }
    }
    let results = await Promise.all(promises);
    let meds = [...allSubMedicationItems];
    for (let i = 0; i < results.length; i++) {
      let result = results[i];
      if (result.type === ResponseType.Success) {
        let newMedDose = result.data;
        meds.push(newMedDose);
      }
    }
    setAllSubMedicationItems(meds);
  };

  const handleRemoveMedicationDose = async (
    dose: MedicationSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    console.log('Deleting Dose', dose, type);
    let subMeds = [...subMedicationItems];

    if (type === 'block') {
      // let resp = await createMedicationDoseItem(clone, undefined);
      let resp: Response = await handleToggleEnabled(user, department, dose);
      if (resp.type === ResponseType.Success) {
        let newDose: MedicationSubItem = resp.data;
        let allDoses = allSubMedicationItems.filter(
          (item: MedicationSubItem) => item !== dose
        );

        setAllSubMedicationItems([...allDoses, newDose]);

        reloadDatabase(parmMedication.uid);
      }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      (dose.status === ProgressStatus.DRAFT && dose.activeID == null)
    ) {
      let resp = await deleteMedicationDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        let deletedDose: MedicationSubItem = resp.data;

        let allDoses = allSubMedicationItems.filter(
          (item: MedicationSubItem) => item !== dose
        );
        if (
          dose.status === ProgressStatus.DEACTIVATED &&
          deletedDose.overrideItem
        )
          allDoses.push(deletedDose.overrideItem);
        setAllSubMedicationItems([...allDoses]);
        reloadDatabase(parmMedication.uid);
        auditMedicationDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
    // else if (
    //   dose.status === ProgressStatus.DRAFT_DELETE &&
    //   type === 'restore' &&
    //   (dose.activeItem || dose.overrideItem)
    // ) {
    //   let index = subMedicationItems.findIndex(
    //     (item: MedicationSubItem) => item === dose
    //   );

    //   let item = dose.activeItem ? dose.activeItem : dose.overrideItem;

    //   let newMedDose = new MedicationSubItem(
    //     dose.parent,
    //     dose.parentProtocol,
    //     (item as MedicationSubItem).model
    //   );
    //   console.log('Setting new dose', newMedDose, index);
    //   //Replace the deleted dose with the active dose

    //   subMeds[index] = newMedDose;
    //   deleteMedicationDoseItem(dose, false)
    //     .then((resp) => {
    //       console.log('Deleted Dose Resp', resp);
    //       if (resp.type === ResponseType.Success) {
    //         reloadDatabase(parmMedication.uid);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Failed to delete dose', error);
    //     });
    // }
    else {
      let resp = await deleteMedicationDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        let deletedDose: MedicationSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose.name);
        let allDoses = allSubMedicationItems.filter(
          (item: MedicationSubItem) => item !== dose
        );
        setAllSubMedicationItems([...allDoses]);
        reloadDatabase(parmMedication.uid);
        auditMedicationDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
      }
    }
  };

  // const handleActivateState = async (isDeactivate: boolean) => {
  //   if (isDeactivate) {
  //     let clone = cloneMedication(parmMedication);
  //     clone.status = ProgressStatus.DEACTIVATED;
  //     clone.departmentID = department.id;
  //     clone.modifiedBy = user;
  //     clone.overrideID =
  //       parmMedication.status.includes('DRAFT') && parmMedication.activeID
  //         ? parmMedication.activeID
  //         : parmMedication.uid;
  //     clone.activeID = undefined;

  //     let resp = await createParentMedication(department, clone, undefined);
  //     if (resp.type === ResponseType.Success) {
  //       console.log('Successfully blocked Medications', resp);
  //       let newDose: MedicationItem = resp.data;
  //       newDose.overrideItem = parmMedication;
  //       newDose.concentrations = parmMedication.concentrations;
  //       newDose.routes = parmMedication.routes;
  //       newDose.subItems = parmMedication.subItems;
  //       setParmMedication(newDose);
  //       reloadDatabase(newDose.uid);
  //     }
  //   } else {
  //     let resp = await deleteMedication(parmMedication, false);
  //     if (resp.type === ResponseType.Success) {
  //       let deletedDose: MedicationItem = resp.data;
  //       if (deletedDose.overrideItem) {
  //         let clone = cloneMedication(deletedDose.overrideItem);
  //         setParmMedication(clone);
  //         reloadDatabase(clone.uid);
  //       }
  //     }
  //   }

  //   setIsBlockModal(false);
  // };

  const handleActivateState = async () => {
    try {
      let res: Response = await handleToggleEnabled(
        user,
        department,
        parmMedication
      );
      if (res.type === ResponseType.Success) {
        let updated: MedicationItem = res.data;
        console.log('Updated Medication', updated);
        setParmMedication(updated);
        reloadDatabase(updated.uid);
      }
    } catch (error) {
      console.error('Error toggling enabled state', error);
    }

    setIsBlockModal(false);
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteMedication = async () => {
    const isDraft =
      parmMedication.status === ProgressStatus.DRAFT &&
      parmMedication.activeID == null;
    let response = await deleteMedication(parmMedication, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete medication', response.data);
    }
  };

  const showDiverageModal = (message: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const handleResponse = (response: boolean) => {
        resolve(response);
        setWarningModal(null);
      };

      // Pass the resolve function to the modal
      setTimeout(() => {
        setWarningModal(
          <ConfirmModal
            isVisible={true}
            title="Warning: You are about to diverge this medication."
            handleClose={() => {
              handleResponse(false);
            }}
            handleSubmit={() => handleResponse(true)}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Diverge"
            primaryDescription={message}
          />
        );
      }, 0);
    });
  };

  const isSaveActive = useMemo(() => {
    return (
      (formik.dirty &&
        formik.isValid &&
        (formik.values.concentration?.length > 0 || noConcentration) &&
        formik.values.routes.length > 0) ||
      !(
        initialDeactivationStatusIDs.current.length ===
          itemDeactivationStatus.length &&
        initialDeactivationStatusIDs.current.every((id: any) =>
          itemDeactivationStatus.some(
            (item: MedicationSubItem) => item.uid === id
          )
        )
      )
    );
  }, [
    formik.dirty,
    formik.isValid,
    formik.values,
    noConcentration,
    itemDeactivationStatus,
  ]);

  const handleToggleEnabledUI = async (item: MedicationSubItem) => {
    // Check if the item is already in itemDeactivationStatus
    const isItemDeactivated = itemDeactivationStatus.some(
      (deactivatedItem) => deactivatedItem.uid === item.uid
    );

    // Update itemDeactivationStatus
    if (isItemDeactivated) {
      // Remove the item if it's already deactivated
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    } else {
      // Add the item if it's not deactivated
      setItemDeactivationStatus([...itemDeactivationStatus, item]);
    }

    // Update selectedItemDeactivationStatus
    setSelectedItemDeactivationStatus((prevSelected) => {
      const currentSelected = prevSelected || [];

      const isItemSelected = currentSelected.some(
        (selectedItem) => selectedItem.uid === item.uid
      );

      if (isItemSelected) {
        // Remove the item if it's already selected
        return currentSelected.filter(
          (selectedItem) => selectedItem.uid !== item.uid
        );
      } else {
        // Add the item if it's not selected
        return [...currentSelected, item];
      }
    });
  };

  const handleSaveFormik = async () => {
    if (formik.dirty && formik.isValid) {
      formik.submitForm();
    } else if (
      selectedItemDeactivationStatus &&
      selectedItemDeactivationStatus.length > 0
    ) {
      try {
        console.log(
          'selectedItemDeactivationStatus',
          selectedItemDeactivationStatus
        );
        let promises: any[] = [];
        for (let i = 0; i < selectedItemDeactivationStatus.length; i++) {
          console.log(
            'UPDATING itemDeactivationStatus[' + i + ']',
            selectedItemDeactivationStatus[i]
          );
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              selectedItemDeactivationStatus[i]
            )
          );
        }
        console.log('PROMISES', promises);
        let results = await Promise.all(promises);
        console.log('results', results);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        console.log('RELOADING DATABASE');

        setAllSubMedicationItems(
          allSubMedicationItems.map((item: MedicationSubItem) => {
            const updatedItem = results.find(
              (result) => result.data.uid === item.uid
            );
            return updatedItem ? updatedItem.data : item;
          })
        );
        setSelectedItemDeactivationStatus([]);
        reloadDatabase(parmMedication.uid);
        setIsEditMode(false);
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    } else {
      setIsEditMode(false);
    }
  };

  const Modals = () => {
    return (
      <>
        {warningModal && warningModal}
        {isOpen && (
          <DraftSaveModal
            database={database}
            isVisible={isOpen}
            handleClose={() => {
              setIsOpen(false);
            }}
            handleContinue={() => {
              setIsEditMode(true);
              setIsOpen(false);
            }}
            handleNew={() => {
              setIsDraftConfirmModal(true);
              setIsOpen(false);
            }}
          />
        )}

        {isBlockModal && (
          <ConfirmModal
            isVisible={isBlockModal}
            title={`${
              parmMedication.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            } ${departmentOwner?.name} ${parmMedication.name} Medication?`}
            handleClose={() => {
              setIsBlockModal(false);
            }}
            handleSubmit={
              () => handleActivateState()
              // handleDeleteRestoreBlockDose(parmMedication.status === ProgressStatus.DEACTIVATED ? 'restore' : 'block')
            }
            isDeleteBtn={parmMedication.status !== ProgressStatus.DEACTIVATED}
            primaryBtnName="Cancel"
            secondaryBtnName={
              parmMedication.status === ProgressStatus.DEACTIVATED
                ? 'Reactivate'
                : 'Deactivate'
            }
            primaryDescription={`This will ${parmMedication.status === ProgressStatus.DEACTIVATED ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${parmMedication?.name} Medications for ${database.department?.name}. ${parmMedication.status === ProgressStatus.DEACTIVATED ? '' : 'This can be reactiveated at any time.'}`}
          />
        )}

        <ConfirmModal
          isVisible={isDraftConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsDraftConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleBack}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication.  Click cancel to return to Medication details.  To continue without saving changes, select Abandon.`}
          />
        )}

        {isDelete && (
          <ConfirmModal
            isVisible={isDelete}
            title={'Delete Medication?'}
            handleClose={() => {
              setIsDelete(false);
            }}
            handleSubmit={handleConfirmDeleteMedication}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={`Are you sure you would like to delete ${parmMedication.name}?`}
          />
        )}

        {isCancelWarningModal && (
          <ConfirmModal
            isVisible={isCancelWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsCancelWarningModal(false);
            }}
            handleSubmit={handleCancel}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication.  Click cancel to return to Medication details.  To cancel without saving changes, select Abandon.`}
          />
        )}

        <ConcentrationDialog
          dialogVisible={concentrationDialogVisible}
          handleSet={handleConcentrationSet}
          setDialogVisible={setConcentrationDialogVisible}
          headerText="Concentration"
        />

        <ConfirmWarningModal
          isVisible={isConfirmModal}
          modalType={isWarning ? 'warning' : modalType}
          type={modalType}
          handleClose={() => {
            setIsConfirmModal(false);
            setIsWarning(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        <MedicationDoseSideout
          doseIndex={doseIndex}
          solidUnit={solidUnits}
          protocol={protocol}
          parentModel={parmMedication}
          adminRoutes={formik.values.routes}
          dose={selectedDose ? selectedDose : undefined}
          subMedicationItems={allSubMedicationItems}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          onSubmit={handleOnSubmitMedicationDose}
          onSubmitMultiDose={handleCopyProtocolDoses}
          onSetNewRoutes={(newRoutes: string[]) => {
            formik.setFieldValue('routes', newRoutes);
          }}
          onRemove={handleRemoveMedicationDose}
        />

        <AddRouteModal
          dialogVisible={isAddRouteVisible}
          handleSet={handleNewRouteSet}
          onNewRouteCancel={() => {
            setIsAddRouteVisible(false);
          }}
          headerText="New Administration Route"
        />
      </>
    );
  };

  const ListRender = ({ data }: any) => {
    let list = data
      .filter(
        (item: MedicationSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: MedicationSubItem, b: MedicationSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });
    return (
      <ViewportList items={list}>
        {(dose: MedicationSubItem, index: number) => {
          const lastIndex = list.length === index + 1;
          return (
            <div
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (isEditMode) {
                  setSelectedDose(dose);
                  setDoseIndex(index);
                  setEditDose(true);
                  setSidebarVisible(true);
                }
              }}
            >
              <MedicationDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
                isEditMode={isEditMode}
                onToggle={handleToggleEnabledUI}
                isToggled={
                  itemDeactivationStatus.find(
                    (newItem) => newItem.uid === dose.uid
                  )
                    ? false
                    : true
                }
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const handleAccordionChange = useCallback(
    (protocol: ProtocolItem) => {
      if (expanded.includes(protocol.uid)) {
        setExpanded(expanded.filter((item) => item !== protocol.uid));
      } else {
        setExpanded([...expanded, protocol.uid]);
      }
    },
    [expanded]
  );

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubMedicationItems.filter(
      (item: MedicationSubItem) => item.parentProtocol.uid === protocol.uid
    );
    let list = doses
      .filter(
        (item: MedicationSubItem) =>
          showDeactivated || item.status !== ProgressStatus.DEACTIVATED
      )
      .sort((a: MedicationSubItem, b: MedicationSubItem) => {
        if (a.index === b.index) return a.rangeLow - b.rangeLow;
        return a.index - b.index;
      });
    // if (globals.debug) console.log('Doses', list);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <AccordionItem
          title={protocol.name}
          titleStyle={{ fontWeight: '400' }}
          rightTitle={`${list.length} doses`}
          rightTitleStyle={{ fontWeight: '700' }}
          expanded={expanded.includes(protocol.uid)}
          onChange={() => {
            handleAccordionChange(protocol);
          }}
        >
          <div
            style={{ flex: 1, padding: '0 10px 10px 10px' }}
            onClick={(e) => e.stopPropagation()} // Add this to prevent event bubbling
          >
            <ListRender data={list} />
          </div>
        </AccordionItem>
        {/* <div
          className={`border hover-raise-elevation ${
            expanded.includes(protocol.uid) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          {expanded.includes(protocol.uid) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {protocol.name}
            </span>
            {SHOW_PROTOCOL_ID && (
              <span
                className="ketamine-general-label"
                style={{
                  fontWeight: '400',
                  fontSize: '11px',
                  marginTop: '-8px',
                }}
              >
                {protocol.status === ProgressStatus.ACTIVE ||
                protocol.activeID == null
                  ? protocol.uid
                  : protocol.activeID}

                <span>
                  {isCopied &&
                  isCopied ===
                    (protocol.status === ProgressStatus.ACTIVE ||
                    protocol.activeID == null
                      ? protocol.uid
                      : protocol.activeID) ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(
                          protocol.status === ProgressStatus.ACTIVE ||
                            protocol.activeID == null
                            ? protocol.uid
                            : protocol.activeID,
                          e,
                          setIsCopied
                        )
                      }
                    />
                  )}
                </span>
              </span>
            )}
          </div>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {list.length} doses
          </span>
        </div>
        {expanded.includes(protocol.uid) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={doses} />
          </div>
        )} */}
      </div>
    );
  };

  return (
    <div className={showOnlySideout ? '' : 'screen-container'}>
      <Modals />
      {!showOnlySideout && (
        <>
          <ProtocolHeader
            name={
              parmMedication.name
              // (parmMedication.status === "DRAFT" ? " (Draft)" : "")
            }
            status={parmMedication.status}
            isBackButton={true}
            protocolDetail={protocol}
            page={protocol ? protocol.name : 'Medications'}
            type={'protocol'}
            rightSideBtn={isEditMode ? 'save' : 'edit'}
            isSaveButton={isEditMode}
            isSaveActive={isSaveActive}
            isCopyDescription={user.type === UserType.ADMIN}
            descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
            description={user.type === UserType.ADMIN ? parmMedication.uid : ''}
            // isVersionButton={true}
            isDeactivateButton={
              isEditMode &&
              protocol == null &&
              (adminLevel === 1 || adminLevel === 3) &&
              parmMedication.status !== ProgressStatus.DEACTIVATED &&
              !isOwner
            }
            isDeactivateActive={isEditMode}
            isReactivateActive={isEditMode}
            isReactivateButton={
              isEditMode && parmMedication.status === ProgressStatus.DEACTIVATED
            }
            handleDeactivate={() => {
              setIsBlockModal(true);
            }}
            handleReactivate={() => setIsBlockModal(true)}
            isDeleteButton={
              isEditMode &&
              protocol == null &&
              adminLevel > 2 &&
              adminAccess &&
              isOwner
            }
            isEditButton={!isEditMode && adminAccess}
            isDotButton={true}
            handleCancel={handleBack}
            handleCancelEdit={handleCancel}
            handleEdit={() => {
              handleCheckIsDraft().then((isDraft: boolean) => {
                if (!isDraft && !isEditMode) setIsEditMode(true);
              });
            }}
            handleDelete={handleDelete}
            handleSave={() => handleSaveFormik()}
            handleVersion={handleVersion}
          />
          <div className="ketamineContent">
            <div className="KetamineGeneral ">
              <h5 className="ketmine-header-text">General Information</h5>
              <div className="input-container roundBorder">
                <div>
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Modified By:</div>
                    <div style={{ fontWeight: '500' }}>
                      {modifiedBy
                        ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </div>
                  </div>
                  {parmMedication.model?.updatedAt && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Last Updated:</div>
                      <div style={{ fontWeight: '500' }}>
                        {getFormattedDateTime(
                          parmMedication.model.updatedAt,
                          false
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Version:</div>
                    <div style={{ fontWeight: '500' }}>
                      {parmMedication.version}
                    </div>
                  </div>
                  {user.type === UserType.ADMIN &&
                    parmMedication.rxNormCode != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>RxNorm Code:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmMedication.rxNormCode}
                          <span>
                            {isCopied &&
                            isCopied === parmMedication.rxNormCode ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmMedication.rxNormCode as string,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  {user.type === UserType.ADMIN && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Owner ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {parmMedication.departmentID}
                        <span>
                          {isCopied &&
                          isCopied === parmMedication.departmentID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(
                                  parmMedication.departmentID,
                                  e,
                                  setIsCopied
                                )
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN &&
                    parmMedication.overrideID != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Override ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmMedication.overrideID}
                          <span>
                            {isCopied &&
                            isCopied === parmMedication.overrideID ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmMedication.overrideID as string,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  {user.type === UserType.ADMIN && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {parmMedication.uid}
                        <span>
                          {isCopied && isCopied === parmMedication.uid ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(parmMedication.uid, e, setIsCopied)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN &&
                    parmMedication.status === ProgressStatus.DRAFT &&
                    parmMedication.activeID != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Active ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {parmMedication.activeID}
                          <span>
                            {isCopied &&
                            isCopied === parmMedication.activeID ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmMedication.activeID as string,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              <label htmlFor="Name" className="ketamine-general-label">
                Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="name"
                  data-testid="name"
                  required={true}
                  value={formik.values.name}
                  onChange={(e: any) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '10px',
                }}
              >
                <label
                  htmlFor="Concentration"
                  className="ketamine-general-label"
                >
                  Concentration <span className="required-field">*</span>
                </label>
                {/* <Tooltip
                  title={`Toggle to remove any concentration requirements for this medication.`}
                  placement="left"
                  arrow
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="ketamine-general-label"
                    style={{
                      flexDirection: 'row',
                      color: '#434343',
                      fontSize: '12px',
                    }}
                  >
                    <span>
                      <FaInfoCircle
                        color="#636363"
                        style={{ marginRight: '5px', marginBottom: '2px' }}
                      />
                    </span>
                    Not Required:
                    <InputSwitch
                      name="isSolidOnly"
                      style={{ marginLeft: '10px', marginTop: '-5px' }}
                      checked={noConcentration}
                      onChange={(e) => {
                        setNoConcentration(e.value);
                        if (e.value) {
                          formik.setFieldValue('concentration', []);
                        }
                      }}
                    />
                  </label>
                </Tooltip> */}
              </div>
              {!noConcentration ? (
                <div
                  data-testid="outsideConcentration"
                  data-div-id="outsideConcentration"
                  onClick={handleConcentrationEdit}
                  className={`selectionArea-hover ${
                    isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
                  } ${
                    formik.values.concentration?.length !== 0
                      ? ''
                      : 'centerContent'
                  }`}
                >
                  {formik.values.concentration?.length !== 0 && (
                    <ViewportList items={formik.values.concentration}>
                      {(item: Concentration, index: any) => {
                        return (
                          <div
                            key={index.toLocaleString()}
                            className={`selectedGreybox ${
                              isEditMode ? 'cursorPointer' : 'disabled'
                            }`}
                          >
                            <div>{getConcentrationString(item)}</div>
                            {isEditMode ? (
                              <span
                                className="close_icon_color"
                                data-testid={`deleteConcentration ${index}`}
                                onClick={(e) =>
                                  handleRemoveConcentration(e, item)
                                }
                              >
                                <AiOutlineCloseCircle className="icon-normal" />
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      }}
                    </ViewportList>
                  )}
                  {formik.values.concentration?.length !== 0 && isEditMode && (
                    <HiPencil
                      data-testid={`editConcentration`}
                      className={`icon-black rightIcon ${
                        isEditMode && 'cursorPointer'
                      }`}
                      onClick={() => {
                        if (isEditMode) setConcentrationDialogVisible(true);
                      }}
                    />
                  )}
                  {formik.values.concentration?.length === 0 && (
                    <div
                      onClick={() => {
                        isEditMode &&
                          setConcentrationDialogVisible(
                            !concentrationDialogVisible
                          );
                      }}
                    >
                      <span className="formAnchorText">+Add Concentration</span>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '-10px',
                    marginLeft: '10px',
                  }}
                >
                  <p className="sidebarText">
                    {parmMedication.name} does not require a concentration.
                  </p>
                </div>
              )}
              <label htmlFor="Concentration" className="ketamine-general-label">
                Routes <span className="required-field">*</span>
              </label>
              <div
                data-testid="outsideRoute"
                data-div-id="outsideRoute"
                onClick={(e: any) => {
                  if (isEditMode && adminLevel > 2) handleRouteEdit(e);
                }}
                className={`selectionArea-hover ${
                  isEditMode && adminLevel > 2
                    ? 'cursorPointer '
                    : 'selectionArea-disable'
                } ${formik.values.routes.length !== 0 ? '' : 'centerContent'}`}
              >
                {formik.values.routes.length >= 1 && (
                  <ViewportList items={formik.values.routes}>
                    {(item: any, index: any) => {
                      return (
                        <div
                          key={index.toLocaleString()}
                          className={`selectedGreybox ${
                            isEditMode ? 'cursorPointer' : 'disabled'
                          }`}
                        >
                          <div>{item}</div>
                          {isEditMode && adminLevel > 2 ? (
                            <span className="close_icon_color">
                              <AiOutlineCloseCircle
                                data-testid={`deleteRoute ${index}`}
                                className={`icon-normal ${
                                  isEditMode && 'cursorPointer'
                                }`}
                                onClick={(e) => {
                                  if (isEditMode && adminLevel > 2)
                                    handleRemoveRoute(e, item);
                                }}
                              />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {formik.values.routes.length >= 1 &&
                  isEditMode &&
                  adminLevel > 2 && (
                    <HiPencil
                      data-testid={`EditRoute`}
                      className={`icon-black rightIcon ${
                        isEditMode && 'cursorPointer'
                      }`}
                    />
                  )}
                {formik.values.routes.length === 0 && (
                  <div
                    onClick={() => {
                      isEditMode && setIsAddRouteVisible(true);
                    }}
                  >
                    <span className="cursorPointer formAnchorText">
                      +Add Route
                    </span>
                  </div>
                )}
              </div>
              <Row>
                <Col sm={6}>
                  <label htmlFor="rangeLow" className="ketamine-general-label">
                    Range Low (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="name"
                      name="rangeLow"
                      data-testid="rangeLow"
                      value={
                        formik.values.rangeLow &&
                        Number(formik.values.rangeLow) !== 0
                          ? formik.values.rangeLow
                          : ''
                      }
                      // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                      placeholder="Min"
                      disabled={!isEditMode || adminLevel < 3}
                      onChange={(e: any) => {
                        /* Check for "Min" */
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeLow', 'Invalid input');
                        else formik.setFieldValue('rangeLow', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-border"></div>
                  </div>
                  {/* {formik.touched.rangeLow && formik.errors.rangeLow ? (
                <span className="errorText">{formik.errors.rangeLow}</span>
              ) : null} */}
                </Col>
                <Col sm={6}>
                  <label htmlFor="rangeHigh" className="ketamine-general-label">
                    Range High (kg)
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="rangeHigh"
                      name="rangeHigh"
                      data-testid="rangeHigh"
                      disabled={!isEditMode || adminLevel < 3}
                      value={
                        formik.values.rangeHigh &&
                        Number(formik.values.rangeHigh) !== globals.MAX_VALUE
                          ? formik.values.rangeHigh
                          : ''
                      }
                      placeholder="Max"
                      onChange={(e: any) => {
                        let n = Number(e.target.value);
                        if (isNaN(n))
                          formik.setFieldError('rangeHigh', 'Invalid input');
                        else formik.setFieldValue('rangeHigh', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-border"></div>
                  </div>
                  {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                    <span className="errorText">{formik.errors.rangeHigh}</span>
                  ) : null}
                </Col>
              </Row>
              <label
                htmlFor="Contraindication"
                className="ketamine-general-label"
              >
                Contraindication
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="contraindication"
                  data-testid="contraindication"
                  name="contraindication"
                  value={formik.values.contraindication}
                  onChange={(e: any) => {
                    formik.setFieldValue('contraindication', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="warning"
                  data-testid="warning"
                  name="warning"
                  value={formik.values.warning}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Indication" className="ketamine-general-label">
                Indication
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="indication"
                  data-testid="indication"
                  name="indication"
                  value={formik.values.indication}
                  onChange={(e: any) => {
                    formik.setFieldValue('indication', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="DrugInteraction"
                className="ketamine-general-label"
              >
                Drug Interaction
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="interaction"
                  data-testid="interaction"
                  name="interaction"
                  value={formik.values.interaction}
                  onChange={(e: any) => {
                    formik.setFieldValue('interaction', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Onset" className="ketamine-general-label">
                Onset
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="onset"
                  data-testid="onset"
                  name="onset"
                  value={formik.values.onset}
                  onChange={(e: any) => {
                    formik.setFieldValue('onset', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Duration" className="ketamine-general-label">
                Duration
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="duration"
                  data-testid="duration"
                  name="duration"
                  value={formik.values.duration}
                  onChange={(e: any) => {
                    formik.setFieldValue('duration', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Note" className="ketamine-general-label">
                Note
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="notes"
                  data-testid="notes"
                  name="notes"
                  value={formik.values.note}
                  onChange={(e: any) => {
                    formik.setFieldValue('note', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Class" className="ketamine-general-label">
                Class
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="class"
                  data-testid="class"
                  name="class"
                  value={formik.values.medClass}
                  onChange={(e: any) => {
                    formik.setFieldValue('medClass', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Action" className="ketamine-general-label">
                Action
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="action"
                  data-testid="action"
                  name="action"
                  value={formik.values.action}
                  onChange={(e: any) => {
                    formik.setFieldValue('action', e.target.value);
                  }}
                  disabled={!isEditMode || adminLevel < 3}
                />
                <div className="input-border"></div>
              </div>
            </div>
            <div className="KetamineGeneral">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '10px',
                }}
              >
                <h5 className="ketmine-header-text">
                  {protocol ? 'Protocol' : 'All'} Doses:{' '}
                  {subMedicationItems.length} Items
                  {!protocol && (
                    <span
                      className="no-select"
                      style={{
                        fontSize: '12px',
                        marginLeft: '10px',
                        marginTop: '5px',
                      }}
                      onClick={() => {
                        if (allProtocols.length === expanded.length)
                          setExpanded([]);
                        else setExpanded(allProtocols.map((p) => p.uid));
                      }}
                    >
                      <div className="clickableText">
                        {expanded.length === allProtocols.length
                          ? 'Collapse All'
                          : 'Expand All'}
                      </div>
                    </span>
                  )}
                </h5>
                {/* <Tooltip title={'Full Screen'} placement="bottom" arrow> */}
                {numDeactivated > 0 && (
                  <InputSwitch
                    tooltip={'Show ' + numDeactivated + ' Deactivated Items'}
                    tooltipOptions={{
                      position: 'bottom',
                      style: { fontSize: '12px' },
                    }}
                    name="isSolidOnly"
                    style={{ marginLeft: '10px' }}
                    checked={showDeactivated}
                    onChange={(e) => {
                      setShowDeactivated(e.value);
                    }}
                  />
                )}
                {/* </Tooltip> */}
              </div>
              {isEditMode && adminLevel > 2 && (
                <div style={{ marginTop: '5px' }}>
                  <span className="ketamine-general-label">Doses</span>
                  <div
                    onClick={onAddDoseClick}
                    className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                      isEditMode && 'cursorPointer newRouteButton'
                    }`}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      style={{
                        textDecoration: 'underLine',
                        textUnderlinePosition: 'under',
                      }}
                    >
                      <HiPlus className="text-icon " /> Add Dose
                    </span>
                  </div>
                </div>
              )}

              {protocol ? (
                <ViewportList
                  items={subMedicationItems.filter(
                    (item) =>
                      showDeactivated ||
                      item.status !== ProgressStatus.DEACTIVATED
                  )}
                >
                  {(dose: MedicationSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (isEditMode) {
                            setSelectedDose(dose);
                            setDoseIndex(index);
                            setEditDose(true);
                            setSidebarVisible(true);
                          }
                        }}
                      >
                        <MedicationDoseUI
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={protocol == null}
                          isEditMode={isEditMode}
                          onToggle={handleToggleEnabledUI}
                          isToggled={
                            itemDeactivationStatus.find(
                              (newItem) => newItem.uid === dose.uid
                            )
                              ? false
                              : true
                          }
                        />
                      </div>
                    );
                  }}
                </ViewportList>
              ) : (
                <>
                  {allProtocols.map((protocol: ProtocolItem, index: number) => {
                    return <ProtocolItemUI key={index} protocol={protocol} />;
                  })}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MedicationProtocolEditPage;
