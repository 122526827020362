import { Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useState, forwardRef, useEffect } from 'react';

const HinckleySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00534C',
    '&:hover': {
      backgroundColor: alpha('#00534C', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00534C',
  },
  '& .MuiSwitch-switchBase': {
    '&:hover': {
      backgroundColor: alpha('#ffcc00', theme.palette.action.hoverOpacity), // Color when hovering over the button
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#ccc', // Default track color
  },
}));

const label = { inputProps: { 'aria-label': 'Hinckley Medical Switch' } };

interface SwitchProps {
  checked?: boolean;
  // minus?: boolean;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
  tooltipText?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  style?: React.CSSProperties;
}

const HMSwitch = forwardRef<HTMLInputElement, SwitchProps>((props, ref) => {
  const [checked, setChecked] = useState(props.checked || false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setChecked(checked); // Update the internal state
    if (props.onChange) {
      props.onChange(event, checked); // Call the external onChange if passed
    }
  };

  useEffect(() => {
    setChecked(props.checked || false);
  }, [props.checked]);

  return props.tooltipText ? (
    <Tooltip
      title={props.tooltipText}
      arrow
      placement={props.tooltipPosition || 'bottom'}
    >
      <div style={props.style}>
        <HinckleySwitch
          {...label}
          checked={checked} // Controlled state
          onChange={handleChange} // Detect button press
          // ref={ref} // Forward the ref
        />
      </div>
    </Tooltip>
  ) : (
    <div style={props.style}>
      <HinckleySwitch
        {...label}
        checked={checked} // Controlled state
        onChange={handleChange} // Detect button press
        // ref={ref} // Forward the ref
        style={props.style}
      />
    </div>
  );
});

export default HMSwitch;
