import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Loading from '../../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  DatabaseResponse,
  getDepartments,
  loadDatabase,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { handleGetDepartment } from '../../../../../../store/actions';
import { handleCopy, toTitleCase } from '../../../../../_global/common/Utils';
import { set } from 'lodash';
import { ViewportList } from 'react-viewport-list';
import MedicationSubItem from '../../../../../../data/model/MedicationSubItem';
import MedicationItem, {
  getConcentrationString,
} from '../../../../../../data/model/MedicationItem';
import { DataStore } from 'aws-amplify';
import {
  Concentration,
  Drip,
  Medication,
  MedicationConcentration,
  MedicationDose,
} from '../../../../../../models';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import {
  convertAllMedicationConcentrationsToConcentrationTables,
  convertToNewDatabaseLogic,
  copyConcentrationsToSubDepartment,
} from '../../../../../../data/AmplifyActions';
import InfusionItem from '../../../../../../data/model/InfusionItem';

type NewConcenType = {
  medication: MedicationItem | InfusionItem;
  c: Concentration;
};
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const CopySubDepConcentrations = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    fromDepartment: undefined as DepartmentItem | undefined,
    toDepartment: undefined as DepartmentItem | undefined,
    fromConcentrations: [] as NewConcenType[],
    toConcentrations: [] as NewConcenType[],
  });

  const handleBack = () => {
    navigate('/actions/sub-departments');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
    }
  };

  const loadData = async (department: DepartmentItem, isToDep: boolean) => {
    const loadMedsAndDrips = async () => {
      let dep = department;
      let depIDs = [dep.id];
      if (dep.parentDep) depIDs.push(dep.parentDep.id);
      if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

      let promises = [
        DataStore.query(Medication, (c) =>
          c.or((c) => depIDs.map((id) => c.departmentID.eq(id)))
        ),
        DataStore.query(Drip, (c) =>
          c.or((c) => depIDs.map((id) => c.departmentID.eq(id)))
        ),
      ];
      let [meds, drips] = await Promise.all(promises);
      let allMeds: MedicationItem[] = [];
      let allDrips: InfusionItem[] = [];
      for (let med of meds) {
        let newMed = new MedicationItem(med as Medication);
        allMeds.push(newMed);
      }
      for (let drip of drips) {
        let newDrip = new InfusionItem(drip as Drip);
        allDrips.push(newDrip);
      }
      return { meds: allMeds, drips: allDrips };
    };
    await department.checkParentDep();
    let promises = [
      department.checkSubDeps(),
      loadMedsAndDrips(),
      DataStore.query(Concentration, (c) => c.departmentID.eq(department.id)),
    ];
    let [depsResp, response, concentrations]: any = await Promise.all(promises);

    let allNewConcen: NewConcenType[] = [];
    if (response.meds && response.drips && concentrations) {
      let medsAndDrips = [...response.meds, ...response.drips];
      console.log('CONCENTRATIONS:', concentrations);
      console.log('MEDS AND DRIPS:', medsAndDrips);

      for (let concen of concentrations) {
        let c: Concentration = concen as Concentration;
        let med = medsAndDrips.find(
          (m) => m.uid === c.medicationID || m.uid === c.dripID
        );
        if (med) {
          allNewConcen.push({ medication: med, c: c });
        }
      }

      if (isToDep) {
        setInfo({
          ...info,
          toDepartment: department,
          toConcentrations: allNewConcen,
        });
      } else {
        setInfo({
          ...info,
          fromDepartment: department,
          fromConcentrations: allNewConcen,
        });
      }
    }
    setIsLoading(null);
  };

  const handlePerformAudit = async () => {
    console.log('Perming Copy Concentrations');
    copyConcentrationsToSubDepartment(info.fromDepartment, info.toDepartment)
      .then((response) => {
        console.log('Response:', response);
        if (response.type === ResponseType.Success) {
          let medDoses = response.data;
          setModalState({
            isVisible: true,
            title: 'Successfully Created Concentrations',
            primaryBtnName: 'Okay',
            secondaryBtnName: 'Okay',
            primaryDescription:
              'Successfully created ' +
              medDoses.length +
              ' Concentrations for ' +
              info.toDepartment?.name,
          });
          // setInfo({
          //   ...info,
          //   newStyleMeds: medDoses,
          // });
          loadData(info.toDepartment, true);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isSaveValid = useMemo(() => {
    return info.toDepartment !== undefined && info.fromDepartment !== undefined;
  }, [info]);

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          // handlePerformAudit(true);
          setModalState({ ...modalState, isVisible: false });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={
          'Copy the concentrations from one department to another department'
        }
        description={
          'This will ONLY copy the concentrations from one department to another department.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Run"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit();
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <label className="ketamine-general-label">
            From Department: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy the concentrations FROM.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={departments}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              setIsLoading('Loading ' + option.name + ' Data...');

              loadData(option, false);
            }}
            onClear={() => {
              setInfo({
                ...info,
                fromDepartment: undefined,
              });
            }}
            placeholder="Search department..."
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <label className="ketamine-general-label">
            TO Department: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy the concentrations TO.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={
              info.fromDepartment && info.fromDepartment.subDeps
                ? ((info.fromDepartment as DepartmentItem)
                    .subDeps as DepartmentItem[])
                : []
            }
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              setIsLoading('Loading ' + option.name + ' Data...');
              loadData(option, true);
            }}
            onClear={() => {
              setInfo({
                ...info,
                toDepartment: undefined,
              });
            }}
            placeholder="Search department..."
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        {info.fromDepartment && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {info.fromDepartment && info.fromDepartment.logoVerifiedUrl && (
                <img
                  className="large-dep-logo"
                  src={info.fromDepartment.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              {info.fromDepartment && (
                <div
                  className="large-dep-name"
                  style={{
                    marginLeft: '2rem',
                    marginRight: '6px',
                  }}
                >
                  {info.fromDepartment.name}
                </div>
              )}
            </div>
            <div
              className="input-container roundBorder "
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
            >
              <div
                className="ketamine-general-label"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div style={{ marginRight: '10px' }}>ID:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {info.fromDepartment.id}
                  <span>
                    {isCopied && isCopied === info.fromDepartment.id ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size="1rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size="1rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(info.fromDepartment.id, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="input-container roundBorder "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <h4
                  style={{
                    borderBottom: '1px solid #e0e0e0',
                    padding: '10px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                  }}
                >
                  Concentrations:{' '}
                  <span style={{ color: '#636363' }}>
                    {info.fromConcentrations.length}
                  </span>
                </h4>
                <ViewportList items={info.fromConcentrations}>
                  {(item: NewConcenType, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          borderBottom: '1px solid #e0e0e0',
                          padding: '10px',
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ fontSize: '12px', color: '#636363' }}>
                            {item.medication.TAG +
                              ' - ' +
                              toTitleCase(item.medication.status)}
                          </div>
                          <div style={{}}>{item.medication.name}</div>
                          <div style={{ fontSize: '12px', color: '#636363' }}>
                            {item.medication.uid}
                          </div>
                        </div>
                        <div>{getConcentrationString(item.c)}</div>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          </div>
        )}
        {info.toDepartment && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {info.toDepartment && info.toDepartment.logoVerifiedUrl && (
                <img
                  className="large-dep-logo"
                  src={info.toDepartment.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              {info.toDepartment && (
                <div
                  className="large-dep-name"
                  style={{
                    marginLeft: '2rem',
                    marginRight: '6px',
                  }}
                >
                  {info.toDepartment.name}
                </div>
              )}
            </div>
            <div
              className="input-container roundBorder "
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
            >
              <div
                className="ketamine-general-label"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div style={{ marginRight: '10px' }}>ID:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {info.toDepartment.id}
                  <span>
                    {isCopied && isCopied === info.toDepartment.id ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size="1rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size="1rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(info.toDepartment.id, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                className="input-container roundBorder "
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <h4
                  style={{
                    borderBottom: '1px solid #e0e0e0',
                    padding: '10px',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                  }}
                >
                  Concentrations:{' '}
                  <span style={{ color: '#636363' }}>
                    {info.toConcentrations.length}
                  </span>
                </h4>
                <ViewportList items={info.toConcentrations}>
                  {(item: NewConcenType, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          borderBottom: '1px solid #e0e0e0',
                          padding: '10px',
                          fontWeight: 'bold',
                          fontSize: '14px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div style={{ fontSize: '12px', color: '#636363' }}>
                            {item.medication.TAG +
                              ' - ' +
                              toTitleCase(item.medication.status)}
                          </div>
                          <div style={{}}>{item.medication.name}</div>
                          <div style={{ fontSize: '12px', color: '#636363' }}>
                            {item.medication.uid}
                          </div>
                        </div>
                        <div>{getConcentrationString(item.c)}</div>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopySubDepConcentrations;
