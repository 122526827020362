import { DataStore } from 'aws-amplify';
import { User, WeightObject } from '../../models';
import DepartmentItem from '../model/DepartmentItem';
import { executeQuery, Response, ResponseType } from '../AmplifyDB';
import { weightObjectsByDepartmentID } from '../../graphql/queries';
import { globals, upgradeVersion } from '../../ui/_global/common/Utils';

export const fetchWeightObjects = async (
  department: DepartmentItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let weightList: WeightObject[];
    if (useDataStore) {
      weightList = await DataStore.query(WeightObject, (wo) =>
        wo.departmentID.eq(department.id)
      );
    } else {
      weightList = await executeQuery(weightObjectsByDepartmentID, {
        departmentID: department.id,
      });
    }
    /* Sort all contacts by their title then by their index */
    weightList.sort((a: WeightObject, b: WeightObject) => {
      if (a.weight === b.weight) return a.name.localeCompare(b.name);
      return a.weight - b.weight;
    });

    return {
      type: ResponseType.Success,
      data: weightList,
    };
  } catch (error) {
    if (globals.debug) console.error('Error fetching weight objects:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteWeightObject = async (
  object: WeightObject | null
): Promise<Response> => {
  if (!object)
    return {
      type: ResponseType.Failure,
      data: 'Subtractble Weight not found',
    };
  try {
    const toDelete = await DataStore.query(WeightObject, object.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Subtractable Weight not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createWeightObject = async (
  weightObject: any,
  createdBy: User,
  pairedDeps?: DepartmentItem[]
): Promise<Response> => {
  try {
    let ids = pairedDeps ? pairedDeps.map((d: DepartmentItem) => d.id) : [];
    let w = await DataStore.save(
      new WeightObject({
        name: weightObject.name,
        weight: Number(weightObject.weightLbs),
        createdBy: createdBy.id,
        version: weightObject.version,
        pairedDepIDs: ids,
        departmentID: weightObject.departmentID,
      })
    );
    return {
      type: ResponseType.Success,
      data: w,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const editWeightObject = async (
  weightObject: any,
  modifiedBy: User,
  pairedDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    let w = await DataStore.query(WeightObject, weightObject.id);
    if (!w)
      return {
        type: ResponseType.Failure,
        data: 'Could not find existing subtractable weight. Try again later.',
      };
    let res = await DataStore.save(
      WeightObject.copyOf(w, (updated) => {
        updated.name = weightObject.name;
        updated.weight = Number(weightObject.weightLbs);
        updated.pairedDepIDs = pairedDeps.map((d: DepartmentItem) => d.id);
        updated.modifiedBy = modifiedBy.id;
        updated.version = upgradeVersion(weightObject.version);
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};
