import { Link, useLocation, useNavigate } from 'react-router-dom';
// import * as FaIcons from "react-icons/fa"
// import * as SiIcons from "react-icons/si"
// import * as HiIcons from "react-icons/hi"
// import * as MdIcons from "react-icons/md"
import {
  Fa6,
  FaCloudArrowUp,
  FaDatabase,
  FaFolderOpen,
  FaHeartPulse,
} from 'react-icons/fa6';
import { FaHistory, FaUserFriends } from 'react-icons/fa';

import * as Io5 from 'react-icons/io5';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoBook, IoNotifications, IoArchive } from 'react-icons/io5';
import { User } from '../../../models';
import { UserType } from '../../../models';
import { TbPlugConnected } from 'react-icons/tb';
import { globals } from '../../_global/common/Utils';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { handleShowActivityLog, setSidebarMode } from '../../../store/actions';
import ActivityLogSidebar from './ActivityLogSidebar';
import { set } from 'lodash';

/* 09-29-23 Arul: Created the  sidebar component globally  */
const Sidebar = ({ show, name }: any) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState('/');
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const user: User = useSelector((state: any) => state.user);
  const activityLogSidebar = useSelector((state: any) => state.activityLogSidebar);
  const isMini = useSelector((state: any) => state.isMini);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'f') {
        event.preventDefault();
        navigate('/protocol');
        setSelectedLink('/protocol');
      } else if (event.ctrlKey && event.shiftKey && event.key === 'd') {
        event.preventDefault();
        navigate('/database');
        setSelectedLink('/database');
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.includes('/protocol-sets'))
      setSelectedLink('/protocol-sets');
    else if (location.pathname.includes('/notification'))
      setSelectedLink('/notification');
    else if (location.pathname.includes('/database'))
      setSelectedLink('/database');
    else if (location.pathname.includes('/protocol'))
      setSelectedLink('/protocol');
    else if (location.pathname.includes('/snapshot'))
      setSelectedLink('/snapshot');
  }, [location.pathname]);

  // Helper function to get avatar text
  // Get first letter of name for avatar
  const getAvatarLetter = () => {
    if (!name) return 'G';
    return name.charAt(0).toUpperCase();
  };

  // Helper function for rendering links with fixed alignment
  const renderLink = (to: string, icon: JSX.Element, text: string) => {

    const handleClick = (e: React.MouseEvent) => {
      // Special handling for Activity Log
      if (to === '/activity-log') {
        // Prevent navigation
        e.preventDefault();
        // Toggle the Activity Log sidebar without changing the selected link
        if (activityLogSidebar === true) {
          // If activity log sidebar is already open, Hide it
          dispatch<any>(setSidebarMode({ isMini: false }));
          dispatch<any>(handleShowActivityLog({ activityLogSidebar: false }));
        } else {
          // If activity log sidebar is not open, Show it
          dispatch<any>(setSidebarMode({ isMini: true }));
          dispatch<any>(handleShowActivityLog({ activityLogSidebar: true }));
        }
      } else {
        // Update the selected link only for non-Activity Log links
        setSelectedLink(to);
      }
    };

    // For Activity Log, use a button styled as a link instead of Link component
    if (to === '/activity-log') {
      return (
        <a
          href="#"
          onClick={handleClick}
          title={isMini ? text : ''}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isMini ? 'center' : 'flex-start'
          }}
        >
          {icon}
          {!isMini && text}
        </a>
      );
    }

    // For all other menu items, use normal Link component
    return (
      <Link
        to={to}
        onClick={handleClick}
        title={isMini ? text : ''} // For tooltip in mini mode
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: isMini ? 'center' : 'flex-start'
        }}
      >
        {icon}
        {!isMini && text}
      </Link>
    );
  };

  return (
    <div className={`sidebar ${show ? 'active' : ''} ${isMini ? 'mini' : ''}`}>
      {/* <img src={logo} alt='Logo' className='logo' /> */}
      {isMini ? (
        <div className="avatar-container">
          <div className="avatar-mini">
            {getAvatarLetter()}
          </div>
        </div>
      ) : (
        name !== '' && <h1 className="greeting no-select">Hi, {name}</h1>
      )}
      {isLoggedIn &&
        user &&
        (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN) ? (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {/* <Io5.IoLayers /> */}
              {/* Protocols */}
              {renderLink('/protocol', <FaFolderOpen />, 'Folders')}
            </div>
          </li>
          {/* <li >
                        <div style={selectedLink === '/snapshot' ? { backgroundColor: "#003935", borderRadius: 10 } : {}} >
                            <Link to='/snapshot' onClick={() => setSelectedLink('/snapshot')}><FaCloudArrowUp />Snapshots</Link>
                        </div>
                    </li> */}
          <li>
            <div
              style={
                selectedLink === '/database'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/database', <FaDatabase />, 'Database')}
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/notification'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/notification', <IoNotifications />, 'Notifications')}
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/protocol-sets'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/protocol-sets', <IoBook />, 'Protocol Sets')}
            </div>
          </li>
          <li>
            <div
              style={
                selectedLink === '/archive'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/archive', <IoArchive />, 'Archive')}
            </div>
          </li>
          {user.type === 'ADMIN' && (
            <li>
              <div
                style={
                  selectedLink === '/actions'
                    ? { backgroundColor: '#003935', borderRadius: 10 }
                    : {}
                }
              >
                {renderLink('/actions', <TbPlugConnected />, 'Actions')}
              </div>
            </li>
          )}
          {/* <li>
            <div
              style={
                selectedLink === '/review'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              <Link to="/review" onClick={() => setSelectedLink('/review')}>
                <FaUserFriends />
                Review
              </Link>
            </div>
          </li> */}

          <li>
            <div
              style={
                selectedLink === '/settings'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/settings', <Io5.IoSettings />, 'Settings')}
            </div>
          </li>
          {/* Activity Log menu item for users */}
          <li>
            <div
              style={
                selectedLink === '/activity-log' || activityLogSidebar
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/activity-log', <FaHistory />, 'Activity Log')}
            </div>
          </li>

          {/* <li>
                    <Link to='/'><FaIcons.FaHome />Home</Link>
                </li>
                <li>
                    <Link to='/assembly'><SiIcons.SiAssemblyscript />Assembly</Link>
                </li>
                <li>
                    <Link to='/devices'><MdIcons.MdDeviceHub />Devices</Link>
                </li>
                <li>
                    <Link to='/inventory'><MdIcons.MdOutlineInventory />Inventory</Link>
                </li>
                <li>
                    <Link to='/product'><FaIcons.FaProductHunt />Product</Link>
                </li>
                <li>
                    <Link to='/qms'><HiIcons.HiOutlineDocumentDuplicate />QMS</Link>
                </li>
                <li>
                    <Link to='/shipments'><FaIcons.FaShippingFast />Shipments</Link>
                </li>
                <li>
                    <Link to='/serviceability'><RiIcons.RiCustomerService2Fill />Serviceability</Link>
                </li> */}
        </ul>
      ) : (
        <ul className="sidebar-ul">
          <li>
            <div
              style={
                selectedLink === '/protocol' || selectedLink === '/'
                  ? { backgroundColor: '#003935', borderRadius: 10 }
                  : {}
              }
            >
              {renderLink('/protocol', <FaFolderOpen />, 'Folders')}
            </div>
          </li>
        </ul>
      )}
      {!isMini && (
        <div className="sidebar-footer">
          <p style={{ padding: 0, margin: 0, fontSize: '12px' }}>
            OneDose Admin {globals.VERSION}
          </p>
          <p style={{ padding: 0, margin: '0px 0px 10px 0px', fontSize: '12px' }}>
            © Hinckley Medical, Inc
          </p>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
