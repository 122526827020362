import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  Response,
  ResponseType,
  createDepartment,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import {
  addDepartmentIDToAllReferences,
  copyConcentrationsToSubDepartment,
  copyElectricalFromDeptoDep,
  copyEquipmentFromDeptoDep,
  copyVitalsFromDeptoDep,
} from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin, getSalt } from '../../../../_global/common/Encrypt';
import { BiCopy, BiRename, BiSolidCopy, BiTrash } from 'react-icons/bi';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  USAStatesAbrev,
  generateTemporaryPassword,
  getGroupCode,
  globals,
  toTitleCase,
} from '../../../../_global/common/Utils';
import { InputSwitch } from 'primereact/inputswitch';
import { DataStore } from 'aws-amplify';
import { Department } from '../../../../../models';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import { IoMdAdd } from 'react-icons/io';
import { Tooltip } from '@mui/material';
import { FaCodeMerge } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';
import { UserType } from '../../../../../models';
import { getEmailByCognitoId } from '../../../protocol/edit/User/UserCreateEditPage';
import { createUserGraphQL } from '../../../../../data/GraphQL_API';
import Loading from '../../../../components/Loading/Loading';
import HMSwitch from '../../../../components/general/HMSwitch';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

const AWS = require('aws-sdk');
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});
const cognito = new AWS.CognitoIdentityServiceProvider();
const lambda = new AWS.Lambda();

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const DepartmentPage = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [hinckleyDepartment, setHinckleyDepartment] = useState<Department>();
  const [isSubDepartment, setIsSubDepartment] = useState<boolean>(false);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [isAskAddID, setIsAskAddID] = useState<boolean>(false);
  const [departments, setDepartments] = useState<any[]>([]);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [userWarningModal, setUserWarningModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newDepartment, setNewDepartment] = useState<DepartmentItem | null>(
    null
  );
  const [isAllSettings, setIsAllSettings] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: '',
      location: 'MN',
      uniqueCode: '',
      hashedPin: '',
      saltedPin: '',
      isPublic: false,
      isPublicSignup: false,
      parentDep: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      location: Yup.string().required(),
      uniqueCode: Yup.string().required(),
      hashedPin: Yup.string().required(),
      saltedPin: Yup.string().required(),
      isPublic: Yup.boolean().notRequired(),
      isPublicSignup: Yup.boolean().notRequired(),
      parentDep: Yup.object().notRequired(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const userFormik = useFormik({
    initialValues: {
      email: '',
      firstName: 'Hinckley',
      lastName: 'Medical',
      username: '',
      pin: '8485',
      pairedDeps: [],
      password: 'HM8485!',
      type: UserType.ADMIN,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(/\S+@\S+\.\S+/, 'Invalid email address')
        .required('Required'),
      firstName: Yup.string()
        .min(2, 'First name must be at least 2 characters')
        .required('Required'),
      lastName: Yup.string()
        .min(2, 'Last name must be at least 2 characters')
        .required('Required'),
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
      pin: Yup.string()
        .length(4, '4-digit PIN is required')
        .required('Required'),
      pairedDeps: Yup.array().required('Required'),
    }),
    onSubmit: async (values) => {},
  });

  const handleCreateUser = async (
    values: any,
    newDepartment: DepartmentItem,
    cognitoResponse: any
  ) => {
    setIsLoading(true); // Show loading state
    try {
      const { email, firstName, lastName, username, pin, type } = values; // Extract values
      let capitalizedFirstName = firstName;
      let capitalizedLastName = lastName;
      if (firstName[0] !== firstName[0].toUpperCase()) {
        capitalizedFirstName = firstName[0].toUpperCase() + firstName.slice(1);
        userFormik.setFieldValue('firstName', capitalizedFirstName);
      }

      if (lastName[0] !== lastName[0].toUpperCase()) {
        capitalizedLastName = lastName[0].toUpperCase() + lastName.slice(1);
        formik.setFieldValue('lastName', capitalizedLastName);
      }
      // Create user entry in GraphQL or your database
      let dbResponse = await createUserGraphQL(
        type,
        username,
        capitalizedFirstName,
        capitalizedLastName,
        pin,
        newDepartment as DepartmentItem,
        [newDepartment as DepartmentItem],
        [newDepartment as DepartmentItem]
      );
      //  Create user in the Group
      let groupResponse = await addUserToGroup();

      // Check if the user was successfully created in the database
      if (
        dbResponse &&
        groupResponse &&
        groupResponse.statusCode === 200 &&
        cognitoResponse
      ) {
        handleSendEmail(); // Send email to the user
      } else {
        throw new Error('Failed to save user details in the database.'); // Handle the error if the response is not valid
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setIsWarningModal(true); // Show warning modal on error
    } finally {
      setIsLoading(false); // Hide loading state regardless of success or failure
    }
  };

  const handleCreateCognitoUser = async (values: any): Promise<any> => {
    const { email, firstName, lastName, username, pin, type } = values; // Extract values
    let capitalizedFirstName = firstName;
    let capitalizedLastName = lastName;
    if (firstName[0] !== firstName[0].toUpperCase()) {
      capitalizedFirstName = firstName[0].toUpperCase() + firstName.slice(1);
      userFormik.setFieldValue('firstName', capitalizedFirstName);
    }

    if (lastName[0] !== lastName[0].toUpperCase()) {
      capitalizedLastName = lastName[0].toUpperCase() + lastName.slice(1);
      formik.setFieldValue('lastName', capitalizedLastName);
    }

    const cognitoParams = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      Username: username.replace(/\s/g, ''),
      TemporaryPassword: values.password, // Set temporary password
      UserAttributes: [
        { Name: 'email', Value: email },
        {
          Name: 'email_verified',
          Value: 'true', // // Mark email as verified to update email address in cognito later on
        },
        { Name: 'custom:type', Value: '3' },
      ],
      MessageAction: 'SUPPRESS', // it suppress the email notification
    };

    try {
      // Create user in Cognito
      let cognitoResponse = await cognito
        .adminCreateUser(cognitoParams)
        .promise();
      // Step 2: Set the password as permanent
      await cognito
        .adminSetUserPassword({
          UserPoolId: process.env.REACT_APP_USER_POOL_ID,
          Username: username.replace(/\s/g, ''),
          Password: values.password,
          Permanent: true,
        })
        .promise();
      return cognitoResponse;
    } catch (error) {
      console.error('Error creating Cognito user:', error);
      throw error;
    }
  };
  const handleSendEmail = async () => {
    let userEmail = await getEmailByCognitoId(userFormik.values.username);
    if (userFormik.values.username && userEmail) {
      let payload = {};
      payload = {
        email: userEmail,
        actionType: 'SUPER_ADMIN_CREATION',
        username: userFormik.values.username,
        fullName:
          userFormik.values.firstName + ' ' + userFormik.values.lastName,
        departmentName: formik.values.name,
        newPassword: userFormik.values.password,
      };

      const params = {
        FunctionName: 'sendEmailToUsers', // Ensure this matches your Lambda's actual name
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(payload),
      };

      try {
        const result = await lambda.invoke(params).promise();
        const parsedResult = JSON.parse(result.Payload);

        if (parsedResult && parsedResult.statusCode === 200) {
          if (globals.debug)
            console.log('Email sent successfully:', parsedResult);
        } else {
          if (globals.debug)
            console.error('Error in Lambda response:', parsedResult);
          throw new Error('Error invoking Lambda function');
        }
      } catch (error) {
        if (globals.debug)
          console.error('Error invoking Lambda function:', error);
        throw new Error('Error invoking Lambda function');
      }
    }
  };

  const addUserToGroup = async () => {
    const groupCode = getGroupCode(userFormik.values.type);
    const payload = {
      isAddToGroup: true,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userName: userFormik.values.username,
      type: groupCode,
    };

    const params = {
      FunctionName: 'addingUsersByAdmintoOneDoseGroup',
      InvocationType: 'RequestResponse',
      Payload: JSON.stringify(payload),
    };

    try {
      const result = await lambda.invoke(params).promise();
      if (globals.debug) console.log('Lambda invoked successfully:', result);
      return JSON.parse(result.Payload as string);
    } catch (error) {
      console.error('Error invoking Lambda function:', error);
      throw error;
    }
  };

  const handleCreateDepartment = async (isSubAndAddIDs: boolean) => {
    try {
      console.log('Creating departments', isSubAndAddIDs, isCreateValid);
      if (!isCreateValid) return;
      let values = formik.values;
      if (isSubDepartment) {
        let promises: Promise<Response>[] = [];
        for (let dep of departments) {
          let salt = getSalt();
          values = {
            ...values,
            name: dep.name,
            uniqueCode: dep.departmentCode,
            hashedPin: getHashedPin(dep.departmentPin, salt),
            saltedPin: salt,
          };
          promises.push(createDepartment(values));
        }
        let responses = await Promise.all(promises);
        if (globals.debug) console.log('Responses', responses);
        if (formik.values.parentDep) {
          promises = [];
          for (let resp of responses) {
            if (resp.type === ResponseType.Failure) {
              throw new Error(resp.data);
            } else {
              let dep: DepartmentItem = new DepartmentItem(resp.data);
              if (isSubAndAddIDs) {
                promises.push(
                  addDepartmentIDToAllReferences(
                    formik.values.parentDep as DepartmentItem,
                    dep.id
                  )
                );
              }
              promises.push(
                copyConcentrationsToSubDepartment(
                  formik.values.parentDep as DepartmentItem,
                  dep
                )
              );
            }
          }
          let addIDResponses = await Promise.all(promises);
          for (let resp of addIDResponses) {
            if (resp.type === ResponseType.Failure) {
              throw new Error(resp.data);
            }
          }
          if (globals.debug) console.log('Add ID Responses', addIDResponses);
        }

        alert('Sub-departments created successfully');
        navigate('/actions');
      } else {
        let cognitoResponse = await handleCreateCognitoUser(userFormik.values);
        let response = await createDepartment(values);
        if (response.type === ResponseType.Success) {
          let dep: Department = response.data;
          let depItem: DepartmentItem = new DepartmentItem(dep);
          await handleCreateUser(userFormik.values, depItem, cognitoResponse);

          if (isSubAndAddIDs && formik.values.parentDep && isSubDepartment) {
            console.log(
              'Adding the sub-department id to all ModelItems in the parent department'
            );
            let parentDep = formik.values.parentDep as DepartmentItem;
            let resp = await addDepartmentIDToAllReferences(parentDep, dep.id);
            if (resp.type === ResponseType.Success) {
              alert(
                'Added sub-department ID to all references in parent department: ' +
                  resp.data
              );
            }
          }

          /* Create the Electrical, Vitals, and Equipment items from Hinckley Database */
          if (hinckleyDepartment && !isSubDepartment) {
            let promises = [
              copyEquipmentFromDeptoDep(hinckleyDepartment, dep),
              copyVitalsFromDeptoDep(hinckleyDepartment, dep),
              copyElectricalFromDeptoDep(hinckleyDepartment, dep),
            ];
            let [equipNum, vitalNum, elecNum] = await Promise.all(promises);
          }

          setIsSuccessModal(true); // Show success modal

          // navigate('/actions');
        } else {
          alert('Error creating department');
        }
      }
    } catch (error: any) {
      if (error.code === 'UsernameExistsException') {
        setUserWarningModal(true);
      } else
        console.log('Error creating department:', error.code, error.message);
    }
  };

  useEffect(() => {
    const fetchDepartments = async () => {
      let resp = await getDepartments();
      if (resp.type === ResponseType.Success) setDepartmentList(resp.data);
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchHMDB = async () => {
      const db = await DataStore.query(Department, HM_DB_ID);
      if (db) setHinckleyDepartment(db);
    };
    fetchHMDB();
  }, []);

  const handleBack = () => {
    navigate('/actions');
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const generateCodes = () => {
    if (isSubDepartment) {
      let newDepartments = [];
      for (let dep of departments) {
        let salt = getSalt();
        let code = Math.floor(100000 + Math.random() * 900000);
        let _pin = '' + (pin ? pin : Math.floor(1000 + Math.random() * 9000));
        newDepartments.push({
          ...dep,
          departmentCode: code.toString(),
          departmentPin: _pin,
        });
      }
      setDepartments(newDepartments);
    } else {
      //Genereate 6 digit code
      let code = Math.floor(100000 + Math.random() * 900000);
      formik.setFieldValue('uniqueCode', code.toString());

      let _pin = '' + (pin ? pin : Math.floor(1000 + Math.random() * 9000));
      let _salt = getSalt();

      setPin(_pin);
      formik.setFieldValue('hashedPin', getHashedPin(_pin, _salt));
      formik.setFieldValue('saltedPin', _salt);
    }
  };

  const isCreateValid = useMemo(() => {
    if (isSubDepartment) {
      if (departments.length === 0) return false;
      for (let dep of departments) {
        if (
          dep.name === '' ||
          dep.departmentCode === '' ||
          dep.departmentPin === ''
        )
          return false;
      }
      return true;
    } else {
      return (
        formik.isValid && formik.dirty && userFormik.isValid && userFormik.dirty
      );
    }
  }, [formik, departments, isSubDepartment, userFormik]);

  return (
    <div className="screen-container">
      {isLoading && <Loading type="bubbles" />}
      <ProtocolHeader
        page={'Actions'}
        name={'Create Department Page'}
        description={'Create a new parent department or sub-department'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={false}
        isCreateButton={true}
        isCreateActive={isCreateValid}
        type={'protocol'}
        handleCancel={handleBack}
        handleCreate={() => {
          if (isSubDepartment && formik.values.parentDep) {
            setIsAskAddID(true);
          } else if (!isSubDepartment) {
            handleCreateDepartment(false);
          }
        }}
        handleSave={() => {
          userFormik.handleSubmit();
        }}
        handleCancelEdit={() => {
          userFormik.resetForm();
        }}
      />

      {userWarningModal && (
        <ConfirmModal
          isVisible={userWarningModal}
          title="Error: UserName Already Exists"
          handleClose={() => {
            setUserWarningModal(false);
          }}
          handleSubmit={() => setUserWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Username already exists. Please try again with a different username."
        />
      )}

      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Error: User Creation Failed"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          isDeleteBtn={true}
          handleSubmit={() => setIsWarningModal(false)}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription="Please try again later or contact Hinckley Medical Representative at support@hinckleymed.com."
        />
      )}

      {isSuccessModal && (
        <ConfirmModal
          isVisible={isSuccessModal}
          title="Success: Department & Account Created"
          handleClose={() => {
            setIsSuccessModal(false);
          }}
          handleSubmit={() => {
            setIsSuccessModal(false);
            formik.resetForm();
            userFormik.resetForm();
            setIsAskAddID(false);
            setIsSubDepartment(false);
            setPin('');
          }}
          isSingleBtn={true}
          secondaryBtnName="OK"
          primaryDescription={
            'Department & User created. Please check your email for the confirmation code from onedose@hinckleymed.com.\nEmail: ' +
            userFormik.values.email
          }
        />
      )}

      <ConfirmModal
        isVisible={isAskAddID}
        title={
          'Do you want to have this new sub-department subscribe to all the references from ' +
          (formik.values.parentDep as DepartmentItem | null)?.name +
          '?'
        }
        handleClose={() => {
          handleCreateDepartment(false);
          setIsAskAddID(false);
        }}
        handleSubmit={() => {
          setIsAskAddID(false);
          handleCreateDepartment(true);
        }}
        isSingleBtn={false}
        primaryBtnName={`Don't Add`}
        secondaryBtnName="Add"
        primaryDescription={
          'This will make the new department subscribe to all the references from ' +
          (formik.values.parentDep as DepartmentItem | null)?.name +
          ' including checklists, vitals, and equipment.'
        }
      />

      <Row>
        <Col sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <label
              htmlFor="isSubDepartment"
              className="ketamine-general-label"
              style={{ flexDirection: 'row' }}
            >
              Is Sub-Department:
              <InputSwitch
                name="isSubDepartment"
                style={{ marginLeft: '10px' }}
                checked={isSubDepartment}
                onChange={(e) => {
                  setIsSubDepartment(e.value);
                  if (!e.value) formik.setFieldValue('parentDep', null);
                  else
                    setDepartments([
                      {
                        name: '',
                        departmentCode: '',
                        departmentPin: '',
                      },
                    ]);
                }}
              />
            </label>
          </div>
          {isSubDepartment && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Parent Department
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={false}
                onChange={(option: DepartmentItem) =>
                  // handleAddDepartment(option)
                  formik.setFieldValue('parentDep', option)
                }
                onClear={() => formik.setFieldValue('parentDep', null)}
                placeholder="Search department..."
              />
            </>
          )}
          {/* Left column */}
          <label htmlFor="Location" className="ketamine-general-label">
            Location
          </label>
          <Dropdown
            value={formik.values.location}
            options={USAStatesAbrev}
            onChange={(value: any) => {
              formik.setFieldValue('location', value);
            }}
            style={{
              marginTop: '-13px',
              marginLeft: '16px',
              padding: 0,
            }}
            buttonColor={'#E0EADD'}
            textColor={'#00534C'}
          />
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <label
              htmlFor="isSolidOnly"
              className="ketamine-general-label"
              style={{ flexDirection: 'row' }}
            >
              Is Public Signup:
              <InputSwitch
                name="isSolidOnly"
                style={{ marginLeft: '10px' }}
                checked={formik.values.isPublicSignup}
                onChange={(e) => {
                  formik.setFieldValue('isPublicSignup', e.value);
                }}
              />
            </label>
            <label
              htmlFor="isSolidOnly"
              className="ketamine-general-label"
              style={{ flexDirection: 'row' }}
            >
              Is Public:
              <InputSwitch
                name="isSolidOnly"
                style={{ marginLeft: '10px' }}
                checked={formik.values.isPublic}
                onChange={(e) => {
                  formik.setFieldValue('isPublic', e.value);
                }}
              />
            </label>
          </div>

          {!isSubDepartment && (
            <div>
              <label htmlFor="Name" className="ketamine-general-label">
                Name
                <span className="required-field ">
                  *
                  <BiRename
                    className="refresh-icon"
                    onClick={() => {
                      formik.setFieldValue(
                        'name',
                        toTitleCase(formik.values.name)
                      );
                    }}
                    size="1.25rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="name"
                  data-testid="name"
                  required={true}
                  value={formik.values.name}
                  onChange={(e: any) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Name" className="ketamine-general-label">
                Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                Optional to set the pin
              </p>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="pin"
                  name="pin"
                  data-testid="pin"
                  required={true}
                  value={pin}
                  onChange={(e: any) => {
                    setPin(e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <Button
                data-testid="set-Bttn"
                className="primary-button btn-rightMargin"
                disabled={false}
                style={
                  {
                    // marginTop: '3rem',
                  }
                }
                onClick={() => {
                  generateCodes();
                }}
              >
                Generate
              </Button>
            </div>
          )}
        </Col>

        <Col sm={6}>
          {formik.values.parentDep != null && (
            <div>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Parent Sub-Departments:{' '}
                {(formik.values.parentDep as DepartmentItem).allSubDeps
                  ?.length || 0}
              </label>
              <div
                className="list-container"
                style={{
                  border: '1px solid #E0EADD',
                  borderRadius: '5px',
                }}
              >
                <ViewportList
                  items={
                    (formik.values.parentDep as DepartmentItem).allSubDeps || []
                  }
                >
                  {(item: any, index: any) => (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          borderBottom: '1px solid #E0EADD',
                        }}
                      >
                        <span
                          style={{
                            fontSize: '1rem',
                            fontWeight: '500',
                            marginRight: '10px',
                            padding: '5px',
                          }}
                        >
                          {item.name}
                        </span>
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
          {isSubDepartment && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <label
                  htmlFor="Name"
                  className="ketamine-general-label"
                  style={{ width: '100%' }}
                >
                  Name
                  <span className="required-field ">*</span>
                  <Tooltip title={'Title Case'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        let newDepartments = departments.map((dep) => ({
                          ...dep,
                          name: toTitleCase(dep.name),
                        }));
                        setDepartments(newDepartments);
                      }}
                    >
                      <BiRename
                        className="icon"
                        size="1.25rem"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip title={'Add'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        setDepartments([
                          ...departments,
                          {
                            name: '',
                            departmentCode: '',
                            departmentPin: '',
                          },
                        ]);
                      }}
                    >
                      <IoMdAdd
                        data-testid="isDotButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip title={'Generate Codes'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        generateCodes();
                      }}
                    >
                      <FaCodeMerge
                        data-testid="isDotButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>
                </label>
                <label
                  htmlFor="departmentCode"
                  className="ketamine-general-label"
                  style={{ minWidth: '100px' }}
                >
                  Code
                </label>
                <label
                  htmlFor="departmentPin"
                  className="ketamine-general-label"
                  style={{ minWidth: '100px' }}
                >
                  Pin
                </label>
              </div>
              {departments.map((dep, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div className="input-container" style={{ width: '100%' }}>
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="departmentName"
                      name="departmentName"
                      data-testid="departmentName"
                      required={true}
                      value={dep.name}
                      onKeyDown={(e: any) => {
                        if (e.shiftKey && e.key === 'Enter') {
                          //add a new department
                          setDepartments([
                            ...departments,
                            {
                              name: '',
                              departmentCode: '',
                              departmentPin: '',
                            },
                          ]);
                        } else if (e.key === 'Enter') {
                          generateCodes();
                        }
                      }}
                      onChange={(e: any) => {
                        let newDepartments = departments.map((d, i) =>
                          i === index ? { ...d, name: e.target.value } : d
                        );
                        setDepartments(newDepartments);
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="departmentCode"
                      name="departmentCode"
                      data-testid="departmentCode"
                      required={true}
                      value={dep.departmentCode}
                    />
                  </div>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="departmentPin"
                      name="departmentPin"
                      data-testid="departmentPin"
                      required={true}
                      value={dep.departmentPin}
                    />
                  </div>
                  <Tooltip title={'Delete'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        setDepartments(
                          departments.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      <BiTrash
                        className="icon"
                        size="1.2rem"
                        style={{ padding: '6px' }}
                      />
                    </span>
                  </Tooltip>
                </div>
              ))}
            </div>
          )}

          {!isSubDepartment && (
            <div className="KetamineGeneral">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h5 className="ketmine-header-text">Create Super Admin User</h5>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <label
                    htmlFor=""
                    className="settings-general-label"
                    style={
                      {
                        // marginTop: '18px',
                      }
                    }
                  >
                    Config
                  </label>
                  <div style={{ marginTop: '-18px' }}>
                    <HMSwitch
                      checked={isAllSettings}
                      onChange={(e, checked) => {
                        setIsAllSettings(checked);
                      }}
                      tooltipText="Configure more settings"
                    />
                  </div>
                </div>
              </div>
              <Row>
                <Col sm={6}>
                  <label htmlFor="Name" className="ketamine-general-label">
                    Email <span className="required-field">*</span>
                  </label>
                  <div className="input-container">
                    <InputText
                      type="email"
                      className="form-control-general"
                      id="email"
                      name="email"
                      data-testid="email"
                      value={userFormik.values.email}
                      onChange={(e: any) => {
                        userFormik.setFieldValue('email', e.target.value);
                      }}
                    />
                    <div className="input-border"></div>
                    {userFormik.touched.email && userFormik.errors.email && (
                      <div className="errorText">{userFormik.errors.email}</div>
                    )}
                  </div>
                </Col>
                <Col sm={6}>
                  <label htmlFor="Name" className="ketamine-general-label">
                    Username <span className="required-field">*</span>
                  </label>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="username"
                      name="username"
                      data-testid="username"
                      value={userFormik.values.username}
                      onChange={(e: any) => {
                        userFormik.setFieldValue('username', e.target.value);
                      }}
                    />
                    <div className="input-border"></div>
                    {userFormik.touched.username &&
                      userFormik.errors.username && (
                        <div className="errorText">
                          {userFormik.errors.username}
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
              {isAllSettings && (
                <>
                  {' '}
                  <Row>
                    <Col sm={6}>
                      <label htmlFor="Name" className="ketamine-general-label">
                        First Name <span className="required-field">*</span>
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="firstName"
                          name="firstName"
                          data-testid="firstName"
                          value={userFormik.values.firstName}
                          onChange={(e: any) => {
                            userFormik.setFieldValue(
                              'firstName',
                              e.target.value
                            );
                          }}
                        />
                        <div className="input-border"></div>
                        {userFormik.touched.firstName &&
                          userFormik.errors.firstName && (
                            <div className="errorText">
                              {userFormik.errors.firstName}
                            </div>
                          )}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <label htmlFor="Name" className="ketamine-general-label">
                        Last Name <span className="required-field">*</span>
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="lastName"
                          name="lastName"
                          data-testid="lastName"
                          value={userFormik.values.lastName}
                          onChange={(e: any) => {
                            userFormik.setFieldValue(
                              'lastName',
                              e.target.value
                            );
                          }}
                        />
                        <div className="input-border"></div>
                        {userFormik.touched.lastName &&
                          userFormik.errors.lastName && (
                            <div className="errorText">
                              {userFormik.errors.lastName}
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <label htmlFor="Name" className="ketamine-general-label">
                        Password <span className="required-field">*</span>
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="password"
                          name="password"
                          data-testid="password"
                          value={userFormik.values.password}
                          onChange={(e: any) => {
                            const password = e.target.value;
                            userFormik.setFieldValue('password', password);
                          }}
                        />
                        <div className="input-border"></div>
                        {userFormik.touched.password &&
                          userFormik.errors.password && (
                            <div className="errorText">
                              {userFormik.errors.password}
                            </div>
                          )}
                      </div>
                    </Col>
                    <Col sm={6}>
                      <label htmlFor="Name" className="ketamine-general-label">
                        4 Digit Pin <span className="required-field">*</span>
                      </label>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          id="pin"
                          name="pin"
                          data-testid="pin"
                          value={userFormik.values.pin}
                          onChange={(e: any) => {
                            const pin = e.target.value.replace(/\s+/g, ''); // Remove all spaces
                            if (pin.length <= 4 && !isNaN(Number(pin)))
                              userFormik.setFieldValue('pin', pin);
                          }}
                        />
                        <div className="input-border"></div>
                        {userFormik.touched.pin && userFormik.errors.pin && (
                          <div className="errorText">
                            {userFormik.errors.pin}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}

          {!isSubDepartment && formik.values.hashedPin !== '' && (
            <div>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Hashed Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.hashedPin}
                {isCopied !== formik.values.hashedPin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.hashedPin, e)}
                  />
                )}
                {isCopied === formik.values.hashedPin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Salt Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.saltedPin}
                {isCopied !== formik.values.saltedPin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.saltedPin, e)}
                  />
                )}
                {isCopied === formik.values.saltedPin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Unique Code:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {formik.values.uniqueCode}
                {isCopied !== formik.values.uniqueCode && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(formik.values.uniqueCode, e)}
                  />
                )}
                {isCopied === formik.values.uniqueCode && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
              <label
                className="ketamine-general-label"
                style={{ marginTop: '20px' }}
              >
                Pin:
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {pin}
                {isCopied !== pin && (
                  <BiCopy
                    size="1rem"
                    className="refresh-icon"
                    onClick={(e) => handleCopy(pin, e)}
                  />
                )}
                {isCopied === pin && (
                  <BiSolidCopy
                    color={'#00534C'}
                    size="1rem"
                    className="refresh-icon"
                  />
                )}
              </p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DepartmentPage;
