import { create } from 'lodash';
import { ProgressStatus } from '../../models';
import { User } from '../../models';
import { findDepartmentOwner, globals } from '../../ui/_global/common/Utils';
import { Response, ResponseType } from '../AmplifyDB';
import CategoryItem, { cloneCategory } from '../model/CategoryItem';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import ModelItem from '../model/ModelItem';
import ModelSubItem from '../model/ModelSubItem';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import { createCategory, deleteCategory } from './CategoryDB';
import {
  createMedicationDoseItem,
  createParentMedication,
  deleteMedication,
  deleteMedicationDoseItem,
} from './MedicationDB';
import { createProtocol, deleteProtocol } from './ProtocolDB';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../model/MedicationSubItem';
import {
  createInfusionDoseItem,
  createParentInfusion,
  deleteInfusion,
  deleteInfusionDoseItem,
} from './InfusionDB';
import InfusionItem, { cloneInfusion } from '../model/InfusionItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../model/InfusionSubItem';
import {
  createElectrical,
  createElectricalDoseItem,
  deleteElectrical,
  deleteElectricalDoseItem,
} from './ElectricalDB';
import ElectricalItem, { cloneElectrical } from '../model/ElectricalItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../model/ElectricalSubItem';
import { createEquipment, deleteEquipment } from './EquipmentDB';
import EquipmentItem from '../model/EquipmentItem';
import { createVital, deleteVital } from './VitalDB';
import VitalItem from '../model/VitalItem';
import FormItem from '../model/FormItem';
import { createCheckList, deleteCheckList } from './CheckListDB';

export const cloneModelItem = (
  item: ModelItem<any> | ModelSubItem<any>
): ModelItem<any> | ModelSubItem<any> => {
  switch (item.TAG) {
    case 'CategoryItem':
      return cloneCategory(item as CategoryItem);
    case 'ProtocolItem':
      return cloneProtocol(item as ProtocolItem);
    case 'MedicationItem':
      return cloneMedication(item as MedicationItem);
    case 'MedicationSubItem':
      return cloneMedicationSubItem(item as MedicationSubItem);
    case 'InfusionItem':
      return cloneInfusion(item as InfusionItem);
    case 'InfusionSubItem':
      return cloneInfusionSubItem(item as InfusionSubItem);
    case 'ElectricalItem':
      return cloneElectrical(item as ElectricalItem);
    case 'ElectricalSubItem':
      return cloneElectricalSubItem(item as ElectricalSubItem);
    case 'EquipmentItem':
      return new EquipmentItem(item.model);
    case 'VitalItem':
      return new VitalItem(item.model);
    case 'FormItem':
      return new FormItem(item.model);
    default:
      console.error('Error in cloneItem: unknown item type');
      return item;
  }
};

export const handleToggleEnabled = (
  user: User,
  department: DepartmentItem,
  item: ModelItem<any> | ModelSubItem<any>
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Find the owner (Department) of the ModelItem or ModelSubItem */
      const owner = findDepartmentOwner(department, item);
      if (owner == null) {
        console.error('Error in handleToggleEnabled: owner not found');
        return reject('Owner not found');
      }
      const isDisable = item.status !== ProgressStatus.DEACTIVATED;
      if (isDisable) {
        let clone = cloneModelItem(item);
        clone.departmentID = department.id;
        clone.modifiedBy = user;
        clone.overrideID =
          item.status.includes('DRAFT') && item.activeID
            ? item.activeID
            : item.uid;
        clone.status = ProgressStatus.DEACTIVATED;

        clone.activeID = undefined;
        let createPromise;
        switch (item.TAG) {
          case 'CategoryItem':
            createPromise = createCategory(
              clone as CategoryItem,
              item as CategoryItem
            );
            break;
          case 'ProtocolItem':
            createPromise = createProtocol(
              clone as ProtocolItem,
              item as ProtocolItem
            );
            break;
          case 'MedicationItem':
            createPromise = createParentMedication(
              department,
              clone as MedicationItem,
              item as MedicationItem
            );
            break;
          case 'MedicationSubItem':
            createPromise = createMedicationDoseItem(
              clone as MedicationSubItem,
              item as MedicationSubItem
            );
            break;
          case 'InfusionItem':
            createPromise = createParentInfusion(
              department,
              clone as InfusionItem,
              item as InfusionItem
            );
            break;
          case 'InfusionSubItem':
            createPromise = createInfusionDoseItem(
              clone as InfusionSubItem,
              item as InfusionSubItem
            );
            break;
          case 'ElectricalItem':
            createPromise = createElectrical(
              clone as ElectricalItem,
              item as ElectricalItem
            );
            break;
          case 'ElectricalSubItem':
            createPromise = createElectricalDoseItem(
              clone as ElectricalSubItem,
              item as ElectricalSubItem
            );
            break;
          case 'EquipmentItem':
            createPromise = createEquipment(
              clone as EquipmentItem,
              item as EquipmentItem
            );
            break;
          case 'VitalItem':
            createPromise = createVital(clone as VitalItem, item as VitalItem);
            break;
          case 'FormItem':
            createPromise = createCheckList(
              clone as FormItem,
              item as FormItem
            );
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let createResult: Response = await createPromise;
        if (createResult.type === ResponseType.Success) {
          console.log('Success in handleToggleEnabled: CREATE succeeded', item);
          const newItem = createResult.data as
            | ModelItem<any>
            | ModelSubItem<any>;
          newItem.overrideItem = item;

          return resolve(createResult);
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            createResult.data
          );
          return resolve(createResult);
        }
      } else {
        /* Find the Delete function */
        let deletePromise;
        switch (item.TAG) {
          case 'CategoryItem':
            deletePromise = deleteCategory(
              department,
              item as CategoryItem,
              user,
              false
            );
            break;
          case 'ProtocolItem':
            deletePromise = deleteProtocol(
              department,
              item as ProtocolItem,
              user,
              false,
              false,
              false
            );
            break;
          case 'MedicationItem':
            deletePromise = deleteMedication(item as MedicationItem, false);
            break;
          case 'MedicationSubItem':
            deletePromise = deleteMedicationDoseItem(
              item as MedicationSubItem,
              false
            );
            break;
          case 'InfusionItem':
            deletePromise = deleteInfusion(item as InfusionItem, false);
            break;
          case 'InfusionSubItem':
            deletePromise = deleteInfusionDoseItem(
              item as InfusionSubItem,
              false
            );
            break;
          case 'ElectricalItem':
            deletePromise = deleteElectrical(item as ElectricalItem, false);
            break;
          case 'ElectricalSubItem':
            deletePromise = deleteElectricalDoseItem(
              item as ElectricalSubItem,
              false
            );
            break;
          case 'EquipmentItem':
            deletePromise = deleteEquipment(item as EquipmentItem, false);
            break;
          case 'VitalItem':
            deletePromise = deleteVital(item as VitalItem, false);
            break;
          case 'FormItem':
            deletePromise = deleteCheckList(item as FormItem, false);
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let deleteResult: Response = await deletePromise;
        if (deleteResult.type === ResponseType.Success) {
          if (globals.debug)
            console.log(
              'Success in handleToggleEnabled: delete succeeded',
              item
            );
          if (item.overrideItem) {
            let newItem = cloneModelItem(item.overrideItem);
            newItem.status = ProgressStatus.DEACTIVATED;
            return resolve({
              type: ResponseType.Success,
              data: newItem,
            });
          } else {
            if (globals.debug)
              console.log('No override item found in handleToggleEnabled');
            return resolve(deleteResult);
          }
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            deleteResult.data
          );
          return resolve({
            type: ResponseType.Failure,
            data: deleteResult.data,
          });
        }
      }
    } catch (error) {
      console.error('Error in handleToggleEnabled:', error);
      reject(error);
    }
  });
};
