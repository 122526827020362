import { useMemo, useState } from 'react';
import MedicationItem from '../../../../data/model/MedicationItem';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../../../../data/model/MedicationSubItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationDoseSideout from '../../../components/SideOut/doseSideOut/MedicationDoseSideout';
import {
  createMedicationDoseItem,
  deleteMedicationDoseItem,
  duplicateMedicationDose,
  MedicationDoseJSON,
} from '../../../../data/functions/MedicationDB';
import { useSelector } from 'react-redux';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { User } from '../../../../models';
import { ProgressStatus } from '../../../../models';
import { globals, upgradeVersion } from '../../../_global/common/Utils';
import { auditMedicationDosesToOldDBStyle } from '../../../../data/AmplifyActions';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import PatientAge from '../../../_global/common/PatientAge';

interface MedicationDoseSideoutProps {
  visible: boolean;
  protocol?: ProtocolItem | undefined | null;
  parentModel: MedicationItem;
  dose?: MedicationSubItem;
  handleClose: () => void;
  handleSubmit: (reloadDatabase: boolean) => void;
}
/* 09-28-23 Arul: Created Department component  for Protocol page to display the category and protocol accordion*/
const NewMedicationDoseSideout = (props: MedicationDoseSideoutProps) => {
  const { visible, dose, parentModel, protocol, handleClose, handleSubmit } =
    props;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state?.user);

  const parmMedication = useMemo(() => {
    return parentModel;
  }, [parentModel]);

  const [allSubMedicationItems, setAllSubMedicationItems] = useState<
    MedicationSubItem[]
  >(parmMedication.subItems);
  const subMedicationItems = useMemo(() => {
    return protocol
      ? allSubMedicationItems
          .filter(
            (item: MedicationSubItem) =>
              item.parentProtocol.uid === protocol.uid
          )
          .sort((a, b) => a.index - b.index)
      : allSubMedicationItems;
  }, [allSubMedicationItems, protocol]);

  const solidUnits = useMemo(() => {
    if (parmMedication.concentrations?.length > 0) {
      let concen = parmMedication.concentrations[0];
      return concen.firstUnit;
    }
    return '';
  }, [parmMedication]);

  const handleOnSubmitMedicationDose = async (
    data: any,
    previousDose: MedicationSubItem | undefined,
    parentProtocol: ProtocolItem | undefined
  ) => {
    if (globals.debug)
      console.log('Submitting Dose', data, previousDose, parentProtocol);
    let prot = parentProtocol ? parentProtocol : protocol;
    if (!prot) {
      console.error('Failed to find protocol');
      return;
    }

    let protocolID =
      prot.status === ProgressStatus.DRAFT && prot.activeID !== null
        ? prot.activeID
        : prot.uid;

    if (!protocolID) {
      console.error('Failed to find protocol ID');
      return;
    }

    /* Create a new MedicationDose for the medicaiton */
    let prevDose = previousDose as MedicationSubItem;
    const isOwner = prevDose ? department.id === prevDose.departmentID : true;
    let index = prevDose ? prevDose.index : prot.medications.length;
    if (department.parentDep?.parentDep) index += 10000;
    else if (department.parentDep) index += 1000;

    let newMedDose: MedicationDoseJSON = {
      departmentID: department.id,
      medication: parmMedication,
      protocol: prot,
      rangeLow: data.rangeLow ? Number(data.rangeLow) : 0,
      rangeHigh: data.rangeHigh ? Number(data.rangeHigh) : globals.MAX_VALUE,
      createdBy: user.id,
      basis: data.basis,
      route: data.routes,
      title: data.warning ? undefined : data.title,
      nemsisRoutes: data.nemsisRoutes,
      warning: data.warning,
      instruction: data.instruction,
      note: data.note,
      maxDose: data.maxDose,
      minDose: data.minDose,
      maxTotalDose: data.maxTotalDose,
      calcMax: data.calcMax,
      calcMin: data.calcMin,
      index: index,
      ageLow: data.ageLow ? (data.ageLow as PatientAge).ageValue : 0,
      ageHigh: data.ageHigh
        ? (data.ageHigh as PatientAge).ageValue
        : globals.MAX_VALUE,
      ageGroup: data.ageGroup,
      repeatTime: data.repeatTime,

      activeID: !isOwner
        ? undefined
        : prevDose == null
          ? null
          : prevDose.status === ProgressStatus.ACTIVE
            ? prevDose.uid
            : prevDose.activeID,

      overrideID: isOwner ? null : prevDose?.uid,
      status: ProgressStatus.DRAFT,
      version:
        prevDose == null
          ? 'v1.0.0'
          : prevDose.status === ProgressStatus.ACTIVE
            ? upgradeVersion(prevDose.version)
            : prevDose.version,
      createdAt: prevDose?.createdAt ? prevDose.createdAt : new Date(),
    };

    createMedicationDoseItem(newMedDose, isOwner ? prevDose : undefined).then(
      (response: Response) => {
        if (response.type === ResponseType.Success) {
          let newMedSubItem = response.data as MedicationSubItem;
          if (globals.debug)
            console.log('Successfully created dose', newMedSubItem);
          //   newMedSubItem.activeItem = prevDose ? prevDose : null;
          //   let allSubMeds = prevDose
          //     ? allSubMedicationItems.filter(
          //         (item: MedicationSubItem) => item !== prevDose
          //       )
          //     : allSubMedicationItems;
          //   setAllSubMedicationItems([...allSubMeds, newMedSubItem]);
          handleSubmit(true);
          // reloadDatabase(parmMedication.uid);
        }
      }
    );
  };

  const handleCopyProtocolDoses = async (
    toProtocol: ProtocolItem,
    fromProtocol: ProtocolItem
  ) => {
    /* First find the Medication Protocol */
    let promises: any[] = [];
    let medDoses = [...fromProtocol.medications];
    for (let i = 0; i < medDoses.length; i++) {
      let medDose = medDoses[i];
      let parent = parmMedication;
      if (
        medDose.parent.uid === parent.uid ||
        medDose.parent.activeID === parent.uid
      ) {
        promises.push(
          duplicateMedicationDose(department, medDose, user, toProtocol)
        );
      }
    }
    let results = await Promise.all(promises);
    handleSubmit(true);
    // let meds = [...allSubMedicationItems];
    // for (let i = 0; i < results.length; i++) {
    //   let result = results[i];
    //   if (result.type === ResponseType.Success) {
    //     let newMedDose = result.data;
    //     meds.push(newMedDose);
    //   }
    // }
    // setAllSubMedicationItems(meds);
  };

  const handleRemoveMedicationDose = async (
    dose: MedicationSubItem,
    type: 'block' | 'restore' | 'delete'
  ) => {
    console.log('Deleting Dose', dose, type);
    let subMeds = [...subMedicationItems];

    if (type === 'block') {
      let resp: Response = await handleToggleEnabled(user, department, dose);
      if (resp.type === ResponseType.Success) {
        let updatedDose = resp.data as MedicationSubItem;
        if (globals.debug) console.log(' Blocked Dose', updatedDose);
        handleSubmit(true);
      } else {
        console.error('Failed to block dose', resp);
      }
    } else if (
      /* Use Cases: Created a new dose and deleted it, or deleted an existing dose */
      dose.status === ProgressStatus.DEACTIVATED ||
      (dose.status === ProgressStatus.DRAFT && dose.activeID == null)
    ) {
      let resp = await deleteMedicationDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        if (globals.debug) console.log('Deleted Dose', resp);
        let deletedDose: MedicationSubItem = resp.data;
        auditMedicationDosesToOldDBStyle([deletedDose])
          .then((response) => {
            if (response.type === ResponseType.Success) {
              console.log('Successfully audited doses', response.data);
            }
          })
          .catch((error) => {
            console.error('Failed to audit doses', error);
          });
        handleSubmit(true);
        // let deletedDose: MedicationSubItem = resp.data;

        // let allDoses = allSubMedicationItems.filter(
        //   (item: MedicationSubItem) => item !== dose
        // );
        // if (
        //   dose.status === ProgressStatus.DEACTIVATED &&
        //   deletedDose.overrideItem
        // )
        //   allDoses.push(deletedDose.overrideItem);
        // setAllSubMedicationItems([...allDoses]);
        // reloadDatabase(parmMedication.uid);
      }
    } else {
      let prevStatus = dose.status;
      let resp = await deleteMedicationDoseItem(dose, false);
      if (resp.type === ResponseType.Success) {
        let deletedDose: MedicationSubItem = resp.data;
        if (globals.debug) console.log('Deleted Dose', deletedDose.name);
        if (prevStatus === ProgressStatus.ACTIVE) {
          auditMedicationDosesToOldDBStyle([deletedDose])
            .then((response) => {
              if (response.type === ResponseType.Success) {
                console.log('Successfully audited doses', response.data);
              }
            })
            .catch((error) => {
              console.error('Failed to audit doses', error);
            });
        }
        handleSubmit(true);
        // let allDoses = allSubMedicationItems.filter(
        //   (item: MedicationSubItem) => item !== dose
        // );
        // setAllSubMedicationItems([...allDoses]);
        // reloadDatabase(parmMedication.uid);
      }
    }
  };

  return (
    <MedicationDoseSideout
      visible={visible}
      setVisible={handleClose}
      doseIndex={dose?.index ?? 1}
      solidUnit={solidUnits}
      protocol={protocol != null ? protocol : null}
      parentModel={parmMedication}
      adminRoutes={parmMedication.routes}
      dose={dose}
      subMedicationItems={allSubMedicationItems}
      editDose={dose != null}
      onSubmit={handleOnSubmitMedicationDose}
      onSubmitMultiDose={handleCopyProtocolDoses}
      onSetNewRoutes={(newRoutes: string[]) => {
        // formik.setFieldValue('routes', newRoutes);
      }}
      onRemove={handleRemoveMedicationDose}
    />
  );
};

export default NewMedicationDoseSideout;
