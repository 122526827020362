import { useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  ResponseType,
  fetchCategoriesWithProtocols,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { setDepartmentProtocolOwners } from '../../../../../data/AmplifyActions';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { globals } from '../../../../_global/common/Utils';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ProtocolOwner = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;

  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [protocols, setProtocols] = useState<ProtocolItem[]>([]);

  const [list, setList] = useState<DepartmentItem[]>([]);

  const [info, setInfo] = useState<any>({
    department: null,
    protocolList: [],
    subDepartmentList: [],
  });

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadData = async (
    department: DepartmentItem
  ): Promise<ProtocolItem[] | null> => {
    const response = await fetchCategoriesWithProtocols(department);
    if (response.type === ResponseType.Success) {
      let protocols = response.data[1];
      setProtocols(protocols);
      return protocols;
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  // useEffect(() => {
  //     if(info.departmentFrom !== null) loadVitalsData(info.departmentFrom, true);
  //     if(info.departmentTo !== null)
  // }
  // , [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/sub-departments`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    let protocols = await loadData(department);
    setInfo({
      ...info,
      department: department,
      protocolList: protocols,
      subDepartmentList: department.subDeps,
    });
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter(
      (item) =>
        item.id !== info.departmentFrom?.id && item.id !== info.departmentTo?.id
    );
    if (globals.debug) console.log('List', l);
    setList(l);
  }, [info]);

  const handleRouteToEquipment = (department: DepartmentItem) => {};

  const handleSubmit = async () => {
    if (
      info.department === null ||
      info.subDepartmentList.length == null ||
      info.subDepartmentList.length === 0
    ) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    }
    if (globals.debug) console.log('Department Transfer', info);
    let result = await setDepartmentProtocolOwners(
      info.department,
      info.subDepartmentList,
      info.protocolList
    );
    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      if (globals.debug)
        console.log('Set', results, 'protocols with the new owners');
      let isConfirmed = window.confirm(
        `Copied ${results} protocols to have ${info.subDepartmentList.length} sub-department owners. Nice work!`
      );
      if (isConfirmed) {
        handleRouteToEquipment(info.department);
      }
    } else {
      if (globals.debug) console.log('Failed to set protocol owners', result);
    }
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleRemoveDepartment = (item: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = [...info.subDepartmentList];
    l = l.filter((i) => i.id !== item.id);
    setInfo({ ...info, subDepartmentList: l });
  };

  const isSaveValid = useMemo(() => {
    return info.department !== null && info.subDepartmentList.length > 0;
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Sub-Department Actions'}
        name={'Set Protocol Owners'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleSave={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Row>
        <Col sm={6}>
          {' '}
          {/* Left column */}
          <label className="ketamine-general-label">
            Parent Department: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to edit the category ownership for.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              handleDepartmentSelected(option, true);
            }}
            onClear={() => {
              setInfo({
                ...info,
                department: null,
                protocolList: [],
                subDepartmentList: [],
              });
            }}
            placeholder="Search department..."
          />
          <label className="ketamine-general-label">
            Parent Protocols: {info.department ? info.protocolList.length : ''}
          </label>
          <hr style={{ margin: 10 }} />
          {!info.department && (
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              None selected...
            </p>
          )}
          {info.department &&
            info.protocolList.map((item: ProtocolItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Sub-deps: {item.pairedDepIDs?.length}</h6>
                </div>
              </div>
            ))}
        </Col>
        <Col sm={6}>
          {' '}
          {/* Right column */}
          <label className="ketamine-general-label">
            Sub-Departments:
            {info.department && (
              <span
                onClick={() => {
                  setInfo({
                    ...info,
                    subDepartmentList: info.department.subDeps,
                  });
                }}
              >
                <div className="clickableText">Add All</div>
              </span>
            )}
          </label>
          <hr style={{ margin: 10 }} />
          {!info.department && (
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              None selected...
            </p>
          )}
          {info.department && (
            <div>
              <label className="ketamine-general-label">
                Name: {info.department.name}
              </label>
              <label className="ketamine-general-label">
                Location: {info.department.location}
              </label>
              <label className="ketamine-general-label">Sub-Departments:</label>
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: 5,
                  padding: 0,
                }}
              >
                <ViewportList items={info.subDepartmentList}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        borderBottom:
                          index === info.department.subDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                    >
                      <Accordion>
                        <div
                          style={rowStyle}
                          className="departmentItem"
                          // onMouseEnter={() => setIsHovered(true)}
                          // onMouseLeave={() => setIsHovered(false)}
                        >
                          <h6 className="departmentItemText">
                            {item.name + ' - ' + item.location}
                          </h6>
                          <h6 className="departmentItemText">
                            <span>
                              <FaTimes
                                color={'red'}
                                className="icon-size"
                                style={{ height: 20, width: 20 }}
                                onClick={(e) => handleRemoveDepartment(item, e)}
                              />
                            </span>
                          </h6>
                        </div>
                      </Accordion>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProtocolOwner;
