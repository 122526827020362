const handleLogin = async () => {
  return {
    status: 'success',
    message: 'Login  success',
  };
};

const handleLogOut = async () => {
  return {
    status: 'success',
    message: 'LogOut  success',
  };
};

const handleUpdateUser = async () => {
  return {
    status: 'success',
    message: 'User Data',
  };
};

const handleUpdateCognitoUser = async () => {
  return {
    status: 'success',
    message: 'User Data',
  };
};

const handleShowSideBar = async () => {
  return {
    status: 'success',
    message: 'Sidebar show and hide ',
  };
};

const setSidebarMode = async () => {
  return {
    status: 'success',
    message: 'Sidebar in mini mode and non mini mode',
  };
};

const handleGetDepartment = async () => {
  return {
    status: 'success',
    message: 'Update department list',
  };
};

const handleUpdateEditProtocol = async () => {
  return {
    status: 'success',
    message: 'Edit Protocol Updated',
  };
};

const handleUpdateEditFolderCategory = async () => {
  return {
    status: 'success',
    message: 'Edit Folder Updated',
  };
};

const handleSelectDataTable = async () => {
  return {
    status: 'success',
    message: 'Datatable selection',
  };
};

const handleDataTableRowSelection = async () => {
  return {
    status: 'success',
    message: 'Datatable Row selection',
  };
};

const handleUpdateEquipmentValue = async () => {
  return {
    status: 'success',
    message: 'Update Equipment Protocol values',
  };
};

const handleUpdateEquipmentOptionValue = async () => {
  return {
    status: 'success',
    message: 'Update Equipment Option values',
  };
};

const handleUpdateRouteList = async () => {
  return {
    status: 'success',
    message: 'Update Route values',
  };
};

const handleSetProtocolData = async () => {
  return {
    status: 'success',
    message: 'Update protocol values',
  };
};

const handleSetSelectedDoseValue = async () => {
  return {
    status: 'success',
    message: 'set  selected dose values',
  };
};

const handleUpdateSelectedAccordion = async () => {
  return {
    status: 'success',
    message: 'Open accordion item updated',
  };
};

const handleGetNotification = async () => {
  return {
    status: 'success',
    message: 'Get Notification list',
  };
};

const handlePublicSelectedItem = async () => {
  return {
    status: 'success',
    message: 'Public selected item',
  };
};

const handleDepartmentCache = async () => {
  return {
    status: 'success',
    message: 'Department cache',
  };
};

const handleNotificationCache = async () => {
  return {
    status: 'success',
    message: 'Notification cache',
  };
};

const handleHinckleyCache = async () => {
  return {
    status: 'success',
    message: 'Hinckley cache',
  };
};

const handleReviewalsCache = async () => {
  return {
    status: 'success',
    message: 'Reviewals cache',
  };
};

const handleLoadingFavoicon = async () => {
  return {
    status: 'success',
    message: 'Loading favicon',
  };
};

const handleShowActivityLog = async () => {
  return {
    status: 'success',
    message: 'Activity Log Sidebar show and hide',
  };
};

export default {
  handleLoadingFavoicon,
  handleLogin,
  handleLogOut,
  handleShowSideBar,
  setSidebarMode,
  handleGetDepartment,
  handleUpdateEditProtocol,
  handleUpdateEditFolderCategory,
  handleSelectDataTable,
  handleDataTableRowSelection,
  handleUpdateEquipmentValue,
  handleUpdateEquipmentOptionValue,
  handleUpdateRouteList,
  handleSetProtocolData,
  handleSetSelectedDoseValue,
  handleUpdateSelectedAccordion,
  handleGetNotification,
  handleUpdateUser,
  handleUpdateCognitoUser,
  handlePublicSelectedItem,
  handleDepartmentCache,
  handleNotificationCache,
  handleHinckleyCache,
  handleReviewalsCache,
  handleShowActivityLog,
};
