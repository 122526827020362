import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const notificationsByDepartmentID =
  /* GraphQL */ `query NotificationsByDepartmentID(
  $departmentID: ID!
  $timestamp: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByDepartmentID(
    departmentID: $departmentID
    timestamp: $timestamp
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          group {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            departmentID
            isAutoGen
            name
            pairedDepIDs
            pairedUserIDs
            type
            updatedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.NotificationsByDepartmentIDQueryVariables,
    APITypes.NotificationsByDepartmentIDQuery
  >;
