import { ACKStatus } from '../../../API';
import { DraftChangeType } from '../../../data/model/DraftChangeItem';

export const commonStyle = {
  padding: '5px',
  marginBottom: '5px',
  justifyContent: 'center',
  marginRight: '5px',
  borderRadius: '5px',
};

export const getStatusStyles = (status: ACKStatus | DraftChangeType) => {
  switch (status) {
    case ACKStatus.PENDING:
    case DraftChangeType.FOLDER:
      return {
        borderColor: '#ADD8E6', // Light Blue
        backgroundColor: '#ADD8E6', // Lighter Blue
        color: '#0d4d8e', // Dark Blue
      };
    case ACKStatus.PUBLISHED:
    case ACKStatus.APPROVED:
    case DraftChangeType.PROTOCOL:
      return {
        borderColor: '#C3DBB0', // Green
        backgroundColor: '#C3DBB0', // Lighter Green
        color: '#037F02', // Dark Green
      };
    case ACKStatus.REJECTED:
    case DraftChangeType.PROTOCOL_PDF:
      return {
        borderColor: '#FFC7CE', // Red
        backgroundColor: '#FFC7CE', // Lighter Red
        color: '#8c1010', // Dark Red
      };
    case DraftChangeType.MEDICATION:
      return {
        borderColor: '#E8F9F2', // Light Yellow
        backgroundColor: '#E8F9F2', // Light Yellow
        color: '#0C6C57', // MUSTARD
      };
    default:
      return {
        borderColor: '#D3D3D3', // Default Gray
        backgroundColor: '#D3D3D3', // Lighter Gray
        color: '#3d3d3d', // Dark Gray
      };
  }
};
