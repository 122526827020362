import { Dialog } from 'primereact/dialog';
import '../../../components/Popup.css';
import { ReactNode } from 'react';

interface FullViewDialogModalProps {
  isVisible: boolean;
  handleClose: () => void;
  isDeleteBtn?: any;
  title: any;
  primaryDescription?: any;
  secondaryDescription?: any;
  primaryBtnName?: any;
  secondaryBtnName?: any;
  secondaryBtnColor?: any;
  isSingleBtn?: boolean;
  children?: ReactNode; // Add this prop to accept children components
}

const FullViewDialog: React.FC<FullViewDialogModalProps> = (props: any) => {
  const {
    isVisible,
    handleClose,
    isDeleteBtn,
    title,
    primaryDescription,
    secondaryDescription,
    primaryBtnName,
    secondaryBtnName,
    secondaryBtnColor,
    isSingleBtn,
    children, // Add this prop to accept children components
  } = props;

  const customHeader = (
    <div className="pointer">
      <h4 className="headerText">{title}</h4>
    </div>
  );

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          backgroundColor: 'white',
          width: '50vw',
        }}
        onHide={handleClose}
        header={customHeader}
        className="confirmDialog routeConfirmModal"
      >
        <div style={{ background: 'white' }}>{children}</div>
      </Dialog>
    </div>
  );
};

export default FullViewDialog;
