import React, { useState } from 'react';

import { getFormattedDate, globals } from '../../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import {
  MedicationConcentration,
  ModelMetaData,
  User,
} from '../../../../models';

import ProtocolItem from '../../../../data/model/ProtocolItem';
import { Col, Row } from 'react-bootstrap';
import MedicationItem, {
  getConcentrationString,
} from '../../../../data/model/MedicationItem';
import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import MedicationDoseUI from '../../protocol/edit/Medication/MedicationDoseUI';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
interface FolderComparisonProps {
  focusItem: MedicationItem;
  handleBack: () => void;
  viewText?: string;
}

const InfusionView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  // const { medication, list } = stateData;

  const medication = focusItem;

  const list = [focusItem];

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );

  const department = database.department;

  const [expanded, setExpanded] = useState<string[]>([]);

  const [medications, setMedications] = useState<MedicationItem[]>(list);

  const [parmMedication, setParmMedication] =
    useState<MedicationItem>(medication);

  const [subMedicationItems, setSubMedicationItems] = useState<
    MedicationSubItem[]
  >(medication.subItems);

  const [allSubMedicationItems, setAllSubMedicationItems] = useState<
    MedicationSubItem[]
  >(parmMedication.subItems);

  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    [...new Set(allSubMedicationItems.map((m) => m.parentProtocol))].sort(
      (a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);

        return a.parent.index - b.parent.index;
      }
    )
  );

  function toMetaDataString(metaData: ModelMetaData) {
    return JSON.stringify(metaData, null, 2);
  }

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(dose: MedicationSubItem, index: number) => {
          const lastIndex = data.length === index + 1;

          return (
            <div key={index} style={{ cursor: 'pointer' }}>
              <MedicationDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubMedicationItems.filter(
      (item: MedicationSubItem) => item.parentProtocol.uid === protocol.uid
    );

    // if (globals.debug) console.log('Doses', doses);

    return (
      <div
        style={{
          display: 'flex',

          flexDirection: 'column',

          flex: 1,

          padding: '10px',

          marginTop: '.25rem',

          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(protocol.uid) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',

            width: '100%',

            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          {expanded.includes(protocol.uid) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}

          <div
            style={{
              flex: 1,

              display: 'flex',

              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {protocol.name}
            </span>
          </div>

          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {doses.length} doses
          </span>
        </div>

        {expanded.includes(protocol.uid) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={doses} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5
            className="ketmine-header-text backTextLight hoverText"
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <span>
              <IoArrowBack
                data-testid="isBackBttn"
                size="1.15rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            General Information
          </h5>

          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {focusItem.modifiedBy
                    ? focusItem.modifiedBy.firstName +
                      ' ' +
                      focusItem.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {focusItem.model?.updatedAt && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Last Updated:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(focusItem.model.updatedAt, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
              </div>
              {user.type === UserType.ADMIN && focusItem.rxNormCode != null && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>RxNorm Code:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.rxNormCode}
                    <span>
                      {isCopied && isCopied === focusItem.rxNormCode ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(focusItem.rxNormCode as string, e)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.uid}
                    <span>
                      {isCopied && isCopied === focusItem.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(focusItem.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                focusItem.status === ProgressStatus.DRAFT &&
                focusItem.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.activeID}
                      <span>
                        {isCopied && isCopied === focusItem.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(focusItem.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.name}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Concentration <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideConcentration"
            data-div-id="outsideConcentration"
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${focusItem?.concentrations.length !== 0 ? '' : 'centerContent'}`}
          >
            {focusItem?.concentrations.length !== 0 && (
              <ViewportList items={focusItem?.concentrations}>
                {(item: MedicationConcentration, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{getConcentrationString(item)}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Routes <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${focusItem?.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {focusItem?.routes.length >= 1 && (
              <ViewportList items={focusItem?.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{item}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>
          <label htmlFor="Concentration" className="ketamine-general-label">
            Routes <span className="required-field">*</span>
          </label>
          <div
            data-testid="outsideRoute"
            data-div-id="outsideRoute"
            className={`selectionArea-hover ${
              isEditMode ? 'cursorPointer ' : 'selectionArea-disable'
            } ${focusItem?.routes.length !== 0 ? '' : 'centerContent'}`}
          >
            {focusItem?.routes.length >= 1 && (
              <ViewportList items={focusItem?.routes}>
                {(item: any, index: any) => {
                  return (
                    <div
                      key={index.toLocaleString()}
                      className={`selectedGreybox ${
                        isEditMode && 'cursorPointer'
                      }`}
                    >
                      <div>{item}</div>
                    </div>
                  );
                }}
              </ViewportList>
            )}
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    focusItem.rangeLow && Number(focusItem?.rangeLow) !== 0
                      ? String(focusItem.rangeLow)
                      : ''
                  }
                  // style={{color: Number(focusItem?.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    focusItem?.rangeHigh &&
                    Number(focusItem.rangeHigh) !== globals.MAX_VALUE
                      ? String(focusItem.rangeHigh)
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="Contraindication" className="ketamine-general-label">
            Contraindication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="contraindication"
              data-testid="contraindication"
              name="contraindication"
              value={focusItem?.contraindication}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              data-testid="warning"
              name="warning"
              value={focusItem?.warning}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Indication" className="ketamine-general-label">
            Indication
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="indication"
              data-testid="indication"
              name="indication"
              value={focusItem?.indication}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="DrugInteraction" className="ketamine-general-label">
            Drug Interaction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="interaction"
              data-testid="interaction"
              name="interaction"
              value={focusItem?.interaction}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Onset" className="ketamine-general-label">
            Onset
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="onset"
              data-testid="onset"
              name="onset"
              value={focusItem?.onset}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Duration" className="ketamine-general-label">
            Duration
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="duration"
              data-testid="duration"
              name="duration"
              value={focusItem?.duration}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="notes"
              data-testid="notes"
              name="notes"
              value={focusItem?.note}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Class" className="ketamine-general-label">
            Class
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="class"
              data-testid="class"
              name="class"
              value={focusItem?.medClass}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Action" className="ketamine-general-label">
            Action
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="action"
              data-testid="action"
              name="action"
              value={focusItem?.action}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        {allProtocols.length > 0 && (
          <div className="KetamineGeneral">
            <ViewportList items={allProtocols}>
              {(protocol: ProtocolItem, index: number) => {
                return <ProtocolItemUI key={index} protocol={protocol} />;
              }}
            </ViewportList>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfusionView;
