import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { getObjectDifference } from '../../ui/_global/common/Utils';
import { UserComment } from '../../API';
import { User } from '../../models';

class UserCommentItem extends ModelItem<UserComment> {
  owner: any;
  user: User;
  message: string;
  pairedItems: any[];
  timestamp: Date;
  comment: UserComment;

  constructor(comment: any, owner: any, user: User, pairedItems: any[]) {
    super(comment, comment.id, comment.departmentID);
    this.owner = owner;
    this.user = user;
    this.message = comment.message;
    this.pairedItems = pairedItems;
    this.timestamp = new Date(comment.createdAt);
    this.comment = comment.Comment;
  }
  toString(): string {
    return `UserCommentItem -> {
            uid=${this.uid},
            owner=${this.owner},
            user=${this.user},
            message=${this.message},
            pairedItems=${this.pairedItems},
            timestamp=${this.timestamp},
            comment=${this.comment}
        }`;
  }
}

export default UserCommentItem;
