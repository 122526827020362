import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import { copyCategoriesAndProtocolFromDeptoDep } from '../../../../../data/AmplifyActions';
import {
  ResponseType,
  fetchCategoriesWithProtocols,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import CategoryItem from '../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { globals } from '../../../../_global/common/Utils';

const ProtocolCopy = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;

  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);

  const [info, setInfo] = useState<any>({
    departmentFrom: null,
    departmentTo: null,
    categoryListFrom: [],
    categoryListTo: [],
    protocolListFrom: [],
    protocolListTo: [],
  });

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadData = async (
    department: DepartmentItem,
    isFrom: boolean
  ): Promise<any[] | null> => {
    const response = await fetchCategoriesWithProtocols(department);
    if (response.type === ResponseType.Success) {
      let categories = response.data[0];
      let protocol = response.data[1];
      return [categories, protocol];
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  // useEffect(() => {
  //     if(info.departmentFrom !== null) loadVitalsData(info.departmentFrom, true);
  //     if(info.departmentTo !== null)
  // }
  // , [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/copy`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    if (globals.debug) console.log('Department selected', department);
    let response = await loadData(department, isFrom);
    if (response === null) {
      if (globals.debug)
        console.log('Error: failed to load data for department', department);
      return;
    }
    let category = response[0];
    let protocol = response[1];
    if (isFrom)
      setInfo({
        ...info,
        departmentFrom: department,
        protocolListFrom: protocol,
        categoryListFrom: category,
      });
    else
      setInfo({
        ...info,
        departmentTo: department,
        protocolListTo: protocol,
        categoryListTo: category,
      });
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter(
      (item) =>
        item.id !== info.departmentFrom?.id && item.id !== info.departmentTo?.id
    );
    if (globals.debug) console.log('List', l);
    setList(l);
  }, [info]);

  const handleRouteToMedication = (department: DepartmentItem) => {};

  const handleSubmit = async () => {
    if (info.departmentFrom === null || info.departmentTo === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.protocolListFrom.length === 0) {
      alert(
        'Error: trying to copy an EMPTY list of protocol\nFrom: ' +
          info.departmentFrom.name +
          '\nTo: ' +
          info.departmentTo.name
      );
      return;
    }
    let isConfirmed = window.confirm(
      `Are sure you would like to copy the categories and protocols from ${info.departmentFrom.name} to ${info.departmentTo.name}?`
    );
    if (!isConfirmed) return;
    if (globals.debug) console.log('Medication', info);
    let result = await copyCategoriesAndProtocolFromDeptoDep(
      info.departmentFrom,
      info.departmentTo
    );
    if (result.type === ResponseType.Success) {
      let categories = result.data[0] as number;
      let protocols = result.data[1] as number;
      if (globals.debug)
        console.log(
          'Copied',
          categories,
          'categories and',
          protocols,
          'protocols from',
          info.departmentFrom.name,
          'to',
          info.departmentTo.name
        );
      let isConfirmed = window.confirm(
        `Copied categories ${categories} and ${protocols} protocols from ${info.departmentFrom.name} to ${info.departmentTo.name}, route to ${info.departmentTo.name} Equipment?`
      );
      if (isConfirmed) {
        handleRouteToMedication(info.departmentTo);
      }
    } else {
      if (globals.debug)
        console.log(
          'Copy categories protocol from ' +
            info.departmentFrom.name +
            ' to ' +
            info.departmentTo.name +
            ' failed: ',
          result.data
        );
    }
  };

  const isSaveValid = useMemo(() => {
    return (
      info.departmentFrom !== null &&
      info.departmentTo !== null &&
      info.protocolListFrom.length > 0
    );
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Copy Actions'}
        name={'Copy Protocols'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleSave={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="formContainer">
        <Row>
          <Col sm={6}>
            {' '}
            {/* Left column */}
            <label className="ketamine-general-label">
              Department FROM: <span className="required-field">*</span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              Select the department you want to copy categories and protocols
              FROM.
            </p>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={list}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.id}
              onChange={(option: DepartmentItem) => {
                handleDepartmentSelected(option, true);
              }}
              onClear={() => {
                setInfo({
                  ...info,
                  departmentFrom: null,
                  protocolListFrom: [],
                });
              }}
              placeholder="Search department..."
            />
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Department TO: <span className="required-field">*</span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              Select the department you want to copy categories and protocols
              TO.
            </p>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={list}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.id}
              onChange={(option: DepartmentItem) => {
                handleDepartmentSelected(option, false);
              }}
              onClear={() => {
                setInfo({ ...info, departmentTo: null, protocolListTo: [] });
              }}
              placeholder="Search department..."
            />
          </Col>
          <Col sm={6}>
            {' '}
            {/* Right column */}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              Category FROM Options - {info.categoryListFrom.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.categoryListFrom.map((item: CategoryItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Protocols: {item.protocols?.length}</h6>
                </div>
              </div>
            ))}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              Protocol FROM Options - {info.protocolListFrom.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.protocolListFrom.map((item: ProtocolItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Sum: {item.sum}</h6>
                </div>
              </div>
            ))}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              Category TO Options - {info.categoryListTo.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.categoryListTo.map((item: CategoryItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Protocols: {item.protocols?.length}</h6>
                </div>
              </div>
            ))}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              Protocol TO Options - {info.protocolListTo.length} items:
            </label>
            <hr style={{ margin: 10 }} />
            {info.protocolListTo.map((item: ProtocolItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Sum: {item.sum}</h6>
                </div>
              </div>
            ))}
            {/* {info.protocolListTo.map((item: ProtocolItem, index: number) => (
                        <div 
                            className='string-list-item'
                            key={index.toString()} 
                            onClick={() => if(globals.debug) console.log(item)}
                            style={{marginTop: 6, marginBottom: 6}}
                        >
                            <div>
                                <h6>Name: {item.name}</h6>
                                <h6>Index: {item.index}</h6>
                            </div>
                        </div>
                        ))} */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProtocolCopy;
