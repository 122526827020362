import { DataStore } from 'aws-amplify';
import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import { CPRAssist } from '../../models';
import DepartmentItem from '../model/DepartmentItem';
import {
  getCPRAssist,
  cPRAssistsByDepartmentID,
  listCPRAssists,
} from '../../graphql/queries';
import CPRItem from '../model/CPRItem';
import { ProgressStatus } from '../../API';
import { globals } from '../../ui/_global/common/Utils';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';

export type cprDB = {
  departmentID: string;
  activeID: string | null | undefined;
  version: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
  defaultMode: string;
  epiOrangeSec: number;
  epiRedSec: number;
  compressionRate: number;
  ventRatePerMin: number;
  ventilateSoundDelay: number;
  equipmentID?: string;
};

/**
 * This function will check if the cpr is a draft version and delete it
 * @param cprItem The cpr to check
 * @returns Success if ready to create a new cpr or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: CPRAssist[];
    if (isActive)
      results = await DataStore.query(CPRAssist, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.activeID.eq(id)])
      );
    else
      results = await DataStore.query(CPRAssist, (v) =>
        v.and((v) => [v.status.eq('DRAFT'), v.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCPR = results[0];
    if (dbCPR.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(CPRAssist, dbCPR.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCPR,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const createCPRAssist = async (
  cpr: cprDB | CPRItem,
  previousItem?: CPRItem
): Promise<Response> => {
  try {
    let json: cprDB;
    if (cpr instanceof CPRItem) {
      json = {
        departmentID: cpr.departmentID,
        activeID: cpr.activeID,
        version: cpr.version,
        status: cpr.status,
        modifiedBy: cpr.modifiedBy ? cpr.modifiedBy.id : '',
        createdBy: cpr.model.createdBy ? cpr.model.createdBy : '',
        defaultMode: cpr.defaultMode,
        epiOrangeSec: cpr.epiOrangeSeconds,
        epiRedSec: cpr.epiRedSeconds,
        compressionRate: cpr.compressionRate,
        ventRatePerMin: cpr.ventRatePerMin,
        ventilateSoundDelay: cpr.ventilateSoundDelay,
        equipmentID: cpr.airwayDevice?.uid || '',
      };
    } else {
      json = cpr;
    }
    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let v: CPRAssist;
    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbCPR = await DataStore.query(CPRAssist, previousItem.uid);
      if (dbCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT CPR does not exist could not update',
        };
      }

      v = await DataStore.save(
        CPRAssist.copyOf(dbCPR, (updated) => {
          updated.cprMetaData = {
            defaultMode: json.defaultMode,
            epiOrangeSec: json.epiOrangeSec,
            epiRedSec: json.epiRedSec,
            compressionRate: json.compressionRate,
            ventRatePerMin: json.ventRatePerMin,
            ventilateSoundDelay: json.ventilateSoundDelay,
          };
          updated.equipmentID = json.equipmentID;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      v = await DataStore.save(
        new CPRAssist({
          protocolIDs: [],
          epiIDs: [],
          defibIDs: [],
          equipmentID: json.equipmentID,
          departmentID: json.departmentID,
          activeID: json.activeID,
          version: json.version,
          status: json.status,
          cprMetaData: {
            defaultMode: json.defaultMode,
            epiOrangeSec: json.epiOrangeSec,
            epiRedSec: json.epiRedSec,
            compressionRate: json.compressionRate,
            ventRatePerMin: json.ventRatePerMin,
            ventilateSoundDelay: json.ventilateSoundDelay,
          },
          createdBy: json.createdBy,
        })
      );
    }
    if (globals.debug) console.log('Created CPR:', v);
    let cprItem = new CPRItem(v);
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the cpr to the database
 *    1. Create a new ARCHEIVED cpr based on the current ACTIVE cpr
 *    2. Update the ACTIVE cpr with the new information
 *    3. Delete the DRAFT cpr
 * @param draftCPRItem The cpr to publish
 */

export const publishCPR = async (
  draftCPRItem: CPRItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the cpr is configured correctly as a draft version */
    if (draftCPRItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The cpr is not a draft version',
      };
    }

    let dbCPR = await DataStore.query(CPRAssist, draftCPRItem.uid);
    if (dbCPR == null) {
      return {
        type: ResponseType.Failure,
        data: 'The cpr does not exist',
      };
    }

    let activeCPR: CPRAssist;

    /* Use case 1: Creating the FIRST active version */
    if (draftCPRItem.activeID == null) {
      /* Update the draft cpr to be active */
      activeCPR = await DataStore.save(
        CPRAssist.copyOf(dbCPR, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
      if (globals.debug)
        if (globals.debug)
          console.log('Created the first release of the cpr:', activeCPR);
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active cpr item */
      let id: string = draftCPRItem.activeID;
      let curCPR = await DataStore.query(CPRAssist, id);

      /* Base Case 3 -- check if the active cpr exists */
      if (curCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active cpr does not exist',
        };
      }

      let archive = new CPRItem(curCPR);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curCPR.id;

      // 2. Create a new ARCHEIVED cpr based on the current ACTIVE cpr
      let archiveResult: Response = await createCPRAssist(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as CPRItem;

      // 2. Update the ACTIVE cpr with the new information
      activeCPR = await DataStore.save(
        CPRAssist.copyOf(curCPR, (updated) => {
          updated.cprMetaData = {
            defaultMode: draftCPRItem.defaultMode,
            epiOrangeSec: draftCPRItem.epiOrangeSeconds,
            epiRedSec: draftCPRItem.epiRedSeconds,
            compressionRate: draftCPRItem.compressionRate,
            ventRatePerMin: draftCPRItem.ventRatePerMin,
            ventilateSoundDelay: draftCPRItem.ventilateSoundDelay,
          };
          updated.equipmentID = draftCPRItem.airwayDevice?.uid;
          updated.modifiedBy = draftCPRItem.model.modifiedBy;
          updated.createdBy = draftCPRItem.model.createdBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCPRItem.version;
        })
      );

      // 3. Delete the DRAFT cpr
      let draftCPR = await DataStore.delete(CPRAssist, draftCPRItem.uid);
      if (draftCPR == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft cpr does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeCPR.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeCPR.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let cprItem = new CPRItem(activeCPR);
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteCPR = async (
  cprItem: CPRItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = cprItem.uid;
    if (isSoft) {
      let cpr = await DataStore.query(CPRAssist, id);
      if (cpr == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr does not exist',
        };
      }

      let result = await DataStore.save(
        CPRAssist.copyOf(cpr, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted cpr:', cpr);
    } else {
      let cpr = await DataStore.delete(CPRAssist, id);
      if (cpr == null) {
        return {
          type: ResponseType.Failure,
          data: 'The cpr does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted cpr:', cpr);
    }
    return {
      type: ResponseType.Success,
      data: cprItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCPRDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(CPRAssist, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCPRDrafts = async (db: DatabaseResponse): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates =
      db.cprModel && db.cprModel.status === 'DRAFT' ? [db.cprModel] : [];

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = modelUpdates[i];
      let message = '';
      // if (model.status === 'DELETED') message = `Deleted cpr: ${model.title}`;
      if (model.activeID == null) message = `Created CPR`;
      else message = `Updated CPR`;
      updates.push({
        model: model,
        title: 'CPR ' + model.name,
        message: message,
        changeType: DraftChangeType.CPR,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertCPRChangeToDraft = (dc: DraftChangeItem): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'CPR ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as CPRItem),
      changeType: DraftChangeType.CPR,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: CPRItem): string {
  if (draftItem.activeItem == null) return `Created CPR`;
  return `Updated CPR`;
}

export const removeCurrentCPRDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCPRs = await DataStore.query(CPRAssist, (v) =>
      v.and((v) => [v.status.eq('DRAFT'), v.departmentID.eq(db.department.id)])
    );
    if (globals.debug) console.log('Found draft CPRAssist:', draftCPRs.length);
    for (let i = 0; i < draftCPRs.length; i++) {
      let cpr: CPRAssist = draftCPRs[i];
      if (globals.debug) console.log('Removing CPRAssist:', cpr.id);
      await DataStore.delete(cpr);
      updates.push({
        model: cpr,
        message: `Removed: ${cpr.id}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
export const fetchCPRAssists = async (
  dep: DepartmentItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let allCPR;
    if (useDataStore) {
      allCPR = await DataStore.query(CPRAssist, (c) =>
        c.or((m) => depIDs.map((id) => m.departmentID.eq(id)))
      );
    } else {
      allCPR = await executeQuery(listCPRAssists, {
        departmentID: dep.id,
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            {
              and: [
                { status: { ne: 'ARCHIVE' } },
                { status: { ne: 'DELETED' } },
              ],
            },
          ],
        },
      });
    }
    let cprItems: CPRItem[] = [];
    for (let i = 0; i < allCPR.length; i++) {
      let cpr = new CPRItem(allCPR[i]);
      // if (cpr.status.includes('DRAFT') || cpr.status === 'ACTIVE') {
      /* Take out the active version if there is one */
      mapModelItems(cpr, cprItems, cpr.status, dep);
    }
    cprItems.sort((a, b) => a.version.localeCompare(b.version));
    if (globals.debug) console.log('ALL CPR Items', cprItems);

    if (cprItems.length === 0)
      return {
        type: ResponseType.Success,
        data: undefined,
      };

    return {
      type: ResponseType.Success,
      data: cprItems[0],
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getCPRByID = async (
  db: DatabaseResponse,
  id: string
): Promise<CPRItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbCPR = db.cprModel && db.cprModel.uid === id ? db.cprModel : null;
      if (dbCPR != null) return resolve(dbCPR);
      else {
        executeSingleQuery(getCPRAssist, { id: id }, 1500)
          .then((cpr: CPRAssist | null | undefined) => {
            if (cpr == null) return null;
            let cprItem = new CPRItem(cpr);
            return resolve(cprItem);
          })
          .catch((error: any) => {
            console.error('getProtocolByID', error);
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
