import { useFormik } from 'formik';
import * as _ from 'lodash';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleSetNotification } from '../../../../store/actions';
import {
  createNotificationData,
  createNotificationValues,
  notificationTypes,
} from '../../../_global/constants/Protocol_constants';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import NotificationProtocolSideout from '../../../components/SideOut/notificationSideOut/NotificationProtocolSideout';
import ProtocolHeader from '../../../pages/protocol/ProtocolHeader';
import DragDropPreviewPage from './DragDropPreviewPage';
import QuestionSideout from '../../../components/SideOut/notificationSideOut/QuestionSideout';
import {
  createNotificationItem,
  NotificationJSON,
  uploadAllFilesToS3,
} from '../../../../data/functions/NotificationDB';
import { Group, NotificationType, Question, User } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import Loading from '../../../components/Loading/Loading';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import {
  globals,
  deepCompareObjects,
  toTitleCase,
} from '../../../_global/common/Utils';
import { FormQuestion } from '../../../../models';
import GeneralSelection from '../../../components/Selection/GeneralSelection';
import AddGroupSideout from '../../../components/SideOut/AddGroupSideout';
import AddUserSideout from '../../../components/SideOut/AddUserSideout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { GroupJSON, fetchGroups } from '../../../../data/functions/GroupDB';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa6';
import GroupItem from '../../../../data/model/GroupItem';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const BY_DEPARTMENT = 0;
const BY_GROUP = 1;
const BY_USER = 2;

/* 11-29-23 Arul: Created the  common component for notification create page*/
const NotificationCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const { sendFormQuestions, previousFormQuestions, saveFormikValues } =
    location.state || {}; // Access the passed state

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const [department, setDepartment] = useState<DepartmentItem>(
    database.department
  );
  const user: User = useSelector((state: any) => state.user);

  const [type, setNotificationType] = useState('GENERAL');
  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isUserModal, setIsUserModal] = useState(false);

  const [selectedData, setSelectedData] = useState<any>('');

  const [isDelete, setIsDelete] = useState(false);
  const [isModalVisisble, setIsModalVisible] = useState(false);
  const [protocolData, setProtocolData] = useState([]);
  const [isDeleteProtocol, setIsDeleteProtocol] = useState(false);
  const [deleteProtocolIndex, setDeleteProtocolIndex] = useState<any>();
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isNoSubDepsWarning, setIsNoSubDepsWarning] = useState(false);
  const [isGroupModalConfirm, setIsGroupModalConfirm] = useState(false);
  const [isDataChange, setisDataChange] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    'Creating Notification...'
  );
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [inputType, setInputType] = useState(BY_DEPARTMENT);
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [autogenGroup, setAutogenGroup] = useState<GroupItem>();
  const [usersCount, setUsersCount] = useState(0);
  const [allGroups, setAllGroups] = useState<GroupItem[]>([]);
  const [expirationDays, setExpirationDays] = useState<number>(30);
  const [urlCount, setUrlCount] = useState(0);

  //initialize the formik values
  const formik = useFormik({
    initialValues: {
      title: '',
      message: '',
      notificationStyle: 'urgent',
      type: NotificationType.GENERAL,
      pairedProtocol: '',
      questions: [] as Question[],
      formQuestions: [] as FormQuestion[],
      attachments: [],
      deadline: null as Date | null,
      pairedDeps: [] as DepartmentItem[],
      Groups: [] as GroupItem[],
      expirationTimestamp: null as Date | null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Must have a title'),
      message: Yup.string(),
      type: Yup.string().required('Must have a type'),
      notificationStyle: Yup.string(),
      pairedProtocol: Yup.string(),
      question: Yup.array(),
      formQuestions: Yup.array(),
      attachments: Yup.array(),
      deadline: Yup.date().nullable(),
      pairedDeps: Yup.array(),
    }),
    onSubmit: async (values) => {
      if (
        department.subDeps &&
        department.subDeps.length > 0 &&
        inputType === BY_DEPARTMENT
      ) {
        if (formik.values.pairedDeps.length === 0 && !isNoSubDepsWarning) {
          setIsNoSubDepsWarning(true);
          return;
        }
      }

      // if (
      //   (inputType === BY_GROUP || inputType === BY_USER) &&
      //   !isGroupModalConfirm
      // ) {
      //   setIsGroupModalConfirm(true);
      //   return;
      // }
      if (formik.isValid) {
        setIsLoading(true);
        let taggedGroups =
          inputType === BY_GROUP
            ? groups
            : inputType === BY_USER
              ? [autogenGroup as GroupItem]
              : [];

        /* First uplad all the files to S3 and get the URLs */
        let results: Response;
        let fileURLs: string[] = [];
        if (values.attachments.length > 0) {
          results = await uploadAllFilesToS3(
            department,
            values.attachments,
            setLoadingMessage
          );
          if (results.type === ResponseType.Success) {
            fileURLs = results.data;
            if (fileURLs.length !== values.attachments.length) {
              if (globals.debug)
                console.log(
                  'Error uploading files to S3',
                  fileURLs.length,
                  fileURLs
                );
              setIsLoading(false);
              alert('Error uploading files please try again');
              return;
            }
            if (globals.debug)
              console.log('Uploaded files to S3', fileURLs.length, fileURLs);
          } else if (globals.debug)
            console.log('Error uploading files to S3', results.data);
        }

        let deps = isNoSubDepsWarning ? departmentList : values.pairedDeps;
        if (
          inputType === BY_DEPARTMENT &&
          (!department.allSubDeps || department.allSubDeps.length === 0)
        ) {
          deps = [department];
        }

        /* Format the formik into the JSON to create the notification */
        let json: NotificationJSON = {
          title: values.title,
          message: values.message,
          timestamp: new Date(),
          expirationTimestamp: values.expirationTimestamp
            ? values.expirationTimestamp
            : undefined,
          deadline: values.deadline ? values.deadline : undefined,
          type: values.type as NotificationType,
          isReadIDs: [],
          isAckIDs: [],
          departmentID: department.id,
          fileURLs: fileURLs,
          taggedProtocols: [],
          questions: values.questions,
          formQuestions: values.formQuestions,
          isPush: values.notificationStyle === 'urgent' ? true : false,
          pairedDeps: deps,
          Groups: taggedGroups,
        };
        console.log('Formik values', json);

        /* Create the notification */
        results = await createNotificationItem(json, user);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Notification created successfully', results.data);
          navigate('/notification');
        } else if (globals.debug)
          console.log('Notification creation failed', results.data);

        setIsLoading(false);
      }
    },
  });

  const handleFormQuestions = (
    newQuestion: FormQuestion[],
    previousQuestions: FormQuestion[],
    saveFormikValues: any
  ) => {
    const questions: FormQuestion[] = [];

    // Iterate over the new questions
    newQuestion.forEach((newQ, newIndex) => {
      // Find the index of the current new question in the previous questions array
      const index = previousQuestions.findIndex(
        (item: FormQuestion) => item.index === newQ.index
      );

      // If the question exists in the previous array and order has changed, update the order
      if (index !== -1 && index !== newIndex) {
        questions.splice(newIndex, 0, newQ);
      } else {
        // Otherwise, just update the question or add it to the new array
        questions.push(newQ);
      }
    });

    console.log('saveFormik', saveFormikValues);
    formik.setValues({
      ...saveFormikValues,
      formQuestions: questions,
      type: NotificationType.TRAINING,
    });
  };

  useEffect(() => {
    if (saveFormikValues) {
      console.log('saveFormik', saveFormikValues);
      formik.setValues({
        ...saveFormikValues,
      });
    }

    if (sendFormQuestions && previousFormQuestions) {
      setNotificationType('TRAINING');
      handleFormQuestions(
        sendFormQuestions,
        previousFormQuestions,
        saveFormikValues
      );
    }
  }, [sendFormQuestions, previousFormQuestions]);

  useEffect(() => {
    const fetchGroupsFromAPI = async () => {
      try {
        let dbGroups = await fetchGroups(department);
        if (dbGroups.type === ResponseType.Success) {
          let nonAutoGenGroups = dbGroups.data.filter(
            (group: GroupItem) => !group.isAutoGen
          );
          setAllGroups(nonAutoGenGroups);
        } else {
          console.log('Error getting all groups', dbGroups.data);
        }
      } catch (error) {
        console.log('Error fetching groups', error);
      }
    };

    fetchGroupsFromAPI();
  }, [inputType]);

  useEffect(() => {
    if (formik.values.Groups.length > 0) {
      console.log('formik.values.Groups updated', formik.values.Groups);
      formik.submitForm();
    }
  }, [formik.values.Groups]);

  useEffect(() => {
    if (department.allSubDeps) {
      let l = [...department.allSubDeps, department];
      l = l.filter((dep) => {
        return !formik.values.pairedDeps.some(
          (d: DepartmentItem) => d.id === dep.id
        );
      });
      setDepartmentList(l);
    }
  }, [department, formik.values.pairedDeps]);
  /* 11-29-23 Arul: function for return protocol main page*/
  const handleBack = () => {
    if (isDataChange && !isWarningModal) {
      setIsWarningModal(true);
    } else {
      navigate('/notification');
    }
  };

  /* 11-29-23 Arul: function for question and answer submit*/
  const handleSubmitQuestion = useCallback(
    (newQuestion: Question, prevQuestion?: Question) => {
      const questions: Question[] = [...formik.values.questions];
      if (prevQuestion) {
        const index = questions.findIndex(
          (item: Question) => item === prevQuestion
        );
        questions[index] = newQuestion;
      } else questions.push(newQuestion);

      formik.setValues({
        ...formik.values,
        questions: questions,
      });
      setIsQuestionModal(false);
      setSelectedData(null);
    },
    [formik.values]
  );

  /* 11-28-23 Arul: function for delete question and answer*/
  const handleDeleteQuestionAnswer = useCallback(() => {
    const data: Question[] = [...formik.values.questions];
    data.filter((item: Question) => item !== selectedData);
    formik.setValues({
      ...formik.values,
      questions: data,
    });
    setIsDelete(false);
    setIsQuestionModal(false);
    setSelectedData(null);
  }, [formik.values, selectedData, setSelectedData]);

  const handleCreate = async () => {};

  const handleAddPairedProtocol = (data: any) => {
    setIsModalVisible(false);
    setProtocolData(data);
  };

  const handleDeletePairedProtocol = useCallback(() => {
    const data: any = [...protocolData];
    data.splice(deleteProtocolIndex, 1);
    setProtocolData(data);
    setIsDeleteProtocol(false);
    setDeleteProtocolIndex(null);
  }, [protocolData, deleteProtocolIndex, setProtocolData]);

  //open notification sideout
  const handleprotocolEdit = (event: any) => {
    const divId = event.target.getAttribute('data-div-id');
    if (divId === 'outsideClick') {
      setIsModalVisible(true);
    }
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...formik.values.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    formik.setFieldValue('pairedDeps', l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = formik.values.pairedDeps.filter(
      (d: DepartmentItem) => d.id !== option.id
    );
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    formik.setFieldValue('pairedDeps', []);
  };
  const handleSetGroup = (data: GroupItem) => {
    setGroups((prevGroups) => [...prevGroups, data]);
  };

  const handleAddGroup = (data: GroupItem) => {
    handleSetGroup(data);
    setAllGroups((prevGroups) =>
      prevGroups.filter((group) => group.uid !== data.uid)
    );
  };

  const handleAddUser = (data: GroupItem) => {
    let users = data.pairedUserIDs;
    setAutogenGroup(data);
    setUsersCount(users.length);
  };

  const handleRemoveGroup = (option: GroupItem, e: any) => {
    e.stopPropagation();
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group.uid !== option.uid)
    );
    setAllGroups((prevGroups) => [...prevGroups, option]);
  };

  const handleInputType = (index: number) => {
    setInputType(index);
    if (index === BY_DEPARTMENT) {
      setGroups([]);
      setAutogenGroup(undefined);
      setUsersCount(0);
    } else if (index === BY_GROUP) {
      setAutogenGroup(undefined);
      setUsersCount(0);
      formik.setFieldValue('pairedDeps', []);
    } else if (index === BY_USER) {
      setGroups([]);
      formik.setFieldValue('pairedDeps', []);
    }
  };
  const handleGroupModalConfirm = () => {};

  useEffect(() => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const urls = formik.values.message.match(urlPattern);
    setUrlCount(urls ? urls.length : 0);
  }, [formik.values.message]);

  return (
    <div className="screen-container">
      {/* Add the CalculationBasisDialog component */}
      <QuestionSideout
        dialogVisible={isQuestionModal}
        onClose={() => {
          setIsQuestionModal(false);
          setSelectedData(null);
        }}
        headerText="Notification Questions"
        handleSet={handleSubmitQuestion}
        question={selectedData}
        handleDeleteQuestion={() => {
          setIsDelete(true);
        }}
      />

      {isGroupModal && (
        <AddGroupSideout
          isVisible={isGroupModal}
          handleClose={() => {
            setIsGroupModal(false);
          }}
          isEditMode={false}
          handleAdd={handleSetGroup}
        />
      )}

      {isUserModal && (
        <AddUserSideout
          isVisible={isUserModal}
          handleClose={() => {
            setIsUserModal(false);
          }}
          isEditMode={false}
          handleAdd={handleAddUser}
          previousGroup={autogenGroup}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Remove question?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteQuestionAnswer}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want remove this question from the notification?'
          }
        />
      )}
      {isModalVisisble && (
        <NotificationProtocolSideout
          isVisible={isModalVisisble}
          handleClose={() => {
            setIsModalVisible(false);
          }}
          data={protocolData}
          handleAdd={handleAddPairedProtocol}
        />
      )}
      {isDeleteProtocol && (
        <ConfirmModal
          isVisible={isDeleteProtocol}
          title="Remove Paired Protocol?"
          handleClose={() => {
            setIsDeleteProtocol(false);
          }}
          handleSubmit={handleDeletePairedProtocol}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want remove this paired protocol?"
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Notification New page. Click cancel to return Notification New page.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {isNoSubDepsWarning && inputType === BY_DEPARTMENT && (
        <ConfirmModal
          isVisible={isNoSubDepsWarning}
          title="Warning: No sub-departments selected."
          handleClose={() => {
            setIsNoSubDepsWarning(false);
          }}
          handleSubmit={() => {
            formik.submitForm();
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Create"
          primaryDescription={`Creating a notification without paired departments will send the notification to all users.  Are you sure you want to continue?`}
        />
      )}
      {isGroupModalConfirm && (
        <ConfirmModal
          isVisible={isGroupModalConfirm}
          title={
            inputType === BY_GROUP
              ? 'Warning: This will send the notification to all the selected groups.'
              : 'Warning: This will send the notification to all the selected users.'
          }
          handleClose={() => {
            setIsGroupModalConfirm(false);
          }}
          handleSubmit={() => {
            handleGroupModalConfirm();
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Create"
          primaryDescription={
            inputType === BY_GROUP
              ? `Creating a notification with Groups will send the notification to all selected groups.  Are you sure you want to continue?`
              : `Creating a notification with Users will send the notification to all selected users.  Are you sure you want to continue?`
          }
        />
      )}

      <ProtocolHeader
        name={'Create Notification'}
        page="Notifications"
        isBackButton={true}
        type={'protocol'}
        isSendButton={true}
        isSendActive={formik.isValid && formik.dirty}
        isDotButton={true}
        handleCancel={handleBack}
        handleCreate={() => formik.submitForm()}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Notification</h5>
          <div className="infolist">
            <div className="display_flex">
              <span
                className="ketamine-general-label"
                style={{
                  marginLeft: '-12px',
                  fontSize: '15px',
                  marginRight: '-13px',
                }}
              >
                Notification Style:
              </span>
              <div className="tab-container">
                <div
                  className={`tab ${
                    formik.values.notificationStyle === 'normal'
                      ? 'tab-active-red'
                      : ''
                  }`}
                  onClick={() =>
                    formik.setFieldValue('notificationStyle', 'normal')
                  }
                >
                  <div style={{ marginTop: '4px' }}>Silent</div>
                </div>
                <div
                  className={`tab ${
                    formik.values.notificationStyle === 'urgent'
                      ? 'tab-active-green'
                      : ''
                  }`}
                  onClick={() =>
                    formik.setFieldValue('notificationStyle', 'urgent')
                  }
                >
                  <div style={{ marginTop: '4px' }}>Push</div>
                </div>
              </div>
            </div>
          </div>
          {formik.values.notificationStyle === 'normal' ? (
            <span className={`notification-css`}>
              Silent: Delivered in-app, displaying a badge on the Notification
              tab when opened.
            </span>
          ) : (
            <span className={`notification-css`}>
              Push: Pushes the notification to all devices so they will
              IMMEDIATELY receive the notification
            </span>
          )}
          <label htmlFor="title" className={`notification-css-title`}>
            Title<span className="required-field ">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general cursorPointer "
              id="title"
              name="title"
              required={true}
              value={formik.values.title}
              onChange={formik.handleChange}
            />
            <div className="input-border"></div>
          </div>
          <div style={{ width: '100%' }}>
            <label htmlFor="type" className={`notification-css-title`}>
              Notification Type <span className="required-field ">*</span>
            </label>
            <div className="input-container-we cursorPointer">
              <Dropdown
                id="type"
                name="type"
                data-testid="type"
                value={type}
                options={notificationTypes}
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    type: e.value,
                  });
                  setNotificationType(e.value);
                }}
                style={{ width: '100%', borderRadius: '10px' }}
                className="dropDownIconWidth input-container-we"
              />
            </div>
          </div>
          {type === 'TRAINING' && (
            <div style={{ width: '100%' }}>
              <label htmlFor="type" className={`notification-css-title`}>
                Training Deadline
              </label>
              <div className="input-container-we cursorPointer">
                <Calendar
                  value={formik.values.deadline}
                  className="calenderWidthStyle"
                  data-testid="training_calender"
                  onChange={(e) => {
                    formik.setFieldValue('deadline', e.value);
                  }}
                  showIcon
                  style={{ width: '100%', display: 'inline-flex' }}
                />
                {formik.values.deadline && (
                  <span className="date-close-icon">
                    <AiOutlineCloseCircle
                      data-testid="delete_date"
                      onClick={() => {
                        formik.setFieldValue('deadline', null);
                      }}
                      className="cursorPointer pairedProtocol-cancel-icon"
                    />
                  </span>
                )}
              </div>
            </div>
          )}
          {/* <div>
            <label htmlFor="question" className={`notification-css-title`}>
              Paired Protocol
            </label>
            <div data-div-id="outsideClick" data-testid="pairedProtocolData" onClick={handleprotocolEdit} style={{ paddingBlock: '1px' }} className={`protocol-hover flex_wrap cursorPointer ${protocolData.length ? '' : 'centerContent'}`}>
              {protocolData.length >= 1 &&
                <ViewportList
                  items={protocolData}
                >
                  {(item: any, index: any) => {
                    return (
                      <div key={index} className={`selectedGreybox cursorPointer`} style={{ margin: '4px 2px' }}>
                        <div>{item.name}</div>
                        <span className="close_icon_color"><AiOutlineCloseCircle   data-testid={`delete_protocol-${index}`}  className={`icon-normal cursorPointer`} onClick={() => { setIsDeleteProtocol(true); setDeleteProtocolIndex(index) }} /></span>
                      </div>
                    )
                  }}
                </ViewportList>
              }
              {protocolData.length >= 1 && <HiPencil  data-testid="edit_protocol" className={`icon-black rightIcon cursorPointer`} onClick={() => { setIsModalVisible(true); }} />}
              {!(protocolData.length >= 1) &&
                <div onClick={() => { setIsModalVisible(true); }}>
                  <span data-testid="add_protocol" className="cursorPointer formAnchorText">+Add</span>
                </div>
              }
            </div>
          </div> */}
          {type === 'TRAINING' && (
            <div>
              <label
                data-testid="question"
                htmlFor="question"
                className={`notification-css-title`}
              >
                Question
              </label>
              <div className="contentBorder cursorPointer">
                {formik.values.formQuestions.length ? (
                  // <ViewportList items={formik.values.formQuestions}>
                  //   {(item: FormQuestion, index: any) => (
                  //     <div
                  //       key={index}
                  //       data-testid={`edit-question-${index}`}
                  //       style={
                  //         formik.values.formQuestions.length !== index + 1
                  //           ? { borderBottom: '1px solid grey' }
                  //           : {}
                  //       }
                  //     >
                  //       <div
                  //         onClick={() => {
                  //           // setSelectedData(item);
                  //           // setIsQuestionModal(true);
                  //           navigate('/create/notification/question', {
                  //             state: {
                  //               previousFormQuestions:
                  //                 formik.values.formQuestions,
                  //               questionIndex: index,
                  //             },
                  //           });
                  //         }}
                  //         style={{
                  //           display: 'flex',
                  //           justifyContent: 'space-between',
                  //           alignItems: 'center',
                  //           padding: '0px 5px',
                  //           fontSize: '15px',
                  //         }}
                  //       >
                  //         <div className="contentHeading">{item.question}</div>
                  //         <div style={{ display: 'flex' }}>
                  //           <div className="contentHeading">
                  //             {toTitleCase(item.type?.replace('_', ' '))}
                  //           </div>
                  //           <div className="contentHeading">
                  //             {item.answers?.length} answers
                  //           </div>
                  //           <div
                  //             style={{
                  //               display: 'flex',
                  //               justifyContent: 'center',
                  //               alignItems: 'center',
                  //               marginLeft: '5px',
                  //             }}
                  //           >
                  //             {' '}
                  //             <IoChevronForward
                  //               size="1.5rem"
                  //               style={{ marginRight: '10px' }}
                  //             />
                  //           </div>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   )}
                  // </ViewportList>
                  <div
                    className="contentHeading greyText"
                    style={{ padding: '8px', marginLeft: '10px' }}
                    onClick={() => {
                      // setSelectedData(null);
                      // setIsQuestionModal(true);
                      navigate('/create/notification/question', {
                        state: {
                          previousFormQuestions: formik.values.formQuestions,
                          saveFormikValues: formik.values,
                        },
                      });
                    }}
                  >
                    Quiz Created : {formik.values.formQuestions.length}{' '}
                    Questions Added
                  </div>
                ) : (
                  <div
                    className="contentHeading greyText"
                    style={{ padding: '2px', marginLeft: '10px' }}
                    onClick={() => {
                      // setSelectedData(null);
                      // setIsQuestionModal(true);
                      navigate('/create/notification/question', {
                        state: {
                          previousFormQuestions: formik.values.formQuestions,
                          saveFormikValues: formik.values,
                        },
                      });
                    }}
                  >
                    None added
                  </div>
                )}
              </div>
              <div
                className={`protocolCalculationPad contentHeading newProtocolBorder`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  // setSelectedData(null);
                  navigate('/create/notification/question', {
                    state: {
                      previousFormQuestions: formik.values.formQuestions,
                      saveFormikValues: formik.values,
                    },
                  });
                  // setIsQuestionModal(true);
                }}
                data-testid="add_question"
              >
                <span>
                  <HiPlus
                    data-testid="add_question_plus"
                    className="text-icon cursorPointer"
                  />
                  {formik.values.formQuestions.length === 0
                    ? ' Create Quiz'
                    : ' Add Another Question'}
                </span>
              </div>
            </div>
          )}
          <label
            htmlFor="type"
            className={`notification-css-title`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Message
            {urlCount > 0 && (
              <span style={{ fontSize: '12px', color: '#636363' }}>
                {urlCount} URL(s) Added
              </span>
            )}
          </label>
          <InputTextarea
            className="form-control-general"
            id="message"
            name="message"
            required={true}
            data-testid="message"
            value={formik.values.message}
            onChange={formik.handleChange}
            style={{ height: '110px', verticalAlign: 'top' }}
          />

          <div style={{ margin: '20px 0px' }}>
            <GeneralSelection
              items={['By Department', 'By Group', 'By User']}
              selectedIndex={inputType}
              onPress={(item, index) => {
                handleInputType(index);
              }}
              labelField={(option) => option}
              valueField={(option) => option}
            />
          </div>

          {inputType === BY_DEPARTMENT && (
            <>
              {' '}
              {department.allSubDeps && department.allSubDeps.length > 0 ? (
                <>
                  <label htmlFor="" className={`notification-css-title`}>
                    <span
                      className="headerTextMargin"
                      style={{ fontSize: '16px', marginTop: '10px' }}
                    >
                      Subscribed Departments: {formik.values.pairedDeps.length}{' '}
                      / {department.allSubDeps.length + 1}
                      <span
                        onClick={() =>
                          formik.setFieldValue('pairedDeps', [
                            ...(department.allSubDeps as DepartmentItem[]),
                            department,
                          ])
                        }
                      >
                        <div className="clickableText">Add All</div>
                      </span>
                    </span>
                  </label>
                  <span
                    className="contentText greyText"
                    style={{ fontSize: '13px', marginLeft: '10px' }}
                  >
                    This is a list of every department that the user subscribes
                    to.
                  </span>
                  <SearchableDropdown<DepartmentItem>
                    id="searchDropdown"
                    options={departmentList}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    keyField={(option) => option.id}
                    multiSelect={true}
                    onChange={(option: DepartmentItem) =>
                      handleAddDepartment(option)
                    }
                    onClear={handleClearDepartments}
                    placeholder="Search department..."
                  />
                  {formik.values.pairedDeps.length === 0 && (
                    <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                      No paired departments...
                    </h6>
                  )}
                  <div
                    style={{
                      overflowY: 'auto',
                      maxHeight: '200px',
                      border:
                        formik.values.pairedDeps.length === 0
                          ? '0px'
                          : '1px solid #ccc',
                      borderRadius: '5px',
                      marginBottom: '20px',
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ViewportList items={formik.values.pairedDeps}>
                      {(item: DepartmentItem, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '16fr 1fr',
                            padding: '6px 10px',
                            alignItems: 'center',
                            borderBottom:
                              index === formik.values.pairedDeps.length - 1
                                ? ''
                                : '1px solid #ccc',
                            // borderTopLeftRadius: '6px',
                            // borderTopRightRadius: '6px',
                            // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                            // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                          }}
                          className="listItem"
                        >
                          <div className="contentText">{item.name}</div>
                          <FaTimes
                            className="icon-cancel"
                            size={16}
                            onClick={(e) => handleRemoveDepartment(item, e)}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </>
              ) : (
                <>
                  <label
                    htmlFor=""
                    className={`notification-css-title`}
                    style={{
                      fontSize: '16px',
                      marginTop: '10px',
                      fontWeight: '500',
                    }}
                  >
                    Sending to all users in {department.name}.
                  </label>
                  <p className="sidebarText" style={{ marginLeft: '12px' }}>
                    This will send the notification to the entire{' '}
                    {department.name} Agency. There are currently{' '}
                    {database.users.length} users in the agency.
                  </p>
                </>
              )}
            </>
          )}
          {inputType === BY_GROUP && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Groups: {groups.length} /{' '}
                  {allGroups.length + groups.length}
                  <span
                    onClick={() =>
                      allGroups.map((group) => handleAddGroup(group))
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every group that the user created
              </span>
              <SearchableDropdown<GroupItem>
                id="searchDropdown"
                keyField={(option) => option.uid}
                options={allGroups}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                multiSelect={true}
                onChange={(option: GroupItem) => handleAddGroup(option)}
                placeholder="Search Groups..."
              />

              {groups.length > 0 ? (
                <>
                  <div
                    style={{
                      overflowY: 'auto',
                      maxHeight: '200px',
                      border:
                        formik.values.pairedDeps.length === 0
                          ? '0px'
                          : '1px solid #ccc',
                      borderRadius: '5px',
                      marginBottom: '20px',
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ViewportList items={groups}>
                      {(item: GroupItem, index) => (
                        <div
                          key={item.uid}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '16fr 1fr',
                            padding: '6px 10px',
                            alignItems: 'center',
                            border: '1px solid #ccc',
                            borderRadius: '6px',
                          }}
                          className="listItem"
                        >
                          <div className="contentText">
                            {item.name}: {item.pairedUserIDs.length} Users added
                          </div>
                          <FaTimes
                            className="icon-cancel"
                            size={16}
                            onClick={(e) => handleRemoveGroup(item, e)}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </>
              ) : (
                <>
                  <h6
                    style={{
                      textAlign: 'center',
                      marginTop: '10px',
                      paddingTop: '20px',
                    }}
                  >
                    {allGroups && allGroups.length > 0
                      ? 'Group(s) Created, Select the group from the dropdown to send the notification'
                      : 'No Groups Created...'}
                  </h6>
                </>
              )}
              <div
                className={`protocolCalculationPad contentHeading newProtocolBorder`}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  flex: '1',
                }}
                onClick={() => {
                  setIsGroupModal(true);
                }}
                data-testid="create_group"
              >
                <span className="clickableText">
                  <HiPlus
                    data-testid="add_question_plus"
                    className="text-icon cursorPointer"
                  />
                  {' ' + (groups.length === 0 ? 'Create Group' : 'Add Group')}
                </span>
              </div>
            </>
          )}
          {inputType === BY_USER && (
            <div>
              {' '}
              {usersCount > 0 ? (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '16fr 1fr',
                    padding: '6px 10px',
                    alignItems: 'center',
                    border: '1px solid #ccc',
                    borderRadius: '6px',
                    marginBottom: '20px',
                  }}
                  className="listhover cursorPointer"
                  onClick={() => setIsUserModal(true)}
                >
                  <div className="contentText">{usersCount} User(s) Added</div>

                  <FaChevronRight
                    className="icon-normal"
                    size={16}
                    color={'#a0a0a0'}
                  />
                </div>
              ) : (
                <h6
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    paddingTop: '20px',
                  }}
                >
                  No Users Added...
                </h6>
              )}
              <>
                <div
                  className={`protocolCalculationPad contentHeading newProtocolBorder`}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    flex: '1',
                  }}
                  onClick={() => {
                    setIsUserModal(true);
                  }}
                  data-testid="add_users"
                >
                  <span className="clickableText">
                    <HiPlus
                      data-testid="add_question_plus"
                      className="text-icon cursorPointer"
                    />
                    {' ' + 'Add Users'}
                  </span>
                </div>
              </>
            </div>
          )}
          <Accordion
            style={{
              borderRadius: '5px',
              boxShadow: 'none',
              alignContent: 'center',
              justifyContent: 'center',

              fontSize: '16px',
              marginTop: '20px',
              backgroundColor: 'transparent',
              border: '1px solid #ccc',
            }}
            onChange={(e, expanded) => {
              // if (!expanded && formik.values.owner == null)
              //   setTimeout(() => {
              //     formik.setFieldValue('owner', department);
              //   }, 100);
            }}
          >
            <AccordionSummary
              expandIcon={<FaChevronDown />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Advanced Settings
            </AccordionSummary>
            <AccordionDetails>
              <label htmlFor="" className={`notification-css-title`}>
                Notification Expiration
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '6px' }}
              >
                Enter in a value between 1 and 365 days. Leave blank for default
                30 days.
              </span>
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'space-between',
                  alignItems: 'center',
                  // marginTop: '10px',
                  marginBottom: '10px',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <label
                    htmlFor=""
                    className={`notification-css-title`}
                    style={{
                      color: '#616161',
                    }}
                  >
                    Enter By Days (1-365):
                  </label>
                  <InputText
                    type="text"
                    className="form-control-general cursorPointer"
                    id="title"
                    name="title"
                    required={true}
                    value={expirationDays + ''}
                    onChange={(e) => {
                      setExpirationDays(Number(e.target.value));
                      //Calculate the date
                      let date = new Date();
                      date.setDate(date.getDate() + Number(e.target.value));
                      formik.setFieldValue('expirationTimestamp', date);
                    }}
                    placeholder="Enter By Days..."
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  <label
                    htmlFor=""
                    className={`notification-css-title`}
                    style={{
                      color: '#616161',
                    }}
                  >
                    Enter By Date
                  </label>
                  <Calendar
                    value={formik.values.expirationTimestamp}
                    className="calenderWidthStyle"
                    data-testid="training_calender"
                    onChange={(e) => {
                      if (e.value) {
                        formik.setFieldValue('expirationTimestamp', e.value);
                        //Calculate the days from today include the months and years
                        let date = new Date();
                        let diff = e.value.getTime() - date.getTime();
                        let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
                        setExpirationDays(days);
                      }
                    }}
                    showIcon
                    placeholder="Enter By Date..."
                  />
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Attachments</h5>
          <div className="infolist" style={{ display: 'block' }}>
            <span className="contentText" style={{ fontSize: '18px' }}>
              Add Attachment
            </span>
            <DragDropPreviewPage
              handleChangefiles={(e: any) => {
                formik.setFieldValue('attachments', e);
              }}
              existingFiles={formik.values.attachments}
            />
          </div>
        </div>
      </div>
      {isLoading && <Loading type="bubbles" message={loadingMessage} />}
    </div>
  );
};

export default NotificationCreatePage;
