import React, { useEffect, useState } from 'react';
import './css/Login.scss'; // Importing CSS
import image from '../../../assets/images/hinckleymedical_logo_symbol.png'; // Importing image
import { InputText } from 'primereact/inputtext';
import { CgProfile } from 'react-icons/cg';
import { IoPersonSharp } from 'react-icons/io5';
import { RiLockPasswordFill } from 'react-icons/ri';
import { Auth, DataStore } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { handleLogOut, handleUpdateCognitoUser } from '../../../store/actions';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { set } from 'lodash';
import { globals } from '../../_global/common/Utils';

interface LoginScreenProps {}

const NORMAL_LOGIN = 0;
const PASSWORD_CHANGE = 1;
const PASSWORD_RESET = 2;
const CONFIRM_CODE_PASSWORD = 3;
const NEW_USER = 4;
const CREATE_NEW_USER = 5;
const CONFIRM_NEW_USER = 6;

const LandingPage: React.FC<LoginScreenProps> = () => {
  const [status, setStatus] = useState(NORMAL_LOGIN);
  const [cognitoUser, setCognitoUser] = useState<any>();
  const [state, setState] = useState<any>({
    username: '',
    password: '',
    confirmPassword: '',
    confirmCode: '',
    error: '',
  });
  const [isLoading, setIsLoading] = useState<string>('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState({
    title: '',
    message: '',
  });

  useEffect(() => {
    document.title = `Login | OneDose`;
    /* Clear anything in local storage and redux store */
    dispatch<any>(handleLogOut());
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (status === NORMAL_LOGIN) handleLogin();
    else if (status === PASSWORD_CHANGE) handlePasswordChange();
  };

  const handleLogin = async () => {
    if (state.username && state.password) {
      setIsLoading('Logging in...');
      try {
        const { username, password } = state;
        const user = await Auth.signIn(username, password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(user);
          setModalState({
            title: 'Password Change Required',
            message:
              'Please enter a new password. Your password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
          });
          setState({
            ...state,
            password: '',
            error: 'Please enter a new password.',
          });
          setStatus(PASSWORD_CHANGE);
        } else if (user.challengeName === undefined) {
          let userinfo = await Auth.currentAuthenticatedUser();
          // await DataStore.start();
          dispatch<any>(
            handleUpdateCognitoUser({
              user: userinfo,
              waitForSync: true,
            })
          );
          navigate(`/protocol`);
        }
      } catch (err: any) {
        if (globals.debug) console.log(err, '\n', err.message, '\n', err.code);
        let msg: string = err.message;
        console.log('MESSAGE', msg);
        if (err.code === 'UserNotFoundException') {
          setState({
            ...state,
            error: 'User not found. Username or password is incorrect.',
          });
        } else if (msg.includes('DataStoreStateError')) {
          setState({
            ...state,
            error:
              'Could not sync to the cloud. Please make sure this is the only tab open. This error can happen from having multiple tabs open and then try to login. Please close all tabs and try again.',
          });
        } else setState({ ...state, error: err.message });
      }
      setIsLoading('');
    }
  };

  /**
   * Handle a force change password workflow
   */
  const handlePasswordChange = async () => {
    if (validateNewPasswordForm() && cognitoUser != null) {
      try {
        setIsLoading('Setting new password...');
        const results = await Auth.completeNewPassword(
          cognitoUser,
          state.password
        );
        handleLogin();
      } catch (error) {
        setIsLoading('');
        if (globals.debug) console.log('Error signing in', error);
        setModalState({
          title: 'Error signing in',
          message: 'An unexpected error occurred. Please try again later.',
        });
      }
    }
  };
  /**
   * Check if the new password is valid with these requirements:
   * 1. Password is > 6 characters long
   * 2. Password contains a number
   * 3. Password contains a special character
   * @returns true if the new password is valid, false otherwise
   */
  function validateNewPasswordForm() {
    if (state.username.length == 0) {
      setState({ ...state, error: 'Username is required' });
      return false;
    }

    /* 1-5-23 Hazlett: Tested with my email as the username and it worked fine. - NOT sure what happended earlier with the people and syncing */
    // //Check if the username has a space or period
    // if(username.includes(' ') || username.includes('.')){
    //   setError("Username cannot contain spaces or periods")
    //   return false;
    // }

    if (state.password.length == 0) {
      setState({ ...state, error: 'Password is required' });
      return false;
    } else if (state.confirmPassword.length == 0) {
      setState({ ...state, error: 'Confirm password is required' });
      return false;
    } else if (state.password !== state.confirmPassword) {
      setState({ ...state, error: 'Passwords do not match' });
      return false;
    } else if (!validatePassword(state.password)) {
      setState({
        ...state,
        error:
          'Password does not meet requirements\nIt must be ≥6 characters long, contain a number and special character',
      });
      return false;
    }

    return true;
  }
  /**
   * Validates the password to ensure it meets the following criteria:
   *  1. At least 6 characters long
   *  2. Contains at least one number
   *  3. Contains at least one special character
   */
  const validatePassword = (new_password: string): boolean => {
    const minLength = /.{6,}/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return (
      minLength.test(new_password) &&
      hasNumber.test(new_password) &&
      hasSpecialChar.test(new_password)
    );
  };
  return (
    <div className="login-container">
      <ConfirmModal
        isVisible={modalState.title !== ''}
        title={modalState.title}
        handleClose={() => {
          setModalState({ title: '', message: '' });
        }}
        handleSubmit={() => {
          setModalState({ title: '', message: '' });
        }}
        isDeleteBtn={false}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={modalState.message}
      />

      <form className="login-form" onSubmit={handleSubmit}>
        <div className="company-logo-container ">
          <img className="login-logo" src={image} alt="Hinckley Medical Logo" />
          <h6 className="title-company">Hinckley Medical</h6>
        </div>
        {status === NORMAL_LOGIN && (
          <div className="login-form-container">
            {state.error && <div className="login-error">{state.error}</div>}
            <label htmlFor="username" className={`login-title`}>
              Username
            </label>
            <div className="login-input-container">
              <IoPersonSharp size={20} />
              <InputText
                type="text"
                className="login-input"
                id="username"
                name="username"
                placeholder="Username"
                autoFocus={true}
                required={true}
                value={state.username}
                onChange={(e: any) => {
                  setState({ ...state, username: e.target.value });
                }}
              />
            </div>
            <label htmlFor="password" className={`login-title`}>
              Password
            </label>
            <div className="login-input-container">
              <RiLockPasswordFill size={20} />
              <InputText
                autoComplete="current-password"
                type="password"
                className="login-input"
                id="password"
                name="password"
                placeholder="Password"
                required={true}
                value={state.password}
                onChange={(e: any) => {
                  setState({ ...state, password: e.target.value });
                }}
              />
            </div>
            <button className="login-button" type="submit">
              Login
            </button>
          </div>
        )}
        {status === PASSWORD_CHANGE && (
          <div className="login-form-container">
            {state.error && <div className="login-error">{state.error}</div>}
            <label htmlFor="password" className={`login-title`}>
              New Password
            </label>
            <div className="login-input-container">
              <RiLockPasswordFill size={20} />
              <InputText
                type="password"
                className="login-input"
                id="password"
                name="password"
                placeholder="Password"
                autoFocus={true}
                required={true}
                value={state.password}
                onChange={(e: any) => {
                  setState({ ...state, password: e.target.value });
                }}
              />
            </div>
            <label htmlFor="confirm-password" className={`login-title`}>
              Confirm Password
            </label>
            <div className="login-input-container">
              <RiLockPasswordFill size={20} />
              <InputText
                type="password"
                className="login-input"
                id="confirm-password"
                name="confirm-password"
                placeholder="Confirm Password"
                required={true}
                value={state.confirmPassword}
                onChange={(e: any) => {
                  setState({ ...state, confirmPassword: e.target.value });
                }}
              />
            </div>
            <button className="login-button" type="submit">
              Reset Password
            </button>
          </div>
        )}
      </form>
      <div className="login-background">
        {/* Background image will be set via CSS */}
      </div>
      {isLoading && <Loading type="bubbles" visible={isLoading} />}
    </div>
  );
};

export default LandingPage;
