import React, { useEffect, useState } from 'react';
import { FaLock } from 'react-icons/fa6';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { Tooltip } from '@mui/material';
import './OwnerImage.scss';

interface ProgressStatusInterface {
  owner: DepartmentItem;
  isLocked?: boolean;
  size?: number;
  style?: React.CSSProperties;
}

const OwnerImage: React.FC<ProgressStatusInterface> = (props) => {
  const [owner, setOwner] = useState<DepartmentItem>(props.owner);
  const [isLockButton, setIsLockButton] = useState<boolean>(
    props.isLocked ?? false
  );

  useEffect(() => {
    setOwner(props.owner);
  }, [props.owner]);

  useEffect(() => {
    setIsLockButton(props.isLocked ?? false);
  }, [props.isLocked]);

  return (
    <Tooltip
      title={owner.name + ' owns this item.'}
      placement="bottom"
      arrow
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -16],
              },
            },
          ],
        },
      }}
    >
      <div className="header-logo-container" style={props.style}>
        <img
          className="no-select owner-logo"
          src={owner.logoVerifiedUrl ?? ''}
          alt="Agency Logo"
          style={{
            width: props.size ? props.size : 40,
            height: props.size ? props.size : 40,
          }}
        />
        <div className="lock-icon">
          <FaLock
            data-testid="isLockButton"
            style={{
              color: isLockButton ? '#A3A3A3' : 'transparent',
              fontSize: props.size ? props.size / 1.75 : 20,
            }}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export default OwnerImage;
