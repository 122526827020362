import ProtocolItem from './ProtocolItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import {
  getObjectDifference,
  globals,
  isObjectEqual,
  isObjectInArray,
} from '../../ui/_global/common/Utils';
import { ElectricalShock, ElectricalShockOption, User } from '../../models';
import ElectricalSubItem, {
  cloneAllElectricalSubItems,
} from './ElectricalSubItem';
import { ProgressStatus } from '../../API';

export const cloneElectrical = (electrical: ElectricalItem): ElectricalItem => {
  let clone = new ElectricalItem(electrical.model);
  clone.subItems = cloneAllElectricalSubItems(electrical.subItems);
  clone.parents = electrical.parents;
  return clone;
};

class ElectricalItem extends ModelItem<ElectricalShock> {
  subItems: ElectricalSubItem[] = [];
  parents: ProtocolItem[] = [];
  options: ElectricalShockOption[];
  overrideItem: ElectricalItem | null | undefined;
  activeItem: ElectricalItem | null | undefined;
  rangeLow: number;
  rangeHigh: number;

  warning: string = '';
  instruction: string = '';
  note: string = '';

  constructor(electrical: ElectricalShock) {
    super(electrical, electrical.title, electrical.departmentID);
    this.TAG = 'ElectricalItem';
    this.options = electrical.options;
    this.parents = [];
    this.rangeLow = electrical.rangeLow ? electrical.rangeLow : 0;
    this.rangeHigh = electrical.rangeHigh
      ? electrical.rangeHigh
      : globals.MAX_VALUE;

    this.warning = electrical.warning ? electrical.warning : '';
    this.instruction = electrical.instruction ? electrical.instruction : '';
    this.note = electrical.note ? electrical.note : '';
  }

  getAmount(weight: PatientWeight): string {
    return '';
  }

  // constructor(parent : ProtocolItem, name: string) {
  //     this.name               = name;
  //     this.parentProtocol     = parent;
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.instruction        = "";
  //     this.warning            = "";
  //     this.option             = {
  //         title : "1st Dose",
  //         ranges: [{
  //             rangeLow: 0,
  //             rangeHigh: globals.MAX_VALUE,
  //             basisLow: "1 J/kg",
  //             basisHigh: "2 J/kg",
  //             calcMax: "10 J",
  //             fixedMax: "10 J"
  //         }]};
  //     this.dbElectrical       = null;
  // }

  getUid(): string {
    return this.uid;
  }

  getName(): string {
    return this.name;
  }

  getSubElectricals(): ElectricalSubItem[] {
    return this.subItems;
  }

  addElectricalSubItem(subElectrical: ElectricalSubItem): void {
    let find = this.subItems.find(
      (item) => isObjectEqual(item, subElectrical) === true
    );
    if (find) return;
    this.subItems.push(subElectrical);
    this.subItems.sort((a, b) =>
      a
        .getParentProtocol()
        .getName()
        .localeCompare(b.getParentProtocol().getName())
    );
  }

  removeElectricalSubItem(subElectrical: ElectricalSubItem) {
    this.subItems = this.subItems.filter(
      (item) => isObjectEqual(item, subElectrical) === false
    );
  }

  addParent(parent: ProtocolItem): void {
    this.parents.push(parent);
  }

  getParent(): ProtocolItem[] {
    return this.parents;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ElectricalItem)) return false;
    let other: ElectricalItem = obj as ElectricalItem;
    return this.uid === other.getUid() && this.name === other.getName();
  }

  toString(): string {
    return `ElectricalItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
        }`;
  }
}

export default ElectricalItem;
