import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import InfusionDoseComparisonView from '../comparisonView/InfusionDoseComparisonView';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}
export const compareInfusionSubItems = (
  currentItem: InfusionSubItem,
  archiveItem: InfusionSubItem,
  countInfusionDose: number
): { changes: Change[]; count: number } => {
  console.log('currentItem', currentItem);
  console.log('archiveItem', archiveItem);
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof InfusionSubItem)[] = [
    'rangeLow',
    'rangeHigh',
    'routes',
    'nemsisRoutes',
    'title',
    'warning',
    'note',
    'instruction',
    'ageLow',
    'ageHigh',

    'fullBasis',
    'fullMaxDose',
    'fullMaxTotalDose',
    'fullMinDose',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (
      key === 'rangeHigh' ||
      key === 'fullMaxDose' ||
      key === 'fullMaxTotalDose'
    ) {
      currentValue =
        currentValue === globals.MAX_VALUE || currentValue === ''
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === globals.MAX_VALUE || archiveValue === ''
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'ageLow' || key === 'ageHigh') {
      currentValue = currentValue
        ? currentValue.age + ' ' + currentValue.units
        : '';
      archiveValue = archiveValue
        ? archiveValue.age + ' ' + archiveValue.units
        : '';
    }

    if (key === 'rangeLow' || key === 'fullMinDose') {
      currentValue =
        currentValue === 0 || currentValue === ''
          ? 'Min'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === 0 || archiveValue === ''
          ? 'Min'
          : (archiveValue as string) || '';
    }

    if (key === 'routes' || key === 'nemsisRoutes') {
      currentValue = (currentValue as string[])?.join(', ') || '';
      archiveValue =
        (archiveValue !== '' && (archiveValue as string[])?.join(', ')) || '';
    }

    if (compareValues(currentValue, archiveValue)) {
      countInfusionDose += 1;
    }
    changes.push({
      key,
      currentValue: String(currentValue),
      archiveValue: String(archiveValue),
    });
  });

  return { changes, count: countInfusionDose };
};

const InfusionDoseComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  changeItem,
  previousItem,
  onChangesCount,
}) => {
  const countInfusionDose = 0;
  const { changes, count } = useMemo(() => {
    const { changes: comparedChanges, count: changeCount } =
      compareInfusionSubItems(
        changeItem as InfusionSubItem,
        previousItem as InfusionSubItem,
        countInfusionDose
      );
    if (onChangesCount) {
      onChangesCount(changeCount); // Call the callback with the changes count
    }
    return { changes: comparedChanges, count: changeCount };
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    setViewMode(mode);
  };
  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">
                      Before
                      {/* <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('before')}
                      >
                        View
                      </button> */}
                    </div>{' '}
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      After
                      {/* <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button> */}
                    </div>
                  </th>
                </tr>
              </thead>
              {/* pass the changes to medicationFullView to display only the changes for before and after in tabular */}
              <tbody>
                <tr>
                  <td>
                    <InfusionDoseComparisonView
                      focusItem={previousItem as InfusionSubItem}
                      showHeader={false}
                      changes={changes}
                      keepBefore={true}
                    />
                  </td>
                  <td>
                    <InfusionDoseComparisonView
                      focusItem={changeItem as InfusionSubItem}
                      changes={changes}
                      showHeader={false}
                    />
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      {/* <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button> */}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <InfusionDoseComparisonView
                      focusItem={changeItem as InfusionSubItem}
                      changes={changes}
                      showHeader={false}
                    />
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default InfusionDoseComparison;
