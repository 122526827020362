export const Colors = {
  /* ---------- Hinckley Medical's Theme Colors ---------- */
  hm_green: '#00534C',
  
  hm_green_light: '#016B62',
  hm_green_dark: '#00372F',
  hm_green_background: '#F3F7F2',

  hm_green_accent: '#8CB181',
  hm_green_accent_light: '#B7CFB0',
  hm_green_accent_dark: '#5A8F5C',

  hm_light_green: '#E0EADD',

  /* HM Dark mode colors */
  hm_green_dark_mode: '#206962',
  hm_dark_shade_green: '#1A1B1A',//#383A37',//
  hm_onPrimary: '#383A37',
  hm_offBackground: '#232422',//
  hm_offLightBackground: '##101110',//


  grey: '#9E9E9E',
  dark_mode_grey: '#A3A3A3',
  light_grey: '#E0E0E0',
  dark_grey: '#616161',
  median_grey: '#f2f2f2',

  red: '#FA1212',
  light_red: '#cda0a5',
  dark_red: '#880808',
  median_red: '#8c1010',
  dark_mode_median_red: '#661414',

  green: '#7FFF00',
  light_green: '#D0FFB3',
  cherry_green: '#D0FEB3',
  cherry_green_dark_mode: '#387000',

  dark_green: '#037F02',
  notification_grid_color: '#c4e6b3',

  blue: '#0479b3',
  dark_mode_blue: '#4A90E2', //'#8CCBEE',
  light_blue: '#61add7',
  dark_blue: '#7bbde0',

  white: '#FFF',
  off_white: '#F3F3F3',
  black: '#000',
  off_black: '#3A3737',
  overlay: '#000000A0',

  radio_button_blue: '#655393',
  radio_btn_transparent: 'transparent',

  light_orange: '#fed698',
  light_yellow: '#e9f542',
  highlight_light_yellow: '#fcf403',

  transparent: 'transparent',

  /* ---------- Pateint Theme Colors ---------- */
  //Primary Color
  patient_grey: '#787878',
  patient_pink: '#F9B7B2',
  patient_red: '#DC143C',
  patient_purple: '#823896',
  patient_yellow: '#F0D600',
  patient_white: '#F3F3F3',
  patient_blue: '#729CC3',
  patient_orange: '#FA7A45',
  patient_green: '#5F9E2C',
  patient_small_adult: '#8CB181',
  patient_adult: '#8CB181',
  patient_large_adult: '#8CB181',

  //Primary Selected Color
  patient_grey_selected: '#787878',
  patient_pink_selected: '#F9B7B2',
  patient_red_selected: '#DC143C',
  patient_purple_selected: '#823896',
  patient_yellow_selected: '#F0D600',
  patient_white_selected: '#F3F3F3',
  patient_blue_selected: '#729CC3',
  patient_orange_selected: '#FA7A45',
  patient_green_selected: '#5F9E2C',
  patient_small_adult_selected: '#8CB181',
  patient_adult_selected: '#8CB181',
  patient_large_adult_selected: '#8CB181',

  //Secondary Color
  patient_grey_light: '#C8C8C8',
  patient_pink_light: '#fbd2d0',
  patient_red_light: '#FFC7CE',
  patient_purple_light: '#BCA9B3',
  patient_yellow_light: '#faf0b7',
  patient_white_light: '#F5F5F5',
  patient_blue_light: '#ADD8E6',
  patient_orange_light: '#FFCF8B',
  patient_green_light: '#C5CD94',
  patient_small_adult_light: '#b7cfb0',
  patient_adult_light: '#b7cfb0',
  patient_large_adult_light: '#b7cfb0',

  //Secondary Selected Color
  patient_grey_light_selected: '#C8C8C8',
  patient_pink_light_selected: '#fbd2d0',
  patient_red_light_selected: '#FFC7CE',
  patient_purple_light_selected: '#BCA9B3',
  patient_yellow_light_selected: '#faf0b7',
  patient_white_light_selected: '#E3E3E3',
  patient_blue_light_selected: '#ADD8E6',
  patient_orange_light_selected: '#FFCF8B',
  patient_green_light_selected: '#C5CD94',
  patient_small_adult_light_selected: '#b7cfb0',
  patient_adult_light_selected: '#b7cfb0',
  patient_large_adult_light_selected: '#b7cfb0',

  //Background Color
  patient_grey_bg: '#CCCCCC',
  patient_pink_bg: '#FDE4E2',
  patient_red_bg: '#F2A7B6',
  patient_purple_bg: '#D0B4D8',
  patient_yellow_bg: '#F9F09F',
  patient_white_bg: '#DDDCD6',
  patient_blue_bg: '#CADAE9',
  patient_orange_bg: '#FDCDB9',
  patient_green_bg: '#C3DBB0',
  patient_small_adult_bg: '#B7CFB0',
  patient_adult_bg: '#B7CFB0',
  patient_large_adult_bg: '#B7CFB0',

  //Secondary Background Color
  patient_grey_bg_secondary: '#CCCCCC',
  patient_white_bg_secondary: '#F5F5F5',
  patient_dark_bg_secondary: '#383A37',

  //Contrast Color
  patient_orange_contrast: '#FFD699',
  patient_blue_contrast: '#ADD8E6',
  patient_dark_orange_contrast: '#C9A678',
  patient_dark_blue_contrast: '#6E90A6',

  //Secondary Selected Background Color
  patient_grey_bg_light: '#a0a0a0',
  patient_pink_bg_light: '#caa8a7',
  patient_red_bg_light: '#cda0a5',
  patient_purple_bg_light: '#97888f',
  patient_yellow_bg_light: '#c8c092',
  patient_white_bg_light: '#c4c4c4',
  patient_blue_bg_light: '#8badb7',
  patient_orange_bg_light: '#cba671',
  patient_green_bg_light: '#9ea478',
  patient_small_adult_bg_light: '#D28D98',
  patient_adult_bg_light: '#D8B3A4',
  patient_large_adult_bg_light: '#D6AF82',

  /* PATIENT DARK MODE COLORS */
  //Background Color
  patient_dark_grey_bg: '#808080',
  patient_dark_pink_bg: '#D16F8C',
  patient_dark_red_bg: '#B85C72',
  patient_dark_purple_bg: '#8B6A9F',
  patient_dark_yellow_bg: '#B8A461',
  patient_dark_white_bg: '#9B9A91',
  patient_dark_blue_bg: '#7A92B1',
  patient_dark_orange_bg: '#B28675',
  patient_dark_green_bg: '#7B967A',
  patient_dark_small_adult_bg: '#7A967B',
  patient_dark_adult_bg: '#7A967B',
  patient_dark_large_adult_bg: '#7A967B',

  //Secondary Color
  patient_dark_grey_light: '#7B7B7B',
  patient_dark_pink_light: '#C18B89',
  patient_dark_red_light: '#CB858C',
  patient_dark_purple_light: '#7E6C7B',
  patient_dark_yellow_light: '#C1B187',
  patient_dark_white_light: '#B1B1B1',
  patient_dark_blue_light: '#6E90A6',
  patient_dark_orange_light: '#C09B60',
  patient_dark_green_light: '#899371',
  patient_dark_small_adult_light: '#7A967B',
  patient_dark_adult_light: '#7A967B',
  patient_dark_large_adult_light: '#7A967B',


  // Bluetooth Animation Waves
  bluetooth_Dark_Blue_Primay: '#5aabd6',
  bluetooth_Dark_Blue_Secondary: '#8bc8e6',
  bluetooth_Dark_Blue_Third: '#83c2e8',
  bluetooth_Dark_Blue_Four: '#75b9de',

  /* Event History Colors */
  scale_saved: '#BDEEBD',
  scale_mode: '#E6B8B7',
  scale_sleep: '#FCD5B4',
  scale_protocol: '#DDD9C4',




  /* Dark mode colors */
  
};
