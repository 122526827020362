import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import HighlightChanges from '../HighlightChanges';
import InfusionFullView from '../fullView/InfusionFullView';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import InfusionComparisonView from '../comparisonView/InfusionComparisonView';
import FullViewDialog from '../fullView/FullViewDialog';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}
export const compareInfusionItems = (
  currentItem: MedicationItem,
  archiveItem: MedicationItem
): Change[] => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof MedicationItem)[] = [
    'name',
    'rangeLow',
    'rangeHigh',
    'contraindication',
    'warning',
    'indication',
    'interaction',
    'onset',
    'duration',
    'note',
    'medClass',
    'action',
    'routes',
    'concentrations',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  function getConcentrationString(concentrations: any) {
    if (!concentrations) return '';
    let str = '';

    for (let i = 0; i < concentrations.length; i++) {
      let concen = concentrations[i];
      if (concen.firstAmnt <= 0) return 'N/A';
      str += concen.firstAmnt + ' ' + concen.firstUnit;
      if (!concen.secAmnt || concen.secAmnt <= 0) return str;
      if (concen ?? 0 > 0) {
        str += '/';
        if (concen.secAmnt != 1.0) str += concen.secAmnt + ' ' + concen.secUnit;
        else str += concen.secUnit;
      }
      if (i < concentrations.length - 1) str += ', ';
    }
    return str;
  }

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (key === 'rangeHigh') {
      currentValue =
        currentValue === globals.MAX_VALUE
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === globals.MAX_VALUE
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'rangeLow') {
      currentValue =
        currentValue === 0 ? 'Min' : (currentValue as string) || '';
      archiveValue =
        archiveValue === 0 ? 'Min' : (archiveValue as string) || '';
    }

    if (key === 'routes') {
      currentValue = (currentValue as string[])?.join(', ') || '';
      archiveValue =
        (archiveValue !== '' && (archiveValue as string[])?.join(', ')) || '';
    }

    if (key === 'concentrations') {
      currentValue = getConcentrationString(currentValue);
      archiveValue = getConcentrationString(archiveValue);
    }

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return changes;
};

const InfusionComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  changeItem,
  previousItem,
  onChangesCount,
}) => {
  const changes = useMemo(() => {
    const comparedChanges = compareInfusionItems(
      changeItem as MedicationItem,
      previousItem as MedicationItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    setViewMode(mode);
  };

  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem ? (
              <>
                {' '}
                <thead>
                  <tr>
                    <th className="changeHeader beforeChange">
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>{' '}
                    </th>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <InfusionComparisonView
                        focusItem={previousItem as MedicationItem}
                        showHeader={false}
                        changes={changes}
                        keepBefore={true}
                      />
                    </td>

                    <td>
                      <InfusionComparisonView
                        focusItem={changeItem as MedicationItem}
                        changes={changes}
                        showHeader={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                {' '}
                <thead>
                  <tr>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        New {toTitleCase(currentDraft.changeType)}
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <InfusionComparisonView
                        focusItem={changeItem as MedicationItem}
                        changes={changes}
                        showHeader={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FullViewDialog
            isVisible={true}
            handleClose={() => setViewMode('none')}
            title="View Infusion"
          >
            <InfusionFullView
              focusItem={
                viewMode === 'before'
                  ? (previousItem as MedicationItem)
                  : (changeItem as MedicationItem)
              }
              handleBack={() => setViewMode('none')}
              viewText={viewMode === 'before' ? 'Before' : 'After'}
            />
          </FullViewDialog>
        </>
      )}
    </div>
  );
};

export default InfusionComparison;
