const currentDate = new Date();

export const sideoutList = [
  {
    id: 1,
    name: 'Add Paired Protocol',
    type: 'Paired Protocol',
  },
  {
    id: 2,
    name: 'Add Medication',
    type: 'Medication',
  },
  {
    id: 3,
    name: 'Add Infusion',
    type: 'Infusion',
  },
  {
    id: 4,
    name: 'Add Electrical',
    type: 'Electrical',
  },
  {
    id: 5,
    name: 'Add Equipment',
    type: 'Equipment',
  },
  // ,
  // {
  //     id: 6,
  //     name: 'Add Vital',
  //     type: 'Vital'
  // }
  {
    id: 7,
    name: 'Add Checklist',
    type: 'Checklist',
  },
];

export const cprAssistSideoutList = [
  {
    id: 2,
    name: 'Add Epinephrine',
    type: 'Epinephrine',
  },
  {
    id: 4,
    name: 'Add Defibrillation Shock',
    type: 'Defibrillation Shock',
  },
  {
    id: 5,
    name: 'Add Equipment',
    type: 'Equipment',
  },
];

export const detectedChangesMockData = [
  {
    id: 1,
    topic: '9-item order changes',
  },
  {
    id: 2,
    topic: 'Added Protocol A1. Advanced Airway Analgesic Sedation',
  },
  {
    id: 3,
    topic: 'Added Folder A.Universal Protocols',
  },
];

export const dropdownSolidUnits = [
  { name: 'mcg', code: 'mcg' },
  { name: 'mg', code: 'mg' },
  { name: 'G', code: 'G' },
  { name: 'gm', code: 'gm' },
  { name: 'mEq', code: 'mEq' },
  { name: 'mL', code: 'mL' },
  { name: 'L', code: 'L' },
  { name: 'drops', code: 'drops' },
  { name: 'units', code: 'units' },
  { name: 'sprays', code: 'sprays' },
  { name: '%', code: '%' },
  { name: 'gtts', code: 'gtts' },
  { name: 'Puffs', code: 'Puffs' },
  { name: 'LPM', code: 'LPM' },
  { name: 'inches', code: 'inches' },
];

export const dropdownSolidCalcUnits = [
  { name: 'mcg/kg', code: 'mcg/kg' },
  { name: 'mg/kg', code: 'mg/kg' },
  { name: 'G/kg', code: 'G/kg' },
  { name: 'gm/kg', code: 'gm/kg' },
  { name: 'mEq/kg', code: 'mEq/kg' },
  { name: 'mL/kg', code: 'mL/kg' },
  { name: 'L/kg', code: 'L/kg' },
  { name: 'drops/kg', code: 'drops/kg' },
  { name: 'units/kg', code: 'units/kg' },
  { name: 'sprays/kg', code: 'sprays/kg' },
  { name: '%/kg', code: '%/kg' },
  { name: 'gtts/kg', code: 'gtts/kg' },
  { name: 'Puffs/kg', code: 'Puffs/kg' },
  { name: 'LPM/kg', code: 'LPM/kg' },
];

export const dropdownLiquidUnits = [
  { name: '(blank)', code: '' },
  { name: 'mL', code: 'mL' },
  { name: 'L', code: 'L' },
  { name: 'cc', code: 'cc' },
  { name: 'Tab', code: 'Tab' },
];

export const dropdownUnitsInTime = [
  { name: 'NA', code: 'NA' },
  { name: 'hr', code: 'hr' },
  { name: 'min', code: 'min' },
];

export const dropdownWeightUnits = [
  { name: 'NA', code: 'NA' },
  { name: 'kg', code: 'kg' },
  { name: 'lbs', code: 'lbs' },
];

export const dropdownFixedMax = [
  { name: 'J', code: 'J' },
  { name: 'mA', code: 'mA' },
  { name: 'mAmps', code: 'mAmps' },
  { name: 'rate', code: 'rate' },
];

export const dropdownElectricalCalcUnits = [
  { name: 'J/kg', code: 'J/kg' },
  { name: 'mA/kg', code: 'mA/kg' },
  { name: 'mAmps/kg', code: 'mAmps/kg' },
  { name: 'rate/kg', code: 'rate/kg' },
];

export const dropdownElectricalUnits = [
  { name: 'J', code: 'J' },
  { name: 'mA', code: 'mA' },
  { name: 'mAmps', code: 'mAmps' },
  { name: 'rate', code: 'rate' },
];

export const ageDropdownValues = [
  { name: 'MIN', code: 'MIN' },
  { name: '1 Mo', code: '1 Mo' },
  { name: '2 Mo', code: '2 Mo' },
  { name: '3 Mo', code: '3 Mo' },
  { name: '4 Mo', code: '4 Mo' },
  { name: '5 Mo', code: '5 Mo' },
  { name: '6 Mo', code: '6 Mo' },
  { name: '7 Mo', code: '7 Mo' },
  { name: '8 Mo', code: '8 Mo' },
  { name: '9 Mo', code: '9 Mo' },
  { name: '10 Mo', code: '10 Mo' },
  { name: '11 Mo', code: '11 Mo' },
  { name: '1 Yr', code: '1 Yr' },
  { name: '2 Yr', code: '2 Yr' },
  { name: '3 Yr', code: '3 Yr' },
  { name: '4 Yr', code: '4 Yr' },
  { name: '5 Yr', code: '5 Yr' },
  { name: '6 Yr', code: '6 Yr' },
  { name: '7 Yr', code: '7 Yr' },
  { name: '8 Yr', code: '8 Yr' },
  { name: '9 Yr', code: '9 Yr' },
  { name: '10 Yr', code: '10 Yr' },
  { name: '11 Yr', code: '11 Yr' },
  { name: '12 Yr', code: '12 Yr' },
  { name: '13 Yr', code: '13 Yr' },
  { name: '14 Yr', code: '14 Yr' },
  { name: 'Sm. Adult', code: 'Sm. Adult' },
  { name: 'Adult', code: 'Adult' },
  { name: 'Lg. Adult', code: 'Lg. Adult' },
  { name: 'MAX', code: 'MAX' },
];

export const routeOptions = ['IV', 'IO', 'Slow IV/IO'];

export const infusionItems = [
  { id: 1, name: 'Infusion1', type: 'Infusion' },
  { id: 2, name: 'Infusion2', type: 'Infusion' },
  { id: 3, name: 'Infusion3', type: 'Infusion' },
];

export const snapshotList = [
  {
    id: 1,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 2,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 3,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 4,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 5,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 6,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 7,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 8,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
  {
    id: 9,
    name: 'Added three files and changes the order',
    title: 'snapshot',
    message: 'snapshot message',
    user: {
      name: 'Colton Hazlett',
      publishDate: '11/12/2022',
      publishType: ' Automatic Change',
      detectedChange: 11,
    },
    numberOfChanges: 7,
    date: '11/12/2022',
  },
];
export const notificationist = [
  {
    id: 1,
    title: 'GenerLal Notification Title',
    questionAnswer: [],
    type: 'GENERAL',
    progress: { totalUser: 21, viewed: 10, acknowledge: 20 },
    notificationStyle: 'push',
    notificationType: 'General',
    user: 'Colton Hazlett',
    message:
      'This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description',
    numberOfChanges: 7,
    date: '11/12/2022',
    timestamp: '11/12/2022',
  },
  {
    id: 2,
    title: 'Acknowledge Notification Title',
    questionAnswer: [],
    type: 'ACKNOWLEDGE',
    progress: { totalUser: 21, viewed: 10, acknowledge: 30 },
    notificationStyle: 'push',
    notificationType: 'Acknowledge',
    user: 'Colton Hazlett',
    message:
      'This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description',
    numberOfChanges: 7,
    date: '11/12/2022',
    timestamp: '11/12/2022',
  },
  {
    id: 3,
    title: 'Training Notification Title',
    trainingDeadline: new Date(),
    questionAnswer: [],
    type: 'TRAINING',
    progress: { totalUser: 21, viewed: 10, acknowledge: 60 },
    notificationStyle: 'push',
    notificationType: 'Training',
    user: 'Colton Hazlett',
    message:
      'This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description. This is an example description',
    numberOfChanges: 7,
    date: '11/12/2022',
    timestamp: '11/12/2022',
  },
];

export const notificationTypes = [
  { label: 'General', value: 'GENERAL' },
  { label: 'Acknowledge', value: 'ACKNOWLEDGE' },
  { label: 'Training', value: 'TRAINING' },
];

export const adminUserTypes = [
  { label: 'User', value: 'USER' },
  { label: 'Department', value: 'DEPT' },
  { label: 'Admin', value: 'DEPT_ADMIN' },
  { label: 'Super Admin', value: 'ADMIN' },
];

export const genUserTypes = [
  { label: 'User', value: 'USER' },
  { label: 'Department', value: 'DEPT' },
  { label: 'Admin', value: 'DEPT_ADMIN' },
];

export const filterOption = [
  { id: 1, name: 'General' },
  { id: 2, name: 'Acknowledge' },
  { id: 3, name: 'Training' },
];

export const filterOptionDateRange = [
  { label: 'Last 3 Days', value: '3 Days' },
  { label: 'Last 1 Week', value: '1 Week' },
  { label: 'Last 2 Weeks', value: '2 Weeks' },
  { label: 'Last Month', value: '1 Month' },
  { label: 'Last 3 Months', value: '3 Months' },
  { label: `Till ${currentDate.getFullYear()}`, value: 'Till Present' },
];

export const createNotificationValues = {
  id: '',
  notificationStyle: '',
  notificationType: '',
  title: '',
  pairedProtocol: '',
  trainingDeadline: null,
  message: '',
  attachment: '',
  user: 'Colton Hazlett',
  numberOfChanges: 7,
  date: currentDate,
  progress: { totalUser: 21, viewed: 0, acknowledge: 0 },
  questionAnswer: { question: '', answer: [] },
};

export const createNotificationData = {
  uid: '',
  title: '',
  message: '',
  timestamp: currentDate,
  isReadIDs: '',
  isAckIds: '',
  imageURIs: '',
  videoURIs: '',
  taggedProtocols: [],
  trainingDeadline: currentDate,
  questions: [],
  type: '',
  attachment: '',
  style: '',
  progress: { totalUser: 21, viewed: 0, acknowledge: 0 },
};
