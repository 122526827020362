import React, { ReactNode, useEffect, useState } from 'react';
import Wrapper from '../components/Wrapper';
import SideBar from '../components/sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Popup from '../components/Popup';
import Login from '../pages/login/Login';
import { Col, Row } from 'react-grid-system';
import { DatabaseResponse } from '../../data/AmplifyDB';
import { User } from '../../models';
import { globals } from '../_global/common/Utils';
import ActivityLogSidebar from '../components/sidebar/ActivityLogSidebar';

interface DashboardProps {
  children?: ReactNode;
  pageTitle: string;
  component?: ReactNode;
}

/* 09-28-23 Arul: Created the Dashboard layout component to wrapper the navbar, sidebar and main component */
const Dashboard: React.FC<DashboardProps> = ({
  children,
  pageTitle,
  component,
}) => {
  const location = useLocation();
  const Sidebar = useSelector((state: any) => state.sidebar);
  const isMiniMode = useSelector((state: any) => state.isMini);
  const activityLogSidebar = useSelector(
    (state: any) => state.activityLogSidebar
  );
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const [isOpen, setIsOpen] = useState(false);
  const user: User = useSelector((state: any) => state.user);

  const handleUserInfo = (props: any) => {
    setIsOpen(!isOpen);
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ flex: 1 }}>
      {isOpen && (
        <Popup
          isVisible={isOpen}
          content={
            <Login onSuccess={handleUserInfo} handleClose={togglePopup} />
          }
          handleClose={togglePopup}
        />
      )}
      <Navbar handleLogin={togglePopup} />

      {/* Left Sidebar Container for positioning Sidebar and Activity Log Sidebar together on the left */}
      <div className="left-sidebar-container">
        <SideBar
          show={Sidebar}
          name={!isLoggedIn || !user ? 'Guest' : user.firstName}
        />
        {isLoggedIn && user && (
          <ActivityLogSidebar show={activityLogSidebar && Sidebar} />
        )}
      </div>

      <div
        className={`main ${Sidebar ? 'with-sidebar' : ''} 
        ${Sidebar && isMiniMode && activityLogSidebar ? 'with-activity-log' : ''}`}
      >
        {pageTitle}
        {children}
        {component}
      </div>
    </div>
  );
};

export default Dashboard;
