import React from 'react';
import { diffWords, Change as DiffChange } from 'diff';

interface HighlightChangesProps {
  beforeText: string;
  afterText: string;
}

// Type alias for the diff changes
type DiffPart = DiffChange;

const HighlightChanges: React.FC<HighlightChangesProps> = ({
  beforeText,
  afterText,
}) => {
  // Get the differences between the two texts
  const changes: DiffPart[] = diffWords(beforeText, afterText);

  return (
    <div>
      {changes.map((part, index) => {
        // Determine background and text color based on the type of change
        const backgroundColor = part.added
          ? '#eaf2c2'
          : part.removed
            ? '#fadad7'
            : 'transparent';
        const color = part.added
          ? '#406619'
          : part.removed
            ? '#b30000'
            : '#000000';

        return (
          <span
            key={index}
            style={{
              backgroundColor,
              color,
              display: 'inline',
              padding: '0 2px',
              borderRadius: '3px',
              width: 'fit-content',
            }}
          >
            {part.value}
          </span>
        );
      })}
    </div>
  );
};

export default HighlightChanges;
