import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import { User, Workbook } from '../../../models';
import WorkbookItem from '../../../data/model/WorkbookItem';
import ProtocolHeader from '../protocol/ProtocolHeader';
import SearchBar from '../../components/Search/SearchBar';
import { ProgressStatus } from '../../../models';
import {
  CalculateByteSting,
  findDepartmentOwner,
  getFormattedDate,
  globals,
} from '../../_global/common/Utils';
import { TbDatabaseOff } from 'react-icons/tb';
import { MdCreateNewFolder } from 'react-icons/md';
import ProtocolSetFeatures from './ProtocolSetFeatures';
import { fetchWorkbooks } from '../../../data/functions/WorkbookDB';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import OwnerImage from '../../components/OwnerImage/OwnerImage';
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ProtocolSetList = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);
  const department = database.department;
  const user: User = useSelector((state: any) => state.user);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state && state.search ? state.search.searchQuery : ''
  );
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [list, setList] = useState<WorkbookItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };

  const getDetails = useCallback(async () => {
    const result: Response = await fetchWorkbooks(department, true, true);
    if (result.type === ResponseType.Success) {
      setWorkbookList(result.data);
      setList(result.data);
    } else if (globals.debug)
      console.log('Error getting Workbooks', result.data);
  }, [department.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails, department.id]);

  useEffect(() => {
    if (sort) {
      const sortedList = list.sort((a, b) => {
        if (sort === 'name_desc') return a.name.localeCompare(b.name);
        else if (sort === 'modified_asc') {
          if (!a.model.updatedAt || !b.model.updatedAt) return 0;
          const aDate = new Date(a.model.updatedAt);
          const bDate = new Date(b.model.updatedAt);
          return aDate.getTime() - bDate.getTime();
        } else if (sort === 'modified_desc') {
          if (!a.model.updatedAt || !b.model.updatedAt) return 0;
          const aDate = new Date(a.model.updatedAt);
          const bDate = new Date(b.model.updatedAt);
          return bDate.getTime() - aDate.getTime();
        } else if (sort === 'file_size_asc') return a.fileSize - b.fileSize;
        else if (sort === 'file_size_desc') return b.fileSize - a.fileSize;

        return b.name.localeCompare(a.name);
      });
      setList(sortedList);
    }
  }, [sort]);

  const handleSearchChange = (query: string) => {
    setSearchQuery(query);
    const filteredList = workbookList.filter((item: WorkbookItem) => {
      return item.name.toLowerCase().includes(query);
    });
    setList(filteredList);
  };

  const handleItemClick = (wb: WorkbookItem) => {
    const state = {
      list: workbookList,
      selectedItem: wb,
      searchState: {
        searchQuery: searchQuery,
      },
    };
    navigate(`/protocol-sets/edit`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      list: workbookList,
      searchState: {
        searchQuery: searchQuery,
      },
    };
    navigate(`/protocol-sets/new`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns:
      '3fr 2fr 2fr 1fr' + (user.type === 'ADMIN' ? ' 1fr' : ''), // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value.name}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },
    {
      field: 'modified_date',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Modified Date</div>
      ),
      filterable: false,
    },

    {
      field: 'modified_by',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Modified By</div>,
      filterable: false,
    },
    {
      field: 'owner',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Owner</div>,
      renderCell: (params) => {
        if (department) {
          const departmentOwner = findDepartmentOwner(department, params.value);
          return (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {departmentOwner && (
                <OwnerImage
                  owner={departmentOwner}
                  // isLocked={!adminAccess}
                  size={32}
                />
              )}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
      sortable: true,
      sortComparator: (v1, v2) => {
        const owner1 = findDepartmentOwner(department, v1);
        const owner2 = findDepartmentOwner(department, v2);
        return owner1?.name.localeCompare(owner2?.name || '') || 0;
      },
      filterable: false,
    },

    ...(user.type === 'ADMIN'
      ? [
          {
            field: 'ai_parsed',
            flex: 1,
            renderHeader: () => (
              <div style={{ fontWeight: 'bold' }}>AI Parsed</div>
            ),
            filterable: false,
          },
        ]
      : []),

    {
      field: 'file_size',
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          File Size
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {params.value}
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      filterable: false,
    },
  ];

  const rows = list.map((item: WorkbookItem) => {
    let username = item.modifiedBy
      ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
      : 'Hinckley Medical';
    return {
      id: item.uid,
      name: {
        name: item.name,
      },
      modified_date: getFormattedDate(
        item.model?.updatedAt ? item?.model?.updatedAt : new Date(),
        true
      ),
      modified_by: username,
      owner: item,
      ai_parsed: item.aiPdfParserResults != null ? 'True' : '-',
      file_size: CalculateByteSting(item.fileSize),
    };
  });

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        handleCancel={handleBack}
        name={'Protocol Sets: ' + list.length + ' items'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <SearchBar
        containerStyle={{ width: '60%' }}
        value={searchQuery}
        onChange={(searchTerm: string) => {
          handleSearchChange(searchTerm);
        }}
        onSubmit={(searchTerm: string) => {}}
        placeholder={'Search Protocol Sets...'}
      />
      {workbookList.length === 0 ? (
        <div className="no-data-container" style={{ marginTop: '15vh' }}>
          <MdCreateNewFolder size={220} className="light-grey-icon" />
          <h4 className="light-grey-icon">No workbooks created...</h4>
        </div>
      ) : (
        <>
          <Paper>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[50, 100, 200]}
              onRowClick={(params) =>
                handleItemClick(
                  list.find((item) => item.uid === params.id) as WorkbookItem
                )
              }
              sx={{
                '& .MuiDataGrid-footerContainer p': {
                  // Target the <p> tags
                  margin: 0, // Remove margin
                },

                '& .MuiCheckbox-root': {
                  '&.Mui-checked': {
                    color: '#00534C',
                  },
                  '&.MuiCheckbox-indeterminate': {
                    color: '#00534C',
                  },
                },
                '& .Mui-selected': {
                  backgroundColor: '#E0EADD !important',
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                  backgroundColor: '#E0EADD',
                },
                '& .MuiDataGrid-cell': {
                  outline: 'none', // Remove blue border on click
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none', // Remove focus outline when cell is focused
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Paper>
        </>
      )}
    </div>
  );
};

export default ProtocolSetList;
