import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import { ProgressStatus } from '../../../../models';
import SearchBar from '../../../components/Search/SearchBar';

const ListArchiveElectrical = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { data: electrical, department, database } = state;

  const [searchQuery, setSearchQuery] = useState(
    state.search ? state.search.searchQuery : ''
  );
  const [filterElectrical, setFilterElectrical] = useState<any[]>(electrical);

  useEffect(() => {
    const filtered = electrical.filter(
      (electrical: any) =>
        electrical.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (electrical.modifiedBy &&
          electrical.modifiedBy
            .toLowerCase()
            .includes(searchQuery.toLowerCase()))
    );
    setFilterElectrical(filtered);
  }, [searchQuery, electrical]);

  // const handleItemClick = (elec: ElectricalItem) => {
  //     const state = {
  //         selectedProtocol: null,
  //         value: elec,
  //         subValue: null,
  //         type: "Electrical",
  //         editType: "edit",
  //         editMode: false,
  //         page: "listElectricalPage",
  //     };
  //     navigate(`/protocol/edit/edit-protocol`, { state });
  // };

  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 1fr',
  };

  const handleBack = () => {
    navigate(`/archive`, { state: department });
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Electrical: ' + filterElectrical.length + ' items'}
        page={department.name}
        type={'protocol'}
      />
      <hr style={{ margin: 10 }} />
      <Row>
        <Col sm={12}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Electrical...'}
          />
        </Col>
      </Row>

      <div>
        <div className="accordion-container">
          <div style={{ borderBottom: '1px solid #ccc' }}>
            <Accordion>
              <div style={rowStyle} className="departmentItemNoHover">
                <h6 className="departmentItemText">Name</h6>
                <h6 className="departmentItemText">Modified Date</h6>
                <h6 className="departmentItemText">Modified By</h6>
                <h6 className="departmentItemText">Shocks</h6>
              </div>
            </Accordion>
          </div>
          <ViewportList items={filterElectrical}>
            {(item: ElectricalItem | any, index) => {
              let username = item.modifiedBy
                ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                : 'Hinckley Medical';
              return (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  //   onClick={() => handleItemClick(item)}
                >
                  <div style={rowStyle} className="departmentItem">
                    <h6 className="departmentItemText">
                      {item.name}
                      {item.status === ProgressStatus.DRAFT ? ' (Draft)' : ''}
                    </h6>
                    <h6 className="departmentItemText">
                      {getFormattedDate(
                        item?.model?.updatedAt
                          ? item?.model?.updatedAt
                          : new Date(),
                        true
                      )}
                    </h6>
                    <h6 className="departmentItemText">{username}</h6>
                    <div style={{ width: '100%', justifyContent: 'flex-end' }}>
                      <h6 className="departmentItemText">
                        {item.subItems.length}
                        <span>
                          <FaChevronRight
                            className="icon-normal "
                            style={{ margin: '4px' }}
                          />
                        </span>
                      </h6>
                    </div>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default ListArchiveElectrical;
