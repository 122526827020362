import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import MedicationSubItem, {
  cloneMedicationSubItems,
} from './MedicationSubItem';
import {
  roundToDec,
  globals,
  getObjectDifference,
  isObjectInArray,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import {
  Concentration,
  Drip,
  Medication,
  MedicationConcentration,
  MedicationProtocol,
  User,
} from '../../models';
import { ProgressStatus } from '../../API';

export const cloneMedication = (med: MedicationItem): MedicationItem => {
  let newMed = new MedicationItem(med.model);
  newMed.subItems = cloneMedicationSubItems(med.subItems);
  newMed.concentrations = med.concentrations;
  return newMed;
};

export const getConcentrationsArrayString = (
  concens: MedicationConcentration[] | Concentration[]
): string => {
  if (concens == null || concens.length === 0) return '';
  let str = '';
  concens.forEach((concen) => {
    str += getConcentrationString(concen) + ', ';
  });
  return str.slice(0, -2);
};

export const getConcentrationString = (
  concen: MedicationConcentration | Concentration
): string => {
  if (concen.firstAmnt <= 0) return 'N/A';

  let str = '';
  str += concen.firstAmnt + ' ' + concen.firstUnit;
  if (!concen.secAmnt || concen.secAmnt <= 0) return str;
  str += '/';
  if (concen.secAmnt !== 1.0) str += concen.secAmnt + ' ' + concen.secUnit;
  else str += concen.secUnit;

  return str;
};

class MedicationItem extends ModelItem<Medication> {
  oldConcentrations: MedicationConcentration[];
  concentrations: Concentration[] = [];

  overrideItem: MedicationItem | null | undefined;
  activeItem: MedicationItem | null | undefined;

  subItems: MedicationSubItem[];
  protocolOptions: MedicationProtocol[];
  routes: string[];

  rxNormCode: string | null | undefined;
  rangeLow: number;
  rangeHigh: number;

  contraindication: string;
  warning: string;
  indication: string;
  interaction: string;
  onset: string;
  duration: string;
  note: string;
  medClass: string;
  action: string;

  constructor(model: Medication) {
    super(model, model.name, model.departmentID);
    this.TAG = 'MedicationItem';
    // console.log('MEDICATION:', model.name, model.rxNormCode);
    this.rxNormCode = model.rxNormCode;
    this.subItems = [];
    this.oldConcentrations = model.concentration ? model.concentration : [];
    this.routes = model.route ? model.route : [];
    this.rangeLow = model.rangeLow ? model.rangeLow : 0;
    this.rangeHigh = model.rangeHigh ? model.rangeHigh : globals.MAX_VALUE;

    this.protocolOptions = model.protocolOptions ? model.protocolOptions : [];

    this.contraindication = model.contraindication
      ? model.contraindication
      : '';
    this.warning = model.warning ? model.warning : '';
    this.indication = model.indication ? model.indication : '';
    this.interaction = model.interaction ? model.interaction : '';
    this.onset = model.onset ? model.onset : '';
    this.duration = model.duration ? model.duration : '';
    this.note = model.note ? model.note : '';
    this.medClass = model.medClass ? model.medClass : '';
    this.action = model.action ? model.action : '';

    if ((model as any).Concentrations != null) {
      this.concentrations = (model as any).Concentrations.items;
    }
  }

  // constructor(name: string){
  //     this.uid                = "1234-567-890";
  //     this.depID              = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.name               = name;
  //     this.dbMedication       = null;
  //     this.subItems = [];
  //     this.concentrations     = [{
  //         firstAmnt: 10.0,
  //         firstUnit: "mg",
  //         secAmnt: 1,
  //         secUnit: "mL"
  //     }]

  // }

  /**
   * Return nothing for the parent
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  getAmount(weight: PatientWeight): string {
    return '';
  }

  /**
   * Get the list of sub items for this medication
   * @returns Get the list of sub items for this medication
   */
  getMedicationSubItems(): MedicationSubItem[] {
    return this.subItems;
  }

  /**
   * Add a sub item to this medication
   * @param medicationSubItem Add a sub item to this medication
   */
  addMedicationSubItem(medicationSubItem: MedicationSubItem) {
    let find = this.subItems.find(
      (item) => isObjectEqual(item, medicationSubItem) === true
    );
    if (find) return;
    this.subItems.push(medicationSubItem);
    this.subItems.sort((a, b) => a.index - b.index);
  }

  removeMedicationSubItem(medicationSubItem: MedicationSubItem) {
    this.subItems = this.subItems.filter(
      (item) => isObjectEqual(item, medicationSubItem) === false
    );
  }

  /**
   * Get the string representation of the concentration
   * @returns The string representation of the concentration Ex. "1 mg/mL"
   */
  getConcentrations(): MedicationConcentration[] {
    return this.concentrations;
  }

  /**
   * Compare this MedicationItem to another MedicationItem first by name then by concentration
   * @param other A MedicationItem to compare to
   * @returns 0 if the two items are equal, 1 if this item is greater than the other, -1 if this item is less than the other
   */
  compareTo(other: ModelItem<any>): number {
    if (other instanceof MedicationItem) {
      let med = other as MedicationItem;
      if (this.name === med.name) {
        return this.uid === med.uid ? 0 : this.uid > med.uid ? 1 : -1;
      } else if (this.name > med.name) return 1;
      return -1;
    }
    return -1;
  }

  /**
   * Get if the medication is in range of the patient's weight
   * @param weight The patient's weight
   */
  inRange(weight: PatientWeight): boolean {
    return true;
  }

  setModifiedBy(user: User): void {
    this.modifiedBy = user;
  }

  //Create a toString function to output the data in a readable format
  toString(): string {
    return `MedicationItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
            subItems=${this.subItems.length}
        }`;
  }
}

export default MedicationItem;
