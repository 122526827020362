import React, { useEffect, useState } from 'react';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import { ResponseType, fetchPDF } from '../../../../data/AmplifyDB';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { getHashedPin } from '../../../_global/common/Encrypt';
import { toTitleCase } from '../../../_global/common/Utils';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  changeItem?: any | null;
  previousItem?: any | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

const ProtocolPDFComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  changeItem,
  previousItem,
  onChangesCount, // Add the callback prop
}) => {
  const [currentPdf, setCurrentPdf] = useState<string | null>(null);
  const [previousPdf, setPreviousPdf] = useState<string | null>(null);
  const rootUrl = window.location.origin;

  useEffect(() => {
    const fetchCurrentPdf = async () => {
      if (changeItem?.pdfUrl) {
        let result = await fetchPDF(changeItem.pdfUrl);
        if (result.type === ResponseType.Success) {
          setCurrentPdf(result.data);
        }
      }
    };

    const fetchPreviousPdf = async () => {
      if (previousItem?.pdfUrl) {
        let result = await fetchPDF(previousItem.pdfUrl);
        if (result.type === ResponseType.Success) {
          setPreviousPdf(result.data);
        }
      }
    };

    fetchCurrentPdf();
    fetchPreviousPdf();
    if (onChangesCount) {
      onChangesCount(1); // Call the callback with the changes count
    }
  }, [changeItem, previousItem]);

  const handleViewFullScreen = (item: any, pdfUrl: string | null) => {
    if (pdfUrl !== '' || pdfUrl != null) {
      localStorage.setItem('pdfURL', pdfUrl !== null ? pdfUrl : '');
      localStorage.setItem('pdfName', item?.name);
      let filterName = item?.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        pdfUrl + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );

      console.log('Opening Full Screen PDF');
    }
  };
  return (
    <div>
      <div className="doseChangesWrapper" style={{ height: '100%' }}>
        <table
          className="changesTable"
          style={{ width: '100%', height: '100%' }}
        >
          {previousPdf ? (
            <>
              <thead>
                <tr>
                  <th
                    className="changeHeader beforeChange"
                    style={{ width: '50%' }}
                  >
                    <div className="headerContent">
                      Before
                      <span
                        className="iconButton"
                        onClick={() => {
                          handleViewFullScreen(previousItem, previousPdf);
                        }}
                      >
                        <AiOutlineFullscreen
                          data-testid="isFullScreenButton"
                          className="icon"
                        />
                      </span>
                    </div>
                  </th>
                  <th
                    className="changeHeader afterChange"
                    style={{ width: '50%' }}
                  >
                    <div className="headerContent">
                      After
                      <span
                        className="iconButton"
                        onClick={() => {
                          handleViewFullScreen(changeItem, currentPdf);
                        }}
                      >
                        <AiOutlineFullscreen
                          data-testid="isFullScreenButton"
                          className="icon"
                        />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={1} style={{ height: '100%' }}>
                  <td style={{ height: '100%' }}>
                    {previousPdf ? (
                      <iframe
                        src={previousPdf}
                        title="PDF Viewer - Before"
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: '80vh' }}
                      />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </td>
                  <td style={{ height: '100%' }}>
                    {currentPdf ? (
                      <iframe
                        src={currentPdf}
                        title="PDF Viewer - After"
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: '80vh' }}
                      />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th
                    className="changeHeader afterChange"
                    style={{ textAlign: 'center' }}
                  >
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      <span
                        className="iconButton"
                        onClick={() => {
                          handleViewFullScreen(changeItem, currentPdf);
                        }}
                      >
                        <AiOutlineFullscreen
                          data-testid="isFullScreenButton"
                          className="icon"
                        />
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr key={1} style={{ height: '100%' }}>
                  <td style={{ height: '100%' }}>
                    {currentPdf ? (
                      <iframe
                        src={currentPdf}
                        title="PDF Viewer - New"
                        width="100%"
                        height="100%"
                        style={{ border: 'none', minHeight: '80vh' }}
                      />
                    ) : (
                      <div>Loading...</div>
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProtocolPDFComparison;
