import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { getObjectDifference, globals } from '../../ui/_global/common/Utils';
import { LogEvent, Acknowledge, Reviewal, UserComment } from '../../API';
import { ProgressStatus, ACKStatus } from '../../API';
import { DatabaseResponse } from '../AmplifyDB';
import DraftGroupItem from './DraftGroupItem';
import UserCommentItem from './UserCommentItem';
import { User } from '../../models';

export const cloneAcknowledgeItem = (
  item: AcknowledgeItem
): AcknowledgeItem => {
  let newItem = new AcknowledgeItem(item.model, item.owner, item.user);
  newItem.comment = item.comment;
  return newItem;
};
class AcknowledgeItem extends ModelItem<Acknowledge> {
  owner: any;
  user: User;
  timestamp: Date;
  comment: UserCommentItem | null | undefined;
  state: ACKStatus;

  constructor(review: any, owner: any, user: User) {
    super(review, 'Acknowledgement', owner.departmentID);
    this.TAG = 'AcknowledgeItem';
    this.user = user;
    this.owner = owner;
    this.state = this.getACKStatus(review.status);
    this.timestamp = new Date(review.createdAt);
    this.comment = review.Comment;
  }

  getACKStatus(status: ACKStatus | string): ACKStatus {
    switch (status) {
      case 'PENDING':
        return ACKStatus.PENDING;
      case 'APPROVED':
        return ACKStatus.APPROVED;
      case 'REJECTED':
        return ACKStatus.REJECTED;
      case 'CLOSED':
        return ACKStatus.CLOSED;
      case 'PUBLISHED':
        return ACKStatus.PUBLISHED;
      default:
        return ACKStatus.DRAFT;
    }
  }

  toString(): string {
    return `AcknowledgeItem -> {
            uid=${this.uid},
            Model=${this.model},
            Owner=${this.owner},
            User=${this.user},
            status=${this.status},
            createdAt=${this.timestamp},
            comment=${this.comment}
        }`;
  }
}

export default AcknowledgeItem;
