import React, { useMemo, useState, useEffect } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import CategoryItem from '../../../../data/model/CategoryItem';
import HighlightChanges from '../HighlightChanges';
import { jsonToTitleCase } from '../../../_global/common/Utils';
import FolderFullView from '../fullView/FolderFullView';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem | null;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}

export const compareFolderItems = (
  currentItem: CategoryItem,
  archiveItem: CategoryItem
): Change[] => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof CategoryItem)[] = ['name', 'pairedDepIDs'];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (key === 'pairedDepIDs') {
      currentValue = (currentValue as string[])?.join(', ') || '';
      archiveValue = (archiveValue as string[])?.join(', ') || '';
    }

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return changes;
};

const FolderComparison: React.FC<FolderComparisonProps> = ({
  changeItem,
  previousItem,
  onChangesCount, // Add the callback prop
}) => {
  const changes = useMemo(() => {
    const comparedChanges = compareFolderItems(
      changeItem as CategoryItem,
      previousItem as CategoryItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    console.log('comparedChanges', comparedChanges);
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);

  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    setViewMode(mode);
  };
  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem ? (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader changes">Changes</th>
                    <th className="changeHeader beforeChange">
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {changes.map((change, index) => (
                    <tr key={index}>
                      <td>
                        <b>{jsonToTitleCase(change.key)}</b>
                      </td>
                      <td>{change.archiveValue}</td>
                      <td>
                        <HighlightChanges
                          beforeText={change.archiveValue}
                          afterText={change.currentValue}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader changes">Changes</th>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        New
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {changes.map((change, index) => (
                    <tr key={index}>
                      <td>
                        <b>{jsonToTitleCase(change.key)}</b>
                      </td>
                      <td>
                        <HighlightChanges
                          beforeText={change.archiveValue}
                          afterText={change.currentValue}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FolderFullView
            focusItem={
              viewMode === 'before'
                ? (previousItem as CategoryItem)
                : (changeItem as CategoryItem)
            }
            handleBack={() => setViewMode('none')}
            viewText={viewMode === 'before' ? 'Before' : 'After'}
          />
        </>
      )}
    </div>
  );
};

export default FolderComparison;
