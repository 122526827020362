import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import Loading from '../../../../../components/Loading/Loading';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  DatabaseResponse,
  getDepartments,
  loadDatabase,
  ResponseType,
} from '../../../../../../data/AmplifyDB';
import { handleCopy } from '../../../../../_global/common/Utils';
import { addDepartmentIDToAllReferences } from '../../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { User, UserType } from '../../../../../../models';
import { ViewportList } from 'react-viewport-list';
import { Accordion } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa6';

const AddIDToModelItems = (props: any) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  const [isLoading, setIsLoading] = useState<string | null>(null);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });
  const [info, setInfo] = useState<any>({
    department: undefined as DepartmentItem | undefined,
    id: '',
  });

  const handleBack = () => {
    navigate('/actions/ids');
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
    }
  };

  const handlePerformAudit = async () => {
    console.log('Perming Add ID to all ModelItems');
    addDepartmentIDToAllReferences(info.department, info.id)
      .then((response) => {
        console.log('Response:', response);
        if (response.type === ResponseType.Success) {
          let n = response.data;
          setModalState({
            isVisible: true,
            title: 'Successfully Added ID to ' + info.department?.name,
            primaryBtnName: 'Okay',
            secondaryBtnName: 'Okay',
            primaryDescription:
              'Successfully added the ID to ' +
              n +
              ' ModelItems for ' +
              info.department?.name,
          });
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const isSaveValid = useMemo(() => {
    return info.department && info.id;
  }, [info]);

  return (
    <div className="screen-container">
      {isLoading && (
        <Loading
          type="bubbles"
          message={isLoading ? isLoading : 'Loading...'}
        />
      )}
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          if (modalState.title === 'Audit Required') {
            setModalState({ ...modalState, isVisible: false });
          } else {
            setModalState({ ...modalState, isVisible: false });
            navigate('/database');
          }
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <ProtocolHeader
        page={'Actions'}
        name={'Add Department ID to all ModelItems'}
        description={'This only adds the department ID to all ModelItems.'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCustomButton={true}
        customText="Run"
        isCustomActive={isSaveValid}
        isCancelButton={false}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
        handleCustom={() => {
          handlePerformAudit();
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <label className="ketamine-general-label">
            Department: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to add the ID to.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={departments}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              setInfo({
                ...info,
                department: option,
              });
            }}
            onClear={() => {
              setInfo({
                ...info,
                department: undefined,
              });
            }}
            placeholder="Search department..."
          />
          <label htmlFor="Name" className="ketamine-general-label">
            ID:
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            The ID to add to all the ModelItems.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              name="pin"
              data-testid="pin"
              required={true}
              value={info.id}
              onChange={(e: any) => {
                setInfo({
                  ...info,
                  id: e.target.value,
                });
              }}
              disabled={false}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        ></div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        {info.department && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {info.department && info.department.logoVerifiedUrl && (
                <img
                  className="large-dep-logo"
                  src={info.department.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              {info.department && (
                <div
                  className="large-dep-name"
                  style={{
                    marginLeft: '2rem',
                    marginRight: '6px',
                  }}
                >
                  {info.department.name}
                </div>
              )}
            </div>
            <div
              className="input-container roundBorder "
              style={{
                marginLeft: '1rem',
                marginRight: '1rem',
                marginTop: '1rem',
              }}
            >
              <div
                className="ketamine-general-label"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div style={{ marginRight: '10px' }}>ID:</div>
                <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                  {info.department.id}
                  <span>
                    {isCopied && isCopied === info.department.id ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size="1rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size="1rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(info.department.id, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddIDToModelItems;
