import React, { useState } from 'react';

import { getFormattedDate } from '../../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';

import { ModelMetaData, User } from '../../../../models';

import { Col, Row } from 'react-bootstrap';
import MedicationItem from '../../../../data/model/MedicationItem';
import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import HighlightChanges from '../HighlightChanges';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
interface FolderComparisonProps {
  focusItem: MedicationItem;
  handleBack?: () => void;
  viewText?: string;
  changes?: any;
  showHeader?: boolean;
  keepBefore?: boolean;
}

const InfusionComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
  changes,
  showHeader,
  keepBefore,
}) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          {showHeader && (
            <h5
              className="ketmine-header-text backTextLight hoverText"
              style={{ color: 'black' }}
              onClick={handleBack}
            >
              <span>
                <IoArrowBack
                  data-testid="isBackBttn"
                  size="1.15rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
              General Information
            </h5>
          )}

          {showHeader && (
            <div className="input-container roundBorder">
              <div>
                <div
                  style={{ display: 'flex', marginTop: '5px' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Modified By:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.modifiedBy
                      ? focusItem.modifiedBy.firstName +
                        ' ' +
                        focusItem.modifiedBy.lastName
                      : 'Hinckley Medical'}
                  </div>
                </div>
                {focusItem.model?.updatedAt && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Last Updated:</div>
                    <div style={{ fontWeight: '500' }}>
                      {getFormattedDate(focusItem.model.updatedAt, true)}
                    </div>
                  </div>
                )}
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Version:</div>
                  <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
                </div>
                {user.type === UserType.ADMIN &&
                  focusItem.rxNormCode != null && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>RxNorm Code:</div>
                      <div style={{ fontWeight: '500' }}>
                        {focusItem.rxNormCode}
                        <span>
                          {isCopied && isCopied === focusItem.rxNormCode ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(focusItem.rxNormCode as string, e)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                {user.type === UserType.ADMIN && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.uid}
                      <span>
                        {isCopied && isCopied === focusItem.uid ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) => handleCopy(focusItem.uid, e)}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {user.type === UserType.ADMIN &&
                  focusItem.status === ProgressStatus.DRAFT &&
                  focusItem.activeID != null && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Active ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {focusItem.activeID}
                        <span>
                          {isCopied && isCopied === focusItem.activeID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(focusItem.activeID as string, e)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {(findBeforeValue(changes, 'name') ||
            findCurrentValue(changes, 'name')) && (
            <>
              <label htmlFor="Name" className="ketamine-general-label">
                Name <span className="required-field">*</span>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'name')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'name')}
                    afterText={findCurrentValue(changes, 'name')}
                  />
                )}
              </div>
              <div className="input-border"></div>
            </>
          )}

          {(findBeforeValue(changes, 'concentrations') ||
            findCurrentValue(changes, 'concentrations')) && (
            <>
              {' '}
              <label htmlFor="Concentration" className="ketamine-general-label">
                Concentration <span className="required-field">*</span>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'concentrations')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'concentrations')}
                    afterText={findCurrentValue(changes, 'concentrations')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'routes') ||
            findCurrentValue(changes, 'routes')) && (
            <>
              <label htmlFor="Concentration" className="ketamine-general-label">
                Routes <span className="required-field">*</span>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'routes')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'routes')}
                    afterText={findCurrentValue(changes, 'routes')}
                  />
                )}
              </div>
            </>
          )}

          <Row>
            {(findBeforeValue(changes, 'rangeLow') ||
              findCurrentValue(changes, 'rangeLow')) && (
              <Col sm={6}>
                <label htmlFor="rangeLow" className="ketamine-general-label">
                  Range Low (kg)
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                  }}
                >
                  {keepBefore ? (
                    <div>{findBeforeValue(changes, 'rangeLow')}</div>
                  ) : (
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'rangeLow')}
                      afterText={findCurrentValue(changes, 'rangeLow')}
                    />
                  )}
                </div>
              </Col>
            )}
            {(findBeforeValue(changes, 'rangeHigh') ||
              findCurrentValue(changes, 'rangeHigh')) && (
              <Col sm={6}>
                <label htmlFor="rangeHigh" className="ketamine-general-label">
                  Range High (kg)
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                  }}
                >
                  {keepBefore ? (
                    <div>{findBeforeValue(changes, 'rangeHigh')}</div>
                  ) : (
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'rangeHigh')}
                      afterText={findCurrentValue(changes, 'rangeHigh')}
                    />
                  )}
                </div>
              </Col>
            )}
          </Row>
          {(findBeforeValue(changes, 'contraindication') ||
            findCurrentValue(changes, 'contraindication')) && (
            <>
              <label
                htmlFor="Contraindication"
                className="ketamine-general-label"
              >
                Contraindication
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'contraindication')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'contraindication')}
                    afterText={findCurrentValue(changes, 'contraindication')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'warning') ||
            findCurrentValue(changes, 'warning')) && (
            <>
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'warning')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'warning')}
                    afterText={findCurrentValue(changes, 'warning')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'indication') ||
            findCurrentValue(changes, 'indication')) && (
            <>
              {' '}
              <label htmlFor="Indication" className="ketamine-general-label">
                Indication
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'indication')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'indication')}
                    afterText={findCurrentValue(changes, 'indication')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'interaction') ||
            findCurrentValue(changes, 'interaction')) && (
            <>
              {' '}
              <label
                htmlFor="DrugInteraction"
                className="ketamine-general-label"
              >
                Drug Interaction
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'interaction')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'interaction')}
                    afterText={findCurrentValue(changes, 'interaction')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'onset') ||
            findCurrentValue(changes, 'onset')) && (
            <>
              <label htmlFor="Onset" className="ketamine-general-label">
                Onset
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'onset')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'onset')}
                    afterText={findCurrentValue(changes, 'onset')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'duration') ||
            findCurrentValue(changes, 'duration')) && (
            <>
              {' '}
              <label htmlFor="Duration" className="ketamine-general-label">
                Duration
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'duration')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'duration')}
                    afterText={findCurrentValue(changes, 'duration')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'note') ||
            findCurrentValue(changes, 'note')) && (
            <>
              <label htmlFor="Note" className="ketamine-general-label">
                Note
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'note')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'note')}
                    afterText={findCurrentValue(changes, 'note')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'medClass') ||
            findCurrentValue(changes, 'medClass')) && (
            <>
              <label htmlFor="Class" className="ketamine-general-label">
                Class
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'medClass')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'medClass')}
                    afterText={findCurrentValue(changes, 'medClass')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'action') ||
            findCurrentValue(changes, 'action')) && (
            <>
              {' '}
              <label htmlFor="Action" className="ketamine-general-label">
                Action
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'action')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'action')}
                    afterText={findCurrentValue(changes, 'action')}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfusionComparisonView;
