import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { DataStore } from 'aws-amplify';
import {
  alpha,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchBar from '../../../../components/Search/SearchBar';
import { visuallyHidden } from '@mui/utils';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { Col, Row } from 'react-bootstrap';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import {
  executeQuery,
  fetchUserEmail,
  getDepartments,
  ResponseType,
} from '../../../../../data/AmplifyDB';
import * as XLSX from 'xlsx';
import { compareVersions } from '../../../../_global/common/Utils';
import { listUsers } from '../../../../../graphql/queries';
import { User, UserType } from '../../../../../API';

type Order = 'asc' | 'desc';

type UserData = {
  id: string;
  firstName: string;
  lastName: string;
  cognitoID: string;
  oneDoseVersion: string;
  department: DepartmentItem;
  email: string;
  type: UserType;
};

interface HeadCell {
  id: keyof UserData;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'Full Name',
  },
  { id: 'cognitoID', numeric: false, disablePadding: false, label: 'Username' },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'oneDoseVersion',
    numeric: false,
    disablePadding: false,
    label: 'OneDose Version',
  },
  {
    id: 'department',
    numeric: false,
    disablePadding: false,
    label: 'Department',
  },
];
// Helper function to sort data
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}
function versionComparator(a: string, b: string) {
  if (compareVersions(a, b) === -1) return -1;
  if (compareVersions(a, b) === 1) return 1;
  return 0;
}
// Function to get comparator based on order
function getComparator<Key extends keyof UserData>(
  order: any,
  orderBy: any
): (a: UserData, b: UserData) => number {
  if (orderBy === 'oneDoseVersion') {
    return order === 'desc'
      ? (a, b) => versionComparator(a.oneDoseVersion, b.oneDoseVersion)
      : (a, b) => -versionComparator(a.oneDoseVersion, b.oneDoseVersion);
  }
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const ExportUsers = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof UserData>('lastName');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dense, setDense] = useState(false);
  const [search, setSearch] = useState<string>('');
  const [allDepartments, setAllDepartments] = useState<DepartmentItem[]>([]);
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);

  useEffect(() => {
    const loadData = async () => {
      let deps: DepartmentItem[] = [];
      const response = await getDepartments();
      if (response.type === ResponseType.Success) {
        deps = response.data;
        setAllDepartments(response.data);
      }

      const userList = await executeQuery(
        listUsers,
        {
          filter: {
            status: { ne: 'DELETED' },
          },
        },
        20000,
        true,
        -1
      );
      let emails: string[] = [];
      let promises: any[] = [];
      let i = 0;
      for (let user of userList) {
        promises.push(fetchUserEmail(user.cognitoID));
        if (promises.length > 10) {
          console.log('Users:', emails.length + '/' + userList.length);
          let useremails = await Promise.all(promises);
          emails = emails.concat(useremails);
          promises = [];
        }
      }
      console.log('Users:', userList.length);
      let useremails = await Promise.all(promises);
      emails = emails.concat(useremails);
      console.log('Emails:', emails.length, emails[0]);
      let users: UserData[] = userList.map((user: User, index: number) => {
        return {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          cognitoID: user.cognitoID,
          oneDoseVersion: user.oneDoseVersion,
          department: deps.find(
            (item: DepartmentItem) => item.id === user.departmentID
          ),
          email: emails[index],
          type: user.type,
        } as UserData;
      });
      setAllUsers(users);
      // let users: UserData[] = userList.map(async (user) => {
      //   return {
      //     id: user.id,
      //     firstName: user.firstName,
      //     lastName: user.lastName,
      //     cognitoID: user.cognitoID,
      //     oneDoseVersion: { version: user.oneDoseVersion },
      //     department: deps.find(
      //       (item: DepartmentItem) => item.id === user.departmentID
      //     ),
      //     email: '',
      //   } as UserData;
      // });
      // setAllUsers(users);
    };
    loadData();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof UserData
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = allUsers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const filteredUsers = useMemo(() => {
    return allUsers.filter((user) =>
      [
        user.firstName,
        user.lastName,
        user.email,
        user.cognitoID,
        user.department?.name,
        user.department?.id,
      ].some((field) => field?.toLowerCase().includes(search.toLowerCase()))
    );
  }, [allUsers, search]);

  // Use getComparator to sort the filteredUsers based on order and orderBy
  const sortedUsers = useMemo(() => {
    return filteredUsers.slice().sort(getComparator(order, orderBy));
  }, [filteredUsers, order, orderBy]);

  const visibleRows = sortedUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const handleBack = () => {
    navigate(`/actions/export`);
  };

  const downloadUserExcelFile = (userData: UserData[]) => {
    try {
      if (!userData || userData.length === 0) {
        alert('Error: User data is empty or undefined');
        return;
      }

      // Prepare Excel data array
      const excelData: any[] = [];

      // Iterate through each user and add rows to the Excel data
      userData.forEach((user) => {
        excelData.push({
          'User ID': user.id || '',
          'First Name': user.firstName || '',
          'Last Name': user.lastName || '',
          Username: user.cognitoID || '',
          'OneDose Version': user.oneDoseVersion || '',
          Department: user.department?.name || '',
          Email: user.email || '',
        });
      });

      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      const sheetName = 'User Data';

      // Append the sheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

      // Convert the workbook to a Blob and download it as an Excel file
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });
      const excelUrl = URL.createObjectURL(blob);
      const excelLink = document.createElement('a');
      excelLink.href = excelUrl;

      // Generate file name with timestamp
      const date = new Date();
      const formattedDate = date
        .toISOString()
        .replace(/[:\-T]/g, '_')
        .split('.')[0]; // Format: YYYY_MM_DD_HH_MM_SS
      const fileName = `OneDose_User_Data_${formattedDate}.xlsx`;

      // Set file name and trigger download
      excelLink.download = fileName;
      excelLink.click();

      // Cleanup
      URL.revokeObjectURL(excelUrl);
    } catch (e) {
      console.error('Error generating Excel file:', e);
    }
  };

  const permormExport = () => {
    if (selected.length > 0) {
      const selectedUsers = allUsers.filter((user) =>
        selected.includes(user.id)
      );
      downloadUserExcelFile(selectedUsers);
    } else {
      downloadUserExcelFile(allUsers);
    }
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Export Actions'}
        name={'Export Users'}
        description={
          'Engage export protocol: Activate user data extraction! From usernames to emails, initiate a full data dump into your preferred format—because sometimes, you just need to flex those data-migration muscles like a true sysadmin.'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        exportActive={allUsers.length > 0}
        isExportButton={true}
        handleExport={() => {
          permormExport();
        }}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Row>
        <Col sm={6}>
          <SearchBar
            containerStyle={{ width: '100%' }}
            value={search}
            onChange={(searchTerm: string) => setSearch(searchTerm)}
            placeholder={'Search Users...'}
          />
        </Col>
      </Row>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
              ...(selected.length > 0 && {
                bgcolor: (theme) =>
                  alpha(
                    theme.palette.primary.main,
                    theme.palette.action.activatedOpacity
                  ),
              }),
            }}
          >
            {selected.length > 0 ? (
              <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length}/{allUsers.length} Selected
              </Typography>
            ) : (
              <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
              >
                OneDose Users: {allUsers.length}
              </Typography>
            )}
          </Toolbar>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        selected.length > 0 && selected.length < allUsers.length
                      }
                      checked={
                        allUsers.length > 0 &&
                        selected.length === allUsers.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{ 'aria-label': 'select all users' }}
                    />
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id as any}
                      align={headCell.numeric ? 'right' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'normal'}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={(event) =>
                          handleRequestSort(event, headCell.id)
                        }
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows.map((row: UserData, index: number) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.firstName} {row.lastName}
                      </TableCell>
                      <TableCell>{row.cognitoID}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.oneDoseVersion}</TableCell>
                      <TableCell>{row.department?.name}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
};

export default ExportUsers;

// import React, { useEffect, useMemo, useState } from 'react';
// import { Button, Form } from 'react-bootstrap';
// import { Col, Row } from 'react-grid-system';
// import { BiCopy } from 'react-icons/bi';
// import { useSelector } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';
// import SearchableDropdown from '../../../../components/SearchableDropdown';
// import ProtocolItem from '../../../../../data/model/ProtocolItem';
// import DepartmentItem from '../../../../../data/model/DepartmentItem';
// import VitalItem from '../../../../../data/model/VitalItem';
// import MedicationItem from '../../../../../data/model/MedicationItem';
// import {
//   ResponseType,
//   fetchCategoriesWithProtocols,
//   fetchMedications,
//   getDepartments,
// } from '../../../../../data/AmplifyDB';
// import {
//   copyMedicationFromDeptoDep,
//   replaceOldIDsToNew,
//   replaceOldIdToNewProtocol,
// } from '../../../../../data/AmplifyActions';
// import ProtocolHeader from '../../../protocol/ProtocolHeader';
// import { globals } from '../../../../_global/common/Utils';
// import { User } from '../../../../../models';
// import { DataStore } from 'aws-amplify';
// import {
//   alpha,
//   Box,
//   Checkbox,
//   FormControlLabel,
//   IconButton,
//   Paper,
//   Switch,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
//   TableSortLabel,
//   Toolbar,
//   Tooltip,
//   Typography,
// } from '@mui/material';
// import { TableVirtuoso, TableComponents } from 'react-virtuoso';
// import SearchBar from '../../../../components/Search/SearchBar';
// import { check } from 'prettier';
// import { RepoDeleteIcon } from '@primer/styled-octicons';
// import { visuallyHidden } from '@mui/utils';

// function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// type Order = 'asc' | 'desc';

// function getComparator<Key extends keyof any>(
//   order: Order,
//   orderBy: Key
// ): (
//   a: { [key in Key]: number | string },
//   b: { [key in Key]: number | string }
// ) => number {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// interface HeadCell {
//   disablePadding: boolean;
//   id: keyof User;
//   label: string;
//   numeric: boolean;
// }

// const headCells: readonly HeadCell[] = [
//   {
//     id: 'firstName',
//     numeric: false,
//     disablePadding: true,
//     label: 'First Name',
//   },
//   {
//     numeric: false,
//     disablePadding: false,
//     label: 'Last Name',
//     id: 'lastName',
//   },
//   {
//     id: 'cognitoID',
//     numeric: true,
//     disablePadding: false,
//     label: 'Username',
//   },
//   {
//     id: 'oneDoseVersion',
//     numeric: true,
//     disablePadding: false,
//     label: 'OneDose Version',
//   },
//   {
//     id: 'departmentID',
//     numeric: true,
//     disablePadding: false,
//     label: 'Department',
//   },
// ];

// interface EnhancedTableProps {
//   numSelected: number;
//   onRequestSort: (
//     event: React.MouseEvent<unknown>,
//     property: keyof User
//   ) => void;
//   onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
//   order: Order;
//   orderBy: string;
//   rowCount: number;
// }

// /* 09-27-23 Arul: Created Component for Protocol Screen*/
// const ExportUsers = (props: any) => {
//   const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [allUsers, setAllUsers] = useState<User[]>([]);
// const [allDepartments, setAllDepartments] = useState<DepartmentItem[]>([]);
// const [departments, setDepartments] = useState<DepartmentItem[]>([]);
//   const [filters, setFilters] = useState<string[]>([]);
//   const [search, setSearch] = useState<string>('');
//   const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

//   const filteredUsers = useMemo(() => {
//     let list = [...allUsers];
//     if (departments.length > 0) {
//       list = list.filter((item: User) => {
//         return (
//           departments.some(
//             (department: DepartmentItem) => department.id === item.departmentID
//           ) ||
//           departments.some((department: DepartmentItem) =>
//             item.pairedDepIDs?.includes(department.id)
//           )
//         );
//       });
//     }
//     if (filters.length > 0) {
//       // list = list.filter((item: User) => {
//       //   return filters.some((filter: string) => item.name.includes(filter));
//       // });
//     }
//     if (search.length > 0) {
//       list = list.filter((item: User) => {
//         return (
//           item.firstName.includes(search.toLocaleLowerCase()) ||
//           item.lastName.includes(search.toLocaleLowerCase()) ||
//           item.cognitoID.includes(search.toLocaleLowerCase()) ||
//           item.id.includes(search.toLocaleLowerCase())
//         );
//       });
//     }
//     return list;
//   }, [allUsers, filters, departments, search]);

//   const filteredDepartments = useMemo(() => {
//     return allDepartments.filter(
//       (item: DepartmentItem) =>
//         !departments.some(
//           (department: DepartmentItem) => department.id === item.id
//         )
//     );
//   }, [allDepartments, departments]);

// const loadDepartmentData = async () => {
//   const response = await getDepartments();
//   if (response.type === ResponseType.Success)
//     setAllDepartments(response.data);

//   const userList = await DataStore.query(User);
//   setAllUsers(userList);
// };

//   const loadData = async (
//     department: DepartmentItem,
//     isFrom: boolean
//   ): Promise<VitalItem[] | null> => {
//     const response = await fetchMedications(department);
//     const response2 = await fetchCategoriesWithProtocols(department);
//     if (
//       response.type === ResponseType.Success &&
//       response2.type === ResponseType.Success
//     ) {
//       let medication = response.data;
//       let protocols = response2.data[1];
//       /* Filter out the protocols that are not vital */
//       if (isFrom)
//         protocols = protocols.filter((item: ProtocolItem) => {
//           let meds = item.getModel().medicationIDs;
//           let drips = item.getModel().dripIDs;
//           if (meds && drips) return meds.length > 0 || drips.length > 0;
//           return false;
//         });
//       return [medication, protocols];
//     }
//     return null;
//   };

//   useEffect(() => {
//     loadDepartmentData();
//   }, []);

// const handleBack = () => {
//   navigate(`/actions/export`);
// };

//   // const VirtuosoTableComponents: TableComponents<User> = {
//   //   Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
//   //     <TableContainer component={Paper} {...props} ref={ref} />
//   //   )),
//   //   Table: (props) => (
//   //     <Table
//   //       {...props}
//   //       sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}
//   //     />
//   //   ),
//   //   TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
//   //     <TableHead {...props} ref={ref} />
//   //   )),
//   //   TableRow,
//   //   TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
//   //     <TableBody {...props} ref={ref} />
//   //   )),
//   // };

//   // const columns: ColumnData[] = [
//   //   {
//   //     width: 40,
//   //     label: '',
//   //     dataKey: 'id',
//   //   },
//   //   {
//   //     width: 200,
//   //     label: 'First Name',
//   //     dataKey: 'firstName',
//   //   },
//   //   {
//   //     width: 120,
//   //     label: 'Last Name',
//   //     dataKey: 'lastName',
//   //   },
//   //   {
//   //     width: 120,
//   //     label: 'Username',
//   //     dataKey: 'cognitoID',
//   //   },
//   //   {
//   //     width: 120,
//   //     label: 'OneDose Version',
//   //     dataKey: 'oneDoseVersion',
//   //   },
//   //   {
//   //     width: 120,
//   //     label: 'Department',
//   //     dataKey: 'departmentID',
//   //   },
//   // ];

//   // function fixedHeaderContent() {
//   //   return (
//   //     <TableRow>
//   //       {columns.map((column) => (
//   //         <TableCell
//   //           key={column.dataKey as any}
//   //           variant="head"
//   //           align={column.numeric || false ? 'right' : 'left'}
//   //           style={{ width: column.width, fontWeight: 'bold' }}
//   //           sx={{ backgroundColor: '#E0EADD' }}
//   //         >
//   //           {column.label}
//   //         </TableCell>
//   //       ))}
//   //     </TableRow>
//   //   );
//   // }

//   // function rowContent(_index: number, row: any) {
//   //   let department = allDepartments.find(
//   //     (item: DepartmentItem) => item.id === row.departmentID
//   //   );
//   //   let checked = selectedUsers.includes(row.id);
//   //   const checkRef = React.createRef<HTMLButtonElement>();
//   //   return (
//   //     <React.Fragment>
//   //       {columns.map((column) => (
//   //         <>
//   //           <TableCell
//   //             style={{
//   //               padding: '0px 16px',
//   //               margin: 0,
//   //             }}
//   //             key={column.dataKey as any}
//   //             align={column.numeric || false ? 'right' : 'left'}
//   //           >
//   //             {column.dataKey === 'id' ? (
//   //               <Checkbox
//   //                 ref={checkRef}
//   //                 value={checked}
//   //                 onChange={(e) => {
//   //                   // if (e.target.checked) {
//   //                   //   setSelectedUsers([...selectedUsers, row.id]);
//   //                   // } else {
//   //                   //   setSelectedUsers(
//   //                   //     selectedUsers.filter((item) => item !== row.id)
//   //                   //   );
//   //                   // }
//   //                 }}
//   //                 size="small"
//   //                 style={{ margin: 0 }}
//   //                 sx={{
//   //                   color: '#00534C',
//   //                   '&.Mui-checked': {
//   //                     color: '#00534C',
//   //                   },
//   //                 }}
//   //               />
//   //             ) : (
//   //               <div
//   //                 onClick={() => {
//   //                   console.log('clicked', checkRef.current);
//   //                   checkRef?.current?.click();
//   //                 }}
//   //               >
//   //                 {column.dataKey === 'departmentID'
//   //                   ? department?.name
//   //                   : row[column.dataKey]}
//   //               </div>
//   //             )}
//   //           </TableCell>
//   //         </>
//   //       ))}
//   //     </React.Fragment>
//   //   );
//   // }

//   function EnhancedTableHead(props: EnhancedTableProps) {
//     const {
//       onSelectAllClick,
//       order,
//       orderBy,
//       numSelected,
//       rowCount,
//       onRequestSort,
//     } = props;
//     const createSortHandler =
//       (property: keyof User) => (event: React.MouseEvent<unknown>) => {
//         onRequestSort(event, property);
//       };

//     return (
//       <TableHead>
//         <TableRow>
//           <TableCell padding="checkbox">
//             <Checkbox
//               color="primary"
//               indeterminate={numSelected > 0 && numSelected < rowCount}
//               checked={rowCount > 0 && numSelected === rowCount}
//               onChange={onSelectAllClick}
//               inputProps={{
//                 'aria-label': 'select all desserts',
//               }}
//             />
//           </TableCell>
//           {headCells.map((headCell: any) => (
//             <TableCell
//               key={headCell.id}
//               align={headCell.numeric ? 'right' : 'left'}
//               padding={headCell.disablePadding ? 'none' : 'normal'}
//               sortDirection={orderBy === headCell.id ? order : false}
//             >
//               <TableSortLabel
//                 active={orderBy === headCell.id}
//                 direction={orderBy === headCell.id ? order : 'asc'}
//                 onClick={createSortHandler(headCell.id)}
//               >
//                 {headCell.label}
//                 {orderBy === headCell.id ? (
//                   <Box component="span" sx={visuallyHidden}>
//                     {order === 'desc'
//                       ? 'sorted descending'
//                       : 'sorted ascending'}
//                   </Box>
//                 ) : null}
//               </TableSortLabel>
//             </TableCell>
//           ))}
//         </TableRow>
//       </TableHead>
//     );
//   }
//   interface EnhancedTableToolbarProps {
//     numSelected: number;
//   }
//   function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
//     const { numSelected } = props;
//     return (
//       <Toolbar
//         sx={[
//           {
//             pl: { sm: 2 },
//             pr: { xs: 1, sm: 1 },
//           },
//           numSelected > 0 && {
//             bgcolor: (theme) =>
//               alpha(
//                 theme.palette.primary.main,
//                 theme.palette.action.activatedOpacity
//               ),
//           },
//         ]}
//       >
//         {numSelected > 0 ? (
//           <Typography
//             sx={{ flex: '1 1 100%' }}
//             color="inherit"
//             variant="subtitle1"
//             component="div"
//           >
//             {numSelected} selected
//           </Typography>
//         ) : (
//           <Typography
//             sx={{ flex: '1 1 100%' }}
//             variant="h6"
//             id="tableTitle"
//             component="div"
//           >
//             Nutrition
//           </Typography>
//         )}
//         {numSelected > 0 ? (
//           <Tooltip title="Delete">
//             <IconButton>
//               <RepoDeleteIcon />
//             </IconButton>
//           </Tooltip>
//         ) : (
//           <Tooltip title="Filter list">
//             <IconButton>{/* <FilterListIcon /> */}</IconButton>
//           </Tooltip>
//         )}
//       </Toolbar>
//     );
//   }
//   const [order, setOrder] = React.useState<Order>('asc');
//   const [orderBy, setOrderBy] = React.useState<keyof User>('lastName');
//   const [selected, setSelected] = React.useState<readonly string[]>([]);
//   const [page, setPage] = React.useState(0);
//   const [dense, setDense] = React.useState(false);
//   const [allUsersPerPage, setRowsPerPage] = React.useState(5);

//   const handleRequestSort = (
//     event: React.MouseEvent<unknown>,
//     property: keyof User
//   ) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.checked) {
//       const newSelected = allUsers.map((n) => n.id);
//       setSelected(newSelected);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
//     // const selectedIndex = selected.indexOf(id);
//     // let newSelected: readonly number[] = [];
//     // if (selectedIndex === -1) {
//     //   newSelected = newSelected.concat(selected, id);
//     // } else if (selectedIndex === 0) {
//     //   newSelected = newSelected.concat(selected.slice(1));
//     // } else if (selectedIndex === selected.length - 1) {
//     //   newSelected = newSelected.concat(selected.slice(0, -1));
//     // } else if (selectedIndex > 0) {
//     //   newSelected = newSelected.concat(
//     //     selected.slice(0, selectedIndex),
//     //     selected.slice(selectedIndex + 1)
//     //   );
//     // }
//     // setSelected(newSelected);
//   };

//   const handleChangePage = (event: unknown, newPage: number) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (
//     event: React.ChangeEvent<HTMLInputElement>
//   ) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setDense(event.target.checked);
//   };

//   // Avoid a layout jump when reaching the last page with empty allUsers.
//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * allUsersPerPage - allUsers.length) : 0;

//   const visibleRows = React.useMemo(
//     () =>
//       [...allUsers]
//         .sort(getComparator(order, orderBy))
//         .slice(
//           page * allUsersPerPage,
//           page * allUsersPerPage + allUsersPerPage
//         ),
//     [order, orderBy, page, allUsersPerPage]
//   );

//   return (
//     <div className="screen-container">
// <ProtocolHeader
//   page={'Export Actions'}
//   name={'Export Users'}
//   description={
//     'Engage export protocol: Activate user data extraction! From usernames to emails, initiate a full data dump into your preferred format—because sometimes, you just need to flex those data-migration muscles like a true sysadmin.'
//   }
//   isBackButton={true}
//   rightSideBtn={'edit'}
//   isEditButton={false}
//   isExportButton={true}
//   type={'protocol'}
//   handleCancel={handleBack}
// />
//       <Row>
//         <Col sm={6}>
//           <SearchBar
//             containerStyle={{ width: '100%' }}
//             value={search}
//             onChange={(searchTerm: string) => {
//               setSearch(searchTerm);
//             }}
//             onSubmit={(searchTerm: string) => {
//               // updateDisplayedUsers();
//             }}
//             placeholder={'Search Users...'}
//           />
//         </Col>
//         <Col sm={3}>
//           <SearchableDropdown<DepartmentItem>
//             id="searchDropdown"
//             multiSelect
//             options={filteredDepartments}
//             labelField={(option) => option.name}
//             valueField={(option) => option.name}
//             keyField={(option) => option.id}
//             onChange={(option: DepartmentItem) => {
//               setDepartments([...departments, option]);
//             }}
//             onClear={() => {
//               setDepartments([]);
//             }}
//             placeholder="Search departments..."
//           />
//         </Col>
//         <Col sm={3}></Col>
//       </Row>

//       {/* <Paper style={{ height: 400, width: '100%' }}> */}
//       {/* <div style={{ height: '65vh', width: '100%' }}>
//         <TableVirtuoso
//           data={filteredUsers}
//           components={VirtuosoTableComponents}
//           fixedHeaderContent={fixedHeaderContent}
//           itemContent={rowContent}
//         />
//       </div> */}
//       <Box sx={{ width: '100%' }}>
//         <Paper sx={{ width: '100%', mb: 2 }}>
//           <EnhancedTableToolbar numSelected={selected.length} />
//           <TableContainer>
//             <Table
//               sx={{ minWidth: 750 }}
//               aria-labelledby="tableTitle"
//               size={dense ? 'small' : 'medium'}
//             >
//               <EnhancedTableHead
//                 numSelected={selected.length}
//                 order={order}
//                 orderBy={orderBy}
//                 onSelectAllClick={handleSelectAllClick}
//                 onRequestSort={handleRequestSort}
//                 rowCount={allUsers.length}
//               />
//               <TableBody>
//                 {visibleRows.map((row, index) => {
//                   const isItemSelected = selected.includes(row.id);
//                   const labelId = `enhanced-table-checkbox-${index}`;

//                   return (
//                     <TableRow
//                       hover
//                       onClick={(event) => handleClick(event, row.id)}
//                       role="checkbox"
//                       aria-checked={isItemSelected}
//                       tabIndex={-1}
//                       key={row.id}
//                       selected={isItemSelected}
//                       sx={{ cursor: 'pointer' }}
//                     >
//                       <TableCell padding="checkbox">
//                         <Checkbox
//                           color="primary"
//                           checked={isItemSelected}
//                           inputProps={{
//                             'aria-labelledby': labelId,
//                           }}
//                         />
//                       </TableCell>
//                       <TableCell
//                         component="th"
//                         id={labelId}
//                         scope="row"
//                         padding="none"
//                       >
//                         {row.firstName + ' ' + row.lastName}
//                       </TableCell>
//                       <TableCell align="right">{row.cognitoID}</TableCell>
//                       <TableCell align="right">{row.oneDoseVersion}</TableCell>
//                       <TableCell align="right">{row.departmentID}</TableCell>
//                       <TableCell align="right">
//                         {row.pairedDepIDs?.length}
//                       </TableCell>
//                     </TableRow>
//                   );
//                 })}
//                 {emptyRows > 0 && (
//                   <TableRow
//                     style={{
//                       height: (dense ? 33 : 53) * emptyRows,
//                     }}
//                   >
//                     <TableCell colSpan={6} />
//                   </TableRow>
//                 )}
//               </TableBody>
//             </Table>
//           </TableContainer>
//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={allUsers.length}
//             allUsersPerPage={allUsersPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Paper>
//         <FormControlLabel
//           control={<Switch checked={dense} onChange={handleChangeDense} />}
//           label="Dense padding"
//         />
//       </Box>
//       {/* </Paper> */}
//     </div>
//   );
// };

// export default ExportUsers;
