import PatientTheme from '../theme/PatientTheme';
import { roundToDec } from './Utils'; // Assuming you have a Utils.ts file with the roundToDec function

/**
 * Gets the age from the weight (Lbs.) in the seek bar format (0-28)
 *      - >0-5kg        : Neonate
 *      - 5-10kg        : 1-11 months
 *      - 10-20kg       : 1-5 years
 *      - 20-56kg       : 5-14 years
 *      - 56-69.5kg     : Sm. Adult
 *      - 69.5-99.5kg   : Adult
 *      - >99.5kg       : Lg. Adult
 * @param weight The weight of the patient in Lbs.
 * @returns The age of the patient in the seek bar format (0-28)
 */
export const getAgeFromWeight = (weight: number): number => {
  weight = roundToDec(weight / 2.205, 1);
  if (weight < 5.0)
    return -1; //Neonate
  else if (weight <= 10.0)
    return Math.round(weight * 2 - 9) - 1; //Age < 12 months: Weight (kg) = (age in months + 9)/2
  else if (weight <= 20.0)
    return Math.round(weight / 2 - 5) - 1 + 11; //Age 1-5 years  : Weight (kg) = 2 * (age in years + 5)
  else if (weight <= 56.0)
    return Math.round(weight / 4) - 1 + 11; //Age 5-14 years : Weight (kg) = 4 * age in years
  else if (weight <= 69.5) return 26;
  else if (weight <= 99.5) return 27;
  else return 28;
};

export const getWeightFromAge = (age: number): number => {
  if (age < 0) return 0;
  else if (age < 11) return (age + 1 + 9) / 2;
  else if (age < 16) return (age - 10 + 5) * 2;
  else if (age < 26) return (age - 10) * 4;
  else if (age == 26) return 50;
  else if (age == 27) return 70;
  else return 100;
};

export const getAgeFromSeekBar = (value: number): string => {
  if (value === -1) return 'Neonate';
  else if (value < 11) return `${value + 1} Mo`;
  else if (value < 26) return `${value - 10} Yr`;
  else if (value == 26) return 'Sm. Adult';
  else if (value == 27) return 'Adult';
  else return 'Lg. Adult';
};

class PatientWeight {
  private theme: PatientTheme;
  private _isEstimate: boolean;
  private weightLbs: number;
  private weightKg: number;
  private age: number;
  private _isSelected: boolean;

  /**
   * Creates a new PatientWeight object
   * @param weight The weight of the patient in Lbs.
   * @param isEstimate Whether or not the weight is an estimate
   */
  constructor(weight: number | null | undefined, isEstimate: boolean) {
    if (weight == null || weight === undefined) this.weightLbs = 0;
    else this.weightLbs = weight;
    this.weightKg = this.weightLbs / 2.205;
    this._isEstimate = isEstimate;
    this._isSelected = false;
    this.theme = new PatientTheme(this.weightKg);
    this.age = getAgeFromWeight(this.weightLbs);
  }

  equals(obj: any): boolean {
    return (
      obj instanceof PatientWeight &&
      this.weightLbs === obj.weightLbs &&
      this.isEstimate === obj.isEstimate
    );
  }

  isEstimate(): boolean {
    return this._isEstimate;
  }

  isSelected(): boolean {
    return this._isSelected;
  }

  getColorTheme() {
    return this.theme;
  }

  getWeightLbs(): number {
    return this.weightLbs;
  }

  getWeightKg(): number {
    return this.weightKg;
  }

  getAge(): number {
    return this.age;
  }

  getAgeString(): string {
    if (this.age === -1) return 'Neonate';
    else if (this.age < 11) return `${this.age + 1} Mo`;
    else if (this.age < 26) return `${this.age - 10} Yr`;
    else if (this.age == 26) return 'Sm. Adult';
    else if (this.age == 27) return 'Adult';
    else return 'Lg. Adult';
  }

  getRoundedWeight(dec: number, isKg: boolean): number {
    return roundToDec(isKg ? this.weightKg : this.weightLbs, dec);
  }

  /**
   * Sets the weight of the patient in Lbs.
   * @param weight The weight of the patient in Lbs.
   */
  setWeight(weight: number): void {
    this.weightLbs = weight;
    this.weightKg = weight / 2.205;
    this.theme = new PatientTheme(weight);
    this.age = getAgeFromWeight(weight);
  }

  setSelected(selected: boolean): void {
    this._isSelected = selected;
  }

  isReverseColor(): boolean {
    return false;
  }

  toString(): string {
    return `PatientWeight -> {
            isEstimate=${this._isEstimate}, 
            weight=${this.weightLbs} lbs (${roundToDec(this.weightKg, 1)} kg),
            age=${this.age},
            theme='',
        }`;
  }
}

export default PatientWeight;
