import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import MedicationItem from '../../../data/model/MedicationItem';
import './DeviceMockup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faFileLines,
  faGear,
  faHouse,
  faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
import ProtocolItem from '../../../data/model/ProtocolItem';

import { MedicationMockup } from './MedicationMockup';
import ElectricalItem from '../../../data/model/ElectricalItem';
import InfusionItem from '../../../data/model/InfusionItem';
import { ElectricalMockup } from './ElectricalMockup';
import { InfusionMockup } from './InfusionMockup';
import { useState } from 'react';
import PatientWeight from '../../_global/common/PatientWeight';
import { InputText } from 'primereact/inputtext';

interface DeviceMockupProps {
  parentModel: MedicationItem | ElectricalItem | InfusionItem;
  formik: any;
  protocol: ProtocolItem;
}
export const DeviceMockup: React.FC<DeviceMockupProps> = (props) => {
  const { parentModel, formik, protocol } = props;
  console.log('DeviceMockup -> protocol', props);
  const [weightValue, setWeightValue] = useState('20');
  const { height, width } = window.screen;

  const handleWeightChange = (event: any) => {
    const value = event.target.value;

    // Allow empty input
    if (value === '') {
      setWeightValue(''); // Allow clearing the input
      return;
    }

    const parsedValue = parseInt(value, 10);

    // Validate the input and set the weight value accordingly
    if (!isNaN(parsedValue)) {
      if (parsedValue < 0) {
        setWeightValue('0'); // Reset to '0' if less than 0
      } else if (parsedValue > 999) {
        setWeightValue('999'); // Cap the value to '999' if it exceeds the max
      } else {
        setWeightValue(value); // Accept valid numbers
      }
    }
  };

  return (
    <div className="device-mockup-container">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '300px',
          margin: '10px auto',
        }}
      >
        <label
          htmlFor="weight-input"
          style={{
            color: '#00534C',
            marginRight: '10px',
            whiteSpace: 'nowrap',
          }}
        >
          Patient Weight: (kg)
        </label>
        <InputText
          id="weight-input"
          type="number"
          value={weightValue}
          onChange={handleWeightChange}
          placeholder="Enter weight"
          style={{
            fontSize: '16px',
            border: '2px solid #00534C',
            borderRadius: '4px',
          }}
          max={999}
          min={0}
        />
      </div>

      <DeviceFrameset
        device="iPhone X"
        height={height * 0.675}
        width={height * 0.325}
      >
        {/* Top tab view */}
        <div className="device-mockup-title">
          {protocol ? <div>{protocol?.name}</div> : <div>...</div>}
        </div>

        {/* Main tab view */}
        {(parentModel instanceof MedicationItem ||
          parentModel.TAG === 'MedicationItem') && (
          <MedicationMockup
            parentModel={parentModel as MedicationItem}
            formik={formik}
            protocol={protocol}
            weightValue={
              weightValue === '' ? 0 : parseInt(weightValue, 10) * 2.205 // Convert to lbs
            }
          />
        )}
        {(parentModel instanceof InfusionItem ||
          parentModel.TAG === 'InfusionItem') && (
          <InfusionMockup
            parentModel={parentModel as InfusionItem}
            formik={formik}
            protocol={protocol}
            weightValue={
              weightValue === '' ? 0 : parseInt(weightValue, 10) * 2.205 // Convert to lbs
            }
          />
        )}

        {(parentModel instanceof ElectricalItem ||
          parentModel.TAG === 'ElectricalItem') && (
          <ElectricalMockup
            parentModel={parentModel as ElectricalItem}
            formik={formik}
            protocol={protocol}
            weightValue={
              weightValue === '' ? 0 : parseInt(weightValue, 10) * 2.205 // Convert to lbs
            }
          />
        )}

        {/* Bottom tab view */}
        <div
          style={{
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
          }}
        >
          <div
            id="bottom-tab-view-2"
            style={{
              display: 'flex',
              border: '1px solid #d9d9d9',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '5px',
              marginTop: '10px',
              backgroundColor: '#F3F7F2',
            }}
          >
            {/* Weight Frame */}
            <div
              style={{
                backgroundColor: '#9e9e9e',
                padding: '5px',
                width: '25%',
                borderRadius: '10px',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              <div>0 kg</div>
              <div>0 lbs</div>
            </div>

            {/* Device title and status section */}
            <div
              style={{
                margin: '10px 5px',
                width: '55%',
                alignItems: 'center',
              }}
            >
              {/* Device title */}
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                No Patient
              </div>

              {/* Status section */}
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    paddingRight: '10px',
                    fontWeight: 'bold',
                  }}
                >
                  Status:
                </div>
                <div
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                    borderRadius: '20px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    display: 'inline-flex',
                    alignItems: 'center', // Vertically center content
                    padding: '3px 12px 3px 12px', // Add padding to ensure space inside the div
                  }}
                >
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: 'red',
                      display: 'inline-block',
                      marginRight: '8px',
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: '10px',
                      color: 'black',
                    }}
                  >
                    Not Connected
                  </div>
                </div>
              </div>
            </div>

            {/* Zero button */}
            <div
              style={{
                textAlign: 'right',
                width: '20%',
              }}
            >
              <div
                style={{
                  backgroundColor: '#808080',
                  color: 'white',
                  textAlign: 'center',
                  padding: '10px 20px 10px 20px',
                  borderRadius: '15px',
                }}
              >
                Zero
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '20px',
              backgroundColor: '#F3F7F2',
            }}
          >
            <FontAwesomeIcon
              icon={faRectangleList}
              color={'gray'}
              size={'lg'}
            />
            <FontAwesomeIcon icon={faFileLines} color={'gray'} size={'lg'} />
            <FontAwesomeIcon icon={faHouse} color={'#00534C'} size={'xl'} />
            <FontAwesomeIcon icon={faBell} color={'gray'} size={'lg'} />
            <FontAwesomeIcon icon={faGear} color={'gray'} size={'lg'} />
          </div>
        </div>
      </DeviceFrameset>
    </div>
  );
};
