import { useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import {
  findPairedDepartments,
  handleCopy,
  toTitleCase,
} from '../../_global/common/Utils';
import Status from '../ProgressStatus/ProgressStatus';
import EditAccessModal from './EditAccessModal';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { FaLock } from 'react-icons/fa6';
import SearchableDropdown from '../SearchableDropdown';

interface FolderProtocolModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleMove: (protocol: ProtocolItem, toFolder: CategoryItem) => void;
  department: DepartmentItem;
  item: ProtocolItem;
}
/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const MoveFolderModal = (props: FolderProtocolModalProps) => {
  const { isVisible, handleClose, handleMove, department, item } = props;
  const user: User = useSelector((state: any) => state.user);

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const categoryList = useMemo(() => {
    return database.categories.filter(
      (category: CategoryItem) => category.uid !== item.parent.uid
    );
  }, [database, item]);
  const [newCategory, setNewCategory] = useState<CategoryItem | null>(null);

  const [isCopied, setIsCopied] = useState<string | null>(null);

  /* 10-11-23 Arul: Function for handling modal submit */
  const handleSubmit = async () => {
    if (newCategory) {
      handleMove(item, newCategory);
      handleClose();
    }
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const isSaveActive = useMemo(() => {
    if (item && newCategory) return item.parent.uid !== newCategory.uid;
    return false;
  }, [item, newCategory]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: '25%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <div className="sidebarContainer">
        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Move Protocol
        </div>
        <div className="contentText">
          Move the protocol to a different folder.
        </div>
        {user.type === 'ADMIN' && (
          <div>
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              ID: {item.uid}
              <span>
                {isCopied && isCopied === item.uid ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size=".75rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(item.uid, e, setIsCopied)}
                  />
                )}
              </span>
            </div>
            {item.activeID != null && (
              <div
                className="contentText"
                style={{
                  marginTop: '10px',
                }}
              >
                Actv. ID: {item.uid}
                <span>
                  {isCopied && isCopied === item.activeID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(item.activeID as string, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        )}
        <label
          htmlFor="name"
          className={`notification-css-title`}
          style={{ fontSize: '16px' }}
        >
          Folder
          <span className="required-field ">*</span>
        </label>
        <div className="contentText">
          Search for the folder to move the protocol to.
        </div>
        <SearchableDropdown<CategoryItem>
          id="searchDropdown"
          options={categoryList}
          labelField={(option) => option.name}
          valueField={(option) => option.name}
          keyField={(option) => option.uid}
          onChange={(option: CategoryItem) => setNewCategory(option)}
          onClear={() => setNewCategory(null)}
          placeholder="Search folder..."
        />

        <div className="dialogButtonsForce">
          <Button
            data-testid="cancelBtnn"
            className="secondary-button-small-border btn-rightMargin"
            onClick={handleClose}
          >
            {' '}
            Cancel
          </Button>
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isSaveActive}
            onClick={() => {
              handleSubmit();
            }}
          >
            Move
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default MoveFolderModal;
