import React from 'react';
import ReactLoading from 'react-loading';
import './Loading.css';
import { Portal } from '@mui/material';

const Loading = (props) => {
  return (
    <Portal>
      <div className="loading-background">
        <ReactLoading className="load" type={props.type ? props.type : 'bubbles'} size={60} />
        <h3>{props.message ? props.message : 'Loading...'}</h3>
      </div>
    </Portal>
  );
};

export default Loading;
