import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import {
  IoChevronDown,
  IoChevronForward,
  IoClose,
  IoDocumentOutline,
  IoSearch,
} from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DraftConfirmModal from '../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../components/Modal/DraftSaveModal';
import ProtocolHeader from './ProtocolHeader';
import { handleUpdateEditProtocol } from '../../../store/actions';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../data/AmplifyVersion';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { ViewportList } from 'react-viewport-list';
import { MdFolderOff, MdFolderOpen } from 'react-icons/md';
import Loading from '../../components/Loading/Loading';
import ListDepartmentItems from './details/ListDepartmentItems';
import { User, UserType } from '../../../models';
import SearchBar from '../../components/Search/SearchBar';
import ReactLoading from 'react-loading';
import Status from '../../components/ProgressStatus/ProgressStatus';
import { globals, hasAdminUserAccess } from '../../_global/common/Utils';
import SearchableList from '../../components/Search/SearchableList';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const Protocol = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const user: User = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );
  const [searchList, setSearchList] = useState<ProtocolItem[]>(
    database.protocols
  );
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const [adminAccess, setAdminAccess] = useState<boolean>(
    database && hasAdminUserAccess(database.department, user)
  );

  const adminLevel = useMemo(() => {
    return database.department?.adminLevel ?? 4;
  }, [database.department]);

  useEffect(() => {
    setDatabase(dbState.departmentItem);
  }, [dbState.departmentItem]);

  useEffect(() => {
    setAdminAccess(
      (user &&
        database &&
        database.department &&
        (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN) &&
        user.depAdmins &&
        user.depAdmins.includes(database.department.id)) ??
        false
    );
  }, [user, database]);

  /* 09-28-23 Arul: handle function for filter search*/
  useEffect(() => {
    if (searchFocus) {
      let filteredList = database.protocols.filter((item: ProtocolItem) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setSearchList(filteredList);
    } else setSearchList(database.protocols);
  }, [searchQuery, searchFocus]);

  useEffect(() => {
    setSearchList(database.protocols);
  }, [database]);

  /* Hotkey detection */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (adminAccess && event.ctrlKey && event.key === 'e') {
        event.preventDefault();
        handleCheckIsDraft();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [adminAccess]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleProtocolClick = async (selectedProtocol: ProtocolItem) => {
    // await dispatch<any>(handleUpdateEditProtocol(selectedProtocol));
    const state = { selectedProtocol: selectedProtocol, editMode: false };
    const formattedString = selectedProtocol.nickname.replace(/[\s\/]/g, '-');
    navigate(`/${formattedString}/protocol-detail`, { state });
  };

  /* 10-09-23 Arul: function for handling the draft confirm modal make new button functionality*/
  const handleMakeNew = async () => {
    navigate(`/protocol/edit`);
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      else navigate(`/protocol/edit`);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const rowStyle = {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: '10fr 3fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

  return (
    <div className="screen-container">
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            navigate(`/protocol/edit`);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}
      <div className="fixedHeader fixedHeaderPad">
        <ProtocolHeader
          homeScreen={true}
          page={'Folders'}
          rightSideBtn={'edit'}
          isEditButton={
            true && adminAccess
            // user &&
            // (user.type === UserType.ADMIN || user.type === UserType.DEPT_ADMIN)
          }
          handleEdit={() => {
            handleCheckIsDraft();
          }}
          type={'protocol'}
        />
      </div>
      <Row>
        <Col sm={10}>
          {/* <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(value: string) => {
              setSearchQuery(value);
            }}
            onFocus={() => setSearchFocus(true)}
            onBlur={() => {
              delay(200).then(() => setSearchFocus(false));
            }}
            placeholder={'Search Protocols...'}
          /> */}
          <SearchableList<ProtocolItem>
            id="searchDropdown"
            options={searchList}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            iconField={(option) => {
              return <IoDocumentOutline size={16} />;
            }}
            onChange={(option: ProtocolItem) => {
              handleProtocolClick(option);
            }}
            // multiSelect={true}
            placeholder="Search Protocols..."
            containerStyle={{ width: '60%' }}
            notFoundText="No protocols found..."
            itemClassName="search-item"
          />
        </Col>
        {/* <Col sm={2}>
					<div style={{ display: "flex", justifyContent: "end" }}>
						<Button
							className="secondary-button-small"
							data-testid="update-testid"
						>
							Filter
							<span>
								<IoChevronDown size="1rem" className="filter_Icon_Pad" />
							</span>
						</Button>
					</div>
				</Col> */}
      </Row>
      <div style={{ flex: 1, display: 'flex' }}>
        {searchFocus ? (
          <div
            style={{
              flex: 1,
              border: '1px solid #ccc',
              borderRadius: '5px',
              marginBottom: '10px',
            }}
          >
            <ViewportList items={searchList}>
              {(item: ProtocolItem, index) => (
                <div
                  key={index}
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={(e) => {
                    handleProtocolClick(item);
                  }}
                >
                  <Accordion defaultActiveKey={index === 0 ? '0' : ''}>
                    <div
                      style={rowStyle}
                      className="departmentItem"
                      // onMouseEnter={() => setIsHovered(true)}
                      // onMouseLeave={() => setIsHovered(false)}
                    >
                      <h6 className="departmentItemText">
                        {item.name}
                        {item.status !== 'ACTIVE' && (
                          <Status status={item.status} />
                        )}
                      </h6>
                      <h6 className="departmentItemText">{item.sum} items</h6>
                      <IoChevronForward size="1.5rem" />
                    </div>
                  </Accordion>
                </div>
              )}
            </ViewportList>
          </div>
        ) : (
          <>
            {database &&
            database.categories &&
            database.categories.length >= 1 ? (
              <ListDepartmentItems
                value={searchQuery}
                data={database}
                handleViewProtocol={handleProtocolClick}
              />
            ) : (
              <div className="no-data-container">
                <MdFolderOff size={220} className="light-grey-icon" />
                <h4 className="light-grey-icon">
                  {database ? 'No Records Found/Created...' : 'Loading'}
                </h4>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Protocol;
