import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import VitalItem from '../../../../data/model/VitalItem';
import HighlightChanges from '../HighlightChanges';
import { jsonToTitleCase, toTitleCase } from '../../../_global/common/Utils';
import VitalFullView from '../fullView/VitalFullView';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import VitalComparisonView from '../comparisonView/VitalComparisonView';
import FullViewDialog from '../fullView/FullViewDialog';
import { VitalOption } from '../../../../models';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

export const compareVitalItems = (
  currentItem: VitalItem,
  archiveItem: VitalItem
) => {
  const changes: any[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof VitalItem)[] = ['name'];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  // Compare options array
  const currentOptions = currentItem.options || [];
  const archiveOptions = archiveItem?.options || [];

  const optionsChanges = currentOptions.reduce(
    (acc: any[], currentOption, index) => {
      const archiveOption = archiveOptions[index] || '';

      // Define the specific fields to check in options
      const fieldsToCompareInOptions: (keyof VitalOption)[] = [
        'rangeLow',
        'rangeHigh',
        'amntLow',
        'amntHigh',
      ];

      let optionChanged = false;
      const optionChanges: any = { index, currentValue: {}, archiveValue: {} };

      fieldsToCompareInOptions.forEach((field) => {
        const currentValue = currentOption[field];
        const archiveValue = archiveOption ? archiveOption[field] : '';

        if (compareValues(currentValue, archiveValue)) {
          optionChanged = true;
          optionChanges.currentValue[field] = String(currentValue);
          optionChanges.archiveValue[field] = String(archiveValue) || '';
        }
      });

      // Only add to the changes if any field has changed
      if (optionChanged) {
        acc.push(optionChanges);
      }
      return acc;
    },
    []
  );

  if (optionsChanges.length > 0) {
    changes.push({
      key: 'options',
      changes: optionsChanges,
    });
  }

  return changes;
};

const VitalComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  changeItem,
  previousItem,
  onChangesCount,
}) => {
  const changes = useMemo(() => {
    const comparedChanges = compareVitalItems(
      changeItem as VitalItem,
      previousItem as VitalItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length);
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);

  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    setViewMode(mode);
  };
  console.log('changes', changes);
  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem ? (
              <>
                {' '}
                <thead>
                  <tr>
                    <th className="changeHeader beforeChange">
                      {' '}
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                    <th className="changeHeader afterChange">
                      {' '}
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <VitalComparisonView
                        focusItem={previousItem as VitalItem}
                        showHeader={false}
                        changes={changes}
                        keepBefore={true}
                      />
                    </td>
                    <td>
                      <VitalComparisonView
                        focusItem={changeItem as VitalItem}
                        changes={changes}
                        showHeader={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <>
                {' '}
                <thead>
                  <tr>
                    <th className="changeHeader afterChange">
                      {' '}
                      <div className="headerContent">
                        New {toTitleCase(currentDraft.changeType)}
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <VitalComparisonView
                        focusItem={changeItem as VitalItem}
                        changes={changes}
                        showHeader={false}
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FullViewDialog
            isVisible={true}
            handleClose={() => setViewMode('none')}
            title="View Vital"
          >
            <VitalFullView
              focusItem={
                viewMode === 'before'
                  ? (previousItem as VitalItem)
                  : (changeItem as VitalItem)
              }
              handleBack={() => setViewMode('none')}
              viewText={viewMode === 'before' ? 'Before' : 'After'}
            />
          </FullViewDialog>
        </>
      )}
    </div>
  );
};

export default VitalComparison;
