import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  formatTimestamp,
} from '../../../data/AmplifyDB';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { ViewportList } from 'react-viewport-list';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../components/Search/SearchBar';
import { User, Workbook } from '../../../models';
import { Storage } from 'aws-amplify';
import PDFScreen from '../protocol/details/PdfScreen';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import Dropzone from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import DepartmentItem from '../../../data/model/DepartmentItem';
import {
  WorkbookDB,
  createWorkbook,
  uploadWorkbookPDF,
} from '../../../data/functions/WorkbookDB';
import Loading from '../../components/Loading/Loading';
import { ProgressStatus } from '../../../models';
import { InputText } from 'primereact/inputtext';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SearchableDropdown from '../../components/SearchableDropdown';
import ProtocolHeader from '../protocol/ProtocolHeader';
import WorkbookItem from '../../../data/model/WorkbookItem';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { globals } from '../../_global/common/Utils';

const WorkBookCreate = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const user: User = useSelector((state: any) => state.user);

  const [file, setFile] = useState<File | null>(null);
  const [errorText, setErrorText] = useState('');
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>(
    state && state.list ? state.list : []
  );
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [dropState, setDropState] = useState({
    isDragActive: false,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState('Loading...');
  const [selectedWorkbook, setSelectedWorkbook] = useState<Workbook | null>(
    null
  );
  const [isViewWorkBook, setIsViewWorkBook] = useState(false);
  const [uploadedWorkbook, setUploadedWorkbook] = useState('');

  const objURL = useMemo(() => (file ? URL.createObjectURL(file) : ''), [file]);

  if (globals.debug) console.log('OBJ URL ', objURL);

  const formik = useFormik({
    initialValues: {
      name: '',
      pairedDeps: [] as DepartmentItem[],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      pairedDeps:
        department.subDeps && department.subDeps.length > 0
          ? Yup.array()
          : Yup.array().nullable(),
    }),
    onSubmit: async (values) => {
      if (isCreateValid && file) {
        try {
          setIsUploading(true);
          let fileSize = file.size;
          const fileKey = await uploadWorkbookPDF(
            department,
            file,
            values.name,
            undefined,
            (progress) => {
              setUploadingText(`Uploading... ${Math.floor(progress * 100)}%`);
            }
          );
          if (globals.debug) console.log('fileKey', fileKey);
          const workbookDetails: WorkbookDB = {
            isNew: true,
            name: formik.values.name,
            workbookID: fileKey,
            fileSize: fileSize,
            createdBy: user.id,
            pairedDepIDs: formik.values.pairedDeps.map(
              (d: DepartmentItem) => d.id
            ),
            departmentID: department.id,
            status: ProgressStatus.ACTIVE,
            version: 'v1.0.0',
            activeID: null,
          };
          if (globals.debug) console.log('workbookDetails', workbookDetails);
          const resp = await createWorkbook(workbookDetails);
          if (resp.type === ResponseType.Success) {
            if (globals.debug)
              console.log('Workbook saved with PDF:', resp.data);
            navigate(`/protocol-sets`, {
              state: { searchState: state.searchState },
            });
          } else {
            throw new Error('Failed to save workbook');
          }
        } catch (error) {
          if (globals.debug)
            console.log('Failed to upload and save workbook:', error);
        }
      }
    },
  });

  /* Add and filter all the departments */
  useEffect(() => {
    if (department.subDeps) {
      let l = [...department.subDeps];
      l = l.filter((dep) => {
        return !formik.values.pairedDeps.some(
          (d: DepartmentItem) => d.id === dep.id
        );
      });
      setDepartmentList(l);
    }
  }, [department, formik.values.pairedDeps]);

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...formik.values.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    formik.setFieldValue('pairedDeps', l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = formik.values.pairedDeps.filter(
      (d: DepartmentItem) => d.id !== option.id
    );
    formik.setFieldValue('pairedDeps', l);
  };

  const handleClearDepartments = () => {
    formik.setFieldValue('pairedDeps', []);
  };

  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if ((file || formik.dirty) && !isWarningModal) {
      setIsWarningModal(true);
    } else {
      navigate(`/protocol-sets`, { state: { searchState: state.searchState } });
    }
  };

  const handleDrop = (file: File) => {
    setFile(file);
    setErrorText('');
    if (formik.values.name === '')
      formik.setFieldValue('name', file.name.replace('.pdf', ''));
  };

  const isCreateValid = useMemo(() => {
    if (globals.debug) console.log('file', file);
    if (globals.debug) console.log('formik.values.name', formik.values.name);
    if (globals.debug)
      console.log('formik.values.pairedDeps', formik.values.pairedDeps);
    if (globals.debug) console.log('department.subDeps', department.subDeps);
    return (
      file != null &&
      formik.values.name.length > 0 &&
      (department.subDeps && department.subDeps.length > 0
        ? formik.values.pairedDeps.length > 0
        : true)
    );
  }, [file, formik.values.name, formik.values.pairedDeps, department.subDeps]);

  return (
    <div className="screen-container">
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Workbook.  Click cancel to return to Workbook or Abandon.`}
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={'Create Protocol Set'}
        description={
          'Create by uploading a PDF and then naming the protocol set.' +
          (department.subDeps && department.subDeps.length > 0
            ? ' You can also pair the protocol set with departments.'
            : '')
        }
        page={'Protocol Sets'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={file != null}
        handleCancelEdit={() => {
          setFile(null);
          formik.resetForm();
        }}
        isCreateButton={true}
        isCreateActive={isCreateValid}
        isDotButton={true}
        handleCreate={() => {
          formik.handleSubmit();
        }}
        handleEdit={() => {}}
        type={'protocol'}
      />
      <div className="ketamineContent">
        {file === null && (
          <Dropzone
            accept={{ 'application/pdf': [] }}
            maxFiles={1}
            onDragOver={() =>
              setDropState({ ...dropState, isDragActive: true })
            }
            onDragLeave={() =>
              setDropState({ ...dropState, isDragActive: false })
            }
            onDropRejected={(files: any[], event: any) => {
              if (files.length === 0) return;
              else if (files.length > 1)
                setErrorText('Only one file can be uploaded at a time');
              else setErrorText('File type not supported:' + files[0].type);

              setDropState({ ...dropState, isDragActive: false });
            }}
            onDropAccepted={(files: File[], event: any) => {
              if (files.length > 1)
                setErrorText('Only one file can be uploaded at a time');
              else {
                handleDrop(files[0]);
              }
              setDropState({ ...dropState, isDragActive: false });
            }}
            disabled={false}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps()}
                  className="cursorPointer dragDropDotGridNewWorkbook"
                  style={{
                    display: 'flex',
                    flex: 1,
                    background: dropState.isDragActive ? '#E0EADD' : '#FFF',
                    border: dropState.isDragActive
                      ? '2px dashed #00534C'
                      : errorText
                        ? '2px dashed #880808'
                        : '2px dashed #cdc8c8',
                  }}
                >
                  <input {...getInputProps({ disabled: false })} />
                  {errorText !== '' && (
                    <div
                      className="errorText"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        marginBottom: '5px',
                        fontSize: '24px',
                      }}
                    >
                      {errorText}
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    {dropState.isDragActive ? (
                      <RiUpload2Line className="drag-drop-icon" />
                    ) : (
                      <RiDownload2Line className="drag-drop-icon" />
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    {dropState.isDragActive ? 'Upload' : 'Drag and Drop'}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '24px',
                    }}
                  >
                    or{' '}
                    <span
                      style={{ marginLeft: '5px' }}
                      className="green_accent_text"
                    >
                      {' '}
                      Browse
                    </span>
                  </div>
                  <div
                    style={{
                      fontSize: '14px',
                      justifyContent: 'center',
                    }}
                    className="grey_text ketamine-general-label"
                  >
                    Support: PDF
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {file && objURL && (
          <div className="workbook-upload-container">
            {/* <iframe
              src={objURL}
              title="Uploaded Workbook"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                marginRight: '10px',
              }}
            /> */}
            <embed
              title="PDF Document"
              type="application/pdf"
              src={objURL}
              style={{ height: '100%', width: '100%', flex: 2 }}
            />
            <div className="dragDropDotGridNewWorkbookDetails">
              <h5 className="ketmine-header-text">Protocol Set Details</h5>
              <div className="contentText">
                {`Great, now name the Protocol Set${department.subDeps && department.subDeps.length > 0 ? ' and pair it with departments' : ''}.`}
              </div>
              <label
                htmlFor="name"
                className={`notification-css-title`}
                style={{ fontSize: '16px' }}
              >
                Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="notification-model"
                  id="name"
                  name="name"
                  autoFocus={true}
                  required={true}
                  value={formik.values.name}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                  }}
                  style={{
                    fontSize: '16px',
                    cursor: 'auto',
                    padding: '20px 20px',
                  }}
                />
                <div className="input-border" />
                {formik.touched.name && formik.errors.name && (
                  <div className="errorText">{formik.errors.name}</div>
                )}
              </div>
              {department &&
                department.subDeps &&
                department.subDeps.length > 0 && (
                  <>
                    <label htmlFor="" className={`notification-css-title`}>
                      <span
                        className="headerTextMargin"
                        style={{ fontSize: '16px', marginTop: '10px' }}
                      >
                        Subscribed Departments:{' '}
                        {formik.values.pairedDeps.length} /{' '}
                        {department.subDeps.length}
                        <span
                          onClick={() =>
                            formik.setFieldValue(
                              'pairedDeps',
                              department.subDeps
                            )
                          }
                        >
                          <div className="clickableText">Add All</div>
                        </span>
                      </span>
                    </label>
                    <span
                      className="contentText greyText"
                      style={{ fontSize: '13px', marginLeft: '10px' }}
                    >
                      This is a list of every department that will subscribe to
                      the protocol.
                    </span>
                    <SearchableDropdown<DepartmentItem>
                      id="searchDropdown"
                      options={departmentList}
                      labelField={(option) => option.name}
                      valueField={(option) => option.name}
                      keyField={(option) => option.id}
                      multiSelect={true}
                      onChange={(option: DepartmentItem) =>
                        handleAddDepartment(option)
                      }
                      onClear={handleClearDepartments}
                      placeholder="Search department..."
                    />
                    {formik.values.pairedDeps.length === 0 && (
                      <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                        No paired departments...
                      </h6>
                    )}
                    <div
                      style={{
                        border:
                          formik.values.pairedDeps.length === 0
                            ? '0px'
                            : '1px solid #ccc',
                        borderRadius: '5px',
                        marginBottom: '20px',
                        marginTop: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <ViewportList items={formik.values.pairedDeps}>
                        {(item: DepartmentItem, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '16fr 1fr',
                              padding: '6px 10px',
                              alignItems: 'center',
                              borderBottom:
                                index === formik.values.pairedDeps.length - 1
                                  ? ''
                                  : '1px solid #ccc',
                            }}
                            className="listItem"
                          >
                            <div className="contentText">{item.name}</div>
                            <FaTimes
                              className="icon-cancel"
                              size={16}
                              onClick={(e) => handleRemoveDepartment(item, e)}
                            />
                          </div>
                        )}
                      </ViewportList>
                    </div>
                  </>
                )}
            </div>
          </div>
        )}
      </div>
      {isUploading && <Loading type="bubbles" message={uploadingText} />}
    </div>
  );
};

export default WorkBookCreate;
