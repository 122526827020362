import React, { useState } from 'react';

import { ModelMetaData, User } from '../../../../models';
import { AiOutlineThunderbolt } from 'react-icons/ai';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import { LiaWeightHangingSolid } from 'react-icons/lia';
interface FolderComparisonProps {
  focusItem: ElectricalSubItem;
  handleBack?: () => void;
  viewText?: string;
  changes?: any;
  showHeader?: boolean;
  keepBefore?: boolean;
}

const ElectricalShockComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
  changes,
  showHeader,
  keepBefore,
}) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div
        className={`model-input-container  ${'cursorPointerDiv hoverableBackground'}`}
        style={{ padding: '15px' }}
      >
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div
              className=""
              style={{
                display: 'grid',
                gridTemplateColumns: '0fr 1fr 1fr',
                gap: '15px',
                margin: '10px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  minWidth: '70px',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Range Low
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'rangeLow')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(changes, 'rangeLow')}
                        afterText={findCurrentValue(changes, 'rangeLow')}
                      />
                    )}
                  </div>
                </div>
              }
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label
                    htmlFor={''}
                    className={` input-label cursorPointerDiv`}
                  >
                    Range High
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'rangeHigh')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(changes, 'rangeHigh')}
                        afterText={findCurrentValue(changes, 'rangeHigh')}
                      />
                    )}
                  </div>
                </div>
              }
            </div>
            <div
              className=""
              style={{
                display: 'grid',
                gridTemplateColumns: '0fr 1fr 1fr 1fr 1fr',
                gap: '15px',
                margin: '10px 0px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  minWidth: '70px',
                  marginBottom: '5px',
                }}
              >
                <AiOutlineThunderbolt className="protocol-vital-icons " />
              </div>
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Calculation Basis
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'fullBasis')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(changes, 'fullBasis')}
                        afterText={findCurrentValue(changes, 'fullBasis')}
                      />
                    )}
                  </div>
                </div>
              }
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Min Dose
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'fullMinDose')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(changes, 'fullMinDose')}
                        afterText={findCurrentValue(changes, 'fullMinDose')}
                      />
                    )}
                  </div>
                </div>
              }
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Max Dose
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'fullMaxDose')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(changes, 'fullMaxDose')}
                        afterText={findCurrentValue(changes, 'fullMaxDose')}
                      />
                    )}
                  </div>
                </div>
              }
              {
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Max Total
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    {keepBefore ? (
                      <div>{findBeforeValue(changes, 'fullMaxTotalDose')}</div>
                    ) : (
                      <HighlightChanges
                        beforeText={findBeforeValue(
                          changes,
                          'fullMaxTotalDose'
                        )}
                        afterText={findCurrentValue(
                          changes,
                          'fullMaxTotalDose'
                        )}
                      />
                    )}
                  </div>
                </div>
              }
            </div>
            {
              <div
                className="sidebarInputContent"
                style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
              >
                {
                  <>
                    {' '}
                    <label
                      htmlFor="Title"
                      className={`sidebarLabel `}
                      style={{ minWidth: '70px' }}
                    >
                      Title:{' '}
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      {keepBefore ? (
                        <div>{findBeforeValue(changes, 'title')}</div>
                      ) : (
                        <HighlightChanges
                          beforeText={findBeforeValue(changes, 'title')}
                          afterText={findCurrentValue(changes, 'title')}
                        />
                      )}
                    </div>
                  </>
                }
              </div>
            }
            {
              <div
                className="sidebarInputContent"
                style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
              >
                {
                  <>
                    {' '}
                    <label
                      htmlFor="Warning"
                      className={`sidebarLabel`}
                      style={{ minWidth: '70px' }}
                    >
                      Warning:{' '}
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      {keepBefore ? (
                        <div>{findBeforeValue(changes, 'warning')}</div>
                      ) : (
                        <HighlightChanges
                          beforeText={findBeforeValue(changes, 'warning')}
                          afterText={findCurrentValue(changes, 'warning')}
                        />
                      )}
                    </div>
                  </>
                }
              </div>
            }
            {
              <div
                className="sidebarInputContent"
                style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
              >
                {
                  <>
                    {' '}
                    <label
                      htmlFor="instructions"
                      className={`sidebarLabel`}
                      style={{ minWidth: '70px' }}
                    >
                      Instructions:{' '}
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      {keepBefore ? (
                        <div>{findBeforeValue(changes, 'instruction')}</div>
                      ) : (
                        <HighlightChanges
                          beforeText={findBeforeValue(changes, 'instruction')}
                          afterText={findCurrentValue(changes, 'instruction')}
                        />
                      )}
                    </div>
                  </>
                }
              </div>
            }
            {
              <div
                className="sidebarInputContent"
                style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
              >
                {
                  <>
                    {' '}
                    <label
                      htmlFor="Note"
                      className={`sidebarLabel`}
                      style={{ minWidth: '70px' }}
                    >
                      Note:{' '}
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      {keepBefore ? (
                        <div>{findBeforeValue(changes, 'note')}</div>
                      ) : (
                        <HighlightChanges
                          beforeText={findBeforeValue(changes, 'note')}
                          afterText={findCurrentValue(changes, 'note')}
                        />
                      )}
                    </div>
                  </>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalShockComparisonView;
