import * as _ from 'lodash';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { sideoutList } from '../../_global/constants/Protocol_constants';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';

interface EquipmentCPRSideoutProps {
  handleClose: () => void;
  handleAdd?: (items: EquipmentItem[], type: string) => void | any;
  handleSubmit?: (reloadDatabase: boolean) => void;
  details?: any;
  title?: string;
  isVisible?: boolean;
  isEditMode?: any;
  page?: any;
  protocol?: ProtocolItem;
  selectedProtocols?: ProtocolItem[];
  selectedEquipment?: EquipmentItem[];
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const EquipmentCPRSideout: React.FC<EquipmentCPRSideoutProps> = (props) => {
  const {
    isVisible,
    handleClose,
    isEditMode,
    page,
    handleAdd,
    handleSubmit,
    protocol,
    selectedEquipment,
    selectedProtocols,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  console.log('database', database);

  const [selectedData, setSelectedData] = useState<any>(
    sideoutList.find((item) => item.id === 5)
  );
  const [value, setValue] = useState<any>('');
  const [options, setOptions] = useState<any>(database.equipment);

  const [sideoutData, setSideoutData] = useState<any>(null);
  const editProtocol = useSelector(
    (state: any) => state?.protocol?.editProtocol
  );
  const departmentItem = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const handleCloseSideouts = () => {
    setSelectedData('');
    setSelectedOptions([]);
    handleClose();
  };
  const handleBack = () => {
    setSelectedData('');
    setSelectedOptions([]);
  };

  const handleNavigateToNewPage = (selectedDataType: any) => {
    const state = {
      selectedProtocol: protocol,
      value: '',
      type: selectedDataType,
      editType: 'new',
      editMode: isEditMode,
      page,
    };
    navigate(`/protocol/edit/new-protocol`, { state });
  };

  const dataList = useMemo(() => {
    return database.equipment;
  }, [database.equipment]);

  const handleSearch = (text: string) => {
    setValue(text);
    handleFilter(text);
  };

  const handleNavigateToEditPage = (data: any) => {
    const state = {
      selectedProtocol: protocol === undefined ? null : protocol,
      value: data,
      type: selectedData?.type,
      editType: 'edit',
      editMode: true,
      page,
    };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    setSideoutData(data);
    navigate(`/protocol/edit/edit-protocol`, { state });
  };

  const handleFilter = useCallback(
    (value: any) => {
      if (value) {
        console.log('value', value);
        const filterData = dataList?.filter((item: any) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(filterData);
      } else {
        console.log('dataList', dataList);
        setOptions(dataList);
      }
    },
    [value, dataList, setOptions]
  );

  useEffect(() => {
    if (selectedEquipment && selectedEquipment.length > 0) {
      setSelectedOptions(selectedEquipment);
    }
  }, [selectedEquipment]);

  const handleCheckboxChange = (item: any) => {
    if (selectedData.type === 'Checklist') {
      if (selectedOptions.includes(item)) {
        // If the option is already selected, remove it
        return setSelectedOptions([]);
      } else {
        // If the option is not selected, add it
        return setSelectedOptions([item]);
      }
    } else {
      if (selectedOptions.find((option: any) => option.uid === item.uid)) {
        // If the option is already selected, remove it
        return setSelectedOptions(
          selectedOptions?.filter((option: any) => option.uid !== item.uid)
        );
      } else {
        // If the option is not selected, add it
        return setSelectedOptions([item]);
      }
    }
  };

  const isSaveValid = useMemo(() => {
    if (selectedData.type === 'Equipment') {
      if (selectedEquipment && selectedEquipment.length > 0) {
        let change = false;
        selectedEquipment.forEach((equipment) => {
          if (
            !selectedOptions.find((option: any) => option.uid === equipment.uid)
          ) {
            change = true;
          }
        });
        return change;
      } else if (
        protocol &&
        protocol.equipment &&
        protocol.equipment.length > 0
      ) {
        let change = false;
        protocol.equipment.forEach((equipment) => {
          if (
            !selectedOptions.find((option: any) => option.uid === equipment.uid)
          ) {
            change = true;
          }
        });
        return change;
      }
    }
    return selectedOptions?.length > 0;
  }, [
    selectedData.type,
    selectedOptions,
    selectedProtocols,
    selectedEquipment,
    protocol,
  ]);

  const handleAddPairedProtocol = async () => {
    handleAdd && handleAdd(selectedOptions, selectedData.type);
  };

  return (
    <div>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleCloseSideouts}
        style={{
          width: '25%',
          minWidth: '400px',
        }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
      >
        <div style={{ height: '100%', padding: '10px' }}>
          <div
            className="buttonContainer contentTitleLarge hoverText"
            onClick={handleCloseSideouts}
          >
            <span className="headerTilte">
              <BiArrowBack
                className="header-icon cursorPointer"
                style={{ paddingLeft: '4px' }}
              />
              <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
              {selectedData.name}
            </span>
          </div>

          <div className="contentText">
            Add a {selectedData?.type} to this protocol either be searching
            already made {selectedData?.type} or by creating a new{' '}
            {selectedData?.type}.
          </div>

          <div>
            <div className="contentLabelBold">
              Create New {selectedData?.type}
            </div>
            <div
              onClick={() => {
                handleNavigateToNewPage(selectedData.type);
              }}
              className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <span>
                <HiPlus className="text-icon " /> Create New {selectedData.type}
              </span>
            </div>
          </div>

          <div className="contentLabelBold">Select {selectedData?.type}</div>

          <SearchBar
            value={value}
            onChange={handleSearch}
            placeholder="Search"
            containerStyle={{ width: '100%' }}
          />
          <div
            style={{ maxHeight: '60%' }}
            className="contentBorder protocolCalculationPad secondaryListScroll"
          >
            <ViewportList items={options}>
              {(item: any, index: any) => (
                <div
                  key={item?.name + index}
                  onClick={() => {
                    handleCheckboxChange(item);
                  }}
                  className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight `}
                >
                  <RadioButton
                    inputId="pairedProtocol"
                    name="pairedProtocol"
                    className="radioBtnColor"
                    style={{ marginRight: '10px' }}
                    value={item.name}
                    checked={selectedOptions?.find(
                      (option: any) => option.uid === item.uid
                    )}
                    onChange={() => {
                      handleCheckboxChange(item);
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <div>{item.name}</div>
                    {
                      <div className="editIconContainer">
                        <HiPencil
                          onClick={() => {
                            handleNavigateToEditPage(item);
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
              )}
            </ViewportList>
          </div>

          <div className="btn_Bottom">
            <Button
              data-testid="cancel"
              className="secondary-button btn-rightMargin"
              onClick={handleCloseSideouts}
            >
              X Cancel
            </Button>
            <Button
              data-testid="save"
              className="primary-button"
              disabled={!isSaveValid}
              onClick={handleAddPairedProtocol}
            >
              + Add
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default EquipmentCPRSideout;
