import React, { useEffect, useMemo, useState } from 'react';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { LiaWeightHangingSolid } from 'react-icons/lia';
import { HiPencil } from 'react-icons/hi';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  convertToHighestTime,
  findDepartmentOwner,
  globals,
  handleCopy,
  toTitleCase,
} from '../../../../_global/common/Utils';
import { useSelector } from 'react-redux';
import { User } from '../../../../../models';
import Status from '../../../../components/ProgressStatus/ProgressStatus';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import OwnerImage from '../../../../components/OwnerImage/OwnerImage';
import { FaLock } from 'react-icons/fa6';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import HMSwitch from '../../../../components/general/HMSwitch';
import { ProgressStatus } from '../../../../../models';
import { IoMenu } from 'react-icons/io5';
import PatientAge, {
  getFormattedAge,
} from '../../../../_global/common/PatientAge';

interface MedicationDoseProps {
  dose: MedicationSubItem | InfusionSubItem;
  doseIndex: number;
  showProtocol: boolean;
  isEditMode?: boolean;
  onToggle?: (item: MedicationSubItem) => void;
  isToggled?: boolean;
  provided?: any;
}
const MedicationDoseUI: React.FC<MedicationDoseProps> = (props) => {
  const {
    dose,
    doseIndex,
    showProtocol,
    isEditMode,
    onToggle,
    isToggled,
    provided,
  } = props;
  const user: User = useSelector((state: any) => state?.user);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const department: DepartmentItem = useSelector(
    (state: any) => state?.protocol?.departmentItem.department
  );

  const departmentOwner = useMemo(() => {
    return findDepartmentOwner(department, dose);
  }, [department, dose]);
  const isOwner = useMemo(() => {
    return departmentOwner?.id === department.id || false;
  }, [departmentOwner, department]);
  const isDeactivated = useMemo(() => {
    if (
      dose.status === ProgressStatus.DEACTIVATED &&
      dose.overrideItem &&
      dose.overrideItem.departmentID !== department.id
    )
      return true;
    return false;
  }, [dose, department]);

  const additonalFields = useMemo(() => {
    let num: number = 0;
    if (dose.title) num++;
    if (dose.warning) num++;
    if (dose.instruction) num++;
    if (dose.note) num++;
    if (dose.ageLow) num++;
    if (dose.ageHigh) num++;
    if (dose.repeatTimeSec > 0) num++;

    return num;
  }, [dose]);

  return (
    <div>
      <div className="addDoseHeader"></div>
      <div
        className={`model-input-container  ${
          isEditMode ? 'cursorPointerDiv hoverableBackground' : ''
        }`}
      >
        <div
          className="dose-header"
          style={{
            padding: isEditMode && !isOwner ? '0px 14px' : '6px 14px',
          }}
        >
          <span className="ketamine-general-label">
            {isDeactivated && (
              <FaLock
                className="table-icon"
                style={{
                  // marginBottom: '4px',
                  marginRight: '5px',
                }}
                color="#A3A3A3"
              />
            )}
            {isEditMode && provided && (
              <div
                {...provided.dragHandleProps}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginRight: '8px',
                }}
                onClick={(e) => e.stopPropagation()} // Add this to prevent click propagation
              >
                <IoMenu
                  color="#636363"
                  size="1.5rem"
                  style={{ marginRight: '8px' }}
                />
              </div>
            )}
            Dose {doseIndex}
            {showProtocol ? ' - ' + dose.parentProtocol.name : ''}
            {globals.debug && (
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '12px',
                  color: 'grey',
                }}
              >
                {dose.uid}
                {isCopied && isCopied === dose.uid ? (
                  <BiSolidCopy
                    color={'#00534C'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <BiCopy
                    size=".75rem"
                    className="copy_icon"
                    onClick={(e) => handleCopy(dose.uid, e, setIsCopied)}
                  />
                )}
              </span>
            )}
          </span>
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            {isEditMode && !isOwner && (
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-6px',
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Stop click from reaching parent
                }}
              >
                <HMSwitch
                  checked={isToggled}
                  onChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    checked: boolean
                  ) => {
                    event.stopPropagation();
                    onToggle && onToggle(dose as MedicationSubItem);
                  }}
                />
              </span>
            )}
            {dose.status !== ProgressStatus.DELETED && (
              <span
                style={{
                  // marginRight: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Status status={dose.status} />
                {departmentOwner && (
                  <OwnerImage
                    style={{
                      padding: 0,
                      marginRight: '-10px',
                    }}
                    owner={departmentOwner}
                  />
                )}
              </span>
            )}
          </span>
        </div>
        <div className={`${'doseGridPad'}`}>
          <div className="ketamine-container-pad">
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '20px',
                }}
              >
                <LiaWeightHangingSolid
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Weight Low (kg)
                </label>
                <div className="p-input-icon-right" data-testid="editWeightLow">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="weightLow"
                    name="weightLow"
                    placeholder="Min"
                    value={dose.rangeLow > 0 ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>

              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Weight High (kg)
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="weightHigh"
                    name="weightHigh"
                    placeholder="Max"
                    data-testid="weightHigh"
                    value={
                      dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>

              {(dose.ageLow != null || dose.ageHigh != null) && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age Low
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="ageLow"
                      name="ageLow"
                      data-testid="ageLow"
                      placeholder="Max"
                      value={
                        dose.ageLow == null ? '' : getFormattedAge(dose.ageLow)
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {(dose.ageLow != null || dose.ageHigh != null) && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age High
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="ageHigh"
                      name="ageHigh"
                      data-testid="ageHigh"
                      placeholder="Max"
                      value={
                        dose.ageHigh != null
                          ? getFormattedAge(dose.ageHigh as PatientAge)
                          : ''
                      }
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
              {dose.repeatTimeSec > 0 && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Repeat Time
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="repeatTime"
                      name="repeatTime"
                      data-testid="repeatTime"
                      placeholder="Optional..."
                      value={convertToHighestTime(dose.repeatTimeSec) + ''}
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className=""
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  marginRight: '20px',
                }}
              >
                <PiSyringe
                  style={{
                    fontSize: '2rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 2,
                }}
              >
                <label className="input-label">Calculation Basis</label>
                <div className={`p-input-icon-right`}>
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      dose.model.basis === '-1' ? 'EMERGENCY' : dose.model.basis
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* {dose.minDose > 0 && ( */}
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Min Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="minDose"
                    name="minDose"
                    data-testid="minDose"
                    placeholder="Min"
                    value={
                      dose.minDose ? dose.minDose + ' ' + dose.maxDoseUnit : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* )} */}
              {/* {dose.maxDose !== globals.MAX_VALUE && ( */}
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Max Single Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={
                      dose.maxDose !== globals.MAX_VALUE
                        ? dose.maxDose + ' ' + dose.maxDoseUnit
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              {/* )} */}
              {dose.maxTotalDose !== globals.MAX_VALUE && (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Max Total Dose
                  </label>
                  <div className="p-input-icon-right">
                    <InputText
                      type="text"
                      className={`form-control btn-rightMargin  pointer`}
                      id="maxTotalDose"
                      name="maxTotalDose"
                      data-testid="maxTotalDose"
                      placeholder="Max"
                      value={
                        dose.maxTotalDose
                          ? dose.maxTotalDose + ' ' + dose.maxDoseUnit
                          : ''
                      }
                      readOnly={true}
                      // disabled={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <div className="ketamine-container-pad">
            <div className="ketamine-input-twocontent">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <LiaWeightHangingSolid className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Range Low
                </label>
                <div className="p-input-icon-right" data-testid="editRangeLow">
                  <InputText
                    type="text"
                    className={`form-control pointer ${!dose.rangeLow ? 'greyText' : ''}`}
                    id="rangeLow"
                    name="rangeLow"
                    placeholder="Min"
                    value={dose.rangeLow ? dose.rangeLow + ' kg' : ''}
                    data-testid="rangeLow"
                    readOnly={true}
                    disabled={true}
                    // style={{cursor:'context-menu'}}
                  />
                </div>
              </div>
              <div className="form-group">
                <label
                  htmlFor={''}
                  className={` ketamine-label cursorPointerDiv`}
                >
                  Range High
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control highRange pointer ${Number(dose.rangeLow) === globals.MAX_VALUE ? 'greyText' : ''}`}
                    id="rangeHigh"
                    name="rangeHigh"
                    placeholder="Max"
                    data-testid="rangeHigh"
                    value={
                      dose.rangeHigh && dose.rangeHigh !== globals.MAX_VALUE
                        ? dose.rangeHigh + ' kg'
                        : ''
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="ketamine-input-content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  marginBottom: '5px',
                }}
              >
                <PiSyringe className="protocol-icons " />
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Calculation Basis
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control btn-rightMargin  pointer`}
                    id="calculationBasis"
                    name="calculationBasis"
                    placeholder="Required..."
                    data-testid="calculationBasisValue"
                    value={
                      dose.range.basis === '-1' ? 'EMERGENCY' : dose.range.basis
                    }
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Min Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="minDose"
                    name="minDose"
                    placeholder="Min"
                    data-testid="minDose"
                    value={dose.range.minDose ? dose.range.minDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor={''} className={` ketamine-label`}>
                  Max Dose
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control pointer`}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={dose.range.maxDose ? dose.range.maxDose : ''}
                    readOnly={true}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div> */}
          {dose.model.basis !== '-1' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                marginTop: '10px',
                paddingBottom: '10px',
              }}
            >
              <label htmlFor={''} className={` sidebarLabel`}>
                Route(s):
              </label>
              <div data-div-id="outside" className={`selectionArea-flex`}>
                {dose.routes.length !== 0 && (
                  <ViewportList items={dose.routes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
                {user.type === 'ADMIN' && dose.nemsisRoutes.length !== 0 && (
                  <ViewportList items={dose.nemsisRoutes}>
                    {(item, index) => {
                      return (
                        <div
                          data-div-id="grey"
                          key={index}
                          className={`selectedGreybox`}
                        >
                          <div>{item.route + ' (' + item.code + ')'}</div>
                        </div>
                      );
                    }}
                  </ViewportList>
                )}
              </div>
            </div>
          )}
          {additonalFields > 0 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 0,
                marginTop: '-6px',
                cursor: 'pointer',
              }}
            >
              <p className="sidebarText-hover">
                {additonalFields} more advanced fields {'>'}{' '}
              </p>
            </div>
          )}
        </div>

        {/* <div className={` ${'sideoutFieldPad'}`}>
          {dose.title && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Title" className={`sidebarLabel `}>
                Title:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder="Enter Title here..."
                  value={dose.title}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.warning && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Warning" className={`sidebarLabel`}>
                Warning:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="warning"
                  name="warning"
                  data-testid="warning"
                  placeholder="Enter Warning here..."
                  value={dose.warning}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.instruction && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="instructions" className={`sidebarLabel`}>
                Instructions:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general `}
                  id="instruction"
                  name="instruction"
                  data-testid="instruction"
                  placeholder="Add Instructions here..."
                  value={dose.instruction}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
          {dose.note && (
            <div
              className="sidebarInputContent"
              style={{ marginBottom: '10px' }}
            >
              <label htmlFor="Note" className={`sidebarLabel`}>
                Note:{' '}
              </label>
              <div className="input-container">
                <input
                  type="text"
                  className={`form-control-general`}
                  id="note"
                  name="note"
                  data-testid="note"
                  placeholder="Enter Note here..."
                  value={dose.note}
                  //disabled={true}
                  disabled={true}
                />
                <div className="input-border"></div>
              </div>
            </div>
          )}
        </div> */}
      </div>
    </div>
  );
};
export default MedicationDoseUI;
