import React, { useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { IoChevronForward, IoMenu } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as _ from 'lodash';
import { ProgressStatus, User } from '../../../../models';
import MedicationSubItem, {
  getRoutesString,
} from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import FormItem from '../../../../data/model/FormItem';
import { UserType } from '../../../../models';
import { useSelector } from 'react-redux';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import HighlightChanges from '../../review/HighlightChanges';
import {
  findAfterIndexAndName,
  findBeforeIndexAndName,
} from '../../review/reviewComparsion/DoseIndexComparsion';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import InfusionItem from '../../../../data/model/InfusionItem';
import {
  findDepartmentOwner,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { IconButton, Tooltip } from '@mui/material';
import { FaTrash } from 'react-icons/fa6';
import { FaTimes } from 'react-icons/fa';
import ModelItem from '../../../../data/model/ModelItem';
import ModelSubItem from '../../../../data/model/ModelSubItem';
import HMSwitch from '../../../components/general/HMSwitch';
import DepartmentItem from '../../../../data/model/DepartmentItem';

interface AccordionProps {
  type:
    | 'Medication'
    | 'Infusion'
    | 'Equipment'
    | 'Electrical'
    | 'Checklist'
    | 'Paired Protocol';
  onItemClick?: (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol',
    state?: any
  ) => void;
  onRemoveItem?: (
    item:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  onReorder: (
    items: (
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
    )[],
    type:
      | 'Medication'
      | 'Infusion'
      | 'Equipment'
      | 'Electrical'
      | 'Checklist'
      | 'Paired Protocol'
  ) => void;
  accordionType?: any;
  isEditMode: boolean;
  protocol: ProtocolItem;
  data: (
    | MedicationSubItem
    | InfusionSubItem
    | ElectricalSubItem
    | EquipmentItem
    | FormItem
    | ProtocolItem
  )[];
  comparisonMode?: boolean;
  keepBefore?: boolean;
  previousItems?:
    | (
        | MedicationSubItem
        | InfusionSubItem
        | ElectricalSubItem
        | EquipmentItem
        | FormItem
        | ProtocolItem
      )[]
    | null;
  changeItems?:
    | (
        | MedicationSubItem
        | InfusionSubItem
        | ElectricalSubItem
        | EquipmentItem
        | FormItem
        | ProtocolItem
      )[]
    | null;
  department: DepartmentItem;
  adminLevel?: number;
  onToggle?: (item: ModelItem<any> | ModelSubItem<any>) => void;
  isToggled?: boolean;
}

/* 10-02-23 Arul: Created ProtocolAccordionSecondLayer component  for calculation page*/
const ProtocolAccordionSecondLayer: React.FC<AccordionProps> = ({
  type,
  onItemClick,
  accordionType,
  isEditMode,
  protocol,
  data,
  onReorder,
  onRemoveItem,
  comparisonMode,
  keepBefore,
  previousItems,
  changeItems,
  adminLevel,
  onToggle,
  isToggled,
  department,
}) => {
  const user: User = useSelector((state: any) => state.user);
  const [items, setItems] =
    useState<
      (
        | MedicationSubItem
        | InfusionSubItem
        | ElectricalSubItem
        | EquipmentItem
        | FormItem
        | ProtocolItem
      )[]
    >(data);
  const navigate = useNavigate();
  const adminAccess = useMemo(
    () => hasAdminUserAccess(department, user),
    [department, user]
  );
  const isDragDisabled =
    type === 'Checklist' || type === 'Paired Protocol' || type === 'Equipment';
  // function for dragging

  const reorder = (
    list: any[],
    startIndex: number,
    endIndex: number
  ): any[] => {
    // Determine if the item being dragged or the target is in a locked area
    const isStartLocked = hasAdminUserAccess(
      department,
      user,
      list[startIndex]
    );
    const isEndLocked = hasAdminUserAccess(department, user, list[endIndex]);

    // Prevent dragging an unlocked item into a locked area
    if (isStartLocked && !isEndLocked) return list;
    // let departmentOwner = findDepartmentOwner(department, list[startIndex]);

    //Verify the owner of the items are grouped together
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(updatedItems);
    onReorder(updatedItems, type);
  };

  useEffect(() => {
    if (data.length === 0) {
      setItems([]);
      return;
    }

    if (data[0].TAG === 'ElectricalSubItem') {
      setItems(
        (data as ElectricalSubItem[]).sort(
          (a: ElectricalSubItem, b: ElectricalSubItem) => {
            if (a.index === b.index) return a.name.localeCompare(b.name);
            return a.index - b.index;
          }
        )
      );
    } else if (data[0].TAG === 'InfusionSubItem') {
      setItems(
        (data as InfusionSubItem[]).sort(
          (a: InfusionSubItem, b: InfusionSubItem) => {
            if (a.index === b.index) return a.name.localeCompare(b.name);
            return a.index - b.index;
          }
        )
      );
    } else {
      setItems(data.sort((a: any, b: any) => a.name.localeCompare(b.name)));
    }
  }, [data]);

  // navigate to protocol edit page
  const handleNavigate = (
    parent:
      | MedicationItem
      | InfusionItem
      | ElectricalItem
      | EquipmentItem
      | FormItem
      | ProtocolItem,
    child:
      | MedicationSubItem
      | InfusionSubItem
      | ElectricalSubItem
      | EquipmentItem
      | FormItem
      | ProtocolItem
  ) => {
    if (
      user &&
      user.type !== UserType.ADMIN &&
      user.type !== UserType.DEPT_ADMIN
    )
      return;
    if (accordionType === 'Paired Protocol') {
      onItemClick && onItemClick(child, accordionType);
    } else {
      const state = {
        selectedProtocol: protocol,
        value: parent,
        subValue: child,
        type: accordionType,
        editType: 'edit',
        editMode: isEditMode,
        page: 'protocolDetailPage',
      };
      if (comparisonMode) {
        // do nothing
      } else {
        if (
          type === 'Medication' ||
          type === 'Infusion' ||
          type === 'Electrical'
        ) {
          onItemClick && onItemClick(child, accordionType, state);
        } else {
          navigate(`/protocol/edit/edit-protocol`, { state });
        }
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided: any) => (
          <ul
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="listItem"
          >
            <ViewportList items={items}>
              {(
                item:
                  | MedicationSubItem
                  | InfusionSubItem
                  | ElectricalSubItem
                  | EquipmentItem
                  | FormItem
                  | ProtocolItem,
                index: number
              ) => {
                let parent =
                  item.TAG === 'MedicationSubItem' ||
                  item.TAG === 'ElectricalSubItem' ||
                  item.TAG === 'InfusionSubItem'
                    ? (item as ElectricalSubItem).parent
                    : item.TAG === 'FormItem'
                      ? (item as FormItem)
                      : (item as EquipmentItem);
                const departmentOwner = findDepartmentOwner(department, item);
                const isOwner = departmentOwner?.id === department.id;

                return isDragDisabled ? (
                  <div
                    key={index}
                    className={`cursorPointer listContainer ${
                      index % 2 === 0 ? 'listBackground' : ''
                    }`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <li
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNavigate(parent, item);
                      }}
                      className="listContent"
                    >
                      <div className="a1SubITems">
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                          }}
                        >
                          <div className={'a1Content'} style={{}}>
                            <span>{item.name}</span>
                            <span>
                              {item.status !== 'ACTIVE' && (
                                <Status status={item.status} />
                              )}
                            </span>
                          </div>
                        </div>
                        {type === 'Checklist' && (
                          <div
                            className="a1SubItemsContent"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div className="a1type">
                              {(item as FormItem).items?.length} Sizes
                              <span>
                                <IoChevronForward
                                  size="1.5rem"
                                  style={{ marginLeft: '10px' }}
                                />
                              </span>
                            </div>
                            {isEditMode &&
                              adminAccess &&
                              adminLevel &&
                              adminLevel > 2 && (
                                <span>
                                  <Tooltip
                                    title={'Remove'}
                                    placement="right"
                                    arrow
                                  >
                                    <span
                                      className={`iconButton redHover`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRemoveItem &&
                                          onRemoveItem(item, type);
                                      }}
                                    >
                                      <FaTimes
                                        data-testid="isFullScreenButton"
                                        className="icon"
                                        style={{
                                          padding: '6px',
                                          color: '#a94c4c',
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </span>
                              )}
                          </div>
                        )}
                        {type === 'Paired Protocol' && (
                          <div
                            className="a1SubItemsContent"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            {(item as ProtocolItem).sum} Items
                            <div>
                              <IoChevronForward
                                size="1.5rem"
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                            {isEditMode &&
                              adminAccess &&
                              adminLevel &&
                              adminLevel > 2 && (
                                <span>
                                  <Tooltip
                                    title={'Remove'}
                                    placement="right"
                                    arrow
                                  >
                                    <span
                                      className={`iconButton redHover`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRemoveItem &&
                                          onRemoveItem(item, type);
                                      }}
                                    >
                                      <FaTimes
                                        data-testid="isFullScreenButton"
                                        className="icon"
                                        style={{
                                          padding: '6px',
                                          color: '#a94c4c',
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </span>
                              )}
                          </div>
                        )}
                        {type === 'Equipment' && (
                          <div
                            className="a1SubItemsContent"
                            style={{ justifyContent: 'flex-end' }}
                          >
                            <div className="a1type">
                              {(item as EquipmentItem).options?.length} Sizes
                              <span>
                                <IoChevronForward
                                  size="1.5rem"
                                  style={{ marginLeft: '10px' }}
                                />
                              </span>
                            </div>
                            {isEditMode &&
                              adminAccess &&
                              adminLevel &&
                              adminLevel > 2 && (
                                <span>
                                  <Tooltip
                                    title={'Remove'}
                                    placement="right"
                                    arrow
                                  >
                                    <span
                                      className={`iconButton redHover`}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        onRemoveItem &&
                                          onRemoveItem(item, type);
                                      }}
                                    >
                                      <FaTimes
                                        data-testid="isFullScreenButton"
                                        className="icon"
                                        style={{
                                          padding: '6px',
                                          color: '#a94c4c',
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                </span>
                              )}
                          </div>
                        )}
                      </div>
                    </li>
                  </div>
                ) : (
                  <Draggable
                    key={item.name + '-' + index}
                    draggableId={item.name + '-' + index}
                    isDragDisabled={!isEditMode}
                    index={index}
                  >
                    {(provided: any) => (
                      <div
                        className={`cursorPointer listContainer ${
                          index % 2 === 0 ? 'listBackground' : ''
                        }`}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <li
                          key={index}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleNavigate(parent, item);
                          }}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="listContent"
                        >
                          <div className="a1SubITems">
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                flex: 2,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              {isEditMode && (
                                <div
                                  {...provided.dragHandleProps}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: isOwner ? '0px' : '-12px',
                                  }}
                                >
                                  {!isOwner ? (
                                    <HMSwitch
                                      checked={item.status !== 'DEACTIVATED'}
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                        checked: boolean
                                      ) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        onToggle && onToggle(item);
                                      }}
                                    />
                                  ) : (
                                    <IoMenu
                                      size="1.5rem"
                                      style={{ marginRight: '24px' }}
                                    />
                                  )}
                                </div>
                              )}
                              {comparisonMode ? (
                                <h6 className="departmentItemText">
                                  {keepBefore ? (
                                    findBeforeIndexAndName(
                                      item as
                                        | MedicationSubItem
                                        | ElectricalSubItem,
                                      previousItems as
                                        | MedicationSubItem[]
                                        | ElectricalSubItem[]
                                    )
                                  ) : (
                                    <HighlightChanges
                                      beforeText={findBeforeIndexAndName(
                                        item as
                                          | MedicationSubItem
                                          | ElectricalSubItem,
                                        previousItems as
                                          | MedicationSubItem[]
                                          | ElectricalSubItem[]
                                      )}
                                      afterText={findAfterIndexAndName(
                                        item as
                                          | MedicationSubItem
                                          | ElectricalSubItem,
                                        changeItems as
                                          | MedicationSubItem[]
                                          | ElectricalSubItem[]
                                      )}
                                    />
                                  )}
                                  {item.status !== 'ACTIVE' && (
                                    <Status status={item.status} />
                                  )}
                                </h6>
                              ) : (
                                <h6
                                  className="departmentItemText"
                                  style={{
                                    flex: 3,
                                  }}
                                >
                                  {globals.debug && (item as any).index != null
                                    ? `${(item as any).index} - `
                                    : ''}
                                  {item.name}
                                  {item.status !== 'ACTIVE' && (
                                    <Status status={item.status} />
                                  )}
                                </h6>
                              )}
                            </div>
                            {type === 'Medication' && (
                              <div className="a1SubItemsContent">
                                <div className="a1type">
                                  {(item as MedicationSubItem).model.basis}
                                </div>
                                <div className="a1type">
                                  {getRoutesString(item as MedicationSubItem)}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                            {type === 'Infusion' && (
                              <div
                                className="a1SubItemsContent"
                                style={{
                                  justifyContent: 'flex-end',
                                  gap: '10px',
                                  width: 'auto',
                                }}
                              >
                                <div
                                  className="a1type"
                                  style={{
                                    borderRight: '1.75px solid #a0a0a0',
                                    paddingRight: '12px',
                                  }}
                                >
                                  {(item as MedicationSubItem).model.basis}
                                </div>
                                <div className="a1type">
                                  {getRoutesString(item as MedicationSubItem)}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}

                            {type === 'Electrical' && (
                              <div
                                className="a1SubItemsContent"
                                style={{ justifyContent: 'flex-end' }}
                              >
                                <div className="a1type">
                                  {(item as ElectricalSubItem).model.basis}
                                  <span>
                                    <IoChevronForward
                                      size="1.5rem"
                                      style={{ marginLeft: '10px' }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </li>
                      </div>
                    )}
                  </Draggable>
                );
              }}
            </ViewportList>{' '}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ProtocolAccordionSecondLayer;
