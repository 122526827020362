import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { getObjectDifference } from '../../ui/_global/common/Utils';
import { DraftChange, DraftGroup, Acknowledge, Reviewal } from '../../API';
import { DatabaseResponse } from '../AmplifyDB';
import DraftChangeItem from './DraftChangeItem';
import { User } from '../../models';
import { DraftGroupJSON } from '../functions/ReviewalDB';

class DraftGroupItem extends ModelItem<DraftGroup> {
  title: string;
  description: string | null | undefined;
  draftChanges: DraftChangeItem[];
  createdAt: Date;
  owner: User;
  reviewal: Reviewal | null | undefined;
  reviewACK: Acknowledge | null | undefined;

  constructor(draftGroup: DraftGroup, db: DatabaseResponse) {
    super(draftGroup, draftGroup.title, db.department.id);
    this.title = draftGroup.title;
    this.description = draftGroup.description;
    this.createdAt = new Date(draftGroup.createdAt);

    let owner = db.users.find((u) => u.id === draftGroup.ownerID);
    if (owner) this.owner = owner;
    else this.owner = db.users[0];

    this.draftChanges = [];
  }

  toString(): string {
    return `DraftGroupItem -> {
            uid=${this.uid},
            title=${this.title},
            description=${this.description},
            owner=${this.owner.firstName + ' ' + this.owner.lastName},
            draftChanges=${this.draftChanges.length},
            createdAt=${this.createdAt},
        }`;
  }
}

export default DraftGroupItem;
