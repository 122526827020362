import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useState } from 'react';
import { RiLockPasswordFill } from 'react-icons/ri';
import { InputText } from 'primereact/inputtext';
import '../../login/css/Login.scss';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { MdEmail } from 'react-icons/md';
import { validateEmail, validatePassword } from '../../../_global/common/Utils';
interface ActionsModalInterface {
  isVisible: boolean;
  value?: string;
  department: DepartmentItem;
  handleClose: () => void;
  handleSubmit: (response: string | boolean, type: string) => void;
  heading?: string;
  primaryDescription?: string;
  type?: string;
  isButtonTextHeading?: boolean;
  buttonTextHeading?: string;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const ActionsModal: React.FC<ActionsModalInterface> = (
  props: ActionsModalInterface
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    heading,
    primaryDescription,
    type,
    isButtonTextHeading,
    buttonTextHeading,
  } = props;

  const [state, setState] = useState({
    email: '',
    password: '',
    error: '',
    passwordVisible: false,
  });

  useEffect(() => {
    if (isVisible) {
      setState({
        email: '',
        password: '',
        error: '',
        passwordVisible: false,
      });
    }
  }, [isVisible]);

  const handleButton = () => {
    // Determine action based on the type
    if (type === 'newPassword') {
      if (state.password.length === 0) {
        setState({ ...state, error: 'Password is required' });
        return;
      } else if (!validatePassword(state.password)) {
        setState({
          ...state,
          error:
            'Password must be at least 6 characters long, include a number and a special character.',
        });
        return;
      }
      handleSubmit(state.password, type);
    } else if (type === 'changeEmail') {
      if (state.email.length === 0) {
        setState({ ...state, error: 'Email is required' });
        return;
      } else if (!validateEmail(state.email)) {
        setState({ ...state, error: 'Invalid email address' });
        return;
      }
      handleSubmit(state.email, type);
    } else if (type === 'reset') {
      handleSubmit(true, type);
    }
  };
  const resetState = () => {
    setState({
      email: '',
      password: '',
      error: '',
      passwordVisible: false,
    });
  };

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          flex: 1,
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '600px',
        }}
        onHide={() => {
          handleClose();
          resetState();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}
        >
          <h4 className="keychain-title">
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              {heading}
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>

          <form
            className="keychain-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleButton();
            }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              background: 'transparent',
              border: 'none',
            }}
          >
            <div className="sidebarText">{primaryDescription}</div>

            {isButtonTextHeading && (
              <label
                htmlFor="password"
                className={`keychain-title`}
                style={{
                  marginTop: '10px',
                }}
              >
                {buttonTextHeading}
              </label>
            )}
            {type === 'newPassword' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // width: '100%',
                    flex: 1,
                    margin: 0,
                    padding: 0,
                    // marginBottom: '10px',
                  }}
                >
                  <div className="public-input-container">
                    <RiLockPasswordFill className="form-icon-left" size={20} />
                    <InputText
                      type={state.passwordVisible ? 'text' : 'password'}
                      className="password-input"
                      id="password"
                      name="password"
                      placeholder="Password"
                      required={true}
                      value={state.password}
                      autoFocus={true}
                      onChange={(e: any) => {
                        setState({
                          ...state,
                          password: e.target.value,
                          error: '',
                        });
                      }}
                      style={{
                        borderColor: state.error !== '' ? 'darkred' : 'gray',
                      }}
                    />
                  </div>

                  {state.passwordVisible ? (
                    <BsEye
                      className="form-icon-eye"
                      size={'2rem'}
                      onClick={() => {
                        setState({
                          ...state,
                          passwordVisible: !state.passwordVisible,
                        });
                      }}
                    />
                  ) : (
                    <BsEyeSlash
                      className="form-icon-eye"
                      size={'2rem'}
                      onClick={() => {
                        setState({
                          ...state,
                          passwordVisible: !state.passwordVisible,
                        });
                      }}
                    />
                  )}
                </div>
                {state.error !== '' && (
                  <label
                    htmlFor="password"
                    className="error-text"
                    style={{
                      color: 'darkred',
                      fontSize: '1rem',
                      marginLeft: '10px',
                      marginTop: '6px',
                    }}
                  >
                    {state.error}
                  </label>
                )}
              </>
            )}
            {type === 'changeEmail' && (
              <div>
                <div className="public-input-container">
                  <MdEmail className="form-icon-left" size={20} />
                  <InputText
                    type="text"
                    className="password-input"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    required={true}
                    value={state.email}
                    autoFocus={true}
                    onChange={(e: any) => {
                      setState({
                        ...state,
                        email: e.target.value,
                        error: '',
                      });
                    }}
                    style={{
                      borderColor: state.error !== '' ? 'darkred' : 'gray',
                    }}
                  />
                </div>
                {state.error !== '' && (
                  <label
                    htmlFor="password"
                    className="error-text"
                    style={{
                      color: 'darkred',
                      fontSize: '1rem',
                      marginLeft: '10px',
                      marginTop: '6px',
                    }}
                  >
                    {state.error}
                  </label>
                )}
              </div>
            )}
            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              style={{
                marginTop: '2rem',
              }}
              onClick={handleButton}
            >
              {heading}
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default ActionsModal;
