import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaChevronRight, FaFolderOpen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { handleDataTableRowSelection } from '../../../../../store/actions';
import {
  findDepartmentOwner,
  getFormattedDate,
  hasAdminUserAccess,
} from '../../../../_global/common/Utils';
import CategoryItem from '../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import { User } from '../../../../../models';
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ViewportList } from 'react-viewport-list';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import Status from '../../../../components/ProgressStatus/ProgressStatus';
import { IoDocumentText } from 'react-icons/io5';
import OwnerImage from '../../../../components/OwnerImage/OwnerImage';
import { FaArrowDown, FaArrowUp, FaLock } from 'react-icons/fa6';
import { globals } from '../../../../_global/common/Utils';
import HMSwitch from '../../../../components/general/HMSwitch';
import HMCheckbox from '../../../../components/general/HMCheckbox';
import { isEqual } from 'lodash';

// Helper function to get the clientY position of a DOM element
const getClientYPosition = (draggableId: string) => {
  const draggableElement = document.querySelector(
    `[data-rbd-drag-handle-draggable-id='${draggableId}']`
  );
  if (draggableElement) {
    const { top, bottom } = draggableElement.getBoundingClientRect();
    return (top + bottom) / 2; // Get the vertical midpoint of the dragged element
  }
  return null;
};

// Custom auto-scroll function using requestAnimationFrame
const autoScroll = (event: DragUpdate) => {
  const scrollableContainer = document.querySelector('.protocolListContainer'); // Adjust selector as needed
  if (!scrollableContainer) return;

  const threshold = 100; // Distance from top or bottom to start scrolling
  const maxScrollSpeed = 500; // Maximum speed for scrolling
  const minScrollSpeed = 10; // Minimum speed for scrolling
  const { destination, draggableId } = event;

  // Early exit if there is no destination (i.e., dragging outside droppable area)
  if (!destination) return;

  // Get the clientY position of the dragged item
  const clientY = getClientYPosition(draggableId);
  if (clientY === null) return;

  // Get container's bounding rectangle
  const { top, bottom } = scrollableContainer.getBoundingClientRect();

  // Determine the speed based on proximity to edges
  let scrollSpeed = 0;
  if (clientY - top < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (clientY - top) / threshold),
      minScrollSpeed
    );
  } else if (bottom - clientY < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (bottom - clientY) / threshold),
      minScrollSpeed
    );
  }

  // Scroll the container using requestAnimationFrame for smoother performance
  const scroll = () => {
    if (scrollSpeed !== 0) {
      scrollableContainer.scrollBy({
        top: scrollSpeed > 0 ? scrollSpeed : -scrollSpeed,
      });
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
interface ProtocolDataTableProps {
  handleTabClick: (
    item: CategoryItem | ProtocolItem,
    isDoubleClick: boolean
  ) => void;
  handleToggleEnabled: (item: CategoryItem | ProtocolItem) => void;
  protocolData: (CategoryItem | ProtocolItem)[];
  newButtonRef?: any;
  isRename?: boolean;
  isModalOpen?: boolean;
  isEditFolderModalOpen?: boolean;
  isMoveModalOpen?: boolean;
  handleRename?: any;
  isDeleteModalOpen?: boolean;
  handleReorder: (items: (CategoryItem | ProtocolItem)[]) => void;
}

/* 10-10-2023 Arul: Created the seperate datatable component for Edit Folder Page*/
const ProtocolDataTable: React.FC<ProtocolDataTableProps> = ({
  handleTabClick,
  handleToggleEnabled,
  handleRename,
  isModalOpen,
  isEditFolderModalOpen,
  isMoveModalOpen,
  isRename,
  isDeleteModalOpen,
  protocolData,
  newButtonRef,
  handleReorder,
}) => {
  const [folderData, setFolderData] = useState<any>([]);
  const dataTableRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const tableData = useSelector((state: any) => state?.protocol?.editDatatable);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database?.department;
  const [inputValue, setInputValue] = useState<any>('');
  const [nameChanged, setNameChanged] = useState<any>(false);
  const [isEditFolder, setIsEditFolder] = useState<any>(isEditFolderModalOpen);
  const [lastSelectedTime, setLastSelectedTime] = useState<any>(null);
  const [isHovered, setIsHovered] = useState<string | null>(null);

  const [showRestrictive, setShowRestrictive] = useState<boolean>(false);
  const [showIndex, setShowIndex] = useState<boolean>(false);
  const [isSelectAll, setIsSelectAll] = useState(false); // State for header checkbox

  const [switchStatus, setSwitchStatus] = useState<any[]>([]);
  const [sortField, setSortField] = useState('name'); // default sort field
  const [sortDirection, setSortDirection] = useState('none'); // default sort direction
  const [initialFolderData, setInitialFolderData] = useState<any>([]);

  const [currentSelection, setCurrentSelection] = useState<
    (CategoryItem | ProtocolItem)[] | null
  >(null);

  /* 10-20-2023 Arul: initialize the selected category and protocol name*/
  useEffect(() => {
    // ToDo:selectedRowData in array now, need to change to Work
    setInputValue(
      tableData.selectedRowData && tableData.selectedRowData[0]?.name
    );
    setNameChanged(false);
  }, [tableData.selectedRowData]);

  // Update currentSelection when tableData.selectedRowData changes
  useEffect(() => {
    if (!isEqual(currentSelection, tableData.selectedRowData)) {
      setCurrentSelection(tableData.selectedRowData);
    }
  }, [tableData.selectedRowData]);

  // Cleanup function to clear selection when component unmounts
  useEffect(() => {
    return () => {
      dispatch<any>(
        handleDataTableRowSelection({
          selectedRowData: null,
        })
      );
    };
  }, []); // Empty dependency array since it is needed only on mount/unmount

  // Update isSelectAll state based on currentSelection and folderData
  useEffect(() => {
    if (currentSelection) {
      const allSelectableItems = folderData.filter(
        (item: CategoryItem | ProtocolItem) =>
          hasAdminUserAccess(department, user, item) &&
          item.status !== 'DEACTIVATED'
      );

      setIsSelectAll(
        currentSelection.length === allSelectableItems.length &&
          allSelectableItems.length > 0
      );
    } else {
      setIsSelectAll(false);
    }
  }, [currentSelection, folderData, department, user]);

  useEffect(() => {
    setIsEditFolder(isEditFolderModalOpen);
  }, [isEditFolderModalOpen]);

  /* 10-20-2023 Arul: Update the Row selection and selected row data in state*/
  const handleUpdataRowSelection = (isSelected: boolean) => {
    if (!isModalOpen) {
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    }
  };

  const getItemSubs = (item: CategoryItem | ProtocolItem) => {
    let availableSubs = (department.allSubDeps?.length ?? 0) + 1; // Default available subs
    console.log(department.name, 'All Subs: ', availableSubs, department);
    let itemSubs: number = 0; // Default item subs

    if (item instanceof CategoryItem) {
      itemSubs = !item.isRestrictive
        ? availableSubs
        : (item.pairedDepIDs?.length ?? 0);
    } else if (item instanceof ProtocolItem) {
      const cat = item.parent;
      availableSubs = cat.isRestrictive
        ? (cat.pairedDepIDs?.length ?? -1)
        : availableSubs;
      const filteredSubs = item.pairedDepIDs?.filter((depID) =>
        !cat.isRestrictive ? true : cat.pairedDepIDs?.includes(depID)
      );
      itemSubs = item.isRestrictive
        ? (filteredSubs?.length ?? 0)
        : availableSubs;
    }

    return itemSubs;
  };

  // Sorting function
  const sortData = (data: (CategoryItem | ProtocolItem)[]) => {
    return data.sort(
      (a: CategoryItem | ProtocolItem, b: CategoryItem | ProtocolItem) => {
        // Default to zero (no change) if sort direction is none

        if (sortDirection === 'none') return 0;
        // Get item subscriptions for both items
        const itemSubsA = getItemSubs(a);
        const itemSubsB = getItemSubs(b);
        // Ascending sort
        if (sortDirection === 'asc') {
          switch (sortField) {
            case 'name':
              return a.name.localeCompare(b.name);
            case 'updatedAt':
              return (
                new Date(a?.model?.updatedAt as string).getTime() -
                new Date(b?.model?.updatedAt as string).getTime()
              );
            case 'modifiedBy':
              const userNameA: string = a.modifiedBy
                ? `${a.modifiedBy.firstName} ${a.modifiedBy.lastName}`
                : 'Hinckley Medical';
              const userNameB: string = b.modifiedBy
                ? `${b.modifiedBy.firstName} ${b.modifiedBy.lastName}`
                : 'Hinckley Medical';
              return userNameA.localeCompare(userNameB);
            case 'pairedDepIDs':
              return itemSubsA - itemSubsB;
            case 'isPublic':
              return Number(a.isPublic) - Number(b.isPublic); // Ensure boolean values are treated as numbers
            case 'size':
              return a.getSum() - b.getSum();
            default:
              return 0; // Fallback case
          }
        }
        // Descending sort
        else if (sortDirection === 'desc') {
          switch (sortField) {
            case 'name':
              return b.name.localeCompare(a.name);
            case 'updatedAt':
              return (
                new Date(b?.model?.updatedAt as string).getTime() -
                new Date(a?.model?.updatedAt as string).getTime()
              );
            case 'modifiedBy':
              const userNameADesc: string = a.modifiedBy
                ? `${a.modifiedBy.firstName} ${a.modifiedBy.lastName}`
                : 'Hinckley Medical';
              const userNameBDesc: string = b.modifiedBy
                ? `${b.modifiedBy.firstName} ${b.modifiedBy.lastName}`
                : 'Hinckley Medical';
              return userNameBDesc.localeCompare(userNameADesc);
            case 'pairedDepIDs':
              return itemSubsB - itemSubsA;
            case 'isPublic':
              return Number(b.isPublic) - Number(a.isPublic); // Ensure boolean values are treated as numbers
            case 'size':
              return b.getSum() - a.getSum();
            default:
              return 0; // Fallback case
          }
        }

        return 0; // Fallback case for no sorting
      }
    );
  };

  const handleSort = (field: any) => {
    //  sort the data based on the field "asc", "desc" or "none"
    if (sortField === field) {
      if (sortDirection === 'asc') {
        setSortDirection('desc');
      } else if (sortDirection === 'desc') {
        setSortDirection('none');
      } else {
        setSortDirection('asc');
      }
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  //  do the sorting here
  useEffect(() => {
    if (folderData.length > 0) {
      if (sortDirection === 'none') {
        setFolderData(initialFolderData);
      } else {
        const sortedData = sortData([...folderData]);
        setFolderData(sortedData);
      }
    }
  }, [sortField, sortDirection]);

  const getSortArrow = (field: string) => {
    const isSorted = sortField === field && sortDirection !== 'none';
    return (
      <div className={`arrow-container ${isSorted ? 'visible' : ''}`}>
        {sortDirection === 'asc' && isSorted ? (
          <FaArrowUp className="arrow-icon" />
        ) : sortDirection === 'desc' && isSorted ? (
          <FaArrowDown className="arrow-icon" />
        ) : (
          <FaArrowUp className="arrow-icon" />
        )}
      </div>
    );
  };

  // const reorder = (
  //   list: (CategoryItem | ProtocolItem)[],
  //   startIndex: number,
  //   endIndex: number
  // ): (CategoryItem | ProtocolItem)[] => {
  // //Verify the owner of the items are grouped together
  // const result = Array.from(list);
  // const [removed] = result.splice(startIndex, 1);
  // result.splice(endIndex, 0, removed);
  // return result;
  // };

  const reorder = (
    list: (CategoryItem | ProtocolItem)[],
    startIndex: number,
    endIndex: number
  ): (CategoryItem | ProtocolItem)[] => {
    // Determine if the item being dragged or the target is in a locked area
    const isStartLocked = hasAdminUserAccess(
      department,
      user,
      list[startIndex]
    );
    const isEndLocked = hasAdminUserAccess(department, user, list[endIndex]);

    // Prevent dragging an unlocked item into a locked area
    if (isStartLocked && !isEndLocked) return list;
    // let departmentOwner = findDepartmentOwner(department, list[startIndex]);

    //Verify the owner of the items are grouped together
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // function for dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      folderData,
      result.source.index,
      result.destination.index
    );
    setFolderData(updatedItems);
    setSwitchStatus(
      updatedItems.map((item) => {
        return {
          checked: item.status !== 'DEACTIVATED',
          item: item,
        };
      })
    );
    /* Udpate the database here */
    handleReorder(updatedItems);
    // onReorder(updatedItems, type);
  };

  // Function to handle custom scrolling
  // Custom auto-scroll function

  /* 10-20-2023 Arul: Mapping the table data in setstate*/
  useEffect(() => {
    if (protocolData?.length) {
      setFolderData(protocolData);
      setInitialFolderData(protocolData);
      setSwitchStatus(
        protocolData.map((item) => {
          return {
            checked: item.status !== 'DEACTIVATED',
            item: item,
          };
        })
      );
    } else {
      setFolderData([]);
      setInitialFolderData([]);
      setSwitchStatus([]);
    }
  }, [protocolData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Example: Detect "Ctrl + N"
      if (event.ctrlKey && event.shiftKey && event.key === 'R') {
        event.preventDefault();
        setShowRestrictive(!showRestrictive);
      } else if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
        setShowIndex(!showIndex);
      } else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        event.preventDefault();
        if (tableData?.selectedRowData?.length > 0) {
          for (let i = 0; i < tableData?.selectedRowData?.length; i++) {
            const item = tableData?.selectedRowData[i] as
              | CategoryItem
              | ProtocolItem;
            console.log(i, item.TAG, item);
          }
        }
      } else if (event.ctrlKey && event.shiftKey && event.key === 'A') {
        event.preventDefault();
        handleSelectAll(event);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showRestrictive, showIndex, isSelectAll, tableData?.selectedRowData]);

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [
    isModalOpen,
    inputValue,
    isEditFolder,
    isMoveModalOpen,
    isRename,
    isDeleteModalOpen,
  ]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (
      dataTableRef?.current?.contains(e.target) ||
      isRename ||
      isEditFolder ||
      isMoveModalOpen ||
      isDeleteModalOpen
    ) {
      return;
    }
    if (globals.debug)
      console.log(
        'DOCUMENT CLICKED',
        inputValue,
        nameChanged,
        isEditFolderModalOpen
      );
    console.log('tableData?.selectedRowData', tableData?.selectedRowData);
    if (tableData?.selectedRowData?.length > 0) {
      handleUpdataRowSelection(false);
    }
    // handleUpdataRowSelection(false);
    if (nameChanged && inputValue !== '') handleRename(inputValue);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (globals.debug) console.log('ENTER PRESSED', inputValue, nameChanged);
      if (nameChanged && inputValue !== '') handleRename(inputValue);
    }
  };

  /* 10-11-2023  Arul:Function to handle the single onrow click */
  const handleRowClick = (item: ProtocolItem | CategoryItem) => {
    handleRename('');

    const isItemSelected = currentSelection?.some(
      (selected) => selected.uid === item.uid
    );

    dispatch<any>(
      handleDataTableRowSelection({
        selectedRowData: isItemSelected ? null : [item],
      })
    );
  };

  const handleCheckboxClick = (e: any, item: ProtocolItem | CategoryItem) => {
    e.stopPropagation();
    handleRename('');

    const newSelection = currentSelection?.some(
      (selected) => selected.uid === item.uid
    )
      ? currentSelection.filter((selected) => selected.uid !== item.uid)
      : [...(currentSelection ?? []), item];

    dispatch<any>(
      handleDataTableRowSelection({
        selectedRowData: newSelection,
      })
    );
  };

  const handleInputChange = (e: any) => {
    setNameChanged(true);
    setInputValue(e.target.value);
  };

  const handleItemClick = (e: any, item: CategoryItem | ProtocolItem) => {
    e.stopPropagation();
    if (lastSelectedTime && new Date().getTime() - lastSelectedTime < 300)
      handleTabClick(item, true);
    else {
      handleRowClick(item);
    }
    setLastSelectedTime(new Date().getTime());
  };

  const handleSelectAll = (e: any) => {
    e.stopPropagation();
    handleRename('');

    const selectableItems = folderData.filter(
      (item: CategoryItem | ProtocolItem) =>
        hasAdminUserAccess(department, user, item) &&
        item.status !== 'DEACTIVATED'
    );

    // If everything is selected, clear selection. Otherwise select all selectable items
    const newSelection = isSelectAll ? [] : selectableItems;

    // if (isSelectAll) {
    //   tableData.selectedRowData = [];
    // } else {
    //   tableData.selectedRowData = [
    //     ...folderData.filter((item: CategoryItem | ProtocolItem) => {
    //       return (
    //         hasAdminUserAccess(department, user, item) &&
    //         item.status !== 'DEACTIVATED'
    //       );
    //     }),
    //   ];
    // }
    // setIsSelectAll(!isSelectAll);

    dispatch<any>(
      handleDataTableRowSelection({
        selectedRowData: newSelection,
      })
    );
  };

  const isAllRowsSelected = useMemo(() => {
    if (!currentSelection || !folderData.length) return false;

    const selectableItems = folderData.filter(
      (item: CategoryItem | ProtocolItem) =>
        hasAdminUserAccess(department, user, item) &&
        item.status !== 'DEACTIVATED'
    );

    return (
      selectableItems.length > 0 &&
      currentSelection.length === selectableItems.length
    );
  }, [currentSelection, folderData, department, user]);

  return (
    <div>
      <div
        className="editDataTableHeader"
        style={{
          display: 'flex',
          padding: '4px 20px',
          fontWeight: 'bold',
          alignItems: 'center',
          border: '1px solid #ccc',
          borderRadius: '5px',
          borderBottom: 'none',
          fontSize: '15px',
        }}
      >
        <span
          style={{
            flex: 1,
            textAlign: 'start',
            display: 'flex',
            alignItems: 'center',
            borderRight: '1px solid #ccc',
            cursor: 'pointer',
            marginLeft: '-12px',
          }}
        >
          <HMCheckbox
            checked={isAllRowsSelected}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              handleSelectAll(event);
            }}
          />
        </span>

        <span
          style={{
            textAlign: 'start',
            flex: 10,
            marginLeft: '20px',
            borderRight: '1px solid #ccc',
          }}
        >
          Name
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => handleSort('name')}
          >
            {getSortArrow('name')}
          </span>
        </span>
        <span
          style={{
            flex: 6,
            borderRight: '1px solid #ccc',
          }}
        >
          Modified At
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => handleSort('updatedAt')}
          >
            {getSortArrow('updatedAt')}
          </span>
        </span>
        <span
          style={{
            flex: 6,
            borderRight: '1px solid #ccc',
          }}
        >
          Modified By
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => handleSort('modifiedBy')}
          >
            {getSortArrow('modifiedBy')}
          </span>
        </span>
        {department.subDeps && department.subDeps.length > 0 && (
          <span
            style={{
              flex: 6,
              borderRight: '1px solid #ccc',
            }}
          >
            Subs
            <span
              style={{ cursor: 'pointer', marginLeft: '5px' }}
              onClick={() => handleSort('pairedDepIDs')}
            >
              {getSortArrow('pairedDepIDs')}
            </span>
          </span>
        )}
        <span
          style={{
            flex: 6,
            borderRight: '1px solid #ccc',
          }}
        >
          Access
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => handleSort('isPublic')}
          >
            {getSortArrow('isPublic')}
          </span>
        </span>
        {department.isMultiDep && (
          <span
            style={{
              flex: 6,
              borderRight: '1px solid #ccc',
            }}
          >
            Owner
          </span>
        )}
        <span
          style={{
            flex: 3,
            marginLeft: '6px',
          }}
        >
          Files
          <span
            style={{ cursor: 'pointer', marginLeft: '5px' }}
            onClick={() => handleSort('size')}
          >
            {getSortArrow('size')}
          </span>
        </span>
      </div>

      <DragDropContext onDragUpdate={autoScroll} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="protocolListContainer"
            >
              {folderData.map(
                (item: CategoryItem | ProtocolItem, index: any) => {
                  const isSelected = currentSelection?.some(
                    (selectedItem) => selectedItem.uid === item.uid
                  );
                  // const isSelected = tableData.selectedRowData?.includes(item);
                  // console.log('isSelected for index', isSelected, index);

                  const userName: string = item?.modifiedBy
                    ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                    : 'Hinckley Medical';
                  const adminAccess = hasAdminUserAccess(department, user);
                  console.log('ADMIN ACCESS', adminAccess);
                  const departmentOwner = findDepartmentOwner(department, item);
                  const isOwner = item.departmentID === department.id ?? false;
                  let availableSubs = (department.allSubDeps?.length ?? 0) + 1;
                  let itemSubs = !item.isRestrictive
                    ? availableSubs
                    : (item.pairedDepIDs?.length ?? 0);
                  let status: 'Private' | 'Public' | 'Protected' =
                    !item.isPublic
                      ? 'Private'
                      : item.keychainID
                        ? 'Protected'
                        : 'Public';

                  if (item instanceof ProtocolItem) {
                    const cat = item.parent;
                    availableSubs = cat.isRestrictive
                      ? (cat.pairedDepIDs?.length ?? -1)
                      : availableSubs;
                    let filteredSubs = item.pairedDepIDs?.filter((depID) =>
                      !cat.isRestrictive
                        ? true
                        : cat.pairedDepIDs?.includes(depID)
                    );
                    itemSubs = item.isRestrictive
                      ? (filteredSubs?.length ?? 0)
                      : availableSubs;
                    if (!item.parent.isPublic) {
                      status = 'Private';
                    }
                  }
                  return (
                    <Draggable
                      key={item.uid}
                      draggableId={item.uid}
                      isDragDisabled={
                        !(isOwner && item.status !== 'DEACTIVATED')
                      }
                      index={index}
                    >
                      {(provided: any) => (
                        <li
                          key={index}
                          onClick={(e) => handleItemClick(e, item)}
                          // onMouseEnter={() => setIsHovered(item.uid)}
                          // onMouseLeave={() => setIsHovered(null)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`cursorPointer row_unselected spanFormat ${index === folderData.length - 1 ? '' : 'underline'}`}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: isSelected
                              ? '#E0EADD'
                              : isHovered === item.uid
                                ? '#f3f7f2'
                                : !(isOwner && item.status !== 'DEACTIVATED')
                                  ? '#f2f2f2'
                                  : undefined,
                            display: 'flex',
                            //7-10-24 Hazlett: PSA Cannot do grid format and a draggable item hd to switch to flex
                          }}
                        >
                          {adminAccess && (
                            <>
                              {isOwner && item.status !== 'DEACTIVATED' ? (
                                <div
                                  style={{
                                    flex: 1,
                                    cursor: 'pointer',
                                    marginLeft: '-12px',
                                    marginRight: '12px',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCheckboxClick(e, item);
                                  }}
                                >
                                  <HMCheckbox
                                    checked={isSelected}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>,
                                      checked: boolean
                                    ) => {
                                      handleCheckboxClick(event, item);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    flex: 1,
                                    cursor: 'pointer',
                                    marginLeft: '-16px',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleToggleEnabled(item);
                                    //Toggle the checked status
                                    setSwitchStatus(
                                      switchStatus.map((status) => {
                                        if (status.item.uid === item.uid) {
                                          return {
                                            checked: !status.checked,
                                            item: item,
                                          };
                                        } else {
                                          return status;
                                        }
                                      })
                                    );
                                  }}
                                >
                                  <HMSwitch
                                    // tooltipText={`Enable/Disable ${item.name}. This will remove it for all users of ${department.name}.`}
                                    checked={item.status !== 'DEACTIVATED'}
                                    // checked={item.status === 'DEACTIVATED'}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      handleToggleEnabled(item);
                                      //Toggle the checked status
                                      setSwitchStatus(
                                        switchStatus.map((status) => {
                                          if (status.item.uid === item.uid) {
                                            return {
                                              checked: !status.checked,
                                              item: item,
                                            };
                                          } else {
                                            return status;
                                          }
                                        })
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </>
                          )}

                          <div
                            style={{
                              flex: 1,
                            }}
                          >
                            {!isOwner || item.status === 'DEACTIVATED' ? (
                              <FaLock
                                className="table-icon"
                                style={{
                                  marginBottom: '4px',
                                }}
                                color="#A3A3A3"
                              />
                            ) : item instanceof CategoryItem ? (
                              <FaFolderOpen
                                className="table-icon"
                                style={{
                                  marginBottom: '4px',
                                }}
                                color={!adminAccess ? '#A3A3A3' : '#636363'}
                              />
                            ) : (
                              <IoDocumentText
                                className="table-icon"
                                style={{ margin: '0 4px' }}
                              />
                            )}
                          </div>

                          {isOwner && isRename && isSelected ? (
                            <form
                              style={{
                                flex: 9,
                              }}
                              onSubmit={() => {
                                handleRename(inputValue);
                              }}
                            >
                              <InputText
                                value={inputValue}
                                autoFocus
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ padding: '10px', margin: '-10px' }}
                              />
                            </form>
                          ) : (
                            <div
                              className="titleItemContainer"
                              style={{
                                flex: 10,
                              }}
                            >
                              <div className="titleItemText">
                                <div
                                  className={`hoverableText ${!(isOwner && item.status !== 'DEACTIVATED') ? 'disabled' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTabClick(item, true);
                                  }}
                                >
                                  {(showIndex ? item?.index + ' - ' : '') +
                                    item?.name}
                                </div>
                              </div>
                              {item.status !== 'ACTIVE' && (
                                <Status status={item.status} />
                              )}
                            </div>
                          )}

                          <span
                            className="protocolDatatableText"
                            style={{
                              textAlign: 'center',
                              flex: 6,
                              color: !(isOwner && item.status !== 'DEACTIVATED')
                                ? '#636363'
                                : '#000',
                            }}
                          >
                            {getFormattedDate(
                              item?.model?.updatedAt
                                ? item?.model?.updatedAt
                                : new Date(),
                              true
                            )}
                          </span>
                          <span
                            className="protocolDatatableText"
                            style={{
                              flex: 6,
                              textAlign: 'center',
                              color: !(isOwner && item.status !== 'DEACTIVATED')
                                ? '#636363'
                                : '#000',
                            }}
                          >
                            {userName}
                          </span>
                          {department &&
                            department.allSubDeps &&
                            department.allSubDeps.length > 0 && (
                              <span style={{ flex: 6, textAlign: 'center' }}>
                                {itemSubs + '/' + availableSubs}
                                {showRestrictive && (
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#636363',
                                    }}
                                  >
                                    {item.isRestrictive
                                      ? ' (Restrictive)'
                                      : '(Inclusive)'}
                                  </span>
                                )}
                              </span>
                            )}
                          <span style={{ flex: 6, textAlign: 'center' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Status status={status} />
                            </div>
                          </span>
                          {department.isMultiDep && (
                            <span style={{ flex: 6, textAlign: 'center' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {departmentOwner && (
                                  <OwnerImage
                                    owner={departmentOwner}
                                    // isLocked={!adminAccess}
                                    size={32}
                                  />
                                )}
                              </div>
                            </span>
                          )}

                          <span
                            style={{
                              flex: 3,
                              textAlign: 'center',
                              color: !(isOwner && item.status !== 'DEACTIVATED')
                                ? '#636363'
                                : '#000',
                            }}
                          >
                            {item?.getSum()}
                            <span>
                              <FaChevronRight
                                className="icon-normal "
                                style={{ margin: '4px' }}
                              />
                            </span>
                          </span>
                        </li>
                      )}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ProtocolDataTable;
