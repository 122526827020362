import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import ProtocolItem from './ProtocolItem';
import InfusionItem from './InfusionItem';
import {
  roundToDec,
  globals,
  isObjectEqual,
} from '../../ui/_global/common/Utils';
import {
  Concentration,
  InfusionDose,
  LazyInfusionDose,
  MedicationConcentration,
  MedicationRoute,
} from '../../models';
import { Drip } from '../../models';
import { PatientAgeGroup } from '../../API';
import PatientAge from '../../ui/_global/common/PatientAge';
import ModelSubItem from './ModelSubItem';

type DoseResult = {
  dose: number;
  doseHigh: number;
  doseUnit: string;
  isCalc: boolean;
};

export const cloneInfusionSubItems = (
  meds: InfusionSubItem[]
): InfusionSubItem[] => {
  let clone: InfusionSubItem[] = [];
  for (let i = 0; i < meds.length; i++)
    clone.push(
      new InfusionSubItem(meds[i].parent, meds[i].parentProtocol, meds[i].model)
    );
  return clone;
};

export const cloneInfusionSubItem = (med: InfusionSubItem): InfusionSubItem => {
  return new InfusionSubItem(med.parent, med.parentProtocol, med.model);
};

const timeUnits = ['sec', 'min', 'hr', 'seconds', 'minutes', 'hours'];

class InfusionSubItem extends ModelSubItem<InfusionDose> {
  parent: InfusionItem;
  overrideItem: InfusionSubItem | null | undefined;
  activeItem: InfusionSubItem | null | undefined;

  concentrations: Concentration[];
  oldConcentrations: MedicationConcentration[];

  fullBasisUnit: string;
  basisUnit: string;
  calcUnit: string;
  maxDoseUnit: string;
  timeUnit?: string;

  routes: string[];
  nemsisRoutes: MedicationRoute[];

  constructor(
    medication: InfusionItem,
    parent: ProtocolItem,
    range: InfusionDose
  ) {
    // console.log('NEMSIS ROUTES', range.route, range.nemsisRoutes);
    super(medication, parent, range);
    this.TAG = 'InfusionSubItem';
    this.parent = medication;
    this.concentrations = medication.concentrations ?? [];
    this.oldConcentrations = medication.oldConcentrations ?? [];

    this.routes = range.route ? range.route : [];
    this.nemsisRoutes = range.nemsisRoutes ? range.nemsisRoutes : [];

    this.fullBasisUnit = '';
    this.basisUnit = '';
    this.calcUnit = '';
    this.maxDoseUnit = '';

    try {
      if (range.basis === '-1') {
        this.basis = -1;
        this.basisHigh = globals.MAX_VALUE;
      } else {
        /* ---------------- Parse the data for the basis variables ---------------- */
        let basisArr: string[] = range.basis.split(' ', 2);

        /* Parse basis amount to a double for comparison */
        let basisNums: string[] = basisArr[0].split('-');
        this.basis = parseFloat(basisNums[0]);
        if (basisNums.length > 1) this.basisHigh = parseFloat(basisNums[1]);
        else this.basisHigh = globals.MAX_VALUE;

        /* Parse the units for the basis to validate calculations */
        this.fullBasisUnit = basisArr[1];
        let basisUnitsArr: string[] = basisArr[1].split('/');
        this.basisUnit = basisUnitsArr[0];
        if (basisUnitsArr.length > 1) {
          this.calcUnit = basisUnitsArr[1];
          if (basisUnitsArr.length > 2) {
            this.timeUnit = basisUnitsArr[2];
            if (this.timeUnit !== 'hr' && this.timeUnit !== 'min') {
              console.log(
                this.TAG,
                'ERROR: ' +
                  medication.getName() +
                  ' second infusion unit is not kg, min, or hr  -> ' +
                  this.calcUnit +
                  ' basis: ' +
                  range.basis
              );
            }
          } else if (this.calcUnit === 'hr' || this.calcUnit === 'min') {
            this.timeUnit = this.calcUnit;
            this.calcUnit = '';
          } else
            console.log(
              this.TAG,
              'ERROR: ' +
                medication.getName() +
                ' second infusion unit is not kg, min, or hr  -> ' +
                this.calcUnit +
                ' basis: ' +
                range.basis
            );
        }

        /* ---------------- Parse the data for the max dose variables ---------------- */
        let { dose, doseHigh, doseUnit, isCalc } = this.parseDose(
          range.maxDose ?? ''
        );
        this.maxDose = dose;
        this.maxDoseUnit = doseUnit;

        let { dose: minDose } = this.parseDose(
          range.minDose ?? '',
          undefined,
          'min'
        );
        this.minDose = minDose;

        let { dose: maxTotalDose } = this.parseDose(range.maxTotalDose ?? '');
        this.maxTotalDose = maxTotalDose;
        // if (range.maxDose && range.maxDose !== '') {
        //   let maxDoseArr: string[] = range.maxDose.split(' ', 2);

        //   if (maxDoseArr.length != 2) {
        //     if (globals.debug)
        //       if (globals.debug)
        //         console.log(
        //           this.TAG,
        //           "ERROR: Max dose is not in the format of 'number units' -> " +
        //             range.maxDose +
        //             ' length: ' +
        //             maxDoseArr.length
        //         );
        //     this.maxDose = globals.MAX_VALUE;
        //     return;
        //   }

        //   /* Parse basis amount to a double for comparison */
        //   this.maxDose = parseFloat(maxDoseArr[0]);

        //   /* Parse the units for the basis to validate calculations */
        //   this.maxDoseUnit = maxDoseArr[1];
        //   if (this.maxDoseUnit !== this.basisUnit)
        //     if (globals.debug)
        //       if (globals.debug)
        //         console.log(
        //           this.TAG,
        //           'ERROR: Max dose unit does not match basis unit -> ' +
        //             range.maxDose +
        //             ' basis: ' +
        //             this.basisUnit +
        //             ' maxDose: ' +
        //             this.maxDoseUnit
        //         );
        // }

        // if (range.minDose && range.minDose !== '') {
        //   let maxDoseArr: string[] = range.minDose.split(' ', 2);

        //   if (maxDoseArr.length != 2) {
        //     if (globals.debug)
        //       if (globals.debug)
        //         console.log(
        //           this.TAG,
        //           "ERROR: Max dose is not in the format of 'number units' -> " +
        //             range.maxDose +
        //             ' length: ' +
        //             maxDoseArr.length
        //         );
        //     this.minDose = 0;
        //     return;
        //   }

        //   /* Parse basis amount to a double for comparison */
        //   this.minDose = parseFloat(maxDoseArr[0]);

        //   /* Parse the units for the basis to validate calculations */
        //   this.minDoseUnit = maxDoseArr[1];
        //   if (this.maxDoseUnit !== this.basisUnit)
        //     if (globals.debug)
        //       if (globals.debug)
        //         console.log(
        //           this.TAG,
        //           'ERROR: Max dose unit does not match basis unit -> ' +
        //             range.maxDose +
        //             ' basis: ' +
        //             this.basisUnit +
        //             ' maxDose: ' +
        //             this.maxDoseUnit
        //         );
        // }

        let { time, timeHigh } = this.parseRepeatTime(range.repeatTime ?? '');
        this.repeatTimeSec = time;
      }
    } catch (e) {
      if (globals.debug)
        if (globals.debug)
          console.log(
            this.TAG,
            'ERROR: Failed to parse basis -> ' + range.basis
          );
    }
  }

  parseRepeatTime(repeatTime: string, isConfirmUnits: boolean = true): any {
    if (repeatTime === '' || repeatTime === '0')
      return {
        time: -1,
        timeHigh: -1,
      };
    let time: number = -1;
    let timeHigh: number = -1;
    let timeArr: string[] = repeatTime.split(' ', 2);

    /* Parse dose amount to a double for comparison */
    let timeNums: string[] = timeArr[0].split('-');
    time = Number(timeNums[0]);
    if (timeNums.length > 1) timeHigh = parseFloat(timeNums[1]);

    /* Parse the units for the dose to validate calculations */
    if (timeArr.length > 1) {
      let units = timeArr[1];
      if (timeArr.length > 2)
        console.error(
          'ERROR: ' +
            this.name +
            ' Repeat time has more than 2 units -> ' +
            repeatTime
        );
      if (timeUnits.indexOf(units.toLocaleLowerCase()) === -1) {
        console.error(
          'ERROR: ' +
            this.name +
            ' Repeat time unit is not valid -> ' +
            repeatTime
        );
      } else {
        if (['min', 'minutes'].indexOf(units.toLocaleLowerCase()) !== -1) {
          time = time * 60;
          timeHigh = timeHigh * 60;
        } else if (['hr', 'hours'].indexOf(units.toLocaleLowerCase()) !== -1) {
          time = time * 3600;
          timeHigh = timeHigh * 3600;
        }
      }
      return {
        time: time,
        timeHigh: timeHigh,
      };
    }

    return { time: time, timeHigh: timeHigh };
  }

  parseDose(
    dose: string,
    isConfirmUnits: boolean = true,
    type: 'max' | 'min' = 'max'
  ): DoseResult {
    if (dose === '' || dose === '0')
      return {
        dose: type === 'max' ? globals.MAX_VALUE : 0,
        doseHigh: 0,
        doseUnit: this.basisUnit,
        isCalc: false,
      };
    let doseHigh: number = -1;
    let doseUnit: string = '';
    let isCalc: boolean = false;
    let doseArr: string[] = dose.split(' ', 2);

    /* Parse dose amount to a double for comparison */
    let doseNums: string[] = doseArr[0].split('-');
    let doseNum: number = parseFloat(doseNums[0]);
    if (doseNums.length > 1) doseHigh = parseFloat(doseNums[1]);

    /* Parse the units for the dose to validate calculations */
    let doseUnitsArr: string[] = doseArr[1].split('/');
    let doseUnits: string = doseUnitsArr[0];
    if (doseUnitsArr.length > 1) {
      let calcUnits: string = doseUnitsArr[1];
      if (isConfirmUnits) {
        if ('kg' !== calcUnits.toLocaleLowerCase()) {
          console.log(
            this.TAG,
            'ERROR: ' +
              this.name +
              ' Dose unit calculation is not ' +
              'Kilograms (kg)' +
              ' -> ' +
              calcUnits +
              ' dose: ' +
              dose
          );
        }
        isCalc = true;
      }
    }

    /* Check if the dose is in the correct units */
    if (isConfirmUnits && this.basisUnit !== doseUnits) {
      console.log(
        this.TAG,
        'ERROR: ' +
          this.name +
          ' Dose unit is not ' +
          this.basisUnit +
          ' -> ' +
          doseUnits +
          ' dose: ' +
          dose
      );
    }

    return { dose: doseNum, doseHigh: doseHigh, doseUnit: doseUnits, isCalc };
  }

  getPatientAgeGroup(str: any): PatientAgeGroup | null {
    switch (str) {
      case 'ADULT':
        return PatientAgeGroup.ADULT;
      case 'NEONATE':
        return PatientAgeGroup.NEONATE;
      case 'PEDIATRIC':
        return PatientAgeGroup.PEDIATRIC;
      case 'GERIATRIC':
        return PatientAgeGroup.GERIATRIC;
      default:
        return null;
    }
  }

  /**
   * function get the amount to administer in liquid and solid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  getAmount(weight: PatientWeight): string {
    if (!this.inRange(weight)) return '';

    if (this.basis <= 0 || this.basis == globals.MAX_VALUE) return '';

    if (this.basisHigh == globals.MAX_VALUE)
      return `${roundToDec(this.basis, 1).toFixed(1)}`;
    return `${roundToDec(this.basis, 1).toFixed(1)}-${roundToDec(this.basisHigh, 1).toFixed(1)}`;
  }

  /**
   * Get the amount to administer in solid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in solid form Ex. "10.2 mg"
   */
  getDoseSolid(weight: PatientWeight, selectedConcentration: number): string {
    /* Base case is no concentration */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      this.basis == 0.0
    )
      return '';

    /* If there is multiple concentrations and it has not been selected return nothing */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '--' + this.concentrations[0].firstUnit;
    else if (this.concentrations.length == 1) selectedConcentration = 0;

    let concentration: Concentration =
      this.concentrations[selectedConcentration];

    if (concentration == null) return '';

    /* if there is no calculation needed just return required amount - Ex. Glucagon (0.5 mg) */
    if (concentration.secAmnt == null || concentration.secAmnt <= 0) {
      //TODO need to validate it will be null in a single concentration
      /* No dose range, return the dose in primary units */
      let dose: number =
        this.calcUnit === null ? this.basis : this.basis * weight.getWeightKg();
      dose = Math.min(dose, this.maxDose);
      let dec1 = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;

      if (this.basisHigh == globals.MAX_VALUE)
        return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number =
          this.calcUnit === null
            ? this.basisHigh
            : this.basisHigh * weight.getWeightKg();
        doseHigh = Math.min(dose, this.maxDose);
        let dec2 = doseHigh > 99.9 ? 0 : doseHigh > 9.9 ? 1 : 2;

        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;

        /* If the doses are different return a range in primary units */
        return `${roundToDec(dose, dec1).toFixed(dec1)}-${roundToDec(doseHigh, dec2).toFixed(dec2)} ${concentration.firstUnit}`;
      }
    } else {
      /* No dose range, return the dose in primary units */
      let dose: number = Math.min(
        weight.getWeightKg() * this.basis,
        this.maxDose
      );
      let dec1 = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;
      if (this.basisHigh == globals.MAX_VALUE)
        return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number = roundToDec(
          Math.min(weight.getWeightKg() * this.basisHigh, this.maxDose),
          1
        );
        let dec2 = doseHigh > 99.9 ? 0 : doseHigh > 9.9 ? 1 : 2;
        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return `${roundToDec(dose, dec2).toFixed(dec2)} ${concentration.firstUnit}`;

        /* If the doses are different return a range in primary units */
        return `${roundToDec(dose, dec1).toFixed(dec1)}-${roundToDec(doseHigh, dec2).toFixed(dec2)} ${concentration.firstUnit}`;
      }
    }
  }

  /**
   * Get the unit of the solid form
   * @returns The unit of the solid form Ex. "mg"
   */
  getSolidUnit(): string {
    if (this.concentrations == null || this.concentrations.length == 0)
      return '';
    return this.concentrations[0].firstUnit;
  }

  /**
   * Get the amount to administer in liquid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in liquid form Ex. "1.5 mL"
   */
  getDoseLiquid(weight: PatientWeight, selectedConcentration: number): string {
    /* Base case is no concentration */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      this.basis == 0.0
    )
      return '';

    /* If there is multiple concentrations and it has not been selected return nothing */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '--' + this.concentrations[0].secUnit;
    else if (this.concentrations.length == 1) selectedConcentration = 0;

    let concentration: Concentration =
      this.concentrations[selectedConcentration];

    if (
      concentration == null ||
      !concentration.secAmnt ||
      concentration.secAmnt <= 0
    )
      return '';

    /* No dose range, return the dose in primary units */
    let concentrationAmount: number =
      concentration.firstAmnt / concentration.secAmnt;
    let dose: number =
      Math.min(weight.getWeightKg() * this.basis, this.maxDose) /
      concentrationAmount;

    if (this.basisHigh == globals.MAX_VALUE)
      return `${roundToDec(dose, 1).toFixed(1)} ${concentration.secUnit}`;
    /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
      let doseHigh: number =
        roundToDec(
          Math.min(weight.getWeightKg() * this.basisHigh, this.maxDose),
          1
        ) / concentrationAmount;

      /* If the doses are the same return the dose in primary units */
      if (dose == doseHigh)
        return `${roundToDec(dose, 1).toFixed(1)} ${concentration.secUnit}`;

      /* If the doses are different return a range in primary units */
      return `${roundToDec(dose, 1).toFixed(1)}-${roundToDec(doseHigh, 1).toFixed(1)} ${concentration.secUnit}`;
    }
  }

  /**
   * Get the unit of the liquid form
   * @returns The unit of the liquid form Ex. "mL"
   */
  getLiquidUnit(): string {
    if (
      this.concentrations[0] == null ||
      this.concentrations[0].secAmnt == null
    )
      return '';
    return this.concentrations[0].secUnit ?? '';
  }

  /**
   * Get the string representation of the routes
   * @returns The string representation of the routes Ex. "IV / IO"
   */
  getRoutesString(): string {
    if (this.routes == null || this.routes.length == 0) return '';
    let str = '';
    for (let i = 0; i < this.routes.length; i++) {
      if (i == this.routes.length - 1) str += this.routes[i];
      else str += this.routes[i] + ' / ';
    }
    return str;
  }

  /**
   * Get the string representation of the concentration
   * @returns The string representation of the concentration Ex. "1 mg/mL"
   */
  getConcentration(i: number): string {
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      i >= this.concentrations.length
    )
      return '';

    if (this.concentrations.length > 1 && i == -1) return 'Input Required';
    else if (this.concentrations.length == 1) i = 0;

    let str =
      this.concentrations[i].firstAmnt + ' ' + this.concentrations[i].firstUnit;
    if (this.concentrations[i].secAmnt ?? 0 > 0) {
      str += '/';
      if (this.concentrations[i].secAmnt != 1.0)
        str +=
          this.concentrations[i].secAmnt + ' ' + this.concentrations[i].secUnit;
      else str += this.concentrations[i].secUnit;
    }
    return str;
  }

  /**
   * Compare this InfusionItem to another InfusionItem first by name then by concentration
   * @param other A InfusionItem to compare to
   * @returns 0 if the two items are equal, 1 if this item is greater than the other, -1 if this item is less than the other
   */
  compareTo(other: ModelSubItem<any>): number {
    if (other instanceof InfusionSubItem) {
      let med = other as InfusionSubItem;
      if (this.name === med.name) {
        return this.uid === med.uid ? 0 : this.uid > med.uid ? 1 : -1;
      } else if (this.name > med.name) return 1;
      return -1;
    }
    return -1;
  }

  equals(other: ModelItem<any>): boolean {
    if (other instanceof InfusionSubItem) return isObjectEqual(this, other);
    return false;
  }

  /**
   * Parse the basis string into a number and units
   * @returns The string representation of the basis Ex. "0.5 mg/kg"
   */
  public getBasisString(): string {
    if (this.basisHigh == globals.MAX_VALUE) {
      if (this.calcUnit == null) return this.basis + ' ' + this.basisUnit;
      return this.basis + ' ' + this.basisUnit + '/' + this.calcUnit;
    } else {
      if (this.calcUnit == null)
        return this.basis + '-' + this.basisHigh + ' ' + this.basisUnit;
      return (
        this.basis +
        '-' +
        this.basisHigh +
        ' ' +
        this.basisUnit +
        '/' +
        this.calcUnit
      );
    }
  }

  /**
   * Get the lowest liquid amount to administer to the patient based on their weight. This is used for the slider
   * @param weight The patient's weight
   * @returns The number amount to administer in lowest liquid form to the patient based on their weight
   */
  getAmountLow(weight: PatientWeight, selectedConcentration: number): number {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: Concentration = this.concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number =
      this.calcUnit === null ? this.basis : this.basis * weight.getWeightKg();
    return roundToDec(Math.min(amnt, this.maxDose) / concentrationAmount, 1);
  }

  /**
   * Get the highest liquid amount to administer to the patient based on their weight. This is used for the slider
   * @param weight The patient's weight
   * @returns The number amount to administer in highest liquid form to the patient based on their weight
   */
  getAmountHigh(weight: PatientWeight, selectedConcentration: number): number {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: Concentration = this.concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number =
      this.calcUnit === null
        ? this.basisHigh
        : this.basisHigh * weight.getWeightKg();
    return roundToDec(Math.min(amnt, this.maxDose) / concentrationAmount, 1);
  }

  /**
   * Get the interval to use for the slider
   * @param weight The patient's weight
   * @param selectedConcentration The selected concentration index
   * @returns The interval to use for the slider
   */
  getInterval(weight: PatientWeight, selectedConcentration: number): number {
    let low: number = this.getAmountLow(weight, selectedConcentration);
    let high: number = this.getAmountHigh(weight, selectedConcentration);
    if (high - low > 10) return 1;
    else if (high - low > 5) return 0.1;
    else return 0.05;
  }

  /**
   * Convert the liquid dose to solid form
   * @param doseLiquid The liquid dose to convert to solid
   * @param selectedConcentration The selected concentration index
   * @returns The converted dose in solid form
   */
  convertLiquidToSolid(
    doseLiquid: number,
    selectedConcentration: number
  ): string {
    /* Base case check if the concentration is null or empty */
    if (
      this.concentrations == null ||
      this.concentrations.length == 0 ||
      selectedConcentration >= this.concentrations.length
    )
      return '';

    /* Check if there are multiple concentrations and if one has been selected */
    if (this.concentrations.length > 1 && selectedConcentration == -1)
      return '';
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      this.concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: Concentration = this.concentrations[selectedConcentration];

    if (concen === null || concen.secAmnt === null) return '';
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let dose: number = doseLiquid * concentrationAmount;

    let decs = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;
    return roundToDec(dose, decs).toFixed(decs) + ' ' + concen.firstUnit;
  }

  /**
   * Check if the current medication is a range medication
   * @returns True if the medication is a range medication, false otherwise
   */
  isRangeMedication(): boolean {
    return this.basisHigh !== globals.MAX_VALUE;
  }

  inRange(weight: PatientWeight): boolean {
    return true;
  }

  //Create a toString function to output the data in a readable format
  toString(): string {
    let str = '';
    for (let i = 0; i < this.parent?.getConcentrations()?.length ?? 0; i++)
      str += this.getConcentration(i) + ', ';
    return `InfusionSubItem -> {
            uid=${this.uid}, 
            name=${this.name}, 
            concentration=${str}, 
            option=${this.title}, 
            rangeLow=${this.rangeLow}, 
            rangeHigh=${this.rangeHigh}, 
            basis=${this.basis}, 
            basisHigh=${this.basisHigh}, 
            maxDose=${this.maxDose}, 
            basisUnit=${this.basisUnit}, 
            calcUnit=${this.calcUnit}, 
            maxDoseUnit=${this.maxDoseUnit}, 
            instruction=${this.instruction},
            description=${this.description}, 
            warning=${this.warning}, 
            note=${this.note}, 
            routes=${this.routes}, 
        }`;
  }
}

export default InfusionSubItem;
