import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import './Review.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { GitMergeIcon } from '@primer/styled-octicons';
import ReviewalItem from '../../../data/model/ReviewalItem';
import { ACKStatus } from '../../../API';
import { FaUser } from 'react-icons/fa6';
import { RiGitClosePullRequestFill } from 'react-icons/ri';

const ReviewStatusButton: React.FC<{
  state: ReviewalItem;
  approvedCount: number;
  onClick: (type: 'publish' | 'override' | 'close') => void;
}> = ({ state, approvedCount, onClick }) => {
  const isPending = state.state === ACKStatus.PENDING;
  const isApproved = state.state === ACKStatus.APPROVED;
  const pendingCount = state.reviewers.length - approvedCount;
  const buttonClass = `publishButton-${ACKStatus[state.state].toLowerCase()}`;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const isMessage = isApproved
    ? 'All Reviewers Have Approved These Changes'
    : 'Publish Without Approval';

  return (
    <div>
      <div style={{ marginTop: '10px ' }}>
        <div className={`branch-icon ${isPending ? 'pending' : 'approved'}`}>
          <GitMergeIcon
            color="red.6"
            mr={2}
            size={'medium'}
            className="mergeIcon"
          />
        </div>
        <div className={`mergeContainer ${isPending ? 'pending' : 'approved'}`}>
          <div
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <FontAwesomeIcon
              icon={isPending ? faCircleXmark : faCircleCheck}
              size="xl"
              style={{
                color: isPending ? '#8b0000' : '#037F02',
                verticalAlign: 'top',
              }}
            />

            <div
              style={{
                paddingLeft: '6px',
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >
              <div style={{ fontWeight: '600' }}>{isMessage}</div>
              {isPending ? (
                <div style={{ fontSize: '14px' }}>
                  {/* <span>
                    <FaUser
                      style={{
                        color: '#636363',
                        height: '12px',
                        width: '12px',
                        marginRight: '5px',
                      }}
                    />
                  </span> */}
                  {pendingCount === 1
                    ? '1 reviewer is pending'
                    : `${pendingCount} reviewers are pending`}
                </div>
              ) : (
                <div style={{ fontSize: '14px' }}>
                  All reviewers have approved these changes. Publish the changes
                  to make them go live in OneDose.
                </div>
              )}
            </div>
          </div>
          <div className="mergeMessage">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                padding: '0 20px',
              }}
            >
              <div>
                {isPending && (
                  <input
                    type="checkbox"
                    className="pendingCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                )}
                <Button
                  onClick={() => {
                    onClick(isPending && isChecked ? 'override' : 'publish');
                  }}
                  className={
                    isChecked || !isPending
                      ? buttonClass
                      : `${buttonClass} disabled`
                  }
                >
                  {isPending ? 'Publish Now' : 'Publish'}
                </Button>
              </div>

              {/* {isPending && ( */}
              <Button
                onClick={() => {
                  onClick('close');
                }}
                className={'publishButton-close'}
              >
                {/* <span> */}
                <RiGitClosePullRequestFill
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '5px',
                    marginTop: '-4px',
                  }}
                />
                {/* </span> */}
                Close review
              </Button>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewStatusButton;
