import MedicationItem from '../../../data/model/MedicationItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import VitalItem from '../../../data/model/VitalItem';
import {
  ModelInit,
  MutableModel,
  __modelMeta__,
  ManagedIdentifier,
} from '@aws-amplify/datastore';
import CategoryItem from '../../../data/model/CategoryItem';
import {
  Category,
  LazyCategory,
  LazyProtocol,
  Protocol,
} from '../../../models';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { ProgressStatus } from '../../../models';

export const equipmentDose = {
  amntHigh: '',
  amnt: '',
  rangeHigh: 2147483647,
  rangeLow: 0,
  title: '',
  warning: '',
  instruction: '',
  note: '',
};

export const newChecklist = {
  isDouble: false,
  isUrgent: false,
  items: [],
  title: '',
};

export const newVitalOption = {
  amntHigh: '',
  amntLow: '',
  rangeHigh: 'MAX',
  rangeLow: 'MIN',
};

export const electricalDose = {
  amntHigh: '',
  amntLow: '',
  rangeHigh: 2147483647,
  rangeLow: 0,
  title: '',
  warning: '',
  instruction: '',
  note: '',
};

export const newMedicationItem = {
  uid: '1',
  depID: '2024',
  name: 'Medication',
  concentrations: [],
  medicationSubItems: [],
  protocolOptions: [],
  routes: [],
  version: 'v1.0.1',
  activeID: '',
  status: ProgressStatus.DRAFT,
  rangeLow: 0,
  rangeHigh: 2147483647,
};

// Model for New Medication
const newMedicationSubItem = {
  administeredAmount: 0,
  basis: 0,
  basisHigh: 0,
  basisUnit: 'mg',
  calcUnit: 'kg',
  concentrations: [],
  depID: '',
  description: '',
  fullBasisUnit: '',
  instruction: '',
  maxDose: 2147483647,
  maxDoseUnit: 'mg',
  name: '',
  note: '',
  rangeHigh: 2147483647,
  rangeLow: 0,
  routes: [],
  title: '',
  uid: '1234-567-890',
  warning: '',
};

export const medicationSubItem = {
  ...newMedicationSubItem,
  parentMedication: {
    concentrations: [],
    routes: [],
  },
  parentProtocol: {},
};

const rangeValue = {
  basis: '',
  rangeLow: 0,
  rangeHigh: 2147483647,
  route: [],
  title: '',
  warning: '',
  instruction: '',
  note: '',
  maxDose: '',
  minDose: '',
  calcMax: '',
  calcMin: '',
  index: 0,
};

export const medicationSubItemValue = (
  medicationItem: any,
  protocol: any
): any => {
  const data = {
    ...newMedicationSubItem,
    parentMedication: medicationItem,
    parentProtocol: protocol,
    range: rangeValue,
  };
  return data;
};

export const medicationDose = (medicationSubItem: any): any => {
  const medicationSubItemValues = {
    ...medicationSubItem,
    ...newMedicationSubItem,
  };
  return medicationSubItemValues;
};

//Created the Mock data for  infusion items
export const infusionListIem = (protocol: any): any => {
  const medicationItem: any = {};
  const list = ['Infusion-0', 'Infusion-1', 'Infusion-2'];
  const infusionList = list.map(
    (item: any) => new MedicationSubItem(protocol, medicationItem, item)
  );
  return infusionList;
};

//Created the Mock data for vital items
export const vitalItem = (): any => {
  const data = {
    name: '',
    options: [],
  };
  return data;
};

// new electrical model Data
export const electricalModel = {
  uid: '',
  id: '',
  title: '',
  options: [],
  protocols: [],
  depID: '',
  name: '',
  dbElectrical: '',
  subElectricals: [],
  parents: {},
};

//create new Category
export const newCategoryItem = (name: any, department: any): any => {
  const initValue: ModelInit<LazyCategory> = {
    name: name,
    departmentID: department ? department?.department?.id : '',
    index: 0,
    createdBy: '',
    isPublic: false,
    isRestrictive: false,
  };
  const newDepartment = new Category(initValue);
  const catogoryModalItem: any = new CategoryItem(newDepartment);
  debugger;
  return catogoryModalItem;
};

//create new Category
export const newProtocolItem = (name: any, url: any, department: any): any => {
  const initValue: ModelInit<LazyProtocol, {}> = {
    departmentID: department?.uid,
    name: name,
    index: 0,
    pdfID: url,
    categoryID: department?.uid,
    nickname: '',
    createdBy: '',
    isPublic: false,
    isRestrictive: false,
  };

  const newDepartment = new Protocol(initValue);
  const catogoryModalItem: any = new ProtocolItem(newDepartment, department);
  return catogoryModalItem;
};
