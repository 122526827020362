import React, { useEffect } from 'react';
import './App.scss';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { Auth, DataStore } from 'aws-amplify';
import AppRoute from './AppRoute';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  handleGetDepartment,
  handleUpdateCognitoUser,
  handleUpdateUser,
} from './store/actions';
import {
  Response,
  ResponseType,
  getUserInformation,
  loadDatabase,
  repeatTimeoutPromise,
  timeoutPromise,
} from './data/AmplifyDB';
import SplashScreen from './ui/pages/SplashScreen';
import { globals } from './ui/_global/common/Utils';
import { User, UserStatus } from './models';
import ConfirmModal from './ui/components/Modal/ConfirmModal';
import DepartmentItem from './data/model/DepartmentItem';
import AuthRoute, { checkPublicURL } from './AuthRoute';
import { set } from 'lodash';

const App = () => {
  const content = useRoutes(AppRoute);
  const location = useLocation();
  const dispatch = useDispatch();
  const cognitoUser = useSelector((state: any) => state.cognitoUser);
  const waitForSync = useSelector((state: any) => state.waitForSync);
  const user = useSelector((state: any) => state.user);
  const [loadingError, setLoadingError] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const [checkedAuth, setCheckedAuth] = React.useState(
    checkPublicURL(location.pathname)
  );

  const displayLoadingIcon = useSelector(
    (state: any) => state.displayLoadingIcon
  );

  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);

  // const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabledUser, setIsDisabledUser] = React.useState<User>();

  useEffect(() => {
    checkAuthState();
  }, []);

  useEffect(() => {
    setLoggedIn(isLoggedIn);
  }, [isLoggedIn]);

  const checkAuthState = async (): Promise<any> => {
    try {
      if (globals.debug) console.log('Checking Auth Stateee');
      const _user = await Auth.currentAuthenticatedUser();
      setLoggedIn(true);
      setIsLoading(true);
      dispatch<any>(
        handleUpdateCognitoUser({
          user: _user,
          waitForSync: false,
        })
      );
    } catch (err) {
      if (globals.debug) console.log('ERROR', err);
      setLoggedIn(false);
      setIsLoading(false);
      setCheckedAuth(true);
    }
  };

  // effect to update favicon
  useEffect(() => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = displayLoadingIcon ? '/favicon_loading.ico' : '/favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  }, [displayLoadingIcon]);

  const fetchUserInformation = async (user: any) => {
    if (globals.debug) console.log('FETCHING USER INFO', waitForSync);
    if (checkPublicURL(location.pathname)) return;
    let time = new Date().getTime();

    if (waitForSync) setCheckedAuth(false);

    const result = await repeatTimeoutPromise(
      getUserInformation(
        user.attributes.sub,
        user.username,
        false,
        waitForSync
      ),
      globals.maxDatabaseDelayMS,
      3
    ).catch((error) => {
      return setLoadingError(true);
    });

    if (result == null) {
      console.error('Error fetching user information');
      return setLoadingError(true);
    }

    let response: Response = result as Response;
    if (response.type === ResponseType.Success) {
      if (response.data[0] == null || response.data[1] == null) {
        window.confirm(
          'User is not authorized to use this application - Please contact a HM representative: No Department Assigned'
        );
        await Auth.signOut();
        return;
      }

      if (
        (response.data[0] as User).status === UserStatus.SUSPENDED ||
        (response.data[0] as User).status === UserStatus.DELETED
      ) {
        Auth.signOut();
        navigate(`/login`);
        setCheckedAuth(true);
        return setIsDisabledUser(response.data[0] as User);
      } else {
        /* Rename the title of the page */
        document.title = `${(response.data[1] as DepartmentItem).name} | OneDose`;

        /* Load the database */
        const result = await repeatTimeoutPromise(
          loadDatabase(response.data[1], dispatch),
          globals.maxDatabaseDelayMS,
          3
        ).catch(async (error) => {
          return setLoadingError(true);
        });

        if (result == null) {
          console.error('Error loading database');
          setLoadingError(true);
        }
        let dbResp = result as Response;
        if (dbResp.type === ResponseType.Success) {
          if (globals.debug)
            console.log('DB Loadtime:', new Date().getTime() - time + 'ms');
          dispatch<any>(handleUpdateUser(response.data[0]));
          dispatch<any>(handleGetDepartment(dbResp.data));
          if (globals.debug)
            console.log('User Information: ', response.data[0]);
          if (location.pathname === '/login' || location.pathname === '/')
            navigate(`/protocol`, { state: dbResp.data });
        } else {
          if (globals.debug) console.log(dbResp.data);
          setLoadingError(true);
        }
        setCheckedAuth(true);
      }
    } else {
      if (globals.debug) console.log(response.data);
      setLoadingError(true);
      setCheckedAuth(true);
    }

    // /* Get the user session and decode the token to get the user type */
    // let attempt = 0;
    // const maxAttempts = 3;

    // const attemptFetch = async () => {
    //   /* Fetch the user information from the backend wait for 10 seconds or logout the user */
    //   let userInfoPromise = getUserInformation(
    //     user.attributes.sub,
    //     user.username,
    //     waitForSync
    //   );

    //   const result = await timeoutPromise(
    //     userInfoPromise,
    //     globals.maxDatabaseDelayMS * (1 + attempt)
    //   ).catch(async (error) => {
    //     if (error.message === 'Timeout') {
    //       if (attempt >= maxAttempts) {
    //         if (globals.debug)
    //           console.log('Failed to find user... Logging out...');
    //         try {
    //           setLoadingError(true);
    //         } catch (clearError) {
    //           console.error('Failed to clear DataStore:', clearError);
    //           reloadWebsite();
    //         }
    //       }
    //       if (globals.debug)
    //         console.log(`Attempt ${attempt + 1} due to timeout, retrying...`);
    //       attempt++;
    //       setTimeout(attemptFetch, 2000 * attempt);
    //     } else {
    //       console.error('Error fetching user information:', error);
    //     }
    //     return null;
    //   });

    //   if (globals.debug) {
    //     console.log('Result:', result);
    //   }

    //   if (result) {
    //     if (globals.debug)
    //       console.log(
    //         'Time to fetch user info:',
    //         new Date().getTime() - time + 'ms',
    //         result
    //       );
    //     let response: Response = result as Response;
    //     if (response.type === ResponseType.Success) {
    //       if (response.data[0] == null || response.data[1] == null) {
    //         window.confirm(
    //           'User is not authorized to use this application - Please contact a HM representative: No Department Assigned'
    //         );
    //         await Auth.signOut();
    //         return;
    //       }

    //       document.title = response.data[1].name + ' | OneDose';

    //       if (
    //         (response.data[0] as User).status === UserStatus.SUSPENDED ||
    //         (response.data[0] as User).status === UserStatus.DELETED
    //       ) {
    //         Auth.signOut();
    //         navigate(`/login`);
    //         setIsDisabledUser(response.data[0] as User);
    //       } else {
    //         document.title = `${(response.data[1] as DepartmentItem).name} | OneDose`;
    //         const result = await repeatTimeoutPromise(
    //           loadDatabase(response.data[1], dispatch),
    //           globals.maxDatabaseDelayMS,
    //           3
    //         ).catch(async (error) => {
    //           return setLoadingError(true);
    //         });

    //         if (result == null) {
    //           console.error('Error loading database');
    //           setLoadingError(true);
    //         }
    //         let dbResp = result as Response;

    //         if (dbResp.type === ResponseType.Success) {
    //           if (globals.debug)
    //             console.log('DB Loadtime:', new Date().getTime() - time + 'ms');
    //           dispatch<any>(handleUpdateUser(response.data[0]));
    //           dispatch<any>(handleGetDepartment(dbResp.data));
    //           if (globals.debug)
    //             console.log('User Information: ', response.data[0]);
    //           if (location.pathname === '/login' || location.pathname === '/')
    //             navigate(`/protocol`, { state: dbResp.data });
    //         } else {
    //           if (globals.debug) console.log(dbResp.data);
    //           setLoadingError(true);
    //         }
    //       }
    //     } else {
    //       if (globals.debug) console.log(response.data);
    //       setLoadingError(true);
    //     }
    //     setCheckedAuth(true);
    //   } else {
    //     if (globals.debug) console.log('No result');
    //     attempt++;
    //     setTimeout(attemptFetch, 2000 * attempt);
    //     // setLoadingError(true);
    //   }
    // };
    // attemptFetch();
  };

  const reloadWebsite = async () => {
    let res = await timeoutPromise(Auth.signOut(), 1000).catch(
      async (error) => {
        console.error('Error:', error);
      }
    );
    localStorage.clear();
    res = await timeoutPromise(DataStore.clear(), 1000).catch(async (error) => {
      console.error('Error:', error);
    });
    navigate(`/login`);
    //Refresh the page
    window.location.reload();
  };

  // update user department details after Login
  useEffect(() => {
    if (cognitoUser != null) {
      setLoggedIn(true);
      if (globals.debug) console.log('LOGGED IN - FETCHING USER INFO');
      fetchUserInformation(cognitoUser);
      setIsLoading(false);
    }
  }, [cognitoUser]);

  return (
    <>
      {checkedAuth ? (
        <div className="App">
          {isDisabledUser && (
            <ConfirmModal
              isVisible={isDisabledUser != null}
              title={'User has been disabled'}
              handleClose={() => {
                setIsDisabledUser(undefined);
              }}
              handleSubmit={() => {
                setIsDisabledUser(undefined);
              }}
              isSingleBtn={true}
              secondaryBtnName="Okay"
              primaryDescription={
                'The user ' +
                isDisabledUser?.firstName +
                ' ' +
                isDisabledUser?.lastName +
                ' is currently disabled.'
              }
            />
          )}
          {loadingError && (
            <ConfirmModal
              isVisible={loadingError}
              title={'Error: There was an error loading OneDose Admin Console'}
              handleClose={() => {
                reloadWebsite();
              }}
              handleSubmit={() => {
                reloadWebsite();
              }}
              isSingleBtn={true}
              secondaryBtnName="Okay"
              primaryDescription={
                'There was an error loading OneDose Admin Console. We apologize for the inconvenience. Please try to refresh the page and log back in.'
              }
            />
          )}
          <AuthRoute content={content} />
          {/* <LandingPage /> */}
          {/* {isLoading && <Loading type="bubbles" />} */}
        </div>
      ) : (
        <>
          {loadingError && (
            <ConfirmModal
              isVisible={loadingError}
              title={'Error: There was an error loading OneDose Admin Console'}
              handleClose={() => {
                reloadWebsite();
              }}
              handleSubmit={async () => {
                reloadWebsite();
              }}
              isSingleBtn={true}
              secondaryBtnName="Okay"
              primaryDescription={
                'There was an error loading OneDose Admin Console. We apologize for the inconvenience. Please try to refresh the page and log back in.'
              }
            />
          )}
          <SplashScreen />
        </>
        // <DashboardLayout pageTitle="" component={<Loading type="bubbles" />} />
      )}
    </>
  );
};

export default App;
