import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getFormattedDate,
  getFormattedDateTime,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import { Ambulance } from '../../../../models';
import SearchBar from '../../../components/Search/SearchBar';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../store/actions';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  deleteAmbulance,
  fetchAmbulances,
} from '../../../../data/functions/AmbulanceDB';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
function findDepartmentOwner(
  department: DepartmentItem,
  item: Ambulance
): DepartmentItem | null {
  if (department.allSubDeps == null && department.parentDep == null) {
    return null;
  } else if (department.id === item.departmentID) {
    return department;
  } else if (department.parentDep?.id === item.departmentID) {
    return department.parentDep;
  } else if (department.parentDep?.parentDep?.id === item.departmentID) {
    return department.parentDep?.parentDep;
  }

  return (
    department?.allSubDeps?.find((dep) => dep.id === item.departmentID) || null
  );
}
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListAmbulances = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const dispatch = useDispatch();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [ambulances, setAmbulances] = useState<Ambulance[]>(
    database.ambulances
  );
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < ambulances.length; i++) {
      let owner = findDepartmentOwner(department, ambulances[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [ambulances, department]);
  const scrollPosition = useRef(0);

  console.log('OWNER', allDepartmentOwners);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [list, setList] = useState<any[]>([]);
  const [filters, setFilters] = useState<DepartmentItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<Ambulance[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const reloadDatabase = async () => {
    const response = await fetchAmbulances(department);
    if (response.type === ResponseType.Success) {
      setAmbulances(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          ambulances: response.data,
        })
      );
    } else {
      console.error('Failed to fetch ambulances', response.data);
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : ambulances)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: Ambulance, b: Ambulance) => {
      return a.name.localeCompare(b.name);
    });
    if (filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return filters.some((filter) => filter.id === item.departmentID);
      });
    }
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(ambulances);
  }, [searchQuery, ambulances, filters]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (amb: Ambulance) => {
    const state = {
      selectedProtocol: null,
      value: amb,
      subValue: null,
      type: 'Ambulance',
      editType: 'edit',
      editMode: false,
      page: 'listAmbulancePage',
    };
    navigate(`/database/edit/ambulance`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Ambulances',
      editType: 'new',
      editMode: false,
      page: 'listAmbulancePage',
    };
    navigate(`/database/new/ambulance`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 2,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value.name}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },

    {
      field: 'modified_date',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Modified Date</div>
      ),
      filterable: false,
    },
    {
      field: 'owner',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Owner</div>,
      renderCell: (params) => {
        if (department) {
          const departmentOwner = findDepartmentOwner(department, params.value);
          return (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {departmentOwner && (
                <OwnerImage
                  owner={departmentOwner}
                  // isLocked={!adminAccess}
                  size={32}
                />
              )}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
      sortable: true,
      sortComparator: (v1, v2) => {
        const owner1 = findDepartmentOwner(department, v1);
        const owner2 = findDepartmentOwner(department, v2);
        return owner1?.name.localeCompare(owner2?.name || '') || 0;
      },
      filterable: false,
    },
    {
      field: 'logs',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Logs</div>,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{params.value.count}</span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.count - v2.count,
      filterable: false,
    },
  ];

  const rows = list.map((item: Ambulance) => {
    return {
      id: item.id,
      name: {
        name: item.name,
      },
      modified_date: getFormattedDate(
        item.updatedAt ? item.updatedAt : new Date(),
        true
      ),
      owner: item,
      logs: {
        count: 0,
      },
    };
  });

  const handleSelectionChange = (newSelectionModel: any) => {
    const selectedItems = newSelectionModel
      .map((id: string) => list.find((item) => item.id === id))
      .filter((item: Ambulance | undefined) => item !== undefined);
    setSelectedItems(selectedItems);
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteAmbulance(currentMedication);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted ambulance: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: Ambulance[]) => {
          const updatedItems = prevItems.filter(
            (item: Ambulance) => item.id !== currentMedication.id
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete ambulance');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete ambulance');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteAmbulance(parmMedication);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(
            `Successfully deleted ${successCount} ambulances.`
          );
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} ambulances.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete ambulance.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Ambulance?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} ambulance?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Ambulances: ' + list.length + ' items'
            : 'Ambulances: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row>
        <Col sm={6}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Ambulances...'}
          />
        </Col>
        <Col sm={6}>
          {allDepartmentOwners.length > 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginBottom: '10px',
              }}
            >
              <ToggleButtonGroup
                value={viewing}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newAlignment: string | null
                ) => setViewing(newAlignment)}
                aria-label="owner"
                sx={{
                  padding: '0px',
                }}
              >
                <Tooltip title={'View All Ambulances'} placement="bottom" arrow>
                  <ToggleButton
                    value="all"
                    aria-label="all"
                    autoCapitalize="none"
                    sx={{
                      padding: '0px 8px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                    onChange={() => {
                      setViewing('all');
                      setFilters([]);
                    }}
                  >
                    All
                  </ToggleButton>
                </Tooltip>
                {allDepartmentOwners.map((owner: DepartmentItem) => (
                  <ToggleButton
                    value={owner.id}
                    aria-label={owner.name}
                    autoCapitalize="none"
                    onChange={() => {
                      setViewing(owner.id);
                      setFilters([owner]);
                    }}
                    sx={{
                      padding: '4px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                  >
                    <Tooltip
                      title={owner.name + ' Ambulances'}
                      placement="bottom"
                      arrow
                    >
                      <img
                        className="no-select owner-logo"
                        src={owner.logoVerifiedUrl ?? ''}
                        alt="Agency Logo"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </Tooltip>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          )}
        </Col>
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[50, 100, 200]}
          checkboxSelection
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.id === params.id))
          }
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedItems.map((item: Ambulance) => item.id)}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListAmbulances;
