import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  cprAssistSideoutList,
  sideoutList,
} from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../Modal/ConfirmModal';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { validatePointerID } from '../../../data/functions/ProtocolDB';
import { ProgressStatus } from '../../../API';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';
import { fetchItemFromAPI } from '../../../data/GraphQL_API';
import { handleHinckleyCache } from '../../../store/actions';
import NewModelItemSideout from './NewModelItemSideout';
import ElectricalProtocolEditPage from '../../pages/protocol/edit/Electrical/ElectricalProtocolEditPage';
import InfusionProtocolEditPage from '../../pages/protocol/edit/Infusion/InfusionProtocolEditPage';
import MedicationProtocolEditPage from '../../pages/protocol/edit/Medication/MedicationProtocolEditPage';
import MedicationItem from '../../../data/model/MedicationItem';
import NewMedicationDoseSideout from '../../pages/protocol/details/NewMedicationDoseSideout';
import InfusionItem from '../../../data/model/InfusionItem';
import NewInfusionDoseSideout from '../../pages/protocol/details/NewInfusionDoseSideout';
import ElectricalItem from '../../../data/model/ElectricalItem';
import NewElectricalDoseSideout from '../../pages/protocol/details/NewElectricalDoseSideout';

const HM_DB_ID =
  process.env.REACT_APP_HM_DB_ID ??
  (function () {
    throw new Error('Hinckley Medical DB ID is not defined');
  })();

interface AddCPRDoseSideoutProps {
  isVisible: boolean;
  handleClose: () => void;
  handleAdd: (option: any) => void;
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const AddCPRDoseSideout: React.FC<AddCPRDoseSideoutProps> = (props) => {
  const { isVisible, handleClose, handleAdd } = props;

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add
      </span>
    </div>
  );

  return (
    <div>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleClose}
        style={{ width: '25%', minWidth: '400px' }}
        className={
          isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'
        }
        header={customHeader}
      >
        <div className="sidebarContainer">
          <div>
            <div
              className="buttonContainer contentTitleLarge"
              onClick={handleClose}
            >
              <span className="">
                <HiPlus
                  className="header-icon"
                  style={{ marginLeft: '0px', color: '#000' }}
                />
                Add
              </span>
            </div>
            <div className="contentText">
              Select an option to continue to edit the information that will be
              displayed on the CPR Assist Screen.
            </div>
            <div className="contentLabelBold">Select Option</div>
            <div className="contentBorder protocolCalculationPad primaryListScroll">
              <ViewportList items={cprAssistSideoutList}>
                {(item: any, index: any) => (
                  <div
                    key={item + index}
                    onClick={() => {
                      handleAdd && handleAdd(item);
                    }}
                    className={`listhover spaceBetween cursorPointer item contentHeading ${
                      sideoutList?.length !== index + 1
                        ? 'contentUnderline'
                        : ''
                    }`}
                  >
                    <div>{item?.name}</div>
                    <div>
                      <span style={{ marginLeft: '0px' }}>
                        <BiSolidChevronRight className="icon_medium_small " />
                      </span>
                    </div>
                  </div>
                )}
              </ViewportList>
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddCPRDoseSideout;
