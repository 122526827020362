export function getFormattedAge(age: PatientAge | null | undefined): string {
  if (age == null) return '';
  if (age.ageMonths <= 0 && age.ageYears <= 0) return 'Min';
  else if (age.ageYears <= 0) return `${age.ageMonths} Mo`;
  else if (age.ageMonths === 0) return `${age.ageYears} Yr`;
  else return `${age.ageYears} Yr ${age.ageMonths} Mo`;
}

class PatientAge {
  ageValue: number;
  ageYears: number;
  ageMonths: number;

  constructor(ageInMonths: number) {
    this.ageValue = ageInMonths;
    this.ageYears = Math.floor(ageInMonths / 12);
    this.ageMonths = ageInMonths % 12;
  }

  toString(): string {
    return `PatientAge -> {
              ageYears: ${this.ageYears},
              ageMonths: ${this.ageMonths},
              value: ${this.ageValue}
        }`;
  }
}

export default PatientAge;
