import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { BiCopy, BiExit, BiRename, BiSolidCopy } from 'react-icons/bi';
import { InputSwitch } from 'primereact/inputswitch';
import {
  USAStatesAbrev,
  generateDepartmentURL,
  getFormattedDate,
  getFormattedDateTime,
  handleCopy,
  toTitleCase,
} from '../../../_global/common/Utils';
import { getHashedPin, getSalt } from '../../../_global/common/Encrypt';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User } from '../../../../models';
import { PiPencil } from 'react-icons/pi';
import ResetPinModal from './ResetPinModal';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { RadioButton } from 'primereact/radiobutton';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import EditAccessModal from '../../../components/Modal/EditAccessModal';
import CategoryItem from '../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';

interface DepartmentProps {
  user: User;
  formik: any;
  isEditMode: boolean;
}

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const EditProfilePage = (props: DepartmentProps) => {
  const { user, formik, isEditMode } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(isEditMode);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const dropRef = useRef(null);
  const [isSubDepChoiceOpen, setIsSubDepChoiceOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);

  const [isResetPinModal, setIsResetPinModal] = useState(false);
  // const [options, setOptions] = useState<any[] | null>(
  //   activeDepartment.subDeps ? [] : null
  // );

  // useEffect(() => {
  //   setActiveDepartment(activeDepartment);
  // }, [activeDepartment]);

  useEffect(() => {
    setIsEdit(isEditMode === true ? true : false);
  }, [isEditMode]);

  const handleBack = () => {
    navigate('/actions');
  };

  return (
    <div className="">
      {/* <ResetPinModal
        isVisible={isResetPinModal}
        department={parentDepartment}
        handleClose={() => {
          setIsResetPinModal(false);
        }}
        handleSubmit={(pin: string, salt: string, hash: string) => {
          formik.setFieldValue('pin', pin);
          formik.setFieldValue('saltedPin', salt);
          formik.setFieldValue('hashedPin', hash);
          setIsResetPinModal(false);
        }}
        value={formik.values.pin === '' ? undefined : formik.values.pin}
      /> */}
      <Row>
        <Col
          sm={8}
          style={{
            padding: '0 5rem',
            justifyContent: 'center',
            alignItems: 'center',

            // overflowX: 'hidden',
          }}
        >
          <label
            htmlFor="neonateCutoff"
            className="settings-general-label"
            style={{
              fontSize: '1.25rem',
              marginTop: '3rem',
              marginLeft: '0rem',
            }}
          >
            General
          </label>

          <Row>
            <Col sm={6}>
              <label
                htmlFor="firstName"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                First Name
                <span className="required-field ">
                  *
                  <BiRename
                    className="refresh-icon"
                    onClick={() => {
                      formik.setFieldValue(
                        'firstName',
                        toTitleCase(formik.values.firstName)
                      );
                    }}
                    size="1.25rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="firstName"
                  name="firstName"
                  data-testid="firstName"
                  required={true}
                  value={formik.values.firstName + ''}
                  onChange={(e: any) => {
                    formik.setFieldValue('firstName', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label
                htmlFor="lastName"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Last Name
                <span className="required-field ">
                  *
                  <BiRename
                    className="refresh-icon"
                    onClick={() => {
                      formik.setFieldValue(
                        'lastName',
                        toTitleCase(formik.values.lastName)
                      );
                    }}
                    size="1.25rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="lastName"
                  name="lastName"
                  data-testid="lastName"
                  required={true}
                  value={formik.values.lastName + ''}
                  onChange={(e: any) => {
                    formik.setFieldValue('lastName', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          {user.type === 'ADMIN' && (
            <>
              <label
                htmlFor="departmentID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Department ID
                <span>
                  {isCopied && isCopied === formik.values.departmentID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.departmentID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="departmentID"
                  name="departmentID"
                  data-testid="departmentID"
                  required={true}
                  value={formik.values.departmentID}
                  onChange={(e: any) => {
                    formik.setFieldValue('departmentID', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="cognitoID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Username
                <span>
                  {isCopied && isCopied === formik.values.cognitoID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.cognitoID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="cognitoID"
                  name="cognitoID"
                  data-testid="cognitoID"
                  required={true}
                  value={formik.values.cognitoID}
                  onChange={(e: any) => {
                    formik.setFieldValue('cognitoID', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="departmentID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Subscribed Departments
              </label>
              {user.pairedDepIDs &&
                user.pairedDepIDs.length > 0 &&
                user.pairedDepIDs.map((depID: string, index: number) => {
                  return (
                    <>
                      <div className="input-container">
                        <InputText
                          type="text"
                          className="form-control-general"
                          required={true}
                          value={
                            [
                              ...(database.department.allSubDeps || []),
                              database.department,
                            ]?.find((dep) => dep.id === depID)?.name ?? depID
                          }
                          onChange={(e: any) => {
                            // formik.setFieldValue('cognitoID', e.target.value);
                          }}
                          disabled={true}
                        />
                        <div className="input-border"></div>
                      </div>
                    </>
                  );
                })}
            </>
          )}
        </Col>
        <Col sm={4}>
          <label
            htmlFor="s"
            className="settings-general-label"
            style={{
              marginLeft: '0rem',
              fontSize: '1.25rem',
              marginTop: '3rem',
            }}
          >
            User Information
          </label>
          <div
            className="input-container roundBorder"
            style={{
              margin: '0px 0rem',
              marginTop: '1rem',
              gap: '0.25rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Last Activity:</div>
              <div style={{ fontWeight: '500' }}>
                {getFormattedDateTime(user.lastActivity ?? '', false)}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>OneDose Version:</div>
              <div style={{ fontWeight: '500' }}>
                {formik.values.oneDoseVersion}
              </div>
            </div>
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Username:</div>
              <div style={{ fontWeight: '500' }}>
                {formik.values.cognitoID}
                <span>
                  {isCopied && isCopied === formik.values.cognitoID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.cognitoID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>User Type:</div>
              <div style={{ fontWeight: '500' }}>
                {toTitleCase(formik.values.type)}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Status</div>
              <div style={{ fontWeight: '500' }}>
                {toTitleCase(formik.values.status)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfilePage;
