import { InputText } from 'primereact/inputtext';
import React, { useMemo } from 'react';
import { AiOutlineCloseCircle, AiOutlineThunderbolt } from 'react-icons/ai';
import { HiPencil } from 'react-icons/hi';
import { LiaHeartbeatSolid, LiaWeightHangingSolid } from 'react-icons/lia';
import { PiSyringe } from 'react-icons/pi';
import { ViewportList } from 'react-viewport-list';
import vitalAgeImage from '../../../assets/images/vitalAgeImage.png';
import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import { globals } from '../../../../_global/common/Utils';
import {
  FaArrowRightArrowLeft,
  FaArrowsLeftRightToLine,
  FaBoltLightning,
  FaSyringe,
} from 'react-icons/fa6';
import { InputSwitch } from 'primereact/inputswitch';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import PatientAge, {
  getFormattedAge,
} from '../../../../_global/common/PatientAge';

interface DoseSelectionProps {
  doseIndex: number;
  parent: ElectricalItem;
  formik: any;
  initialValue?: ElectricalSubItem;
  isEmergency?: boolean;
  disabled?: boolean;
  onRangeClick: (range: string, isLow: boolean, isVital: boolean) => void;
  onAgeRange: (age: PatientAge, isLow: boolean) => void;
  onBasisClick?: (basis: string, parent: ElectricalItem) => void;
  onMinMaxDoseClick: (
    value: string,
    type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
  ) => void;
  handleSubmit: (dose: any) => void;
}

/* 10-17-23 Praveen: Added Global component for Dose Section  */
const ElectricalDoseSelection: React.FC<DoseSelectionProps> = ({
  formik,
  doseIndex,
  parent,
  onRangeClick,
  onAgeRange,
  onBasisClick,
  onMinMaxDoseClick,
  handleSubmit,
  initialValue,
  isEmergency,
  disabled,
}) => {
  const isEditable: boolean = disabled !== true;
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [isWarning, setIsWarning] = React.useState(
    formik.values.warning != null && formik.values.warning !== ''
  );
  const isAgeBased = useMemo(() => {
    return database.department.isAgeFilterEnabled;
  }, [database.department]);
  return (
    <div
      className={`model-input-container  ${
        !isEditable
          ? 'cursorPointerSpan cursorPointerDiv cursorPointerInput cursorPointerLabel'
          : ''
      }`}
      style={{
        padding: '0px 10px',
      }}
    >
      <div className={`${!isEditable && 'doseGridPad'}`}>
        <div className="ketamine-container-pad">
          {/* Range Input */}
          <div
            className=""
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              flex: 1,
              gap: '10px',
              marginTop: '6px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '50px',
              }}
            >
              <FaArrowsLeftRightToLine
                style={{
                  fontSize: '1.5rem',
                  color: 'black',
                }}
              />
            </div>
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
              onClick={() => {
                if (isEditable)
                  onRangeClick(formik.values.rangeLow, true, false);
              }}
            >
              <label htmlFor={''} className={`input-label`}>
                Weight Low (kg)
              </label>
              <div className="p-input-icon-right" data-testid="editRangeLow">
                <div
                  // type="text"
                  className={`form-control pointer ${
                    !isEditable
                      ? !formik.values.rangeLow
                        ? 'greyText'
                        : ''
                      : 'inputHover'
                  }`}
                  id="rangeLow"
                  // name="rangeLow"
                  data-testid="rangeLow"
                  style={{
                    color:
                      !formik.values.rangeLow || !isEditable
                        ? '#6B6D70'
                        : '#000000',
                  }}
                >
                  {formik.values.rangeLow
                    ? formik.values.rangeLow + ' kg'
                    : 'Min'}
                </div>
                {isEditable && <HiPencil className="icon-black rightIcon" />}
              </div>
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Weight High (kg)
              </label>
              <div
                className="p-input-icon-right"
                onClick={() => {
                  if (isEditable) {
                    let rangeHigh = formik.values.rangeHigh
                      ? formik.values.rangeHigh
                      : globals.MAX_VALUE.toLocaleString();
                    onRangeClick(formik.values.rangeHigh, false, false);
                  }
                }}
              >
                <div
                  // type="text"
                  className={`form-control pointer ${
                    !isEditable
                      ? !formik.values.rangeHigh
                        ? 'greyText'
                        : ''
                      : 'inputHover'
                  }`}
                  id="rangeLow"
                  // name="rangeLow"
                  data-testid="rangeLow"
                  style={{
                    color:
                      !formik.values.rangeHigh || !isEditable
                        ? '#6B6D70'
                        : '#000000',
                  }}
                >
                  {formik.values.rangeHigh
                    ? formik?.values?.rangeHigh + ' kg'
                    : 'Max'}
                </div>
                {isEditable && <HiPencil className="icon-black rightIcon" />}
              </div>
            </div>
            {isAgeBased && (
              <>
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={`input-label`}>
                    Age Low
                  </label>
                  <div
                    className="p-input-icon-right"
                    data-testid="editRangeLow"
                    onClick={() => {
                      if (isEditable) onAgeRange(formik.values.ageLow, true);
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.ageLow
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.ageLow ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.ageLow
                        ? getFormattedAge(formik.values.ageLow as PatientAge)
                        : 'Min'}
                    </div>
                    {isEditable && (
                      <HiPencil className="icon-black rightIcon" />
                    )}
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age High
                  </label>
                  <div
                    className="p-input-icon-right"
                    onClick={() => {
                      if (isEditable) {
                        onAgeRange(formik.values.ageHigh, false);
                      }
                    }}
                  >
                    <div
                      // type="text"
                      className={`form-control pointer ${
                        !isEditable
                          ? !formik.values.ageHigh
                            ? 'greyText'
                            : ''
                          : 'inputHover'
                      }`}
                      id="rangeLow"
                      // name="rangeLow"
                      data-testid="rangeLow"
                      style={{
                        color: !formik.values.ageHigh ? '#6B6D70' : '#000000',
                      }}
                    >
                      {formik.values.ageHigh
                        ? getFormattedAge(formik.values.ageHigh)
                        : 'Max'}
                    </div>
                    {isEditable && (
                      <HiPencil className="icon-black rightIcon" />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          {!isEmergency && (
            <div
              className=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flex: 1,
                gap: '10px',
                marginTop: '6px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '50px',
                }}
              >
                <FaBoltLightning
                  style={{
                    fontSize: '1.5rem',
                    color: 'black',
                  }}
                />
              </div>
              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 2,
                }}
                onClick={() => {
                  if (isEditable)
                    onBasisClick?.(
                      formik.values.basis,
                      parent as ElectricalItem
                    );
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Calculation Basis
                  <span className="required-field">
                    {isEditable ? '*' : ''}
                  </span>
                </label>
                <div className="p-input-icon-right">
                  <InputText
                    type="text"
                    className={`form-control  pointer ${
                      formik.values.basis === '+Add' && 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    style={{
                      color: !isEditable ? '#6B6D70' : '#000000',
                    }}
                    id="basis"
                    name="basis"
                    placeholder=" "
                    data-testid="calculationBasisValue"
                    value={formik.values.basis}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable &&
                    formik.values.basis !== '' &&
                    formik.values.basis !== '+Add' && (
                      <HiPencil
                        className="icon-black rightIcon"
                        style={{ marginRight: '8px' }}
                      />
                    )}
                </div>
              </div>

              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={` input-label`}>
                  Min Dose
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.minDose, 'min');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.minDose !== '+Add' ? '' : 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    style={{
                      color: !isEditable ? '#6B6D70' : '#000000',
                    }}
                    id="minDose"
                    name="minDose"
                    placeholder="Min"
                    data-testid="minDose"
                    value={formik.values.minDose}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {formik.values.minDose !== '+Add' && isEditable && (
                    <HiPencil className="icon-black rightIcon" />
                  )}
                </div>
              </div>

              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Max Dose
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.maxDose, 'max');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.maxDose !== '+Add' ? '' : 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    style={{
                      color: !isEditable ? '#6B6D70' : '#000000',
                    }}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={formik.values.maxDose}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && formik.values.maxDose !== '+Add' && (
                    <HiPencil className="icon-black rightIcon" />
                  )}
                </div>
              </div>

              <div
                className=""
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
              >
                <label htmlFor={''} className={`input-label`}>
                  Max Total
                </label>
                <div
                  className="p-input-icon-right"
                  onClick={() => {
                    if (isEditable)
                      onMinMaxDoseClick(formik.values.maxTotalDose, 'maxTotal');
                  }}
                >
                  <InputText
                    type="text"
                    className={`form-control pointer ${
                      formik.values.maxTotalDose !== '+Add'
                        ? ''
                        : 'formAnchorText'
                    } ${!isEditable ? '' : 'inputHover'}`}
                    style={{
                      color: !isEditable ? '#6B6D70' : '#000000',
                    }}
                    id="maxDose"
                    name="maxDose"
                    data-testid="maxDose"
                    placeholder="Max"
                    value={formik.values.maxTotalDose}
                    onChange={formik.handleChange}
                    readOnly={true}
                    disabled={!isEditable}
                  />
                  {isEditable && formik.values.maxTotalDose !== '+Add' && (
                    <HiPencil className="icon-black rightIcon" />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={` ${!isEditable && 'sideoutFieldPad'}`}>
        {(isEditable || formik.values.title) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`REQUIRED: Add more context to the electrical shock. This will be displayed below "${parent.name}" in OneDose Ex. "First Shock", "Subsequent Shocks", etc.`}
              placement="left"
              arrow
            >
              <label
                htmlFor="Title"
                className={`sidebarLabel `}
                style={{
                  color: isWarning ? '#880808' : 'black',
                }}
              >
                Name:
                <span
                  className="required-field"
                  style={{
                    marginBottom: '6px',
                  }}
                >
                  {isEditable ? '*' : ''}
                </span>
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder={`Enter Title here...`}
                  value={formik.values.title}
                  //disabled={!isEditable}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('title', e.target.value);
                  }}
                  disabled={!isEditable}
                  style={{
                    color: !isEditable ? '#6B6D70' : '#000000',
                  }}
                />
              </div>
              <div className="input-border"></div>
            </div>
          </div>
        )}
        {(isEditable || formik.values.warning) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add a a RED message above where the electricals's name is displayed.`}
              placement="left"
              arrow
            >
              <label
                htmlFor="Title"
                className={`sidebarLabel `}
                style={{
                  color: '#880808',
                }}
              >
                Warning:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <input
                  type="text"
                  className={`form-control-general `}
                  id="title"
                  name="title"
                  data-testid="title"
                  placeholder={`Enter Warning here...`}
                  value={formik.values.warning}
                  //disabled={!isEditable}
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') handleSubmit(formik.values);
                  }}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                  disabled={!isEditable}
                  style={{
                    color: !isEditable ? '#6B6D70' : '#000000',
                  }}
                />
              </div>
              <div className="input-border"></div>
            </div>
          </div>
        )}

        {(isEditable || formik.values.instruction) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add instructions for the electrical. This will be displayed under the electrical's name.`}
              placement="left"
              arrow
            >
              <label htmlFor="instructions" className={`sidebarLabel `}>
                Instructions:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <input
                type="text"
                className={`form-control-general `}
                id="instruction"
                name="instruction"
                data-testid="instruction"
                placeholder="Add Instructions here..."
                value={formik.values.instruction}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit(formik.values);
                }}
                //disabled={!isEditable}
                onChange={(e: any) => {
                  formik.setFieldValue('instruction', e.target.value);
                }}
                disabled={!isEditable}
                style={{
                  color: !isEditable ? '#6B6D70' : '#000000',
                }}
              />
              <div className="input-border"></div>
            </div>
          </div>
        )}
        {(isEditable || formik.values.note) && !isEmergency && (
          <div className="sidebarInputContent" style={{ marginBottom: '10px' }}>
            <Tooltip
              title={`Add additional information for the electrical. This will be displayed ONLY in the dropdown when the electrical is expaned.`}
              placement="left"
              arrow
            >
              <label htmlFor="note" className={`sidebarLabel `}>
                Note:
                <span>
                  <FaInfoCircle
                    color="#636363"
                    style={{ marginLeft: '5px', marginBottom: '2px' }}
                  />
                </span>
              </label>
            </Tooltip>
            <div className="input-container">
              <input
                type="text"
                className={`form-control-general`}
                id="note"
                name="note"
                data-testid="note"
                placeholder="Enter Note here..."
                value={formik.values.note}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') handleSubmit(formik.values);
                }}
                //disabled={!isEditable}
                onChange={(e: any) => {
                  formik.setFieldValue('note', e.target.value);
                }}
                disabled={!isEditable}
                style={{
                  color: !isEditable ? '#6B6D70' : '#000000',
                }}
              />
              <div className="input-border"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElectricalDoseSelection;
