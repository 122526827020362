import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../../protocol/ProtocolHeader';
import { useEffect, useMemo, useRef, useState } from 'react';
import '../Import.scss';
import { ViewportList } from 'react-viewport-list';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import {
  getFormattedDate,
  globals,
  upgradeVersion,
} from '../../../../../_global/common/Utils';
import MedicationDoseInput from '../MedicationDoseInput';
import {
  Medication,
  MedicationProtocol,
  MedicationRange,
  ProgressStatus,
} from '../../../../../../models';
import MedicationItem, {
  cloneMedication,
} from '../../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import ConfirmModal from '../../../../../components/Modal/ConfirmModal';
import {
  MedicationJSON,
  createParentMedication,
} from '../../../../../../data/functions/MedicationDB';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
  loadDatabase,
} from '../../../../../../data/AmplifyDB';
import { find } from 'lodash';
import NewMedicationInput from './NewMedicationInput';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../../../store/actions';
import WorkbookItem from '../../../../../../data/model/WorkbookItem';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import SearchBar from '../../../../../components/Search/SearchBar';
import { Button, Col, Row } from 'react-bootstrap';
import PDFScreen from '../../../../protocol/details/PdfScreen';
import { Storage } from 'aws-amplify';
import SearchableDropdown from '../../../../../components/SearchableDropdown';
import {
  ProtocolJSON,
  createProtocol,
} from '../../../../../../data/functions/ProtocolDB';
import { IoArrowBack, IoSave } from 'react-icons/io5';
import { AiOutlineRight } from 'react-icons/ai';
import { InputText } from 'primereact/inputtext';
import { setIn } from 'formik';
import { HiPlus } from 'react-icons/hi';
import { BiTrash } from 'react-icons/bi';
import { fetchWorkbooks } from '../../../../../../data/functions/WorkbookDB';
const CREATING_MEDICATIONS = 0;
const CHECKING_DOSES = 1;
const REVIEWING_DOSES = 2;
const CONFIRMING_DOSES = 3;

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ImportPagesResults = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data, file, database } = location.state;

  const { pages, newMedications } = data;
  const [db, setDatabase] = useState<DatabaseResponse>(database);
  const [meds, setMedications] = useState<any[]>(db.medications);
  const [filePages, setFilePages] = useState<any[]>(pages);
  const dispatch = useDispatch();

  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [selectedWorkbook, setSelectedWorkbook] = useState<WorkbookItem | null>(
    null
  );
  const viewportRef = useRef<any>(null);
  const colRef = useRef<any>(null);
  const [workbookURL, setWorkbookURL] = useState<string>('');
  const [worbookFile, setWorkbookFile] = useState<File>();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [list, setList] = useState<WorkbookItem[]>([]);

  const [doseData, setDoseData] = useState<any>(data);
  const [expanded, setExpanded] = useState<string[]>([]);

  const [final, setFinal] = useState<any>();

  const [confirmedPages, setConfirmedPages] = useState<any[]>([]);
  const [index, setIndex] = useState<number>(0);
  const [indexStr, setIndexStr] = useState<string>('1');
  const [cur, setCurrent] = useState<any>(filePages[index]);
  const [protocol, setProtocol] = useState<ProtocolItem | null>(null);
  // const [curDoses, setCurrentDoses] = useState<any>(medications[index].doses);
  const [screen, setScreen] = useState<any>({
    width: window.innerWidth,
    height: window.innerHeight,
    portrait: window.innerWidth < window.innerHeight,
  });
  const [state, setState] = useState<number>(
    newMedications?.length > 0 ? CREATING_MEDICATIONS : CHECKING_DOSES
  );
  const department: DepartmentItem = useMemo(() => {
    return db.department;
  }, [db]);

  useEffect(() => {
    const handleResize = () => {
      setScreen({
        width: window.innerWidth,
        height: window.innerHeight,
        portrait: window.innerWidth < window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const cleanedConfirmedPages = useMemo(() => {
    let newPages: any[] = [];
    for (let i = 0; i < confirmedPages.length; i++) {
      let page = confirmedPages[i];
      if (page.protocol == null) {
        console.log('Page Protocol is null', page);
        continue;
      } else {
        newPages.push(page);
      }
    }

    return newPages;
  }, [confirmedPages]);

  useEffect(() => {
    if (selectedWorkbook !== null) {
      // const fetchURL = async () => {
      //   return await Storage.get(selectedWorkbook.workbookUrl, {
      //     level: 'public',
      //   });
      // };
      // fetchURL().then((url) => {
      //   setWorkbookURL(url);
      // });
      fetchPDF(selectedWorkbook.workbookUrl).then((response: Response) => {
        if (response.type === ResponseType.Success) {
          // const blob: Blob = response.data;
          const url = response.data;
          setWorkbookURL(url);
          // let file: File = new File([blob], selectedWorkbook.name);
          // setWorkbookFile(file);
        }
      });
    }
  }, [selectedWorkbook]);

  // console.log('PAGE', cur);
  // console.log('All Confirmed', confirmedPages);
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });

  const handleBack = () => {
    navigate(`/actions/import-medication-doses/results`, {
      state: {
        data: data,
        file: file,
        database: db,
      },
    });
  };

  useEffect(() => {
    const getDetails = async (department: DepartmentItem) => {
      const result = await fetchWorkbooks(department, true, true);
      setWorkbookList(result.data);
      setList(result.data);
    };
    getDetails(db.department);
  }, [db]);

  const allConfirmDoses = useMemo(() => {
    // if (state === REVIEWING_DOSES) {
    //   let doses: any[] = [];
    //   for (let i = 0; i < confirmedPages.length; i++) {
    //     let options = confirmedPages[i].newProtocolOptions;
    //     for (let j = 0; j < options.length; j++) {
    //       let protocol = findProtocol(options[j].protocolID);
    //       let ranges = options[j].options;
    //       for (let k = 0; k < ranges.length; k++) {
    //         let range = ranges[k];
    //         doses.push({
    //           dose: range,
    //           protocol: protocol,
    //         });
    //       }
    //     }
    //   }
    //   return doses;
    // }
    return [];
  }, [state, confirmedPages]);

  const [newMedicationsData, setNewMedicationsData] = useState<any>(
    newMedications.map((medication: MedicationItem) => {
      return {
        medication: medication,
        data: {
          name: medication.name,
          concentrations: medication.concentrations,
          routes: medication.routes,
        },
      };
    })
  );

  function findProtocol(protocolID: string) {
    for (let i = 0; i < db.protocols.length; i++) {
      let protocol: ProtocolItem = db.protocols[i];
      let id =
        protocol.status === ProgressStatus.ACTIVE ||
        (protocol.status === ProgressStatus.DRAFT && protocol.activeID == null)
          ? protocol.uid
          : protocol.activeID;
      if (id === protocolID) {
        return protocol;
      }
    }
    return null;
  }

  const handleCreateMedications = async () => {
    setModalState({ ...modalState, isVisible: false });
    if (globals.debug) console.log('Creating medications');
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < final.medications.length; i++) {
      let newMed = final.medications[i];
      let medication: MedicationItem = newMed.medication;
      let importedOptions = newMed.protocolOptions;
      let currentMedicationOptions = medication.protocolOptions;
      if (globals.debug)
        console.log(
          'Creating medication',
          currentMedicationOptions,
          importedOptions
        );

      /* Merge the new options with the current options */
      let options = [...currentMedicationOptions];
      for (let j = 0; j < importedOptions.length; j++) {
        /* Check if the protocol is already in the current medication */
        let find = options.find(
          (item) => item.protocolID === importedOptions[j].protocolID
        );
        if (find) {
          /* Merge the options */
          let newMP = {
            protocolID: find.protocolID,
            options: [...find.options, ...importedOptions[j].options],
          };
          options = options.map((item) =>
            item.protocolID === newMP.protocolID ? newMP : item
          );
        } else {
          /* Add the new protocol to the options */
          options.push(importedOptions[j]);
        }
      }
      // let protocols = options.map((po: MedicationProtocol) =>
      //   findProtocol(po.protocolID)
      // );
      // if (globals.debug)
      //   console.log('Creating medication', medication, options, protocols);
      // const protocolList = options.map((po: MedicationProtocol) =>
      //   findProtocol(po.protocolID)
      // );
      const newMedication: MedicationJSON = {
        name: medication.name,
        concentration: medication.concentrations,
        routes: medication.routes,
        rangeLow: !isNaN(Number(medication.rangeLow))
          ? Number(medication.rangeLow)
          : 0,
        rangeHigh: !isNaN(Number(medication.rangeHigh))
          ? Number(medication.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: medication.departmentID,
        protocolOptions: options,

        rxNormCode: medication.model?.rxNormCode,
        nemsisRoutes: medication.model?.nemsisRoutes,
        minDose: medication.model?.minDose,
        maxDose: medication.model?.maxDose,
        metaData: medication.model?.metaData,

        contraindication: medication.model?.contraindication,
        note: medication.model?.note,
        warning: medication.model?.warning,
        medClass: medication.model?.medClass,
        action: medication.model?.action,
        indication: medication.model?.indication,
        interaction: medication.model?.interaction,
        onset: medication.model?.onset,
        duration: medication.model?.duration,
        taggedProtocols: [], //protocolList,
        createdBy: medication.model?.createdBy
          ? medication.model?.createdBy
          : 'OneBot',
        modifiedBy: 'OneBot',

        status: ProgressStatus.DRAFT,
        activeID:
          medication.status === ProgressStatus.ACTIVE
            ? medication.uid
            : medication.activeID,
        version:
          medication.status === ProgressStatus.ACTIVE
            ? upgradeVersion(
                medication.version == null ? 'v1.0.0' : medication.version
              )
            : medication.version == null
              ? 'v1.0.0'
              : medication.version,
      };

      if (globals.debug) console.log('Creating New Medication', newMedication);
      let results: Response = await createParentMedication(
        department,
        newMedication,
        medication
      );
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created medication', results.data);
        let newMedication: MedicationItem = results.data;
        index++;
      } else {
        console.error('Failed to create medication', results.data);
      }
    }

    setModalState({
      isVisible: true,
      title: index + ' Medications Created',
      description: 'The medications have been created successfully',
      primaryBtnName: 'Stay',
      secondaryBtnName: 'Go to DB',
      primaryDescription: '',
      secondaryDescription: '',
    });

    if (globals.debug)
      console.log(
        'All Medications Created',
        index,
        '/',
        final.medications.length
      );
  };

  const handleCreateProtocols = async () => {
    setModalState({ ...modalState, isVisible: false });
    if (globals.debug) console.log('Creating protocols');
    /* Create all the medications for now I will just do one */
    let index = 0;
    for (let i = 0; i < final.protocols.length; i++) {
      let newProtocol = final.protocols[i];
      let protocol: ProtocolItem = newProtocol.protocol;
      let medicationIDs = newProtocol.medicationIDs;
      if (globals.debug)
        console.log('Creating protocol', protocol, medicationIDs);
      let protocolJSON: ProtocolJSON = {
        name: protocol.name,
        nickname: protocol.nickname,
        departmentID: db.department.id,
        index: protocol.index,
        rangeLow: protocol.rangeLow,
        rangeHigh: protocol.rangeHigh,
        parentCategory: protocol.parent,
        pdfID: protocol.model.pdfID,
        pairedDepsIDs: protocol.pairedDepIDs ? protocol.pairedDepIDs : [],
        pairedProtocols: protocol.model.pairedProtocols
          ? protocol.model.pairedProtocols
          : [],
        medications: medicationIDs,
        infusions: protocol.model.dripIDs ? protocol.model.dripIDs : [],
        equipment: protocol.model.equipmentIDs
          ? protocol.model.equipmentIDs
          : [],
        electrical: protocol.model.electricalIDs
          ? protocol.model.electricalIDs
          : [],
        forms: protocol.model.formIDs ? protocol.model.formIDs : [],
        createdBy: protocol.model.createdBy,
        status: ProgressStatus.DRAFT,
        activeID:
          protocol.status === ProgressStatus.ACTIVE
            ? protocol.uid
            : protocol.activeID,
        version:
          protocol.status === ProgressStatus.ACTIVE
            ? upgradeVersion(
                protocol.version == null ? 'v1.0.0' : protocol.version
              )
            : protocol.version == null
              ? 'v1.0.0'
              : protocol.version,
        pdfVersion: protocol.model.pdfVersion
          ? protocol.model.pdfVersion
          : 'v1.0.0',
        isPublic: protocol.model.isPublic,
        isRestrictive: protocol.model.isRestrictive,
        keychainID: protocol.model.keychainID,
      };

      let results: Response = await createProtocol(protocolJSON);
      if (results.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Successfully created protocol', results.data);
        let newProtocol: ProtocolItem = results.data;
        index++;
      } else {
        console.error('Failed to create protocol', results.data);
      }
    }

    if (globals.debug)
      console.log('All Protocols Created', index, '/', final.protocols.length);

    setModalState({
      isVisible: true,
      title: index + ' Protocols Created',
      description: 'The protocols have been created successfully',
      primaryBtnName: 'Okay',
      secondaryBtnName: 'Okay',
      primaryDescription: '',
      secondaryDescription: '',
    });
  };

  const handleBackDose = () => {
    if (state === CONFIRMING_DOSES) {
      const lastConfirm = confirmedPages[confirmedPages.length - 1];
      setIndex(index - 1);
      setIndexStr(index.toString());
      setCurrent(lastConfirm);
      //Filter out the current medication if it exists
      let newConfirmed: any[] = [...confirmedPages];
      newConfirmed = newConfirmed.filter(
        (item) =>
          !(item.index === lastConfirm.index && item.file === lastConfirm.file)
      );

      setConfirmedPages(newConfirmed);
      setProtocol(lastConfirm.protocol);
    } else if (state === REVIEWING_DOSES) {
      //Remove the last confirmed page
      let newConfirmed: any[] = [...confirmedPages];
      newConfirmed.pop();
      setConfirmedPages(newConfirmed);
      setIndex(index - 1);
      setIndexStr(index.toString());
      setCurrent(filePages[index - 1]);

      setState(CHECKING_DOSES);
    }
    // const lastMedication = medications[index - 1];
    // setIndex(index - 1);
    // setCurrent(lastMedication);
    // setCurrentDoses(lastMedication.doses);
    // //Filter out the current medication if it exists
    // let newConfirmed: any[] = [...confirmedPages];
    // newConfirmed = newConfirmed.filter(
    //   (item) => item.medication.uid !== lastMedication.uid
    // );
    // setconfirmedPages(newConfirmed);
  };

  useEffect(() => {
    if (globals.debug) console.log('Confirmed Pages', confirmedPages);
  }, [confirmedPages]);

  const handleContinue = async () => {
    if (state === CREATING_MEDICATIONS) {
      console.log('Creating Medications', newMedicationsData);
      createMedications();
    }
    if (state === REVIEWING_DOSES) {
      setModalState({
        isVisible: true,
        title: 'Import all reviewd items?',
        description:
          'There was ' +
          final.medications.length +
          ' medications' +
          ' and ' +
          final.protocols.length +
          ' protocols that were updated. Would you like to submit these updates to ' +
          db.department.name +
          '?',
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Submit',
        primaryDescription:
          'Would you like to submit these updates to ' +
          db.department.name +
          '?',
        secondaryDescription:
          'There was ' +
          final.medications.length +
          ' medications' +
          ' and ' +
          final.protocols.length +
          ' protocols that were updated.',
      });
    } else if (index === filePages.length - 1) {
      groupAllPageData([...confirmedPages, { ...cur, protocol: protocol }]);
      setState(REVIEWING_DOSES);
      addMedicationToConfirmed();
      //Submitting medications
    } else {
      addMedicationToConfirmed();
      setIndex(index + 1);
      setIndexStr((index + 2).toString());
      setCurrent(filePages[index + 1]);
      setProtocol(null);
      //Scroll to the top of the page
      window.scrollTo(0, 0);
      viewportRef.current.scrollToIndex(0);
    }
  };

  const groupAllPageData = (pages: any[]) => {
    let newProts: any[] = [];
    let newMeds: any[] = [];
    console.log('GROUPING ALL PAGE DATA', pages);

    /* First group all the medications together */
    for (let i = 0; i < meds.length; i++) {
      let med = meds[i];

      /* Search and group every dose for the medication */
      for (let j = 0; j < pages.length; j++) {
        let page = pages[j];

        if (page.protocol == null) {
          continue;
        }
        for (let k = 0; k < page.doses.length; k++) {
          let dose = page.doses[k];
          if (dose.medication.uid === med.uid) {
            if (!newMeds.find((item) => item.medication.uid === med.uid)) {
              newMeds.push({
                medication: med,
                protocolOptions: [
                  {
                    protocolID: page.protocol.activeID
                      ? page.protocol.activeID
                      : page.protocol.uid,
                    options: [create_medication_range(dose)],
                  },
                ] as MedicationProtocol[],
              });
            } else {
              let protID = page.protocol.activeID
                ? page.protocol.activeID
                : page.protocol.uid;
              let find = newMeds.find(
                (item) => item.medication.uid === med.uid
              );
              let range = create_medication_range(dose);
              let findProtocol = find.protocolOptions.find(
                (item: MedicationProtocol) => item.protocolID === protID
              );
              if (findProtocol) {
                findProtocol.options.push(range);
              } else {
                find.protocolOptions.push({
                  protocolID: protID,
                  options: [range],
                });
              }
            }

            /* Make sure hte medication id is mapped to the protocol */
            let find = newProts.find(
              (item) => item.protocol.uid === page.protocol.uid
            );
            if (!find) {
              newProts.push({
                protocol: page.protocol,
                medicationIDs: [
                  ...((page.protocol as ProtocolItem).model.medicationIDs
                    ? ((page.protocol as ProtocolItem).model
                        .medicationIDs as string[])
                    : []),
                  med.uid,
                ],
              });
            } else {
              if (!find.medicationIDs.includes(med.uid)) {
                find.medicationIDs.push(med.uid);
              }
            }
          }
        }
      }
    }

    console.log('GROUPD MEDICATIONS', newMeds);
    console.log('New Protocol Pairings', newProts);

    setFinal({
      medications: newMeds,
      protocols: newProts,
    });
  };

  const create_medication_range = (dose: any, index?: number) => {
    let range = new MedicationRange({
      basis: dose.confirmedData.dose,
      rangeLow: dose.confirmedData.rangeLow
        ? Number(dose.confirmedData.rangeLow.split(' ')[0])
        : 0,
      rangeHigh: dose.confirmedData.rangeHigh
        ? Number(dose.confirmedData.rangeHigh.split(' ')[0])
        : globals.MAX_VALUE,
      route: dose.confirmedData.routes,
      title: dose.confirmedData.title,
      warning: dose.confirmedData.warning,
      instruction: dose.confirmedData.instruction,
      note: dose.confirmedData.note,
      maxDose: dose.confirmedData.maxSingleDose,
      minDose: dose.confirmedData.minSingleDose,
      calcMax: dose.confirmedData.calcMax,
      index: index ? index : 0,
    });
    return range;
  };

  const createMedications = async () => {
    let newMeds: MedicationItem[] = [];
    for (let i = 0; i < newMedicationsData.length; i++) {
      let m: MedicationItem = newMedicationsData[i].medication;
      let data = newMedicationsData[i].data;
      // m.name = newMedicationsData[i].data.name;
      // m.concentrations = newMedicationsData[i].data.concentrations;
      // m.routes = newMedicationsData[i].data.routes;
      // m.version = 'v1.0.0';
      // m.status = ProgressStatus.DRAFT;
      // m.activeID = null;
      // m.depID = db.department.id;
      const newMedication: MedicationJSON = {
        name: data.name,
        concentration: data.concentrations,
        routes: data.routes,
        rangeLow: !isNaN(Number(m.rangeLow)) ? Number(m.rangeLow) : 0,
        rangeHigh: !isNaN(Number(m.rangeHigh))
          ? Number(m.rangeHigh)
          : globals.MAX_VALUE,
        departmentID: db.department.id,
        protocolOptions: m.protocolOptions,

        contraindication: m.model?.contraindication,
        note: m.model?.note,
        warning: m.model?.warning,
        medClass: m.model?.medClass,
        action: m.model?.action,
        indication: m.model?.indication,
        interaction: m.model?.interaction,
        onset: m.model?.onset,
        duration: m.model?.duration,
        taggedProtocols: [],
        createdBy: 'OneBot',

        rxNormCode: m.model?.rxNormCode,
        nemsisRoutes: m.model?.nemsisRoutes,
        minDose: m.model?.minDose,
        maxDose: m.model?.maxDose,
        metaData: m.model?.metaData,

        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
      };

      let resp = await createParentMedication(department, newMedication);
      if (resp.type === ResponseType.Success) {
        console.log('Successfully created medication', resp.data);
        // newMeds.push(resp.data);
        // //Replace all pages with the new medications
        // let newPages = filePages.map((page: any) => {
        //   let newDoses = [];
        //   for (let i = 0; i < page.doses.length; i++) {
        //     let med: MedicationItem = page.doses[i].medication;
        //     if (med.uid === m.uid) {
        //       newDoses.push({
        //         ...page.doses[i],
        //         medication: resp.data,
        //       });
        //     }
        //   }
        //   return {
        //     ...page,
        //     doses: newDoses,
        //   };
        // });
        // setFilePages(newPages);
      } else {
        console.error('Failed to create medication', resp.data);
      }
    }

    // let results = await Promise.all(promises);
    // let newMeds: MedicationItem[] = [];
    // for (let i = 0; i < results.length; i++) {
    //   if (results[i].type === ResponseType.Success) {
    //     let med: MedicationItem = results[i].data;
    //     newMeds.push(med);
    //     if (globals.debug)
    //       console.log('Successfully created medication', med.name);
    //   } else {
    //     console.error('Failed to create medication', results[i].data);
    //   }
    // }
    let isLoaded: boolean = await reloadDatabase();
    if (isLoaded) {
      if (globals.debug) console.log('Successfully reloaded database');
      setState(CHECKING_DOSES);
      setMedications(newMeds);
      setCurrent(filePages[0]);
    }
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(db.department);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const addMedicationToConfirmed = () => {
    let newConfirmed: any[] = [
      ...confirmedPages,
      {
        ...cur,
        protocol: protocol,
      },
    ];
    setConfirmedPages(newConfirmed);
    // let mps: MedicationProtocol[] =
    //   (cur.item as MedicationItem).protocolOptions || [];
    // for (let i = 0; i < curDoses.length; i++) {
    //   const dose = curDoses[i];
    //   if (!dose.confirmedData) {
    //     alert('Please confirm all doses before continuing');
    //     return;
    //   }
    //   let protocolID =
    //     dose.protocol.status === ProgressStatus.ACTIVE ||
    //     dose.protocol.activeID == null
    //       ? dose.protocol.uid
    //       : dose.protocol.activeID;
    //   if (!protocolID) {
    //     console.error('Failed to find protocol ID');
    //     return;
    //   }
    //   /* Then check if there is a medication protocol already created */
    //   let medProtocol: MedicationProtocol | null = null;
    //   for (let i = 0; i < mps.length; i++) {
    //     let po = mps[i];
    //     if (po.protocolID === protocolID) {
    //       medProtocol = po;
    //       break;
    //     }
    //   }
    //   /* Create the MedicationRange for the dose */
    //   let medRange = new MedicationRange({
    //     basis: dose.confirmedData.dose,
    //     rangeLow: dose.confirmedData.rangeLow
    //       ? Number(dose.confirmedData.rangeLow.split(' ')[0])
    //       : 0,
    //     rangeHigh: dose.confirmedData.rangeHigh
    //       ? Number(dose.confirmedData.rangeHigh.split(' ')[0])
    //       : globals.MAX_VALUE,
    //     route: dose.confirmedData.routes,
    //     title: dose.confirmedData.title,
    //     warning: dose.confirmedData.warning,
    //     instruction: dose.confirmedData.instruction,
    //     note: dose.confirmedData.note,
    //     maxDose: dose.confirmedData.maxDose,
    //     minDose: dose.confirmedData.minDose,
    //     calcMax: dose.confirmedData.calcMax,
    //     index:
    //       dose.medicationData && dose.medicationData.index
    //         ? dose.medicationData.index
    //         : 0,
    //   });
    //   /* If there is no medication protocol then create one */
    //   if (!medProtocol) {
    //     medProtocol = new MedicationProtocol({
    //       protocolID: protocolID,
    //       options: [medRange],
    //     });
    //     /* Add the medication protocol to the formik values */
    //     mps = [...mps, medProtocol];
    //   } else {
    //     /* Check if we are updating a previous dose and filter out the previoud dose otherwise add the new range */
    //     let medicationRanges = medProtocol.options;
    //     let ranges = [...medicationRanges, medRange];
    //     // medicationRanges.push(medRange);
    //     ranges.sort((a: MedicationRange, b: MedicationRange) => {
    //       if (a.rangeLow === b.rangeLow) return a.basis.localeCompare(b.basis);
    //       return a.rangeLow - b.rangeLow;
    //     });
    //     medProtocol = new MedicationProtocol({
    //       protocolID: medProtocol.protocolID,
    //       options: [...ranges],
    //     });
    //     /* Add the medication protocol to the formik values */
    //     let newProtocolOptions = mps.filter(
    //       (po: MedicationProtocol) => po.protocolID !== protocolID
    //     );
    //     mps = [...newProtocolOptions, medProtocol];
    //   }
    // }
    // let confirm = {
    //   medication: cur.item,
    //   newProtocolOptions: mps,
    //   doses: curDoses,
    // };
    // //Filter out the current medication if it exists
    // let newConfirmed: any[] = [...confirmedPages];
    // newConfirmed = newConfirmed.filter(
    //   (item) => item.medication.uid !== cur.item.uid
    // );
    // newConfirmed.push(confirm);
    // setconfirmedPages(newConfirmed);
  };

  const handleSearch = (searchTerm: string) => {
    let list = workbookList.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setList(list);
  };

  const pageTitle = useMemo(() => {
    if (state === CHECKING_DOSES) {
      return 'Checking Doses';
    } else if (state === CREATING_MEDICATIONS) {
      return 'Create Medications';
    } else {
      return 'Review Medications';
    }
  }, [state]);

  const pageTopTitle = useMemo(() => {
    if (state === CHECKING_DOSES) {
      if (index > 0) {
        let lastPage = confirmedPages[confirmedPages.length - 1];
        return 'Page: ' + lastPage.index;
      }
      return undefined;
    } else if (state === CREATING_MEDICATIONS) {
      return undefined;
    } else {
      return 'Review all of the doses before submitting';
    }
  }, [state, confirmedPages]);

  const pageDescription = useMemo(() => {
    if (state === CHECKING_DOSES) {
      return (
        'Page: ' +
        (index + 1) +
        ' / ' +
        filePages.length +
        ' (' +
        cur.index +
        ')'
      );
    } else if (state === CREATING_MEDICATIONS) {
      return (
        'Review ' + newMedications.length + ' medications before submitting'
      );
    } else {
      return 'Review all of the doses before submitting';
    }
  }, [state, newMedications, index, filePages, cur]);

  const isErrorCount = useMemo(() => {
    let count = 0;
    if (state === CREATING_MEDICATIONS) {
      for (let i = 0; i < newMedicationsData.length; i++) {
        let data = newMedicationsData[i].data;
        if (!data.name || data.name === '') count++;
        if (!data.concentrations || data.concentrations.length === 0) count++;
        if (!data.routes || data.routes.length === 0) count++;
      }
    } else if (state === CHECKING_DOSES) {
      if (cur == null) count++;
      if (protocol === null) count++;

      for (let i = 0; i < cur?.doses.length; i++) {
        let dose = cur.doses[i];
        if (dose.confirmedData) {
          if (!dose.confirmedData.dose || dose.confirmedData.dose === '')
            count++;
          if (
            !dose.confirmedData.routes ||
            dose.confirmedData.routes.length === 0
          )
            count++;
        }
        if (dose.medication == null) count++;
      }
    } else if (state === REVIEWING_DOSES) {
      if (final == null) count++;
    }
    return count;
  }, [state, newMedicationsData, cur, protocol, final]);

  const handleSaveNow = () => {
    if (state === CHECKING_DOSES) {
      /* Save the current status of the doses */
      let pages = protocol
        ? [...confirmedPages, { ...cur, protocol: protocol }]
        : confirmedPages;
      console.log('Pages', pages);
      setState(REVIEWING_DOSES);
      groupAllPageData(pages);
      if (protocol) addMedicationToConfirmed();
    }
  };

  const handleSkip = () => {
    if (state === CREATING_MEDICATIONS) {
      setModalState({
        isVisible: true,
        title: 'Skip Medications',
        description: 'Are you sure you want to skip creating medications?',
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Skip',
        primaryDescription: '',
        secondaryDescription: '',
      });
    } else if (state === CHECKING_DOSES) {
      if (index === filePages.length - 1) {
        groupAllPageData([...confirmedPages]);
        setState(REVIEWING_DOSES);
        // addMedicationToConfirmed();
      } else {
        setIndex(index + 1);
        setIndexStr((index + 2).toString());
        setCurrent(filePages[index + 1]);
        setProtocol(null);
      }
    }
  };

  const handleAddMedicationDose = () => {
    if (state === CONFIRMING_DOSES) {
      console.log('Adding Dose');
      /* Copy a blank dose into the cur doses */
      let doses = [...cur.doses];
      let dose = {
        file: {
          file: cur.file,
          index: cur.index,
        },
        isHM: false,
        medication: null,
        medicationData: {
          ageGroup: '',
          index: 0,
          dose: '',
          rangeLow: '',
          rangeHigh: '',
          routes: [],
          title: '',
          warning: '',
          instruction: '',
          note: '',
          maxSingleDose: '',
          minSingleDose: '',
          repeatAllowed: false,
          repeatTime: '',
          source: '',
        },
      };

      doses.push(dose);
      let newCur = { ...cur, doses: doses };
      setCurrent(newCur);
    }
  };

  return (
    <div className="screen-container">
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          if (modalState.secondaryBtnName === 'Submit') {
            handleCreateMedications();
            handleCreateProtocols();
          } else if (modalState.secondaryBtnName === 'Skip') {
            setModalState({ ...modalState, isVisible: false });
            if (state === CREATING_MEDICATIONS) {
              setState(CHECKING_DOSES);
              setCurrent(filePages[0]);
            }
          } else if (modalState.secondaryBtnName === 'Go to DB') {
            setModalState({ ...modalState, isVisible: false });
            navigate(`/database`);
          } else {
            setModalState({ ...modalState, isVisible: false });
            /* Go back to the last page */
            handleBackDose();
          }
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === 'Okay' ? true : false}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.primaryDescription}
        secondaryDescription={modalState.secondaryDescription}
      />
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                {pageTopTitle && (
                  <h6
                    className={`backTextLight hoverText`}
                    onClick={handleBackDose}
                  >
                    <span>
                      <IoArrowBack
                        data-testid="isBackBttn"
                        size="1.15rem"
                        onClick={handleBackDose}
                        style={{ marginRight: '5px' }}
                      />
                    </span>
                    {pageTopTitle}
                  </h6>
                )}
              </div>
              <div className="titleItemContainer">
                <div className="titleTextPH">{pageTitle}</div>
              </div>
              <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                {pageDescription}
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyItems: 'flex-end',
              paddingRight: 0,
              flexDirection: 'column',
            }}
          >
            <div className="headerButtonContainer">
              <Button
                data-testid="isSaveCancelButton"
                className="secondary-button-small-border btn-rightMargin"
                onClick={handleBack}
              >
                Cancel
              </Button>
              {state !== REVIEWING_DOSES && (
                <Button
                  data-testid="isSaveCancelButton"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleSkip}
                >
                  Skip
                </Button>
              )}
              {state === CHECKING_DOSES && (
                <Button
                  data-testid="isSaveButton"
                  className={` ${
                    // rightSideBtn === "save"
                    'primary-button btn-rightMargin'
                    // : "secondary-button-small-border btn-rightMargin"
                  }`}
                  onClick={handleSaveNow}
                  disabled={confirmedPages.length === 0}
                >
                  <span>
                    <IoSave size="1rem" />
                  </span>{' '}
                  Save Now
                </Button>
              )}
              <Button
                data-testid="isSaveButton"
                className={` ${
                  // rightSideBtn === "save"
                  'primary-button btn-rightMargin'
                  // : "secondary-button-small-border btn-rightMargin"
                }`}
                onClick={handleContinue}
                disabled={isErrorCount > 0}
              >
                {state === REVIEWING_DOSES
                  ? 'Submit'
                  : index === filePages.length - 1
                    ? 'Review'
                    : 'Continue'}{' '}
                <span>
                  <AiOutlineRight className="icon-normal" />
                </span>
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: 0,
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Page:
              </label>
              {/* <div className="p-input-icon-right"> */}
              <InputText
                type="text"
                className={`input-control`}
                style={{
                  marginLeft: '15px',
                  width: '35px',
                }}
                id="number"
                name="number"
                placeholder=""
                data-testid=""
                value={indexStr}
                onChange={(e) => {
                  let value = e.target.value;
                  let num = Number(value);
                  if (value === '' || isNaN(num)) setIndexStr('');
                  else setIndexStr(value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    let num = Number(indexStr);
                    if (indexStr === '' || isNaN(num)) {
                      setIndexStr('');
                    } else if (num < 1 || num > filePages.length) {
                      setIndexStr('');
                    }
                    if (num > 0 && num <= filePages.length) {
                      setIndex(num - 1);
                      setCurrent(filePages[num - 1]);
                    }
                  }
                }}
              />
              <label htmlFor={''} className={` input-label`}>
                / {filePages.length}
              </label>
              {/* </div> */}
            </div>
          </div>
        </div>
        <hr />
      </div>

      <div style={{ flex: 1, display: 'flex', ...props.containerStyle }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          {state === CREATING_MEDICATIONS ? (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >
              <ViewportList items={newMedicationsData}>
                {(data: any, index: number) => {
                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <NewMedicationInput
                        medData={data}
                        index={index}
                        onChange={(
                          data: any,
                          medication: MedicationItem,
                          index: number
                        ) => {
                          const newMedications = [...newMedicationsData];
                          newMedications[index].data = data;
                          setNewMedicationsData(newMedications);
                          console.log('Updated Medication', data);
                          // const newDoses = [...cur.doses];
                          // newDoses[index].confirmedData = data;
                          // setCurrentDoses(newDoses);
                        }}
                        onDelete={(
                          medication: MedicationItem,
                          index: number
                        ) => {
                          const newMedications = [...newMedicationsData];
                          newMedications.splice(index, 1);
                          setNewMedicationsData(newMedications);
                        }}
                      />
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          ) : state === CHECKING_DOSES ? (
            <Row>
              <Col
                ref={colRef}
                sm={screen.portrait ? 12 : 6}
                style={{
                  overflowY: 'auto',
                  maxHeight: screen.portrait ? '' : 'calc(88vh)',
                }}
              >
                <div className="input-container roundBorder">
                  <div>
                    <div
                      style={{ display: 'flex', marginTop: '5px' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>File:</div>
                      <div style={{ fontWeight: '500' }}>{cur.file}</div>
                    </div>
                    <div
                      style={{ display: 'flex', marginTop: '5px' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Page:</div>
                      <div style={{ fontWeight: '500' }}>{cur.index}</div>
                    </div>
                    <div
                      style={{ display: 'flex', marginTop: '5px' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Medications:</div>
                      <div style={{ fontWeight: '500' }}>
                        {cur.doses.length}
                      </div>
                    </div>
                    {isErrorCount > 0 && (
                      <div>
                        <div
                          style={{ display: 'flex', marginTop: '5px' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ marginRight: '10px', color: 'red' }}>
                            Errors:
                          </div>
                          <div style={{ fontWeight: '500' }}>
                            {isErrorCount}
                          </div>
                        </div>

                        <div
                          style={{ display: 'flex', marginTop: '5px' }}
                          className="ketamine-general-label"
                        >
                          <div style={{ fontWeight: '500' }}>
                            Cannot move on to the next page until the errors
                            have been resolved. Look for a red border on inputs
                            that are requried to have a value.
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="" style={{ padding: '0px 15px' }}>
                  <div style={{ marginTop: '15px' }}>
                    <span
                      className="ketamine-general-label"
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        marginLeft: 0,
                      }}
                    >
                      Protocol
                    </span>
                    <p className="sidebarText">
                      This is the protocol that this dose will belong to.
                    </p>
                  </div>
                  <SearchableDropdown<ProtocolItem>
                    id="searchDropdown"
                    value={protocol ? protocol : undefined}
                    options={db.protocols}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    keyField={(option) => option.uid}
                    onChange={(option: ProtocolItem) => {
                      setProtocol(option);
                    }}
                    onClear={() => {
                      setProtocol(null);
                    }}
                    isActive={true}
                    placeholder="Search protocol..."
                  />
                  {/* <div
                    onClick={() => {
                      handleAddMedicationDose();
                    }}
                    className="cursorPointer contentHeading checklist newProtocolBorder "
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <span
                      style={{
                        textDecoration: 'underLine',
                        textUnderlinePosition: 'under',
                        fontSize: '15px',
                      }}
                    >
                      <HiPlus className="text-icon " /> Add Medication
                    </span>
                  </div> */}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    marginTop: '30px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      gap: '1rem',
                    }}
                  >
                    <ViewportList ref={viewportRef} items={cur.doses}>
                      {(dose: any, index: number) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                          <MedicationDoseInput
                            dose={
                              dose.confirmedData
                                ? dose.confirmedData
                                : dose.medicationData
                            }
                            medication={dose.medication}
                            db={db}
                            index={index}
                            onChange={(data: any) => {
                              let newCur = { ...cur };
                              let newCurDoses = [...cur.doses];
                              newCurDoses[index].confirmedData = data;
                              newCur.doses = newCurDoses;
                              setCurrent(newCur);
                            }}
                            onMedicationChange={(
                              med: MedicationItem | null
                            ) => {
                              let newCur = { ...cur };
                              let newCurDoses = [...cur.doses];
                              newCurDoses[index].medication = med;
                              newCur.doses = newCurDoses;
                              setCurrent(newCur);
                            }}
                            onDelete={() => {
                              let newCur = { ...cur };
                              let newCurDoses = [...cur.doses];
                              newCurDoses.splice(index, 1);
                              newCur.doses = newCurDoses;
                              setCurrent(newCur);
                            }}
                            onCopy={(index: number) => {
                              console.log('COPY DOSE', index, dose);
                              let newCur = { ...cur };
                              let newCurDoses = [...cur.doses];
                              let clone = {
                                ...newCurDoses[index],
                              };
                              newCurDoses.push(clone);
                              newCur.doses = newCurDoses;
                              setCurrent(newCur);
                            }}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </div>
              </Col>
              {!screen.portrait && workbookList.length > 0 && (
                <Col sm={6}>
                  {selectedWorkbook ? (
                    <PDFScreen
                      pdf={workbookURL}
                      style={{
                        height: '78vh',
                        width: '100%',
                        marginLeft: '0',
                        marginRight: '0',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        flex: 1,
                        padding: '20px',
                        display: 'flex',
                        marginTop: '2rem',
                        flexDirection: 'column',
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                      }}
                      className="ketamine-general-label"
                    >
                      <h5 className="ketmine-header-text">
                        Select From Protocol Set
                      </h5>
                      <SearchBar
                        containerStyle={{ width: '100%' }}
                        value={searchQuery}
                        onChange={(searchTerm: string, e) => {
                          setSearchQuery(searchTerm);
                          handleSearch(searchTerm);
                        }}
                        placeholder={'Search Protocol Sets...'}
                      />
                      <div
                        className="contentBorder protocolCalculationPad primaryListScroll"
                        style={{ marginBottom: '10px' }}
                      >
                        <ViewportList items={list}>
                          {(item: WorkbookItem, index: number) => (
                            <div
                              className=" cursorPointer hoverItem"
                              style={{
                                alignItems: 'center',
                                display: 'flex',
                                borderBottom:
                                  index !== list.length - 1
                                    ? '1px solid #e0e0e0'
                                    : 'none',
                              }}
                              onClick={() => {
                                setSelectedWorkbook(item);
                              }}
                            >
                              <span
                                className={'a1Content'}
                                style={{ width: '100%' }}
                              >
                                <div
                                  className="notification-css-grey"
                                  style={{ fontSize: '14px' }}
                                >
                                  {item.model.updatedAt &&
                                    getFormattedDate(
                                      item.model.updatedAt,
                                      false
                                    )}
                                </div>

                                <div
                                  style={{
                                    fontSize: '16px',
                                    marginBottom: '8px',
                                  }}
                                  className="contentTitle a1SubITems"
                                >
                                  {item.name}
                                </div>
                              </span>
                            </div>
                          )}
                        </ViewportList>
                      </div>
                    </div>
                  )}
                </Col>
              )}
            </Row>
          ) : (
            <div
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <ViewportList items={cleanedConfirmedPages}>
                {(page: any, index: number) => {
                  if (!page.protocol) return null;
                  /* Group doses into twos */
                  let doses: any[] = [];
                  for (let i = 0; i < page.doses.length; i += 2) {
                    let dose1 = page.doses[i];
                    if (i + 1 >= page.doses.length)
                      doses.push({
                        dose1: dose1,
                        dose2: null,
                      });
                    else {
                      let dose2 = page.doses[i + 1];
                      doses.push({
                        dose1: dose1,
                        dose2: dose2,
                      });
                    }
                  }
                  return (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <div
                        className="border hover-raise-elevation"
                        style={{
                          display: 'flex',
                          width: '100%',
                          padding: '10px',
                          marginTop: '.5rem',
                          marginBottom: '0rem',
                        }}
                        onClick={() => {
                          if (expanded.includes(page.protocol.name)) {
                            setExpanded(
                              expanded.filter(
                                (item) => item !== page.protocol.name
                              )
                            );
                          } else {
                            setExpanded([...expanded, page.protocol.name]);
                          }
                        }}
                      >
                        {expanded.includes(page.protocol.name) ? (
                          <FaChevronDown size={14} color={'#a0a0a0'} />
                        ) : (
                          <FaChevronRight size={14} color={'#a0a0a0'} />
                        )}
                        <span
                          className="ketamine-general-label"
                          style={{ flex: 1 }}
                        >
                          {page.protocol.name}
                        </span>
                        <span
                          className="ketamine-general-label"
                          style={{ marginRight: '10px' }}
                        >
                          {page.doses.length} items
                        </span>
                        <span
                          className="iconButton"
                          onClick={(e) => {
                            e.stopPropagation();
                            let newConfirmed = confirmedPages.filter(
                              (item) =>
                                item.protocol.name !== page.protocol.name
                            );
                            setConfirmedPages(newConfirmed);
                            groupAllPageData(newConfirmed);
                          }}
                        >
                          <BiTrash
                            data-testid="isFullScreenButton"
                            className="icon"
                            style={{
                              color: '#B03A2E',
                            }}
                          />
                        </span>
                      </div>
                      {expanded.includes(page.protocol.name) &&
                        doses.length > 0 && (
                          <div
                            style={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'column',
                              gap: '1rem',
                            }}
                          >
                            <ViewportList items={doses}>
                              {(dose: any, index: number) => {
                                let dose1 = dose.dose1;
                                let dose2 = dose.dose2;
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      marginBottom: '10px',
                                      flexDirection: 'row',
                                      width: '100%',
                                      display: 'flex',
                                      flex: 1,
                                      gap: '1rem',
                                    }}
                                  >
                                    <div
                                      style={{
                                        flex: 1,
                                        maxWidth: '50%',
                                      }}
                                    >
                                      <MedicationDoseInput
                                        dose={
                                          dose1.confirmedData
                                            ? dose1.confirmedData
                                            : dose1.medicationData
                                        }
                                        db={db}
                                        medication={dose1.medication}
                                        index={index}
                                        onChange={(data: any) => {}}
                                      />
                                    </div>
                                    {dose2 && (
                                      <div
                                        style={{
                                          flex: 1,
                                          maxWidth: '50%',
                                        }}
                                      >
                                        <MedicationDoseInput
                                          dose={
                                            dose2.confirmedData
                                              ? dose2.confirmedData
                                              : dose2.medicationData
                                          }
                                          db={db}
                                          medication={dose2.medication}
                                          index={index}
                                          onChange={(data: any) => {}}
                                        />
                                      </div>
                                    )}
                                  </div>
                                );
                              }}
                            </ViewportList>
                          </div>
                        )}
                    </div>
                  );
                }}
              </ViewportList>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportPagesResults;
