import React from 'react';
import { Box } from '@mui/material';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: '1024px',
        margin: '0 auto',
        marginTop: '20px',
        fontSize: '1rem',
      }}
    >
      <div
        style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}
      >
        Privacy Policy of Hinckley Medical, Inc.
        <span
          style={{
            fontSize: '0.9rem',
            fontStyle: 'italic',
            marginLeft: '5px',
            fontWeight: 'normal',
            color: '#616161',
          }}
        >
          Revised October 21st, 2024
        </span>
      </div>
      <div
        style={{
          color: '#616161',
        }}
      >
        <div style={{ marginBottom: '16px' }}>
          For questions about the privacy policy information on this page,
          contact:
        </div>

        <div style={{ marginBottom: '16px' }}>
          <strong>Hinckley Medical, Inc.</strong>
          <br />
          8485 210th St W<br />
          Lakeville, MN 55044 USA
          <br />
          support@hinckleymed.com
          <br />
          (402) 207-0220
        </div>

        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
          Privacy Policy for Website and Digital Activities
        </span>
        <span
          style={{
            fontSize: '0.9rem',
            marginBottom: '16px',
            fontStyle: 'italic',
          }}
        >
          Revised September 12, 2023
        </span>
        <div style={{ marginBottom: '16px' }}>
          This privacy policy describes how Hinckley Medical, Inc. collects and
          processes personal information through its website and digital
          activities. By using our website, email services, and/or social media,
          you are consenting to the practices described herein and from related
          third-party service providers.
        </div>
        <div style={{ marginBottom: '16px' }}>
          Hinckley Medical, Inc. hosts its website on the WordPress platform at{' '}
          <span style={{ fontWeight: 'bold' }}>https://hinckleymed.com/ .</span>{' '}
          We utilize a number of plugins and services to provide Web visitors
          with a good experience:
        </div>
        <ul
          style={{
            paddingLeft: '0px',
            marginBottom: '16px',
            listStyleType: 'none',
          }}
        >
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>Google Analytics, </span>
            <a
              href="https://analytics.google.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://analytics.google.com
            </a>
          </li>
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>
              MailChimp Email Services,{' '}
            </span>
            <a
              href="https://mailchimp.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://mailchimp.com
            </a>
          </li>
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>
              HubSpot Marketing Services,{' '}
            </span>
            <a
              href="https://www.hubspot.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://www.hubspot.com
            </a>
          </li>
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>Facebook Social Media, </span>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://www.facebook.com
            </a>
          </li>
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>LinkedIn Social Media, </span>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://www.linkedin.com
            </a>
          </li>
          <li style={{}}>
            <span style={{ fontWeight: 'bold' }}>YouTube Social Media, </span>
            <a
              href="https://www.youtube.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2d7abf', textDecoration: 'none' }}
            >
              https://www.youtube.com
            </a>
          </li>
        </ul>

        <div style={{ marginBottom: '16px' }}>
          Several of the above plugins and services compile information to help
          analyze how visitors use our website. Example information includes
          content viewed, time spent viewing, city location of visitors, and
          viewing device types. If you elect to receive email marketing
          messages, promotional emails will be processed through MailChimp and
          will offer unsubscribe options in email messages. Hinckley Medical,
          Inc. does not sell any personal data.
        </div>
        <div style={{ marginBottom: '16px' }}>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '5px',
              marginBottom: '16px',
            }}
          >
            Privacy Policy for OneDose Application
          </span>
          <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
            Revised September 12, 2023
          </span>
        </div>
        <div style={{ marginBottom: '16px' }}>1. Introduction</div>
        <div style={{ marginBottom: '16px' }}>
          Welcome to the OneDose application (“we”, “our”, or “us”). We respect
          your privacy and are committed to protecting it through our compliance
          with this policy. This policy describes the types of information we
          may collect from you or that you may provide when you use our OneDose
          application and our practices for collecting, using, and disclosing
          that information.
        </div>

        <div style={{ marginBottom: '16px' }}>2. Information We Collect</div>
        <div style={{ marginBottom: '16px' }}>
          Device Software Version Information: To provide the best experience
          and ensure compatibility, we access and determine your device’s
          software version.
        </div>
        <div style={{ marginBottom: '16px' }}>
          Location Information: For devices with software versions Android 11
          and below, we request location permissions. This is solely to enable
          the Bluetooth Low Energy (BLE) feature, which requires location
          permission to scan for nearby devices such as the OneWeight gurney
          scale.
        </div>
        <div style={{ marginBottom: '16px' }}>
          For devices with software versions Android 12 or higher, scanning for
          nearby devices is required to ensure smooth connectivity with our
          OneWeight scale.
        </div>

        <div style={{ marginBottom: '16px' }}>
          3. How We Use Your Information
        </div>
        <div style={{ marginBottom: '16px' }}>
          We use the device software version information:
        </div>
        <div>
          To determine if location permission is necessary to activate BLE
          functionalities or if scanning for nearby devices is essential based
          on the software version.
        </div>
        <div style={{ marginBottom: '16px' }}>
          Your location information is used for:
        </div>
        <div>
          Enabling BLE functionality on Android 11 and lower, which is essential
          for scanning and connecting to our OneWeight scale.
        </div>
        <div style={{ marginBottom: '16px' }}>
          Devices with Android 12 or higher will utilize scanning features
          without accessing location to connect to the OneWeight scale.
        </div>

        <div style={{ marginBottom: '16px' }}>4. Storage of Information</div>
        <div style={{ marginBottom: '16px' }}>
          We do not store your device’s software version or location
          information. It is temporarily accessed to enable BLE or scanning
          functionalities, depending on your device’s Android version, and
          promptly discarded afterward.
        </div>

        <div style={{ marginBottom: '16px' }}>
          5. Disclosure of Your Information
        </div>
        <div style={{ marginBottom: '16px' }}>
          We will not share, sell, rent, or trade your device software version
          or location information with any third parties.
        </div>

        <div style={{ marginBottom: '16px' }}>6. Data Security</div>
        <div style={{ marginBottom: '16px' }}>
          Though we do not store your data, we care about the security of your
          information when it is being transmitted. While no method of
          electronic transmission or storage is 100% secure, we incorporate
          standard security practices to protect the data you share with us.
        </div>

        <div style={{ marginBottom: '16px' }}>
          7. Changes to Our Privacy Policy
        </div>
        <div style={{ marginBottom: '16px' }}>
          We may update our privacy policy from time to time. We will notify you
          of any changes by posting the new privacy policy on this page. We
          advise you to review this privacy policy periodically for any changes.
        </div>

        <div style={{ marginBottom: '16px' }}>8. Contact Information</div>
        <div style={{ marginBottom: '16px' }}>
          For any questions about this privacy policy, please contact us using
          the information at the top of this page.
        </div>

        <div style={{ marginBottom: '16px' }}>9. Consent</div>
        <div style={{ marginBottom: '16px' }}>
          By using our OneDose application, you hereby consent to our privacy
          policy and agree to its terms.
        </div>
      </div>
    </Box>
  );
};

export default PrivacyPolicyPage;
