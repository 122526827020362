import React, { useEffect, useState } from 'react';

import { globals } from '../../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { InputText } from 'primereact/inputtext';
import { ModelMetaData, User } from '../../../../models';

import { Col, Row } from 'react-bootstrap';

import { ProgressStatus } from '../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ProtocolAccordionSecondLayer from '../../protocol/details/ProtocolAccordionSecondLayer';
import MedicationAccordion from '../../protocol/edit/Medication/MedicationAccordion';
import { TbDatabaseOff } from 'react-icons/tb';
import { handleUpdateSelectedAccordion } from '../../../../store/actions';
import { getDepartmentByID } from '../../../../data/functions/DepartmentDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';

interface FolderComparisonProps {
  focusItem: ProtocolItem;
  handleBack: () => void;
  viewText?: string;
}

const ProtocolFullView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
}) => {
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [departmentNames, setDepartmentNames] = useState<string>('');
  const user: User = useSelector((state: any) => state?.user);
  const selectedAccordion = useSelector(
    (state: any) => state.protocol.selectedAccordion
  );
  const department: DepartmentItem = useSelector(
    (state: any) => state.protocol.departmentItem.department
  );

  const handleClick = (e: any) => {
    dispatch<any>(handleUpdateSelectedAccordion(e.index));
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };
  useEffect(() => {
    if (focusItem?.pairedDepIDs) {
      handleGetDepartment(focusItem.pairedDepIDs).then((names) => {
        setDepartmentNames(names.join(', '));
      });
    } else {
      setDepartmentNames('');
    }
  }, [focusItem?.pairedDepIDs]);

  const handleGetDepartment = async (ids: string[]) => {
    const departmentNames: string[] = [];
    for (let i = 0; i < ids.length; i++) {
      const department = await getDepartmentByID(ids[i]);
      if (department) {
        departmentNames.push(department.name);
      }
    }
    return departmentNames;
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5
            className="ketmine-header-text backTextLight hoverText"
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <span>
              <IoArrowBack
                data-testid="isBackBttn"
                size="1.15rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            General Information
          </h5>

          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {focusItem.modifiedBy
                    ? focusItem.modifiedBy.firstName +
                      ' ' +
                      focusItem.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>

              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
              </div>

              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.uid}
                    <span>
                      {isCopied && isCopied === focusItem.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(focusItem.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                focusItem.status === ProgressStatus.DRAFT &&
                focusItem.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.activeID}
                      <span>
                        {isCopied && isCopied === focusItem.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(focusItem.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.name}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="nickname" className="ketamine-general-label">
            Nickname <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="nickname"
              name="nickname"
              data-testid="nickname"
              required={true}
              value={focusItem?.nickname ? focusItem.nickname : ''}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>

          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    focusItem.rangeLow && Number(focusItem?.rangeLow) !== 0
                      ? String(focusItem.rangeLow)
                      : ''
                  }
                  // style={{color: Number(focusItem?.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    focusItem?.rangeHigh &&
                    Number(focusItem.rangeHigh) !== globals.MAX_VALUE
                      ? String(focusItem.rangeHigh)
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="Name" className="ketamine-general-label">
            Paired Departments <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.pairedDepIDs ? departmentNames : ''}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral ">
          <h5 className="ketmine-header-text">Weight-Based Calculations</h5>
          {focusItem.sum == 0 && (
            <div className="no-data-container">
              <TbDatabaseOff size={120} className="light-grey-icon" />
              <h4 className="light-grey-icon">
                No weight-based calculations...
              </h4>
            </div>
          )}
          <Accordion
            activeIndex={selectedAccordion}
            onTabChange={handleClick}
            multiple
          >
            {focusItem.medications?.length >= 1 && (
              <AccordionTab data-testid="Medications" header="Medications">
                <div>
                  <MedicationAccordion
                    medicationList={focusItem.medications}
                    protocol={focusItem}
                    isEditMode={isEditMode}
                    onReorder={() => {}}
                    onItemClick={() => {}}
                  />
                </div>
              </AccordionTab>
            )}
            {focusItem.infusions?.length >= 1 && (
              <AccordionTab data-testid="Infusions" header="Infusions">
                <div>
                  <ProtocolAccordionSecondLayer
                    data={focusItem.infusions}
                    protocol={focusItem}
                    type={'Infusion'}
                    accordionType={'Infusion'}
                    isEditMode={isEditMode}
                    onReorder={() => {}}
                    department={department}
                  />
                </div>
              </AccordionTab>
            )}

            {focusItem.electrical?.length >= 1 && (
              <AccordionTab data-testid="Electrical" header="Electrical">
                <div>
                  <ProtocolAccordionSecondLayer
                    data={focusItem.electrical}
                    protocol={focusItem}
                    type={'Electrical'}
                    accordionType={'Electrical'}
                    onReorder={() => {}}
                    isEditMode={isEditMode}
                    department={department}
                  />
                </div>
              </AccordionTab>
            )}
            {focusItem.equipment?.length >= 1 && (
              <AccordionTab data-testid="Equipment" header="Equipment">
                <div>
                  <ProtocolAccordionSecondLayer
                    data={focusItem.equipment}
                    protocol={focusItem}
                    type={'Equipment'}
                    accordionType={'Equipment'}
                    onReorder={() => {}}
                    isEditMode={isEditMode}
                    department={department}
                  />
                </div>
              </AccordionTab>
            )}
            {focusItem.forms?.length >= 1 && (
              <AccordionTab data-testid="Checklist" header="Checklist">
                <div>
                  <ProtocolAccordionSecondLayer
                    data={focusItem.forms}
                    protocol={focusItem}
                    type={'Checklist'}
                    accordionType={'Checklist'}
                    onReorder={() => {}}
                    isEditMode={isEditMode}
                    department={department}
                  />
                </div>
              </AccordionTab>
            )}
            {focusItem.pairedProtocols?.length >= 1 && (
              <AccordionTab
                data-testid="Paired Protocols"
                header="Paired Protocols"
              >
                <div>
                  <ProtocolAccordionSecondLayer
                    data={focusItem.pairedProtocols}
                    protocol={focusItem}
                    type={'Paired Protocol'}
                    accordionType={'Paired Protocol'}
                    isEditMode={isEditMode}
                    onReorder={() => {}}
                    department={department}
                  />
                </div>
              </AccordionTab>
            )}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ProtocolFullView;
