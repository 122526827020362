import { filter } from 'lodash';
import { getDepartment, listDepartments } from '../../graphql/queries';
import {
  Department,
  DepartmentConfig,
  LazyDepartment,
  SoftwareType,
  User,
} from '../../models';
import {
  getSoftwarePackage,
  hasAdminUserAccess,
} from '../../ui/_global/common/Utils';
import {
  executeQuery,
  executeSingleQuery,
  findDepartmentLogo,
  Response,
  ResponseType,
} from '../AmplifyDB';
import KeychainItem from './KeychainItem';
import { DataStore } from 'aws-amplify';
export const NO_ADMIN = 0,
  RESTRICTED_ADMIN = 1,
  PROTOCOLS = 2,
  LIMITED_ADMIN = 3,
  FULL_ADMIN = 4;
class DepartmentItem {
  TAG = '';
  id: string;
  name: string;
  location: string;
  logoURL: string;
  protocolVersion: string;
  shiftTypes: string[];
  subDepIDs: string[];
  parentDepID: string | null | undefined;
  hashedPin: string;
  salt: string;
  departmentCode: string;

  logoVerifiedUrl: string | null | undefined;

  model: Department | LazyDepartment;
  subDeps: DepartmentItem[] | null;
  allSubDeps: DepartmentItem[] | null = null;
  grandParentDep: DepartmentItem | null | undefined;
  parentDep: DepartmentItem | null | undefined;
  activeSubDep: DepartmentItem | null | undefined;
  isSoftwareOnly: boolean = false;
  isMultiDep: boolean = false;
  config: DepartmentConfig | undefined;
  softwarePlan: SoftwareType;

  keychainID: string | null | undefined;
  isPublic: boolean = false;
  keychain: KeychainItem | null | undefined;
  isNemsisConfig: boolean = false;
  isOneWeightEnabled: boolean = true;
  infusionCalculation: boolean = false;
  isRealTimeEnabled: boolean = false;
  isAgeFilterEnabled: boolean = false;
  renewalDate: Date | null | undefined;
  isPublicSignup: boolean = true;
  isTopEnabled: boolean = true;
  isSearchedPairedDeps: boolean = false;
  gttsCalculations: number[] | null = null;

  adminLevel: number = FULL_ADMIN;

  constructor(depInfo: Department | LazyDepartment) {
    this.id = depInfo.id;
    this.name = depInfo.name;
    this.location = depInfo.location ? depInfo.location : 'N/A';
    this.logoURL = depInfo.logoID;

    this.shiftTypes = depInfo.shiftTypes ? depInfo.shiftTypes : [];
    this.subDepIDs = depInfo.subDepIDs ? depInfo.subDepIDs : [];
    this.parentDepID = depInfo.parentDepID ? depInfo.parentDepID : null;
    this.hashedPin = depInfo.hashedPin ? depInfo.hashedPin : '';
    this.salt = depInfo.saltedPin ? depInfo.saltedPin : '';
    this.protocolVersion = depInfo.protocolVersions
      ? depInfo.protocolVersions
      : 'v1.0.0';
    this.isSoftwareOnly = depInfo.softwarePlan === SoftwareType.SOFTWARE_ONLY;
    this.keychainID = depInfo.keychainID;
    this.isPublic = depInfo.isPublic ? depInfo.isPublic : false;
    this.config = depInfo.config ? depInfo.config : undefined;
    this.departmentCode = depInfo.uniqueCode ? depInfo.uniqueCode : '';
    this.isPublicSignup = depInfo.isPublicSignup;

    if (depInfo.subDepIDs && depInfo.subDepIDs.length > 0) {
      this.subDeps = [];
      this.isMultiDep = true;
    } else this.subDeps = null;
    let plan = getSoftwarePackage(depInfo.softwarePlan);
    this.softwarePlan = plan != null ? plan : SoftwareType.PREMIUM;
    this.adminLevel = this.getAdminLevel();

    this.model = depInfo;
    this.activeSubDep = null;
    if (this.config) {
      this.isOneWeightEnabled =
        this.config.oneweightEnabled === false ? false : true;
      this.isNemsisConfig =
        this.config.epcrProvider != null && this.config.epcrProvider !== ''
          ? true
          : false;
      this.infusionCalculation =
        this.config.infusionCalculation === true ? true : false;
      this.isRealTimeEnabled =
        this.config.realTimeUpdating === true ? true : false;
      this.isAgeFilterEnabled =
        this.config.ageFilterEnabled === true ? true : false;
      this.isTopEnabled = this.config.isTopEnabled === true ? true : false;
      this.renewalDate = this.config.renewalDate
        ? new Date(this.config.renewalDate)
        : null;
      this.gttsCalculations = this.config.infusionGTTS
        ? this.config.infusionGTTS
        : null;
    }
  }

  getTopLevelDep(): DepartmentItem {
    if (this.parentDep) return this.parentDep.getTopLevelDep();
    else return this;
  }

  async checkParentDep(
    useDataStore: boolean = true
  ): Promise<DepartmentItem | null> {
    return new Promise((resolve, reject) => {
      if (this.parentDepID) {
        if (useDataStore) {
          DataStore.query(Department, this.parentDepID).then(
            async (parentDep) => {
              if (parentDep) {
                let parent = new DepartmentItem(parentDep);
                let urlResp = await findDepartmentLogo(parent);
                if (urlResp.type === ResponseType.Success)
                  parent.logoVerifiedUrl = urlResp.data;

                this.parentDep = parent;
                this.parentDep.addSubDep(this);
                await this.parentDep.checkParentDep(useDataStore);
                this.parentDep.isMultiDep = true;
                this.isMultiDep = true;
                resolve(this.parentDep);
              }
            }
          );
        } else {
          executeSingleQuery(getDepartment, { id: this.parentDepID }).then(
            async (parentDep) => {
              if (parentDep) {
                let parent = new DepartmentItem(parentDep);
                let urlResp = await findDepartmentLogo(parent);
                if (urlResp.type === ResponseType.Success)
                  parent.logoVerifiedUrl = urlResp.data;

                this.parentDep = parent;
                this.parentDep.addSubDep(this);
                await this.parentDep.checkParentDep(useDataStore);
                this.parentDep.isMultiDep = true;
                this.isMultiDep = true;
                resolve(this.parentDep);
              }
            }
          );
        }
      } else resolve(null);
    });
  }

  findAllSubDeps() {
    if (this.subDeps) {
      this.allSubDeps = [];
      for (let subDep of this.subDeps) {
        this.allSubDeps.push(subDep);
        if (subDep.subDeps) {
          subDep.findAllSubDeps();
          this.allSubDeps = this.allSubDeps.concat(subDep.allSubDeps ?? []);
        }
      }
      this.allSubDeps = this.allSubDeps.filter(
        (dep, index, self) =>
          self.findIndex((t) => t.id === dep.id) === index && dep.id !== this.id
      );
    }
  }

  /* Recursivly fettch all parents and sub departments associated with this department */
  fetchAllDepartments(
    visitedDepartmentIds = new Set<string>()
  ): DepartmentItem[] {
    let allDepartments: DepartmentItem[] = [];

    // Check if this department's ID has already been visited
    if (visitedDepartmentIds.has(this.id)) {
      return [];
    }

    // Mark this department's ID as visited
    visitedDepartmentIds.add(this.id);

    // Include the parent department and recursively fetch its departments
    if (this.parentDep) {
      allDepartments.push(this.parentDep);
      allDepartments = allDepartments.concat(
        this.parentDep.fetchAllDepartments(visitedDepartmentIds)
      );
    }

    // Include the sub-departments and recursively fetch their departments
    if (this.subDeps && this.subDeps.length > 0) {
      allDepartments = allDepartments.concat(this.subDeps);
      for (let subDep of this.subDeps) {
        allDepartments = allDepartments.concat(
          subDep.fetchAllDepartments(visitedDepartmentIds)
        );
      }
    }

    allDepartments.push(this);

    return allDepartments.filter(
      (dep, index, self) => self.findIndex((t) => t.id === dep.id) === index
    );
  }

  async checkSubDeps(useDataStore: boolean = true): Promise<DepartmentItem[]> {
    return new Promise((resolve, reject) => {
      try {
        if (useDataStore) {
          DataStore.query(Department, (d) => d.parentDepID.eq(this.id)).then(
            async (respDeps) => {
              if (respDeps) {
                let allDeps: DepartmentItem[] = [];
                let subDeps: DepartmentItem[] = [];
                this.isMultiDep = true;
                if (this.parentDep)
                  for (let subDep of respDeps) {
                    let subDepItem = new DepartmentItem(subDep);
                    let urlResp = await findDepartmentLogo(subDepItem);
                    if (urlResp.type === ResponseType.Success)
                      subDepItem.logoVerifiedUrl = urlResp.data;

                    allDeps.push(subDepItem);
                    subDeps.push(subDepItem);
                    subDepItem.parentDep = this;
                    let deps = await subDepItem.checkSubDeps(useDataStore);
                    if (deps) allDeps = allDeps.concat(deps);
                  }
                this.subDeps = subDeps;
                this.allSubDeps = allDeps;
                this.subDeps.sort((a, b) => a.name.localeCompare(b.name));
                this.subDeps = this.subDeps.filter(
                  (dep, index, self) =>
                    self.findIndex((t) => t.id === dep.id) === index &&
                    dep.id !== this.id
                );
                this.allSubDeps.sort((a, b) => a.name.localeCompare(b.name));
                this.allSubDeps = this.allSubDeps.filter(
                  (dep, index, self) =>
                    self.findIndex((t) => t.id === dep.id) === index &&
                    dep.id !== this.id
                );
              }
              resolve(this.subDeps ?? []);
            }
          );
        } else {
          executeQuery(listDepartments, {
            filter: {
              and: [
                {
                  parentDepID: {
                    eq: this.id,
                  },
                },
                {
                  _deleted: {
                    ne: true,
                  },
                },
              ],
            },
          }).then(async (respDeps) => {
            if (respDeps) {
              let allDeps: DepartmentItem[] = [];
              let subDeps: DepartmentItem[] = [];
              this.isMultiDep = true;
              if (this.parentDep) this.parentDep.isMultiDep = true;

              for (let subDep of respDeps) {
                let subDepItem = new DepartmentItem(subDep);
                let urlResp = await findDepartmentLogo(subDepItem);
                if (urlResp.type === ResponseType.Success)
                  subDepItem.logoVerifiedUrl = urlResp.data;

                allDeps.push(subDepItem);
                subDeps.push(subDepItem);
                subDepItem.parentDep = this;
                let deps = await subDepItem.checkSubDeps(useDataStore);
                if (deps) allDeps = allDeps.concat(deps);
              }
              this.subDeps = subDeps;
              this.allSubDeps = allDeps;
              this.subDeps.sort((a, b) => a.name.localeCompare(b.name));
              this.subDeps = this.subDeps.filter(
                (dep, index, self) =>
                  self.findIndex((t) => t.id === dep.id) === index &&
                  dep.id !== this.id
              );
              this.allSubDeps.sort((a, b) => a.name.localeCompare(b.name));
              this.allSubDeps = this.allSubDeps.filter(
                (dep, index, self) =>
                  self.findIndex((t) => t.id === dep.id) === index &&
                  dep.id !== this.id
              );
            }
            resolve(this.subDeps ?? []);
          });
        }
      } catch (error) {
        console.log('Error: ', error);
        reject(error);
      }
    });
  }

  addSubDep(dep: DepartmentItem) {
    if (this.subDeps && this.allSubDeps) {
      this.subDeps.push(dep);
      this.subDeps.sort((a, b) => a.name.localeCompare(b.name));
      this.allSubDeps.push(dep);
      this.allSubDeps.sort((a, b) => a.name.localeCompare(b.name));
      this.allSubDeps = this.allSubDeps.filter(
        (dep, index, self) =>
          self.findIndex((t) => t.id === dep.id) === index && dep.id !== this.id
      );
    }
  }

  calculateAdminLevel(user: User) {
    this.adminLevel = this.getAdminLevel();
    if (!hasAdminUserAccess(this, user)) this.adminLevel = NO_ADMIN;
  }

  getAdminLevel(): number {
    if (this.softwarePlan === SoftwareType.ESSENTIALS) {
      if (this.parentDepID) return NO_ADMIN;
      else return PROTOCOLS;
    }

    if (this.softwarePlan === SoftwareType.STARTER) {
      if (this.parentDepID) return NO_ADMIN;
      else return PROTOCOLS;
    }

    if (this.softwarePlan === SoftwareType.PLUS) {
      if (this.parentDepID) return LIMITED_ADMIN;
      else return FULL_ADMIN;
    }

    if (this.softwarePlan === SoftwareType.PROFESSIONAL) {
      if (this.parentDepID) return RESTRICTED_ADMIN;
      else return FULL_ADMIN;
    }

    return FULL_ADMIN;
  }
}

export default DepartmentItem;
