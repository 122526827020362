import { DataStore, graphqlOperation, Storage } from 'aws-amplify';
import {
  ResponseType,
  Response,
  findDepartmentLogo,
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
} from '../AmplifyDB';
import KeychainItem from '../model/KeychainItem';

import { globals } from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import {
  CreateGroupInput,
  DeleteGroupInput,
  DepartmentConfigInput,
  Group,
  GroupType,
  SoftwareType,
  UpdateGroupInput,
} from '../../API';
import { uniqueId } from 'lodash';
import {
  getDepartment,
  getGroup,
  groupsByDepartmentID,
} from '../../graphql/queries';
import {
  Department,
  DepartmentConfig,
  Keychain,
  LazyDepartment,
  User,
} from '../../models';
import GroupItem from '../model/GroupItem';
import { createGroup, deleteGroup, updateGroup } from '../../graphql/mutations';

export type GroupJSON = {
  name: string;
  type: GroupType;
  isAutoGen: boolean;
  departmentID: string;
  pairedDepIDs: string[];
  pairedUserIDs: string[];
};

/**
 * Create a new group in the database and choose the version
 * @param group GroupJSON JSON format
 * @param previousItem GroupItem The previous item to update
 * @returns The successful GroupItem or the error
 */
export const createGroupItem = async (
  group: GroupJSON | GroupItem,
  previousItem?: GroupItem | null | undefined
): Promise<Response> => {
  try {
    let json: GroupJSON;
    if (group instanceof GroupItem) {
      json = {
        name: group.name,
        type: group.type,
        isAutoGen: group.isAutoGen,
        departmentID: group.departmentID,
        pairedDepIDs: group.pairedDepIDs != null ? group.pairedDepIDs : [],
        pairedUserIDs: group.pairedUserIDs != null ? group.pairedUserIDs : [],
      };
    } else json = group;

    let c: Group;

    if (previousItem) {
      console.log('Updating Group:', previousItem.uid);
      let dbGroup = await executeSingleQuery(getGroup, {
        id: previousItem.uid,
      });
      if (dbGroup == null) {
        console.log('The NOTIFICATION Group does not exist, could not update');
        return {
          type: ResponseType.Failure,
          data: 'The NOTIFICATION Group does not exist, could not update',
        };
      }

      // c = await DataStore.save(
      //   Group.copyOf(dbGroup, (updated) => {
      //     updated.name = json.name;
      //     updated.type = json.type;
      //     updated.isAutoGen = json.isAutoGen;
      //     updated.departmentID = json.departmentID;
      //     updated.pairedDepIDs = json.pairedDepIDs;
      //     updated.pairedUserIDs = json.pairedUserIDs;
      //   })
      // );
      c = await executeSingleQuery(updateGroup, {
        input: {
          id: previousItem.uid,
          name: json.name,
          type: json.type,
          isAutoGen: json.isAutoGen,
          departmentID: json.departmentID,
          pairedDepIDs: json.pairedDepIDs,
          pairedUserIDs: json.pairedUserIDs,
          _version: dbGroup.any_model._version,
        } as UpdateGroupInput,
      });
    } else {
      console.log('Creating Group:', json.name);
      // c = await DataStore.save(
      //   new Group({
      //     name: json.name,
      //     type: json.type,
      //     isAutoGen: json.isAutoGen,
      //     departmentID: json.departmentID,
      //     pairedDepIDs: json.pairedDepIDs,
      //     pairedUserIDs: json.pairedUserIDs,
      //   })
      // );
      c = await executeSingleQuery(createGroup, {
        input: {
          name: json.name,
          type: json.type,
          isAutoGen: json.isAutoGen,
          departmentID: json.departmentID,
          pairedDepIDs: json.pairedDepIDs,
          pairedUserIDs: json.pairedUserIDs,
        } as CreateGroupInput,
      });
    }

    if (globals.debug) console.log('Created Group:', c);
    let groupItem = new GroupItem(c);
    return {
      type: ResponseType.Success,
      data: groupItem,
    };
  } catch (e) {
    console.log('Error creating group:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const removeGroup = async (groupItem: GroupItem): Promise<Response> => {
  try {
    let id: string = groupItem.uid;
    let dbGroup = await executeSingleQuery(getGroup, { id: id });
    if (dbGroup == null) {
      return {
        type: ResponseType.Failure,
        data: 'The NOTIFICATION Group does not exist could not update',
      };
    }
    let _version = dbGroup.any_model._version;

    let result = await executeSingleQuery(updateGroup, {
      input: {
        id: id,
        _version: _version,
        isDeleted: true,
      } as UpdateGroupInput,
    });

    if (globals.debug) console.log('Deleted Group:', result);
    return {
      type: ResponseType.Success,
      data: result,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchGroups = async (
  dep: DepartmentItem,
  allowAutoGen = false
): Promise<Response> => {
  try {
    let filter: any = { isAutoGen: { ne: true } };
    if (allowAutoGen) filter = {};

    let groupsList: Group[] = await executeQuery(groupsByDepartmentID, {
      departmentID: dep.id,
      filter: filter,
    });
    console.log('Groups:', groupsList);

    let groups: GroupItem[] = [];
    for (let i = 0; i < groupsList.length; i++) {
      let group = new GroupItem(groupsList[i]);
      groups.push(group);
    }
    return {
      type: ResponseType.Success,
      data: groups,
    };
  } catch (error) {
    console.error('Error fetching groups:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
