import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { globals } from '../../ui/_global/common/Utils';
import { Keychain, User } from '../../models';
import { ProgressStatus } from '../../models';
import { type } from 'os';
import ProtocolItem from './ProtocolItem';
import { getHashedPin } from '../../ui/_global/common/Encrypt';
import CategoryItem from './CategoryItem';

class KeychainItem extends ModelItem<Keychain> {
  hash: string;
  salt: string;
  type: string;

  isUnlocked: boolean = false;
  createdBy: User | null | undefined;

  categories: CategoryItem[] = [];
  protocols: ProtocolItem[] = [];

  constructor(keychain: Keychain) {
    super(
      keychain,
      keychain.name,
      keychain.departmentID,
      undefined,
      keychain.version
    );
    this.hash = keychain.hashedPin;
    this.salt = keychain.saltPin;
    this.type = keychain.hashType;

    this.isUnlocked = this.checkUnlocked(keychain);
  }

  checkUnlocked(keychain: Keychain): boolean {
    let value: string | null = localStorage.getItem(keychain.id);
    if (value) {
      let json: any = JSON.parse(value);
      const expiration = Number(json.expiration);
      const currentTime = new Date().getTime();

      /* If unexpired validate signature */
      if (expiration > currentTime) {
        let signature = getHashedPin(
          keychain.hashedPin,
          keychain.saltPin + expiration.toLocaleString(),
          keychain.hashType
        );
        if (signature === json.signature) return true;
      } else localStorage.removeItem(keychain.id);
    }
    return false;
  }

  toString(): string {
    return `${this.TAG} -> {
      uid: ${this.uid},
      name: ${this.name},
      depID: ${this.departmentID},
      version: ${this.version},
      model: ${this.model},
      modifiedBy: ${this.modifiedBy},
      createdBy: ${this.createdBy},
      hash: ${this.hash},
      salt: ${this.salt},
      type: ${this.type},
    }`;
  }
}

export default KeychainItem;
