import React, { useState } from 'react';

import { BiCopy, BiSolidCopy } from 'react-icons/bi';

import { ModelMetaData, User } from '../../../../models';

import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import VitalItem from '../../../../data/model/VitalItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import { formatForMatchingUnits } from '../../../../data/model/EquipmentItem';
interface FolderComparisonProps {
  focusItem: VitalItem;
  handleBack?: () => void;
  viewText?: string;
  changes?: any;
  showHeader?: boolean;
  keepBefore?: boolean;
}

const VitalComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
  changes,
  showHeader,
  keepBefore,
}) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  const findBeforeVitalValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const findCurrentVitalValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const getVitalOptionsLength = () => {
    let currentSize = findCurrentVitalValue(changes, 'options').length;
    let beforeSizeAfterFilter = findBeforeVitalValue(changes, 'options').filter(
      (option: any) =>
        (option.archiveValue?.rangeLow !== '' &&
          option.archiveValue?.rangeLow !== undefined) ||
        (option.archiveValue?.rangeHigh !== '' &&
          option.archiveValue?.rangeHigh !== undefined) ||
        (option.archiveValue?.amnt !== '' &&
          option.archiveValue?.amnt !== undefined)
    ).length;
    const result = {
      beforeSizeAfterFilter,
      currentSize,
    };
    return result;
  };

  const getTextColor = (index: number) => {
    const { beforeSizeAfterFilter, currentSize } = getVitalOptionsLength();

    if (currentSize > beforeSizeAfterFilter) {
      return index >= beforeSizeAfterFilter ? '#016B62' : 'black';
    }
    return 'black';
  };

  const formatOptions = (options: any[]) => {
    return options.map((change: any, index: number) => {
      const hasValues =
        (change.archiveValue?.rangeLow !== undefined &&
          change.archiveValue?.rangeLow !== '') ||
        (change.archiveValue?.rangeHigh !== undefined &&
          change.archiveValue?.rangeHigh !== '') ||
        (change.archiveValue?.amnt !== undefined &&
          change.archiveValue?.amnt !== '');

      if (!hasValues) {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0fr 1fr 1fr 1fr',
              gap: '15px',
              margin: '10px 0px',
              minHeight: '45px',
            }}
          ></div>
        );
      }

      return (
        <div
          key={index}
          style={{
            display: 'grid',
            gridTemplateColumns: '0fr 1fr 1fr 1fr',
            gap: '15px',
            margin: '10px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
              minWidth: '70px',
              marginBottom: '5px',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Option {index + 1}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={`rangeLow-${index}`} className="input-label">
              Range Low
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <div>{change.archiveValue?.rangeLow || ''}</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={`rangeHigh-${index}`} className="input-label">
              Range High
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <div>{change.archiveValue?.rangeHigh || ''}</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={`value-${index}`} className="input-label">
              Value
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <div>
                {formatForMatchingUnits(
                  change.archiveValue?.amntLow,
                  change.archiveValue?.amntHigh
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const renderOptionsChanges = (optionsChanges: any) => {
    return optionsChanges.map((change: any, index: number) => (
      <div
        className=""
        style={{
          display: 'grid',
          gridTemplateColumns: '0fr 1fr 1fr 1fr',
          gap: '15px',
          margin: '10px 0px',
        }}
        key={index}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            minWidth: '70px',
            marginBottom: '5px',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: getTextColor(index),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Option {index + 1}
          </div>
        </div>
        {
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Range Low
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.rangeLow || ''}
                afterText={change.currentValue?.rangeLow || ''}
              />
            </div>
          </div>
        }
        {
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Range High
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.rangeHigh || ''}
                afterText={change.currentValue?.rangeHigh || ''}
              />
            </div>
          </div>
        }
        {
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Value
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={
                  formatForMatchingUnits(
                    change.archiveValue?.amntLow,
                    change.archiveValue?.amntHigh
                  ) || ''
                }
                afterText={
                  formatForMatchingUnits(
                    change.currentValue?.amntLow,
                    change.currentValue?.amntHigh
                  ) || ''
                }
              />
            </div>
          </div>
        }
      </div>
    ));
  };

  const findOptionsKey = (changes: any[]) => {
    return changes.find((change) => change.key === 'options');
  };

  const findOtherKeys = (changes: any[]) => {
    return changes.find((change) => change.key !== 'options');
  };
  return (
    <div>
      <div className="ketamineContent">
        {findOtherKeys(changes) && (
          <div
            className="KetamineGeneral"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            {showHeader && (
              <h5
                className="ketmine-header-text backTextLight hoverText"
                style={{ color: 'black' }}
                onClick={handleBack}
              >
                <span>
                  <IoArrowBack
                    data-testid="isBackBttn"
                    size="1.15rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
                General Information
              </h5>
            )}

            {showHeader && (
              <div className="input-container roundBorder">
                <div>
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Modified By:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.modifiedBy
                        ? focusItem.modifiedBy.firstName +
                          ' ' +
                          focusItem.modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </div>
                  </div>

                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Version:</div>
                    <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
                  </div>

                  {user.type === UserType.ADMIN && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {focusItem.uid}
                        <span>
                          {isCopied && isCopied === focusItem.uid ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) => handleCopy(focusItem.uid, e)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {user.type === UserType.ADMIN &&
                    focusItem.status === ProgressStatus.DRAFT &&
                    focusItem.activeID != null && (
                      <div
                        style={{ display: 'flex' }}
                        className="ketamine-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Active ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {focusItem.activeID}
                          <span>
                            {isCopied && isCopied === focusItem.activeID ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(focusItem.activeID as string, e)
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            {(findBeforeValue(changes, 'name') ||
              findCurrentValue(changes, 'name')) && (
              <>
                {' '}
                <label htmlFor="Name" className="ketamine-general-label">
                  Name <span className="required-field">*</span>
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                    minHeight: '30px',
                  }}
                >
                  {keepBefore ? (
                    <div>{findBeforeValue(changes, 'name')}</div>
                  ) : (
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'name')}
                      afterText={findCurrentValue(changes, 'name')}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
        {findOptionsKey(changes) && (
          <div
            className="KetamineGeneral"
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <h5 className="ketmine-header-text">Vital Options</h5>

            {(findBeforeVitalValue(changes, 'options') ||
              findCurrentVitalValue(changes, 'options')) && (
              <>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '10px',
                    borderRadius: '5px',
                    minHeight: '30px',
                  }}
                >
                  {keepBefore ? (
                    <div>
                      {formatOptions(
                        findBeforeVitalValue(changes, 'options') || []
                      )}
                    </div>
                  ) : (
                    <div>
                      {renderOptionsChanges(
                        findCurrentVitalValue(changes, 'options') || []
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VitalComparisonView;
