import React, { useState } from 'react';

import { globals } from '../../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { InputText } from 'primereact/inputtext';
import { ViewportList } from 'react-viewport-list';
import { ModelMetaData, User } from '../../../../models';
import { HiPlus } from 'react-icons/hi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { Col, Row } from 'react-bootstrap';

import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import ElectricalDoseUI from '../../protocol/edit/Electrical/ElectricalDoseUI';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
interface FolderComparisonProps {
  focusItem: ElectricalItem;
  handleBack: () => void;
  viewText?: string;
}

const ElectricalFullView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);
  const value = focusItem;
  const selectedProtocol: any = null;
  const [protocol, setProtocol] = useState<ProtocolItem>(selectedProtocol);
  const [parmElectrical, setParmElectrical] = useState<ElectricalItem>(value);
  const [subElectricalItems, setSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(
    protocol
      ? (value as ElectricalItem).subItems.filter((item: ElectricalSubItem) => {
          return item.parentProtocol.uid === protocol.uid;
        })
      : value.subItems
  );

  const [allSubElectricalItems, setAllSubElectricalItems] = useState<
    ElectricalSubItem[]
  >(parmElectrical.subItems);
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    [...new Set(allSubElectricalItems.map((m) => m.parentProtocol))].sort(
      (a, b) => {
        if (a.parent.index === b.parent.index)
          return a.name.localeCompare(b.name);
        return a.parent.index - b.parent.index;
      }
    )
  );
  const SHOW_PROTOCOL_ID = false;
  const [expanded, setExpanded] = useState<string[]>([]);

  function toMetaDataString(metaData: ModelMetaData) {
    return JSON.stringify(metaData, null, 2);
  }

  const ListRender = ({ data }: any) => {
    return (
      <ViewportList items={data}>
        {(dose: ElectricalSubItem, index: number) => {
          const lastIndex = data.length === index + 1;
          return (
            <div key={index} style={{ cursor: 'pointer' }}>
              <ElectricalDoseUI
                dose={dose}
                doseIndex={index + 1}
                showProtocol={false}
              />
            </div>
          );
        }}
      </ViewportList>
    );
  };

  const ProtocolItemUI = ({ protocol }: any) => {
    const doses = allSubElectricalItems.filter(
      (item: ElectricalSubItem) => item.parentProtocol.uid === protocol.uid
    );
    // if (globals.debug) console.log('Doses', doses);
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '10px',
          marginTop: '.25rem',
          marginBottom: '.25rem',
        }}
      >
        <div
          className={`border hover-raise-elevation ${
            expanded.includes(protocol.uid) ? 'expanded' : ''
          }`}
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
          }}
          onClick={() => {
            if (expanded.includes(protocol.uid)) {
              setExpanded(expanded.filter((item) => item !== protocol.uid));
            } else {
              setExpanded([...expanded, protocol.uid]);
            }
          }}
        >
          {expanded.includes(protocol.uid) ? (
            <FaChevronDown size={14} color={'#a0a0a0'} />
          ) : (
            <FaChevronRight size={14} color={'#a0a0a0'} />
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span
              className="ketamine-general-label"
              style={{ flex: 1, fontWeight: '400' }}
            >
              {protocol.name}
            </span>
          </div>
          <span
            className="ketamine-general-label"
            style={{ marginRight: '10px' }}
          >
            {doses.length} doses
          </span>
        </div>
        {expanded.includes(protocol.uid) && (
          <div style={{ flex: 1, padding: '0 10px 10px 10px' }}>
            <ListRender data={doses} />
          </div>
        )}
      </div>
    );
  };

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <h5
            className="ketmine-header-text backTextLight hoverText"
            style={{ color: 'black' }}
            onClick={handleBack}
          >
            <span>
              <IoArrowBack
                data-testid="isBackBttn"
                size="1.15rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
            General Information
          </h5>

          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {focusItem.modifiedBy
                    ? focusItem.modifiedBy.firstName +
                      ' ' +
                      focusItem.modifiedBy.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>

              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Version:</div>
                <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
              </div>

              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.uid}
                    <span>
                      {isCopied && isCopied === focusItem.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) => handleCopy(focusItem.uid, e)}
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === UserType.ADMIN &&
                focusItem.status === ProgressStatus.DRAFT &&
                focusItem.activeID != null && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>Active ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.activeID}
                      <span>
                        {isCopied && isCopied === focusItem.activeID ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) =>
                              handleCopy(focusItem.activeID as string, e)
                            }
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name <span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={focusItem?.name}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
          <Row>
            <Col sm={6}>
              <label htmlFor="rangeLow" className="ketamine-general-label">
                Range Low (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="name"
                  name="rangeLow"
                  data-testid="rangeLow"
                  value={
                    focusItem.rangeLow && Number(focusItem?.rangeLow) !== 0
                      ? String(focusItem.rangeLow)
                      : ''
                  }
                  // style={{color: Number(focusItem?.rangeLow) ? '#9e9e9e' : '#000000'}}
                  placeholder="Min"
                  disabled={!isEditMode}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label htmlFor="rangeHigh" className="ketamine-general-label">
                Range High (kg)
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="rangeHigh"
                  name="rangeHigh"
                  data-testid="rangeHigh"
                  disabled={!isEditMode}
                  value={
                    focusItem?.rangeHigh &&
                    Number(focusItem.rangeHigh) !== globals.MAX_VALUE
                      ? String(focusItem.rangeHigh)
                      : ''
                  }
                  placeholder="Max"
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          <label htmlFor="Warning" className="ketamine-general-label">
            Warning
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="warning"
              name="warning"
              required={true}
              data-testid="warning"
              value={focusItem?.model?.warning || ''}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Instruction" className="ketamine-general-label">
            Instruction
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="instruction"
              name="instruction"
              required={true}
              data-testid="instruction"
              value={focusItem?.model?.instruction || ''}
            />
            <div className="input-border"></div>
          </div>
          <label htmlFor="Note" className="ketamine-general-label">
            Note
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="note"
              name="note"
              required={true}
              data-testid="note"
              value={focusItem?.model?.note || ''}
            />
            <div className="input-border"></div>
          </div>
        </div>
        {allProtocols.length > 0 && (
          <div className="KetamineGeneral">
            <h5 className="ketmine-header-text">Electrical Shocks</h5>
            {isEditMode && (
              <div style={{ marginTop: '5px' }}>
                <span className="ketamine-general-label">Shocks</span>
                <div
                  className={`contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder  ${
                    isEditMode && 'cursorPointer newRouteButton'
                  }`}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <span
                    style={{
                      textDecoration: 'underLine',
                      textUnderlinePosition: 'under',
                    }}
                  >
                    <HiPlus className="text-icon " /> Add Shock
                  </span>
                </div>
              </div>
            )}

            {protocol ? (
              <ViewportList items={subElectricalItems}>
                {(dose: ElectricalSubItem, index: number) => {
                  return (
                    <div key={index} style={{ cursor: 'pointer' }}>
                      <ElectricalDoseUI
                        dose={dose}
                        doseIndex={index + 1}
                        showProtocol={protocol == null}
                      />
                    </div>
                  );
                }}
              </ViewportList>
            ) : (
              <ViewportList items={allProtocols}>
                {(protocol: ProtocolItem, index: number) => {
                  return <ProtocolItemUI key={index} protocol={protocol} />;
                }}
              </ViewportList>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ElectricalFullView;
