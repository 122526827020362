import { globals } from '../common/Utils';
import { Colors } from './colors';

class PatientTheme {
  private title: string;
  private primary: string;
  private primarySelected: string;
  private secondary: string;
  private secondarySelected: string;
  private bgPrimary: string;
  private bgSecondary: string;
  private contrast: string;
  private isDark: boolean;

  /**
   * Creates a new PatientTheme object
   * @param weight The weight of the patient in Lbs.
   */
  constructor(weight: number) {
    this.isDark = false;
    this.title = this.getColortitle(weight);
    this.primary = this.getColorPrimary(weight);
    this.primarySelected = this.getColorPrimarySelected(weight);
    this.secondary = this.getColorSecondary(weight);
    this.secondarySelected = this.getColorSecondarySelected(weight);
    this.bgPrimary = this.getColorBgPrimary(weight);
    this.bgSecondary = this.getColorBgSecondary(weight);
    this.contrast = this.getColorContrast(weight);
  }

  getTitle(): string {
    return this.title;
  }

  getPrimary(): string {
    return this.primary;
  }

  getPrimarySelected(): string {
    return this.primarySelected;
  }

  getSecondary(): string {
    return this.secondary;
  }

  getSecondarySelected(): string {
    return this.secondarySelected;
  }

  getBgPrimary(): string {
    return this.bgPrimary;
  }

  getBgSecondary(): string {
    return this.bgSecondary;
  }

  getContrast(): string {
    return this.contrast;
  }

  private getColortitle(weight: number): string {
    if (weight == -globals.MAX_VALUE) return 'Protocols';
    else if (weight < 5.5) return 'Grey';
    else if (weight < 7.5) return 'Pink';
    else if (weight < 9.5) return 'Red';
    else if (weight < 11.5) return 'Purple';
    else if (weight < 14.5) return 'Yellow';
    else if (weight < 18.5) return 'White';
    else if (weight < 23.5) return 'Blue';
    else if (weight < 29.5) return 'Orange';
    else if (weight < 36.5) return 'Green';
    else if (weight < 69.5) return 'Sm. Adult';
    else if (weight < 99.5) return 'Adult';
    else return 'Lg. Adult';
  }

  private getColorPrimary(weight: number): string {
    if (weight == -globals.MAX_VALUE)
      return this.isDark ? Colors.hm_onPrimary : Colors.white;
    else if (weight < 5.5) return Colors.patient_grey;
    else if (weight < 7.5) return Colors.patient_pink;
    else if (weight < 9.5) return Colors.patient_red;
    else if (weight < 11.5) return Colors.patient_purple;
    else if (weight < 14.5) return Colors.patient_yellow;
    else if (weight < 18.5) return Colors.patient_white;
    else if (weight < 23.5) return Colors.patient_blue;
    else if (weight < 29.5) return Colors.patient_orange;
    else if (weight < 36.5) return Colors.patient_green;
    else if (weight < 69.5) return Colors.patient_small_adult;
    else if (weight < 99.5) return Colors.patient_adult;
    else return Colors.patient_large_adult;
  }

  private getColorPrimarySelected(weight: number): string {
    if (weight == -globals.MAX_VALUE) return Colors.hm_green_accent_dark;
    else if (weight < 5.5) return Colors.patient_grey_selected;
    else if (weight < 7.5) return Colors.patient_pink_selected;
    else if (weight < 9.5) return Colors.patient_red_selected;
    else if (weight < 11.5) return Colors.patient_purple_selected;
    else if (weight < 14.5) return Colors.patient_yellow_selected;
    else if (weight < 18.5) return Colors.patient_white_selected;
    else if (weight < 23.5) return Colors.patient_blue_selected;
    else if (weight < 29.5) return Colors.patient_orange_selected;
    else if (weight < 36.5) return Colors.patient_green_selected;
    else if (weight < 69.5) return Colors.patient_small_adult_selected;
    else if (weight < 99.5) return Colors.patient_adult_selected;
    else return Colors.patient_large_adult_selected;
  }

  private getColorSecondary(weight: number): string {
    if (weight < 5.5)
      return !this.isDark
        ? Colors.patient_grey_light
        : Colors.patient_dark_grey_light;
    else if (weight < 7.5)
      return !this.isDark
        ? Colors.patient_pink_light
        : Colors.patient_dark_pink_light;
    else if (weight < 9.5)
      return !this.isDark
        ? Colors.patient_red_light
        : Colors.patient_dark_red_light;
    else if (weight < 11.5)
      return !this.isDark
        ? Colors.patient_purple_light
        : Colors.patient_dark_purple_light;
    else if (weight < 14.5)
      return !this.isDark
        ? Colors.patient_yellow_light
        : Colors.patient_dark_yellow_light;
    else if (weight < 18.5)
      return !this.isDark
        ? Colors.patient_white_light
        : Colors.patient_dark_white_light;
    else if (weight < 23.5)
      return !this.isDark
        ? Colors.patient_blue_light
        : Colors.patient_dark_blue_light;
    else if (weight < 29.5)
      return !this.isDark
        ? Colors.patient_orange_light
        : Colors.patient_dark_orange_light;
    else if (weight < 36.5)
      return !this.isDark
        ? Colors.patient_green_light
        : Colors.patient_dark_green_light;
    else if (weight < 69.5)
      return !this.isDark
        ? Colors.patient_small_adult_light
        : Colors.patient_dark_small_adult_light;
    else if (weight < 99.5)
      return !this.isDark
        ? Colors.patient_adult_light
        : Colors.patient_dark_adult_light;
    else
      return !this.isDark
        ? Colors.patient_large_adult_light
        : Colors.patient_dark_large_adult_light;

    // if (weight < 5.5) return Colors.patient_grey_light;
    // else if (weight < 7.5) return Colors.patient_pink_light;
    // else if (weight < 9.5) return Colors.patient_red_light;
    // else if (weight < 11.5) return Colors.patient_purple_light;
    // else if (weight < 14.5) return Colors.patient_yellow_light;
    // else if (weight < 18.5) return Colors.patient_white_light;
    // else if (weight < 23.5) return Colors.patient_blue_light;
    // else if (weight < 29.5) return Colors.patient_orange_light;
    // else if (weight < 36.5) return Colors.patient_green_light;
    // else if (weight < 69.5) return Colors.patient_small_adult_light;
    // else if (weight < 99.5) return Colors.patient_adult_light;
    // else return Colors.patient_large_adult_light;
  }

  private getColorSecondarySelected(weight: number): string {
    if (weight < 5.5) return Colors.patient_grey_light_selected;
    else if (weight < 7.5) return Colors.patient_pink_light_selected;
    else if (weight < 9.5) return Colors.patient_red_light_selected;
    else if (weight < 11.5) return Colors.patient_purple_light_selected;
    else if (weight < 14.5) return Colors.patient_yellow_light_selected;
    else if (weight < 18.5) return Colors.patient_white_light_selected;
    else if (weight < 23.5) return Colors.patient_blue_light_selected;
    else if (weight < 29.5) return Colors.patient_orange_light_selected;
    else if (weight < 36.5) return Colors.patient_green_light_selected;
    else if (weight < 69.5) return Colors.patient_small_adult_light_selected;
    else if (weight < 99.5) return Colors.patient_adult_light_selected;
    else return Colors.patient_large_adult_light_selected;
  }

  private getColorBgPrimary(weight: number): string {
    if (weight < 5.5)
      return !this.isDark
        ? Colors.patient_grey_bg
        : Colors.patient_dark_grey_bg;
    else if (weight < 7.5)
      return !this.isDark
        ? Colors.patient_pink_bg
        : Colors.patient_dark_pink_bg;
    else if (weight < 9.5)
      return !this.isDark ? Colors.patient_red_bg : Colors.patient_dark_red_bg;
    else if (weight < 11.5)
      return !this.isDark
        ? Colors.patient_purple_bg
        : Colors.patient_dark_purple_bg;
    else if (weight < 14.5)
      return !this.isDark
        ? Colors.patient_yellow_bg
        : Colors.patient_dark_yellow_bg;
    else if (weight < 18.5)
      return !this.isDark
        ? Colors.patient_white_bg
        : Colors.patient_dark_white_bg;
    else if (weight < 23.5)
      return !this.isDark
        ? Colors.patient_blue_bg
        : Colors.patient_dark_blue_bg;
    else if (weight < 29.5)
      return !this.isDark
        ? Colors.patient_orange_bg
        : Colors.patient_dark_orange_bg;
    else if (weight < 36.5)
      return !this.isDark
        ? Colors.patient_green_bg
        : Colors.patient_dark_green_bg;
    else if (weight < 69.5)
      return !this.isDark
        ? Colors.patient_small_adult_bg
        : Colors.patient_dark_small_adult_bg;
    else if (weight < 99.5)
      return !this.isDark
        ? Colors.patient_adult_bg
        : Colors.patient_dark_adult_bg;
    else
      return !this.isDark
        ? Colors.patient_large_adult_bg
        : Colors.patient_dark_large_adult_bg;
  }

  private getColorBgSecondary(weight: number): string {
    if (this.isDark) return Colors.patient_dark_bg_secondary;
    else return Colors.patient_white_bg_secondary;
  }

  private getColorContrast(weight: number): string {
    if (weight < 5.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 7.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 9.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 11.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 14.5)
      return !this.isDark
        ? Colors.patient_blue_contrast
        : Colors.patient_dark_blue_contrast;
    else if (weight < 18.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 23.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 29.5)
      return !this.isDark
        ? Colors.patient_blue_contrast
        : Colors.patient_dark_blue_contrast;
    else if (weight < 36.5)
      return !this.isDark
        ? Colors.patient_blue_contrast
        : Colors.patient_dark_blue_contrast;
    else if (weight < 69.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else if (weight < 99.5)
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
    else
      return !this.isDark
        ? Colors.patient_orange_contrast
        : Colors.patient_dark_orange_contrast;
  }
}

export default PatientTheme;
