/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateDepartmentInput = {
  id?: string | null,
  name: string,
  location?: string | null,
  protocolVersions?: string | null,
  shiftTypes: Array< string >,
  cognitoID: string,
  logoID: string,
  userID: string,
  neonateCutoff: number,
  pediatricCutoff: number,
  calculators?: Array< CalculatorType > | null,
  adultRanges: Array< AdultRangeInput | null >,
  softwarePlan?: SoftwareType | null,
  subDepIDs?: Array< string > | null,
  parentDepID?: string | null,
  activeStatus?: boolean | null,
  agencyNumEMS?: string | null,
  stateIdEMS?: string | null,
  gnisCodeEMS?: string | null,
  uniqueCode: string,
  hashedPin?: string | null,
  saltedPin?: string | null,
  keychainID?: string | null,
  isPublic: boolean,
  infusionCalculation?: boolean | null,
  isPublicSignup: boolean,
  config: DepartmentConfigInput,
  _version?: number | null,
  departmentRequireSyncId?: string | null,
};

export enum CalculatorType {
  PARKLAND = "PARKLAND",
  RACE = "RACE",
  RASS = "RASS",
  APGAR_CALC = "APGAR_CALC",
  CPSSS_CALC = "CPSSS_CALC",
  ETT_DEPTH_TIDAL_VOL_CALC = "ETT_DEPTH_TIDAL_VOL_CALC",
  GCS_CALC = "GCS_CALC",
  IBW_ABW_CALC = "IBW_ABW_CALC",
  PETT_CALC = "PETT_CALC",
  PGCS_CALC = "PGCS_CALC",
}


export type AdultRangeInput = {
  label: string,
  value: number,
};

export enum SoftwareType {
  ENTERPRISE = "ENTERPRISE",
  PROFESSIONAL = "PROFESSIONAL",
  STARTER = "STARTER",
  PREMIUM = "PREMIUM",
  PLUS = "PLUS",
  ESSENTIALS = "ESSENTIALS",
  SOFTWARE_ONLY = "SOFTWARE_ONLY",
  VIEW_ONLY = "VIEW_ONLY",
}


export type DepartmentConfigInput = {
  isTopEnabled: boolean,
  neonateCutoff: number,
  pediatricCutoff: number,
  calculators?: Array< CalculatorType > | null,
  adultRanges: Array< AdultRangeInput | null >,
  softwarePlan?: SoftwareType | null,
  infusionCalculation?: boolean | null,
  isPublic: boolean,
  realTimeUpdating: boolean,
  epcrProvider?: string | null,
  oneweightEnabled: boolean,
  ageFilterEnabled: boolean,
  ageGroupFilterEnabled: boolean,
  renewalDate?: string | null,
  infusionGTTS?: Array< number > | null,
};

export type ModelDepartmentConditionInput = {
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  protocolVersions?: ModelStringInput | null,
  shiftTypes?: ModelStringInput | null,
  cognitoID?: ModelStringInput | null,
  logoID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  neonateCutoff?: ModelFloatInput | null,
  pediatricCutoff?: ModelFloatInput | null,
  calculators?: ModelCalculatorTypeListInput | null,
  softwarePlan?: ModelSoftwareTypeInput | null,
  subDepIDs?: ModelIDInput | null,
  parentDepID?: ModelIDInput | null,
  activeStatus?: ModelBooleanInput | null,
  agencyNumEMS?: ModelStringInput | null,
  stateIdEMS?: ModelStringInput | null,
  gnisCodeEMS?: ModelStringInput | null,
  uniqueCode?: ModelStringInput | null,
  hashedPin?: ModelStringInput | null,
  saltedPin?: ModelStringInput | null,
  keychainID?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  infusionCalculation?: ModelBooleanInput | null,
  isPublicSignup?: ModelBooleanInput | null,
  and?: Array< ModelDepartmentConditionInput | null > | null,
  or?: Array< ModelDepartmentConditionInput | null > | null,
  not?: ModelDepartmentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  departmentRequireSyncId?: ModelIDInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCalculatorTypeListInput = {
  eq?: Array< CalculatorType | null > | null,
  ne?: Array< CalculatorType | null > | null,
  contains?: CalculatorType | null,
  notContains?: CalculatorType | null,
};

export type ModelSoftwareTypeInput = {
  eq?: SoftwareType | null,
  ne?: SoftwareType | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Department = {
  __typename: "Department",
  id: string,
  Categories?: ModelCategoryConnection | null,
  OneWeights?: ModelOneWeightConnection | null,
  name: string,
  location?: string | null,
  protocolVersions?: string | null,
  shiftTypes: Array< string >,
  Medications?: ModelMedicationConnection | null,
  Equipment?: ModelEquipmentConnection | null,
  Forms?: ModelFormConnection | null,
  Vitals?: ModelVitalsConnection | null,
  Electricals?: ModelElectricalConnection | null,
  ElectricalShocks?: ModelElectricalShockConnection | null,
  cognitoID: string,
  Users?: ModelUserConnection | null,
  Logs?: ModelLogConnection | null,
  Ambulances?: ModelAmbulanceConnection | null,
  Drips?: ModelDripConnection | null,
  RequireSync?: RequireSync | null,
  logoID: string,
  userID: string,
  neonateCutoff: number,
  pediatricCutoff: number,
  calculators?: Array< CalculatorType > | null,
  adultRanges:  Array<AdultRange | null >,
  softwarePlan?: SoftwareType | null,
  Notifications?: ModelNotificationConnection | null,
  Contacts?: ModelContactConnection | null,
  InputForms?: ModelInputFormConnection | null,
  FormLogs?: ModelFormLogConnection | null,
  Workbooks?: ModelWorkbookConnection | null,
  WeightObjects?: ModelWeightObjectConnection | null,
  Keychains?: ModelKeychainConnection | null,
  Reviewals?: ModelReviewalConnection | null,
  Groups?: ModelGroupConnection | null,
  CPRAssists?: ModelCPRAssistConnection | null,
  subDepIDs?: Array< string > | null,
  parentDepID?: string | null,
  activeStatus?: boolean | null,
  agencyNumEMS?: string | null,
  stateIdEMS?: string | null,
  gnisCodeEMS?: string | null,
  uniqueCode: string,
  hashedPin?: string | null,
  saltedPin?: string | null,
  keychainID?: string | null,
  isPublic: boolean,
  infusionCalculation?: boolean | null,
  isPublicSignup: boolean,
  config: DepartmentConfig,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  departmentRequireSyncId?: string | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  departmentID: string,
  department?: Department | null,
  index: number,
  pairedDepIDs?: Array< string > | null,
  parentCategoryID?: string | null,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  isPublic: boolean,
  isRestrictive: boolean,
  overrideID?: string | null,
  keychainID?: string | null,
  Protocols?: ModelProtocolConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  departmentCategoriesId?: string | null,
};

export enum ProgressStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  ARCHIVE = "ARCHIVE",
  DELETED = "DELETED",
  DRAFT_DELETE = "DRAFT_DELETE",
  DEACTIVATED = "DEACTIVATED",
}


export type ModelProtocolConnection = {
  __typename: "ModelProtocolConnection",
  items:  Array<Protocol | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Protocol = {
  __typename: "Protocol",
  id: string,
  name: string,
  pdfID: string,
  categoryID: string,
  pairedProtocols?: Array< string > | null,
  medicationIDs?: Array< string > | null,
  equipmentIDs?: Array< string > | null,
  electricalIDs?: Array< string > | null,
  dripIDs?: Array< string > | null,
  formIDs?: Array< string > | null,
  index: number,
  nickname: string,
  pairedDepIDs?: Array< string > | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pdfVersion?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  privatePin?: string | null,
  keychainID?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  isPublic: boolean,
  isRestrictive: boolean,
  departmentID: string,
  MedicationDoses?: ModelMedicationDoseConnection | null,
  ElectricalDoses?: ModelElectricalDoseConnection | null,
  DripDoses?: ModelInfusionDoseConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum PatientAgeGroup {
  NEONATE = "NEONATE",
  PEDIATRIC = "PEDIATRIC",
  ADULT = "ADULT",
  GERIATRIC = "GERIATRIC",
}


export type ModelMedicationDoseConnection = {
  __typename: "ModelMedicationDoseConnection",
  items:  Array<MedicationDose | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MedicationDose = {
  __typename: "MedicationDose",
  id: string,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?:  Array<MedicationRoute > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  medicationID: string,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  Protocol?: Protocol | null,
  Medication?: Medication | null,
  CPRAssist?: CPRAssist | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type MedicationRoute = {
  __typename: "MedicationRoute",
  route: string,
  code: string,
};

export type Medication = {
  __typename: "Medication",
  id: string,
  name: string,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?:  Array<MedicationRoute > | null,
  concentration:  Array<MedicationConcentration >,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  departmentID: string,
  protocolOptions:  Array<MedicationProtocol >,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaData | null,
  MedicationDoses?: ModelMedicationDoseConnection | null,
  Concentrations?: ModelConcentrationConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type MedicationConcentration = {
  __typename: "MedicationConcentration",
  firstAmnt: number,
  firstUnit: string,
  secAmnt?: number | null,
  secUnit?: string | null,
  color?: string | null,
};

export type MedicationProtocol = {
  __typename: "MedicationProtocol",
  protocolID: string,
  options:  Array<MedicationRange >,
};

export type MedicationRange = {
  __typename: "MedicationRange",
  doseID?: string | null,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?:  Array<MedicationRoute > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
};

export type ModelMetaData = {
  __typename: "ModelMetaData",
  parentID?: string | null,
  fullName: string,
  nickName?: string | null,
  brandNames?: Array< string > | null,
  maxDose?: string | null,
  minDose?: string | null,
  absMaxDose?: string | null,
  absMinDose?: string | null,
  releaseNotes?: Array< string > | null,
};

export type ModelConcentrationConnection = {
  __typename: "ModelConcentrationConnection",
  items:  Array<Concentration | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Concentration = {
  __typename: "Concentration",
  id: string,
  firstAmnt: number,
  firstUnit: string,
  secAmnt?: number | null,
  secUnit?: string | null,
  color?: string | null,
  departmentID: string,
  medicationID?: string | null,
  dripID?: string | null,
  status?: ProgressStatus | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CPRAssist = {
  __typename: "CPRAssist",
  id: string,
  protocolIDs: Array< string >,
  epiIDs: Array< string >,
  defibIDs: Array< string >,
  equipmentID?: string | null,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  cprMetaData: CPRMetaData,
  DefibDoses?: ModelElectricalDoseConnection | null,
  EpiDoses?: ModelMedicationDoseConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CPRMetaData = {
  __typename: "CPRMetaData",
  defaultMode: string,
  epiOrangeSec: number,
  epiRedSec: number,
  compressionRate: number,
  ventRatePerMin: number,
  ventilateSoundDelay: number,
};

export type ModelElectricalDoseConnection = {
  __typename: "ModelElectricalDoseConnection",
  items:  Array<ElectricalDose | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ElectricalDose = {
  __typename: "ElectricalDose",
  id: string,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  electricalID: string,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  Protocol?: Protocol | null,
  Electrical?: ElectricalShock | null,
  CPRAssist?: CPRAssist | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ElectricalShock = {
  __typename: "ElectricalShock",
  id: string,
  title: string,
  rxNormCode?: string | null,
  options:  Array<ElectricalShockOption >,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  rangeHigh?: number | null,
  rangeLow?: number | null,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaData | null,
  ElectricalDoses?: ModelElectricalDoseConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ElectricalShockOption = {
  __typename: "ElectricalShockOption",
  protocolID: string,
  ranges:  Array<ElectricalShockRange >,
};

export type ElectricalShockRange = {
  __typename: "ElectricalShockRange",
  doseID?: string | null,
  index: number,
  basis: string,
  rangeLow: number,
  rangeHigh: number,
  calcMax?: string | null,
  fixedMax?: string | null,
  title: string,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
};

export type ModelInfusionDoseConnection = {
  __typename: "ModelInfusionDoseConnection",
  items:  Array<InfusionDose | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InfusionDose = {
  __typename: "InfusionDose",
  id: string,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?:  Array<MedicationRoute > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  dripID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  Protocol?: Protocol | null,
  Drip?: Drip | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Drip = {
  __typename: "Drip",
  id: string,
  name: string,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?:  Array<MedicationRoute > | null,
  concentration?:  Array<MedicationConcentration > | null,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  dripOptions:  Array<MedicationProtocol >,
  departmentID: string,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaData | null,
  InfusionDoses?: ModelInfusionDoseConnection | null,
  Concentrations?: ModelConcentrationConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelOneWeightConnection = {
  __typename: "ModelOneWeightConnection",
  items:  Array<OneWeight | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OneWeight = {
  __typename: "OneWeight",
  id: string,
  name: string,
  serialNumber: string,
  projectNumber: string,
  lastCalibration: string,
  type?: PWSType | null,
  departmentID: string,
  department?: Department | null,
  Logs?: ModelLogConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  departmentOneWeightsId?: string | null,
};

export enum PWSType {
  PWS_A = "PWS_A",
  PWS_B = "PWS_B",
  PWS_C = "PWS_C",
  PWS_D = "PWS_D",
}


export type ModelLogConnection = {
  __typename: "ModelLogConnection",
  items:  Array<Log | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Log = {
  __typename: "Log",
  id: string,
  actualWeight?: number | null,
  ageValue?: number | null,
  protocolID?: Array< string > | null,
  Users?: ModelLogUserConnection | null,
  departmentID: string,
  oneweightID?: string | null,
  ambulanceID?: string | null,
  incidentNumber?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications:  Array<LogMedication >,
  equipments:  Array<LogEquipment >,
  electricals:  Array<LogElectrical >,
  cpr?: LogCPR | null,
  patientSaved?: string | null,
  patientReleased?: string | null,
  arrivedAtHospital?: string | null,
  ageTimestamp?: string | null,
  forms:  Array<LogForm >,
  subtractedItems?:  Array<LogWeightObject > | null,
  userIDs: Array< string >,
  infusions:  Array<LogDrip >,
  estimatedSaved?: string | null,
  tags: Array< string >,
  shifts?:  Array<LogMedicShift > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelLogUserConnection = {
  __typename: "ModelLogUserConnection",
  items:  Array<LogUser | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type LogUser = {
  __typename: "LogUser",
  id: string,
  userId: string,
  logId: string,
  user: User,
  log: Log,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type User = {
  __typename: "User",
  id: string,
  firstName: string,
  lastName: string,
  type: UserType,
  cognitoID: string,
  departmentID: string,
  depAdmins?: Array< string > | null,
  logs?: ModelLogUserConnection | null,
  hashedPin: string,
  saltPin: string,
  pairedDepIDs?: Array< string > | null,
  oneDoseVersion?: string | null,
  lastActivity?: string | null,
  notificationTokens?: Array< string > | null,
  status?: UserStatus | null,
  FormLogs?: ModelFormLogConnection | null,
  Reviewals?: ModelReviewalConnection | null,
  Acknowledges?: ModelAcknowledgeConnection | null,
  UserComments?: ModelUserCommentConnection | null,
  NotifyACKs?: ModelNotifyACKConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum UserType {
  ADMIN = "ADMIN",
  DEPT_ADMIN = "DEPT_ADMIN",
  DEPT = "DEPT",
  USER = "USER",
}


export enum UserStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  DELETED = "DELETED",
}


export type ModelFormLogConnection = {
  __typename: "ModelFormLogConnection",
  items:  Array<FormLog | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type FormLog = {
  __typename: "FormLog",
  id: string,
  responses?:  Array<FormResponse > | null,
  inputformID: string,
  departmentID: string,
  userID: string,
  pairedDepID?: string | null,
  pairedUserIDs?: Array< string > | null,
  notes?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FormResponse = {
  __typename: "FormResponse",
  questionIndex: number,
  type: FormType,
  responses: Array< string >,
};

export enum FormType {
  SHORT_ANSWER = "SHORT_ANSWER",
  LONG_ANSWER = "LONG_ANSWER",
  DROPDOWN = "DROPDOWN",
  SINGLE_SELECT = "SINGLE_SELECT",
  MULTI_SELECT = "MULTI_SELECT",
  DATE_ENTRY = "DATE_ENTRY",
  NUMBER_ANSWER = "NUMBER_ANSWER",
  TRUE_FALSE = "TRUE_FALSE",
}


export type ModelReviewalConnection = {
  __typename: "ModelReviewalConnection",
  items:  Array<Reviewal | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Reviewal = {
  __typename: "Reviewal",
  id: string,
  title: string,
  description?: string | null,
  reviewers: Array< string >,
  status: ACKStatus,
  events:  Array<LogEvent >,
  createdAt: string,
  endedAt?: string | null,
  departmentID: string,
  userID: string,
  User?: User | null,
  DraftGroups?: ModelDraftGroupConnection | null,
  Acknowledges?: ModelAcknowledgeConnection | null,
  UserComments?: ModelUserCommentConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum ACKStatus {
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CLOSED = "CLOSED",
  PUBLISHED = "PUBLISHED",
  VIEWED = "VIEWED",
}


export type LogEvent = {
  __typename: "LogEvent",
  color: string,
  title: string,
  description: string,
  timestamp: string,
  icon?: string | null,
};

export type ModelDraftGroupConnection = {
  __typename: "ModelDraftGroupConnection",
  items:  Array<DraftGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DraftGroup = {
  __typename: "DraftGroup",
  id: string,
  reviewalID: string,
  departmentID: string,
  ownerID: string,
  title: string,
  description?: string | null,
  createdAt: string,
  Reviewal?: Reviewal | null,
  DraftChanges?: ModelDraftChangeConnection | null,
  isClosed: boolean,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelDraftChangeConnection = {
  __typename: "ModelDraftChangeConnection",
  items:  Array<DraftChange | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DraftChange = {
  __typename: "DraftChange",
  id: string,
  draftGroupID: string,
  departmentID: string,
  changeID: string,
  previousID?: string | null,
  changeType: string,
  isClosed: boolean,
  createdAt: string,
  DraftGroup?: DraftGroup | null,
  Comments?: ModelUserCommentConnection | null,
  Acknowledges?: ModelAcknowledgeConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelUserCommentConnection = {
  __typename: "ModelUserCommentConnection",
  items:  Array<UserComment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UserComment = {
  __typename: "UserComment",
  id: string,
  ownerID: string,
  userID: string,
  acknowledgeID?: string | null,
  message: string,
  relatedItems?: Array< string > | null,
  createdAt: string,
  User?: User | null,
  Acknowledge?: Acknowledge | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Acknowledge = {
  __typename: "Acknowledge",
  id: string,
  ownerID: string,
  userID: string,
  status: ACKStatus,
  createdAt: string,
  User?: User | null,
  Comment?: UserComment | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelAcknowledgeConnection = {
  __typename: "ModelAcknowledgeConnection",
  items:  Array<Acknowledge | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelNotifyACKConnection = {
  __typename: "ModelNotifyACKConnection",
  items:  Array<NotifyACK | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type NotifyACK = {
  __typename: "NotifyACK",
  id: string,
  ownerID: string,
  userID: string,
  status: ACKStatus,
  createdAt: string,
  User?: User | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type LogMedication = {
  __typename: "LogMedication",
  medicationID: string,
  amntSolid: string,
  amntLiquid: string,
  timestamp: string,
  verified: boolean,
  name: string,
  rxNormCode?: string | null,
  concentration: string,
  isActualWeight: boolean,
  adminType: string,
  route?: MedicationRoute | null,
  weight: number,
  protocolID?: string | null,
};

export type LogEquipment = {
  __typename: "LogEquipment",
  equipmentID: string,
  amount: string,
  name: string,
  snomedCode?: string | null,
  timestamp: string,
  isActualWeight: boolean,
  verified: boolean,
  weight: number,
  successful: boolean,
  protocolID?: string | null,
};

export type LogElectrical = {
  __typename: "LogElectrical",
  electricalID: string,
  name: string,
  snomedCode?: string | null,
  amount: string,
  timestamp: string,
  isActualWeight: boolean,
  verified: boolean,
  weight: number,
  protocolID?: string | null,
};

export type LogCPR = {
  __typename: "LogCPR",
  cprID: string,
  startTime: string,
  endTime: string,
  outcome?: PatientOutcomeType | null,
};

export enum PatientOutcomeType {
  SKIP = "SKIP",
  ROSC_FIELD = "ROSC_FIELD",
  ROSC_ED = "ROSC_ED",
  RESUSC_OTHER_TEAM = "RESUSC_OTHER_TEAM",
  RESUSC_OUR_TEAM = "RESUSC_OUR_TEAM",
}


export type LogForm = {
  __typename: "LogForm",
  formID?: string | null,
  name?: string | null,
  completed: string,
  startedTime: string,
  protocolID?: string | null,
};

export type LogWeightObject = {
  __typename: "LogWeightObject",
  weightObjectID?: string | null,
  name: string,
  weight: number,
  added: string,
};

export type LogDrip = {
  __typename: "LogDrip",
  dripID: string,
  amnt: string,
  amnt2?: string | null,
  timestamp: string,
  verified: boolean,
  name: string,
  rxNormCode?: string | null,
  concentration: string,
  adminType: string,
  route?: MedicationRoute | null,
  weight: number,
  protocolID?: string | null,
  amntUnit: string,
  amntUnit2?: string | null,
  isActualWeight?: boolean | null,
  gttsDropFactor?: number | null,
  gttsAmount?: number | null,
  gttsUnit?: string | null,
};

export type LogMedicShift = {
  __typename: "LogMedicShift",
  shiftStart: string,
  shiftEnd: string,
  completedLogs?: Array< string > | null,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  taggedUsers: Array< string >,
  ownerID: string,
  departmentID: string,
  metaData?: string | null,
};

export type ModelMedicationConnection = {
  __typename: "ModelMedicationConnection",
  items:  Array<Medication | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelEquipmentConnection = {
  __typename: "ModelEquipmentConnection",
  items:  Array<Equipment | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Equipment = {
  __typename: "Equipment",
  id: string,
  name: string,
  snomedCode?: string | null,
  optionItems:  Array<EquipmentOption >,
  departmentID: string,
  title?: string | null,
  note?: string | null,
  warning?: string | null,
  instruction?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaData | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type EquipmentOption = {
  __typename: "EquipmentOption",
  amnt: string,
  amntHigh?: string | null,
  rangeLow: number,
  rangeHigh?: number | null,
};

export type ModelFormConnection = {
  __typename: "ModelFormConnection",
  items:  Array<Form | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Form = {
  __typename: "Form",
  id: string,
  name: string,
  departmentID: string,
  items:  Array<FormGroup >,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type FormGroup = {
  __typename: "FormGroup",
  title: string,
  options:  Array<FormOption >,
  isUrgent: boolean,
  isDouble: boolean,
};

export type FormOption = {
  __typename: "FormOption",
  firstItem: string,
  secItem?: string | null,
};

export type ModelVitalsConnection = {
  __typename: "ModelVitalsConnection",
  items:  Array<Vitals | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Vitals = {
  __typename: "Vitals",
  id: string,
  title: string,
  options:  Array<VitalOption >,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type VitalOption = {
  __typename: "VitalOption",
  amntLow: string,
  amntHigh: string,
  rangeLow: number,
  rangeHigh: number,
};

export type ModelElectricalConnection = {
  __typename: "ModelElectricalConnection",
  items:  Array<Electrical | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Electrical = {
  __typename: "Electrical",
  id: string,
  title: string,
  options:  Array<ElectricalOption >,
  departmentID: string,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ElectricalOption = {
  __typename: "ElectricalOption",
  title: string,
  ranges:  Array<ElectricalRange >,
};

export type ElectricalRange = {
  __typename: "ElectricalRange",
  basis: string,
  rangeLow: number,
  rangeHigh: number,
  calcMax?: string | null,
  fixedMax?: string | null,
};

export type ModelElectricalShockConnection = {
  __typename: "ModelElectricalShockConnection",
  items:  Array<ElectricalShock | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAmbulanceConnection = {
  __typename: "ModelAmbulanceConnection",
  items:  Array<Ambulance | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Ambulance = {
  __typename: "Ambulance",
  id: string,
  name: string,
  departmentID: string,
  Logs?: ModelLogConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelDripConnection = {
  __typename: "ModelDripConnection",
  items:  Array<Drip | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type RequireSync = {
  __typename: "RequireSync",
  id: string,
  sync: boolean,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type AdultRange = {
  __typename: "AdultRange",
  label: string,
  value: number,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  type: NotificationType,
  title: string,
  message?: string | null,
  timestamp: string,
  expirationTimestamp: string,
  deadlineTimestamp?: string | null,
  isReadIDs: Array< string >,
  isAckIDs: Array< string >,
  imageURLs?: Array< string > | null,
  videoURLs?: Array< string > | null,
  fileURLs?: Array< string > | null,
  taggedProtocols: Array< string >,
  questions:  Array<Question >,
  formQuestions:  Array<FormQuestion >,
  isPush: boolean,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  Groups?: ModelGroupNotificationConnection | null,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum NotificationType {
  GENERAL = "GENERAL",
  ACKNOWLEDGE = "ACKNOWLEDGE",
  TRAINING = "TRAINING",
}


export type Question = {
  __typename: "Question",
  question: string,
  options: Array< string >,
  answer: string,
  taggedProtocol?: string | null,
};

export type FormQuestion = {
  __typename: "FormQuestion",
  index: number,
  type: FormType,
  question: string,
  description?: string | null,
  answers?: Array< string > | null,
  correctAnswer?: Array< string > | null,
};

export type ModelGroupNotificationConnection = {
  __typename: "ModelGroupNotificationConnection",
  items:  Array<GroupNotification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type GroupNotification = {
  __typename: "GroupNotification",
  id: string,
  notificationId: string,
  groupId: string,
  notification: Notification,
  group: Group,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Group = {
  __typename: "Group",
  id: string,
  name: string,
  type: GroupType,
  departmentID: string,
  pairedDepIDs: Array< string >,
  pairedUserIDs: Array< string >,
  isAutoGen: boolean,
  Department?: Department | null,
  Notifications?: ModelGroupNotificationConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export enum GroupType {
  NOTIFICATION = "NOTIFICATION",
}


export type ModelContactConnection = {
  __typename: "ModelContactConnection",
  items:  Array<Contact | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Contact = {
  __typename: "Contact",
  id: string,
  fullName: string,
  title?: string | null,
  number: string,
  note?: string | null,
  index: number,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelInputFormConnection = {
  __typename: "ModelInputFormConnection",
  items:  Array<InputForm | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type InputForm = {
  __typename: "InputForm",
  id: string,
  name: string,
  items:  Array<FormQuestion >,
  FormLogs?: ModelFormLogConnection | null,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelWorkbookConnection = {
  __typename: "ModelWorkbookConnection",
  items:  Array<Workbook | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Workbook = {
  __typename: "Workbook",
  id: string,
  name: string,
  workbookID: string,
  fileSize: number,
  aiPdfParserResults?: string | null,
  activeID?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelWeightObjectConnection = {
  __typename: "ModelWeightObjectConnection",
  items:  Array<WeightObject | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type WeightObject = {
  __typename: "WeightObject",
  id: string,
  name: string,
  weight: number,
  createdBy: string,
  modifiedBy?: string | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelKeychainConnection = {
  __typename: "ModelKeychainConnection",
  items:  Array<Keychain | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Keychain = {
  __typename: "Keychain",
  id: string,
  name: string,
  hashedPin: string,
  saltPin: string,
  hashType: string,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: string | null,
  departmentID: string,
  Protocols?: ModelProtocolConnection | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection",
  items:  Array<Group | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCPRAssistConnection = {
  __typename: "ModelCPRAssistConnection",
  items:  Array<CPRAssist | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type DepartmentConfig = {
  __typename: "DepartmentConfig",
  isTopEnabled: boolean,
  neonateCutoff: number,
  pediatricCutoff: number,
  calculators?: Array< CalculatorType > | null,
  adultRanges:  Array<AdultRange | null >,
  softwarePlan?: SoftwareType | null,
  infusionCalculation?: boolean | null,
  isPublic: boolean,
  realTimeUpdating: boolean,
  epcrProvider?: string | null,
  oneweightEnabled: boolean,
  ageFilterEnabled: boolean,
  ageGroupFilterEnabled: boolean,
  renewalDate?: string | null,
  infusionGTTS?: Array< number > | null,
};

export type UpdateDepartmentInput = {
  id: string,
  name?: string | null,
  location?: string | null,
  protocolVersions?: string | null,
  shiftTypes?: Array< string > | null,
  cognitoID?: string | null,
  logoID?: string | null,
  userID?: string | null,
  neonateCutoff?: number | null,
  pediatricCutoff?: number | null,
  calculators?: Array< CalculatorType > | null,
  adultRanges?: Array< AdultRangeInput | null > | null,
  softwarePlan?: SoftwareType | null,
  subDepIDs?: Array< string > | null,
  parentDepID?: string | null,
  activeStatus?: boolean | null,
  agencyNumEMS?: string | null,
  stateIdEMS?: string | null,
  gnisCodeEMS?: string | null,
  uniqueCode?: string | null,
  hashedPin?: string | null,
  saltedPin?: string | null,
  keychainID?: string | null,
  isPublic?: boolean | null,
  infusionCalculation?: boolean | null,
  isPublicSignup?: boolean | null,
  config?: DepartmentConfigInput | null,
  _version?: number | null,
  departmentRequireSyncId?: string | null,
};

export type DeleteDepartmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateMedicShiftInput = {
  id?: string | null,
  shiftStart: string,
  shiftEnd: string,
  completedLogs: Array< string >,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  taggedUsers: Array< string >,
  subscribedDevices: Array< string >,
  invitedUsers: Array< InviteInput >,
  ownerID: string,
  departmentID: string,
  interactionID?: string | null,
  metaData?: string | null,
  _version?: number | null,
};

export type InviteInput = {
  itemID: string,
  expiration: string,
  sentTime: string,
  title?: string | null,
  description?: string | null,
};

export type ModelMedicShiftConditionInput = {
  shiftStart?: ModelStringInput | null,
  shiftEnd?: ModelStringInput | null,
  completedLogs?: ModelIDInput | null,
  taggedAmbulance?: ModelIDInput | null,
  taggedOneWeight?: ModelIDInput | null,
  taggedUsers?: ModelIDInput | null,
  subscribedDevices?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  interactionID?: ModelIDInput | null,
  metaData?: ModelStringInput | null,
  and?: Array< ModelMedicShiftConditionInput | null > | null,
  or?: Array< ModelMedicShiftConditionInput | null > | null,
  not?: ModelMedicShiftConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MedicShift = {
  __typename: "MedicShift",
  id: string,
  shiftStart: string,
  shiftEnd: string,
  completedLogs: Array< string >,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  taggedUsers: Array< string >,
  subscribedDevices: Array< string >,
  invitedUsers:  Array<Invite >,
  ownerID: string,
  departmentID: string,
  interactionID?: string | null,
  metaData?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Invite = {
  __typename: "Invite",
  itemID: string,
  expiration: string,
  sentTime: string,
  title?: string | null,
  description?: string | null,
};

export type UpdateMedicShiftInput = {
  id: string,
  shiftStart?: string | null,
  shiftEnd?: string | null,
  completedLogs?: Array< string > | null,
  taggedAmbulance?: string | null,
  taggedOneWeight?: string | null,
  taggedUsers?: Array< string > | null,
  subscribedDevices?: Array< string > | null,
  invitedUsers?: Array< InviteInput > | null,
  ownerID?: string | null,
  departmentID?: string | null,
  interactionID?: string | null,
  metaData?: string | null,
  _version?: number | null,
};

export type DeleteMedicShiftInput = {
  id: string,
  _version?: number | null,
};

export type CreatePatientInteractionInput = {
  id?: string | null,
  isLogActive: boolean,
  actualWeight?: number | null,
  ageValue?: number | null,
  startTime: string,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications: Array< LogMedicationInput >,
  equipments: Array< LogEquipmentInput >,
  electricals: Array< LogElectricalInput >,
  infusions: Array< LogDripInput >,
  forms: Array< LogFormInput >,
  subtractedItems: Array< LogWeightObjectInput >,
  taggedUsers: Array< string >,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  incidentNumber?: string | null,
  actualTime?: string | null,
  estimatedTime?: string | null,
  patientReleased?: string | null,
  ageTimestamp?: string | null,
  departmentID: string,
  subscribedUsers: Array< string >,
  subscribedDevices: Array< string >,
  events: Array< LogEventInput >,
  shifts: Array< string >,
  _version?: number | null,
};

export type LogMedicationInput = {
  medicationID: string,
  amntSolid: string,
  amntLiquid: string,
  timestamp: string,
  verified: boolean,
  name: string,
  rxNormCode?: string | null,
  concentration: string,
  isActualWeight: boolean,
  adminType: string,
  route?: MedicationRouteInput | null,
  weight: number,
  protocolID?: string | null,
};

export type MedicationRouteInput = {
  route: string,
  code: string,
};

export type LogEquipmentInput = {
  equipmentID: string,
  amount: string,
  name: string,
  snomedCode?: string | null,
  timestamp: string,
  isActualWeight: boolean,
  verified: boolean,
  weight: number,
  successful: boolean,
  protocolID?: string | null,
};

export type LogElectricalInput = {
  electricalID: string,
  name: string,
  snomedCode?: string | null,
  amount: string,
  timestamp: string,
  isActualWeight: boolean,
  verified: boolean,
  weight: number,
  protocolID?: string | null,
};

export type LogDripInput = {
  dripID: string,
  amnt: string,
  amnt2?: string | null,
  timestamp: string,
  verified: boolean,
  name: string,
  rxNormCode?: string | null,
  concentration: string,
  adminType: string,
  route?: MedicationRouteInput | null,
  weight: number,
  protocolID?: string | null,
  amntUnit: string,
  amntUnit2?: string | null,
  isActualWeight?: boolean | null,
  gttsDropFactor?: number | null,
  gttsAmount?: number | null,
  gttsUnit?: string | null,
};

export type LogFormInput = {
  formID?: string | null,
  name?: string | null,
  completed: string,
  startedTime: string,
  protocolID?: string | null,
};

export type LogWeightObjectInput = {
  weightObjectID?: string | null,
  name: string,
  weight: number,
  added: string,
};

export type LogEventInput = {
  color: string,
  title: string,
  description: string,
  timestamp: string,
  icon?: string | null,
};

export type ModelPatientInteractionConditionInput = {
  isLogActive?: ModelBooleanInput | null,
  actualWeight?: ModelFloatInput | null,
  ageValue?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  estimatedWeight?: ModelFloatInput | null,
  taggedUsers?: ModelIDInput | null,
  taggedAmbulance?: ModelIDInput | null,
  taggedOneWeight?: ModelIDInput | null,
  incidentNumber?: ModelStringInput | null,
  actualTime?: ModelStringInput | null,
  estimatedTime?: ModelStringInput | null,
  patientReleased?: ModelStringInput | null,
  ageTimestamp?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  subscribedUsers?: ModelIDInput | null,
  subscribedDevices?: ModelStringInput | null,
  shifts?: ModelIDInput | null,
  and?: Array< ModelPatientInteractionConditionInput | null > | null,
  or?: Array< ModelPatientInteractionConditionInput | null > | null,
  not?: ModelPatientInteractionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type PatientInteraction = {
  __typename: "PatientInteraction",
  id: string,
  isLogActive: boolean,
  actualWeight?: number | null,
  ageValue?: number | null,
  startTime: string,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications:  Array<LogMedication >,
  equipments:  Array<LogEquipment >,
  electricals:  Array<LogElectrical >,
  infusions:  Array<LogDrip >,
  forms:  Array<LogForm >,
  subtractedItems:  Array<LogWeightObject >,
  taggedUsers: Array< string >,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  incidentNumber?: string | null,
  actualTime?: string | null,
  estimatedTime?: string | null,
  patientReleased?: string | null,
  ageTimestamp?: string | null,
  departmentID: string,
  subscribedUsers: Array< string >,
  subscribedDevices: Array< string >,
  events:  Array<LogEvent >,
  shifts: Array< string >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePatientInteractionInput = {
  id: string,
  isLogActive?: boolean | null,
  actualWeight?: number | null,
  ageValue?: number | null,
  startTime?: string | null,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications?: Array< LogMedicationInput > | null,
  equipments?: Array< LogEquipmentInput > | null,
  electricals?: Array< LogElectricalInput > | null,
  infusions?: Array< LogDripInput > | null,
  forms?: Array< LogFormInput > | null,
  subtractedItems?: Array< LogWeightObjectInput > | null,
  taggedUsers?: Array< string > | null,
  taggedAmbulance?: string | null,
  taggedOneWeight?: string | null,
  incidentNumber?: string | null,
  actualTime?: string | null,
  estimatedTime?: string | null,
  patientReleased?: string | null,
  ageTimestamp?: string | null,
  departmentID?: string | null,
  subscribedUsers?: Array< string > | null,
  subscribedDevices?: Array< string > | null,
  events?: Array< LogEventInput > | null,
  shifts?: Array< string > | null,
  _version?: number | null,
};

export type DeletePatientInteractionInput = {
  id: string,
  _version?: number | null,
};

export type CreateKeychainInput = {
  id?: string | null,
  name: string,
  hashedPin: string,
  saltPin: string,
  hashType: string,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: string | null,
  departmentID: string,
  _version?: number | null,
};

export type ModelKeychainConditionInput = {
  name?: ModelStringInput | null,
  hashedPin?: ModelStringInput | null,
  saltPin?: ModelStringInput | null,
  hashType?: ModelStringInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  metaData?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelKeychainConditionInput | null > | null,
  or?: Array< ModelKeychainConditionInput | null > | null,
  not?: ModelKeychainConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateKeychainInput = {
  id: string,
  name?: string | null,
  hashedPin?: string | null,
  saltPin?: string | null,
  hashType?: string | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: string | null,
  departmentID?: string | null,
  _version?: number | null,
};

export type DeleteKeychainInput = {
  id: string,
  _version?: number | null,
};

export type CreateContactInput = {
  id?: string | null,
  fullName: string,
  title?: string | null,
  number: string,
  note?: string | null,
  index: number,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  _version?: number | null,
};

export type ModelContactConditionInput = {
  fullName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  number?: ModelStringInput | null,
  note?: ModelStringInput | null,
  index?: ModelIntInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  and?: Array< ModelContactConditionInput | null > | null,
  or?: Array< ModelContactConditionInput | null > | null,
  not?: ModelContactConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateContactInput = {
  id: string,
  fullName?: string | null,
  title?: string | null,
  number?: string | null,
  note?: string | null,
  index?: number | null,
  departmentID?: string | null,
  pairedDepIDs?: Array< string > | null,
  _version?: number | null,
};

export type DeleteContactInput = {
  id: string,
  _version?: number | null,
};

export type CreateFormLogInput = {
  id?: string | null,
  responses?: Array< FormResponseInput > | null,
  inputformID: string,
  departmentID: string,
  userID: string,
  pairedDepID?: string | null,
  pairedUserIDs?: Array< string > | null,
  notes?: string | null,
  _version?: number | null,
};

export type FormResponseInput = {
  questionIndex: number,
  type: FormType,
  responses: Array< string >,
};

export type ModelFormLogConditionInput = {
  inputformID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  pairedDepID?: ModelIDInput | null,
  pairedUserIDs?: ModelIDInput | null,
  notes?: ModelStringInput | null,
  and?: Array< ModelFormLogConditionInput | null > | null,
  or?: Array< ModelFormLogConditionInput | null > | null,
  not?: ModelFormLogConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateFormLogInput = {
  id: string,
  responses?: Array< FormResponseInput > | null,
  inputformID?: string | null,
  departmentID?: string | null,
  userID?: string | null,
  pairedDepID?: string | null,
  pairedUserIDs?: Array< string > | null,
  notes?: string | null,
  _version?: number | null,
};

export type DeleteFormLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateInputFormInput = {
  id?: string | null,
  name: string,
  items: Array< FormQuestionInput >,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  _version?: number | null,
};

export type FormQuestionInput = {
  index: number,
  type: FormType,
  question: string,
  description?: string | null,
  answers?: Array< string > | null,
  correctAnswer?: Array< string > | null,
};

export type ModelInputFormConditionInput = {
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  and?: Array< ModelInputFormConditionInput | null > | null,
  or?: Array< ModelInputFormConditionInput | null > | null,
  not?: ModelInputFormConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateInputFormInput = {
  id: string,
  name?: string | null,
  items?: Array< FormQuestionInput > | null,
  departmentID?: string | null,
  pairedDepIDs?: Array< string > | null,
  _version?: number | null,
};

export type DeleteInputFormInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotificationInput = {
  id?: string | null,
  type: NotificationType,
  title: string,
  message?: string | null,
  timestamp: string,
  expirationTimestamp: string,
  deadlineTimestamp?: string | null,
  isReadIDs: Array< string >,
  isAckIDs: Array< string >,
  imageURLs?: Array< string > | null,
  videoURLs?: Array< string > | null,
  fileURLs?: Array< string > | null,
  taggedProtocols: Array< string >,
  questions: Array< QuestionInput >,
  formQuestions: Array< FormQuestionInput >,
  isPush: boolean,
  departmentID: string,
  pairedDepIDs?: Array< string > | null,
  createdBy: string,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type QuestionInput = {
  question: string,
  options: Array< string >,
  answer: string,
  taggedProtocol?: string | null,
};

export type ModelNotificationConditionInput = {
  type?: ModelNotificationTypeInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  timestamp?: ModelStringInput | null,
  expirationTimestamp?: ModelStringInput | null,
  deadlineTimestamp?: ModelStringInput | null,
  isReadIDs?: ModelStringInput | null,
  isAckIDs?: ModelStringInput | null,
  imageURLs?: ModelStringInput | null,
  videoURLs?: ModelStringInput | null,
  fileURLs?: ModelStringInput | null,
  taggedProtocols?: ModelStringInput | null,
  isPush?: ModelBooleanInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type UpdateNotificationInput = {
  id: string,
  type?: NotificationType | null,
  title?: string | null,
  message?: string | null,
  timestamp?: string | null,
  expirationTimestamp?: string | null,
  deadlineTimestamp?: string | null,
  isReadIDs?: Array< string > | null,
  isAckIDs?: Array< string > | null,
  imageURLs?: Array< string > | null,
  videoURLs?: Array< string > | null,
  fileURLs?: Array< string > | null,
  taggedProtocols?: Array< string > | null,
  questions?: Array< QuestionInput > | null,
  formQuestions?: Array< FormQuestionInput > | null,
  isPush?: boolean | null,
  departmentID?: string | null,
  pairedDepIDs?: Array< string > | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type DeleteNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateNotifyACKInput = {
  id?: string | null,
  ownerID: string,
  userID: string,
  status: ACKStatus,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelNotifyACKConditionInput = {
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotifyACKConditionInput | null > | null,
  or?: Array< ModelNotifyACKConditionInput | null > | null,
  not?: ModelNotifyACKConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelACKStatusInput = {
  eq?: ACKStatus | null,
  ne?: ACKStatus | null,
};

export type UpdateNotifyACKInput = {
  id: string,
  ownerID?: string | null,
  userID?: string | null,
  status?: ACKStatus | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteNotifyACKInput = {
  id: string,
  _version?: number | null,
};

export type CreateGroupInput = {
  id?: string | null,
  name: string,
  type: GroupType,
  departmentID: string,
  pairedDepIDs: Array< string >,
  pairedUserIDs: Array< string >,
  isAutoGen: boolean,
  _version?: number | null,
};

export type ModelGroupConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelGroupTypeInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  pairedUserIDs?: ModelIDInput | null,
  isAutoGen?: ModelBooleanInput | null,
  and?: Array< ModelGroupConditionInput | null > | null,
  or?: Array< ModelGroupConditionInput | null > | null,
  not?: ModelGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelGroupTypeInput = {
  eq?: GroupType | null,
  ne?: GroupType | null,
};

export type UpdateGroupInput = {
  id: string,
  name?: string | null,
  type?: GroupType | null,
  departmentID?: string | null,
  pairedDepIDs?: Array< string > | null,
  pairedUserIDs?: Array< string > | null,
  isAutoGen?: boolean | null,
  _version?: number | null,
};

export type DeleteGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateDripInput = {
  id?: string | null,
  name: string,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  concentration?: Array< MedicationConcentrationInput > | null,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  dripOptions: Array< MedicationProtocolInput >,
  departmentID: string,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type MedicationConcentrationInput = {
  firstAmnt: number,
  firstUnit: string,
  secAmnt?: number | null,
  secUnit?: string | null,
  color?: string | null,
};

export type MedicationProtocolInput = {
  protocolID: string,
  options: Array< MedicationRangeInput >,
};

export type MedicationRangeInput = {
  doseID?: string | null,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
};

export type ModelMetaDataInput = {
  parentID?: string | null,
  fullName: string,
  nickName?: string | null,
  brandNames?: Array< string > | null,
  maxDose?: string | null,
  minDose?: string | null,
  absMaxDose?: string | null,
  absMinDose?: string | null,
  releaseNotes?: Array< string > | null,
};

export type ModelDripConditionInput = {
  name?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  contraindication?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  route?: ModelStringInput | null,
  medClass?: ModelStringInput | null,
  action?: ModelStringInput | null,
  indication?: ModelStringInput | null,
  interaction?: ModelStringInput | null,
  onset?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelDripConditionInput | null > | null,
  or?: Array< ModelDripConditionInput | null > | null,
  not?: ModelDripConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelProgressStatusInput = {
  eq?: ProgressStatus | null,
  ne?: ProgressStatus | null,
};

export type UpdateDripInput = {
  id: string,
  name?: string | null,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  concentration?: Array< MedicationConcentrationInput > | null,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  dripOptions?: Array< MedicationProtocolInput > | null,
  departmentID?: string | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type DeleteDripInput = {
  id: string,
  _version?: number | null,
};

export type CreateInfusionDoseInput = {
  id?: string | null,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  dripID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelInfusionDoseConditionInput = {
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  route?: ModelStringInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  dripID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelInfusionDoseConditionInput | null > | null,
  or?: Array< ModelInfusionDoseConditionInput | null > | null,
  not?: ModelInfusionDoseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelPatientAgeGroupInput = {
  eq?: PatientAgeGroup | null,
  ne?: PatientAgeGroup | null,
};

export type UpdateInfusionDoseInput = {
  id: string,
  basis?: string | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID?: string | null,
  protocolID?: string | null,
  dripID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteInfusionDoseInput = {
  id: string,
  _version?: number | null,
};

export type CreateEquipmentInput = {
  id?: string | null,
  name: string,
  snomedCode?: string | null,
  optionItems: Array< EquipmentOptionInput >,
  departmentID: string,
  title?: string | null,
  note?: string | null,
  warning?: string | null,
  instruction?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type EquipmentOptionInput = {
  amnt: string,
  amntHigh?: string | null,
  rangeLow: number,
  rangeHigh?: number | null,
};

export type ModelEquipmentConditionInput = {
  name?: ModelStringInput | null,
  snomedCode?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelEquipmentConditionInput | null > | null,
  or?: Array< ModelEquipmentConditionInput | null > | null,
  not?: ModelEquipmentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateEquipmentInput = {
  id: string,
  name?: string | null,
  snomedCode?: string | null,
  optionItems?: Array< EquipmentOptionInput > | null,
  departmentID?: string | null,
  title?: string | null,
  note?: string | null,
  warning?: string | null,
  instruction?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type DeleteEquipmentInput = {
  id: string,
  _version?: number | null,
};

export type CreateMedicationInput = {
  id?: string | null,
  name: string,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  concentration: Array< MedicationConcentrationInput >,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  departmentID: string,
  protocolOptions: Array< MedicationProtocolInput >,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type ModelMedicationConditionInput = {
  name?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  contraindication?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  route?: ModelStringInput | null,
  medClass?: ModelStringInput | null,
  action?: ModelStringInput | null,
  indication?: ModelStringInput | null,
  interaction?: ModelStringInput | null,
  onset?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelMedicationConditionInput | null > | null,
  or?: Array< ModelMedicationConditionInput | null > | null,
  not?: ModelMedicationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateMedicationInput = {
  id: string,
  name?: string | null,
  rxNormCode?: string | null,
  contraindication?: string | null,
  note?: string | null,
  warning?: string | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  concentration?: Array< MedicationConcentrationInput > | null,
  medClass?: string | null,
  action?: string | null,
  indication?: string | null,
  interaction?: string | null,
  onset?: string | null,
  duration?: string | null,
  departmentID?: string | null,
  protocolOptions?: Array< MedicationProtocolInput > | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  maxDose?: string | null,
  minDose?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type DeleteMedicationInput = {
  id: string,
  _version?: number | null,
};

export type CreateMedicationDoseInput = {
  id?: string | null,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  route: Array< string >,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  medicationID: string,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelMedicationDoseConditionInput = {
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  route?: ModelStringInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  medicationID?: ModelIDInput | null,
  cprAssistID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMedicationDoseConditionInput | null > | null,
  or?: Array< ModelMedicationDoseConditionInput | null > | null,
  not?: ModelMedicationDoseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateMedicationDoseInput = {
  id: string,
  basis?: string | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  route?: Array< string > | null,
  nemsisRoutes?: Array< MedicationRouteInput > | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID?: string | null,
  protocolID?: string | null,
  medicationID?: string | null,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteMedicationDoseInput = {
  id: string,
  _version?: number | null,
};

export type CreateProtocolInput = {
  id?: string | null,
  name: string,
  pdfID: string,
  categoryID: string,
  pairedProtocols?: Array< string > | null,
  medicationIDs?: Array< string > | null,
  equipmentIDs?: Array< string > | null,
  electricalIDs?: Array< string > | null,
  dripIDs?: Array< string > | null,
  formIDs?: Array< string > | null,
  index: number,
  nickname: string,
  pairedDepIDs?: Array< string > | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pdfVersion?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  privatePin?: string | null,
  keychainID?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  isPublic: boolean,
  isRestrictive: boolean,
  departmentID: string,
  _version?: number | null,
};

export type ModelProtocolConditionInput = {
  name?: ModelStringInput | null,
  pdfID?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  pairedProtocols?: ModelIDInput | null,
  medicationIDs?: ModelIDInput | null,
  equipmentIDs?: ModelIDInput | null,
  electricalIDs?: ModelIDInput | null,
  dripIDs?: ModelIDInput | null,
  formIDs?: ModelIDInput | null,
  index?: ModelIntInput | null,
  nickname?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  pdfVersion?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  privatePin?: ModelStringInput | null,
  keychainID?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  isRestrictive?: ModelBooleanInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelProtocolConditionInput | null > | null,
  or?: Array< ModelProtocolConditionInput | null > | null,
  not?: ModelProtocolConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateProtocolInput = {
  id: string,
  name?: string | null,
  pdfID?: string | null,
  categoryID?: string | null,
  pairedProtocols?: Array< string > | null,
  medicationIDs?: Array< string > | null,
  equipmentIDs?: Array< string > | null,
  electricalIDs?: Array< string > | null,
  dripIDs?: Array< string > | null,
  formIDs?: Array< string > | null,
  index?: number | null,
  nickname?: string | null,
  pairedDepIDs?: Array< string > | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pdfVersion?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  privatePin?: string | null,
  keychainID?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  isPublic?: boolean | null,
  isRestrictive?: boolean | null,
  departmentID?: string | null,
  _version?: number | null,
};

export type DeleteProtocolInput = {
  id: string,
  _version?: number | null,
};

export type CreateAmbulanceInput = {
  id?: string | null,
  name: string,
  departmentID: string,
  _version?: number | null,
};

export type ModelAmbulanceConditionInput = {
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelAmbulanceConditionInput | null > | null,
  or?: Array< ModelAmbulanceConditionInput | null > | null,
  not?: ModelAmbulanceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAmbulanceInput = {
  id: string,
  name?: string | null,
  departmentID?: string | null,
  _version?: number | null,
};

export type DeleteAmbulanceInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  type: UserType,
  cognitoID: string,
  departmentID: string,
  depAdmins?: Array< string > | null,
  hashedPin: string,
  saltPin: string,
  pairedDepIDs?: Array< string > | null,
  oneDoseVersion?: string | null,
  lastActivity?: string | null,
  notificationTokens?: Array< string > | null,
  status?: UserStatus | null,
  _version?: number | null,
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  cognitoID?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  depAdmins?: ModelIDInput | null,
  hashedPin?: ModelStringInput | null,
  saltPin?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  oneDoseVersion?: ModelStringInput | null,
  lastActivity?: ModelStringInput | null,
  notificationTokens?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelUserTypeInput = {
  eq?: UserType | null,
  ne?: UserType | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  type?: UserType | null,
  cognitoID?: string | null,
  departmentID?: string | null,
  depAdmins?: Array< string > | null,
  hashedPin?: string | null,
  saltPin?: string | null,
  pairedDepIDs?: Array< string > | null,
  oneDoseVersion?: string | null,
  lastActivity?: string | null,
  notificationTokens?: Array< string > | null,
  status?: UserStatus | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
  departmentID: string,
  index: number,
  pairedDepIDs?: Array< string > | null,
  parentCategoryID?: string | null,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  isPublic: boolean,
  isRestrictive: boolean,
  overrideID?: string | null,
  keychainID?: string | null,
  _version?: number | null,
  departmentCategoriesId?: string | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  index?: ModelIntInput | null,
  pairedDepIDs?: ModelStringInput | null,
  parentCategoryID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  isRestrictive?: ModelBooleanInput | null,
  overrideID?: ModelIDInput | null,
  keychainID?: ModelIDInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  departmentCategoriesId?: ModelIDInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
  departmentID?: string | null,
  index?: number | null,
  pairedDepIDs?: Array< string > | null,
  parentCategoryID?: string | null,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  isPublic?: boolean | null,
  isRestrictive?: boolean | null,
  overrideID?: string | null,
  keychainID?: string | null,
  _version?: number | null,
  departmentCategoriesId?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateElectricalInput = {
  id?: string | null,
  title: string,
  options: Array< ElectricalOptionInput >,
  departmentID: string,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  _version?: number | null,
};

export type ElectricalOptionInput = {
  title: string,
  ranges: Array< ElectricalRangeInput >,
};

export type ElectricalRangeInput = {
  basis: string,
  rangeLow: number,
  rangeHigh: number,
  calcMax?: string | null,
  fixedMax?: string | null,
};

export type ModelElectricalConditionInput = {
  title?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  and?: Array< ModelElectricalConditionInput | null > | null,
  or?: Array< ModelElectricalConditionInput | null > | null,
  not?: ModelElectricalConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateElectricalInput = {
  id: string,
  title?: string | null,
  options?: Array< ElectricalOptionInput > | null,
  departmentID?: string | null,
  activeID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  _version?: number | null,
};

export type DeleteElectricalInput = {
  id: string,
  _version?: number | null,
};

export type CreateElectricalShockInput = {
  id?: string | null,
  title: string,
  rxNormCode?: string | null,
  options: Array< ElectricalShockOptionInput >,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  rangeHigh?: number | null,
  rangeLow?: number | null,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type ElectricalShockOptionInput = {
  protocolID: string,
  ranges: Array< ElectricalShockRangeInput >,
};

export type ElectricalShockRangeInput = {
  doseID?: string | null,
  index: number,
  basis: string,
  rangeLow: number,
  rangeHigh: number,
  calcMax?: string | null,
  fixedMax?: string | null,
  title: string,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
};

export type ModelElectricalShockConditionInput = {
  title?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  rangeHigh?: ModelIntInput | null,
  rangeLow?: ModelIntInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelElectricalShockConditionInput | null > | null,
  or?: Array< ModelElectricalShockConditionInput | null > | null,
  not?: ModelElectricalShockConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateElectricalShockInput = {
  id: string,
  title?: string | null,
  rxNormCode?: string | null,
  options?: Array< ElectricalShockOptionInput > | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  rangeHigh?: number | null,
  rangeLow?: number | null,
  departmentID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  metaData?: ModelMetaDataInput | null,
  _version?: number | null,
};

export type DeleteElectricalShockInput = {
  id: string,
  _version?: number | null,
};

export type CreateElectricalDoseInput = {
  id?: string | null,
  basis: string,
  rangeLow: number,
  rangeHigh?: number | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index: number,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID: string,
  protocolID?: string | null,
  electricalID: string,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelElectricalDoseConditionInput = {
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  electricalID?: ModelIDInput | null,
  cprAssistID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelElectricalDoseConditionInput | null > | null,
  or?: Array< ModelElectricalDoseConditionInput | null > | null,
  not?: ModelElectricalDoseConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateElectricalDoseInput = {
  id: string,
  basis?: string | null,
  rangeLow?: number | null,
  rangeHigh?: number | null,
  title?: string | null,
  warning?: string | null,
  instruction?: string | null,
  note?: string | null,
  maxDose?: string | null,
  minDose?: string | null,
  maxTotalDose?: string | null,
  calcMax?: string | null,
  calcMin?: string | null,
  index?: number | null,
  ageLow?: number | null,
  ageHigh?: number | null,
  ageGroup?: PatientAgeGroup | null,
  repeatTime?: string | null,
  incrementAmnt?: number | null,
  departmentID?: string | null,
  protocolID?: string | null,
  electricalID?: string | null,
  cprAssistID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteElectricalDoseInput = {
  id: string,
  _version?: number | null,
};

export type CreateDeveloperNotificationInput = {
  id?: string | null,
  title: string,
  message?: string | null,
  targetVersion?: string | null,
  additionalData?: string | null,
  type: DevNotifyType,
  _version?: number | null,
};

export enum DevNotifyType {
  FORCE_UPDATE = "FORCE_UPDATE",
  GENERAL_UPDATE = "GENERAL_UPDATE",
}


export type ModelDeveloperNotificationConditionInput = {
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  targetVersion?: ModelStringInput | null,
  additionalData?: ModelStringInput | null,
  type?: ModelDevNotifyTypeInput | null,
  and?: Array< ModelDeveloperNotificationConditionInput | null > | null,
  or?: Array< ModelDeveloperNotificationConditionInput | null > | null,
  not?: ModelDeveloperNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelDevNotifyTypeInput = {
  eq?: DevNotifyType | null,
  ne?: DevNotifyType | null,
};

export type DeveloperNotification = {
  __typename: "DeveloperNotification",
  id: string,
  title: string,
  message?: string | null,
  targetVersion?: string | null,
  additionalData?: string | null,
  type: DevNotifyType,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDeveloperNotificationInput = {
  id: string,
  title?: string | null,
  message?: string | null,
  targetVersion?: string | null,
  additionalData?: string | null,
  type?: DevNotifyType | null,
  _version?: number | null,
};

export type DeleteDeveloperNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateVitalsInput = {
  id?: string | null,
  title: string,
  options: Array< VitalOptionInput >,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type VitalOptionInput = {
  amntLow: string,
  amntHigh: string,
  rangeLow: number,
  rangeHigh: number,
};

export type ModelVitalsConditionInput = {
  title?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelVitalsConditionInput | null > | null,
  or?: Array< ModelVitalsConditionInput | null > | null,
  not?: ModelVitalsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateVitalsInput = {
  id: string,
  title?: string | null,
  options?: Array< VitalOptionInput > | null,
  departmentID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type DeleteVitalsInput = {
  id: string,
  _version?: number | null,
};

export type CreateOneWeightInput = {
  id?: string | null,
  name: string,
  serialNumber: string,
  projectNumber: string,
  lastCalibration: string,
  type?: PWSType | null,
  departmentID: string,
  _version?: number | null,
  departmentOneWeightsId?: string | null,
};

export type ModelOneWeightConditionInput = {
  name?: ModelStringInput | null,
  serialNumber?: ModelStringInput | null,
  projectNumber?: ModelStringInput | null,
  lastCalibration?: ModelStringInput | null,
  type?: ModelPWSTypeInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelOneWeightConditionInput | null > | null,
  or?: Array< ModelOneWeightConditionInput | null > | null,
  not?: ModelOneWeightConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  departmentOneWeightsId?: ModelIDInput | null,
};

export type ModelPWSTypeInput = {
  eq?: PWSType | null,
  ne?: PWSType | null,
};

export type UpdateOneWeightInput = {
  id: string,
  name?: string | null,
  serialNumber?: string | null,
  projectNumber?: string | null,
  lastCalibration?: string | null,
  type?: PWSType | null,
  departmentID?: string | null,
  _version?: number | null,
  departmentOneWeightsId?: string | null,
};

export type DeleteOneWeightInput = {
  id: string,
  _version?: number | null,
};

export type CreateFormInput = {
  id?: string | null,
  name: string,
  departmentID: string,
  items: Array< FormGroupInput >,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type FormGroupInput = {
  title: string,
  options: Array< FormOptionInput >,
  isUrgent: boolean,
  isDouble: boolean,
};

export type FormOptionInput = {
  firstItem: string,
  secItem?: string | null,
};

export type ModelFormConditionInput = {
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelFormConditionInput | null > | null,
  or?: Array< ModelFormConditionInput | null > | null,
  not?: ModelFormConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateFormInput = {
  id: string,
  name?: string | null,
  departmentID?: string | null,
  items?: Array< FormGroupInput > | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  _version?: number | null,
};

export type DeleteFormInput = {
  id: string,
  _version?: number | null,
};

export type CreateLogInput = {
  id?: string | null,
  actualWeight?: number | null,
  ageValue?: number | null,
  protocolID?: Array< string > | null,
  departmentID: string,
  oneweightID?: string | null,
  ambulanceID?: string | null,
  incidentNumber?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications: Array< LogMedicationInput >,
  equipments: Array< LogEquipmentInput >,
  electricals: Array< LogElectricalInput >,
  cpr?: LogCPRInput | null,
  patientSaved?: string | null,
  patientReleased?: string | null,
  arrivedAtHospital?: string | null,
  ageTimestamp?: string | null,
  forms: Array< LogFormInput >,
  subtractedItems?: Array< LogWeightObjectInput > | null,
  userIDs: Array< string >,
  infusions: Array< LogDripInput >,
  estimatedSaved?: string | null,
  tags: Array< string >,
  shifts?: Array< LogMedicShiftInput > | null,
  _version?: number | null,
};

export type LogCPRInput = {
  cprID: string,
  startTime: string,
  endTime: string,
  outcome?: PatientOutcomeType | null,
};

export type LogMedicShiftInput = {
  shiftStart: string,
  shiftEnd: string,
  completedLogs?: Array< string > | null,
  taggedAmbulance: string,
  taggedOneWeight?: string | null,
  taggedUsers: Array< string >,
  ownerID: string,
  departmentID: string,
  metaData?: string | null,
};

export type ModelLogConditionInput = {
  actualWeight?: ModelFloatInput | null,
  ageValue?: ModelIntInput | null,
  protocolID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  oneweightID?: ModelIDInput | null,
  ambulanceID?: ModelIDInput | null,
  incidentNumber?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  estimatedWeight?: ModelFloatInput | null,
  patientSaved?: ModelStringInput | null,
  patientReleased?: ModelStringInput | null,
  arrivedAtHospital?: ModelStringInput | null,
  ageTimestamp?: ModelStringInput | null,
  userIDs?: ModelIDInput | null,
  estimatedSaved?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  and?: Array< ModelLogConditionInput | null > | null,
  or?: Array< ModelLogConditionInput | null > | null,
  not?: ModelLogConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateLogInput = {
  id: string,
  actualWeight?: number | null,
  ageValue?: number | null,
  protocolID?: Array< string > | null,
  departmentID?: string | null,
  oneweightID?: string | null,
  ambulanceID?: string | null,
  incidentNumber?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  estimatedWeight?: number | null,
  medications?: Array< LogMedicationInput > | null,
  equipments?: Array< LogEquipmentInput > | null,
  electricals?: Array< LogElectricalInput > | null,
  cpr?: LogCPRInput | null,
  patientSaved?: string | null,
  patientReleased?: string | null,
  arrivedAtHospital?: string | null,
  ageTimestamp?: string | null,
  forms?: Array< LogFormInput > | null,
  subtractedItems?: Array< LogWeightObjectInput > | null,
  userIDs?: Array< string > | null,
  infusions?: Array< LogDripInput > | null,
  estimatedSaved?: string | null,
  tags?: Array< string > | null,
  shifts?: Array< LogMedicShiftInput > | null,
  _version?: number | null,
};

export type DeleteLogInput = {
  id: string,
  _version?: number | null,
};

export type CreateRequireSyncInput = {
  id?: string | null,
  sync: boolean,
  _version?: number | null,
};

export type ModelRequireSyncConditionInput = {
  sync?: ModelBooleanInput | null,
  and?: Array< ModelRequireSyncConditionInput | null > | null,
  or?: Array< ModelRequireSyncConditionInput | null > | null,
  not?: ModelRequireSyncConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateRequireSyncInput = {
  id: string,
  sync?: boolean | null,
  _version?: number | null,
};

export type DeleteRequireSyncInput = {
  id: string,
  _version?: number | null,
};

export type CreateHardwareVersionInput = {
  id?: string | null,
  version: string,
  name: string,
  description: string,
  _version?: number | null,
};

export type ModelHardwareVersionConditionInput = {
  version?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelHardwareVersionConditionInput | null > | null,
  or?: Array< ModelHardwareVersionConditionInput | null > | null,
  not?: ModelHardwareVersionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type HardwareVersion = {
  __typename: "HardwareVersion",
  id: string,
  version: string,
  name: string,
  description: string,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateHardwareVersionInput = {
  id: string,
  version?: string | null,
  name?: string | null,
  description?: string | null,
  _version?: number | null,
};

export type DeleteHardwareVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateFirmwareVersionInput = {
  id?: string | null,
  name: string,
  version: string,
  fileURL: string,
  description: string,
  cover_image?: string | null,
  compatibleHW: Array< string >,
  _version?: number | null,
};

export type ModelFirmwareVersionConditionInput = {
  name?: ModelStringInput | null,
  version?: ModelStringInput | null,
  fileURL?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cover_image?: ModelStringInput | null,
  compatibleHW?: ModelStringInput | null,
  and?: Array< ModelFirmwareVersionConditionInput | null > | null,
  or?: Array< ModelFirmwareVersionConditionInput | null > | null,
  not?: ModelFirmwareVersionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type FirmwareVersion = {
  __typename: "FirmwareVersion",
  id: string,
  name: string,
  version: string,
  fileURL: string,
  description: string,
  cover_image?: string | null,
  compatibleHW: Array< string >,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFirmwareVersionInput = {
  id: string,
  name?: string | null,
  version?: string | null,
  fileURL?: string | null,
  description?: string | null,
  cover_image?: string | null,
  compatibleHW?: Array< string > | null,
  _version?: number | null,
};

export type DeleteFirmwareVersionInput = {
  id: string,
  _version?: number | null,
};

export type CreateWorkbookInput = {
  id?: string | null,
  name: string,
  workbookID: string,
  fileSize: number,
  aiPdfParserResults?: string | null,
  activeID?: string | null,
  createdBy: string,
  modifiedBy?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID: string,
  _version?: number | null,
};

export type ModelWorkbookConditionInput = {
  name?: ModelStringInput | null,
  workbookID?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  aiPdfParserResults?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelWorkbookConditionInput | null > | null,
  or?: Array< ModelWorkbookConditionInput | null > | null,
  not?: ModelWorkbookConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateWorkbookInput = {
  id: string,
  name?: string | null,
  workbookID?: string | null,
  fileSize?: number | null,
  aiPdfParserResults?: string | null,
  activeID?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID?: string | null,
  _version?: number | null,
};

export type DeleteWorkbookInput = {
  id: string,
  _version?: number | null,
};

export type CreateWeightObjectInput = {
  id?: string | null,
  name: string,
  weight: number,
  createdBy: string,
  modifiedBy?: string | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID: string,
  _version?: number | null,
};

export type ModelWeightObjectConditionInput = {
  name?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  version?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  and?: Array< ModelWeightObjectConditionInput | null > | null,
  or?: Array< ModelWeightObjectConditionInput | null > | null,
  not?: ModelWeightObjectConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateWeightObjectInput = {
  id: string,
  name?: string | null,
  weight?: number | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  version?: string | null,
  pairedDepIDs?: Array< string > | null,
  departmentID?: string | null,
  _version?: number | null,
};

export type DeleteWeightObjectInput = {
  id: string,
  _version?: number | null,
};

export type CreateConcentrationInput = {
  id?: string | null,
  firstAmnt: number,
  firstUnit: string,
  secAmnt?: number | null,
  secUnit?: string | null,
  color?: string | null,
  departmentID: string,
  medicationID?: string | null,
  dripID?: string | null,
  status?: ProgressStatus | null,
  _version?: number | null,
};

export type ModelConcentrationConditionInput = {
  firstAmnt?: ModelFloatInput | null,
  firstUnit?: ModelStringInput | null,
  secAmnt?: ModelFloatInput | null,
  secUnit?: ModelStringInput | null,
  color?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  medicationID?: ModelIDInput | null,
  dripID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  and?: Array< ModelConcentrationConditionInput | null > | null,
  or?: Array< ModelConcentrationConditionInput | null > | null,
  not?: ModelConcentrationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateConcentrationInput = {
  id: string,
  firstAmnt?: number | null,
  firstUnit?: string | null,
  secAmnt?: number | null,
  secUnit?: string | null,
  color?: string | null,
  departmentID?: string | null,
  medicationID?: string | null,
  dripID?: string | null,
  status?: ProgressStatus | null,
  _version?: number | null,
};

export type DeleteConcentrationInput = {
  id: string,
  _version?: number | null,
};

export type CreateCPRAssistInput = {
  id?: string | null,
  protocolIDs: Array< string >,
  epiIDs: Array< string >,
  defibIDs: Array< string >,
  equipmentID?: string | null,
  departmentID: string,
  activeID?: string | null,
  overrideID?: string | null,
  status: ProgressStatus,
  version: string,
  createdBy: string,
  modifiedBy?: string | null,
  cprMetaData: CPRMetaDataInput,
  _version?: number | null,
};

export type CPRMetaDataInput = {
  defaultMode: string,
  epiOrangeSec: number,
  epiRedSec: number,
  compressionRate: number,
  ventRatePerMin: number,
  ventilateSoundDelay: number,
};

export type ModelCPRAssistConditionInput = {
  protocolIDs?: ModelIDInput | null,
  epiIDs?: ModelIDInput | null,
  defibIDs?: ModelIDInput | null,
  equipmentID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  and?: Array< ModelCPRAssistConditionInput | null > | null,
  or?: Array< ModelCPRAssistConditionInput | null > | null,
  not?: ModelCPRAssistConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateCPRAssistInput = {
  id: string,
  protocolIDs?: Array< string > | null,
  epiIDs?: Array< string > | null,
  defibIDs?: Array< string > | null,
  equipmentID?: string | null,
  departmentID?: string | null,
  activeID?: string | null,
  overrideID?: string | null,
  status?: ProgressStatus | null,
  version?: string | null,
  createdBy?: string | null,
  modifiedBy?: string | null,
  cprMetaData?: CPRMetaDataInput | null,
  _version?: number | null,
};

export type DeleteCPRAssistInput = {
  id: string,
  _version?: number | null,
};

export type CreateReviewalInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  reviewers: Array< string >,
  status: ACKStatus,
  events: Array< LogEventInput >,
  createdAt?: string | null,
  endedAt?: string | null,
  departmentID: string,
  userID: string,
  _version?: number | null,
};

export type ModelReviewalConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  reviewers?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelReviewalConditionInput | null > | null,
  or?: Array< ModelReviewalConditionInput | null > | null,
  not?: ModelReviewalConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateReviewalInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  reviewers?: Array< string > | null,
  status?: ACKStatus | null,
  events?: Array< LogEventInput > | null,
  createdAt?: string | null,
  endedAt?: string | null,
  departmentID?: string | null,
  userID?: string | null,
  _version?: number | null,
};

export type DeleteReviewalInput = {
  id: string,
  _version?: number | null,
};

export type CreateDraftGroupInput = {
  id?: string | null,
  reviewalID: string,
  departmentID: string,
  ownerID: string,
  title: string,
  description?: string | null,
  createdAt?: string | null,
  isClosed: boolean,
  _version?: number | null,
};

export type ModelDraftGroupConditionInput = {
  reviewalID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  isClosed?: ModelBooleanInput | null,
  and?: Array< ModelDraftGroupConditionInput | null > | null,
  or?: Array< ModelDraftGroupConditionInput | null > | null,
  not?: ModelDraftGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateDraftGroupInput = {
  id: string,
  reviewalID?: string | null,
  departmentID?: string | null,
  ownerID?: string | null,
  title?: string | null,
  description?: string | null,
  createdAt?: string | null,
  isClosed?: boolean | null,
  _version?: number | null,
};

export type DeleteDraftGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateDraftChangeInput = {
  id?: string | null,
  draftGroupID: string,
  departmentID: string,
  changeID: string,
  previousID?: string | null,
  changeType: string,
  isClosed: boolean,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelDraftChangeConditionInput = {
  draftGroupID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  changeID?: ModelIDInput | null,
  previousID?: ModelIDInput | null,
  changeType?: ModelStringInput | null,
  isClosed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelDraftChangeConditionInput | null > | null,
  or?: Array< ModelDraftChangeConditionInput | null > | null,
  not?: ModelDraftChangeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateDraftChangeInput = {
  id: string,
  draftGroupID?: string | null,
  departmentID?: string | null,
  changeID?: string | null,
  previousID?: string | null,
  changeType?: string | null,
  isClosed?: boolean | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteDraftChangeInput = {
  id: string,
  _version?: number | null,
};

export type CreateAcknowledgeInput = {
  id?: string | null,
  ownerID: string,
  userID: string,
  status: ACKStatus,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelAcknowledgeConditionInput = {
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelAcknowledgeConditionInput | null > | null,
  or?: Array< ModelAcknowledgeConditionInput | null > | null,
  not?: ModelAcknowledgeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateAcknowledgeInput = {
  id: string,
  ownerID?: string | null,
  userID?: string | null,
  status?: ACKStatus | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteAcknowledgeInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserCommentInput = {
  id?: string | null,
  ownerID: string,
  userID: string,
  acknowledgeID?: string | null,
  message: string,
  relatedItems?: Array< string > | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelUserCommentConditionInput = {
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  acknowledgeID?: ModelIDInput | null,
  message?: ModelStringInput | null,
  relatedItems?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelUserCommentConditionInput | null > | null,
  or?: Array< ModelUserCommentConditionInput | null > | null,
  not?: ModelUserCommentConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateUserCommentInput = {
  id: string,
  ownerID?: string | null,
  userID?: string | null,
  acknowledgeID?: string | null,
  message?: string | null,
  relatedItems?: Array< string > | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteUserCommentInput = {
  id: string,
  _version?: number | null,
};

export type CreateGroupNotificationInput = {
  id?: string | null,
  notificationId: string,
  groupId: string,
  _version?: number | null,
};

export type ModelGroupNotificationConditionInput = {
  notificationId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  and?: Array< ModelGroupNotificationConditionInput | null > | null,
  or?: Array< ModelGroupNotificationConditionInput | null > | null,
  not?: ModelGroupNotificationConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateGroupNotificationInput = {
  id: string,
  notificationId?: string | null,
  groupId?: string | null,
  _version?: number | null,
};

export type DeleteGroupNotificationInput = {
  id: string,
  _version?: number | null,
};

export type CreateLogUserInput = {
  id?: string | null,
  userId: string,
  logId: string,
  _version?: number | null,
};

export type ModelLogUserConditionInput = {
  userId?: ModelIDInput | null,
  logId?: ModelIDInput | null,
  and?: Array< ModelLogUserConditionInput | null > | null,
  or?: Array< ModelLogUserConditionInput | null > | null,
  not?: ModelLogUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateLogUserInput = {
  id: string,
  userId?: string | null,
  logId?: string | null,
  _version?: number | null,
};

export type DeleteLogUserInput = {
  id: string,
  _version?: number | null,
};

export type ModelDepartmentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  location?: ModelStringInput | null,
  protocolVersions?: ModelStringInput | null,
  shiftTypes?: ModelStringInput | null,
  cognitoID?: ModelStringInput | null,
  logoID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  neonateCutoff?: ModelFloatInput | null,
  pediatricCutoff?: ModelFloatInput | null,
  calculators?: ModelCalculatorTypeListInput | null,
  softwarePlan?: ModelSoftwareTypeInput | null,
  subDepIDs?: ModelIDInput | null,
  parentDepID?: ModelIDInput | null,
  activeStatus?: ModelBooleanInput | null,
  agencyNumEMS?: ModelStringInput | null,
  stateIdEMS?: ModelStringInput | null,
  gnisCodeEMS?: ModelStringInput | null,
  uniqueCode?: ModelStringInput | null,
  hashedPin?: ModelStringInput | null,
  saltedPin?: ModelStringInput | null,
  keychainID?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  infusionCalculation?: ModelBooleanInput | null,
  isPublicSignup?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDepartmentFilterInput | null > | null,
  or?: Array< ModelDepartmentFilterInput | null > | null,
  not?: ModelDepartmentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  departmentRequireSyncId?: ModelIDInput | null,
};

export type ModelDepartmentConnection = {
  __typename: "ModelDepartmentConnection",
  items:  Array<Department | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelMedicShiftFilterInput = {
  id?: ModelIDInput | null,
  shiftStart?: ModelStringInput | null,
  shiftEnd?: ModelStringInput | null,
  completedLogs?: ModelIDInput | null,
  taggedAmbulance?: ModelIDInput | null,
  taggedOneWeight?: ModelIDInput | null,
  taggedUsers?: ModelIDInput | null,
  subscribedDevices?: ModelStringInput | null,
  ownerID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  interactionID?: ModelIDInput | null,
  metaData?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMedicShiftFilterInput | null > | null,
  or?: Array< ModelMedicShiftFilterInput | null > | null,
  not?: ModelMedicShiftFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMedicShiftConnection = {
  __typename: "ModelMedicShiftConnection",
  items:  Array<MedicShift | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelPatientInteractionFilterInput = {
  id?: ModelIDInput | null,
  isLogActive?: ModelBooleanInput | null,
  actualWeight?: ModelFloatInput | null,
  ageValue?: ModelIntInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  estimatedWeight?: ModelFloatInput | null,
  taggedUsers?: ModelIDInput | null,
  taggedAmbulance?: ModelIDInput | null,
  taggedOneWeight?: ModelIDInput | null,
  incidentNumber?: ModelStringInput | null,
  actualTime?: ModelStringInput | null,
  estimatedTime?: ModelStringInput | null,
  patientReleased?: ModelStringInput | null,
  ageTimestamp?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  subscribedUsers?: ModelIDInput | null,
  subscribedDevices?: ModelStringInput | null,
  shifts?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPatientInteractionFilterInput | null > | null,
  or?: Array< ModelPatientInteractionFilterInput | null > | null,
  not?: ModelPatientInteractionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPatientInteractionConnection = {
  __typename: "ModelPatientInteractionConnection",
  items:  Array<PatientInteraction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelKeychainFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  hashedPin?: ModelStringInput | null,
  saltPin?: ModelStringInput | null,
  hashType?: ModelStringInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  metaData?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelKeychainFilterInput | null > | null,
  or?: Array< ModelKeychainFilterInput | null > | null,
  not?: ModelKeychainFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelContactFilterInput = {
  id?: ModelIDInput | null,
  fullName?: ModelStringInput | null,
  title?: ModelStringInput | null,
  number?: ModelStringInput | null,
  note?: ModelStringInput | null,
  index?: ModelIntInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelContactFilterInput | null > | null,
  or?: Array< ModelContactFilterInput | null > | null,
  not?: ModelContactFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFormLogFilterInput = {
  id?: ModelIDInput | null,
  inputformID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  pairedDepID?: ModelIDInput | null,
  pairedUserIDs?: ModelIDInput | null,
  notes?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormLogFilterInput | null > | null,
  or?: Array< ModelFormLogFilterInput | null > | null,
  not?: ModelFormLogFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInputFormFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInputFormFilterInput | null > | null,
  or?: Array< ModelInputFormFilterInput | null > | null,
  not?: ModelInputFormFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  timestamp?: ModelStringInput | null,
  expirationTimestamp?: ModelStringInput | null,
  deadlineTimestamp?: ModelStringInput | null,
  isReadIDs?: ModelStringInput | null,
  isAckIDs?: ModelStringInput | null,
  imageURLs?: ModelStringInput | null,
  videoURLs?: ModelStringInput | null,
  fileURLs?: ModelStringInput | null,
  taggedProtocols?: ModelStringInput | null,
  isPush?: ModelBooleanInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelNotifyACKFilterInput = {
  id?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelNotifyACKFilterInput | null > | null,
  or?: Array< ModelNotifyACKFilterInput | null > | null,
  not?: ModelNotifyACKFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  type?: ModelGroupTypeInput | null,
  departmentID?: ModelIDInput | null,
  pairedDepIDs?: ModelIDInput | null,
  pairedUserIDs?: ModelIDInput | null,
  isAutoGen?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGroupFilterInput | null > | null,
  or?: Array< ModelGroupFilterInput | null > | null,
  not?: ModelGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDripFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  contraindication?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  route?: ModelStringInput | null,
  medClass?: ModelStringInput | null,
  action?: ModelStringInput | null,
  indication?: ModelStringInput | null,
  interaction?: ModelStringInput | null,
  onset?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDripFilterInput | null > | null,
  or?: Array< ModelDripFilterInput | null > | null,
  not?: ModelDripFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelInfusionDoseFilterInput = {
  id?: ModelIDInput | null,
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  route?: ModelStringInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  dripID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelInfusionDoseFilterInput | null > | null,
  or?: Array< ModelInfusionDoseFilterInput | null > | null,
  not?: ModelInfusionDoseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelEquipmentFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  snomedCode?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelEquipmentFilterInput | null > | null,
  or?: Array< ModelEquipmentFilterInput | null > | null,
  not?: ModelEquipmentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMedicationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  contraindication?: ModelStringInput | null,
  note?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  route?: ModelStringInput | null,
  medClass?: ModelStringInput | null,
  action?: ModelStringInput | null,
  indication?: ModelStringInput | null,
  interaction?: ModelStringInput | null,
  onset?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMedicationFilterInput | null > | null,
  or?: Array< ModelMedicationFilterInput | null > | null,
  not?: ModelMedicationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMedicationDoseFilterInput = {
  id?: ModelIDInput | null,
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  route?: ModelStringInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  medicationID?: ModelIDInput | null,
  cprAssistID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMedicationDoseFilterInput | null > | null,
  or?: Array< ModelMedicationDoseFilterInput | null > | null,
  not?: ModelMedicationDoseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelProtocolFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  pdfID?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  pairedProtocols?: ModelIDInput | null,
  medicationIDs?: ModelIDInput | null,
  equipmentIDs?: ModelIDInput | null,
  electricalIDs?: ModelIDInput | null,
  dripIDs?: ModelIDInput | null,
  formIDs?: ModelIDInput | null,
  index?: ModelIntInput | null,
  nickname?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  rangeLow?: ModelIntInput | null,
  rangeHigh?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  pdfVersion?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  privatePin?: ModelStringInput | null,
  keychainID?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  isRestrictive?: ModelBooleanInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelProtocolFilterInput | null > | null,
  or?: Array< ModelProtocolFilterInput | null > | null,
  not?: ModelProtocolFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAmbulanceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAmbulanceFilterInput | null > | null,
  or?: Array< ModelAmbulanceFilterInput | null > | null,
  not?: ModelAmbulanceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  type?: ModelUserTypeInput | null,
  cognitoID?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  depAdmins?: ModelIDInput | null,
  hashedPin?: ModelStringInput | null,
  saltPin?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  oneDoseVersion?: ModelStringInput | null,
  lastActivity?: ModelStringInput | null,
  notificationTokens?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  index?: ModelIntInput | null,
  pairedDepIDs?: ModelStringInput | null,
  parentCategoryID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  isPublic?: ModelBooleanInput | null,
  isRestrictive?: ModelBooleanInput | null,
  overrideID?: ModelIDInput | null,
  keychainID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  departmentCategoriesId?: ModelIDInput | null,
};

export type ModelElectricalFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelElectricalFilterInput | null > | null,
  or?: Array< ModelElectricalFilterInput | null > | null,
  not?: ModelElectricalFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelElectricalShockFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  rxNormCode?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  rangeHigh?: ModelIntInput | null,
  rangeLow?: ModelIntInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelElectricalShockFilterInput | null > | null,
  or?: Array< ModelElectricalShockFilterInput | null > | null,
  not?: ModelElectricalShockFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelElectricalDoseFilterInput = {
  id?: ModelIDInput | null,
  basis?: ModelStringInput | null,
  rangeLow?: ModelFloatInput | null,
  rangeHigh?: ModelFloatInput | null,
  title?: ModelStringInput | null,
  warning?: ModelStringInput | null,
  instruction?: ModelStringInput | null,
  note?: ModelStringInput | null,
  maxDose?: ModelStringInput | null,
  minDose?: ModelStringInput | null,
  maxTotalDose?: ModelStringInput | null,
  calcMax?: ModelStringInput | null,
  calcMin?: ModelStringInput | null,
  index?: ModelIntInput | null,
  ageLow?: ModelIntInput | null,
  ageHigh?: ModelIntInput | null,
  ageGroup?: ModelPatientAgeGroupInput | null,
  repeatTime?: ModelStringInput | null,
  incrementAmnt?: ModelFloatInput | null,
  departmentID?: ModelIDInput | null,
  protocolID?: ModelIDInput | null,
  electricalID?: ModelIDInput | null,
  cprAssistID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelElectricalDoseFilterInput | null > | null,
  or?: Array< ModelElectricalDoseFilterInput | null > | null,
  not?: ModelElectricalDoseFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDeveloperNotificationFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  message?: ModelStringInput | null,
  targetVersion?: ModelStringInput | null,
  additionalData?: ModelStringInput | null,
  type?: ModelDevNotifyTypeInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDeveloperNotificationFilterInput | null > | null,
  or?: Array< ModelDeveloperNotificationFilterInput | null > | null,
  not?: ModelDeveloperNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDeveloperNotificationConnection = {
  __typename: "ModelDeveloperNotificationConnection",
  items:  Array<DeveloperNotification | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelVitalsFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelVitalsFilterInput | null > | null,
  or?: Array< ModelVitalsFilterInput | null > | null,
  not?: ModelVitalsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOneWeightFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  serialNumber?: ModelStringInput | null,
  projectNumber?: ModelStringInput | null,
  lastCalibration?: ModelStringInput | null,
  type?: ModelPWSTypeInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOneWeightFilterInput | null > | null,
  or?: Array< ModelOneWeightFilterInput | null > | null,
  not?: ModelOneWeightFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  departmentOneWeightsId?: ModelIDInput | null,
};

export type ModelFormFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFormFilterInput | null > | null,
  or?: Array< ModelFormFilterInput | null > | null,
  not?: ModelFormFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLogFilterInput = {
  id?: ModelIDInput | null,
  actualWeight?: ModelFloatInput | null,
  ageValue?: ModelIntInput | null,
  protocolID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  oneweightID?: ModelIDInput | null,
  ambulanceID?: ModelIDInput | null,
  incidentNumber?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  estimatedWeight?: ModelFloatInput | null,
  patientSaved?: ModelStringInput | null,
  patientReleased?: ModelStringInput | null,
  arrivedAtHospital?: ModelStringInput | null,
  ageTimestamp?: ModelStringInput | null,
  userIDs?: ModelIDInput | null,
  estimatedSaved?: ModelStringInput | null,
  tags?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLogFilterInput | null > | null,
  or?: Array< ModelLogFilterInput | null > | null,
  not?: ModelLogFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRequireSyncFilterInput = {
  id?: ModelIDInput | null,
  sync?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelRequireSyncFilterInput | null > | null,
  or?: Array< ModelRequireSyncFilterInput | null > | null,
  not?: ModelRequireSyncFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelRequireSyncConnection = {
  __typename: "ModelRequireSyncConnection",
  items:  Array<RequireSync | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelHardwareVersionFilterInput = {
  id?: ModelIDInput | null,
  version?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHardwareVersionFilterInput | null > | null,
  or?: Array< ModelHardwareVersionFilterInput | null > | null,
  not?: ModelHardwareVersionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelHardwareVersionConnection = {
  __typename: "ModelHardwareVersionConnection",
  items:  Array<HardwareVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFirmwareVersionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  version?: ModelStringInput | null,
  fileURL?: ModelStringInput | null,
  description?: ModelStringInput | null,
  cover_image?: ModelStringInput | null,
  compatibleHW?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFirmwareVersionFilterInput | null > | null,
  or?: Array< ModelFirmwareVersionFilterInput | null > | null,
  not?: ModelFirmwareVersionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFirmwareVersionConnection = {
  __typename: "ModelFirmwareVersionConnection",
  items:  Array<FirmwareVersion | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelWorkbookFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  workbookID?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  aiPdfParserResults?: ModelStringInput | null,
  activeID?: ModelIDInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWorkbookFilterInput | null > | null,
  or?: Array< ModelWorkbookFilterInput | null > | null,
  not?: ModelWorkbookFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelWeightObjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  weight?: ModelFloatInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  version?: ModelStringInput | null,
  pairedDepIDs?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelWeightObjectFilterInput | null > | null,
  or?: Array< ModelWeightObjectFilterInput | null > | null,
  not?: ModelWeightObjectFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelConcentrationFilterInput = {
  id?: ModelIDInput | null,
  firstAmnt?: ModelFloatInput | null,
  firstUnit?: ModelStringInput | null,
  secAmnt?: ModelFloatInput | null,
  secUnit?: ModelStringInput | null,
  color?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  medicationID?: ModelIDInput | null,
  dripID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConcentrationFilterInput | null > | null,
  or?: Array< ModelConcentrationFilterInput | null > | null,
  not?: ModelConcentrationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCPRAssistFilterInput = {
  id?: ModelIDInput | null,
  protocolIDs?: ModelIDInput | null,
  epiIDs?: ModelIDInput | null,
  defibIDs?: ModelIDInput | null,
  equipmentID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  activeID?: ModelIDInput | null,
  overrideID?: ModelIDInput | null,
  status?: ModelProgressStatusInput | null,
  version?: ModelStringInput | null,
  createdBy?: ModelIDInput | null,
  modifiedBy?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCPRAssistFilterInput | null > | null,
  or?: Array< ModelCPRAssistFilterInput | null > | null,
  not?: ModelCPRAssistFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelReviewalFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  reviewers?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  departmentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewalFilterInput | null > | null,
  or?: Array< ModelReviewalFilterInput | null > | null,
  not?: ModelReviewalFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDraftGroupFilterInput = {
  id?: ModelIDInput | null,
  reviewalID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  isClosed?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDraftGroupFilterInput | null > | null,
  or?: Array< ModelDraftGroupFilterInput | null > | null,
  not?: ModelDraftGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDraftChangeFilterInput = {
  id?: ModelIDInput | null,
  draftGroupID?: ModelIDInput | null,
  departmentID?: ModelIDInput | null,
  changeID?: ModelIDInput | null,
  previousID?: ModelIDInput | null,
  changeType?: ModelStringInput | null,
  isClosed?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDraftChangeFilterInput | null > | null,
  or?: Array< ModelDraftChangeFilterInput | null > | null,
  not?: ModelDraftChangeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAcknowledgeFilterInput = {
  id?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelACKStatusInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAcknowledgeFilterInput | null > | null,
  or?: Array< ModelAcknowledgeFilterInput | null > | null,
  not?: ModelAcknowledgeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserCommentFilterInput = {
  id?: ModelIDInput | null,
  ownerID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  acknowledgeID?: ModelIDInput | null,
  message?: ModelStringInput | null,
  relatedItems?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserCommentFilterInput | null > | null,
  or?: Array< ModelUserCommentFilterInput | null > | null,
  not?: ModelUserCommentFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelGroupNotificationFilterInput = {
  id?: ModelIDInput | null,
  notificationId?: ModelIDInput | null,
  groupId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelGroupNotificationFilterInput | null > | null,
  or?: Array< ModelGroupNotificationFilterInput | null > | null,
  not?: ModelGroupNotificationFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLogUserFilterInput = {
  id?: ModelIDInput | null,
  userId?: ModelIDInput | null,
  logId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLogUserFilterInput | null > | null,
  or?: Array< ModelLogUserFilterInput | null > | null,
  not?: ModelLogUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDepartmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  location?: ModelSubscriptionStringInput | null,
  protocolVersions?: ModelSubscriptionStringInput | null,
  shiftTypes?: ModelSubscriptionStringInput | null,
  cognitoID?: ModelSubscriptionStringInput | null,
  logoID?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  neonateCutoff?: ModelSubscriptionFloatInput | null,
  pediatricCutoff?: ModelSubscriptionFloatInput | null,
  calculators?: ModelSubscriptionStringInput | null,
  softwarePlan?: ModelSubscriptionStringInput | null,
  subDepIDs?: ModelSubscriptionIDInput | null,
  parentDepID?: ModelSubscriptionIDInput | null,
  activeStatus?: ModelSubscriptionBooleanInput | null,
  agencyNumEMS?: ModelSubscriptionStringInput | null,
  stateIdEMS?: ModelSubscriptionStringInput | null,
  gnisCodeEMS?: ModelSubscriptionStringInput | null,
  uniqueCode?: ModelSubscriptionStringInput | null,
  hashedPin?: ModelSubscriptionStringInput | null,
  saltedPin?: ModelSubscriptionStringInput | null,
  keychainID?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  infusionCalculation?: ModelSubscriptionBooleanInput | null,
  isPublicSignup?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDepartmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionDepartmentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  departmentCategoriesId?: ModelSubscriptionIDInput | null,
  departmentOneWeightsId?: ModelSubscriptionIDInput | null,
  departmentRequireSyncId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionMedicShiftFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  shiftStart?: ModelSubscriptionStringInput | null,
  shiftEnd?: ModelSubscriptionStringInput | null,
  completedLogs?: ModelSubscriptionIDInput | null,
  taggedAmbulance?: ModelSubscriptionIDInput | null,
  taggedOneWeight?: ModelSubscriptionIDInput | null,
  taggedUsers?: ModelSubscriptionIDInput | null,
  subscribedDevices?: ModelSubscriptionStringInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  interactionID?: ModelSubscriptionIDInput | null,
  metaData?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMedicShiftFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedicShiftFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPatientInteractionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  isLogActive?: ModelSubscriptionBooleanInput | null,
  actualWeight?: ModelSubscriptionFloatInput | null,
  ageValue?: ModelSubscriptionIntInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  estimatedWeight?: ModelSubscriptionFloatInput | null,
  taggedUsers?: ModelSubscriptionIDInput | null,
  taggedAmbulance?: ModelSubscriptionIDInput | null,
  taggedOneWeight?: ModelSubscriptionIDInput | null,
  incidentNumber?: ModelSubscriptionStringInput | null,
  actualTime?: ModelSubscriptionStringInput | null,
  estimatedTime?: ModelSubscriptionStringInput | null,
  patientReleased?: ModelSubscriptionStringInput | null,
  ageTimestamp?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  subscribedUsers?: ModelSubscriptionIDInput | null,
  subscribedDevices?: ModelSubscriptionStringInput | null,
  shifts?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPatientInteractionFilterInput | null > | null,
  or?: Array< ModelSubscriptionPatientInteractionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionKeychainFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  hashedPin?: ModelSubscriptionStringInput | null,
  saltPin?: ModelSubscriptionStringInput | null,
  hashType?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  metaData?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionKeychainFilterInput | null > | null,
  or?: Array< ModelSubscriptionKeychainFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionContactFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  fullName?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  number?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionContactFilterInput | null > | null,
  or?: Array< ModelSubscriptionContactFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFormLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  inputformID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  pairedDepID?: ModelSubscriptionIDInput | null,
  pairedUserIDs?: ModelSubscriptionIDInput | null,
  notes?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormLogFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInputFormFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInputFormFilterInput | null > | null,
  or?: Array< ModelSubscriptionInputFormFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  type?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  timestamp?: ModelSubscriptionStringInput | null,
  expirationTimestamp?: ModelSubscriptionStringInput | null,
  deadlineTimestamp?: ModelSubscriptionStringInput | null,
  isReadIDs?: ModelSubscriptionStringInput | null,
  isAckIDs?: ModelSubscriptionStringInput | null,
  imageURLs?: ModelSubscriptionStringInput | null,
  videoURLs?: ModelSubscriptionStringInput | null,
  fileURLs?: ModelSubscriptionStringInput | null,
  taggedProtocols?: ModelSubscriptionStringInput | null,
  isPush?: ModelSubscriptionBooleanInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionNotifyACKFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionNotifyACKFilterInput | null > | null,
  or?: Array< ModelSubscriptionNotifyACKFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  pairedUserIDs?: ModelSubscriptionIDInput | null,
  isAutoGen?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDripFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  rxNormCode?: ModelSubscriptionStringInput | null,
  contraindication?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  route?: ModelSubscriptionStringInput | null,
  medClass?: ModelSubscriptionStringInput | null,
  action?: ModelSubscriptionStringInput | null,
  indication?: ModelSubscriptionStringInput | null,
  interaction?: ModelSubscriptionStringInput | null,
  onset?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  rangeLow?: ModelSubscriptionIntInput | null,
  rangeHigh?: ModelSubscriptionIntInput | null,
  maxDose?: ModelSubscriptionStringInput | null,
  minDose?: ModelSubscriptionStringInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDripFilterInput | null > | null,
  or?: Array< ModelSubscriptionDripFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionInfusionDoseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  basis?: ModelSubscriptionStringInput | null,
  rangeLow?: ModelSubscriptionFloatInput | null,
  rangeHigh?: ModelSubscriptionFloatInput | null,
  route?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  maxDose?: ModelSubscriptionStringInput | null,
  minDose?: ModelSubscriptionStringInput | null,
  maxTotalDose?: ModelSubscriptionStringInput | null,
  calcMax?: ModelSubscriptionStringInput | null,
  calcMin?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  ageLow?: ModelSubscriptionIntInput | null,
  ageHigh?: ModelSubscriptionIntInput | null,
  ageGroup?: ModelSubscriptionStringInput | null,
  repeatTime?: ModelSubscriptionStringInput | null,
  incrementAmnt?: ModelSubscriptionFloatInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  protocolID?: ModelSubscriptionIDInput | null,
  dripID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionInfusionDoseFilterInput | null > | null,
  or?: Array< ModelSubscriptionInfusionDoseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionEquipmentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  snomedCode?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionEquipmentFilterInput | null > | null,
  or?: Array< ModelSubscriptionEquipmentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMedicationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  rxNormCode?: ModelSubscriptionStringInput | null,
  contraindication?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  route?: ModelSubscriptionStringInput | null,
  medClass?: ModelSubscriptionStringInput | null,
  action?: ModelSubscriptionStringInput | null,
  indication?: ModelSubscriptionStringInput | null,
  interaction?: ModelSubscriptionStringInput | null,
  onset?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  rangeLow?: ModelSubscriptionIntInput | null,
  rangeHigh?: ModelSubscriptionIntInput | null,
  maxDose?: ModelSubscriptionStringInput | null,
  minDose?: ModelSubscriptionStringInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMedicationFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedicationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMedicationDoseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  basis?: ModelSubscriptionStringInput | null,
  rangeLow?: ModelSubscriptionFloatInput | null,
  rangeHigh?: ModelSubscriptionFloatInput | null,
  route?: ModelSubscriptionStringInput | null,
  title?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  maxDose?: ModelSubscriptionStringInput | null,
  minDose?: ModelSubscriptionStringInput | null,
  maxTotalDose?: ModelSubscriptionStringInput | null,
  calcMax?: ModelSubscriptionStringInput | null,
  calcMin?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  ageLow?: ModelSubscriptionIntInput | null,
  ageHigh?: ModelSubscriptionIntInput | null,
  ageGroup?: ModelSubscriptionStringInput | null,
  repeatTime?: ModelSubscriptionStringInput | null,
  incrementAmnt?: ModelSubscriptionFloatInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  protocolID?: ModelSubscriptionIDInput | null,
  medicationID?: ModelSubscriptionIDInput | null,
  cprAssistID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMedicationDoseFilterInput | null > | null,
  or?: Array< ModelSubscriptionMedicationDoseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionProtocolFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  pdfID?: ModelSubscriptionStringInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  pairedProtocols?: ModelSubscriptionIDInput | null,
  medicationIDs?: ModelSubscriptionIDInput | null,
  equipmentIDs?: ModelSubscriptionIDInput | null,
  electricalIDs?: ModelSubscriptionIDInput | null,
  dripIDs?: ModelSubscriptionIDInput | null,
  formIDs?: ModelSubscriptionIDInput | null,
  index?: ModelSubscriptionIntInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  rangeLow?: ModelSubscriptionIntInput | null,
  rangeHigh?: ModelSubscriptionIntInput | null,
  ageLow?: ModelSubscriptionIntInput | null,
  ageHigh?: ModelSubscriptionIntInput | null,
  ageGroup?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  pdfVersion?: ModelSubscriptionStringInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  privatePin?: ModelSubscriptionStringInput | null,
  keychainID?: ModelSubscriptionIDInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  isRestrictive?: ModelSubscriptionBooleanInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProtocolFilterInput | null > | null,
  or?: Array< ModelSubscriptionProtocolFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAmbulanceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAmbulanceFilterInput | null > | null,
  or?: Array< ModelSubscriptionAmbulanceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstName?: ModelSubscriptionStringInput | null,
  lastName?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  cognitoID?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  depAdmins?: ModelSubscriptionIDInput | null,
  hashedPin?: ModelSubscriptionStringInput | null,
  saltPin?: ModelSubscriptionStringInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  oneDoseVersion?: ModelSubscriptionStringInput | null,
  lastActivity?: ModelSubscriptionStringInput | null,
  notificationTokens?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  index?: ModelSubscriptionIntInput | null,
  pairedDepIDs?: ModelSubscriptionStringInput | null,
  parentCategoryID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  isPublic?: ModelSubscriptionBooleanInput | null,
  isRestrictive?: ModelSubscriptionBooleanInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  keychainID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionElectricalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionElectricalFilterInput | null > | null,
  or?: Array< ModelSubscriptionElectricalFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionElectricalShockFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  rxNormCode?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  rangeHigh?: ModelSubscriptionIntInput | null,
  rangeLow?: ModelSubscriptionIntInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionElectricalShockFilterInput | null > | null,
  or?: Array< ModelSubscriptionElectricalShockFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionElectricalDoseFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  basis?: ModelSubscriptionStringInput | null,
  rangeLow?: ModelSubscriptionFloatInput | null,
  rangeHigh?: ModelSubscriptionFloatInput | null,
  title?: ModelSubscriptionStringInput | null,
  warning?: ModelSubscriptionStringInput | null,
  instruction?: ModelSubscriptionStringInput | null,
  note?: ModelSubscriptionStringInput | null,
  maxDose?: ModelSubscriptionStringInput | null,
  minDose?: ModelSubscriptionStringInput | null,
  maxTotalDose?: ModelSubscriptionStringInput | null,
  calcMax?: ModelSubscriptionStringInput | null,
  calcMin?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  ageLow?: ModelSubscriptionIntInput | null,
  ageHigh?: ModelSubscriptionIntInput | null,
  ageGroup?: ModelSubscriptionStringInput | null,
  repeatTime?: ModelSubscriptionStringInput | null,
  incrementAmnt?: ModelSubscriptionFloatInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  protocolID?: ModelSubscriptionIDInput | null,
  electricalID?: ModelSubscriptionIDInput | null,
  cprAssistID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionElectricalDoseFilterInput | null > | null,
  or?: Array< ModelSubscriptionElectricalDoseFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDeveloperNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  message?: ModelSubscriptionStringInput | null,
  targetVersion?: ModelSubscriptionStringInput | null,
  additionalData?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDeveloperNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionDeveloperNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionVitalsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionVitalsFilterInput | null > | null,
  or?: Array< ModelSubscriptionVitalsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOneWeightFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  serialNumber?: ModelSubscriptionStringInput | null,
  projectNumber?: ModelSubscriptionStringInput | null,
  lastCalibration?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOneWeightFilterInput | null > | null,
  or?: Array< ModelSubscriptionOneWeightFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFormFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFormFilterInput | null > | null,
  or?: Array< ModelSubscriptionFormFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  actualWeight?: ModelSubscriptionFloatInput | null,
  ageValue?: ModelSubscriptionIntInput | null,
  protocolID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  oneweightID?: ModelSubscriptionIDInput | null,
  ambulanceID?: ModelSubscriptionIDInput | null,
  incidentNumber?: ModelSubscriptionStringInput | null,
  startTime?: ModelSubscriptionStringInput | null,
  endTime?: ModelSubscriptionStringInput | null,
  estimatedWeight?: ModelSubscriptionFloatInput | null,
  patientSaved?: ModelSubscriptionStringInput | null,
  patientReleased?: ModelSubscriptionStringInput | null,
  arrivedAtHospital?: ModelSubscriptionStringInput | null,
  ageTimestamp?: ModelSubscriptionStringInput | null,
  userIDs?: ModelSubscriptionIDInput | null,
  estimatedSaved?: ModelSubscriptionStringInput | null,
  tags?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLogFilterInput | null > | null,
  or?: Array< ModelSubscriptionLogFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionRequireSyncFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sync?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionRequireSyncFilterInput | null > | null,
  or?: Array< ModelSubscriptionRequireSyncFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionHardwareVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  version?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHardwareVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionHardwareVersionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFirmwareVersionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  fileURL?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  cover_image?: ModelSubscriptionStringInput | null,
  compatibleHW?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFirmwareVersionFilterInput | null > | null,
  or?: Array< ModelSubscriptionFirmwareVersionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionWorkbookFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  workbookID?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  aiPdfParserResults?: ModelSubscriptionStringInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWorkbookFilterInput | null > | null,
  or?: Array< ModelSubscriptionWorkbookFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionWeightObjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  weight?: ModelSubscriptionFloatInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  version?: ModelSubscriptionStringInput | null,
  pairedDepIDs?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionWeightObjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionWeightObjectFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConcentrationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  firstAmnt?: ModelSubscriptionFloatInput | null,
  firstUnit?: ModelSubscriptionStringInput | null,
  secAmnt?: ModelSubscriptionFloatInput | null,
  secUnit?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  medicationID?: ModelSubscriptionIDInput | null,
  dripID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConcentrationFilterInput | null > | null,
  or?: Array< ModelSubscriptionConcentrationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCPRAssistFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  protocolIDs?: ModelSubscriptionIDInput | null,
  epiIDs?: ModelSubscriptionIDInput | null,
  defibIDs?: ModelSubscriptionIDInput | null,
  equipmentID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  activeID?: ModelSubscriptionIDInput | null,
  overrideID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  version?: ModelSubscriptionStringInput | null,
  createdBy?: ModelSubscriptionIDInput | null,
  modifiedBy?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCPRAssistFilterInput | null > | null,
  or?: Array< ModelSubscriptionCPRAssistFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionReviewalFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  reviewers?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  endedAt?: ModelSubscriptionStringInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewalFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewalFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDraftGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  reviewalID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  title?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  isClosed?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDraftGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionDraftGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDraftChangeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  draftGroupID?: ModelSubscriptionIDInput | null,
  departmentID?: ModelSubscriptionIDInput | null,
  changeID?: ModelSubscriptionIDInput | null,
  previousID?: ModelSubscriptionIDInput | null,
  changeType?: ModelSubscriptionStringInput | null,
  isClosed?: ModelSubscriptionBooleanInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDraftChangeFilterInput | null > | null,
  or?: Array< ModelSubscriptionDraftChangeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAcknowledgeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAcknowledgeFilterInput | null > | null,
  or?: Array< ModelSubscriptionAcknowledgeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserCommentFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ownerID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  acknowledgeID?: ModelSubscriptionIDInput | null,
  message?: ModelSubscriptionStringInput | null,
  relatedItems?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserCommentFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserCommentFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionGroupNotificationFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  notificationId?: ModelSubscriptionIDInput | null,
  groupId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGroupNotificationFilterInput | null > | null,
  or?: Array< ModelSubscriptionGroupNotificationFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLogUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  userId?: ModelSubscriptionIDInput | null,
  logId?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLogUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionLogUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input: DeleteDepartmentInput,
  condition?: ModelDepartmentConditionInput | null,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type CreateMedicShiftMutationVariables = {
  input: CreateMedicShiftInput,
  condition?: ModelMedicShiftConditionInput | null,
};

export type CreateMedicShiftMutation = {
  createMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMedicShiftMutationVariables = {
  input: UpdateMedicShiftInput,
  condition?: ModelMedicShiftConditionInput | null,
};

export type UpdateMedicShiftMutation = {
  updateMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMedicShiftMutationVariables = {
  input: DeleteMedicShiftInput,
  condition?: ModelMedicShiftConditionInput | null,
};

export type DeleteMedicShiftMutation = {
  deleteMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePatientInteractionMutationVariables = {
  input: CreatePatientInteractionInput,
  condition?: ModelPatientInteractionConditionInput | null,
};

export type CreatePatientInteractionMutation = {
  createPatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePatientInteractionMutationVariables = {
  input: UpdatePatientInteractionInput,
  condition?: ModelPatientInteractionConditionInput | null,
};

export type UpdatePatientInteractionMutation = {
  updatePatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePatientInteractionMutationVariables = {
  input: DeletePatientInteractionInput,
  condition?: ModelPatientInteractionConditionInput | null,
};

export type DeletePatientInteractionMutation = {
  deletePatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateKeychainMutationVariables = {
  input: CreateKeychainInput,
  condition?: ModelKeychainConditionInput | null,
};

export type CreateKeychainMutation = {
  createKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateKeychainMutationVariables = {
  input: UpdateKeychainInput,
  condition?: ModelKeychainConditionInput | null,
};

export type UpdateKeychainMutation = {
  updateKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteKeychainMutationVariables = {
  input: DeleteKeychainInput,
  condition?: ModelKeychainConditionInput | null,
};

export type DeleteKeychainMutation = {
  deleteKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateContactMutationVariables = {
  input: CreateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type CreateContactMutation = {
  createContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateContactMutationVariables = {
  input: UpdateContactInput,
  condition?: ModelContactConditionInput | null,
};

export type UpdateContactMutation = {
  updateContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteContactMutationVariables = {
  input: DeleteContactInput,
  condition?: ModelContactConditionInput | null,
};

export type DeleteContactMutation = {
  deleteContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFormLogMutationVariables = {
  input: CreateFormLogInput,
  condition?: ModelFormLogConditionInput | null,
};

export type CreateFormLogMutation = {
  createFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFormLogMutationVariables = {
  input: UpdateFormLogInput,
  condition?: ModelFormLogConditionInput | null,
};

export type UpdateFormLogMutation = {
  updateFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFormLogMutationVariables = {
  input: DeleteFormLogInput,
  condition?: ModelFormLogConditionInput | null,
};

export type DeleteFormLogMutation = {
  deleteFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInputFormMutationVariables = {
  input: CreateInputFormInput,
  condition?: ModelInputFormConditionInput | null,
};

export type CreateInputFormMutation = {
  createInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInputFormMutationVariables = {
  input: UpdateInputFormInput,
  condition?: ModelInputFormConditionInput | null,
};

export type UpdateInputFormMutation = {
  updateInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInputFormMutationVariables = {
  input: DeleteInputFormInput,
  condition?: ModelInputFormConditionInput | null,
};

export type DeleteInputFormMutation = {
  deleteInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateNotifyACKMutationVariables = {
  input: CreateNotifyACKInput,
  condition?: ModelNotifyACKConditionInput | null,
};

export type CreateNotifyACKMutation = {
  createNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateNotifyACKMutationVariables = {
  input: UpdateNotifyACKInput,
  condition?: ModelNotifyACKConditionInput | null,
};

export type UpdateNotifyACKMutation = {
  updateNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteNotifyACKMutationVariables = {
  input: DeleteNotifyACKInput,
  condition?: ModelNotifyACKConditionInput | null,
};

export type DeleteNotifyACKMutation = {
  deleteNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGroupMutationVariables = {
  input: CreateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type CreateGroupMutation = {
  createGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type UpdateGroupMutation = {
  updateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput,
  condition?: ModelGroupConditionInput | null,
};

export type DeleteGroupMutation = {
  deleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDripMutationVariables = {
  input: CreateDripInput,
  condition?: ModelDripConditionInput | null,
};

export type CreateDripMutation = {
  createDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDripMutationVariables = {
  input: UpdateDripInput,
  condition?: ModelDripConditionInput | null,
};

export type UpdateDripMutation = {
  updateDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDripMutationVariables = {
  input: DeleteDripInput,
  condition?: ModelDripConditionInput | null,
};

export type DeleteDripMutation = {
  deleteDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateInfusionDoseMutationVariables = {
  input: CreateInfusionDoseInput,
  condition?: ModelInfusionDoseConditionInput | null,
};

export type CreateInfusionDoseMutation = {
  createInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateInfusionDoseMutationVariables = {
  input: UpdateInfusionDoseInput,
  condition?: ModelInfusionDoseConditionInput | null,
};

export type UpdateInfusionDoseMutation = {
  updateInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteInfusionDoseMutationVariables = {
  input: DeleteInfusionDoseInput,
  condition?: ModelInfusionDoseConditionInput | null,
};

export type DeleteInfusionDoseMutation = {
  deleteInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateEquipmentMutationVariables = {
  input: CreateEquipmentInput,
  condition?: ModelEquipmentConditionInput | null,
};

export type CreateEquipmentMutation = {
  createEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateEquipmentMutationVariables = {
  input: UpdateEquipmentInput,
  condition?: ModelEquipmentConditionInput | null,
};

export type UpdateEquipmentMutation = {
  updateEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteEquipmentMutationVariables = {
  input: DeleteEquipmentInput,
  condition?: ModelEquipmentConditionInput | null,
};

export type DeleteEquipmentMutation = {
  deleteEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMedicationMutationVariables = {
  input: CreateMedicationInput,
  condition?: ModelMedicationConditionInput | null,
};

export type CreateMedicationMutation = {
  createMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMedicationMutationVariables = {
  input: UpdateMedicationInput,
  condition?: ModelMedicationConditionInput | null,
};

export type UpdateMedicationMutation = {
  updateMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMedicationMutationVariables = {
  input: DeleteMedicationInput,
  condition?: ModelMedicationConditionInput | null,
};

export type DeleteMedicationMutation = {
  deleteMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMedicationDoseMutationVariables = {
  input: CreateMedicationDoseInput,
  condition?: ModelMedicationDoseConditionInput | null,
};

export type CreateMedicationDoseMutation = {
  createMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMedicationDoseMutationVariables = {
  input: UpdateMedicationDoseInput,
  condition?: ModelMedicationDoseConditionInput | null,
};

export type UpdateMedicationDoseMutation = {
  updateMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMedicationDoseMutationVariables = {
  input: DeleteMedicationDoseInput,
  condition?: ModelMedicationDoseConditionInput | null,
};

export type DeleteMedicationDoseMutation = {
  deleteMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateProtocolMutationVariables = {
  input: CreateProtocolInput,
  condition?: ModelProtocolConditionInput | null,
};

export type CreateProtocolMutation = {
  createProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateProtocolMutationVariables = {
  input: UpdateProtocolInput,
  condition?: ModelProtocolConditionInput | null,
};

export type UpdateProtocolMutation = {
  updateProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteProtocolMutationVariables = {
  input: DeleteProtocolInput,
  condition?: ModelProtocolConditionInput | null,
};

export type DeleteProtocolMutation = {
  deleteProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAmbulanceMutationVariables = {
  input: CreateAmbulanceInput,
  condition?: ModelAmbulanceConditionInput | null,
};

export type CreateAmbulanceMutation = {
  createAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAmbulanceMutationVariables = {
  input: UpdateAmbulanceInput,
  condition?: ModelAmbulanceConditionInput | null,
};

export type UpdateAmbulanceMutation = {
  updateAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAmbulanceMutationVariables = {
  input: DeleteAmbulanceInput,
  condition?: ModelAmbulanceConditionInput | null,
};

export type DeleteAmbulanceMutation = {
  deleteAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type CreateElectricalMutationVariables = {
  input: CreateElectricalInput,
  condition?: ModelElectricalConditionInput | null,
};

export type CreateElectricalMutation = {
  createElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateElectricalMutationVariables = {
  input: UpdateElectricalInput,
  condition?: ModelElectricalConditionInput | null,
};

export type UpdateElectricalMutation = {
  updateElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteElectricalMutationVariables = {
  input: DeleteElectricalInput,
  condition?: ModelElectricalConditionInput | null,
};

export type DeleteElectricalMutation = {
  deleteElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateElectricalShockMutationVariables = {
  input: CreateElectricalShockInput,
  condition?: ModelElectricalShockConditionInput | null,
};

export type CreateElectricalShockMutation = {
  createElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateElectricalShockMutationVariables = {
  input: UpdateElectricalShockInput,
  condition?: ModelElectricalShockConditionInput | null,
};

export type UpdateElectricalShockMutation = {
  updateElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteElectricalShockMutationVariables = {
  input: DeleteElectricalShockInput,
  condition?: ModelElectricalShockConditionInput | null,
};

export type DeleteElectricalShockMutation = {
  deleteElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateElectricalDoseMutationVariables = {
  input: CreateElectricalDoseInput,
  condition?: ModelElectricalDoseConditionInput | null,
};

export type CreateElectricalDoseMutation = {
  createElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateElectricalDoseMutationVariables = {
  input: UpdateElectricalDoseInput,
  condition?: ModelElectricalDoseConditionInput | null,
};

export type UpdateElectricalDoseMutation = {
  updateElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteElectricalDoseMutationVariables = {
  input: DeleteElectricalDoseInput,
  condition?: ModelElectricalDoseConditionInput | null,
};

export type DeleteElectricalDoseMutation = {
  deleteElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDeveloperNotificationMutationVariables = {
  input: CreateDeveloperNotificationInput,
  condition?: ModelDeveloperNotificationConditionInput | null,
};

export type CreateDeveloperNotificationMutation = {
  createDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDeveloperNotificationMutationVariables = {
  input: UpdateDeveloperNotificationInput,
  condition?: ModelDeveloperNotificationConditionInput | null,
};

export type UpdateDeveloperNotificationMutation = {
  updateDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDeveloperNotificationMutationVariables = {
  input: DeleteDeveloperNotificationInput,
  condition?: ModelDeveloperNotificationConditionInput | null,
};

export type DeleteDeveloperNotificationMutation = {
  deleteDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateVitalsMutationVariables = {
  input: CreateVitalsInput,
  condition?: ModelVitalsConditionInput | null,
};

export type CreateVitalsMutation = {
  createVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateVitalsMutationVariables = {
  input: UpdateVitalsInput,
  condition?: ModelVitalsConditionInput | null,
};

export type UpdateVitalsMutation = {
  updateVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteVitalsMutationVariables = {
  input: DeleteVitalsInput,
  condition?: ModelVitalsConditionInput | null,
};

export type DeleteVitalsMutation = {
  deleteVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOneWeightMutationVariables = {
  input: CreateOneWeightInput,
  condition?: ModelOneWeightConditionInput | null,
};

export type CreateOneWeightMutation = {
  createOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type UpdateOneWeightMutationVariables = {
  input: UpdateOneWeightInput,
  condition?: ModelOneWeightConditionInput | null,
};

export type UpdateOneWeightMutation = {
  updateOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type DeleteOneWeightMutationVariables = {
  input: DeleteOneWeightInput,
  condition?: ModelOneWeightConditionInput | null,
};

export type DeleteOneWeightMutation = {
  deleteOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type CreateFormMutationVariables = {
  input: CreateFormInput,
  condition?: ModelFormConditionInput | null,
};

export type CreateFormMutation = {
  createForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFormMutationVariables = {
  input: UpdateFormInput,
  condition?: ModelFormConditionInput | null,
};

export type UpdateFormMutation = {
  updateForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFormMutationVariables = {
  input: DeleteFormInput,
  condition?: ModelFormConditionInput | null,
};

export type DeleteFormMutation = {
  deleteForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLogMutationVariables = {
  input: CreateLogInput,
  condition?: ModelLogConditionInput | null,
};

export type CreateLogMutation = {
  createLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLogMutationVariables = {
  input: UpdateLogInput,
  condition?: ModelLogConditionInput | null,
};

export type UpdateLogMutation = {
  updateLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLogMutationVariables = {
  input: DeleteLogInput,
  condition?: ModelLogConditionInput | null,
};

export type DeleteLogMutation = {
  deleteLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateRequireSyncMutationVariables = {
  input: CreateRequireSyncInput,
  condition?: ModelRequireSyncConditionInput | null,
};

export type CreateRequireSyncMutation = {
  createRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateRequireSyncMutationVariables = {
  input: UpdateRequireSyncInput,
  condition?: ModelRequireSyncConditionInput | null,
};

export type UpdateRequireSyncMutation = {
  updateRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteRequireSyncMutationVariables = {
  input: DeleteRequireSyncInput,
  condition?: ModelRequireSyncConditionInput | null,
};

export type DeleteRequireSyncMutation = {
  deleteRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateHardwareVersionMutationVariables = {
  input: CreateHardwareVersionInput,
  condition?: ModelHardwareVersionConditionInput | null,
};

export type CreateHardwareVersionMutation = {
  createHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateHardwareVersionMutationVariables = {
  input: UpdateHardwareVersionInput,
  condition?: ModelHardwareVersionConditionInput | null,
};

export type UpdateHardwareVersionMutation = {
  updateHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteHardwareVersionMutationVariables = {
  input: DeleteHardwareVersionInput,
  condition?: ModelHardwareVersionConditionInput | null,
};

export type DeleteHardwareVersionMutation = {
  deleteHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFirmwareVersionMutationVariables = {
  input: CreateFirmwareVersionInput,
  condition?: ModelFirmwareVersionConditionInput | null,
};

export type CreateFirmwareVersionMutation = {
  createFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFirmwareVersionMutationVariables = {
  input: UpdateFirmwareVersionInput,
  condition?: ModelFirmwareVersionConditionInput | null,
};

export type UpdateFirmwareVersionMutation = {
  updateFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFirmwareVersionMutationVariables = {
  input: DeleteFirmwareVersionInput,
  condition?: ModelFirmwareVersionConditionInput | null,
};

export type DeleteFirmwareVersionMutation = {
  deleteFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWorkbookMutationVariables = {
  input: CreateWorkbookInput,
  condition?: ModelWorkbookConditionInput | null,
};

export type CreateWorkbookMutation = {
  createWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWorkbookMutationVariables = {
  input: UpdateWorkbookInput,
  condition?: ModelWorkbookConditionInput | null,
};

export type UpdateWorkbookMutation = {
  updateWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWorkbookMutationVariables = {
  input: DeleteWorkbookInput,
  condition?: ModelWorkbookConditionInput | null,
};

export type DeleteWorkbookMutation = {
  deleteWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateWeightObjectMutationVariables = {
  input: CreateWeightObjectInput,
  condition?: ModelWeightObjectConditionInput | null,
};

export type CreateWeightObjectMutation = {
  createWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateWeightObjectMutationVariables = {
  input: UpdateWeightObjectInput,
  condition?: ModelWeightObjectConditionInput | null,
};

export type UpdateWeightObjectMutation = {
  updateWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteWeightObjectMutationVariables = {
  input: DeleteWeightObjectInput,
  condition?: ModelWeightObjectConditionInput | null,
};

export type DeleteWeightObjectMutation = {
  deleteWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConcentrationMutationVariables = {
  input: CreateConcentrationInput,
  condition?: ModelConcentrationConditionInput | null,
};

export type CreateConcentrationMutation = {
  createConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConcentrationMutationVariables = {
  input: UpdateConcentrationInput,
  condition?: ModelConcentrationConditionInput | null,
};

export type UpdateConcentrationMutation = {
  updateConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConcentrationMutationVariables = {
  input: DeleteConcentrationInput,
  condition?: ModelConcentrationConditionInput | null,
};

export type DeleteConcentrationMutation = {
  deleteConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCPRAssistMutationVariables = {
  input: CreateCPRAssistInput,
  condition?: ModelCPRAssistConditionInput | null,
};

export type CreateCPRAssistMutation = {
  createCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCPRAssistMutationVariables = {
  input: UpdateCPRAssistInput,
  condition?: ModelCPRAssistConditionInput | null,
};

export type UpdateCPRAssistMutation = {
  updateCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCPRAssistMutationVariables = {
  input: DeleteCPRAssistInput,
  condition?: ModelCPRAssistConditionInput | null,
};

export type DeleteCPRAssistMutation = {
  deleteCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReviewalMutationVariables = {
  input: CreateReviewalInput,
  condition?: ModelReviewalConditionInput | null,
};

export type CreateReviewalMutation = {
  createReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReviewalMutationVariables = {
  input: UpdateReviewalInput,
  condition?: ModelReviewalConditionInput | null,
};

export type UpdateReviewalMutation = {
  updateReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReviewalMutationVariables = {
  input: DeleteReviewalInput,
  condition?: ModelReviewalConditionInput | null,
};

export type DeleteReviewalMutation = {
  deleteReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDraftGroupMutationVariables = {
  input: CreateDraftGroupInput,
  condition?: ModelDraftGroupConditionInput | null,
};

export type CreateDraftGroupMutation = {
  createDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDraftGroupMutationVariables = {
  input: UpdateDraftGroupInput,
  condition?: ModelDraftGroupConditionInput | null,
};

export type UpdateDraftGroupMutation = {
  updateDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDraftGroupMutationVariables = {
  input: DeleteDraftGroupInput,
  condition?: ModelDraftGroupConditionInput | null,
};

export type DeleteDraftGroupMutation = {
  deleteDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDraftChangeMutationVariables = {
  input: CreateDraftChangeInput,
  condition?: ModelDraftChangeConditionInput | null,
};

export type CreateDraftChangeMutation = {
  createDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDraftChangeMutationVariables = {
  input: UpdateDraftChangeInput,
  condition?: ModelDraftChangeConditionInput | null,
};

export type UpdateDraftChangeMutation = {
  updateDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDraftChangeMutationVariables = {
  input: DeleteDraftChangeInput,
  condition?: ModelDraftChangeConditionInput | null,
};

export type DeleteDraftChangeMutation = {
  deleteDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAcknowledgeMutationVariables = {
  input: CreateAcknowledgeInput,
  condition?: ModelAcknowledgeConditionInput | null,
};

export type CreateAcknowledgeMutation = {
  createAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAcknowledgeMutationVariables = {
  input: UpdateAcknowledgeInput,
  condition?: ModelAcknowledgeConditionInput | null,
};

export type UpdateAcknowledgeMutation = {
  updateAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAcknowledgeMutationVariables = {
  input: DeleteAcknowledgeInput,
  condition?: ModelAcknowledgeConditionInput | null,
};

export type DeleteAcknowledgeMutation = {
  deleteAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserCommentMutationVariables = {
  input: CreateUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type CreateUserCommentMutation = {
  createUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserCommentMutationVariables = {
  input: UpdateUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type UpdateUserCommentMutation = {
  updateUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserCommentMutationVariables = {
  input: DeleteUserCommentInput,
  condition?: ModelUserCommentConditionInput | null,
};

export type DeleteUserCommentMutation = {
  deleteUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateGroupNotificationMutationVariables = {
  input: CreateGroupNotificationInput,
  condition?: ModelGroupNotificationConditionInput | null,
};

export type CreateGroupNotificationMutation = {
  createGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateGroupNotificationMutationVariables = {
  input: UpdateGroupNotificationInput,
  condition?: ModelGroupNotificationConditionInput | null,
};

export type UpdateGroupNotificationMutation = {
  updateGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteGroupNotificationMutationVariables = {
  input: DeleteGroupNotificationInput,
  condition?: ModelGroupNotificationConditionInput | null,
};

export type DeleteGroupNotificationMutation = {
  deleteGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLogUserMutationVariables = {
  input: CreateLogUserInput,
  condition?: ModelLogUserConditionInput | null,
};

export type CreateLogUserMutation = {
  createLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLogUserMutationVariables = {
  input: UpdateLogUserInput,
  condition?: ModelLogUserConditionInput | null,
};

export type UpdateLogUserMutation = {
  updateLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLogUserMutationVariables = {
  input: DeleteLogUserInput,
  condition?: ModelLogUserConditionInput | null,
};

export type DeleteLogUserMutation = {
  deleteLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetDepartmentQueryVariables = {
  id: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDepartmentsQueryVariables = {
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDepartmentsQuery = {
  syncDepartments?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DepartmentsByAgencyNumEMSQueryVariables = {
  agencyNumEMS: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DepartmentsByAgencyNumEMSQuery = {
  departmentsByAgencyNumEMS?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DepartmentsByUniqueCodeQueryVariables = {
  uniqueCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DepartmentsByUniqueCodeQuery = {
  departmentsByUniqueCode?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DepartmentsByKeychainIDQueryVariables = {
  keychainID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDepartmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DepartmentsByKeychainIDQuery = {
  departmentsByKeychainID?:  {
    __typename: "ModelDepartmentConnection",
    items:  Array< {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMedicShiftQueryVariables = {
  id: string,
};

export type GetMedicShiftQuery = {
  getMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMedicShiftsQueryVariables = {
  filter?: ModelMedicShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicShiftsQuery = {
  listMedicShifts?:  {
    __typename: "ModelMedicShiftConnection",
    items:  Array< {
      __typename: "MedicShift",
      id: string,
      shiftStart: string,
      shiftEnd: string,
      completedLogs: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      subscribedDevices: Array< string >,
      invitedUsers:  Array< {
        __typename: "Invite",
        itemID: string,
        expiration: string,
        sentTime: string,
        title?: string | null,
        description?: string | null,
      } >,
      ownerID: string,
      departmentID: string,
      interactionID?: string | null,
      metaData?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMedicShiftsQueryVariables = {
  filter?: ModelMedicShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMedicShiftsQuery = {
  syncMedicShifts?:  {
    __typename: "ModelMedicShiftConnection",
    items:  Array< {
      __typename: "MedicShift",
      id: string,
      shiftStart: string,
      shiftEnd: string,
      completedLogs: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      subscribedDevices: Array< string >,
      invitedUsers:  Array< {
        __typename: "Invite",
        itemID: string,
        expiration: string,
        sentTime: string,
        title?: string | null,
        description?: string | null,
      } >,
      ownerID: string,
      departmentID: string,
      interactionID?: string | null,
      metaData?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicShiftsByOwnerIDQueryVariables = {
  ownerID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicShiftsByOwnerIDQuery = {
  medicShiftsByOwnerID?:  {
    __typename: "ModelMedicShiftConnection",
    items:  Array< {
      __typename: "MedicShift",
      id: string,
      shiftStart: string,
      shiftEnd: string,
      completedLogs: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      subscribedDevices: Array< string >,
      invitedUsers:  Array< {
        __typename: "Invite",
        itemID: string,
        expiration: string,
        sentTime: string,
        title?: string | null,
        description?: string | null,
      } >,
      ownerID: string,
      departmentID: string,
      interactionID?: string | null,
      metaData?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicShiftsByDepartmentIDQueryVariables = {
  departmentID: string,
  shiftStart?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicShiftsByDepartmentIDQuery = {
  medicShiftsByDepartmentID?:  {
    __typename: "ModelMedicShiftConnection",
    items:  Array< {
      __typename: "MedicShift",
      id: string,
      shiftStart: string,
      shiftEnd: string,
      completedLogs: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      subscribedDevices: Array< string >,
      invitedUsers:  Array< {
        __typename: "Invite",
        itemID: string,
        expiration: string,
        sentTime: string,
        title?: string | null,
        description?: string | null,
      } >,
      ownerID: string,
      departmentID: string,
      interactionID?: string | null,
      metaData?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicShiftsByInteractionIDQueryVariables = {
  interactionID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicShiftFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicShiftsByInteractionIDQuery = {
  medicShiftsByInteractionID?:  {
    __typename: "ModelMedicShiftConnection",
    items:  Array< {
      __typename: "MedicShift",
      id: string,
      shiftStart: string,
      shiftEnd: string,
      completedLogs: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      subscribedDevices: Array< string >,
      invitedUsers:  Array< {
        __typename: "Invite",
        itemID: string,
        expiration: string,
        sentTime: string,
        title?: string | null,
        description?: string | null,
      } >,
      ownerID: string,
      departmentID: string,
      interactionID?: string | null,
      metaData?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPatientInteractionQueryVariables = {
  id: string,
};

export type GetPatientInteractionQuery = {
  getPatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPatientInteractionsQueryVariables = {
  filter?: ModelPatientInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPatientInteractionsQuery = {
  listPatientInteractions?:  {
    __typename: "ModelPatientInteractionConnection",
    items:  Array< {
      __typename: "PatientInteraction",
      id: string,
      isLogActive: boolean,
      actualWeight?: number | null,
      ageValue?: number | null,
      startTime: string,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } >,
      taggedUsers: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      incidentNumber?: string | null,
      actualTime?: string | null,
      estimatedTime?: string | null,
      patientReleased?: string | null,
      ageTimestamp?: string | null,
      departmentID: string,
      subscribedUsers: Array< string >,
      subscribedDevices: Array< string >,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      shifts: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPatientInteractionsQueryVariables = {
  filter?: ModelPatientInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPatientInteractionsQuery = {
  syncPatientInteractions?:  {
    __typename: "ModelPatientInteractionConnection",
    items:  Array< {
      __typename: "PatientInteraction",
      id: string,
      isLogActive: boolean,
      actualWeight?: number | null,
      ageValue?: number | null,
      startTime: string,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } >,
      taggedUsers: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      incidentNumber?: string | null,
      actualTime?: string | null,
      estimatedTime?: string | null,
      patientReleased?: string | null,
      ageTimestamp?: string | null,
      departmentID: string,
      subscribedUsers: Array< string >,
      subscribedDevices: Array< string >,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      shifts: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PatientInteractionsByTaggedAmbulanceQueryVariables = {
  taggedAmbulance: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientInteractionsByTaggedAmbulanceQuery = {
  patientInteractionsByTaggedAmbulance?:  {
    __typename: "ModelPatientInteractionConnection",
    items:  Array< {
      __typename: "PatientInteraction",
      id: string,
      isLogActive: boolean,
      actualWeight?: number | null,
      ageValue?: number | null,
      startTime: string,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } >,
      taggedUsers: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      incidentNumber?: string | null,
      actualTime?: string | null,
      estimatedTime?: string | null,
      patientReleased?: string | null,
      ageTimestamp?: string | null,
      departmentID: string,
      subscribedUsers: Array< string >,
      subscribedDevices: Array< string >,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      shifts: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PatientInteractionsByIncidentNumberQueryVariables = {
  incidentNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PatientInteractionsByIncidentNumberQuery = {
  patientInteractionsByIncidentNumber?:  {
    __typename: "ModelPatientInteractionConnection",
    items:  Array< {
      __typename: "PatientInteraction",
      id: string,
      isLogActive: boolean,
      actualWeight?: number | null,
      ageValue?: number | null,
      startTime: string,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } >,
      taggedUsers: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      incidentNumber?: string | null,
      actualTime?: string | null,
      estimatedTime?: string | null,
      patientReleased?: string | null,
      ageTimestamp?: string | null,
      departmentID: string,
      subscribedUsers: Array< string >,
      subscribedDevices: Array< string >,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      shifts: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InteractionsByDepartmentIDQueryVariables = {
  departmentID: string,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPatientInteractionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InteractionsByDepartmentIDQuery = {
  interactionsByDepartmentID?:  {
    __typename: "ModelPatientInteractionConnection",
    items:  Array< {
      __typename: "PatientInteraction",
      id: string,
      isLogActive: boolean,
      actualWeight?: number | null,
      ageValue?: number | null,
      startTime: string,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } >,
      taggedUsers: Array< string >,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      incidentNumber?: string | null,
      actualTime?: string | null,
      estimatedTime?: string | null,
      patientReleased?: string | null,
      ageTimestamp?: string | null,
      departmentID: string,
      subscribedUsers: Array< string >,
      subscribedDevices: Array< string >,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      shifts: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetKeychainQueryVariables = {
  id: string,
};

export type GetKeychainQuery = {
  getKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListKeychainsQueryVariables = {
  filter?: ModelKeychainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKeychainsQuery = {
  listKeychains?:  {
    __typename: "ModelKeychainConnection",
    items:  Array< {
      __typename: "Keychain",
      id: string,
      name: string,
      hashedPin: string,
      saltPin: string,
      hashType: string,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?: string | null,
      departmentID: string,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncKeychainsQueryVariables = {
  filter?: ModelKeychainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncKeychainsQuery = {
  syncKeychains?:  {
    __typename: "ModelKeychainConnection",
    items:  Array< {
      __typename: "Keychain",
      id: string,
      name: string,
      hashedPin: string,
      saltPin: string,
      hashType: string,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?: string | null,
      departmentID: string,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type KeychainsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelKeychainFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type KeychainsByDepartmentIDQuery = {
  keychainsByDepartmentID?:  {
    __typename: "ModelKeychainConnection",
    items:  Array< {
      __typename: "Keychain",
      id: string,
      name: string,
      hashedPin: string,
      saltPin: string,
      hashType: string,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?: string | null,
      departmentID: string,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetContactQueryVariables = {
  id: string,
};

export type GetContactQuery = {
  getContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListContactsQuery = {
  listContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      fullName: string,
      title?: string | null,
      number: string,
      note?: string | null,
      index: number,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncContactsQueryVariables = {
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncContactsQuery = {
  syncContacts?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      fullName: string,
      title?: string | null,
      number: string,
      note?: string | null,
      index: number,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ContactsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelContactFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ContactsByDepartmentIDQuery = {
  contactsByDepartmentID?:  {
    __typename: "ModelContactConnection",
    items:  Array< {
      __typename: "Contact",
      id: string,
      fullName: string,
      title?: string | null,
      number: string,
      note?: string | null,
      index: number,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFormLogQueryVariables = {
  id: string,
};

export type GetFormLogQuery = {
  getFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFormLogsQueryVariables = {
  filter?: ModelFormLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormLogsQuery = {
  listFormLogs?:  {
    __typename: "ModelFormLogConnection",
    items:  Array< {
      __typename: "FormLog",
      id: string,
      responses?:  Array< {
        __typename: "FormResponse",
        questionIndex: number,
        type: FormType,
        responses: Array< string >,
      } > | null,
      inputformID: string,
      departmentID: string,
      userID: string,
      pairedDepID?: string | null,
      pairedUserIDs?: Array< string > | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFormLogsQueryVariables = {
  filter?: ModelFormLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFormLogsQuery = {
  syncFormLogs?:  {
    __typename: "ModelFormLogConnection",
    items:  Array< {
      __typename: "FormLog",
      id: string,
      responses?:  Array< {
        __typename: "FormResponse",
        questionIndex: number,
        type: FormType,
        responses: Array< string >,
      } > | null,
      inputformID: string,
      departmentID: string,
      userID: string,
      pairedDepID?: string | null,
      pairedUserIDs?: Array< string > | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FormLogsByInputformIDQueryVariables = {
  inputformID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormLogsByInputformIDQuery = {
  formLogsByInputformID?:  {
    __typename: "ModelFormLogConnection",
    items:  Array< {
      __typename: "FormLog",
      id: string,
      responses?:  Array< {
        __typename: "FormResponse",
        questionIndex: number,
        type: FormType,
        responses: Array< string >,
      } > | null,
      inputformID: string,
      departmentID: string,
      userID: string,
      pairedDepID?: string | null,
      pairedUserIDs?: Array< string > | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FormLogsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormLogsByDepartmentIDQuery = {
  formLogsByDepartmentID?:  {
    __typename: "ModelFormLogConnection",
    items:  Array< {
      __typename: "FormLog",
      id: string,
      responses?:  Array< {
        __typename: "FormResponse",
        questionIndex: number,
        type: FormType,
        responses: Array< string >,
      } > | null,
      inputformID: string,
      departmentID: string,
      userID: string,
      pairedDepID?: string | null,
      pairedUserIDs?: Array< string > | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FormLogsByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormLogsByUserIDQuery = {
  formLogsByUserID?:  {
    __typename: "ModelFormLogConnection",
    items:  Array< {
      __typename: "FormLog",
      id: string,
      responses?:  Array< {
        __typename: "FormResponse",
        questionIndex: number,
        type: FormType,
        responses: Array< string >,
      } > | null,
      inputformID: string,
      departmentID: string,
      userID: string,
      pairedDepID?: string | null,
      pairedUserIDs?: Array< string > | null,
      notes?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInputFormQueryVariables = {
  id: string,
};

export type GetInputFormQuery = {
  getInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInputFormsQueryVariables = {
  filter?: ModelInputFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInputFormsQuery = {
  listInputForms?:  {
    __typename: "ModelInputFormConnection",
    items:  Array< {
      __typename: "InputForm",
      id: string,
      name: string,
      items:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInputFormsQueryVariables = {
  filter?: ModelInputFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInputFormsQuery = {
  syncInputForms?:  {
    __typename: "ModelInputFormConnection",
    items:  Array< {
      __typename: "InputForm",
      id: string,
      name: string,
      items:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InputFormsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInputFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InputFormsByDepartmentIDQuery = {
  inputFormsByDepartmentID?:  {
    __typename: "ModelInputFormConnection",
    items:  Array< {
      __typename: "InputForm",
      id: string,
      name: string,
      items:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotificationsQuery = {
  syncNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NotificationsByDepartmentIDQueryVariables = {
  departmentID: string,
  timestamp?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByDepartmentIDQuery = {
  notificationsByDepartmentID?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetNotifyACKQueryVariables = {
  id: string,
};

export type GetNotifyACKQuery = {
  getNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListNotifyACKSQueryVariables = {
  filter?: ModelNotifyACKFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotifyACKSQuery = {
  listNotifyACKS?:  {
    __typename: "ModelNotifyACKConnection",
    items:  Array< {
      __typename: "NotifyACK",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncNotifyACKSQueryVariables = {
  filter?: ModelNotifyACKFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncNotifyACKSQuery = {
  syncNotifyACKS?:  {
    __typename: "ModelNotifyACKConnection",
    items:  Array< {
      __typename: "NotifyACK",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NotifyACKsByOwnerIDQueryVariables = {
  ownerID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotifyACKFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotifyACKsByOwnerIDQuery = {
  notifyACKsByOwnerID?:  {
    __typename: "ModelNotifyACKConnection",
    items:  Array< {
      __typename: "NotifyACK",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type NotifyACKsByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotifyACKFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotifyACKsByUserIDQuery = {
  notifyACKsByUserID?:  {
    __typename: "ModelNotifyACKConnection",
    items:  Array< {
      __typename: "NotifyACK",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupQueryVariables = {
  id: string,
};

export type GetGroupQuery = {
  getGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupsQuery = {
  listGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupsQueryVariables = {
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupsQuery = {
  syncGroups?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GroupsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupsByDepartmentIDQuery = {
  groupsByDepartmentID?:  {
    __typename: "ModelGroupConnection",
    items:  Array< {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDripQueryVariables = {
  id: string,
};

export type GetDripQuery = {
  getDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDripsQueryVariables = {
  filter?: ModelDripFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDripsQuery = {
  listDrips?:  {
    __typename: "ModelDripConnection",
    items:  Array< {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDripsQueryVariables = {
  filter?: ModelDripFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDripsQuery = {
  syncDrips?:  {
    __typename: "ModelDripConnection",
    items:  Array< {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DripsByRxNormCodeQueryVariables = {
  rxNormCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDripFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DripsByRxNormCodeQuery = {
  dripsByRxNormCode?:  {
    __typename: "ModelDripConnection",
    items:  Array< {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DripsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDripFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DripsByDepartmentIDQuery = {
  dripsByDepartmentID?:  {
    __typename: "ModelDripConnection",
    items:  Array< {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetInfusionDoseQueryVariables = {
  id: string,
};

export type GetInfusionDoseQuery = {
  getInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListInfusionDosesQueryVariables = {
  filter?: ModelInfusionDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInfusionDosesQuery = {
  listInfusionDoses?:  {
    __typename: "ModelInfusionDoseConnection",
    items:  Array< {
      __typename: "InfusionDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      dripID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Drip?:  {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncInfusionDosesQueryVariables = {
  filter?: ModelInfusionDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncInfusionDosesQuery = {
  syncInfusionDoses?:  {
    __typename: "ModelInfusionDoseConnection",
    items:  Array< {
      __typename: "InfusionDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      dripID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Drip?:  {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InfusionDosesByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInfusionDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InfusionDosesByDepartmentIDQuery = {
  infusionDosesByDepartmentID?:  {
    __typename: "ModelInfusionDoseConnection",
    items:  Array< {
      __typename: "InfusionDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      dripID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Drip?:  {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InfusionDosesByProtocolIDQueryVariables = {
  protocolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInfusionDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InfusionDosesByProtocolIDQuery = {
  infusionDosesByProtocolID?:  {
    __typename: "ModelInfusionDoseConnection",
    items:  Array< {
      __typename: "InfusionDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      dripID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Drip?:  {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type InfusionDosesByDripIDQueryVariables = {
  dripID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInfusionDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InfusionDosesByDripIDQuery = {
  infusionDosesByDripID?:  {
    __typename: "ModelInfusionDoseConnection",
    items:  Array< {
      __typename: "InfusionDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      dripID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Drip?:  {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetEquipmentQueryVariables = {
  id: string,
};

export type GetEquipmentQuery = {
  getEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListEquipmentQueryVariables = {
  filter?: ModelEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEquipmentQuery = {
  listEquipment?:  {
    __typename: "ModelEquipmentConnection",
    items:  Array< {
      __typename: "Equipment",
      id: string,
      name: string,
      snomedCode?: string | null,
      optionItems:  Array< {
        __typename: "EquipmentOption",
        amnt: string,
        amntHigh?: string | null,
        rangeLow: number,
        rangeHigh?: number | null,
      } >,
      departmentID: string,
      title?: string | null,
      note?: string | null,
      warning?: string | null,
      instruction?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncEquipmentQueryVariables = {
  filter?: ModelEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncEquipmentQuery = {
  syncEquipment?:  {
    __typename: "ModelEquipmentConnection",
    items:  Array< {
      __typename: "Equipment",
      id: string,
      name: string,
      snomedCode?: string | null,
      optionItems:  Array< {
        __typename: "EquipmentOption",
        amnt: string,
        amntHigh?: string | null,
        rangeLow: number,
        rangeHigh?: number | null,
      } >,
      departmentID: string,
      title?: string | null,
      note?: string | null,
      warning?: string | null,
      instruction?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EquipmentBySnomedCodeQueryVariables = {
  snomedCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EquipmentBySnomedCodeQuery = {
  equipmentBySnomedCode?:  {
    __typename: "ModelEquipmentConnection",
    items:  Array< {
      __typename: "Equipment",
      id: string,
      name: string,
      snomedCode?: string | null,
      optionItems:  Array< {
        __typename: "EquipmentOption",
        amnt: string,
        amntHigh?: string | null,
        rangeLow: number,
        rangeHigh?: number | null,
      } >,
      departmentID: string,
      title?: string | null,
      note?: string | null,
      warning?: string | null,
      instruction?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type EquipmentByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelEquipmentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type EquipmentByDepartmentIDQuery = {
  equipmentByDepartmentID?:  {
    __typename: "ModelEquipmentConnection",
    items:  Array< {
      __typename: "Equipment",
      id: string,
      name: string,
      snomedCode?: string | null,
      optionItems:  Array< {
        __typename: "EquipmentOption",
        amnt: string,
        amntHigh?: string | null,
        rangeLow: number,
        rangeHigh?: number | null,
      } >,
      departmentID: string,
      title?: string | null,
      note?: string | null,
      warning?: string | null,
      instruction?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMedicationQueryVariables = {
  id: string,
};

export type GetMedicationQuery = {
  getMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMedicationsQueryVariables = {
  filter?: ModelMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicationsQuery = {
  listMedications?:  {
    __typename: "ModelMedicationConnection",
    items:  Array< {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMedicationsQueryVariables = {
  filter?: ModelMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMedicationsQuery = {
  syncMedications?:  {
    __typename: "ModelMedicationConnection",
    items:  Array< {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationsByRxNormCodeQueryVariables = {
  rxNormCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationsByRxNormCodeQuery = {
  medicationsByRxNormCode?:  {
    __typename: "ModelMedicationConnection",
    items:  Array< {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationsByDepartmentIDQuery = {
  medicationsByDepartmentID?:  {
    __typename: "ModelMedicationConnection",
    items:  Array< {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMedicationDoseQueryVariables = {
  id: string,
};

export type GetMedicationDoseQuery = {
  getMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMedicationDosesQueryVariables = {
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMedicationDosesQuery = {
  listMedicationDoses?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMedicationDosesQueryVariables = {
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMedicationDosesQuery = {
  syncMedicationDoses?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationDosesByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationDosesByDepartmentIDQuery = {
  medicationDosesByDepartmentID?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationDosesByProtocolIDQueryVariables = {
  protocolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationDosesByProtocolIDQuery = {
  medicationDosesByProtocolID?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationDosesByMedicationIDQueryVariables = {
  medicationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationDosesByMedicationIDQuery = {
  medicationDosesByMedicationID?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MedicationDosesByCprAssistIDQueryVariables = {
  cprAssistID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMedicationDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MedicationDosesByCprAssistIDQuery = {
  medicationDosesByCprAssistID?:  {
    __typename: "ModelMedicationDoseConnection",
    items:  Array< {
      __typename: "MedicationDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      route: Array< string >,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      medicationID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Medication?:  {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetProtocolQueryVariables = {
  id: string,
};

export type GetProtocolQuery = {
  getProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListProtocolsQueryVariables = {
  filter?: ModelProtocolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProtocolsQuery = {
  listProtocols?:  {
    __typename: "ModelProtocolConnection",
    items:  Array< {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncProtocolsQueryVariables = {
  filter?: ModelProtocolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncProtocolsQuery = {
  syncProtocols?:  {
    __typename: "ModelProtocolConnection",
    items:  Array< {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolsByCategoryIDQueryVariables = {
  categoryID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolsByCategoryIDQuery = {
  protocolsByCategoryID?:  {
    __typename: "ModelProtocolConnection",
    items:  Array< {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolsByKeychainIDQueryVariables = {
  keychainID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolsByKeychainIDQuery = {
  protocolsByKeychainID?:  {
    __typename: "ModelProtocolConnection",
    items:  Array< {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ProtocolsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProtocolFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProtocolsByDepartmentIDQuery = {
  protocolsByDepartmentID?:  {
    __typename: "ModelProtocolConnection",
    items:  Array< {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAmbulanceQueryVariables = {
  id: string,
};

export type GetAmbulanceQuery = {
  getAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAmbulancesQueryVariables = {
  filter?: ModelAmbulanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAmbulancesQuery = {
  listAmbulances?:  {
    __typename: "ModelAmbulanceConnection",
    items:  Array< {
      __typename: "Ambulance",
      id: string,
      name: string,
      departmentID: string,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAmbulancesQueryVariables = {
  filter?: ModelAmbulanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAmbulancesQuery = {
  syncAmbulances?:  {
    __typename: "ModelAmbulanceConnection",
    items:  Array< {
      __typename: "Ambulance",
      id: string,
      name: string,
      departmentID: string,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AmbulancesByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAmbulanceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AmbulancesByDepartmentIDQuery = {
  ambulancesByDepartmentID?:  {
    __typename: "ModelAmbulanceConnection",
    items:  Array< {
      __typename: "Ambulance",
      id: string,
      name: string,
      departmentID: string,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UsersByCognitoIDQueryVariables = {
  cognitoID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByCognitoIDQuery = {
  usersByCognitoID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type UsersByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByDepartmentIDQuery = {
  usersByDepartmentID?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      index: number,
      pairedDepIDs?: Array< string > | null,
      parentCategoryID?: string | null,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      overrideID?: string | null,
      keychainID?: string | null,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      index: number,
      pairedDepIDs?: Array< string > | null,
      parentCategoryID?: string | null,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      overrideID?: string | null,
      keychainID?: string | null,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoriesByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByDepartmentIDQuery = {
  categoriesByDepartmentID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      index: number,
      pairedDepIDs?: Array< string > | null,
      parentCategoryID?: string | null,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      overrideID?: string | null,
      keychainID?: string | null,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoriesByKeychainIDQueryVariables = {
  keychainID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByKeychainIDQuery = {
  categoriesByKeychainID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      index: number,
      pairedDepIDs?: Array< string > | null,
      parentCategoryID?: string | null,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      overrideID?: string | null,
      keychainID?: string | null,
      Protocols?:  {
        __typename: "ModelProtocolConnection",
        items:  Array< {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentCategoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetElectricalQueryVariables = {
  id: string,
};

export type GetElectricalQuery = {
  getElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListElectricalsQueryVariables = {
  filter?: ModelElectricalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListElectricalsQuery = {
  listElectricals?:  {
    __typename: "ModelElectricalConnection",
    items:  Array< {
      __typename: "Electrical",
      id: string,
      title: string,
      options:  Array< {
        __typename: "ElectricalOption",
        title: string,
        ranges:  Array< {
          __typename: "ElectricalRange",
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
        } >,
      } >,
      departmentID: string,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncElectricalsQueryVariables = {
  filter?: ModelElectricalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncElectricalsQuery = {
  syncElectricals?:  {
    __typename: "ModelElectricalConnection",
    items:  Array< {
      __typename: "Electrical",
      id: string,
      title: string,
      options:  Array< {
        __typename: "ElectricalOption",
        title: string,
        ranges:  Array< {
          __typename: "ElectricalRange",
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
        } >,
      } >,
      departmentID: string,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalsByDepartmentIDQuery = {
  electricalsByDepartmentID?:  {
    __typename: "ModelElectricalConnection",
    items:  Array< {
      __typename: "Electrical",
      id: string,
      title: string,
      options:  Array< {
        __typename: "ElectricalOption",
        title: string,
        ranges:  Array< {
          __typename: "ElectricalRange",
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
        } >,
      } >,
      departmentID: string,
      activeID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetElectricalShockQueryVariables = {
  id: string,
};

export type GetElectricalShockQuery = {
  getElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListElectricalShocksQueryVariables = {
  filter?: ModelElectricalShockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListElectricalShocksQuery = {
  listElectricalShocks?:  {
    __typename: "ModelElectricalShockConnection",
    items:  Array< {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncElectricalShocksQueryVariables = {
  filter?: ModelElectricalShockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncElectricalShocksQuery = {
  syncElectricalShocks?:  {
    __typename: "ModelElectricalShockConnection",
    items:  Array< {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalShocksByRxNormCodeQueryVariables = {
  rxNormCode: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalShockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalShocksByRxNormCodeQuery = {
  electricalShocksByRxNormCode?:  {
    __typename: "ModelElectricalShockConnection",
    items:  Array< {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalShocksByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalShockFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalShocksByDepartmentIDQuery = {
  electricalShocksByDepartmentID?:  {
    __typename: "ModelElectricalShockConnection",
    items:  Array< {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetElectricalDoseQueryVariables = {
  id: string,
};

export type GetElectricalDoseQuery = {
  getElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListElectricalDosesQueryVariables = {
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListElectricalDosesQuery = {
  listElectricalDoses?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncElectricalDosesQueryVariables = {
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncElectricalDosesQuery = {
  syncElectricalDoses?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalDosesByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalDosesByDepartmentIDQuery = {
  electricalDosesByDepartmentID?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalDosesByProtocolIDQueryVariables = {
  protocolID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalDosesByProtocolIDQuery = {
  electricalDosesByProtocolID?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalDosesByElectricalIDQueryVariables = {
  electricalID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalDosesByElectricalIDQuery = {
  electricalDosesByElectricalID?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ElectricalDosesByCprAssistIDQueryVariables = {
  cprAssistID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelElectricalDoseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ElectricalDosesByCprAssistIDQuery = {
  electricalDosesByCprAssistID?:  {
    __typename: "ModelElectricalDoseConnection",
    items:  Array< {
      __typename: "ElectricalDose",
      id: string,
      basis: string,
      rangeLow: number,
      rangeHigh?: number | null,
      title?: string | null,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      maxDose?: string | null,
      minDose?: string | null,
      maxTotalDose?: string | null,
      calcMax?: string | null,
      calcMin?: string | null,
      index: number,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      repeatTime?: string | null,
      incrementAmnt?: number | null,
      departmentID: string,
      protocolID?: string | null,
      electricalID: string,
      cprAssistID?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      Protocol?:  {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Electrical?:  {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      CPRAssist?:  {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDeveloperNotificationQueryVariables = {
  id: string,
};

export type GetDeveloperNotificationQuery = {
  getDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDeveloperNotificationsQueryVariables = {
  filter?: ModelDeveloperNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDeveloperNotificationsQuery = {
  listDeveloperNotifications?:  {
    __typename: "ModelDeveloperNotificationConnection",
    items:  Array< {
      __typename: "DeveloperNotification",
      id: string,
      title: string,
      message?: string | null,
      targetVersion?: string | null,
      additionalData?: string | null,
      type: DevNotifyType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDeveloperNotificationsQueryVariables = {
  filter?: ModelDeveloperNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDeveloperNotificationsQuery = {
  syncDeveloperNotifications?:  {
    __typename: "ModelDeveloperNotificationConnection",
    items:  Array< {
      __typename: "DeveloperNotification",
      id: string,
      title: string,
      message?: string | null,
      targetVersion?: string | null,
      additionalData?: string | null,
      type: DevNotifyType,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetVitalsQueryVariables = {
  id: string,
};

export type GetVitalsQuery = {
  getVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListVitalsQueryVariables = {
  filter?: ModelVitalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVitalsQuery = {
  listVitals?:  {
    __typename: "ModelVitalsConnection",
    items:  Array< {
      __typename: "Vitals",
      id: string,
      title: string,
      options:  Array< {
        __typename: "VitalOption",
        amntLow: string,
        amntHigh: string,
        rangeLow: number,
        rangeHigh: number,
      } >,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncVitalsQueryVariables = {
  filter?: ModelVitalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncVitalsQuery = {
  syncVitals?:  {
    __typename: "ModelVitalsConnection",
    items:  Array< {
      __typename: "Vitals",
      id: string,
      title: string,
      options:  Array< {
        __typename: "VitalOption",
        amntLow: string,
        amntHigh: string,
        rangeLow: number,
        rangeHigh: number,
      } >,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type VitalsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVitalsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VitalsByDepartmentIDQuery = {
  vitalsByDepartmentID?:  {
    __typename: "ModelVitalsConnection",
    items:  Array< {
      __typename: "Vitals",
      id: string,
      title: string,
      options:  Array< {
        __typename: "VitalOption",
        amntLow: string,
        amntHigh: string,
        rangeLow: number,
        rangeHigh: number,
      } >,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOneWeightQueryVariables = {
  id: string,
};

export type GetOneWeightQuery = {
  getOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type ListOneWeightsQueryVariables = {
  filter?: ModelOneWeightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOneWeightsQuery = {
  listOneWeights?:  {
    __typename: "ModelOneWeightConnection",
    items:  Array< {
      __typename: "OneWeight",
      id: string,
      name: string,
      serialNumber: string,
      projectNumber: string,
      lastCalibration: string,
      type?: PWSType | null,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentOneWeightsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOneWeightsQueryVariables = {
  filter?: ModelOneWeightFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOneWeightsQuery = {
  syncOneWeights?:  {
    __typename: "ModelOneWeightConnection",
    items:  Array< {
      __typename: "OneWeight",
      id: string,
      name: string,
      serialNumber: string,
      projectNumber: string,
      lastCalibration: string,
      type?: PWSType | null,
      departmentID: string,
      department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentOneWeightsId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFormQueryVariables = {
  id: string,
};

export type GetFormQuery = {
  getForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFormsQueryVariables = {
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFormsQuery = {
  listForms?:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      name: string,
      departmentID: string,
      items:  Array< {
        __typename: "FormGroup",
        title: string,
        options:  Array< {
          __typename: "FormOption",
          firstItem: string,
          secItem?: string | null,
        } >,
        isUrgent: boolean,
        isDouble: boolean,
      } >,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFormsQueryVariables = {
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFormsQuery = {
  syncForms?:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      name: string,
      departmentID: string,
      items:  Array< {
        __typename: "FormGroup",
        title: string,
        options:  Array< {
          __typename: "FormOption",
          firstItem: string,
          secItem?: string | null,
        } >,
        isUrgent: boolean,
        isDouble: boolean,
      } >,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type FormsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFormFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FormsByDepartmentIDQuery = {
  formsByDepartmentID?:  {
    __typename: "ModelFormConnection",
    items:  Array< {
      __typename: "Form",
      id: string,
      name: string,
      departmentID: string,
      items:  Array< {
        __typename: "FormGroup",
        title: string,
        options:  Array< {
          __typename: "FormOption",
          firstItem: string,
          secItem?: string | null,
        } >,
        isUrgent: boolean,
        isDouble: boolean,
      } >,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLogQueryVariables = {
  id: string,
};

export type GetLogQuery = {
  getLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLogsQueryVariables = {
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLogsQuery = {
  listLogs?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLogsQueryVariables = {
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLogsQuery = {
  syncLogs?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogsByDepartmentAndEndTimeQueryVariables = {
  departmentID: string,
  endTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogsByDepartmentAndEndTimeQuery = {
  logsByDepartmentAndEndTime?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogsByOneweightIDQueryVariables = {
  oneweightID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogsByOneweightIDQuery = {
  logsByOneweightID?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogsByAmbulanceIDQueryVariables = {
  ambulanceID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogsByAmbulanceIDQuery = {
  logsByAmbulanceID?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogsByIncidentNumberQueryVariables = {
  incidentNumber: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogsByIncidentNumberQuery = {
  logsByIncidentNumber?:  {
    __typename: "ModelLogConnection",
    items:  Array< {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetRequireSyncQueryVariables = {
  id: string,
};

export type GetRequireSyncQuery = {
  getRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListRequireSyncsQueryVariables = {
  filter?: ModelRequireSyncFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRequireSyncsQuery = {
  listRequireSyncs?:  {
    __typename: "ModelRequireSyncConnection",
    items:  Array< {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncRequireSyncsQueryVariables = {
  filter?: ModelRequireSyncFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncRequireSyncsQuery = {
  syncRequireSyncs?:  {
    __typename: "ModelRequireSyncConnection",
    items:  Array< {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetHardwareVersionQueryVariables = {
  id: string,
};

export type GetHardwareVersionQuery = {
  getHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListHardwareVersionsQueryVariables = {
  filter?: ModelHardwareVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHardwareVersionsQuery = {
  listHardwareVersions?:  {
    __typename: "ModelHardwareVersionConnection",
    items:  Array< {
      __typename: "HardwareVersion",
      id: string,
      version: string,
      name: string,
      description: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncHardwareVersionsQueryVariables = {
  filter?: ModelHardwareVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncHardwareVersionsQuery = {
  syncHardwareVersions?:  {
    __typename: "ModelHardwareVersionConnection",
    items:  Array< {
      __typename: "HardwareVersion",
      id: string,
      version: string,
      name: string,
      description: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFirmwareVersionQueryVariables = {
  id: string,
};

export type GetFirmwareVersionQuery = {
  getFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFirmwareVersionsQueryVariables = {
  filter?: ModelFirmwareVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFirmwareVersionsQuery = {
  listFirmwareVersions?:  {
    __typename: "ModelFirmwareVersionConnection",
    items:  Array< {
      __typename: "FirmwareVersion",
      id: string,
      name: string,
      version: string,
      fileURL: string,
      description: string,
      cover_image?: string | null,
      compatibleHW: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFirmwareVersionsQueryVariables = {
  filter?: ModelFirmwareVersionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFirmwareVersionsQuery = {
  syncFirmwareVersions?:  {
    __typename: "ModelFirmwareVersionConnection",
    items:  Array< {
      __typename: "FirmwareVersion",
      id: string,
      name: string,
      version: string,
      fileURL: string,
      description: string,
      cover_image?: string | null,
      compatibleHW: Array< string >,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWorkbookQueryVariables = {
  id: string,
};

export type GetWorkbookQuery = {
  getWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWorkbooksQueryVariables = {
  filter?: ModelWorkbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkbooksQuery = {
  listWorkbooks?:  {
    __typename: "ModelWorkbookConnection",
    items:  Array< {
      __typename: "Workbook",
      id: string,
      name: string,
      workbookID: string,
      fileSize: number,
      aiPdfParserResults?: string | null,
      activeID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWorkbooksQueryVariables = {
  filter?: ModelWorkbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWorkbooksQuery = {
  syncWorkbooks?:  {
    __typename: "ModelWorkbookConnection",
    items:  Array< {
      __typename: "Workbook",
      id: string,
      name: string,
      workbookID: string,
      fileSize: number,
      aiPdfParserResults?: string | null,
      activeID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type WorkbooksByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWorkbookFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WorkbooksByDepartmentIDQuery = {
  workbooksByDepartmentID?:  {
    __typename: "ModelWorkbookConnection",
    items:  Array< {
      __typename: "Workbook",
      id: string,
      name: string,
      workbookID: string,
      fileSize: number,
      aiPdfParserResults?: string | null,
      activeID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetWeightObjectQueryVariables = {
  id: string,
};

export type GetWeightObjectQuery = {
  getWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListWeightObjectsQueryVariables = {
  filter?: ModelWeightObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWeightObjectsQuery = {
  listWeightObjects?:  {
    __typename: "ModelWeightObjectConnection",
    items:  Array< {
      __typename: "WeightObject",
      id: string,
      name: string,
      weight: number,
      createdBy: string,
      modifiedBy?: string | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncWeightObjectsQueryVariables = {
  filter?: ModelWeightObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncWeightObjectsQuery = {
  syncWeightObjects?:  {
    __typename: "ModelWeightObjectConnection",
    items:  Array< {
      __typename: "WeightObject",
      id: string,
      name: string,
      weight: number,
      createdBy: string,
      modifiedBy?: string | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type WeightObjectsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelWeightObjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type WeightObjectsByDepartmentIDQuery = {
  weightObjectsByDepartmentID?:  {
    __typename: "ModelWeightObjectConnection",
    items:  Array< {
      __typename: "WeightObject",
      id: string,
      name: string,
      weight: number,
      createdBy: string,
      modifiedBy?: string | null,
      version?: string | null,
      pairedDepIDs?: Array< string > | null,
      departmentID: string,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConcentrationQueryVariables = {
  id: string,
};

export type GetConcentrationQuery = {
  getConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConcentrationsQueryVariables = {
  filter?: ModelConcentrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConcentrationsQuery = {
  listConcentrations?:  {
    __typename: "ModelConcentrationConnection",
    items:  Array< {
      __typename: "Concentration",
      id: string,
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
      departmentID: string,
      medicationID?: string | null,
      dripID?: string | null,
      status?: ProgressStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConcentrationsQueryVariables = {
  filter?: ModelConcentrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConcentrationsQuery = {
  syncConcentrations?:  {
    __typename: "ModelConcentrationConnection",
    items:  Array< {
      __typename: "Concentration",
      id: string,
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
      departmentID: string,
      medicationID?: string | null,
      dripID?: string | null,
      status?: ProgressStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConcentrationsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConcentrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConcentrationsByDepartmentIDQuery = {
  concentrationsByDepartmentID?:  {
    __typename: "ModelConcentrationConnection",
    items:  Array< {
      __typename: "Concentration",
      id: string,
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
      departmentID: string,
      medicationID?: string | null,
      dripID?: string | null,
      status?: ProgressStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConcentrationsByMedicationIDQueryVariables = {
  medicationID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConcentrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConcentrationsByMedicationIDQuery = {
  concentrationsByMedicationID?:  {
    __typename: "ModelConcentrationConnection",
    items:  Array< {
      __typename: "Concentration",
      id: string,
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
      departmentID: string,
      medicationID?: string | null,
      dripID?: string | null,
      status?: ProgressStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConcentrationsByDripIDQueryVariables = {
  dripID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConcentrationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConcentrationsByDripIDQuery = {
  concentrationsByDripID?:  {
    __typename: "ModelConcentrationConnection",
    items:  Array< {
      __typename: "Concentration",
      id: string,
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
      departmentID: string,
      medicationID?: string | null,
      dripID?: string | null,
      status?: ProgressStatus | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCPRAssistQueryVariables = {
  id: string,
};

export type GetCPRAssistQuery = {
  getCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCPRAssistsQueryVariables = {
  filter?: ModelCPRAssistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCPRAssistsQuery = {
  listCPRAssists?:  {
    __typename: "ModelCPRAssistConnection",
    items:  Array< {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCPRAssistsQueryVariables = {
  filter?: ModelCPRAssistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCPRAssistsQuery = {
  syncCPRAssists?:  {
    __typename: "ModelCPRAssistConnection",
    items:  Array< {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CPRAssistsByDepartmentIDQueryVariables = {
  departmentID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCPRAssistFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CPRAssistsByDepartmentIDQuery = {
  cPRAssistsByDepartmentID?:  {
    __typename: "ModelCPRAssistConnection",
    items:  Array< {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetReviewalQueryVariables = {
  id: string,
};

export type GetReviewalQuery = {
  getReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReviewalsQueryVariables = {
  filter?: ModelReviewalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewalsQuery = {
  listReviewals?:  {
    __typename: "ModelReviewalConnection",
    items:  Array< {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReviewalsQueryVariables = {
  filter?: ModelReviewalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReviewalsQuery = {
  syncReviewals?:  {
    __typename: "ModelReviewalConnection",
    items:  Array< {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReviewalsByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewalsByDepartmentIDQuery = {
  reviewalsByDepartmentID?:  {
    __typename: "ModelReviewalConnection",
    items:  Array< {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReviewalsByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewalsByUserIDQuery = {
  reviewalsByUserID?:  {
    __typename: "ModelReviewalConnection",
    items:  Array< {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDraftGroupQueryVariables = {
  id: string,
};

export type GetDraftGroupQuery = {
  getDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDraftGroupsQueryVariables = {
  filter?: ModelDraftGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDraftGroupsQuery = {
  listDraftGroups?:  {
    __typename: "ModelDraftGroupConnection",
    items:  Array< {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDraftGroupsQueryVariables = {
  filter?: ModelDraftGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDraftGroupsQuery = {
  syncDraftGroups?:  {
    __typename: "ModelDraftGroupConnection",
    items:  Array< {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DraftGroupsByReviewalIDQueryVariables = {
  reviewalID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DraftGroupsByReviewalIDQuery = {
  draftGroupsByReviewalID?:  {
    __typename: "ModelDraftGroupConnection",
    items:  Array< {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DraftGroupsByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DraftGroupsByDepartmentIDQuery = {
  draftGroupsByDepartmentID?:  {
    __typename: "ModelDraftGroupConnection",
    items:  Array< {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDraftChangeQueryVariables = {
  id: string,
};

export type GetDraftChangeQuery = {
  getDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDraftChangesQueryVariables = {
  filter?: ModelDraftChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDraftChangesQuery = {
  listDraftChanges?:  {
    __typename: "ModelDraftChangeConnection",
    items:  Array< {
      __typename: "DraftChange",
      id: string,
      draftGroupID: string,
      departmentID: string,
      changeID: string,
      previousID?: string | null,
      changeType: string,
      isClosed: boolean,
      createdAt: string,
      DraftGroup?:  {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDraftChangesQueryVariables = {
  filter?: ModelDraftChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDraftChangesQuery = {
  syncDraftChanges?:  {
    __typename: "ModelDraftChangeConnection",
    items:  Array< {
      __typename: "DraftChange",
      id: string,
      draftGroupID: string,
      departmentID: string,
      changeID: string,
      previousID?: string | null,
      changeType: string,
      isClosed: boolean,
      createdAt: string,
      DraftGroup?:  {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DraftChangesByDraftGroupIDQueryVariables = {
  draftGroupID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DraftChangesByDraftGroupIDQuery = {
  draftChangesByDraftGroupID?:  {
    __typename: "ModelDraftChangeConnection",
    items:  Array< {
      __typename: "DraftChange",
      id: string,
      draftGroupID: string,
      departmentID: string,
      changeID: string,
      previousID?: string | null,
      changeType: string,
      isClosed: boolean,
      createdAt: string,
      DraftGroup?:  {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DraftChangesByDepartmentIDQueryVariables = {
  departmentID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DraftChangesByDepartmentIDQuery = {
  draftChangesByDepartmentID?:  {
    __typename: "ModelDraftChangeConnection",
    items:  Array< {
      __typename: "DraftChange",
      id: string,
      draftGroupID: string,
      departmentID: string,
      changeID: string,
      previousID?: string | null,
      changeType: string,
      isClosed: boolean,
      createdAt: string,
      DraftGroup?:  {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type DraftChangesByChangeIDQueryVariables = {
  changeID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDraftChangeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DraftChangesByChangeIDQuery = {
  draftChangesByChangeID?:  {
    __typename: "ModelDraftChangeConnection",
    items:  Array< {
      __typename: "DraftChange",
      id: string,
      draftGroupID: string,
      departmentID: string,
      changeID: string,
      previousID?: string | null,
      changeType: string,
      isClosed: boolean,
      createdAt: string,
      DraftGroup?:  {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAcknowledgeQueryVariables = {
  id: string,
};

export type GetAcknowledgeQuery = {
  getAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAcknowledgesQueryVariables = {
  filter?: ModelAcknowledgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAcknowledgesQuery = {
  listAcknowledges?:  {
    __typename: "ModelAcknowledgeConnection",
    items:  Array< {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAcknowledgesQueryVariables = {
  filter?: ModelAcknowledgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAcknowledgesQuery = {
  syncAcknowledges?:  {
    __typename: "ModelAcknowledgeConnection",
    items:  Array< {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AcknowledgesByOwnerIDQueryVariables = {
  ownerID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAcknowledgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AcknowledgesByOwnerIDQuery = {
  acknowledgesByOwnerID?:  {
    __typename: "ModelAcknowledgeConnection",
    items:  Array< {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type AcknowledgesByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAcknowledgeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AcknowledgesByUserIDQuery = {
  acknowledgesByUserID?:  {
    __typename: "ModelAcknowledgeConnection",
    items:  Array< {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserCommentQueryVariables = {
  id: string,
};

export type GetUserCommentQuery = {
  getUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserCommentsQueryVariables = {
  filter?: ModelUserCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCommentsQuery = {
  listUserComments?:  {
    __typename: "ModelUserCommentConnection",
    items:  Array< {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserCommentsQueryVariables = {
  filter?: ModelUserCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserCommentsQuery = {
  syncUserComments?:  {
    __typename: "ModelUserCommentConnection",
    items:  Array< {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CommentsByOwnerIDQueryVariables = {
  ownerID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByOwnerIDQuery = {
  commentsByOwnerID?:  {
    __typename: "ModelUserCommentConnection",
    items:  Array< {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CommentsByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByUserIDQuery = {
  commentsByUserID?:  {
    __typename: "ModelUserCommentConnection",
    items:  Array< {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetGroupNotificationQueryVariables = {
  id: string,
};

export type GetGroupNotificationQuery = {
  getGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListGroupNotificationsQueryVariables = {
  filter?: ModelGroupNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGroupNotificationsQuery = {
  listGroupNotifications?:  {
    __typename: "ModelGroupNotificationConnection",
    items:  Array< {
      __typename: "GroupNotification",
      id: string,
      notificationId: string,
      groupId: string,
      notification:  {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncGroupNotificationsQueryVariables = {
  filter?: ModelGroupNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncGroupNotificationsQuery = {
  syncGroupNotifications?:  {
    __typename: "ModelGroupNotificationConnection",
    items:  Array< {
      __typename: "GroupNotification",
      id: string,
      notificationId: string,
      groupId: string,
      notification:  {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GroupNotificationsByNotificationIdQueryVariables = {
  notificationId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupNotificationsByNotificationIdQuery = {
  groupNotificationsByNotificationId?:  {
    __typename: "ModelGroupNotificationConnection",
    items:  Array< {
      __typename: "GroupNotification",
      id: string,
      notificationId: string,
      groupId: string,
      notification:  {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GroupNotificationsByGroupIdQueryVariables = {
  groupId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGroupNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GroupNotificationsByGroupIdQuery = {
  groupNotificationsByGroupId?:  {
    __typename: "ModelGroupNotificationConnection",
    items:  Array< {
      __typename: "GroupNotification",
      id: string,
      notificationId: string,
      groupId: string,
      notification:  {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      group:  {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLogUserQueryVariables = {
  id: string,
};

export type GetLogUserQuery = {
  getLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLogUsersQueryVariables = {
  filter?: ModelLogUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLogUsersQuery = {
  listLogUsers?:  {
    __typename: "ModelLogUserConnection",
    items:  Array< {
      __typename: "LogUser",
      id: string,
      userId: string,
      logId: string,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      log:  {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLogUsersQueryVariables = {
  filter?: ModelLogUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLogUsersQuery = {
  syncLogUsers?:  {
    __typename: "ModelLogUserConnection",
    items:  Array< {
      __typename: "LogUser",
      id: string,
      userId: string,
      logId: string,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      log:  {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogUsersByUserIdQueryVariables = {
  userId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogUsersByUserIdQuery = {
  logUsersByUserId?:  {
    __typename: "ModelLogUserConnection",
    items:  Array< {
      __typename: "LogUser",
      id: string,
      userId: string,
      logId: string,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      log:  {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LogUsersByLogIdQueryVariables = {
  logId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLogUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LogUsersByLogIdQuery = {
  logUsersByLogId?:  {
    __typename: "ModelLogUserConnection",
    items:  Array< {
      __typename: "LogUser",
      id: string,
      userId: string,
      logId: string,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      log:  {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateDepartmentSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentFilterInput | null,
};

export type OnCreateDepartmentSubscription = {
  onCreateDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type OnUpdateDepartmentSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentFilterInput | null,
};

export type OnUpdateDepartmentSubscription = {
  onUpdateDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type OnDeleteDepartmentSubscriptionVariables = {
  filter?: ModelSubscriptionDepartmentFilterInput | null,
};

export type OnDeleteDepartmentSubscription = {
  onDeleteDepartment?:  {
    __typename: "Department",
    id: string,
    Categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        name: string,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        index: number,
        pairedDepIDs?: Array< string > | null,
        parentCategoryID?: string | null,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        overrideID?: string | null,
        keychainID?: string | null,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentCategoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    OneWeights?:  {
      __typename: "ModelOneWeightConnection",
      items:  Array< {
        __typename: "OneWeight",
        id: string,
        name: string,
        serialNumber: string,
        projectNumber: string,
        lastCalibration: string,
        type?: PWSType | null,
        departmentID: string,
        department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentOneWeightsId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    name: string,
    location?: string | null,
    protocolVersions?: string | null,
    shiftTypes: Array< string >,
    Medications?:  {
      __typename: "ModelMedicationConnection",
      items:  Array< {
        __typename: "Medication",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } >,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        departmentID: string,
        protocolOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Equipment?:  {
      __typename: "ModelEquipmentConnection",
      items:  Array< {
        __typename: "Equipment",
        id: string,
        name: string,
        snomedCode?: string | null,
        optionItems:  Array< {
          __typename: "EquipmentOption",
          amnt: string,
          amntHigh?: string | null,
          rangeLow: number,
          rangeHigh?: number | null,
        } >,
        departmentID: string,
        title?: string | null,
        note?: string | null,
        warning?: string | null,
        instruction?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Forms?:  {
      __typename: "ModelFormConnection",
      items:  Array< {
        __typename: "Form",
        id: string,
        name: string,
        departmentID: string,
        items:  Array< {
          __typename: "FormGroup",
          title: string,
          isUrgent: boolean,
          isDouble: boolean,
        } >,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Vitals?:  {
      __typename: "ModelVitalsConnection",
      items:  Array< {
        __typename: "Vitals",
        id: string,
        title: string,
        options:  Array< {
          __typename: "VitalOption",
          amntLow: string,
          amntHigh: string,
          rangeLow: number,
          rangeHigh: number,
        } >,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Electricals?:  {
      __typename: "ModelElectricalConnection",
      items:  Array< {
        __typename: "Electrical",
        id: string,
        title: string,
        options:  Array< {
          __typename: "ElectricalOption",
          title: string,
        } >,
        departmentID: string,
        activeID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalShocks?:  {
      __typename: "ModelElectricalShockConnection",
      items:  Array< {
        __typename: "ElectricalShock",
        id: string,
        title: string,
        rxNormCode?: string | null,
        options:  Array< {
          __typename: "ElectricalShockOption",
          protocolID: string,
        } >,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        rangeHigh?: number | null,
        rangeLow?: number | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy?: string | null,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    cognitoID: string,
    Users?:  {
      __typename: "ModelUserConnection",
      items:  Array< {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Ambulances?:  {
      __typename: "ModelAmbulanceConnection",
      items:  Array< {
        __typename: "Ambulance",
        id: string,
        name: string,
        departmentID: string,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Drips?:  {
      __typename: "ModelDripConnection",
      items:  Array< {
        __typename: "Drip",
        id: string,
        name: string,
        rxNormCode?: string | null,
        contraindication?: string | null,
        note?: string | null,
        warning?: string | null,
        route?: Array< string > | null,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        concentration?:  Array< {
          __typename: "MedicationConcentration",
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
        } > | null,
        medClass?: string | null,
        action?: string | null,
        indication?: string | null,
        interaction?: string | null,
        onset?: string | null,
        duration?: string | null,
        dripOptions:  Array< {
          __typename: "MedicationProtocol",
          protocolID: string,
        } >,
        departmentID: string,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        maxDose?: string | null,
        minDose?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?:  {
          __typename: "ModelMetaData",
          parentID?: string | null,
          fullName: string,
          nickName?: string | null,
          brandNames?: Array< string > | null,
          maxDose?: string | null,
          minDose?: string | null,
          absMaxDose?: string | null,
          absMinDose?: string | null,
          releaseNotes?: Array< string > | null,
        } | null,
        InfusionDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Concentrations?:  {
          __typename: "ModelConcentrationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    RequireSync?:  {
      __typename: "RequireSync",
      id: string,
      sync: boolean,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    logoID: string,
    userID: string,
    neonateCutoff: number,
    pediatricCutoff: number,
    calculators?: Array< CalculatorType > | null,
    adultRanges:  Array< {
      __typename: "AdultRange",
      label: string,
      value: number,
    } | null >,
    softwarePlan?: SoftwareType | null,
    Notifications?:  {
      __typename: "ModelNotificationConnection",
      items:  Array< {
        __typename: "Notification",
        id: string,
        type: NotificationType,
        title: string,
        message?: string | null,
        timestamp: string,
        expirationTimestamp: string,
        deadlineTimestamp?: string | null,
        isReadIDs: Array< string >,
        isAckIDs: Array< string >,
        imageURLs?: Array< string > | null,
        videoURLs?: Array< string > | null,
        fileURLs?: Array< string > | null,
        taggedProtocols: Array< string >,
        questions:  Array< {
          __typename: "Question",
          question: string,
          options: Array< string >,
          answer: string,
          taggedProtocol?: string | null,
        } >,
        formQuestions:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        isPush: boolean,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        Groups?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Contacts?:  {
      __typename: "ModelContactConnection",
      items:  Array< {
        __typename: "Contact",
        id: string,
        fullName: string,
        title?: string | null,
        number: string,
        note?: string | null,
        index: number,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    InputForms?:  {
      __typename: "ModelInputFormConnection",
      items:  Array< {
        __typename: "InputForm",
        id: string,
        name: string,
        items:  Array< {
          __typename: "FormQuestion",
          index: number,
          type: FormType,
          question: string,
          description?: string | null,
          answers?: Array< string > | null,
          correctAnswer?: Array< string > | null,
        } >,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        pairedDepIDs?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Workbooks?:  {
      __typename: "ModelWorkbookConnection",
      items:  Array< {
        __typename: "Workbook",
        id: string,
        name: string,
        workbookID: string,
        fileSize: number,
        aiPdfParserResults?: string | null,
        activeID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    WeightObjects?:  {
      __typename: "ModelWeightObjectConnection",
      items:  Array< {
        __typename: "WeightObject",
        id: string,
        name: string,
        weight: number,
        createdBy: string,
        modifiedBy?: string | null,
        version?: string | null,
        pairedDepIDs?: Array< string > | null,
        departmentID: string,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Keychains?:  {
      __typename: "ModelKeychainConnection",
      items:  Array< {
        __typename: "Keychain",
        id: string,
        name: string,
        hashedPin: string,
        saltPin: string,
        hashType: string,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        metaData?: string | null,
        departmentID: string,
        Protocols?:  {
          __typename: "ModelProtocolConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Groups?:  {
      __typename: "ModelGroupConnection",
      items:  Array< {
        __typename: "Group",
        id: string,
        name: string,
        type: GroupType,
        departmentID: string,
        pairedDepIDs: Array< string >,
        pairedUserIDs: Array< string >,
        isAutoGen: boolean,
        Department?:  {
          __typename: "Department",
          id: string,
          name: string,
          location?: string | null,
          protocolVersions?: string | null,
          shiftTypes: Array< string >,
          cognitoID: string,
          logoID: string,
          userID: string,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          subDepIDs?: Array< string > | null,
          parentDepID?: string | null,
          activeStatus?: boolean | null,
          agencyNumEMS?: string | null,
          stateIdEMS?: string | null,
          gnisCodeEMS?: string | null,
          uniqueCode: string,
          hashedPin?: string | null,
          saltedPin?: string | null,
          keychainID?: string | null,
          isPublic: boolean,
          infusionCalculation?: boolean | null,
          isPublicSignup: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentRequireSyncId?: string | null,
        } | null,
        Notifications?:  {
          __typename: "ModelGroupNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    CPRAssists?:  {
      __typename: "ModelCPRAssistConnection",
      items:  Array< {
        __typename: "CPRAssist",
        id: string,
        protocolIDs: Array< string >,
        epiIDs: Array< string >,
        defibIDs: Array< string >,
        equipmentID?: string | null,
        departmentID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        cprMetaData:  {
          __typename: "CPRMetaData",
          defaultMode: string,
          epiOrangeSec: number,
          epiRedSec: number,
          compressionRate: number,
          ventRatePerMin: number,
          ventilateSoundDelay: number,
        },
        DefibDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        EpiDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    subDepIDs?: Array< string > | null,
    parentDepID?: string | null,
    activeStatus?: boolean | null,
    agencyNumEMS?: string | null,
    stateIdEMS?: string | null,
    gnisCodeEMS?: string | null,
    uniqueCode: string,
    hashedPin?: string | null,
    saltedPin?: string | null,
    keychainID?: string | null,
    isPublic: boolean,
    infusionCalculation?: boolean | null,
    isPublicSignup: boolean,
    config:  {
      __typename: "DepartmentConfig",
      isTopEnabled: boolean,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      infusionCalculation?: boolean | null,
      isPublic: boolean,
      realTimeUpdating: boolean,
      epcrProvider?: string | null,
      oneweightEnabled: boolean,
      ageFilterEnabled: boolean,
      ageGroupFilterEnabled: boolean,
      renewalDate?: string | null,
      infusionGTTS?: Array< number > | null,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentRequireSyncId?: string | null,
  } | null,
};

export type OnCreateMedicShiftSubscriptionVariables = {
  filter?: ModelSubscriptionMedicShiftFilterInput | null,
};

export type OnCreateMedicShiftSubscription = {
  onCreateMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMedicShiftSubscriptionVariables = {
  filter?: ModelSubscriptionMedicShiftFilterInput | null,
};

export type OnUpdateMedicShiftSubscription = {
  onUpdateMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMedicShiftSubscriptionVariables = {
  filter?: ModelSubscriptionMedicShiftFilterInput | null,
};

export type OnDeleteMedicShiftSubscription = {
  onDeleteMedicShift?:  {
    __typename: "MedicShift",
    id: string,
    shiftStart: string,
    shiftEnd: string,
    completedLogs: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    taggedUsers: Array< string >,
    subscribedDevices: Array< string >,
    invitedUsers:  Array< {
      __typename: "Invite",
      itemID: string,
      expiration: string,
      sentTime: string,
      title?: string | null,
      description?: string | null,
    } >,
    ownerID: string,
    departmentID: string,
    interactionID?: string | null,
    metaData?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePatientInteractionSubscriptionVariables = {
  filter?: ModelSubscriptionPatientInteractionFilterInput | null,
};

export type OnCreatePatientInteractionSubscription = {
  onCreatePatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePatientInteractionSubscriptionVariables = {
  filter?: ModelSubscriptionPatientInteractionFilterInput | null,
};

export type OnUpdatePatientInteractionSubscription = {
  onUpdatePatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePatientInteractionSubscriptionVariables = {
  filter?: ModelSubscriptionPatientInteractionFilterInput | null,
};

export type OnDeletePatientInteractionSubscription = {
  onDeletePatientInteraction?:  {
    __typename: "PatientInteraction",
    id: string,
    isLogActive: boolean,
    actualWeight?: number | null,
    ageValue?: number | null,
    startTime: string,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } >,
    taggedUsers: Array< string >,
    taggedAmbulance: string,
    taggedOneWeight?: string | null,
    incidentNumber?: string | null,
    actualTime?: string | null,
    estimatedTime?: string | null,
    patientReleased?: string | null,
    ageTimestamp?: string | null,
    departmentID: string,
    subscribedUsers: Array< string >,
    subscribedDevices: Array< string >,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    shifts: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateKeychainSubscriptionVariables = {
  filter?: ModelSubscriptionKeychainFilterInput | null,
};

export type OnCreateKeychainSubscription = {
  onCreateKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateKeychainSubscriptionVariables = {
  filter?: ModelSubscriptionKeychainFilterInput | null,
};

export type OnUpdateKeychainSubscription = {
  onUpdateKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteKeychainSubscriptionVariables = {
  filter?: ModelSubscriptionKeychainFilterInput | null,
};

export type OnDeleteKeychainSubscription = {
  onDeleteKeychain?:  {
    __typename: "Keychain",
    id: string,
    name: string,
    hashedPin: string,
    saltPin: string,
    hashType: string,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?: string | null,
    departmentID: string,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnCreateContactSubscription = {
  onCreateContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnUpdateContactSubscription = {
  onUpdateContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteContactSubscriptionVariables = {
  filter?: ModelSubscriptionContactFilterInput | null,
};

export type OnDeleteContactSubscription = {
  onDeleteContact?:  {
    __typename: "Contact",
    id: string,
    fullName: string,
    title?: string | null,
    number: string,
    note?: string | null,
    index: number,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFormLogSubscriptionVariables = {
  filter?: ModelSubscriptionFormLogFilterInput | null,
};

export type OnCreateFormLogSubscription = {
  onCreateFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFormLogSubscriptionVariables = {
  filter?: ModelSubscriptionFormLogFilterInput | null,
};

export type OnUpdateFormLogSubscription = {
  onUpdateFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFormLogSubscriptionVariables = {
  filter?: ModelSubscriptionFormLogFilterInput | null,
};

export type OnDeleteFormLogSubscription = {
  onDeleteFormLog?:  {
    __typename: "FormLog",
    id: string,
    responses?:  Array< {
      __typename: "FormResponse",
      questionIndex: number,
      type: FormType,
      responses: Array< string >,
    } > | null,
    inputformID: string,
    departmentID: string,
    userID: string,
    pairedDepID?: string | null,
    pairedUserIDs?: Array< string > | null,
    notes?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInputFormSubscriptionVariables = {
  filter?: ModelSubscriptionInputFormFilterInput | null,
};

export type OnCreateInputFormSubscription = {
  onCreateInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInputFormSubscriptionVariables = {
  filter?: ModelSubscriptionInputFormFilterInput | null,
};

export type OnUpdateInputFormSubscription = {
  onUpdateInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInputFormSubscriptionVariables = {
  filter?: ModelSubscriptionInputFormFilterInput | null,
};

export type OnDeleteInputFormSubscription = {
  onDeleteInputForm?:  {
    __typename: "InputForm",
    id: string,
    name: string,
    items:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionNotificationFilterInput | null,
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    title: string,
    message?: string | null,
    timestamp: string,
    expirationTimestamp: string,
    deadlineTimestamp?: string | null,
    isReadIDs: Array< string >,
    isAckIDs: Array< string >,
    imageURLs?: Array< string > | null,
    videoURLs?: Array< string > | null,
    fileURLs?: Array< string > | null,
    taggedProtocols: Array< string >,
    questions:  Array< {
      __typename: "Question",
      question: string,
      options: Array< string >,
      answer: string,
      taggedProtocol?: string | null,
    } >,
    formQuestions:  Array< {
      __typename: "FormQuestion",
      index: number,
      type: FormType,
      question: string,
      description?: string | null,
      answers?: Array< string > | null,
      correctAnswer?: Array< string > | null,
    } >,
    isPush: boolean,
    departmentID: string,
    pairedDepIDs?: Array< string > | null,
    Groups?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateNotifyACKSubscriptionVariables = {
  filter?: ModelSubscriptionNotifyACKFilterInput | null,
};

export type OnCreateNotifyACKSubscription = {
  onCreateNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateNotifyACKSubscriptionVariables = {
  filter?: ModelSubscriptionNotifyACKFilterInput | null,
};

export type OnUpdateNotifyACKSubscription = {
  onUpdateNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteNotifyACKSubscriptionVariables = {
  filter?: ModelSubscriptionNotifyACKFilterInput | null,
};

export type OnDeleteNotifyACKSubscription = {
  onDeleteNotifyACK?:  {
    __typename: "NotifyACK",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnCreateGroupSubscription = {
  onCreateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnUpdateGroupSubscription = {
  onUpdateGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGroupSubscriptionVariables = {
  filter?: ModelSubscriptionGroupFilterInput | null,
};

export type OnDeleteGroupSubscription = {
  onDeleteGroup?:  {
    __typename: "Group",
    id: string,
    name: string,
    type: GroupType,
    departmentID: string,
    pairedDepIDs: Array< string >,
    pairedUserIDs: Array< string >,
    isAutoGen: boolean,
    Department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Notifications?:  {
      __typename: "ModelGroupNotificationConnection",
      items:  Array< {
        __typename: "GroupNotification",
        id: string,
        notificationId: string,
        groupId: string,
        notification:  {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        group:  {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDripSubscriptionVariables = {
  filter?: ModelSubscriptionDripFilterInput | null,
};

export type OnCreateDripSubscription = {
  onCreateDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDripSubscriptionVariables = {
  filter?: ModelSubscriptionDripFilterInput | null,
};

export type OnUpdateDripSubscription = {
  onUpdateDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDripSubscriptionVariables = {
  filter?: ModelSubscriptionDripFilterInput | null,
};

export type OnDeleteDripSubscription = {
  onDeleteDrip?:  {
    __typename: "Drip",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration?:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } > | null,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    dripOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    departmentID: string,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    InfusionDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateInfusionDoseSubscriptionVariables = {
  filter?: ModelSubscriptionInfusionDoseFilterInput | null,
};

export type OnCreateInfusionDoseSubscription = {
  onCreateInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateInfusionDoseSubscriptionVariables = {
  filter?: ModelSubscriptionInfusionDoseFilterInput | null,
};

export type OnUpdateInfusionDoseSubscription = {
  onUpdateInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteInfusionDoseSubscriptionVariables = {
  filter?: ModelSubscriptionInfusionDoseFilterInput | null,
};

export type OnDeleteInfusionDoseSubscription = {
  onDeleteInfusionDose?:  {
    __typename: "InfusionDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    dripID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Drip?:  {
      __typename: "Drip",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration?:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } > | null,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      dripOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      departmentID: string,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      InfusionDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionEquipmentFilterInput | null,
};

export type OnCreateEquipmentSubscription = {
  onCreateEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionEquipmentFilterInput | null,
};

export type OnUpdateEquipmentSubscription = {
  onUpdateEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteEquipmentSubscriptionVariables = {
  filter?: ModelSubscriptionEquipmentFilterInput | null,
};

export type OnDeleteEquipmentSubscription = {
  onDeleteEquipment?:  {
    __typename: "Equipment",
    id: string,
    name: string,
    snomedCode?: string | null,
    optionItems:  Array< {
      __typename: "EquipmentOption",
      amnt: string,
      amntHigh?: string | null,
      rangeLow: number,
      rangeHigh?: number | null,
    } >,
    departmentID: string,
    title?: string | null,
    note?: string | null,
    warning?: string | null,
    instruction?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationFilterInput | null,
};

export type OnCreateMedicationSubscription = {
  onCreateMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationFilterInput | null,
};

export type OnUpdateMedicationSubscription = {
  onUpdateMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMedicationSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationFilterInput | null,
};

export type OnDeleteMedicationSubscription = {
  onDeleteMedication?:  {
    __typename: "Medication",
    id: string,
    name: string,
    rxNormCode?: string | null,
    contraindication?: string | null,
    note?: string | null,
    warning?: string | null,
    route?: Array< string > | null,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    concentration:  Array< {
      __typename: "MedicationConcentration",
      firstAmnt: number,
      firstUnit: string,
      secAmnt?: number | null,
      secUnit?: string | null,
      color?: string | null,
    } >,
    medClass?: string | null,
    action?: string | null,
    indication?: string | null,
    interaction?: string | null,
    onset?: string | null,
    duration?: string | null,
    departmentID: string,
    protocolOptions:  Array< {
      __typename: "MedicationProtocol",
      protocolID: string,
      options:  Array< {
        __typename: "MedicationRange",
        doseID?: string | null,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
      } >,
    } >,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    maxDose?: string | null,
    minDose?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Concentrations?:  {
      __typename: "ModelConcentrationConnection",
      items:  Array< {
        __typename: "Concentration",
        id: string,
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
        departmentID: string,
        medicationID?: string | null,
        dripID?: string | null,
        status?: ProgressStatus | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMedicationDoseSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationDoseFilterInput | null,
};

export type OnCreateMedicationDoseSubscription = {
  onCreateMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMedicationDoseSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationDoseFilterInput | null,
};

export type OnUpdateMedicationDoseSubscription = {
  onUpdateMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMedicationDoseSubscriptionVariables = {
  filter?: ModelSubscriptionMedicationDoseFilterInput | null,
};

export type OnDeleteMedicationDoseSubscription = {
  onDeleteMedicationDose?:  {
    __typename: "MedicationDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    route: Array< string >,
    nemsisRoutes?:  Array< {
      __typename: "MedicationRoute",
      route: string,
      code: string,
    } > | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    medicationID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Medication?:  {
      __typename: "Medication",
      id: string,
      name: string,
      rxNormCode?: string | null,
      contraindication?: string | null,
      note?: string | null,
      warning?: string | null,
      route?: Array< string > | null,
      nemsisRoutes?:  Array< {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } > | null,
      concentration:  Array< {
        __typename: "MedicationConcentration",
        firstAmnt: number,
        firstUnit: string,
        secAmnt?: number | null,
        secUnit?: string | null,
        color?: string | null,
      } >,
      medClass?: string | null,
      action?: string | null,
      indication?: string | null,
      interaction?: string | null,
      onset?: string | null,
      duration?: string | null,
      departmentID: string,
      protocolOptions:  Array< {
        __typename: "MedicationProtocol",
        protocolID: string,
        options:  Array< {
          __typename: "MedicationRange",
          doseID?: string | null,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
        } >,
      } >,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      maxDose?: string | null,
      minDose?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Concentrations?:  {
        __typename: "ModelConcentrationConnection",
        items:  Array< {
          __typename: "Concentration",
          id: string,
          firstAmnt: number,
          firstUnit: string,
          secAmnt?: number | null,
          secUnit?: string | null,
          color?: string | null,
          departmentID: string,
          medicationID?: string | null,
          dripID?: string | null,
          status?: ProgressStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateProtocolSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolFilterInput | null,
};

export type OnCreateProtocolSubscription = {
  onCreateProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateProtocolSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolFilterInput | null,
};

export type OnUpdateProtocolSubscription = {
  onUpdateProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteProtocolSubscriptionVariables = {
  filter?: ModelSubscriptionProtocolFilterInput | null,
};

export type OnDeleteProtocolSubscription = {
  onDeleteProtocol?:  {
    __typename: "Protocol",
    id: string,
    name: string,
    pdfID: string,
    categoryID: string,
    pairedProtocols?: Array< string > | null,
    medicationIDs?: Array< string > | null,
    equipmentIDs?: Array< string > | null,
    electricalIDs?: Array< string > | null,
    dripIDs?: Array< string > | null,
    formIDs?: Array< string > | null,
    index: number,
    nickname: string,
    pairedDepIDs?: Array< string > | null,
    rangeLow?: number | null,
    rangeHigh?: number | null,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pdfVersion?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    privatePin?: string | null,
    keychainID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    departmentID: string,
    MedicationDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    DripDoses?:  {
      __typename: "ModelInfusionDoseConnection",
      items:  Array< {
        __typename: "InfusionDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        dripID: string,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Drip?:  {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAmbulanceSubscriptionVariables = {
  filter?: ModelSubscriptionAmbulanceFilterInput | null,
};

export type OnCreateAmbulanceSubscription = {
  onCreateAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAmbulanceSubscriptionVariables = {
  filter?: ModelSubscriptionAmbulanceFilterInput | null,
};

export type OnUpdateAmbulanceSubscription = {
  onUpdateAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAmbulanceSubscriptionVariables = {
  filter?: ModelSubscriptionAmbulanceFilterInput | null,
};

export type OnDeleteAmbulanceSubscription = {
  onDeleteAmbulance?:  {
    __typename: "Ambulance",
    id: string,
    name: string,
    departmentID: string,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    type: UserType,
    cognitoID: string,
    departmentID: string,
    depAdmins?: Array< string > | null,
    logs?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    hashedPin: string,
    saltPin: string,
    pairedDepIDs?: Array< string > | null,
    oneDoseVersion?: string | null,
    lastActivity?: string | null,
    notificationTokens?: Array< string > | null,
    status?: UserStatus | null,
    FormLogs?:  {
      __typename: "ModelFormLogConnection",
      items:  Array< {
        __typename: "FormLog",
        id: string,
        responses?:  Array< {
          __typename: "FormResponse",
          questionIndex: number,
          type: FormType,
          responses: Array< string >,
        } > | null,
        inputformID: string,
        departmentID: string,
        userID: string,
        pairedDepID?: string | null,
        pairedUserIDs?: Array< string > | null,
        notes?: string | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Reviewals?:  {
      __typename: "ModelReviewalConnection",
      items:  Array< {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    NotifyACKs?:  {
      __typename: "ModelNotifyACKConnection",
      items:  Array< {
        __typename: "NotifyACK",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    index: number,
    pairedDepIDs?: Array< string > | null,
    parentCategoryID?: string | null,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    isPublic: boolean,
    isRestrictive: boolean,
    overrideID?: string | null,
    keychainID?: string | null,
    Protocols?:  {
      __typename: "ModelProtocolConnection",
      items:  Array< {
        __typename: "Protocol",
        id: string,
        name: string,
        pdfID: string,
        categoryID: string,
        pairedProtocols?: Array< string > | null,
        medicationIDs?: Array< string > | null,
        equipmentIDs?: Array< string > | null,
        electricalIDs?: Array< string > | null,
        dripIDs?: Array< string > | null,
        formIDs?: Array< string > | null,
        index: number,
        nickname: string,
        pairedDepIDs?: Array< string > | null,
        rangeLow?: number | null,
        rangeHigh?: number | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        status?: ProgressStatus | null,
        version?: string | null,
        pdfVersion?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        privatePin?: string | null,
        keychainID?: string | null,
        createdBy: string,
        modifiedBy?: string | null,
        isPublic: boolean,
        isRestrictive: boolean,
        departmentID: string,
        MedicationDoses?:  {
          __typename: "ModelMedicationDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalDoses?:  {
          __typename: "ModelElectricalDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        DripDoses?:  {
          __typename: "ModelInfusionDoseConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentCategoriesId?: string | null,
  } | null,
};

export type OnCreateElectricalSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalFilterInput | null,
};

export type OnCreateElectricalSubscription = {
  onCreateElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateElectricalSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalFilterInput | null,
};

export type OnUpdateElectricalSubscription = {
  onUpdateElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteElectricalSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalFilterInput | null,
};

export type OnDeleteElectricalSubscription = {
  onDeleteElectrical?:  {
    __typename: "Electrical",
    id: string,
    title: string,
    options:  Array< {
      __typename: "ElectricalOption",
      title: string,
      ranges:  Array< {
        __typename: "ElectricalRange",
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
      } >,
    } >,
    departmentID: string,
    activeID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateElectricalShockSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalShockFilterInput | null,
};

export type OnCreateElectricalShockSubscription = {
  onCreateElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateElectricalShockSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalShockFilterInput | null,
};

export type OnUpdateElectricalShockSubscription = {
  onUpdateElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteElectricalShockSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalShockFilterInput | null,
};

export type OnDeleteElectricalShockSubscription = {
  onDeleteElectricalShock?:  {
    __typename: "ElectricalShock",
    id: string,
    title: string,
    rxNormCode?: string | null,
    options:  Array< {
      __typename: "ElectricalShockOption",
      protocolID: string,
      ranges:  Array< {
        __typename: "ElectricalShockRange",
        doseID?: string | null,
        index: number,
        basis: string,
        rangeLow: number,
        rangeHigh: number,
        calcMax?: string | null,
        fixedMax?: string | null,
        title: string,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
      } >,
    } >,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    rangeHigh?: number | null,
    rangeLow?: number | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy?: string | null,
    modifiedBy?: string | null,
    metaData?:  {
      __typename: "ModelMetaData",
      parentID?: string | null,
      fullName: string,
      nickName?: string | null,
      brandNames?: Array< string > | null,
      maxDose?: string | null,
      minDose?: string | null,
      absMaxDose?: string | null,
      absMinDose?: string | null,
      releaseNotes?: Array< string > | null,
    } | null,
    ElectricalDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateElectricalDoseSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalDoseFilterInput | null,
};

export type OnCreateElectricalDoseSubscription = {
  onCreateElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateElectricalDoseSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalDoseFilterInput | null,
};

export type OnUpdateElectricalDoseSubscription = {
  onUpdateElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteElectricalDoseSubscriptionVariables = {
  filter?: ModelSubscriptionElectricalDoseFilterInput | null,
};

export type OnDeleteElectricalDoseSubscription = {
  onDeleteElectricalDose?:  {
    __typename: "ElectricalDose",
    id: string,
    basis: string,
    rangeLow: number,
    rangeHigh?: number | null,
    title?: string | null,
    warning?: string | null,
    instruction?: string | null,
    note?: string | null,
    maxDose?: string | null,
    minDose?: string | null,
    maxTotalDose?: string | null,
    calcMax?: string | null,
    calcMin?: string | null,
    index: number,
    ageLow?: number | null,
    ageHigh?: number | null,
    ageGroup?: PatientAgeGroup | null,
    repeatTime?: string | null,
    incrementAmnt?: number | null,
    departmentID: string,
    protocolID?: string | null,
    electricalID: string,
    cprAssistID?: string | null,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    Protocol?:  {
      __typename: "Protocol",
      id: string,
      name: string,
      pdfID: string,
      categoryID: string,
      pairedProtocols?: Array< string > | null,
      medicationIDs?: Array< string > | null,
      equipmentIDs?: Array< string > | null,
      electricalIDs?: Array< string > | null,
      dripIDs?: Array< string > | null,
      formIDs?: Array< string > | null,
      index: number,
      nickname: string,
      pairedDepIDs?: Array< string > | null,
      rangeLow?: number | null,
      rangeHigh?: number | null,
      ageLow?: number | null,
      ageHigh?: number | null,
      ageGroup?: PatientAgeGroup | null,
      status?: ProgressStatus | null,
      version?: string | null,
      pdfVersion?: string | null,
      activeID?: string | null,
      overrideID?: string | null,
      privatePin?: string | null,
      keychainID?: string | null,
      createdBy: string,
      modifiedBy?: string | null,
      isPublic: boolean,
      isRestrictive: boolean,
      departmentID: string,
      MedicationDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      DripDoses?:  {
        __typename: "ModelInfusionDoseConnection",
        items:  Array< {
          __typename: "InfusionDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          dripID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Electrical?:  {
      __typename: "ElectricalShock",
      id: string,
      title: string,
      rxNormCode?: string | null,
      options:  Array< {
        __typename: "ElectricalShockOption",
        protocolID: string,
        ranges:  Array< {
          __typename: "ElectricalShockRange",
          doseID?: string | null,
          index: number,
          basis: string,
          rangeLow: number,
          rangeHigh: number,
          calcMax?: string | null,
          fixedMax?: string | null,
          title: string,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
        } >,
      } >,
      warning?: string | null,
      instruction?: string | null,
      note?: string | null,
      rangeHigh?: number | null,
      rangeLow?: number | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status?: ProgressStatus | null,
      version?: string | null,
      createdBy?: string | null,
      modifiedBy?: string | null,
      metaData?:  {
        __typename: "ModelMetaData",
        parentID?: string | null,
        fullName: string,
        nickName?: string | null,
        brandNames?: Array< string > | null,
        maxDose?: string | null,
        minDose?: string | null,
        absMaxDose?: string | null,
        absMinDose?: string | null,
        releaseNotes?: Array< string > | null,
      } | null,
      ElectricalDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    CPRAssist?:  {
      __typename: "CPRAssist",
      id: string,
      protocolIDs: Array< string >,
      epiIDs: Array< string >,
      defibIDs: Array< string >,
      equipmentID?: string | null,
      departmentID: string,
      activeID?: string | null,
      overrideID?: string | null,
      status: ProgressStatus,
      version: string,
      createdBy: string,
      modifiedBy?: string | null,
      cprMetaData:  {
        __typename: "CPRMetaData",
        defaultMode: string,
        epiOrangeSec: number,
        epiRedSec: number,
        compressionRate: number,
        ventRatePerMin: number,
        ventilateSoundDelay: number,
      },
      DefibDoses?:  {
        __typename: "ModelElectricalDoseConnection",
        items:  Array< {
          __typename: "ElectricalDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          electricalID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      EpiDoses?:  {
        __typename: "ModelMedicationDoseConnection",
        items:  Array< {
          __typename: "MedicationDose",
          id: string,
          basis: string,
          rangeLow: number,
          rangeHigh?: number | null,
          route: Array< string >,
          title?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          maxDose?: string | null,
          minDose?: string | null,
          maxTotalDose?: string | null,
          calcMax?: string | null,
          calcMin?: string | null,
          index: number,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          repeatTime?: string | null,
          incrementAmnt?: number | null,
          departmentID: string,
          protocolID?: string | null,
          medicationID: string,
          cprAssistID?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDeveloperNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperNotificationFilterInput | null,
};

export type OnCreateDeveloperNotificationSubscription = {
  onCreateDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDeveloperNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperNotificationFilterInput | null,
};

export type OnUpdateDeveloperNotificationSubscription = {
  onUpdateDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDeveloperNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionDeveloperNotificationFilterInput | null,
};

export type OnDeleteDeveloperNotificationSubscription = {
  onDeleteDeveloperNotification?:  {
    __typename: "DeveloperNotification",
    id: string,
    title: string,
    message?: string | null,
    targetVersion?: string | null,
    additionalData?: string | null,
    type: DevNotifyType,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateVitalsSubscriptionVariables = {
  filter?: ModelSubscriptionVitalsFilterInput | null,
};

export type OnCreateVitalsSubscription = {
  onCreateVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateVitalsSubscriptionVariables = {
  filter?: ModelSubscriptionVitalsFilterInput | null,
};

export type OnUpdateVitalsSubscription = {
  onUpdateVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteVitalsSubscriptionVariables = {
  filter?: ModelSubscriptionVitalsFilterInput | null,
};

export type OnDeleteVitalsSubscription = {
  onDeleteVitals?:  {
    __typename: "Vitals",
    id: string,
    title: string,
    options:  Array< {
      __typename: "VitalOption",
      amntLow: string,
      amntHigh: string,
      rangeLow: number,
      rangeHigh: number,
    } >,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOneWeightSubscriptionVariables = {
  filter?: ModelSubscriptionOneWeightFilterInput | null,
};

export type OnCreateOneWeightSubscription = {
  onCreateOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type OnUpdateOneWeightSubscriptionVariables = {
  filter?: ModelSubscriptionOneWeightFilterInput | null,
};

export type OnUpdateOneWeightSubscription = {
  onUpdateOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type OnDeleteOneWeightSubscriptionVariables = {
  filter?: ModelSubscriptionOneWeightFilterInput | null,
};

export type OnDeleteOneWeightSubscription = {
  onDeleteOneWeight?:  {
    __typename: "OneWeight",
    id: string,
    name: string,
    serialNumber: string,
    projectNumber: string,
    lastCalibration: string,
    type?: PWSType | null,
    departmentID: string,
    department?:  {
      __typename: "Department",
      id: string,
      Categories?:  {
        __typename: "ModelCategoryConnection",
        items:  Array< {
          __typename: "Category",
          id: string,
          name: string,
          departmentID: string,
          index: number,
          pairedDepIDs?: Array< string > | null,
          parentCategoryID?: string | null,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          overrideID?: string | null,
          keychainID?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentCategoriesId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      OneWeights?:  {
        __typename: "ModelOneWeightConnection",
        items:  Array< {
          __typename: "OneWeight",
          id: string,
          name: string,
          serialNumber: string,
          projectNumber: string,
          lastCalibration: string,
          type?: PWSType | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          departmentOneWeightsId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      name: string,
      location?: string | null,
      protocolVersions?: string | null,
      shiftTypes: Array< string >,
      Medications?:  {
        __typename: "ModelMedicationConnection",
        items:  Array< {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Equipment?:  {
        __typename: "ModelEquipmentConnection",
        items:  Array< {
          __typename: "Equipment",
          id: string,
          name: string,
          snomedCode?: string | null,
          departmentID: string,
          title?: string | null,
          note?: string | null,
          warning?: string | null,
          instruction?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Forms?:  {
        __typename: "ModelFormConnection",
        items:  Array< {
          __typename: "Form",
          id: string,
          name: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Vitals?:  {
        __typename: "ModelVitalsConnection",
        items:  Array< {
          __typename: "Vitals",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Electricals?:  {
        __typename: "ModelElectricalConnection",
        items:  Array< {
          __typename: "Electrical",
          id: string,
          title: string,
          departmentID: string,
          activeID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      ElectricalShocks?:  {
        __typename: "ModelElectricalShockConnection",
        items:  Array< {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      cognitoID: string,
      Users?:  {
        __typename: "ModelUserConnection",
        items:  Array< {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Logs?:  {
        __typename: "ModelLogConnection",
        items:  Array< {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Ambulances?:  {
        __typename: "ModelAmbulanceConnection",
        items:  Array< {
          __typename: "Ambulance",
          id: string,
          name: string,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Drips?:  {
        __typename: "ModelDripConnection",
        items:  Array< {
          __typename: "Drip",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      RequireSync?:  {
        __typename: "RequireSync",
        id: string,
        sync: boolean,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      logoID: string,
      userID: string,
      neonateCutoff: number,
      pediatricCutoff: number,
      calculators?: Array< CalculatorType > | null,
      adultRanges:  Array< {
        __typename: "AdultRange",
        label: string,
        value: number,
      } | null >,
      softwarePlan?: SoftwareType | null,
      Notifications?:  {
        __typename: "ModelNotificationConnection",
        items:  Array< {
          __typename: "Notification",
          id: string,
          type: NotificationType,
          title: string,
          message?: string | null,
          timestamp: string,
          expirationTimestamp: string,
          deadlineTimestamp?: string | null,
          isReadIDs: Array< string >,
          isAckIDs: Array< string >,
          imageURLs?: Array< string > | null,
          videoURLs?: Array< string > | null,
          fileURLs?: Array< string > | null,
          taggedProtocols: Array< string >,
          isPush: boolean,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Contacts?:  {
        __typename: "ModelContactConnection",
        items:  Array< {
          __typename: "Contact",
          id: string,
          fullName: string,
          title?: string | null,
          number: string,
          note?: string | null,
          index: number,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      InputForms?:  {
        __typename: "ModelInputFormConnection",
        items:  Array< {
          __typename: "InputForm",
          id: string,
          name: string,
          departmentID: string,
          pairedDepIDs?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Workbooks?:  {
        __typename: "ModelWorkbookConnection",
        items:  Array< {
          __typename: "Workbook",
          id: string,
          name: string,
          workbookID: string,
          fileSize: number,
          aiPdfParserResults?: string | null,
          activeID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      WeightObjects?:  {
        __typename: "ModelWeightObjectConnection",
        items:  Array< {
          __typename: "WeightObject",
          id: string,
          name: string,
          weight: number,
          createdBy: string,
          modifiedBy?: string | null,
          version?: string | null,
          pairedDepIDs?: Array< string > | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Keychains?:  {
        __typename: "ModelKeychainConnection",
        items:  Array< {
          __typename: "Keychain",
          id: string,
          name: string,
          hashedPin: string,
          saltPin: string,
          hashType: string,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          metaData?: string | null,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Groups?:  {
        __typename: "ModelGroupConnection",
        items:  Array< {
          __typename: "Group",
          id: string,
          name: string,
          type: GroupType,
          departmentID: string,
          pairedDepIDs: Array< string >,
          pairedUserIDs: Array< string >,
          isAutoGen: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      CPRAssists?:  {
        __typename: "ModelCPRAssistConnection",
        items:  Array< {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      subDepIDs?: Array< string > | null,
      parentDepID?: string | null,
      activeStatus?: boolean | null,
      agencyNumEMS?: string | null,
      stateIdEMS?: string | null,
      gnisCodeEMS?: string | null,
      uniqueCode: string,
      hashedPin?: string | null,
      saltedPin?: string | null,
      keychainID?: string | null,
      isPublic: boolean,
      infusionCalculation?: boolean | null,
      isPublicSignup: boolean,
      config:  {
        __typename: "DepartmentConfig",
        isTopEnabled: boolean,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        infusionCalculation?: boolean | null,
        isPublic: boolean,
        realTimeUpdating: boolean,
        epcrProvider?: string | null,
        oneweightEnabled: boolean,
        ageFilterEnabled: boolean,
        ageGroupFilterEnabled: boolean,
        renewalDate?: string | null,
        infusionGTTS?: Array< number > | null,
      },
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      departmentRequireSyncId?: string | null,
    } | null,
    Logs?:  {
      __typename: "ModelLogConnection",
      items:  Array< {
        __typename: "Log",
        id: string,
        actualWeight?: number | null,
        ageValue?: number | null,
        protocolID?: Array< string > | null,
        Users?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        departmentID: string,
        oneweightID?: string | null,
        ambulanceID?: string | null,
        incidentNumber?: string | null,
        startTime?: string | null,
        endTime?: string | null,
        estimatedWeight?: number | null,
        medications:  Array< {
          __typename: "LogMedication",
          medicationID: string,
          amntSolid: string,
          amntLiquid: string,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          isActualWeight: boolean,
          adminType: string,
          weight: number,
          protocolID?: string | null,
        } >,
        equipments:  Array< {
          __typename: "LogEquipment",
          equipmentID: string,
          amount: string,
          name: string,
          snomedCode?: string | null,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          successful: boolean,
          protocolID?: string | null,
        } >,
        electricals:  Array< {
          __typename: "LogElectrical",
          electricalID: string,
          name: string,
          snomedCode?: string | null,
          amount: string,
          timestamp: string,
          isActualWeight: boolean,
          verified: boolean,
          weight: number,
          protocolID?: string | null,
        } >,
        cpr?:  {
          __typename: "LogCPR",
          cprID: string,
          startTime: string,
          endTime: string,
          outcome?: PatientOutcomeType | null,
        } | null,
        patientSaved?: string | null,
        patientReleased?: string | null,
        arrivedAtHospital?: string | null,
        ageTimestamp?: string | null,
        forms:  Array< {
          __typename: "LogForm",
          formID?: string | null,
          name?: string | null,
          completed: string,
          startedTime: string,
          protocolID?: string | null,
        } >,
        subtractedItems?:  Array< {
          __typename: "LogWeightObject",
          weightObjectID?: string | null,
          name: string,
          weight: number,
          added: string,
        } > | null,
        userIDs: Array< string >,
        infusions:  Array< {
          __typename: "LogDrip",
          dripID: string,
          amnt: string,
          amnt2?: string | null,
          timestamp: string,
          verified: boolean,
          name: string,
          rxNormCode?: string | null,
          concentration: string,
          adminType: string,
          weight: number,
          protocolID?: string | null,
          amntUnit: string,
          amntUnit2?: string | null,
          isActualWeight?: boolean | null,
          gttsDropFactor?: number | null,
          gttsAmount?: number | null,
          gttsUnit?: string | null,
        } >,
        estimatedSaved?: string | null,
        tags: Array< string >,
        shifts?:  Array< {
          __typename: "LogMedicShift",
          shiftStart: string,
          shiftEnd: string,
          completedLogs?: Array< string > | null,
          taggedAmbulance: string,
          taggedOneWeight?: string | null,
          taggedUsers: Array< string >,
          ownerID: string,
          departmentID: string,
          metaData?: string | null,
        } > | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    departmentOneWeightsId?: string | null,
  } | null,
};

export type OnCreateFormSubscriptionVariables = {
  filter?: ModelSubscriptionFormFilterInput | null,
};

export type OnCreateFormSubscription = {
  onCreateForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFormSubscriptionVariables = {
  filter?: ModelSubscriptionFormFilterInput | null,
};

export type OnUpdateFormSubscription = {
  onUpdateForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFormSubscriptionVariables = {
  filter?: ModelSubscriptionFormFilterInput | null,
};

export type OnDeleteFormSubscription = {
  onDeleteForm?:  {
    __typename: "Form",
    id: string,
    name: string,
    departmentID: string,
    items:  Array< {
      __typename: "FormGroup",
      title: string,
      options:  Array< {
        __typename: "FormOption",
        firstItem: string,
        secItem?: string | null,
      } >,
      isUrgent: boolean,
      isDouble: boolean,
    } >,
    activeID?: string | null,
    overrideID?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnCreateLogSubscription = {
  onCreateLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnUpdateLogSubscription = {
  onUpdateLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLogSubscriptionVariables = {
  filter?: ModelSubscriptionLogFilterInput | null,
};

export type OnDeleteLogSubscription = {
  onDeleteLog?:  {
    __typename: "Log",
    id: string,
    actualWeight?: number | null,
    ageValue?: number | null,
    protocolID?: Array< string > | null,
    Users?:  {
      __typename: "ModelLogUserConnection",
      items:  Array< {
        __typename: "LogUser",
        id: string,
        userId: string,
        logId: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        log:  {
          __typename: "Log",
          id: string,
          actualWeight?: number | null,
          ageValue?: number | null,
          protocolID?: Array< string > | null,
          departmentID: string,
          oneweightID?: string | null,
          ambulanceID?: string | null,
          incidentNumber?: string | null,
          startTime?: string | null,
          endTime?: string | null,
          estimatedWeight?: number | null,
          patientSaved?: string | null,
          patientReleased?: string | null,
          arrivedAtHospital?: string | null,
          ageTimestamp?: string | null,
          userIDs: Array< string >,
          estimatedSaved?: string | null,
          tags: Array< string >,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    departmentID: string,
    oneweightID?: string | null,
    ambulanceID?: string | null,
    incidentNumber?: string | null,
    startTime?: string | null,
    endTime?: string | null,
    estimatedWeight?: number | null,
    medications:  Array< {
      __typename: "LogMedication",
      medicationID: string,
      amntSolid: string,
      amntLiquid: string,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      isActualWeight: boolean,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
    } >,
    equipments:  Array< {
      __typename: "LogEquipment",
      equipmentID: string,
      amount: string,
      name: string,
      snomedCode?: string | null,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      successful: boolean,
      protocolID?: string | null,
    } >,
    electricals:  Array< {
      __typename: "LogElectrical",
      electricalID: string,
      name: string,
      snomedCode?: string | null,
      amount: string,
      timestamp: string,
      isActualWeight: boolean,
      verified: boolean,
      weight: number,
      protocolID?: string | null,
    } >,
    cpr?:  {
      __typename: "LogCPR",
      cprID: string,
      startTime: string,
      endTime: string,
      outcome?: PatientOutcomeType | null,
    } | null,
    patientSaved?: string | null,
    patientReleased?: string | null,
    arrivedAtHospital?: string | null,
    ageTimestamp?: string | null,
    forms:  Array< {
      __typename: "LogForm",
      formID?: string | null,
      name?: string | null,
      completed: string,
      startedTime: string,
      protocolID?: string | null,
    } >,
    subtractedItems?:  Array< {
      __typename: "LogWeightObject",
      weightObjectID?: string | null,
      name: string,
      weight: number,
      added: string,
    } > | null,
    userIDs: Array< string >,
    infusions:  Array< {
      __typename: "LogDrip",
      dripID: string,
      amnt: string,
      amnt2?: string | null,
      timestamp: string,
      verified: boolean,
      name: string,
      rxNormCode?: string | null,
      concentration: string,
      adminType: string,
      route?:  {
        __typename: "MedicationRoute",
        route: string,
        code: string,
      } | null,
      weight: number,
      protocolID?: string | null,
      amntUnit: string,
      amntUnit2?: string | null,
      isActualWeight?: boolean | null,
      gttsDropFactor?: number | null,
      gttsAmount?: number | null,
      gttsUnit?: string | null,
    } >,
    estimatedSaved?: string | null,
    tags: Array< string >,
    shifts?:  Array< {
      __typename: "LogMedicShift",
      shiftStart: string,
      shiftEnd: string,
      completedLogs?: Array< string > | null,
      taggedAmbulance: string,
      taggedOneWeight?: string | null,
      taggedUsers: Array< string >,
      ownerID: string,
      departmentID: string,
      metaData?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateRequireSyncSubscriptionVariables = {
  filter?: ModelSubscriptionRequireSyncFilterInput | null,
};

export type OnCreateRequireSyncSubscription = {
  onCreateRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateRequireSyncSubscriptionVariables = {
  filter?: ModelSubscriptionRequireSyncFilterInput | null,
};

export type OnUpdateRequireSyncSubscription = {
  onUpdateRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteRequireSyncSubscriptionVariables = {
  filter?: ModelSubscriptionRequireSyncFilterInput | null,
};

export type OnDeleteRequireSyncSubscription = {
  onDeleteRequireSync?:  {
    __typename: "RequireSync",
    id: string,
    sync: boolean,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateHardwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareVersionFilterInput | null,
};

export type OnCreateHardwareVersionSubscription = {
  onCreateHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateHardwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareVersionFilterInput | null,
};

export type OnUpdateHardwareVersionSubscription = {
  onUpdateHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteHardwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareVersionFilterInput | null,
};

export type OnDeleteHardwareVersionSubscription = {
  onDeleteHardwareVersion?:  {
    __typename: "HardwareVersion",
    id: string,
    version: string,
    name: string,
    description: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFirmwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionFirmwareVersionFilterInput | null,
};

export type OnCreateFirmwareVersionSubscription = {
  onCreateFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFirmwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionFirmwareVersionFilterInput | null,
};

export type OnUpdateFirmwareVersionSubscription = {
  onUpdateFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFirmwareVersionSubscriptionVariables = {
  filter?: ModelSubscriptionFirmwareVersionFilterInput | null,
};

export type OnDeleteFirmwareVersionSubscription = {
  onDeleteFirmwareVersion?:  {
    __typename: "FirmwareVersion",
    id: string,
    name: string,
    version: string,
    fileURL: string,
    description: string,
    cover_image?: string | null,
    compatibleHW: Array< string >,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWorkbookSubscriptionVariables = {
  filter?: ModelSubscriptionWorkbookFilterInput | null,
};

export type OnCreateWorkbookSubscription = {
  onCreateWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWorkbookSubscriptionVariables = {
  filter?: ModelSubscriptionWorkbookFilterInput | null,
};

export type OnUpdateWorkbookSubscription = {
  onUpdateWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWorkbookSubscriptionVariables = {
  filter?: ModelSubscriptionWorkbookFilterInput | null,
};

export type OnDeleteWorkbookSubscription = {
  onDeleteWorkbook?:  {
    __typename: "Workbook",
    id: string,
    name: string,
    workbookID: string,
    fileSize: number,
    aiPdfParserResults?: string | null,
    activeID?: string | null,
    createdBy: string,
    modifiedBy?: string | null,
    status?: ProgressStatus | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateWeightObjectSubscriptionVariables = {
  filter?: ModelSubscriptionWeightObjectFilterInput | null,
};

export type OnCreateWeightObjectSubscription = {
  onCreateWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateWeightObjectSubscriptionVariables = {
  filter?: ModelSubscriptionWeightObjectFilterInput | null,
};

export type OnUpdateWeightObjectSubscription = {
  onUpdateWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteWeightObjectSubscriptionVariables = {
  filter?: ModelSubscriptionWeightObjectFilterInput | null,
};

export type OnDeleteWeightObjectSubscription = {
  onDeleteWeightObject?:  {
    __typename: "WeightObject",
    id: string,
    name: string,
    weight: number,
    createdBy: string,
    modifiedBy?: string | null,
    version?: string | null,
    pairedDepIDs?: Array< string > | null,
    departmentID: string,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConcentrationSubscriptionVariables = {
  filter?: ModelSubscriptionConcentrationFilterInput | null,
};

export type OnCreateConcentrationSubscription = {
  onCreateConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConcentrationSubscriptionVariables = {
  filter?: ModelSubscriptionConcentrationFilterInput | null,
};

export type OnUpdateConcentrationSubscription = {
  onUpdateConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConcentrationSubscriptionVariables = {
  filter?: ModelSubscriptionConcentrationFilterInput | null,
};

export type OnDeleteConcentrationSubscription = {
  onDeleteConcentration?:  {
    __typename: "Concentration",
    id: string,
    firstAmnt: number,
    firstUnit: string,
    secAmnt?: number | null,
    secUnit?: string | null,
    color?: string | null,
    departmentID: string,
    medicationID?: string | null,
    dripID?: string | null,
    status?: ProgressStatus | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCPRAssistSubscriptionVariables = {
  filter?: ModelSubscriptionCPRAssistFilterInput | null,
};

export type OnCreateCPRAssistSubscription = {
  onCreateCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCPRAssistSubscriptionVariables = {
  filter?: ModelSubscriptionCPRAssistFilterInput | null,
};

export type OnUpdateCPRAssistSubscription = {
  onUpdateCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCPRAssistSubscriptionVariables = {
  filter?: ModelSubscriptionCPRAssistFilterInput | null,
};

export type OnDeleteCPRAssistSubscription = {
  onDeleteCPRAssist?:  {
    __typename: "CPRAssist",
    id: string,
    protocolIDs: Array< string >,
    epiIDs: Array< string >,
    defibIDs: Array< string >,
    equipmentID?: string | null,
    departmentID: string,
    activeID?: string | null,
    overrideID?: string | null,
    status: ProgressStatus,
    version: string,
    createdBy: string,
    modifiedBy?: string | null,
    cprMetaData:  {
      __typename: "CPRMetaData",
      defaultMode: string,
      epiOrangeSec: number,
      epiRedSec: number,
      compressionRate: number,
      ventRatePerMin: number,
      ventilateSoundDelay: number,
    },
    DefibDoses?:  {
      __typename: "ModelElectricalDoseConnection",
      items:  Array< {
        __typename: "ElectricalDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        electricalID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Electrical?:  {
          __typename: "ElectricalShock",
          id: string,
          title: string,
          rxNormCode?: string | null,
          warning?: string | null,
          instruction?: string | null,
          note?: string | null,
          rangeHigh?: number | null,
          rangeLow?: number | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy?: string | null,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    EpiDoses?:  {
      __typename: "ModelMedicationDoseConnection",
      items:  Array< {
        __typename: "MedicationDose",
        id: string,
        basis: string,
        rangeLow: number,
        rangeHigh?: number | null,
        route: Array< string >,
        nemsisRoutes?:  Array< {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } > | null,
        title?: string | null,
        warning?: string | null,
        instruction?: string | null,
        note?: string | null,
        maxDose?: string | null,
        minDose?: string | null,
        maxTotalDose?: string | null,
        calcMax?: string | null,
        calcMin?: string | null,
        index: number,
        ageLow?: number | null,
        ageHigh?: number | null,
        ageGroup?: PatientAgeGroup | null,
        repeatTime?: string | null,
        incrementAmnt?: number | null,
        departmentID: string,
        protocolID?: string | null,
        medicationID: string,
        cprAssistID?: string | null,
        activeID?: string | null,
        overrideID?: string | null,
        status: ProgressStatus,
        version: string,
        createdBy: string,
        modifiedBy?: string | null,
        createdAt: string,
        Protocol?:  {
          __typename: "Protocol",
          id: string,
          name: string,
          pdfID: string,
          categoryID: string,
          pairedProtocols?: Array< string > | null,
          medicationIDs?: Array< string > | null,
          equipmentIDs?: Array< string > | null,
          electricalIDs?: Array< string > | null,
          dripIDs?: Array< string > | null,
          formIDs?: Array< string > | null,
          index: number,
          nickname: string,
          pairedDepIDs?: Array< string > | null,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          ageLow?: number | null,
          ageHigh?: number | null,
          ageGroup?: PatientAgeGroup | null,
          status?: ProgressStatus | null,
          version?: string | null,
          pdfVersion?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          privatePin?: string | null,
          keychainID?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          isPublic: boolean,
          isRestrictive: boolean,
          departmentID: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Medication?:  {
          __typename: "Medication",
          id: string,
          name: string,
          rxNormCode?: string | null,
          contraindication?: string | null,
          note?: string | null,
          warning?: string | null,
          route?: Array< string > | null,
          medClass?: string | null,
          action?: string | null,
          indication?: string | null,
          interaction?: string | null,
          onset?: string | null,
          duration?: string | null,
          departmentID: string,
          rangeLow?: number | null,
          rangeHigh?: number | null,
          maxDose?: string | null,
          minDose?: string | null,
          activeID?: string | null,
          overrideID?: string | null,
          status?: ProgressStatus | null,
          version?: string | null,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        CPRAssist?:  {
          __typename: "CPRAssist",
          id: string,
          protocolIDs: Array< string >,
          epiIDs: Array< string >,
          defibIDs: Array< string >,
          equipmentID?: string | null,
          departmentID: string,
          activeID?: string | null,
          overrideID?: string | null,
          status: ProgressStatus,
          version: string,
          createdBy: string,
          modifiedBy?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReviewalSubscriptionVariables = {
  filter?: ModelSubscriptionReviewalFilterInput | null,
};

export type OnCreateReviewalSubscription = {
  onCreateReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReviewalSubscriptionVariables = {
  filter?: ModelSubscriptionReviewalFilterInput | null,
};

export type OnUpdateReviewalSubscription = {
  onUpdateReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReviewalSubscriptionVariables = {
  filter?: ModelSubscriptionReviewalFilterInput | null,
};

export type OnDeleteReviewalSubscription = {
  onDeleteReviewal?:  {
    __typename: "Reviewal",
    id: string,
    title: string,
    description?: string | null,
    reviewers: Array< string >,
    status: ACKStatus,
    events:  Array< {
      __typename: "LogEvent",
      color: string,
      title: string,
      description: string,
      timestamp: string,
      icon?: string | null,
    } >,
    createdAt: string,
    endedAt?: string | null,
    departmentID: string,
    userID: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftGroups?:  {
      __typename: "ModelDraftGroupConnection",
      items:  Array< {
        __typename: "DraftGroup",
        id: string,
        reviewalID: string,
        departmentID: string,
        ownerID: string,
        title: string,
        description?: string | null,
        createdAt: string,
        Reviewal?:  {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftChanges?:  {
          __typename: "ModelDraftChangeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        isClosed: boolean,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    UserComments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDraftGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDraftGroupFilterInput | null,
};

export type OnCreateDraftGroupSubscription = {
  onCreateDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDraftGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDraftGroupFilterInput | null,
};

export type OnUpdateDraftGroupSubscription = {
  onUpdateDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDraftGroupSubscriptionVariables = {
  filter?: ModelSubscriptionDraftGroupFilterInput | null,
};

export type OnDeleteDraftGroupSubscription = {
  onDeleteDraftGroup?:  {
    __typename: "DraftGroup",
    id: string,
    reviewalID: string,
    departmentID: string,
    ownerID: string,
    title: string,
    description?: string | null,
    createdAt: string,
    Reviewal?:  {
      __typename: "Reviewal",
      id: string,
      title: string,
      description?: string | null,
      reviewers: Array< string >,
      status: ACKStatus,
      events:  Array< {
        __typename: "LogEvent",
        color: string,
        title: string,
        description: string,
        timestamp: string,
        icon?: string | null,
      } >,
      createdAt: string,
      endedAt?: string | null,
      departmentID: string,
      userID: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftGroups?:  {
        __typename: "ModelDraftGroupConnection",
        items:  Array< {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    DraftChanges?:  {
      __typename: "ModelDraftChangeConnection",
      items:  Array< {
        __typename: "DraftChange",
        id: string,
        draftGroupID: string,
        departmentID: string,
        changeID: string,
        previousID?: string | null,
        changeType: string,
        isClosed: boolean,
        createdAt: string,
        DraftGroup?:  {
          __typename: "DraftGroup",
          id: string,
          reviewalID: string,
          departmentID: string,
          ownerID: string,
          title: string,
          description?: string | null,
          createdAt: string,
          isClosed: boolean,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    isClosed: boolean,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDraftChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDraftChangeFilterInput | null,
};

export type OnCreateDraftChangeSubscription = {
  onCreateDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDraftChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDraftChangeFilterInput | null,
};

export type OnUpdateDraftChangeSubscription = {
  onUpdateDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDraftChangeSubscriptionVariables = {
  filter?: ModelSubscriptionDraftChangeFilterInput | null,
};

export type OnDeleteDraftChangeSubscription = {
  onDeleteDraftChange?:  {
    __typename: "DraftChange",
    id: string,
    draftGroupID: string,
    departmentID: string,
    changeID: string,
    previousID?: string | null,
    changeType: string,
    isClosed: boolean,
    createdAt: string,
    DraftGroup?:  {
      __typename: "DraftGroup",
      id: string,
      reviewalID: string,
      departmentID: string,
      ownerID: string,
      title: string,
      description?: string | null,
      createdAt: string,
      Reviewal?:  {
        __typename: "Reviewal",
        id: string,
        title: string,
        description?: string | null,
        reviewers: Array< string >,
        status: ACKStatus,
        events:  Array< {
          __typename: "LogEvent",
          color: string,
          title: string,
          description: string,
          timestamp: string,
          icon?: string | null,
        } >,
        createdAt: string,
        endedAt?: string | null,
        departmentID: string,
        userID: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        DraftGroups?:  {
          __typename: "ModelDraftGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      DraftChanges?:  {
        __typename: "ModelDraftChangeConnection",
        items:  Array< {
          __typename: "DraftChange",
          id: string,
          draftGroupID: string,
          departmentID: string,
          changeID: string,
          previousID?: string | null,
          changeType: string,
          isClosed: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      isClosed: boolean,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comments?:  {
      __typename: "ModelUserCommentConnection",
      items:  Array< {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    Acknowledges?:  {
      __typename: "ModelAcknowledgeConnection",
      items:  Array< {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAcknowledgeSubscriptionVariables = {
  filter?: ModelSubscriptionAcknowledgeFilterInput | null,
};

export type OnCreateAcknowledgeSubscription = {
  onCreateAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAcknowledgeSubscriptionVariables = {
  filter?: ModelSubscriptionAcknowledgeFilterInput | null,
};

export type OnUpdateAcknowledgeSubscription = {
  onUpdateAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAcknowledgeSubscriptionVariables = {
  filter?: ModelSubscriptionAcknowledgeFilterInput | null,
};

export type OnDeleteAcknowledgeSubscription = {
  onDeleteAcknowledge?:  {
    __typename: "Acknowledge",
    id: string,
    ownerID: string,
    userID: string,
    status: ACKStatus,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Comment?:  {
      __typename: "UserComment",
      id: string,
      ownerID: string,
      userID: string,
      acknowledgeID?: string | null,
      message: string,
      relatedItems?: Array< string > | null,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Acknowledge?:  {
        __typename: "Acknowledge",
        id: string,
        ownerID: string,
        userID: string,
        status: ACKStatus,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Comment?:  {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnCreateUserCommentSubscription = {
  onCreateUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnUpdateUserCommentSubscription = {
  onUpdateUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserCommentSubscriptionVariables = {
  filter?: ModelSubscriptionUserCommentFilterInput | null,
};

export type OnDeleteUserCommentSubscription = {
  onDeleteUserComment?:  {
    __typename: "UserComment",
    id: string,
    ownerID: string,
    userID: string,
    acknowledgeID?: string | null,
    message: string,
    relatedItems?: Array< string > | null,
    createdAt: string,
    User?:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    Acknowledge?:  {
      __typename: "Acknowledge",
      id: string,
      ownerID: string,
      userID: string,
      status: ACKStatus,
      createdAt: string,
      User?:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        type: UserType,
        cognitoID: string,
        departmentID: string,
        depAdmins?: Array< string > | null,
        logs?:  {
          __typename: "ModelLogUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        hashedPin: string,
        saltPin: string,
        pairedDepIDs?: Array< string > | null,
        oneDoseVersion?: string | null,
        lastActivity?: string | null,
        notificationTokens?: Array< string > | null,
        status?: UserStatus | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Acknowledges?:  {
          __typename: "ModelAcknowledgeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        UserComments?:  {
          __typename: "ModelUserCommentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        NotifyACKs?:  {
          __typename: "ModelNotifyACKConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      Comment?:  {
        __typename: "UserComment",
        id: string,
        ownerID: string,
        userID: string,
        acknowledgeID?: string | null,
        message: string,
        relatedItems?: Array< string > | null,
        createdAt: string,
        User?:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          type: UserType,
          cognitoID: string,
          departmentID: string,
          depAdmins?: Array< string > | null,
          hashedPin: string,
          saltPin: string,
          pairedDepIDs?: Array< string > | null,
          oneDoseVersion?: string | null,
          lastActivity?: string | null,
          notificationTokens?: Array< string > | null,
          status?: UserStatus | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        Acknowledge?:  {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateGroupNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionGroupNotificationFilterInput | null,
};

export type OnCreateGroupNotificationSubscription = {
  onCreateGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateGroupNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionGroupNotificationFilterInput | null,
};

export type OnUpdateGroupNotificationSubscription = {
  onUpdateGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteGroupNotificationSubscriptionVariables = {
  filter?: ModelSubscriptionGroupNotificationFilterInput | null,
};

export type OnDeleteGroupNotificationSubscription = {
  onDeleteGroupNotification?:  {
    __typename: "GroupNotification",
    id: string,
    notificationId: string,
    groupId: string,
    notification:  {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      title: string,
      message?: string | null,
      timestamp: string,
      expirationTimestamp: string,
      deadlineTimestamp?: string | null,
      isReadIDs: Array< string >,
      isAckIDs: Array< string >,
      imageURLs?: Array< string > | null,
      videoURLs?: Array< string > | null,
      fileURLs?: Array< string > | null,
      taggedProtocols: Array< string >,
      questions:  Array< {
        __typename: "Question",
        question: string,
        options: Array< string >,
        answer: string,
        taggedProtocol?: string | null,
      } >,
      formQuestions:  Array< {
        __typename: "FormQuestion",
        index: number,
        type: FormType,
        question: string,
        description?: string | null,
        answers?: Array< string > | null,
        correctAnswer?: Array< string > | null,
      } >,
      isPush: boolean,
      departmentID: string,
      pairedDepIDs?: Array< string > | null,
      Groups?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdBy: string,
      modifiedBy?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    group:  {
      __typename: "Group",
      id: string,
      name: string,
      type: GroupType,
      departmentID: string,
      pairedDepIDs: Array< string >,
      pairedUserIDs: Array< string >,
      isAutoGen: boolean,
      Department?:  {
        __typename: "Department",
        id: string,
        Categories?:  {
          __typename: "ModelCategoryConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        OneWeights?:  {
          __typename: "ModelOneWeightConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        name: string,
        location?: string | null,
        protocolVersions?: string | null,
        shiftTypes: Array< string >,
        Medications?:  {
          __typename: "ModelMedicationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Equipment?:  {
          __typename: "ModelEquipmentConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Forms?:  {
          __typename: "ModelFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Vitals?:  {
          __typename: "ModelVitalsConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Electricals?:  {
          __typename: "ModelElectricalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        ElectricalShocks?:  {
          __typename: "ModelElectricalShockConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        cognitoID: string,
        Users?:  {
          __typename: "ModelUserConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Logs?:  {
          __typename: "ModelLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Ambulances?:  {
          __typename: "ModelAmbulanceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Drips?:  {
          __typename: "ModelDripConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        RequireSync?:  {
          __typename: "RequireSync",
          id: string,
          sync: boolean,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null,
        logoID: string,
        userID: string,
        neonateCutoff: number,
        pediatricCutoff: number,
        calculators?: Array< CalculatorType > | null,
        adultRanges:  Array< {
          __typename: "AdultRange",
          label: string,
          value: number,
        } | null >,
        softwarePlan?: SoftwareType | null,
        Notifications?:  {
          __typename: "ModelNotificationConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Contacts?:  {
          __typename: "ModelContactConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        InputForms?:  {
          __typename: "ModelInputFormConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        FormLogs?:  {
          __typename: "ModelFormLogConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Workbooks?:  {
          __typename: "ModelWorkbookConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        WeightObjects?:  {
          __typename: "ModelWeightObjectConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Keychains?:  {
          __typename: "ModelKeychainConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Reviewals?:  {
          __typename: "ModelReviewalConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        Groups?:  {
          __typename: "ModelGroupConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        CPRAssists?:  {
          __typename: "ModelCPRAssistConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        subDepIDs?: Array< string > | null,
        parentDepID?: string | null,
        activeStatus?: boolean | null,
        agencyNumEMS?: string | null,
        stateIdEMS?: string | null,
        gnisCodeEMS?: string | null,
        uniqueCode: string,
        hashedPin?: string | null,
        saltedPin?: string | null,
        keychainID?: string | null,
        isPublic: boolean,
        infusionCalculation?: boolean | null,
        isPublicSignup: boolean,
        config:  {
          __typename: "DepartmentConfig",
          isTopEnabled: boolean,
          neonateCutoff: number,
          pediatricCutoff: number,
          calculators?: Array< CalculatorType > | null,
          softwarePlan?: SoftwareType | null,
          infusionCalculation?: boolean | null,
          isPublic: boolean,
          realTimeUpdating: boolean,
          epcrProvider?: string | null,
          oneweightEnabled: boolean,
          ageFilterEnabled: boolean,
          ageGroupFilterEnabled: boolean,
          renewalDate?: string | null,
          infusionGTTS?: Array< number > | null,
        },
        createdAt: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        departmentRequireSyncId?: string | null,
      } | null,
      Notifications?:  {
        __typename: "ModelGroupNotificationConnection",
        items:  Array< {
          __typename: "GroupNotification",
          id: string,
          notificationId: string,
          groupId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLogUserSubscriptionVariables = {
  filter?: ModelSubscriptionLogUserFilterInput | null,
};

export type OnCreateLogUserSubscription = {
  onCreateLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLogUserSubscriptionVariables = {
  filter?: ModelSubscriptionLogUserFilterInput | null,
};

export type OnUpdateLogUserSubscription = {
  onUpdateLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLogUserSubscriptionVariables = {
  filter?: ModelSubscriptionLogUserFilterInput | null,
};

export type OnDeleteLogUserSubscription = {
  onDeleteLogUser?:  {
    __typename: "LogUser",
    id: string,
    userId: string,
    logId: string,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      type: UserType,
      cognitoID: string,
      departmentID: string,
      depAdmins?: Array< string > | null,
      logs?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      hashedPin: string,
      saltPin: string,
      pairedDepIDs?: Array< string > | null,
      oneDoseVersion?: string | null,
      lastActivity?: string | null,
      notificationTokens?: Array< string > | null,
      status?: UserStatus | null,
      FormLogs?:  {
        __typename: "ModelFormLogConnection",
        items:  Array< {
          __typename: "FormLog",
          id: string,
          inputformID: string,
          departmentID: string,
          userID: string,
          pairedDepID?: string | null,
          pairedUserIDs?: Array< string > | null,
          notes?: string | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Reviewals?:  {
        __typename: "ModelReviewalConnection",
        items:  Array< {
          __typename: "Reviewal",
          id: string,
          title: string,
          description?: string | null,
          reviewers: Array< string >,
          status: ACKStatus,
          createdAt: string,
          endedAt?: string | null,
          departmentID: string,
          userID: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      Acknowledges?:  {
        __typename: "ModelAcknowledgeConnection",
        items:  Array< {
          __typename: "Acknowledge",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      UserComments?:  {
        __typename: "ModelUserCommentConnection",
        items:  Array< {
          __typename: "UserComment",
          id: string,
          ownerID: string,
          userID: string,
          acknowledgeID?: string | null,
          message: string,
          relatedItems?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      NotifyACKs?:  {
        __typename: "ModelNotifyACKConnection",
        items:  Array< {
          __typename: "NotifyACK",
          id: string,
          ownerID: string,
          userID: string,
          status: ACKStatus,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    log:  {
      __typename: "Log",
      id: string,
      actualWeight?: number | null,
      ageValue?: number | null,
      protocolID?: Array< string > | null,
      Users?:  {
        __typename: "ModelLogUserConnection",
        items:  Array< {
          __typename: "LogUser",
          id: string,
          userId: string,
          logId: string,
          createdAt: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      departmentID: string,
      oneweightID?: string | null,
      ambulanceID?: string | null,
      incidentNumber?: string | null,
      startTime?: string | null,
      endTime?: string | null,
      estimatedWeight?: number | null,
      medications:  Array< {
        __typename: "LogMedication",
        medicationID: string,
        amntSolid: string,
        amntLiquid: string,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        isActualWeight: boolean,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
      } >,
      equipments:  Array< {
        __typename: "LogEquipment",
        equipmentID: string,
        amount: string,
        name: string,
        snomedCode?: string | null,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        successful: boolean,
        protocolID?: string | null,
      } >,
      electricals:  Array< {
        __typename: "LogElectrical",
        electricalID: string,
        name: string,
        snomedCode?: string | null,
        amount: string,
        timestamp: string,
        isActualWeight: boolean,
        verified: boolean,
        weight: number,
        protocolID?: string | null,
      } >,
      cpr?:  {
        __typename: "LogCPR",
        cprID: string,
        startTime: string,
        endTime: string,
        outcome?: PatientOutcomeType | null,
      } | null,
      patientSaved?: string | null,
      patientReleased?: string | null,
      arrivedAtHospital?: string | null,
      ageTimestamp?: string | null,
      forms:  Array< {
        __typename: "LogForm",
        formID?: string | null,
        name?: string | null,
        completed: string,
        startedTime: string,
        protocolID?: string | null,
      } >,
      subtractedItems?:  Array< {
        __typename: "LogWeightObject",
        weightObjectID?: string | null,
        name: string,
        weight: number,
        added: string,
      } > | null,
      userIDs: Array< string >,
      infusions:  Array< {
        __typename: "LogDrip",
        dripID: string,
        amnt: string,
        amnt2?: string | null,
        timestamp: string,
        verified: boolean,
        name: string,
        rxNormCode?: string | null,
        concentration: string,
        adminType: string,
        route?:  {
          __typename: "MedicationRoute",
          route: string,
          code: string,
        } | null,
        weight: number,
        protocolID?: string | null,
        amntUnit: string,
        amntUnit2?: string | null,
        isActualWeight?: boolean | null,
        gttsDropFactor?: number | null,
        gttsAmount?: number | null,
        gttsUnit?: string | null,
      } >,
      estimatedSaved?: string | null,
      tags: Array< string >,
      shifts?:  Array< {
        __typename: "LogMedicShift",
        shiftStart: string,
        shiftEnd: string,
        completedLogs?: Array< string > | null,
        taggedAmbulance: string,
        taggedOneWeight?: string | null,
        taggedUsers: Array< string >,
        ownerID: string,
        departmentID: string,
        metaData?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    },
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
