import React, { useMemo } from 'react';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import DraftChangeItem, {
  DraftChangeType,
} from '../../../../data/model/DraftChangeItem';
import FullViewDialog from '../fullView/FullViewDialog';
import ProtocolAccordionSecondLayer from '../../protocol/details/ProtocolAccordionSecondLayer';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { toTitleCase } from '../../../_global/common/Utils';
import ProtocolDataTableComparison from './ProtocolDataTableComparison';

interface FolderComparisonProps {
  changeType: DraftChangeType;
  currentDraft: DraftChangeItem;
  changeItem?: ProtocolItem[] | null;
  previousItem?: ProtocolItem[] | null;
  onChangesCount?: (count: number) => void;
}

const compareSubIndexItems = (
  currentItems: ProtocolItem[],
  previousItems: ProtocolItem[]
): number => {
  let changeCount = 0;

  for (let i = 0; i < currentItems.length; i++) {
    const currentItem = currentItems[i];
    const previousItem = previousItems[i];
    if (currentItem.index !== previousItem.index) {
      changeCount++;
    }
  }
  changeCount = 1;
  return changeCount;
};

// export function findBeforeIndexAndName(
//   item: ProtocolItem,
//   previousItems: (ProtocolItem)[]
// ): string {
//   const index = previousItems.findIndex(
//     (prevItem) => prevItem.index === item.index
//   );
//   return `${index + 1}: ${item.name}`;
// }

// export function findAfterIndexAndName(
//   item: ProtocolItem,
//   changeItems: (ProtocolItem)[]
// ): string {
//   const index = changeItems.findIndex(
//     (changeItem) => changeItem.index === item.index
//   );
//   return `${index + 1}: ${item.name}`;
// }

const ProtocolIndexComparison: React.FC<FolderComparisonProps> = ({
  changeItem = [],
  previousItem = [],
  onChangesCount,
  currentDraft,
  changeType,
}) => {
  const changes = useMemo(() => {
    const count = compareSubIndexItems(
      changeItem as ProtocolItem[],
      previousItem as ProtocolItem[]
    );
    if (onChangesCount) {
      onChangesCount(count);
    }
    console.log('changeItem', changeItem);
    console.log('previousItem', previousItem);
    console.log('currentDraft', currentDraft);
    return count;
  }, [changeItem, previousItem, onChangesCount]);
  const headerTitle =
    changeType === DraftChangeType.MEDICATION_INDEX
      ? 'Medication'
      : changeType === DraftChangeType.INFUSION_INDEX
        ? 'Infusion'
        : 'Electrical';

  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">Before</div>{' '}
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">After</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {previousItem?.length >= 1 &&
                      (console.log('previousItem', previousItem),
                      (
                        <div>
                          <ProtocolDataTableComparison
                            handleTabClick={() => {}}
                            protocolData={previousItem}
                            handleReorder={() => {}}
                            comparisonMode={true}
                            handleRename={() => {}}
                            isEditFolderModalOpen={false}
                            isModalOpen={false}
                            isRename={false}
                            keepBefore={true}
                            previousItems={previousItem}
                            changeItems={changeItem}
                          />
                        </div>
                      ))}
                  </td>
                  <td>
                    {changeItem && changeItem?.length >= 1 && (
                      <ProtocolDataTableComparison
                        handleTabClick={() => {}}
                        protocolData={changeItem}
                        handleReorder={() => {}}
                        comparisonMode={true}
                        handleRename={() => {}}
                        isEditFolderModalOpen={false}
                        isModalOpen={false}
                        isRename={false}
                        previousItems={previousItem}
                        changeItems={changeItem}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {changeItem && changeItem?.length >= 1 && (
                    <ProtocolDataTableComparison
                      handleTabClick={() => {}}
                      protocolData={changeItem}
                      handleReorder={() => {}}
                      comparisonMode={true}
                      handleRename={() => {}}
                      isEditFolderModalOpen={false}
                      isModalOpen={false}
                      isRename={false}
                      previousItems={previousItem}
                      changeItems={changeItem}
                    />
                  )}
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProtocolIndexComparison;
