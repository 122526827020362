import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Loading from '../Loading/Loading';
import { Button, Col, Row } from 'react-bootstrap';
import { InputText } from 'primereact/inputtext';
import DepartmentItem from '../../../data/model/DepartmentItem';
import WorkbookItem from '../../../data/model/WorkbookItem';
import { ViewportList } from 'react-viewport-list';
import {
  CalculateByteSting,
  getFormattedDate,
  globals,
} from '../../_global/common/Utils';
import { Storage } from 'aws-amplify';
import { Dialog } from 'primereact/dialog';
import { BiArrowBack } from 'react-icons/bi';
import { on } from 'events';
import { FaTimes } from 'react-icons/fa';
import SearchBar from '../Search/SearchBar';
import { Checkbox } from '@mui/material';
import { fetchWorkbooks } from '../../../data/functions/WorkbookDB';

interface WorkbookParsingModalProps {
  visible: boolean;
  department: DepartmentItem;
  onUpload: (file: File) => void;
  onClose: () => void;
  bypassPreview?: boolean;
}
const baseIndex = 0;

const WorkbookParsingModal: React.FC<WorkbookParsingModalProps> = (props) => {
  const { visible, department, onUpload, onClose, bypassPreview } = props;
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');

  const [selectedWorkbook, setSelectedWorkbook] = useState<WorkbookItem | null>(
    null
  );
  const [workbookURL, setWorkbookURL] = useState('');
  const [startPageError, setStartPageError] = useState('');
  const [endPageError, setEndPageError] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isBypass, setIsBypass] = useState(
    bypassPreview === true ? true : false
  );

  const [searchQuery, setSearchQuery] = useState('');
  const [errorText, setErrorText] = useState('');
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [list, setList] = useState<WorkbookItem[]>([]);
  const [startNumError, setStartNumError] = useState('');
  const ref = useRef();

  const objURL = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else if (workbookURL !== '') return workbookURL;
    return '';
  }, [file, workbookURL]);

  // useEffect(() => {
  //   const lastWorkbookIndex =
  //     Number(localStorage.getItem('lastWorkbookIndex')) + 1;
  //   if (lastWorkbookIndex) setStartPage(lastWorkbookIndex.toString());
  // }, []);

  useEffect(() => {
    const getDetails = async () => {
      const result = await fetchWorkbooks(department, true, true);
      setWorkbookList(result.data);
      if (result.data.length === 1) setSelectedWorkbook(result.data[0]);
    };
    getDetails();
  }, [department]);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery, workbookList]);

  useEffect(() => {
    if (selectedWorkbook !== null) {
      const fetchURL = async () => {
        return await Storage.get(selectedWorkbook.workbookUrl, {
          level: 'public',
        });
      };
      fetchURL().then((url) => {
        setWorkbookURL(url);
      });
    }
  }, [selectedWorkbook]);

  const handleSearch = (searchTerm: string) => {
    const filteredList = workbookList.filter((item) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setList(filteredList);
  };

  const handleStartPageChange = (e: any) => {
    const value = e.target.value;
    setErrorText('');
    if (/^\d*$/.test(value)) {
      setStartPage(value);
      setStartPageError('');
    } else {
      setStartPageError('Please enter a valid number');
    }
  };

  const handleEndPageChange = (e: any) => {
    const value = e.target.value;
    setErrorText('');
    if (/^\d*$/.test(value)) {
      setEndPage(value);
      setEndPageError('');
    } else {
      setEndPageError('Please enter a valid number');
    }
  };

  const valid = () => {
    const startPageNum = Number(startPage);
    const endPageNum = Number(endPage);

    if (
      isNaN(startPageNum) ||
      startPageNum < 1 ||
      isNaN(endPageNum) ||
      (endPageNum < 1 && endPageNum !== 0)
    ) {
      setErrorText(
        'Start Page and End Page must be valid numbers greater than 0.'
      );
      return false;
    }

    if (endPageNum !== 0) {
      if (startPageNum > endPageNum) {
        setErrorText('Start Page must be less than or equal to End Page.');
        return false;
      }
    }
    return true;
  };

  const handleParsingPDF = useCallback(async () => {
    if (!selectedWorkbook) return;
    let id = selectedWorkbook?.workbookUrl;
    if (!id) return;
    setIsPdfLoading(true);
    if (!valid()) {
      setIsPdfLoading(false);
      return;
    }
    const url =
      process.env.REACT_APP_API_GATEWAY_URL ??
      (function () {
        throw new Error('API URL is not defined');
      })();
    const bucketName =
      process.env.REACT_APP_BUCKET_NAME ??
      (function () {
        throw new Error('BUCKET NAME is not defined');
      })();

    const apiUrl = url;
    if (globals.debug) console.log('Parsing PDF...', apiUrl, bucketName);
    const start: Number = Number(startPage) + baseIndex;
    const end: Number = Number(endPage ? endPage : startPage) + baseIndex;
    const requestBody = {
      bucket: bucketName,
      key: `public/${id}`,
      startPage: start.toString(),
      endPage: end.toString(),
      customName: `workBook-${startPage}-${endPage}`,
      depID: department.id,
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (globals.debug) console.log('response', response);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (globals.debug) console.log('result JSON', result);
      if (result.statusCode !== 200) {
        const errorMessages = JSON.parse(result.body);
        if (errorMessages.error === 'sequence index out of range') {
          setErrorText('Invalid Page range');
        } else {
          console.error('Error:', errorMessages.error);
          setErrorText('Error: an unexpected error occurred');
        }
      } else {
        if (globals.debug) console.log('result', result);
      }
      const parsedBody = JSON.parse(result.body);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      const key = processedPdfUrl.split('.com/')[1];
      const keywWithoutPublic = key.split('public/')[1];
      const extractedWorkbook: any = await Storage.get(keywWithoutPublic, {
        level: 'public',
        contentType: 'application/pdf',
        download: true,
      });
      if (extractedWorkbook == null || extractedWorkbook.Body == null) {
        throw new Error('Error extracting workbook');
      }

      const file = new File([extractedWorkbook.Body], selectedWorkbook.name, {
        type: extractedWorkbook.Body.type,
      });

      setFile(file);
      if (isBypass) onUpload(file);
      if (globals.debug)
        localStorage.setItem(
          'lastWorkbookIndex',
          endPage ? endPage : startPage
        );
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsPdfLoading(false);
    }
  }, [selectedWorkbook, startPage, endPage, department.id, isBypass]);

  const onParse = useCallback(() => {
    if (selectedWorkbook !== null) handleParsingPDF();
  }, [selectedWorkbook, handleParsingPDF]);

  return (
    <Dialog
      visible={visible}
      style={{
        display: 'flex',
        width: '90vw',
        maxWidth: '1300px',
        height: '80vh',
        backgroundColor: 'transparent',
      }}
      onHide={onClose}
      showHeader={false}
      // header={<></>}<h4 className='headerText'>Add from Workbook</h4>}

      className="parseDialog"
    >
      {isPdfLoading && <Loading type="bubbles" />}
      <div className="print-modal-container">
        {selectedWorkbook ? (
          <div className="print-modal">
            <div
              style={{
                display: 'flex',
                flex: 1.75,
                flexDirection: 'column',
                backgroundColor: '#F3F3F3',
              }}
            >
              <embed
                src={objURL}
                title="PDF Viewer"
                width={'100%'}
                height="100%"
              />
            </div>
            {file == null ? (
              <div className="modal-actions">
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '20px',
                    width: '100%',
                    marginLeft: '7.5%',
                    marginRight: '7.5%',
                  }}
                >
                  <div
                    className={`buttonContainer contentTitleLarge ${workbookList.length > 1 ? 'hoverText' : ''}`}
                    onClick={() => {
                      if (workbookList.length > 1) setSelectedWorkbook(null);
                    }}
                  >
                    {workbookList.length > 1 && (
                      <span className="">
                        <BiArrowBack
                          className="header-icon cursorPointer"
                          style={{ marginLeft: '4px', marginRight: '8px' }}
                        />
                      </span>
                    )}
                    Parse PDF
                  </div>
                  <div className="contentText" style={{ marginLeft: '10px' }}>
                    Parse the Protocol Set PDF by entering the pages you want to
                    extract.
                  </div>
                </div>
                {errorText && (
                  <span
                    style={{
                      color: 'red',
                      alignSelf: 'flex-start',
                      marginLeft: '10px',
                    }}
                  >
                    Error: {errorText}
                  </span>
                )}
                <div
                  style={{
                    marginBottom: '0px',
                    marginTop: '0px',
                    width: '100%',
                    marginLeft: '7.5%',
                    marginRight: '7.5%',
                  }}
                >
                  <label
                    htmlFor="startPage"
                    className="ketamine-general-label"
                    style={{ marginBottom: '0px' }}
                  >
                    Start Page <span className="required-field">*</span>
                  </label>
                  <div
                    className="contentText"
                    style={{ marginLeft: '10px', marginTop: '0px' }}
                  >
                    This is inclusive of the page number.
                  </div>
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="name"
                    name="startPage"
                    data-testid="startPage"
                    value={startPage}
                    autoFocus
                    placeholder="Start Page..."
                    onChange={handleStartPageChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleParsingPDF();
                      }
                    }}
                  />
                  {startPageError && (
                    <div
                      style={{
                        color: 'red',
                        alignSelf: 'flex-start',
                        marginLeft: '10px',
                      }}
                    >
                      {startPageError}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: '20px',
                    width: '100%',
                    marginLeft: '7.5%',
                    marginRight: '7.5%',
                  }}
                >
                  <label
                    htmlFor="endPage"
                    className="ketamine-general-label"
                    style={{ marginBottom: '0px' }}
                  >
                    End Page
                  </label>
                  <div
                    className="contentText"
                    style={{ marginLeft: '10px', marginTop: '0px' }}
                  >
                    This is inclusive of the page number.
                  </div>
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="endPage"
                    name="endPage"
                    data-testid="endPage"
                    value={endPage}
                    placeholder="Optional..."
                    onChange={handleEndPageChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleParsingPDF();
                      }
                    }}
                  />
                  {endPageError && (
                    <div
                      style={{
                        color: 'red',
                        alignSelf: 'flex-start',
                        marginLeft: '10px',
                      }}
                    >
                      {endPageError}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    marginBottom: '20px',
                    width: '100%',
                    marginLeft: '7.5%',
                    marginRight: '7.5%',
                  }}
                >
                  {Number(startPage) > 0 && !isNaN(Number(startPage)) && (
                    <label
                      htmlFor="endPage"
                      className="ketamine-general-label"
                      style={{ marginBottom: '0px' }}
                    >
                      {`Extracting ${endPage && Number(endPage) > Number(startPage) ? 'Pages' : 'Page'}: ${startPage}${endPage && Number(endPage) > Number(startPage) ? ' - ' + endPage : ''} (${endPage && Number(endPage) > Number(startPage) ? Number(endPage) - Number(startPage) + 1 + ' pages' : '1 page'})`}
                    </label>
                  )}
                </div>
                {globals.debug && (
                  <div
                    style={{
                      marginBottom: '20px',
                      width: '100%',
                      marginLeft: '7.5%',
                      marginRight: '7.5%',
                    }}
                  >
                    <label htmlFor="options" className="">
                      <Checkbox
                        name="options"
                        value={isBypass}
                        onChange={(e) => {
                          setIsBypass(e.target.checked);
                        }}
                        checked={isBypass}
                        style={{ marginRight: '8px' }}
                        className="routeCheckbox"
                      />
                      Bypass Preview
                    </label>
                  </div>
                )}

                <div
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    className="secondary-button-small-border btn-rightMargin"
                    style={{
                      borderRadius: '5px',
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-button-small"
                    style={{
                      borderRadius: '5px',
                    }}
                    onClick={onParse}
                  >
                    Parse
                  </Button>
                </div>
              </div>
            ) : (
              <div className="modal-actions">
                <div
                  style={{
                    marginBottom: '10px',
                    marginTop: '20px',
                    width: '100%',
                    marginLeft: '7.5%',
                    marginRight: '7.5%',
                  }}
                >
                  <div
                    className="buttonContainer contentTitleLarge hoverText"
                    onClick={() => {
                      setFile(null);
                      //TODO: Remove the staging file from the S3 bucket
                    }}
                  >
                    <span className="">
                      <BiArrowBack
                        className="header-icon cursorPointer"
                        style={{ marginLeft: '4px', marginRight: '8px' }}
                      />
                    </span>
                    PDF Preview
                  </div>
                  <div className="contentText" style={{ marginLeft: '10px' }}>
                    Preview of the PDF that was parsed.
                  </div>
                </div>
                <div
                  className="input-container roundBorder"
                  style={{ width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '5px',
                      flexDirection: 'column',
                    }}
                    className="ketamine-general-label"
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ marginRight: '10px' }}>
                        From Protocol Set:
                      </div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {selectedWorkbook.name}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ marginRight: '10px' }}>
                        {`Page${endPage ? 's' : ''}:`}
                      </div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {`${startPage}${endPage ? ' - ' + endPage : ''} (${endPage ? Number(endPage) - Number(startPage) + 1 + ' pages' : '1 page'})`}
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ marginRight: '10px' }}>File Size:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '0px' }}>
                        {CalculateByteSting(file.size)}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    flexDirection: 'row',
                  }}
                >
                  <Button
                    className="secondary-button-small-border btn-rightMargin"
                    style={{
                      borderRadius: '5px',
                    }}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="primary-button-small"
                    style={{
                      borderRadius: '5px',
                    }}
                    onClick={() => {
                      onUpload(file);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="print-modal">
            <div
              style={{
                flex: 1,
                padding: '20px',
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
                border: '1px solid #e0e0e0',
                borderRadius: '5px',
                backgroundColor: '#fff',
              }}
              className="ketamine-general-label"
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                <h5 className="ketmine-header-text">Select Protocol Set</h5>
                <span
                  className="iconButton"
                  style={{
                    position: 'absolute',
                    right: '20px',
                  }}
                  onClick={() => {
                    onClose();
                  }}
                >
                  <FaTimes
                    data-testid="isFullScreenButton"
                    className="icon"
                    color="#9e9e9e"
                  />
                </span>
              </div>
              <SearchBar
                // style={{width: '100%'}}
                containerStyle={{ width: '100%' }}
                value={searchQuery}
                onChange={(searchTerm: string, e: any) => {
                  setSearchQuery(searchTerm);
                  handleSearch(searchTerm);
                }}
                placeholder={'Search Protocol Sets...'}
              />
              <div
                className="contentBorder protocolCalculationPad primaryListScroll"
                style={{ marginBottom: '10px' }}
              >
                <ViewportList items={list}>
                  {(item: WorkbookItem, index: number) => (
                    <div
                      key={index}
                      className=" cursorPointer hoverItem"
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                        borderBottom:
                          index !== list.length - 1
                            ? '1px solid #e0e0e0'
                            : 'none',
                      }}
                      onClick={() => {
                        setSelectedWorkbook(item);
                      }}
                    >
                      <span className={'a1Content'} style={{ width: '100%' }}>
                        <div
                          className="notification-css-grey"
                          style={{ fontSize: '14px' }}
                        >
                          {item.model.updatedAt &&
                            getFormattedDate(item.model.updatedAt, false)}
                        </div>

                        <div
                          style={{ fontSize: '16px', marginBottom: '8px' }}
                          className="contentTitle a1SubITems"
                        >
                          {item.name}
                        </div>
                      </span>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default WorkbookParsingModal;
