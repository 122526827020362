import React, { useState } from 'react';

import { BiCopy, BiSolidCopy } from 'react-icons/bi';

import { ModelMetaData, User } from '../../../../models';

import { Col, Row } from 'react-bootstrap';

import { ProgressStatus } from '../../../../models';
import { useSelector } from 'react-redux';
import { UserType } from '../../../../models';
import { IoArrowBack } from 'react-icons/io5';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
interface FolderComparisonProps {
  focusItem: ElectricalItem;
  handleBack?: () => void;
  viewText?: string;
  changes?: any;
  showHeader?: boolean;
  keepBefore?: boolean;
}

const ElectricalComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  handleBack,
  viewText,
  changes,
  showHeader,
  keepBefore,
}) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const user: User = useSelector((state: any) => state?.user);

  const handleCopy = (text: string, event: any) => {
    event.stopPropagation(); // Prevents the click event from bubbling up
    navigator.clipboard.writeText(text);

    setIsCopied(text);
    setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
  };

  return (
    <div>
      <div className="ketamineContent">
        <div
          className="KetamineGeneral"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          {showHeader && (
            <h5
              className="ketmine-header-text backTextLight hoverText"
              style={{ color: 'black' }}
              onClick={handleBack}
            >
              <span>
                <IoArrowBack
                  data-testid="isBackBttn"
                  size="1.15rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
              General Information
            </h5>
          )}

          {showHeader && (
            <div className="input-container roundBorder">
              <div>
                <div
                  style={{ display: 'flex', marginTop: '5px' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Modified By:</div>
                  <div style={{ fontWeight: '500' }}>
                    {focusItem.modifiedBy
                      ? focusItem.modifiedBy.firstName +
                        ' ' +
                        focusItem.modifiedBy.lastName
                      : 'Hinckley Medical'}
                  </div>
                </div>

                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Version:</div>
                  <div style={{ fontWeight: '500' }}>{focusItem.version}</div>
                </div>

                {user.type === UserType.ADMIN && (
                  <div
                    style={{ display: 'flex' }}
                    className="ketamine-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>ID:</div>
                    <div style={{ fontWeight: '500' }}>
                      {focusItem.uid}
                      <span>
                        {isCopied && isCopied === focusItem.uid ? (
                          <BiSolidCopy
                            color={'#00534C'}
                            size="1rem"
                            className="copy_icon"
                          />
                        ) : (
                          <BiCopy
                            size="1rem"
                            className="copy_icon"
                            onClick={(e) => handleCopy(focusItem.uid, e)}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                )}
                {user.type === UserType.ADMIN &&
                  focusItem.status === ProgressStatus.DRAFT &&
                  focusItem.activeID != null && (
                    <div
                      style={{ display: 'flex' }}
                      className="ketamine-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Active ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {focusItem.activeID}
                        <span>
                          {isCopied && isCopied === focusItem.activeID ? (
                            <BiSolidCopy
                              color={'#00534C'}
                              size="1rem"
                              className="copy_icon"
                            />
                          ) : (
                            <BiCopy
                              size="1rem"
                              className="copy_icon"
                              onClick={(e) =>
                                handleCopy(focusItem.activeID as string, e)
                              }
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {(findBeforeValue(changes, 'name') ||
            findCurrentValue(changes, 'name')) && (
            <>
              {' '}
              <label htmlFor="Name" className="ketamine-general-label">
                Name <span className="required-field">*</span>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'name')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'name')}
                    afterText={findCurrentValue(changes, 'name')}
                  />
                )}
              </div>
            </>
          )}
          <Row>
            {(findBeforeValue(changes, 'rangeLow') ||
              findCurrentValue(changes, 'rangeLow')) && (
              <Col sm={6}>
                <label htmlFor="rangeLow" className="ketamine-general-label">
                  Range Low (kg)
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                    minHeight: '30px',
                  }}
                >
                  {keepBefore ? (
                    <div>{findBeforeValue(changes, 'rangeLow')}</div>
                  ) : (
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'rangeLow')}
                      afterText={findCurrentValue(changes, 'rangeLow')}
                    />
                  )}
                </div>
              </Col>
            )}
            {(findBeforeValue(changes, 'rangeHigh') ||
              findCurrentValue(changes, 'rangeHigh')) && (
              <Col sm={6}>
                <label htmlFor="rangeHigh" className="ketamine-general-label">
                  Range High (kg)
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                    minHeight: '30px',
                  }}
                >
                  {keepBefore ? (
                    <div>{findBeforeValue(changes, 'rangeHigh')}</div>
                  ) : (
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'rangeHigh')}
                      afterText={findCurrentValue(changes, 'rangeHigh')}
                    />
                  )}
                </div>
              </Col>
            )}
          </Row>
          {(findBeforeValue(changes, 'warning') ||
            findCurrentValue(changes, 'warning')) && (
            <>
              {' '}
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning:{' '}
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'warning')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'warning')}
                    afterText={findCurrentValue(changes, 'warning')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'instruction') ||
            findCurrentValue(changes, 'instruction')) && (
            <>
              <label htmlFor="Instructions" className="ketamine-general-label">
                Instructions:{' '}
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'instruction')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'instruction')}
                    afterText={findCurrentValue(changes, 'instruction')}
                  />
                )}
              </div>
            </>
          )}
          {(findBeforeValue(changes, 'note') ||
            findCurrentValue(changes, 'note')) && (
            <>
              <label htmlFor="Note" className="ketamine-general-label">
                Note:{' '}
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                }}
              >
                {keepBefore ? (
                  <div>{findBeforeValue(changes, 'note')}</div>
                ) : (
                  <HighlightChanges
                    beforeText={findBeforeValue(changes, 'note')}
                    afterText={findCurrentValue(changes, 'note')}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElectricalComparisonView;
