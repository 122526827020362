import React, { useState } from 'react';

import ModelItem from '../../../../data/model/ModelInterface';

import DraftChangeItem from '../../../../data/model/DraftChangeItem';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem | null;
  changeItem?: ModelItem | null;
  previousItem?: ModelItem | null;
}
const OtherComparison: React.FC<FolderComparisonProps> = ({
  changeItem,
  previousItem,
}) => {
  const compareItems = (itemA: any, itemB: any, fieldName: string) => {
    if (itemA === itemB) {
      return <span>{itemA}</span>;
    }
    return (
      <span>
        <span style={{ color: 'red' }}>{itemA}</span> vs{' '}
        <span style={{ color: 'green' }}>{itemB}</span> ({fieldName})
      </span>
    );
  };

  return (
    <div>
      <h1>Folder Comparison</h1>
      <div>
        <h2>Comparison Details</h2>
      </div>
    </div>
  );
};

export default OtherComparison;
