import ProtocolItem from './ProtocolItem';
import { Category, Department, ProgressStatus, User } from '../../models';
import DepartmentItem from './DepartmentItem';
import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import CategoryItem from './CategoryItem';
import { Group, GroupType } from '../../API';

export const cloneGroup = (group: GroupItem): GroupItem => {
  const clone = new GroupItem(group.getModel());
  return clone;
};

class GroupItem extends ModelItem<Group> {
  type: GroupType;
  isAutoGen: boolean;
  pairedUserIDs: string[];

  constructor(group: Group) {
    super(group, group.name, group.departmentID);
    this.TAG = 'GroupItem';
    this.type = this.getGroupType(group.type);
    this.pairedUserIDs = group.pairedUserIDs;
    this.isAutoGen = group.isAutoGen;
  }

  getGroupType(status: GroupType | string): GroupType {
    switch (status) {
      case 'NOTIFICATION':
        return GroupType.NOTIFICATION;
      default:
        return GroupType.NOTIFICATION;
    }
  }

  /**
   * Gets the uid of the category from the database.
   * @returns The uid of the category.
   */
  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the name of the category.
   * @returns The name of the category.
   */
  getName(): string {
    return this.name;
  }

  getDepartmentID(): string {
    return this.departmentID;
  }

  getPairedDepIDs(): string[] | null | undefined {
    return this.pairedDepIDs;
  }

  getPairedUserIDs(): string[] | null | undefined {
    return this.pairedUserIDs;
  }

  /**
   * Gets the description of the category. Ex. "A Airway"
   * @returns The description of the category. Ex. "A Airway"
   */
  getDescription(): string {
    return this.makeDetails();
  }

  /* ------------- Private Helpers ------------- */
  /**
   * Gets the description of the category. Ex. "A Airway"
   * @returns The description of the category. Ex. "A Airway"
   */
  private makeDetails(): string {
    return `${this.name}`;
  }

  /**
   * Gets the string representation of the CategoryItem object for debugging.
   * @returns string representation of the CategoryItem object
   */
  toString(): string {
    return `GroupItem -> {
      id: ${this.uid},
      name: ${this.name},
      type: ${this.type},
      departmentID: ${this.departmentID},
      pairedDepIDs: ${this.pairedDepIDs},
      pairedUserIDs: ${this.pairedUserIDs},
      }`;
  }
}

export default GroupItem;
