import { FormQuestion, Group, Notification, NotifyACK } from '../../API';
import { NotificationType, Question, User } from '../../models';
import DepartmentItem from './DepartmentItem';
import GroupItem from './GroupItem';
import ProtocolItem from './ProtocolItem';

export const NOTIFICATION_TYPE = {
  GENERAL: 'GENERAL',
  ACKNOWLEDGE: 'ACKNOWLEDGE',
  TRAINING: 'TRAINING',
};

class NotificationItem {
  /* Used for ALL notifications */
  uid: string;
  depID: string;
  type: NotificationType;
  title: string;
  isPush: boolean;
  message: string | null | undefined;
  timestamp: Date;
  deadline: Date | null | undefined;
  isReadIDs: string[];

  dbNotification: Notification;

  isAckIds: string[];
  fileURLs: string[];
  taggedProtocols: ProtocolItem[];
  modifiedBy: User | null = null;
  pairedDepIDs: string[] | null | undefined;
  pairedDeps: DepartmentItem[];
  acknowledged: NotifyACK[] = [];
  viewed: NotifyACK[] = [];

  questions: Question[];
  formQuestions: FormQuestion[];
  groups: GroupItem[] | null | undefined;

  constructor(notification: Notification, protocols?: ProtocolItem[]) {
    this.uid = notification.id;
    this.depID = notification.departmentID;
    this.type =
      notification.type === 'GENERAL'
        ? NotificationType.GENERAL
        : notification.type === 'ACKNOWLEDGE'
          ? NotificationType.ACKNOWLEDGE
          : NotificationType.TRAINING;
    this.title = notification.title;
    this.message = notification.message;
    this.timestamp = new Date(notification.timestamp);
    this.deadline = notification.deadlineTimestamp
      ? new Date(notification.deadlineTimestamp)
      : null;
    this.questions = notification.questions;
    this.pairedDepIDs = notification.pairedDepIDs;
    this.isPush = notification.isPush;
    this.pairedDeps = [];

    this.isReadIDs = [...notification.isReadIDs];
    this.dbNotification = notification;

    /* Acknowledgement & Training notifications */
    this.isAckIds = [...notification.isAckIDs];
    this.fileURLs = notification.fileURLs ? notification.fileURLs : [];
    this.taggedProtocols = protocols ? protocols : [];

    // this.isReadIDs = notification.isReadIDs;

    // /* Acknowledgement & Training notifications */
    // this.isAckIds        = notification.isAckIDs;
    // this.imageURIs       = notification.imageURLs;
    // this.videoURIs       = notification.videoURLs;
    // this.taggedProtocols = notification.taggedProtocols;

    this.questions = notification.questions;
    this.formQuestions = notification.formQuestions;
    if (notification.Groups && Array.isArray(notification.Groups)) {
      this.groups = (notification.Groups as any[]).map(
        (groupNotification: { group: Group }) => {
          const group = groupNotification.group;
          return new GroupItem(group);
        }
      );
    } else if (
      notification.Groups &&
      notification.Groups.items &&
      Array.isArray(notification.Groups.items)
    ) {
      this.groups = (notification.Groups.items as any[]).map(
        (groupNotification: { group: Group }) => {
          const group = groupNotification.group;
          return new GroupItem(group);
        }
      );
    } else {
      this.groups = [];
    }
  }

  // constructor(type: NotificationType) {
  //     this.uid        = "1234-567-890";
  //     this.depID      = "00089c2e-9f11-409a-8b37-afa9924e965c";
  //     this.type       = type;
  //     this.title      = "Updated protocol E3. Medication Assisted Airway";
  //     this.message    = "This is an example message. There can be as many lines as needed. The notification should not cut off the text. There will be a maximum set in the website that is allowed.";
  //     this.timestamp  = new Date().toISOString();

  //     /* This will store the ID of the users who have read the notification */
  //     this.isReadIDs  = [];

  //     /* This will store the ID of the users who have acknowledged the notification */
  //     this.isAckIds        = [];

  //     this.imageURIs       = [];
  //     this.videoURIs       = [];
  //     this.taggedProtocols = [];
  //     this.questions       = [];

  //     if(type == NotificationType.TRAINGING) {
  //         this.questions.push({
  //             question: "What medication changed?",
  //             options: ["Atropine", "Epinephrine", "Dextrose", "Albuterol"],
  //             answer: "Epinephrine",
  //             taggedProtocol: "1234-567-890",
  //         });
  //         this.questions.push({
  //             question: "What medication changed?",
  //             options: ["Atropine", "Epinephrine", "Dextrose", "Albuterol"],
  //             answer: "Epinephrine",
  //             taggedProtocol: "1234-567-890",
  //         });
  //         this.questions.push({
  //             question: "What medication changed?",
  //             options: ["Atropine", "Epinephrine", "Dextrose", "Albuterol"],
  //             answer: "Epinephrine",
  //             taggedProtocol: "1234-567-890",
  //         });
  //         this.questions.push({
  //             question: "What medication changed?",
  //             options: ["Atropine", "Epinephrine", "Dextrose", "Albuterol"],
  //             answer: "Epinephrine",
  //             taggedProtocol: "1234-567-890",
  //         });
  //     }

  // }

  /**
   * Checks if the given object is equal to this object.
   * @param obj The object to compare to this object.
   * @returns True if the objects are equal, false otherwise.
   */
  equals(obj: any): boolean {
    return obj instanceof NotificationItem && this.uid === obj.uid;
  }

  /**
   * Gets the unique id of the notification.
   * @returns The id of the notification.
   */
  getUid(): string {
    return this.uid;
  }

  /**
   * Gets the department id of the owner of this notification.
   * @returns the department id of the owner of this notification
   */
  getDepID(): string {
    return this.depID;
  }

  /**
   * Gets the type of the notification.
   * @returns The type of the notification.
   */
  getType(): NotificationType {
    return this.type;
  }

  /**
   * Gets the title of the notification.
   * @returns The title of the notification.
   */
  getTitle(): string {
    return this.title;
  }

  /**
   * Gets the message of the notification.
   * @returns The message of the notification.
   */
  getMessage(): string {
    if (this.message == null || this.message == undefined) return '';
    return this.message;
  }

  /**
   * Check if the current user has read the notification.
   * @param userID The id of the user to check.
   * @returns if the notification has been read by the user
   */
  hasUserRead(userID: string): boolean {
    return this.isReadIDs.includes(userID);
  }

  /**
   * Check if the current user has acknowledged the notification.
   * @param userID The id of the user to check.
   * @returns if the notification has been acknowledged by the user
   */
  hasUserAcknowledged(userID: string): boolean {
    return this.isAckIds.includes(userID);
  }

  /**
   * Get the questions to prompt the user for the training notification.
   * @returns An array of questions for the training notification.
   */
  getQuestions(): Question[] {
    return this.questions;
  }

  /**
 Get the formQuestions to prompt the user for the training notification.
   * @returns An array of formQuestions for the training notification.
    */
  getFormQuestions(): FormQuestion[] {
    return this.formQuestions;
  }

  /**
   * Get the protocols tagged in the notification.
   * @returns An array of protocols tagged in the notification.
   */
  getTaggedProtocols(): ProtocolItem[] {
    return this.taggedProtocols;
  }

  addGroups(groups: GroupItem[]) {
    this.groups = groups;
  }

  /**
   * Get the user who last modified the notification.
   * @param user the user to add to the list of users who have read the notification
   */
  setModifiedBy(user: User) {
    this.modifiedBy = user;
  }

  /* ------------- Private Helpers ------------- */
  /**
   * Gets the description of the category. Ex. "A Airway"
   * @returns The description of the category. Ex. "A Airway"
   */
  private makeDetails(): string {
    return `${this.title} ${this.message}`;
  }

  /**
   * Gets the string representation of the CategoryItem object for debugging.
   * @returns string representation of the CategoryItem object
   */
  toString(): string {
    let t =
      this.type == NotificationType.GENERAL
        ? 'General'
        : this.type == NotificationType.ACKNOWLEDGE
          ? 'Acknowledgement'
          : 'Training';
    return `${t} NotificationItem -> {
            uid=${this.uid},
            title=${this.title},
            message=${this.message},
            timestamp=${this.timestamp},
            isReadIDs=${this.isReadIDs.length},
            isAckIds=${this.isAckIds.length},
            fileURLs=${this.fileURLs.length},
            taggedProtocols=${this.taggedProtocols.length},
            questions=${this.questions.length},
            formQuestions=${this.formQuestions.length},
            groups=${this.groups},
        }`;
  }
}

export default NotificationItem;
