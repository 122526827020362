import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaChevronRight, FaFolderOpen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { handleDataTableRowSelection } from '../../../../store/actions';
import {
  findDepartmentOwner,
  getFormattedDate,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import CategoryItem from '../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { User } from '../../../../models';
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ViewportList } from 'react-viewport-list';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import {
  findAfterIndexAndName,
  findBeforeIndexAndName,
} from '../../review/reviewComparsion/DoseIndexComparsion';
import HighlightChanges from '../../review/HighlightChanges';
import { IoDocumentText } from 'react-icons/io5';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import { FaLock } from 'react-icons/fa6';
import { globals } from '../../../_global/common/Utils';

// Helper function to get the clientY position of a DOM element
const getClientYPosition = (draggableId: string) => {
  const draggableElement = document.querySelector(
    `[data-rbd-drag-handle-draggable-id='${draggableId}']`
  );
  if (draggableElement) {
    const { top, bottom } = draggableElement.getBoundingClientRect();
    return (top + bottom) / 2; // Get the vertical midpoint of the dragged element
  }
  return null;
};

// Custom auto-scroll function using requestAnimationFrame
const autoScroll = (event: DragUpdate) => {
  const scrollableContainer = document.querySelector('.protocolListContainer'); // Adjust selector as needed
  if (!scrollableContainer) return;

  const threshold = 100; // Distance from top or bottom to start scrolling
  const maxScrollSpeed = 500; // Maximum speed for scrolling
  const minScrollSpeed = 10; // Minimum speed for scrolling
  const { destination, draggableId } = event;

  // Early exit if there is no destination (i.e., dragging outside droppable area)
  if (!destination) return;

  // Get the clientY position of the dragged item
  const clientY = getClientYPosition(draggableId);
  if (clientY === null) return;

  // Get container's bounding rectangle
  const { top, bottom } = scrollableContainer.getBoundingClientRect();

  // Determine the speed based on proximity to edges
  let scrollSpeed = 0;
  if (clientY - top < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (clientY - top) / threshold),
      minScrollSpeed
    );
  } else if (bottom - clientY < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (bottom - clientY) / threshold),
      minScrollSpeed
    );
  }

  // Scroll the container using requestAnimationFrame for smoother performance
  const scroll = () => {
    if (scrollSpeed !== 0) {
      scrollableContainer.scrollBy({
        top: scrollSpeed > 0 ? scrollSpeed : -scrollSpeed,
      });
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
interface ProtocolDataTableProps {
  handleTabClick: (
    item: CategoryItem | ProtocolItem,
    isDoubleClick: boolean
  ) => void;
  protocolData: (CategoryItem | ProtocolItem)[];
  newButtonRef?: any;
  isRename?: boolean;
  isModalOpen?: boolean;
  isEditFolderModalOpen?: boolean;
  isMoveModalOpen?: boolean;
  handleRename?: any;
  handleReorder: (items: (CategoryItem | ProtocolItem)[]) => void;
  comparisonMode?: boolean;
  keepBefore?: boolean;
  previousItems?: ProtocolItem[] | null;
  changeItems?: ProtocolItem[] | null;
}

/* 10-10-2023 Arul: Created the seperate datatable component for Edit Folder Page*/
const ProtocolDataTableComparison: React.FC<ProtocolDataTableProps> = ({
  handleTabClick,
  handleRename,
  isModalOpen,
  isEditFolderModalOpen,
  isMoveModalOpen,
  isRename,
  protocolData,
  newButtonRef,
  handleReorder,
  comparisonMode,
  keepBefore,
  previousItems,
  changeItems,
}) => {
  const [folderData, setFolderData] = useState<any>([]);
  const dataTableRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const tableData = useSelector((state: any) => state?.protocol?.editDatatable);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database?.department;
  const [inputValue, setInputValue] = useState<any>('');
  const [nameChanged, setNameChanged] = useState<any>(false);
  const [isEditFolder, setIsEditFolder] = useState<any>(isEditFolderModalOpen);
  const [lastSelectedTime, setLastSelectedTime] = useState<any>(null);
  const [isHovered, setIsHovered] = useState<string | null>(null);

  const [showRestrictive, setShowRestrictive] = useState<boolean>(false);
  const [showIndex, setShowIndex] = useState<boolean>(false);

  /* 10-20-2023 Arul: initialize the selected category and protocol name*/
  useEffect(() => {
    setInputValue(tableData.selectedRowData?.name);
    setNameChanged(false);
  }, [tableData.selectedRowData]);

  useEffect(() => {
    setIsEditFolder(isEditFolderModalOpen);
  }, [isEditFolderModalOpen]);

  /* 10-20-2023 Arul: Update the Row selection and selected row data in state*/
  const handleUpdataRowSelection = (isSelected: boolean) => {
    if (!isModalOpen) {
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    }
  };

  // const reorder = (
  //   list: (CategoryItem | ProtocolItem)[],
  //   startIndex: number,
  //   endIndex: number
  // ): (CategoryItem | ProtocolItem)[] => {
  // //Verify the owner of the items are grouped together
  // const result = Array.from(list);
  // const [removed] = result.splice(startIndex, 1);
  // result.splice(endIndex, 0, removed);
  // return result;
  // };

  const reorder = (
    list: (CategoryItem | ProtocolItem)[],
    startIndex: number,
    endIndex: number
  ): (CategoryItem | ProtocolItem)[] => {
    // Determine if the item being dragged or the target is in a locked area
    const isStartLocked = hasAdminUserAccess(
      department,
      user,
      list[startIndex]
    );
    const isEndLocked = hasAdminUserAccess(department, user, list[endIndex]);

    // Prevent dragging an unlocked item into a locked area
    if (isStartLocked && !isEndLocked) return list;
    // let departmentOwner = findDepartmentOwner(department, list[startIndex]);

    //Verify the owner of the items are grouped together
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // function for dragging
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedItems = reorder(
      folderData,
      result.source.index,
      result.destination.index
    );
    setFolderData(updatedItems);
    /* Udpate the database here */
    handleReorder(updatedItems);
    // onReorder(updatedItems, type);
  };

  // Function to handle custom scrolling
  // Custom auto-scroll function

  /* 10-20-2023 Arul: Mapping the table data in setstate*/
  useEffect(() => {
    if (protocolData?.length) {
      setFolderData(protocolData);
    } else {
      setFolderData([]);
    }
  }, [protocolData]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Example: Detect "Ctrl + N"
      if (event.ctrlKey && event.shiftKey && event.key === 'R') {
        event.preventDefault();
        setShowRestrictive(!showRestrictive);
      } else if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
        setShowIndex(!showIndex);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showRestrictive, showIndex]);

  /* 10-16-2023 Arul: Removing the row selection while clicking apart from the table Row*/
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isModalOpen, inputValue, isEditFolder]);

  /* 10-16-2023 Arul: function for clicking outside to remove selection and update rename*/
  const handleDocumentClick = (e: any) => {
    if (
      dataTableRef?.current?.contains(e.target) ||
      isRename ||
      isEditFolder ||
      isMoveModalOpen
    ) {
      return;
    }
    if (globals.debug)
      console.log(
        'DOCUMENT CLICKED',
        inputValue,
        nameChanged,
        isEditFolderModalOpen
      );
    if (tableData?.selectedRowData) handleUpdataRowSelection(false);
    // handleUpdataRowSelection(false);
    if (nameChanged && inputValue !== '') handleRename(inputValue);
  };

  const iconBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    if (item instanceof CategoryItem) {
      return (
        <div className={isSelected ? 'column_selected' : 'column_unselected'}>
          <FaFolderOpen className="icon-normal" style={{ margin: '4px' }} />
        </div>
      );
    } else {
      return (
        <div className={isSelected ? 'column_selected' : 'column_unselected'}>
          <AiOutlineFileText
            className="icon-normal"
            style={{ margin: '4px' }}
          />
        </div>
      );
    }
  };

  const arrowIconBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <div
        className={isSelected ? 'column_selected' : 'column_unselected'}
        style={{ justifyContent: 'flex-end' }}
      >
        <FaChevronRight className="icon-normal " style={{ margin: '4px' }} />
      </div>
    );
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (globals.debug) console.log('ENTER PRESSED', inputValue, nameChanged);
      if (nameChanged && inputValue !== '') handleRename(inputValue);
    }
  };

  const nameBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    const handleInputChange = (e: any) => {
      setNameChanged(true);
      setInputValue(e.target.value);
    };
    return (
      <div>
        {isRename && isSelected ? (
          /* 10-20-2023 Arul:  Rename textinput section*/
          <span
            className={isSelected ? 'column_selected' : 'column_unselected'}
          >
            <form
              onSubmit={() => {
                handleRename(inputValue);
              }}
            >
              <InputText
                value={inputValue}
                autoFocus
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                style={{ padding: '10px', margin: '-10px' }}
              />
            </form>
          </span>
        ) : (
          <div className={isSelected ? 'column_selected' : 'column_unselected'}>
            <div
              className="hoverableText"
              onClick={(e) => {
                e.stopPropagation();
                handleTabClick(item, true);
              }}
            >
              {showIndex ? item.index + ' - ' : ''}
              {item?.name}
              {item.status === 'DRAFT' ? ' (DRAFT)' : ''}
            </div>
          </div>
        )}
      </div>
    );
  };

  const modifiedAtBodyTemplate = (
    item: CategoryItem | ProtocolItem,
    index: any
  ) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <div data-testid={`selectData-${index.rowIndex}`}>
        <span className={isSelected ? 'column_selected' : 'column_unselected'}>
          {getFormattedDate(
            item?.model?.createdAt ? item?.model?.createdAt : new Date(),
            true
          )}
        </span>
      </div>
    );
  };

  const modifiedUserBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    const userName: string = item?.modifiedBy
      ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
      : 'Hinckley Medical';
    return (
      <span className={isSelected ? 'column_selected' : 'column_unselected'}>
        {userName}
      </span>
    );
  };

  const fileSizeBodyTemplate = (item: CategoryItem | ProtocolItem) => {
    const isSelected = item.equals(tableData.selectedRowData);
    return (
      <span className={isSelected ? 'column_selected' : 'column_unselected'}>
        {item?.getSum()}
      </span>
    );
  };

  /* 10-11-2023  Arul:Function to handle the single onrow click */
  const handleRowClick = (item: ProtocolItem | CategoryItem) => {
    handleRename('');
    if (tableData?.selectedRowData?.name === item?.name) {
      /* 10-19-2023  Arul:Update the row unselection in state*/
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    } else {
      /* 10-19-2023  Arul:Update the row selection in state*/
      dispatch<any>(handleDataTableRowSelection({ selectedRowData: item }));
    }
    // let target = event?.originalEvent?.target;
    // if (target?.nodeName !== 'DIV') {
    //     handleRename('');
    //     if (tableData?.selectedRowData?.name === event?.data?.name) {
    //         /* 10-19-2023  Arul:Update the row unselection in state*/
    //         dispatch<any>(handleDataTableRowSelection({ selectedRowData: null }));
    //     } else {
    //         /* 10-19-2023  Arul:Update the row selection in state*/
    //         dispatch<any>(handleDataTableRowSelection({ selectedRowData: event?.data }));
    //     }
    // }
  };

  const handleInputChange = (e: any) => {
    setNameChanged(true);
    setInputValue(e.target.value);
  };

  const handleItemClick = (e: any, item: CategoryItem | ProtocolItem) => {
    e.stopPropagation();
    if (lastSelectedTime && new Date().getTime() - lastSelectedTime < 300)
      handleTabClick(item, true);
    else handleRowClick(item);
    setLastSelectedTime(new Date().getTime());
  };

  return (
    <div>
      <div
        className="editDataTableHeader"
        style={{
          display: 'flex',
          padding: '0px 20px',
          fontSize: comparisonMode ? '12px' : '',
        }}
      >
        {/* <span
          style={{
            flex: 1,
            textAlign: 'start',
          }}
        >
          <input
            className="icon-normal"
            style={{ marginLeft: '5px' }}
            type="checkbox"
            checked={false}
            onChange={() => {
              // handleRowClick(item);
            }}
          />
        </span> */}
        <span
          style={{
            flex: 1,
          }}
        ></span>
        <span style={{ textAlign: 'start', flex: 10 }}>Name</span>
        <span
          style={{
            flex: 6,
          }}
        >
          Modified At
        </span>
        <span
          style={{
            flex: 6,
          }}
        >
          Modified By
        </span>
        {department.subDeps && department.subDeps.length > 0 && (
          <span
            style={{
              flex: 6,
            }}
          >
            Department Subs
          </span>
        )}
        <span
          style={{
            flex: 6,
          }}
        >
          Access
        </span>
        {department.isMultiDep && (
          <span
            style={{
              flex: 6,
            }}
          >
            Owner
          </span>
        )}

        {!comparisonMode && (
          <span
            style={{
              flex: 2,
            }}
          >
            File Size
          </span>
        )}
      </div>
      <DragDropContext onDragUpdate={autoScroll} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="protocolListContainer"
              style={{ fontSize: comparisonMode ? '12px' : '' }}
            >
              {folderData.map(
                (item: CategoryItem | ProtocolItem, index: any) => {
                  const isSelected = comparisonMode
                    ? false
                    : item.equals(tableData.selectedRowData);
                  const userName: string = item?.modifiedBy
                    ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
                    : 'Hinckley Medical';
                  const adminAccess = hasAdminUserAccess(
                    department,
                    user,
                    item
                  );
                  const departmentOwner = findDepartmentOwner(department, item);
                  let availableSubs = (department.allSubDeps?.length ?? 0) + 1;
                  let itemSubs = !item.isRestrictive
                    ? availableSubs
                    : (item.pairedDepIDs?.length ?? 0);
                  let status: 'Private' | 'Public' | 'Protected' =
                    !item.isPublic
                      ? 'Private'
                      : item.keychainID
                        ? 'Protected'
                        : 'Public';
                  if (item instanceof ProtocolItem) {
                    const cat = item.parent;
                    availableSubs = cat.isRestrictive
                      ? (cat.pairedDepIDs?.length ?? -1)
                      : availableSubs;
                    let filteredSubs = item.pairedDepIDs?.filter((depID) =>
                      !cat.isRestrictive
                        ? true
                        : cat.pairedDepIDs?.includes(depID)
                    );
                    itemSubs = item.isRestrictive
                      ? (filteredSubs?.length ?? 0)
                      : availableSubs;
                    if (!item.parent.isPublic) {
                      status = 'Private';
                    }
                  }

                  return (
                    <Draggable
                      key={item.uid}
                      draggableId={item.uid}
                      isDragDisabled={!adminAccess}
                      index={index}
                    >
                      {(provided: any) => (
                        <li
                          key={index}
                          onClick={(e) => handleItemClick(e, item)}
                          onMouseEnter={() => setIsHovered(item.uid)}
                          onMouseLeave={() => setIsHovered(null)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`cursorPointer row_unselected spanFormat ${index === folderData.length - 1 ? '' : 'underline'}`}
                          style={{
                            ...provided.draggableProps.style,
                            backgroundColor: isSelected
                              ? '#E0EADD'
                              : isHovered === item.uid
                                ? '#f3f7f2'
                                : !adminAccess
                                  ? '#f2f2f2'
                                  : undefined,
                            display: 'flex',
                            //7-10-24 Hazlett: PSA Cannot do grid format and a draggable item hd to switch to flex
                          }}
                        >
                          {/* <div
                            style={{
                              flex: 1,
                            }}
                          >
                            <input
                              className="icon-normal"
                              style={{ margin: '4px' }}
                              type="checkbox"
                              checked={isSelected}
                              onChange={() => {
                                handleRowClick(item);
                              }}
                            />
                          </div> */}
                          <div
                            style={{
                              flex: 1,
                            }}
                          >
                            {!adminAccess ? (
                              <FaLock
                                className="table-icon"
                                style={{
                                  marginBottom: '4px',
                                }}
                                color="#A3A3A3"
                              />
                            ) : item instanceof CategoryItem ? (
                              <FaFolderOpen
                                className="table-icon"
                                style={{
                                  marginBottom: '4px',
                                }}
                                color={!adminAccess ? '#A3A3A3' : '#636363'}
                              />
                            ) : (
                              <IoDocumentText
                                className="table-icon"
                                style={{ margin: '0 4px' }}
                              />
                            )}
                          </div>

                          {adminAccess && isRename && isSelected ? (
                            <form
                              style={{
                                flex: 9,
                              }}
                              onSubmit={() => {
                                handleRename(inputValue);
                              }}
                            >
                              <InputText
                                value={inputValue}
                                autoFocus
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                style={{ padding: '10px', margin: '-10px' }}
                              />
                            </form>
                          ) : (
                            <div
                              className="titleItemContainer"
                              style={{
                                flex: 10,
                              }}
                            >
                              <div className="titleItemText">
                                <div
                                  className={`hoverableText ${!adminAccess ? 'disabled' : ''}`}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleTabClick(item, true);
                                  }}
                                >
                                  {comparisonMode ? (
                                    <>
                                      {keepBefore ? (
                                        findBeforeIndexAndName(
                                          item as ProtocolItem,
                                          previousItems as ProtocolItem[]
                                        )
                                      ) : (
                                        <HighlightChanges
                                          beforeText={findBeforeIndexAndName(
                                            item as ProtocolItem,
                                            previousItems as ProtocolItem[]
                                          )}
                                          afterText={findAfterIndexAndName(
                                            item as ProtocolItem,
                                            changeItems as ProtocolItem[]
                                          )}
                                        />
                                      )}
                                    </>
                                  ) : (
                                    (showIndex ? item?.index + ' - ' : '') +
                                    item?.name
                                  )}
                                </div>
                              </div>
                              {item.status !== 'ACTIVE' && (
                                <Status status={item.status} />
                              )}
                            </div>
                          )}

                          <span
                            className="protocolDatatableText"
                            style={{
                              textAlign: 'center',
                              flex: 6,
                              color: !adminAccess ? '#636363' : '#000',
                            }}
                          >
                            {getFormattedDate(
                              item?.model?.updatedAt
                                ? item?.model?.updatedAt
                                : new Date(),
                              true
                            )}
                          </span>
                          <span
                            className="protocolDatatableText"
                            style={{
                              flex: 6,
                              textAlign: 'center',
                              color: !adminAccess ? '#636363' : '#000',
                            }}
                          >
                            {userName}
                          </span>
                          {department &&
                            department.allSubDeps &&
                            department.allSubDeps.length > 0 && (
                              <span style={{ flex: 6, textAlign: 'center' }}>
                                {itemSubs + '/' + availableSubs}
                                {showRestrictive && (
                                  <span
                                    style={{
                                      fontSize: '12px',
                                      color: '#636363',
                                    }}
                                  >
                                    {item.isRestrictive
                                      ? ' (Restrictive)'
                                      : '(Inclusive)'}
                                  </span>
                                )}
                              </span>
                            )}
                          <span style={{ flex: 6, textAlign: 'center' }}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Status status={status} />
                            </div>
                          </span>
                          {department.isMultiDep && (
                            <span style={{ flex: 6, textAlign: 'center' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {departmentOwner && (
                                  <OwnerImage
                                    owner={departmentOwner}
                                    // isLocked={!adminAccess}
                                    size={32}
                                  />
                                )}
                              </div>
                            </span>
                          )}
                          {!comparisonMode && (
                            <span
                              style={{
                                flex: 2,
                                textAlign: 'center',
                                color: !adminAccess ? '#636363' : '#000',
                              }}
                            >
                              {item?.getSum()}
                              <span>
                                <FaChevronRight
                                  className="icon-normal "
                                  style={{ margin: '4px' }}
                                />
                              </span>
                            </span>
                          )}
                        </li>
                      )}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ProtocolDataTableComparison;
