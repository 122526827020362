import { useCallback, useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import CustomPdfUpload from '../CustomPdfUpload';
import { Button } from 'react-bootstrap';
import { IoAdd, IoBook } from 'react-icons/io5';
import SearchableDropdown from '../SearchableDropdown';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { Storage } from 'aws-amplify';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading/Loading';
import {
  findDepartmentOwner,
  findPairedDepartments,
  getFormattedDateTime,
  globals,
  toTitleCase,
} from '../../_global/common/Utils';
import WorkbookParsingModal from './WorkbookParsingModal';
import WorkbookItem from '../../../data/model/WorkbookItem';
import { BiRename } from 'react-icons/bi';
import GeneralSelection from '../Selection/GeneralSelection';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import SearchBar from '../Search/SearchBar';
import CategoryItem from '../../../data/model/CategoryItem';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import SelectDepartmentsModal from './SelectDepartmentsModal';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { fetchWorkbooks } from '../../../data/functions/WorkbookDB';

const NEW_PROTOCOL = 0;
const COPY_PROTOCOL = 1;

/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const FolderProtocolModal = (props: any) => {
  const {
    isVisible,
    handleClose,
    handleAdd,
    handleDuplicateProtocol,
    handleDuplicateFolder,
    breadCrumbs,
    isEdit,
    pdfurl,
    initialPDF,
  } = props;
  const parentFolder = useMemo(() => {
    let item = breadCrumbs[breadCrumbs.length - 1];
    if (item.data == null) return null;
    return item.data as CategoryItem;
  }, [breadCrumbs]);

  const [workbook, setWorkbook] = useState<WorkbookItem[]>([]);
  const [showPrintModal, setShowPrintModal] = useState<boolean>(false);
  const [extractedWorkbook, setExtractedWorkBook] = useState<any>('');
  const [savedWorkbook, setSavedWorkbook] = useState<any>(null);
  const [state, setState] = useState<any>({
    type: NEW_PROTOCOL,
    copyProtocol: null as ProtocolItem | null,
    copyCategory: null as CategoryItem | null,
    search: '',
  });
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);

  const nameRef = React.useRef<HTMLInputElement>(null);

  const [newExtractedWorkbookKey, setNewExtractedWorkbookKey] =
    useState<any>('');
  const [isSelectingDepartments, setIsSelectingDepartments] = useState(false);

  const filteredList: (CategoryItem | ProtocolItem)[] = useMemo(() => {
    if (parentFolder) {
      return database.protocols.filter((protocol) =>
        protocol.name.toLowerCase().includes(state.search.toLowerCase())
      );
    }
    return database.categories.filter((folder) =>
      folder.name.toLowerCase().includes(state.search.toLowerCase())
    );
  }, [state.search, database, parentFolder]);

  const [owner, setOwner] = useState<DepartmentItem | null | undefined>(
    department
  );
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      nickName: '',
      pdf: undefined,
      // pairedDeps: [],
      // owner: department as DepartmentItem | undefined,
      isRestrictive: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      nickName: parentFolder ? Yup.string() : Yup.string(),
      pdf: parentFolder ? Yup.mixed() : Yup.mixed().nullable(),
      // pairedDeps:
      //   department && department.subDeps && department.subDeps.length > 0
      //     ? Yup.array()
      //     : Yup.array().nullable(),
      // owner: Yup.object().required('Owner is required'),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'p') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        setShowPrintModal(true);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === 'b') handleClose();
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (initialPDF) {
      if (globals.debug) console.log('initialPDF', initialPDF.name);
      handlePdfUpload(initialPDF);
    } else formik.resetForm();
  }, [initialPDF]);

  useEffect(() => {
    const getDetails = async () => {
      const result = await fetchWorkbooks(department);
      setWorkbook(result.data);
    };
    getDetails();
  }, []);

  useEffect(() => {
    if (isEdit) {
      formik.setFieldValue('pdf', pdfurl);
    }
  }, [pdfurl, isEdit]);

  /* 10-11-23 Arul: Function for handling modal submit */
  const handleSubmit = async () => {
    let data = {
      value: formik.values.name.trim(),
      nickName: formik.values.nickName
        ? formik.values.nickName.trim()
        : formik.values.name.trim(),
      pdf: formik.values.pdf,
      pairedDeps: formik.values.isRestrictive ? pairedDeps : [],
      owner: owner,
      isRestrictive: formik.values.isRestrictive,
    };
    if (extractedWorkbook !== '') {
      data = {
        ...data,
        pdf: savedWorkbook,
      };
    }
    if (state.type === COPY_PROTOCOL && parentFolder) {
      if (state.copyProtocol) handleDuplicateProtocol(data, state.copyProtocol);
    } else if (state.type === COPY_PROTOCOL && !parentFolder) {
      if (state.copyCategory) handleDuplicateFolder(data, state.copyCategory);
    } else handleAdd(data);
  };

  /* 10-11-23 Arul: Function for handle change pdf upload */
  const handlePdfUpload = (file: File) => {
    formik.setFieldValue('pdf', file);
    if (formik.values.name === '') {
      let name = toTitleCase(file.name.replace('.pdf', ''));
      detectPattern(name);
      formik.setFieldValue('name', name.replace('.pdf', ''));
    }
  };

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const detectPattern = (value: string) => {
    if (parentFolder) {
      const nickname = extractNickname(value);
      if (nickname && !formik.touched.nickName)
        formik.setFieldValue('nickName', nickname);
    }
  };

  function detectAllCapsPaste(lastValue: string, value: string) {
    //Check if the value was pasted in by checking if the las
    if (value.length - lastValue.length > 2) {
      let cleaned_string = value.replace(/[^A-Za-z]/g, '');
      if (cleaned_string.length === 0) return false;
      if (cleaned_string === cleaned_string.toUpperCase())
        formik.setFieldValue('name', toTitleCase(value));
    }
  }

  function extractNickname(str: string): string | null {
    const regex = /([A-Z]+?\s[0-9]+-?[0-9]*\.)/;
    const match = str.match(regex);
    return match ? match[1] : null; // return the captured group if a match is found, else return null
  }

  const getFileFromUrl = async () => {
    try {
      const signedUrlResponse: any = await Storage.get(
        newExtractedWorkbookKey,
        {
          level: 'public',
          download: true,
        }
      );
      if (!signedUrlResponse.Body) throw new Error('Failed to download PDF.');

      const blob = signedUrlResponse.Body;
      const fileName = 'workbookParsed.pdf';
      const lastModified = Date.now();
      const convertedToFile = new File([blob], fileName, {
        type: 'application/pdf',
        lastModified,
      });
      setSavedWorkbook(convertedToFile);
    } catch (error) {
      if (globals.debug) if (globals.debug) console.log(error);
    }
  };

  useEffect(() => {
    getFileFromUrl();
  }, [newExtractedWorkbookKey, extractedWorkbook]);

  const handleSearch = (value: string) => {
    setState({ ...state, search: value });
  };

  const handleProtocolSelection = async (protocol: ProtocolItem) => {
    setState({ ...state, copyProtocol: protocol });
    //Populate the formik values
    formik.setFieldValue('name', protocol.name);
    formik.setFieldValue('nickName', protocol.nickname);

    formik.setFieldValue('isRestrictive', protocol.isRestrictive);
    // let dep = findDepartmentOwner(department, protocol);
    // if (dep) setOwner(dep);
    // else
    setOwner(department);

    let deps = findPairedDepartments(department, protocol);
    if (deps) setPairedDeps(deps);

    const pdfResult: any = await Storage.get(protocol.pdfUrl, {
      level: 'public',
      contentType: 'application/pdf',
      download: true,
    });
    if (pdfResult == null || pdfResult.Body == null) {
      throw new Error('Error downloading PDF');
    }

    const file = new File([pdfResult.Body], protocol.name, {
      type: pdfResult.Body.type,
    });
    formik.setFieldValue('pdf', file);
    // setPairedDeps(protocol.pairedDeps);
  };

  const handleFolderSelection = async (category: CategoryItem) => {
    if (globals.debug) console.log('Cloning category', category);
    setState({ ...state, copyCategory: category });
    //Populate the formik values
    formik.setFieldValue('name', category.name);

    formik.setFieldValue('isRestrictive', category.isRestrictive);
    // let dep = findDepartmentOwner(department, category);
    // if (dep) setOwner(dep);
    // else setOwner(department);
    setOwner(department);

    let deps = findPairedDepartments(department, category);
    if (deps) setPairedDeps(deps);
  };

  const handleClear = () => {
    formik.resetForm();
    formik.setFieldValue('pdf', undefined);
    setPairedDeps([]);
  };

  const isSaveValid = useMemo(() => {
    if (parentFolder)
      return formik.isValid && formik.dirty && formik.values.pdf;
    return formik.isValid && formik.dirty;
  }, [formik, parentFolder]);

  const availableDepartments = useMemo(() => {
    if (owner == null) return [];
    let list = owner.allSubDeps ?? [];
    if (parentFolder) {
      list = list.filter((dep) => {
        if (parentFolder.pairedDepIDs) {
          return parentFolder.pairedDepIDs.includes(dep.id);
        } else return false;
      });
    }
    return list;
  }, [owner, parentFolder]);

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleClose}
      header={customHeader}
      style={{ width: parentFolder ? '40%' : '25%', minWidth: '400px' }}
      className={isVisible ? 'protocolSidebar sidebarWidth' : 'sidebar-hidden'}
    >
      <div className="sidebarContainer">
        {showPrintModal && (
          <WorkbookParsingModal
            visible={showPrintModal}
            onClose={() => setShowPrintModal(false)}
            onUpload={(file: File) => {
              formik.setFieldValue('pdf', file);
              setShowPrintModal(false);
              nameRef.current?.focus();
            }}
            department={department}
            bypassPreview={globals.debug}
          />
        )}

        {owner && (
          <SelectDepartmentsModal
            availableDepartments={availableDepartments}
            department={owner}
            values={pairedDeps}
            isVisible={isSelectingDepartments}
            handleClose={() => setIsSelectingDepartments(false)}
            handleSubmit={(dep) => {
              setPairedDeps(dep);
              setIsSelectingDepartments(false);
            }}
          />
        )}

        <div
          className="buttonContainer contentTitleLarge"
          onClick={handleClose}
        >
          Create New {parentFolder ? 'Protocol' : 'Folder'}
        </div>
        <div className="contentText">
          Create a new{' '}
          {parentFolder
            ? 'protocol by enterring in the name, nickname, and adding PDF. '
            : 'folder by entering in the name. '}
          {state.type === COPY_PROTOCOL &&
            (parentFolder
              ? 'Duplicating a protocol will create a new protocol with the same name, nickname, PDF, and any medications, infusions, and etc. as the selected protocol.'
              : 'Duplicating a folder will create a new folder with the same name and any protocols as the selected folder, along with any medication infusions, and etc. that are associated to that protocol.')}
        </div>
        <div className="" style={{ padding: '0px 0px' }}>
          <span
            className="ketamine-general-label"
            style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
          >
            Input Type
          </span>
          <GeneralSelection
            items={[
              'Create New',
              'Duplicate ' + (parentFolder ? 'Protocol' : 'Folder'),
            ]}
            selectedIndex={state.type}
            onPress={(item, index) => {
              handleSearch('');
              setState({
                ...state,
                type: index,
              });
              handleClear();

              // handleSearch('');
            }}
            labelField={(option) => option}
            valueField={(option) => option}
          />
        </div>

        {state.type === NEW_PROTOCOL ||
        state.copyProtocol ||
        state.copyCategory ? (
          <div>
            {state.type === COPY_PROTOCOL && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    height: 'auto',
                  }}
                >
                  <label
                    htmlFor="name"
                    className={`notification-css-title`}
                    style={{ fontSize: '16px', marginRight: '10px' }}
                  >
                    Duplicating {parentFolder ? 'Protocol' : 'Folder'}:
                  </label>

                  <span
                    onClick={() => {
                      handleSearch('');
                      setState({
                        ...state,
                        copyProtocol: null,
                        copyCategory: null,
                        search: '',
                      });
                    }}
                  >
                    <div
                      className="notification-css clickableText"
                      style={{ fontSize: '16px' }}
                    >
                      {parentFolder
                        ? state.copyProtocol?.name
                        : state.copyCategory?.name}
                    </div>
                  </span>

                  {/* <label
                  htmlFor="name"
                  className={`notification-css`}
                  style={{ fontSize: '16px' }}
                >
                  {state.copyProtocol?.name}
                </label> */}
                </div>
                {parentFolder && state.copyProtocol.sum > 0 && (
                  <div className="contentText">
                    {'Duplicating '}
                    {state.copyProtocol?.medications.length
                      ? state.copyProtocol?.medications.length + ' medications'
                      : ''}
                    {state.copyProtocol?.infusions.length > 0
                      ? ', ' +
                        state.copyProtocol?.infusions.length +
                        ' infusions'
                      : ''}
                    {state.copyProtocol?.equipment.length > 0
                      ? ', ' +
                        state.copyProtocol?.electrical.length +
                        ' electrical shocks'
                      : ''}
                    {state.copyProtocol?.equipment.length > 0
                      ? ', ' +
                        state.copyProtocol?.equipment.length +
                        ' equipment'
                      : ''}
                    {state.copyProtocol?.pairedProtocols.length > 0
                      ? ', ' +
                        state.copyProtocol?.pairedProtocols.length +
                        ' paired protocols'
                      : ''}
                  </div>
                )}
                {!parentFolder && state.copyCategory && (
                  <div className="contentText">
                    {'Duplicating '}
                    {state.copyCategory.protocols
                      ? state.copyCategory.protocols.length + ' protocols'
                      : ''}
                    {state.copyCategory.subCategories
                      ? ', ' +
                        state.copyCategory.subCategories.length +
                        'sub-folders'
                      : ''}
                  </div>
                )}
              </div>
            )}
            <label
              htmlFor="name"
              className={`notification-css-title`}
              style={{ fontSize: '16px' }}
            >
              Name
              <span className="required-field ">
                *
                <BiRename
                  className="refresh-icon"
                  onClick={() => {
                    formik.setFieldValue(
                      'name',
                      toTitleCase(formik.values.name)
                    );
                  }}
                  size="1.25rem"
                  style={{ marginRight: '5px', cursor: 'pointer' }}
                />
              </span>
            </label>
            <div className="input-container">
              <InputText
                ref={nameRef}
                type="text"
                className=" notification-model"
                id="name"
                name="name"
                autoFocus={true}
                required={true}
                value={formik.values.name}
                onChange={(e: any) => {
                  let lastVal = formik.values.name;
                  formik.setFieldValue('name', e.target.value);
                  detectPattern(e.target.value);
                  detectAllCapsPaste(lastVal, e.target.value);
                }}
                onBlur={() => formik.setFieldTouched('name', true)}
                style={{
                  fontSize: '16px',
                  cursor: 'auto',
                  padding: '20px 20px',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                  //Detect CMND + P
                  if (e.ctrlKey && e.key === 'p') {
                    setShowPrintModal(true);
                  }
                }}
              />
              <div className="input-border"></div>
            </div>
            {parentFolder && extractedWorkbook === '' && (
              <div style={{ width: '100%' }}>
                <label
                  htmlFor="nickname"
                  className={`notification-css-title`}
                  style={{ fontSize: '16px' }}
                >
                  Nickname
                </label>
                <span className="contentText" style={{ fontSize: '13px' }}>
                  This will be the protocols shorthand name (Ex. "A1.")
                </span>
                <div className="input-container">
                  <InputText
                    type="text"
                    className=" notification-model"
                    id="nickname"
                    name="nickname"
                    required={true}
                    value={formik.values.nickName}
                    onChange={(e: any) => {
                      formik.setFieldValue('nickName', e.target.value);
                    }}
                    onBlur={() => formik.setFieldTouched('nickName', true)}
                    style={{
                      fontSize: '16px',
                      cursor: 'auto',
                      padding: '20px 20px',
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      }
                    }}
                  />
                  <div className="input-border"></div>
                </div>
                <label
                  htmlFor="nickname"
                  className={`notification-css-title`}
                  style={{ fontSize: '16px' }}
                >
                  PDF File<span className="required-field ">*</span>
                </label>
                <div
                  className="containerPDFUpload"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                  }}
                >
                  <CustomPdfUpload
                    onClear={() => formik.setFieldValue('pdf', undefined)}
                    pdfUploaded={handlePdfUpload}
                    pdfvalue={formik.values.pdf}
                    containerStyle={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  />
                  {!formik.values.pdf && workbook.length > 0 && (
                    <div
                      onClick={() => setShowPrintModal(true)}
                      className="addFromWorkbookContainer"
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginLeft: '6px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          marginBottom: '5px',
                        }}
                      >
                        <IoBook className="drag-drop-icon" />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                          marginBottom: '5px',
                        }}
                      >
                        Add from Protocol Set
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {extractedWorkbook !== '' && (
              <div
                className="pdf-info-container"
                style={{
                  marginTop: '20px',
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                }}
              >
                <FontAwesomeIcon icon={faFilePdf} size="2x" />
                <span style={{ marginTop: '10px' }}>
                  {savedWorkbook ? savedWorkbook.name : 'Extracted PDF'}
                </span>
                <Button
                  variant="outline-danger"
                  size="sm"
                  style={{ marginTop: '10px' }}
                  onClick={() => {
                    setExtractedWorkBook('');
                    setSavedWorkbook(null);
                  }}
                >
                  Remove
                </Button>
              </div>
            )}
            {/* {department &&
              department.subDeps &&
              department.subDeps.length > 0 && (
                <>
                  <label htmlFor="" className={`notification-css-title`}>
                    <span
                      className="headerTextMargin"
                      style={{ fontSize: '16px', marginTop: '10px' }}
                    >
                      Subscribed Departments: {pairedDeps.length}{' '}
                      / {department.subDeps.length}
                      <span
                        onClick={() =>
                          setPairedDeps(department.subDeps)
                        }
                      >
                        <div className="clickableText">Add All</div>
                      </span>
                    </span>
                  </label>
                  <span
                    className="contentText greyText"
                    style={{ fontSize: '13px', marginLeft: '10px' }}
                  >
                    This is a list of every department that will subscribe to
                    the protocol.
                  </span>
                  <SearchableDropdown<DepartmentItem>
                    id="searchDropdown"
                    options={departmentList}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    multiSelect={true}
                    onChange={(option: DepartmentItem) =>
                      handleAddDepartment(option)
                    }
                    onClear={handleClearDepartments}
                    placeholder="Search department..."
                  />
                  {pairedDeps.length === 0 && (
                    <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                      No paired departments...
                    </h6>
                  )}
                  <div
                    style={{
                      border:
                        pairedDeps.length === 0
                          ? '0px'
                          : '1px solid #ccc',
                      borderRadius: '5px',
                      marginBottom: '20px',
                      marginTop: '10px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ViewportList items={pairedDeps}>
                      {(item: DepartmentItem, index) => (
                        <div
                          key={index}
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '16fr 1fr',
                            padding: '6px 10px',
                            alignItems: 'center',
                            borderBottom:
                              index === pairedDeps.length - 1
                                ? ''
                                : '1px solid #ccc',
                          }}
                          className="listItem"
                        >
                          <div className="contentText">{item.name}</div>
                          <FaTimes
                            className="icon-cancel"
                            size={16}
                            onClick={(e) => handleRemoveDepartment(item, e)}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                </>
              )} */}
            {department.isMultiDep && (
              <FolderProtocolAdvancedSettings
                type={parentFolder ? 'Protocol' : 'Folder'}
                owner={owner ? owner : undefined}
                setOwner={setOwner}
                isRestrictive={formik.values.isRestrictive}
                setIsRestrictive={() => {
                  formik.setFieldValue(
                    'isRestrictive',
                    !formik.values.isRestrictive
                  );
                }}
                availableDepartments={availableDepartments}
                pairedDeps={pairedDeps}
                setPairedDeps={setPairedDeps}
                department={department}
                setActiveID={() => {}}
              />
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 'auto',
              marginTop: '10px',
            }}
          >
            <label
              htmlFor="name"
              className={`notification-css-title`}
              style={{ fontSize: '16px' }}
            >
              Find {parentFolder ? 'Protocol' : 'Folder'}
            </label>
            <SearchBar
              value={state.search}
              onChange={handleSearch}
              placeholder="Search"
              containerStyle={{ width: '100%' }}
              autoFocus={true}
            />
            <div
              // style={{ maxHeight: '40%' }}
              className="contentBorder protocolCalculationPad"
            >
              <ViewportList items={filteredList}>
                {(item: ProtocolItem | CategoryItem, index: any) => (
                  <div
                    key={item?.name + index}
                    onClick={() => {
                      if (parentFolder)
                        handleProtocolSelection(item as ProtocolItem);
                      else handleFolderSelection(item as CategoryItem);
                    }}
                    className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight`}
                    style={{
                      borderBottom:
                        index === filteredList.length - 1
                          ? 'none'
                          : '1px solid #ccc',
                    }}
                  >
                    {item.name}
                  </div>
                )}
              </ViewportList>
            </div>
          </div>
        )}
        <div className="dialogButtonsForce">
          <Button
            data-testid="cancelBtnn"
            className="secondary-button-small-border btn-rightMargin"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="addBttn"
            className="primary-button-small"
            disabled={!isSaveValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            {!isEdit && (
              <span>
                <IoAdd size="1rem" />
              </span>
            )}
            {isEdit
              ? 'Save'
              : state.type === COPY_PROTOCOL
                ? 'Duplicate'
                : 'Add'}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};

export default FolderProtocolModal;
