import * as _ from 'lodash';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowBack, BiSolidChevronRight } from 'react-icons/bi';
import { HiPencil, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { sideoutList } from '../../_global/constants/Protocol_constants';
import ConfirmModal from '../Modal/ConfirmModal';
import { DatabaseResponse, ResponseType } from '../../../data/AmplifyDB';
import ProtocolItem from '../../../data/model/ProtocolItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import FormItem from '../../../data/model/FormItem';
import SearchBar from '../Search/SearchBar';
import { User } from '../../../models';
import { InputText } from 'primereact/inputtext';
import { FaTimes } from 'react-icons/fa';
import GeneralSelection from '../Selection/GeneralSelection';
import { GroupJSON, createGroupItem } from '../../../data/functions/GroupDB';
import { GroupType } from '../../../models';
import GroupItem from '../../../data/model/GroupItem';

interface AddUserSideoutPropsProps {
  handleClose: () => void;
  handleAdd?: (items: GroupItem) => void | any;
  details?: any;
  title?: string;
  isVisible?: boolean;
  isEditMode?: any;
  page?: any;
  protocol?: ProtocolItem;
  previousGroup?: GroupItem;
}

const SELECT_USERS = 0;
const ADDED_USERS = 1;
const AddUserSideoutProps: React.FC<AddUserSideoutPropsProps> = (props) => {
  const {
    isVisible,
    handleClose,
    isEditMode,
    page,
    handleAdd,
    protocol,
    previousGroup,
  } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );

  const department = database.department;
  const [isDelete, setIsDelete] = useState(false);
  const [value, setValue] = useState<any>('');
  const [selectedOptions, setSelectedOptions] = useState<User[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<User[]>([]);
  const [filteredAddedOptions, setFilteredAddedOptions] = useState<User[]>([]);

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [inputType, setInputType] = useState(SELECT_USERS);
  const options = useMemo(() => database.users, [database]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    setFilteredAddedOptions(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (previousGroup) {
      let users: any[] = [];
      if (previousGroup && previousGroup.pairedUserIDs) {
        users = previousGroup.pairedUserIDs.map((id) =>
          database.users.find((user: User) => user.id === id)
        );
      }
      setSelectedOptions(users);
    }
  }, [previousGroup]);

  const handleCheckboxChange = (option: User) => {
    const isSelected = selectedOptions.find((item) => item.id === option.id);
    const updatedOptions = isSelected
      ? selectedOptions.filter((item) => item.id !== option.id)
      : [...selectedOptions, option];

    setSelectedOptions(updatedOptions);
  };

  const handleSearchSelectUsers = (text: string) => {
    setValue(text);
    const filtered = text
      ? options.filter(
          (user) =>
            user.firstName.toLowerCase().includes(text.toLowerCase()) ||
            user.lastName.toLowerCase().includes(text.toLowerCase())
        )
      : options;

    setFilteredOptions(filtered);
  };

  const handleSearchAddedUsers = (text: string) => {
    setValue(text);
    const filtered = text
      ? selectedOptions.filter(
          (user) =>
            user.firstName.toLowerCase().includes(text.toLowerCase()) ||
            user.lastName.toLowerCase().includes(text.toLowerCase())
        )
      : selectedOptions;

    setFilteredAddedOptions(filtered);
  };

  const handleAddUser = async () => {
    if (handleAdd) {
      let GroupJSON: GroupJSON = {
        name: 'Auto Generated Group ' + new Date().getTime().toString(),
        type: GroupType.NOTIFICATION,
        isAutoGen: true,
        departmentID: department.id,
        pairedDepIDs: department.subDepIDs,
        pairedUserIDs: selectedOptions.map((item: User) => item.id),
      };
      let result = await createGroupItem(GroupJSON, previousGroup);
      if (result.type === ResponseType.Failure) return result;
      let newGroup = result.data as GroupItem;
      handleAdd(newGroup);
      handleCloseSideouts();
    }
  };

  const handleCloseSideouts = () => {
    setIsDelete(false);
    setSelectedOptions([]);
    handleClose();
  };

  const handleRemoveUser = (user: User) => {
    setSelectedOptions(selectedOptions.filter((item) => item.id !== user.id));
  };

  return (
    <div>
      <Sidebar
        visible={isVisible}
        position="right"
        onHide={handleCloseSideouts}
        style={{
          width: '30%',
          minWidth: '400px',
        }}
        className={'protocolSidebar sidebarWidth'}
      >
        <div style={{ height: '100%', padding: '10px' }}>
          <div
            className="buttonContainer contentTitleLarge hoverText"
            onClick={handleCloseSideouts}
          >
            <span className="headerTilte">
              <BiArrowBack
                className="header-icon cursorPointer"
                style={{ paddingLeft: '4px' }}
              />
              <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
              Add Users
            </span>
          </div>

          <div className="contentText">
            {inputType === SELECT_USERS ? (
              <div>Select users from the list below to notify.</div>
            ) : (
              <div>The selected users will receive the notification.</div>
            )}
          </div>

          <div style={{ margin: '20px 0px' }}>
            <GeneralSelection
              items={['Select Users', 'Added Users']}
              selectedIndex={inputType}
              onPress={(item, index) => {
                setInputType(index);
                setValue(''); // Reset the search bar value when switching tabs
                if (index === SELECT_USERS) {
                  setFilteredOptions(options);
                }
              }}
              labelField={(option) => option}
              valueField={(option) => option}
            />
          </div>
          <div
            style={{
              height: '100%',
              padding: '10px',
              display: 'flex',
            }}
          >
            {inputType === SELECT_USERS && (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="contentLabelBold">Select Users</div>
                <div style={{ minHeight: '50px' }}>
                  <SearchBar
                    value={value}
                    onChange={handleSearchSelectUsers}
                    placeholder="Search"
                    containerStyle={{ width: '100%' }}
                  />
                </div>
                <div
                  style={{ maxHeight: '60%' }}
                  className="contentBorder protocolCalculationPad secondaryListScroll"
                >
                  <ViewportList items={filteredOptions}>
                    {(item: User, index: any) => (
                      <div
                        key={item.id + index}
                        onClick={() => handleCheckboxChange(item)}
                        className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                          filteredOptions.length !== index + 1
                            ? 'contentUnderline '
                            : ''
                        }`}
                      >
                        <RadioButton
                          inputId="pairedProtocol"
                          name="pairedProtocol"
                          className="radioBtnColor"
                          style={{ marginRight: '10px' }}
                          value={item?.firstName + ' ' + item?.lastName}
                          checked={selectedOptions.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                        <div>{item?.firstName + ' ' + item?.lastName}</div>
                      </div>
                    )}
                  </ViewportList>
                </div>
              </div>
            )}

            {inputType === ADDED_USERS && (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="contentLabelBold">Added Users</div>

                <div style={{ minHeight: '50px' }}>
                  <SearchBar
                    value={value}
                    onChange={handleSearchAddedUsers}
                    placeholder="Search"
                    containerStyle={{ width: '100%' }}
                  />
                </div>
                <div
                  style={{ maxHeight: '60%' }}
                  className="contentBorder protocolCalculationPad secondaryListScroll"
                >
                  <ViewportList items={filteredAddedOptions}>
                    {(item: User, index: any) => (
                      <div
                        key={item.id + index}
                        className={`listhover spaceBetween cursorPointer item contentHeading ${
                          filteredAddedOptions.length !== index + 1
                            ? 'contentUnderline'
                            : ''
                        }`}
                      >
                        <div>
                          {item?.firstName} {item?.lastName}
                        </div>
                        <div>
                          <FaTimes
                            style={{ cursor: 'pointer', color: 'red' }}
                            onClick={() => handleRemoveUser(item)}
                          />
                        </div>
                      </div>
                    )}
                  </ViewportList>
                </div>
              </div>
            )}
          </div>

          <div className="btn_Bottom">
            <Button
              data-testid="cancel"
              className="secondary-button btn-rightMargin"
              onClick={handleCloseSideouts}
            >
              X Cancel
            </Button>
            <Button
              data-testid="save"
              className="primary-button"
              disabled={!(selectedOptions?.length > 0)}
              onClick={handleAddUser}
            >
              + Add
            </Button>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default AddUserSideoutProps;
