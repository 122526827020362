import { DataStore } from 'aws-amplify';
import { Ambulance } from '../../models';
import DepartmentItem from '../model/DepartmentItem';
import { executeQuery, Response, ResponseType } from '../AmplifyDB';
import { ambulancesByDepartmentID } from '../../graphql/queries';

export const fetchAmbulances = async (
  department: DepartmentItem,
  userDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);

    let ambulancesList: Ambulance[];
    if (userDataStore) ambulancesList = await DataStore.query(Ambulance);
    else {
      ambulancesList = await executeQuery(ambulancesByDepartmentID, {
        departmentID: department.id,
        filter: {
          status: { ne: 'DELETED' },
        },
      });
    }
    ambulancesList = ambulancesList.filter((c: Ambulance) => {
      if (department.parentDep) return c.departmentID === department.id;
      return true;
    });

    return {
      type: ResponseType.Success,
      data: ambulancesList,
    };
  } catch (error) {
    console.error('Error fetching ambulances:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createAmbulance = async (ambulance: any): Promise<Response> => {
  try {
    let a = await DataStore.save(
      new Ambulance({
        name: ambulance.name,
        departmentID: ambulance.department.id,
      })
    );
    return {
      type: ResponseType.Success,
      data: a,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};
export const editAmbulance = async (ambulance: any): Promise<Response> => {
  try {
    let a = await DataStore.query(Ambulance, ambulance.id);
    if (!a)
      return {
        type: ResponseType.Failure,
        data: 'Ambulance not found',
      };
    let res = await DataStore.save(
      Ambulance.copyOf(a, (updated) => {
        updated.name = ambulance.name;
      })
    );
    return {
      type: ResponseType.Success,
      data: res,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteAmbulance = async (
  ambulance: Ambulance | null
): Promise<Response> => {
  if (!ambulance)
    return {
      type: ResponseType.Failure,
      data: 'Ambulance not found',
    };
  try {
    const toDelete = await DataStore.query(Ambulance, ambulance.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Ambulance not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
