import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { FaSearch, FaCaretDown, FaTimes } from 'react-icons/fa';
import { IoClose, IoSearch } from 'react-icons/io5';
import { ViewportList, ViewportListRef } from 'react-viewport-list';

interface SearchableDropdownProps<T> {
  id: string;
  options: T[];
  labelField: (option: T) => string;
  iconField?: (option: T) => any;
  valueField: (option: T) => string;
  onChange: (selectedOption: T) => void;
  onClear?: () => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  onCreate?: (newOption: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  notFoundText?: string;
  value?: T;
  multiSelect?: boolean;
  isActive?: boolean;
  showItems?: number;
  containerStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  itemClassName?: string;
  renderItem?: (option: T, index: number) => React.ReactNode;
}

function SearchableList<T>(props: SearchableDropdownProps<T>) {
  const displayItems = props.showItems || 15;
  const ref = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [focus, setFocus] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false); // Add this state for disabling the input
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [parmList, setParmList] = useState<T[]>(props.options);
  const listRef = useRef<ViewportListRef>(null);
  useEffect(() => {
    setParmList(props.options);
  }, [props.options]);

  useEffect(() => {
    if (props.value) {
      setSearchTerm(props.labelField(props.value));
      setIsDisabled(props.value !== ''); // Disable the input field when a value is selected
    } else {
      setSearchTerm('');
      setIsDisabled(false); // Enable the input field when a value is not selected
    }
  }, [props.value]);

  useEffect(() => {
    if (!isDropdownOpen) setHighlightedIndex(-1);
  }, [isDropdownOpen]);

  useEffect(() => {
    setHighlightedIndex(-1);
  }, [searchTerm]);

  /* Use this effect to close the dropdown when clicked outside */
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [divRef]);

  const filteredOptions = useMemo(() => {
    let list: T[] = [];
    if (searchTerm === '' || parmList == null) return list;

    /* Check for multiple options
     * If the option is a sibstring return at the top of the list
     * If the option contains all of the characters in the search term return next
     */
    list = parmList.filter((option) =>
      props
        .valueField(option)
        .toLowerCase()
        .trim()
        .includes(searchTerm.toLowerCase().trim())
    );

    //Now check for each character in the search term
    let list2 = parmList.filter((option) => {
      let search = searchTerm.toLowerCase().trim();
      let label = props.valueField(option).toLowerCase().trim();
      let found = true;
      for (let i = 0; i < search.length; i++) {
        if (!label.includes(search[i])) {
          found = false;
          break;
        }
      }
      return found;
    });

    //Filter out the duplicates
    list = [...new Set([...list, ...list2])];

    return list;
  }, [parmList, searchTerm, props]);

  const handleEnter = () => {
    if (highlightedIndex >= 0) {
      setSearchTerm(props.labelField(filteredOptions[highlightedIndex]));
      props.onChange(filteredOptions[highlightedIndex]);
      setIsDropdownOpen(false);
      setIsDisabled(true); // Enable the input field when FaTimes is clicked
    } else if (filteredOptions.length > 0) {
      if (!props.multiSelect) {
        setSearchTerm(props.labelField(filteredOptions[0]));
        props.onChange(filteredOptions[0]);
        setIsDropdownOpen(false);
        setIsDisabled(true); // Enable the input field when FaTimes is clicked
      } else {
        setSearchTerm('');
        props.onChange(filteredOptions[0]);
        setIsDropdownOpen(false);
      }
    }
  };

  const handleArrow = (direction: 'ArrowUp' | 'ArrowDown') => {
    if (direction === 'ArrowUp') {
      if (highlightedIndex > 0) {
        setHighlightedIndex(highlightedIndex - 1);
        listRef.current?.scrollToIndex({
          index: highlightedIndex - 1,
          alignToTop: false,
        });
      }
    } else {
      if (highlightedIndex < filteredOptions.length - 1) {
        setHighlightedIndex(highlightedIndex + 1);
        listRef.current?.scrollToIndex({
          index: highlightedIndex + 1,
          alignToTop: false,
        });
      }
    }
  };

  const RenderListItem = ({ option, index }: any) => {
    return (
      <li
        key={props.valueField(option)}
        className={
          `hoverableBackground ` +
          (props.itemClassName ? ` ${props.itemClassName}` : '') +
          (highlightedIndex === index ? ' focused' : '')
        }
        onClick={(e) => {
          e.stopPropagation();
          if (!props.multiSelect) {
            setSearchTerm(props.labelField(option));
            props.onChange(option);
            setIsDropdownOpen(false);
            setIsDisabled(true); // Enable the input field when FaTimes is clicked
          } else {
            setSearchTerm('');
            props.onChange(option);
            setIsDropdownOpen(false);
          }
        }}
        style={{
          padding: '5px',
          cursor: 'pointer',
          gap: '8px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.labelField(option) != '' &&
          props.iconField &&
          props.iconField(option)}
        {props.labelField(option)}
      </li>
    );
  };

  return (
    <div style={{ position: 'relative' }} ref={divRef}>
      <div
        className={`search-bar ${focus ? 'with-focus' : ''}`}
        style={props.containerStyle}
        onClick={(e) => {
          if (ref.current) ref.current.focus();
        }}
      >
        <InputGroup style={{ zIndex: 0 }} className="input-group-navbar">
          <Button variant="">
            <IoSearch size="1.5rem" color={focus ? '#00534C' : '#616161'} />
          </Button>
          <input
            style={{ ...props.style, flex: 1 }}
            ref={ref}
            placeholder={props.placeholder ? props.placeholder : 'Search...'}
            aria-label="Search"
            value={searchTerm}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleEnter();
              } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();
                handleArrow(e.key);
              }
            }}
            className="searchInput"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === '') {
                setParmList(props.options);
                setIsDropdownOpen(false);
              } else setIsDropdownOpen(true);
            }}
            onFocus={() => {
              // setFocus(true);
              if (searchTerm) {
                setIsDropdownOpen(true);
                //Highlight the text in the input
                ref.current?.select();
              }
            }}
            onBlur={() => {
              // setFocus(false);
              setTimeout(() => setIsDropdownOpen(false), 200);
            }}
          />
          {searchTerm && (
            <Button
              className="clickable"
              variant=""
              onClick={(e) => setSearchTerm('')}
            >
              <IoClose size="1.5rem" />
            </Button>
          )}
        </InputGroup>
      </div>

      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1,
            marginTop: '0px',
          }}
        >
          <div
            style={{
              border: '1px solid #ddd',
              borderRadius: '6px',
              listStyleType: 'none',
              margin: 0,
              padding: 0,
              backgroundColor: 'white',
              maxHeight: `calc(${displayItems} * 26px)`, // Assuming each item has a height of 1.5em and 10px padding
              overflowY: 'auto',
            }}
          >
            {filteredOptions.length === 0 && (
              <li
                className={`contentText listItem rounded ${props.onCreate ? 'hoverableBackground' : ''}`}
                style={{
                  padding: '5px',
                  alignContent: 'center',
                  alignSelf: 'center',
                  color: props.onCreate ? '#8CB181' : '#000',
                  textDecoration: props.onCreate ? 'underline' : 'none',
                }}
              >
                {props.onCreate
                  ? '+ Create New'
                  : props.notFoundText
                    ? props.notFoundText
                    : 'No options found...'}
              </li>
            )}
            <ViewportList ref={listRef} items={filteredOptions}>
              {(option: T, index) => {
                return props.renderItem ? (
                  props.renderItem(option, index)
                ) : (
                  <RenderListItem option={option} index={index} />
                );
              }}
            </ViewportList>
          </div>
        </div>
      )}
    </div>
  );
}

export default SearchableList;
